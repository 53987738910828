import * as React from "react";
import classNames from "classnames/bind";

const style = classNames.bind(require("../styles/CFirstConfigureGlobalContainer.styl"))

interface CStepProps  {
    imageComponent?: JSX.Element
    title: string
    description: JSX.Element | string
}

export const CStep: React.FunctionComponent<CStepProps> = props => {
    return <React.Fragment>
        {props.imageComponent}

        <h1 className={style("header")}>{props.title}</h1>
        <h2 className={style("description")}>{props.description}</h2>

        {props.children}
    </React.Fragment>
}
