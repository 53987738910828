import {PromoData} from "src/bums/common/promo/types"
import {CPromoPopup} from "src/bums/common/promo/CPromoPopup/CPromoPopup"
import {CVideochatPromo} from "src/bums/common/promo/CPromoPopup/CVideochatButton"
import {UserSettingStore} from "src/bums/common/stores/UserSettingStore"
import {ApiStore} from "src/lib/entities/store/ApiStore"
import {videochatHintShown} from "src/bums/common/informerPanel/types"

export const createVideocallPromoSettings = (): PromoData<null> => {
    return {
        id: "bums.common.videocall.videocall_hint_feature",
        feature: "bums.common.videocall.videocall_hint_feature",
        component: CPromoPopup,
        userCondition: (userStore, featureStore) => {
            return featureStore.isAvailable("bums.common.videocall.videocall_feature")
        },
        store: null
    }
}

export const createVideochatButtonPromoSettings = (apiStore: ApiStore): PromoData<null> => {
    return {
        id: "bums.common.videocall.videocall_hint_feature",
        feature: "bums.common.videocall.videocall_hint_feature",
        component: CVideochatPromo,
        userCondition: (userStore, featureStore) => {
            return featureStore.isAvailable("bums.common.videocall.videocall_feature")
        },
        store: new UserSettingStore<number>(
            apiStore,
            () => videochatHintShown,
            () => null
        )
    }
}
