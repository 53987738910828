import {ContentTypeMetadata} from "src/lib/entities/types"
import {entityObjectProperties} from "src/lib/entities/metadataHelper"

/**
 * Сервис работы с метаданными
 */
export class MetadataProvider {

    private entitiesMetadata = new Map<string, ContentTypeMetadata>()

    /**
     * Получить метаданные типа
     * @param contentType
     * @returns {ContentTypeMetadata}
     */
    getContentTypeMetadata(contentType: string): ContentTypeMetadata {
        if (!this.entitiesMetadata.has(contentType) && entityObjectProperties.has(contentType)) {
            const newObject: any = {
                contentType
            }
            entityObjectProperties.get(contentType).forEach(prop => {
                newObject[prop] = void 0
            })
            Object.freeze(newObject)
            this.entitiesMetadata.set(contentType, {newObject})
        }

        return this.entitiesMetadata.get(contentType)
    }
}
