import {Component} from "src/lib/components"
import {observer} from "mobx-react"
import {action} from "mobx"

import * as Collections from "src/lib/collections"
import * as Api from "src/lib/entities/api"
import {isEqual} from "lodash"
import {BaseEntity, ListDirection} from "../types"
import {inject} from "src/lib/utils/inject"
import {noop} from "lodash"

import {ListRenderCallback, ListOptions} from "./types"
import {List} from "src/lib/collections/interfaces"

// Smart component to load and show list of entities

type CListProps = ListOptions & {
    // Probably you want to use getListName from utils.ts for refsets
    listName?: string
    initialCopyFromList?: string
    endpoint: string
    // Коллбек, который будет вызываться при подгразки очередной пачки items.
    onEntitiesLoaded?: (entities: Collections.List<BaseEntity>) => void
    onRenderComplete?: (items: List<Api.BaseEntity>) => void
    renderCallback: ListRenderCallback<any>
    loadOnMount?: boolean
    onError?: (e?: Error) => void
    fullLoad?: boolean
}

@observer
export default class CList extends Component<CListProps, {}> {

    static defaultProps: any = {
        loadOnMount: true,
        options: {} as Api.RequestOptions,
        onError: noop
    }

    @inject(Api.Store)
    private apiStore: Api.Store

    private get list() {
        return this.apiStore.getList(this.props.listName || this.props.endpoint);
    }

    public async componentWillMount() {
        await this.copyListIfNeeded()
        if (
            this.props.loadOnMount &&
            (
                this.list.loadStateNext.isNone() ||
                (void 0 !== this.list.loadedEndpoint && this.list.loadedEndpoint !== this.props.endpoint) ||
                !isEqual(this.list.loadedWithOptions, this.props.options)
            )
        ) {
            this.loadPage("Next")
        } else if (this.props.onEntitiesLoaded && this.list.loadStateNext.isCompleted()) {
            this.props.onEntitiesLoaded(this.list.items)
        }
    }

    public componentWillUnmount() {
        if (this.props.initialCopyFromList && this.props.listName) {
            this.apiStore.removeList(this.props.listName)
        }
    }

    componentDidMount(): void {
        if (this.props.onRenderComplete) {
            this.props.onRenderComplete(this.list.items)
        }
    }

    componentWillUpdate(nextProps: CListProps, nextState: {}, nextContext: any): void {
        if (
            this.props.listName !== nextProps.listName ||
            this.props.endpoint !== nextProps.endpoint ||
            !isEqual(this.props.options, nextProps.options)
        ) {
            this.loadPage("Next", nextProps);
        }
    }

    componentDidUpdate(prevProps: CListProps, prevState: {}, prevContext: any): void {
        if (this.props.onRenderComplete) {
            this.props.onRenderComplete(this.list.items)
        }
    }

    @action
    private async copyListIfNeeded() {
        if (this.props.initialCopyFromList && this.props.listName && !this.apiStore.getLists().has(this.props.listName)) {
            await this.apiStore.copyList(this.props.listName, this.props.initialCopyFromList)
        }
    }

    public render() {
        const entities = this.list.items;
        const totalItems = this.list.totalItemsCount || 0;

        if (Number.isNaN(totalItems)) {
            console.warn(
                `List ${this.props.listName} has no totalItemsCount, please make sure backend sends you Count.`
            )
        }

        return this.props.renderCallback({
            entities,
            totalItems: Number.isNaN(totalItems) ? entities.length : totalItems,
            loadStateNext: this.list.loadStateNext,
            loadStatePrev: this.list.loadStatePrev,
            hasMoreNext: this.list.hasMoreNext,
            hasMorePrev: this.list.hasMorePrev,
            loadPage: this.loadPage,
        });
    }

    private loadPage = (direction: ListDirection = "Next", props?: ListOptions | CListProps): void => {
        const listProps = Object.assign({}, this.props, props) as CListProps
        this.apiStore.fetchList(
            listProps.listName || listProps.endpoint,
            listProps.endpoint,
            listProps.options,
            listProps.softOptions,
            direction
        ).then(newItems => {
            if (listProps.onEntitiesLoaded) {
                listProps.onEntitiesLoaded(Collections.List(newItems))
            }

            const hasMore =
                direction === "Next" ? this.list.hasMoreNext : direction === "Prev" ? this.list.hasMorePrev : false

            if (this.props.fullLoad && hasMore) {
                this.loadPage(direction)
            }
            return newItems;
        }).catch(this.props.onError)
    };
}
