import {isEmpty} from "lodash"
import {endpointWithAny} from "src/lib/components/CAutocomplete/AutocompleteFilterStore"
import {runInAction} from "mobx"
import * as Api from "src/lib/entities/api"
import {prepareEntityToSave} from "src/lib/entities/utils"



export const endpointWithAnyTypes = endpointWithAny

export function createEndpointWithOptions(endpoint: string, options?: Api.RequestOptions) {
    if (isEmpty(options)) {
        return endpoint
    }
    return `${endpoint}?${JSON.stringify(prepareEntityToSave(options))}`
}

export function createEndpointWithAnyTypes(contentTypes: Api.ContentType[]) {
    return createEndpointWithOptions(endpointWithAnyTypes, {contentTypes})
}

export function resetListsByEndpoint(apiStore: Api.Store, endpoint: string) {
    endpoint = JSON.stringify(endpoint)
    runInAction(() => {
        apiStore.getLists().forEach((list, listName) => {
            if (list.usedCount <= 1 && listName.includes(endpoint)) {
                apiStore.getLists().delete(listName)
            }
        })
    })
}
