import {CHotKey, Component} from "src/lib/components"
import {inject} from "src/lib/utils/inject"
import {MainMenuStore} from "src/bums/common/CMainMenu/MainMenuStore"
import {observer} from "mobx-react"
import React from "react"
import {autobind} from "core-decorators"
import * as Api from "src/lib/entities/api"
import {hasInternalLink} from "src/lib/utils/url"
import {ModalFormStore} from "src/bums/common/modalForm/ModalFormStore"
import {MainKey} from "src/lib/components/CHotKey/HotKeyManager"
import {FeatureStore} from "src/bums/common/stores/FeatureStore"
import {KeyMapItem, UrlStruct, hotKeyHandlersMap} from "./HotKeyManager"
import {bindArg} from "src/lib/utils/func"
import {track} from "src/lib/tracking"
import {prepareDataForHotKeysTracking} from "src/lib/components/CHotKey/utils"

@track({channels: ["product", "amplitude"]})
@observer
@autobind
export class CHotKeysHandler extends Component {

    @inject(MainMenuStore)
    private mainMenuStore: MainMenuStore

    @inject(FeatureStore)
    private $featureStore: FeatureStore

    @inject(ModalFormStore)
    private $modalFormStore: ModalFormStore

    private getStruct(item: Api.MenuItem): UrlStruct {
        if (!hasInternalLink(item.url)) {
            return void 0
        }

        const formHandler = hotKeyHandlersMap.get(item.id)

        if (!formHandler) {
            return void 0
        }

        const isEnable = formHandler.features.length === 0 || this.$featureStore.isEnabledForUser(formHandler.features)

        if (isEnable) {
            return {...formHandler}
        }
        return void 0
    }

    @track((self: CHotKeysHandler, args: any[]) => (prepareDataForHotKeysTracking(args)))
    private trackEventAndDoAction(item: Api.MenuItem, openModal: boolean, index: number | string) {
        this.handler(item, openModal)
    }

    private handler(item: Api.MenuItem, openModal: boolean = false): null {
            const struct = this.getStruct(item)
            if (struct) {
                if (openModal) {
                    struct.openFormHandler.handler(this.$modalFormStore)
                } else {
                    struct.redirectHandler.handler()
                }
            } else {
                if (window.location.pathname.includes(item.url)) {
                    return null
                }
                window.location.assign(item.url)
            }
    }

    renderHotKeys(item: Api.MenuItem, index: number) {
        const struct = this.getStruct(item)
        let keyMap: KeyMapItem[] = []

        if (struct && struct.hotKey) {
            if (struct.openFormHandler &&  item.subMenu.find(i => i.id === struct.openFormHandler.buttonId)) {
                keyMap.push(["alt", struct.hotKey, bindArg(this.trackEventAndDoAction, item, true, struct.hotKey), true])
            }
            if (struct.redirectHandler) {
                keyMap.push(["alt+shift", struct.hotKey, bindArg(this.trackEventAndDoAction, item, false, struct.hotKey), true])
            }
        }
        if (index < 9) {
            keyMap.push(["alt+shift", (index + 1).toString() as MainKey, bindArg(this.trackEventAndDoAction, item, false, index), true])
        }
        return keyMap.length ?
            <CHotKey keyMap={keyMap} stack={true} key={index}/> : null
    }

    render() {
        if (!this.mainMenuStore.isCompleted) {
            return null
        }

        return <React.Fragment>
            {this.mainMenuStore.mainItems.map((item, index) => {
                return this.renderHotKeys(item, index)
            })}
            </React.Fragment>
    }

}
