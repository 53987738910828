/* tslint:disable */
import * as H from "../metadataHelper"


export interface TodoRenewActionRequest extends H.BaseValue {
    contentType: "TodoRenewActionRequest"; // Object type
}

export module TodoRenewActionRequest {
    export const contentType: "TodoRenewActionRequest" = "TodoRenewActionRequest";
    export const endpoint = "/api/v3/todoRenewActionRequest";

    export const newObject: TodoRenewActionRequest = {contentType}
    Object.freeze(newObject)

    export module fields {}
}

export function isTodoRenewActionRequest(arg: any): arg is TodoRenewActionRequest {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "TodoRenewActionRequest"
}
