import * as ReduxActions from "redux-actions"
import {CARD_SUBJECT} from "../constants"
import {CardMeta} from "../types"

export const REMOVE_UNSAVED_VALUE = "bums/task/action/REMOVE_UNSAVED_VALUE"

type RemoveUnsavedValueAction = ReduxActions.Action<{
    field: string
}> & CardMeta

export function removeUnsavedValue(cardId: string, field: string): RemoveUnsavedValueAction {
    return {
        type: REMOVE_UNSAVED_VALUE,
        payload: {
            field
        },
        meta: {
            cardId,
            subject: CARD_SUBJECT
        }
    }
}

export function isRemoveUnsavedValueAction(action: any): action is RemoveUnsavedValueAction {
    return "object" === typeof action && REMOVE_UNSAVED_VALUE === action.type
}
