const InvalidDate = "Invalid Date"
import {DateOnly} from "src/lib/entities/api"
import * as DateHelper from "src/lib/utils/intl/dateHelper"

const millisecondsInSecond = 1000
const millisecondsInMinute = millisecondsInSecond * 60
const millisecondsInHour = millisecondsInMinute * 60
const millisecondsInDay = millisecondsInHour * 24
const millisecondsInWeek = millisecondsInDay * 7

export enum Units {
    MILLISECONDS,
    SECONDS,
    MINUTES,
    HOURS,
    DAYS,
    WEEKS,
    MONTHS,
    QUARTERS,
    YEARS,
}

const workUnitInSeconds = new Map<Units, number>([
    [Units.SECONDS, 1],
    [Units.MINUTES, 60],
    [Units.HOURS, 3600],
    [Units.DAYS, 3600 * 8],
    [Units.WEEKS, 28800 * 5],
    [Units.MONTHS, 28800 * 21],
    [Units.YEARS, 28800 * 247],
])

const allUnitInSeconds = new Map<Units, number>([
    [Units.SECONDS, 1],
    [Units.MINUTES, 60],
    [Units.HOURS, 3600],
    [Units.DAYS, 86400],
    [Units.WEEKS, 604800],
    [Units.MONTHS, 2592000],
    [Units.YEARS, 31104000]
])

export function getWorkSecondsByUnit(unit: Units) {
    return workUnitInSeconds.has(unit) ? workUnitInSeconds.get(unit) : null
}

export function getAllSecondsByUnit(unit: Units) {
    return allUnitInSeconds.has(unit) ? allUnitInSeconds.get(unit) : null
}

function hasDivisionNotReminder(dividend: number, divisor: number) {
    const result = dividend / divisor
    return (result === Math.round(result))
}

export function defineUnitBySeconds(value: number) {
    if (!value) {
        return Units.DAYS
    }
    if (hasDivisionNotReminder(value, getAllSecondsByUnit(Units.MONTHS))) {
        return Units.MONTHS
    } else if (hasDivisionNotReminder(value, getAllSecondsByUnit(Units.WEEKS))) {
        return Units.WEEKS
    } else if (hasDivisionNotReminder(value, getAllSecondsByUnit(Units.DAYS))) {
        return Units.DAYS
    } else if (hasDivisionNotReminder(value, getAllSecondsByUnit(Units.HOURS))) {
        return Units.HOURS
    }
    return Units.MINUTES
}


export function defineUnitByWorkSeconds(value: number) {
    if (!value) {
        return Units.DAYS
    }
    if (hasDivisionNotReminder(value, getWorkSecondsByUnit(Units.MONTHS))) {
        return Units.MONTHS
    } else if (hasDivisionNotReminder(value, getWorkSecondsByUnit(Units.WEEKS))) {
        return Units.WEEKS
    } else if (hasDivisionNotReminder(value, getWorkSecondsByUnit(Units.DAYS))) {
        return Units.DAYS
    } else if (hasDivisionNotReminder(value, getWorkSecondsByUnit(Units.HOURS))) {
        return Units.HOURS
    }
    return Units.MINUTES
}

function localOffset(date: Date): number {
    return date.getTimezoneOffset() * 60000
}

function inMilliseconds(date: Date, date2: Date): number {
    return (date.getTime() - localOffset(date)) - (date2.getTime() - localOffset(date2))
}

function inSeconds(date: Date, date2: Date): number {
    return Math.trunc(inMilliseconds(date, date2) / millisecondsInSecond)
}

function inMinutes(date: Date, date2: Date): number {
    return Math.trunc(inMilliseconds(date, date2) / millisecondsInMinute)
}

function inHours(date: Date, date2: Date): number {
    return Math.trunc(inMilliseconds(date, date2) / millisecondsInHour)
}

function inDays(date: Date, date2: Date): number {
    return Math.trunc(inMilliseconds(date, date2) / millisecondsInDay)
}

function inMonths(date: Date, date2: Date): number {
    let months: number
    months = inYears(date, date2) * 12
    months -= date.getMonth() + 1
    months += date2.getMonth()
    return months <= 0 ? 0 : months
}

function inYears(date: Date, date2: Date): number {
    return date2.getFullYear() - date.getFullYear()
}


/**
 * Проверка даты на валидность
 */
export function isValid(date: Date): boolean {
    return date && date.toString() !== InvalidDate
}

/**
 * Являются ли даты идентичными
 */
export function isSame(date: Date, date2: Date, checkHours = false, checkMinutes = false, checkSeconds = false, validate = true) {
    const isValidDates = validate ? isValid(date) && isValid(date2) : true
    return isValidDates
        && date.getFullYear() === date2.getFullYear()
        && date.getMonth() === date2.getMonth()
        && date.getDate() === date2.getDate()
        && (!checkHours || date.getHours() === date2.getHours())
        && (!checkMinutes || date.getMinutes() === date2.getMinutes())
        && (!checkSeconds || date.getSeconds() === date2.getSeconds())
}

/**
 * Относится ли дата к текущему месяцу
 */

export function isDateInMonth(date: Date, month: number) {
    return isValid(date) && date.getMonth() === month
}

/**
 * Проверяет, что переданная дата попадает в текущий день недели
 * @todo проверить, что сработает для воскресенья
 * @param date
 * @returns {boolean}
 */
export function isSameWeek(date: Date): boolean {
    if (!isValid(date)) {
        return false
    }
    // Находим понедельник на этой неделе
    let monday = getToday()

    // Если воскресенье, то пишем 7, а не 0. Считаем воскресенье последним днем недели!
    let day = monday.getDay() || 7
    if (day !== 1) {
        monday.setHours(-24 * (day - 1))
    }
    // Очищаем время
    monday.setHours(0, 0, 0, 0)
    let sunday = monday.clone()
    sunday.addDays(7)
    // Клонируем тестируемую дату, чтобы на неё тоже очистить время
    let testDate = date.clone()
    testDate.setHours(0, 0, 0, 0)
    // Если между понедельник и воскресеньем этой недели, то на этой неделе
    return testDate >= monday && testDate <= sunday
}

/**
 * Является ли дата сегодняшним днем
 */
export function isToday(date: Date): boolean {
    return isSame(getToday(), date)
}
export function isTomorrow(date: Date): boolean {
    return isSame(getTomorrow(), date)
}
export function isYesterday(date: Date): boolean {
    return isSame(getYesterday(), date)
}
export function isSameHour(date: Date): boolean {
    return isSame(new Date(), date, true)
}
export function isSameMinute(date: Date): boolean {
    return isSame(new Date(), date, true, true)
}
export function isSameSecond(date: Date): boolean {
    return isSame(new Date(), date, true, true, true)
}

export function isSameMonth(date: Date, date2: Date): boolean {
    return isValid(date) && isValid(date2)
    && date.getFullYear() === date2.getFullYear()
    && date.getMonth() === date2.getMonth()
}

export function isSameYear(date1: Date, date2: Date) {
    return date1.getFullYear() === date2.getFullYear()
}

/**
 * Соответствует ли дата одному из выражений ["Вчера", "Сегодня", "Завтра"]
 */
export function isNearToday(date: Date, resultDefinition = false): boolean|number {
    if (!isValid(date)) {
        return false
    }
    const daysBetween = getToday().clone().clearTime().getDaysBetween(date.clone().clearTime())
    if (Math.abs(daysBetween) < 2) {
        if (resultDefinition) {
            return daysBetween;
        } else {
            return true;
        }
    } else {
        return false;
    }
}

/**
 * Проверяет, что от переданной даты до текущей не более 6 дней в любую сторону.
 * Что "еще/прошло не больше недели".
 *
 * @param date
 * @returns {boolean}
 */
export function isInWeek(date: Date): boolean {
    if (!isValid(date)) {
        return false
    }
    let today = getToday()
    return Math.abs(date.clone().clearTime().getDaysBetween(today.clone().clearTime())) < 7
}

/**
 * Возвращает true если время для этой даты 00:00
 * Например, чтобы не показывать время для дедлайнов.
 *
 * @param date
 * @returns {boolean}
 */
export function is00am(date: Date) {
    return date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0
}


/**
 * Возвращает true, если переданная дата попадает на текущий год
 *
 * @param date
 * @returns {boolean}
 */
export function isThisYear(date: Date): boolean {
    if (!isValid(date)) {
        return false
    }
    let today = getToday()
    return today.getFullYear() === date.getFullYear()
}

export function isLastDayOfMonth(date: Date): boolean {
    const month = date.getMonth()
    const year = date.getFullYear()
    const day = date.getDate()
    const lastDay = (new Date(year, month + 1, 0)).getDate()
    return day === lastDay
}

export function isFirstDayOfMonth(date: Date): boolean {
    return date.getDate() === 1
}

/**
 * Вычисляет разницу между датами
 */
export function diff(date: Date, date2: Date, units: Units = Units.DAYS): number {
    switch (units) {
        case Units.MILLISECONDS:
            return inMilliseconds(date, date2)
        case Units.SECONDS:
            return inSeconds(date, date2)
        case Units.MINUTES:
            return inMinutes(date, date2)
        case Units.HOURS:
            return inHours(date, date2)
        case Units.DAYS:
            return inDays(date, date2)
        case Units.MONTHS:
            return inMonths(date, date2)
        case Units.YEARS:
            return inYears(date, date2)
    }
}

export function getNow(): Date {
    return new Date()
}

/**
 * Возвращает сегоднящнюю дату с обнуленным временем
 */
export function getToday(): Date {
    let date = new Date()
    date.setHours(0, 0, 0, 0)
    return date
}

export function getTodayDateOnly() {
    return DateHelper.DateToDateOnly(getToday())
}

export function getYesterday(relativeDate?: Date): Date {
    let date = relativeDate ? relativeDate.clone() : getToday()
    date.setDate(date.getDate() - 1)
    date.setHours(0, 0, 0, 0)
    return date
}

export function getYesterdayDateOnly(relativeDate?: DateOnly) {
    return DateHelper.DateToDateOnly(getYesterday(DateHelper.DateOnlyToDate(relativeDate)))
}

export function getTomorrow(relativeDate?: Date): Date {
    let date = relativeDate ? relativeDate.clone() : getToday()
    date.setDate(date.getDate() + 1)
    date.setHours(0, 0, 0, 0)
    return date
}

export function getTomorrowDateOnly(relativeDate?: DateOnly) {
    return DateHelper.DateToDateOnly(getTomorrow(DateHelper.DateOnlyToDate(relativeDate)))
}

export function getDayAfterTomorrow() {
    const date = getToday()
    date.setDate(date.getDate() + 2)
    date.setHours(0, 0, 0, 0)
    return date
}

export function getNextWeek(relativeDate?: Date): Date {
    let date = relativeDate ? relativeDate.clone() : getToday()
    date.setDate(date.getDate() + 7)
    date.setHours(0, 0, 0, 0)
    return date
}
export function getNextWeekDateOnly(relativeDate?: DateOnly) {
    return DateHelper.DateToDateOnly(getNextWeek(DateHelper.DateOnlyToDate(relativeDate)))
}

export function getNextMonth(relativeDate?: Date): Date {
    let date = relativeDate ? relativeDate.clone() : getToday()
    date.setMonth(date.getMonth() + 1)
    date.setHours(0, 0, 0, 0)
    return date
}

export function getDateWithoutTime(relativeDate: Date) {
    let date = relativeDate ? relativeDate.clone() : getToday()
    date.setHours(0, 0, 0, 0)
    return date
}

/**
 * Возвращает количество дней в месяце для указанной даты
 */
export function getDaysInMonth(date: Date): number {
    return (new Date(date.getFullYear(), date.getMonth() + 1 , 0)).getDate()
}

/**
 * Возвращает количество дней в квартале для указанной даты
 */
export function getDaysInQuarter(date: Date): number {
    if (getQuarter(date) === 1) {
        return isLeapYear(date) ? 90 : 91
    } else if (getQuarter(date) === 2) {
        return 91
    } else if (getQuarter(date) === 3) {
        return 92
    } else if (getQuarter(date) === 4) {
        return 92
    }
}

export function getDaysInYear(date: Date) {
    return isLeapYear(date) ? 366 : 365
}

/**
 * Проверяет, високосный ли год
 * @param date
 * @returns {boolean}
 */
export function isLeapYear(date: Date) {
    return (new Date(date.getFullYear(), 1, 29).getDate() === 29)
}

/**
 * Возвращает разницу между двумя датами без учета времени,
 * т.е. например для дней учитывается именно переход даты, а не полные 24 часа
 */
export function diffDateWithoutTime(date1: Date, date2: Date, unit = Units.DAYS, includeSign = false): number {
    let result: number
    if (unit === Units.DAYS) {
        result = Math.floor(date1.clone().clearTime().getTime() / millisecondsInDay) -
            Math.floor(date2.clone().clearTime().getTime() / millisecondsInDay)
    } else if (unit === Units.WEEKS) {
        result = Math.ceil((date1.clone().clearTime().getTime() / millisecondsInWeek) -
           (date2.clone().clearTime().getTime() / millisecondsInWeek))
    } else if (unit === Units.MONTHS) {
        result = date1.getMonth() - date2.getMonth() + (12 * (date1.getFullYear() - date2.getFullYear()))
    } else if (unit === Units.QUARTERS) {
        result = Math.round((date1.getMonth() - date2.getMonth() + (12 * (date1.getFullYear() - date2.getFullYear()))) / 3)
    } else if (unit === Units.YEARS) {
        result = inYears(date1, date2)
    }

    return includeSign ? result : Math.abs(result)
}

/**
 * Возвращает номер квартала переданной даты
 * @param date
 * @returns {number}
 */
export function getQuarter(date: Date): number {
    const month = date.getMonth()

    if ([0, 1, 2].includes(month)) {
        return 1
    } else if ([3, 4, 5].includes(month)) {
        return 2
    } else if ([6, 7, 8].includes(month)) {
        return 3
    } else if ([9, 10, 11].includes(month)) {
        return 4
    }
}

/**
 * Algorithm is to find nearest thursday, it's year
 * is the year of the week number. Then get weeks
 * between that date and the first day of that year.
 *
 * Note that dates in one year can be weeks of previous
 * or next year, overlap is up to 3 days.
 */
export function getWeekNumber(d: Date) {
    // Copy date so don't modify original
    const date = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
    // Get first day of year
    const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
    return Math.ceil(((( date.getTime() - yearStart.getTime() ) / 86400000) + 1) / 7);
}
