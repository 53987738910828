import * as React from "react"
import {observer} from "mobx-react"
import classNames from "classnames/bind"
import {CFormOwnErrors} from "src/bums/task/form/components/CFormOwnErrors"
import {CFieldRow, CInput, Component, CFlex} from "src/lib/components"
import {Form} from "src/lib/utils/form/form"
import {inject} from "src/lib/utils/inject"
import {Intl} from "src/lib/utils/intl/Intl"
import {InviteForm} from "./form/InviteForm"

export namespace CInviteForm {
   export interface Props {
       form: Form<InviteForm.Value>
       addEmptyRowHandler: () => void
   }
}

const libMessages = require("src/lib/messages.yml")
const style: any = classNames.bind(require("./styles/CInviteForm.styl"))

@observer
export class CInviteForm extends Component<CInviteForm.Props, {}> {

    @inject(Intl)
    private intl: Intl

    public render() {
        return <React.Fragment>
            <CFlex className={style("container")}>
                {this.props.form.renderValue("email", {key: "email"}, bind => (
                    <CFieldRow errors={bind.isDirty && bind.errors} bottomMargin>
                        <CInput
                            {...bind}
                            name={"email"}
                            className={style("formInput")}
                            placeholder="E-mail"
                            onFocus={this.props.addEmptyRowHandler}
                            status={this.props.form.ownErrors.filter(e => !!e.message).length ? "error" : "normal"}
                        />
                    </CFieldRow>
                ))}
                {this.props.form.renderValue("name", {key: "name"}, bind => (
                    <CFieldRow errors={bind.isDirty && bind.errors} bottomMargin>
                        <CInput
                            {...bind}
                            name={"name"}
                            className={style("formInput")}
                            placeholder={
                                this.intl.formatMessage(libMessages["firstName"]) + " " + this.intl.formatMessage(libMessages["lastName"])
                            }
                            onFocus={this.props.addEmptyRowHandler}
                        />
                    </CFieldRow>
                ))}
            </CFlex>
            <CFormOwnErrors form={this.props.form}/>
        </React.Fragment>
    }
}
