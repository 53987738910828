/* tslint:disable */
import * as H from "../metadataHelper"
import {ProductQuestItem} from "./productQuestItem"
import {IntegerField} from "./integerField"

export interface ProductQuest extends H.BaseEntity {
    id?: ProductQuest.Id;
    items?: H.List<ProductQuestItem>;
    itemsCount?: number;
    completedItemsCount?: number;
    uncompletedItemsCount?: number;
    contentType: "ProductQuest"; // Object type
}

export module ProductQuest {
    export const contentType: "ProductQuest" = "ProductQuest";
    export const endpoint = "/api/v3/productQuest";
    export type Id = "initial";
    export module Id {
        export const initial: Id = "initial";
    }
    export const newObject: ProductQuest = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const items = {contentType: "RefLinkField" as "RefLinkField", name: "items", isMultiple: true, isSortable: false, id: "ProductQuest.items", refContentType: H.List(["ProductQuestItem"])};
        export const itemsCount = {contentType: "IntegerField" as "IntegerField", name: "itemsCount", isMultiple: false, isSortable: false, id: "ProductQuest.itemsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const completedItemsCount = {contentType: "IntegerField" as "IntegerField", name: "completedItemsCount", isMultiple: false, isSortable: false, id: "ProductQuest.completedItemsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const uncompletedItemsCount = {contentType: "IntegerField" as "IntegerField", name: "uncompletedItemsCount", isMultiple: false, isSortable: false, id: "ProductQuest.uncompletedItemsCount", defaultValue: null as IntegerField["defaultValue"]};
    }
}

export function isProductQuest(arg: any): arg is ProductQuest {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "ProductQuest"
}
