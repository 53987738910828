/* tslint:disable */
import * as H from "../metadataHelper"
import {StringField} from "./stringField"

export interface ContractorStatus extends H.BaseEntity {
    name?: string; // Название
    id?: string; // Идентификатор
    contentType: "ContractorStatus"; // Object type
}

export module ContractorStatus {
    export const contentType: "ContractorStatus" = "ContractorStatus";
    export const endpoint = "/api/v3/contractorStatus";

    export const newObject: ContractorStatus = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "ContractorStatus.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
    }
}

export function isContractorStatus(arg: any): arg is ContractorStatus {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "ContractorStatus"
}
