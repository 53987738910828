import * as React from "react"
import classNames from "classnames/bind"
import {CIcon, CIconType, CIconSize, Component, CIconColor, CFlex} from "src/lib/components"

const style = classNames.bind(require("./CFileExtIcon.styl"))

type CFileExtIconProps = {
    extension: string
    size?: CIconSize,
    className?: string
    inCircle?: boolean
    extensionBgColor?: ExtensionBgColor // Требует inCircle === true
}

export enum ExtensionBgColor {
    GREEN,
    GREY
}

const extensionBgColorMap = {
    [ExtensionBgColor.GREEN]: "green",
    [ExtensionBgColor.GREY]: "grey"
}

interface MultiplyExtension {
    regexp: RegExp
    icon: CIconType
    color: CIconColor
}

const SupportedExtensionsMap: Array<MultiplyExtension> = [
    {regexp: /docx?/i, icon: CIconType.FILE_DOCUMENT, color: CIcon.Color.FILE_DOCUMENT},
    {regexp: /xlsx?/i, icon: CIconType.FILE_EXCEL, color: CIcon.Color.FILE_EXCEL},
    {regexp: /pptx?/i, icon: CIconType.FILE_PPOINT, color: CIcon.Color.FILE_PPOINT},
    {regexp: /(jpe?g|png|bmp|tiff)/i, icon: CIconType.FILE_IMAGE, color: CIcon.Color.FILE_IMAGE},
    {regexp: /(avi|mpe?g|flv|swf|divx|wmv|vob|MPEG\-4)/i, icon: CIconType.FILE_VIDEO, color: CIcon.Color.FILE_VIDEO},
    {regexp: /(mp3|cda|wav|wma|flac)/i, icon: CIconType.FILE_MUSIC, color: CIcon.Color.FILE_MUSIC},
    {regexp: /(zip|rar|7z)/i, icon: CIconType.FILE_ARCHIVE, color: CIcon.Color.FILE_ARCHIVE},
    {regexp: /pdf/i, icon: CIconType.FILE_PDF, color: CIcon.Color.FILE_PDF},
    {regexp: /gif/i, icon: CIconType.FILE_GIF, color: CIcon.Color.FILE_GIF},
    {regexp: /ai/i, icon: CIconType.FILE_AI, color: CIcon.Color.FILE_AI},
    {regexp: /psd/i, icon: CIconType.FILE_PSD, color: CIcon.Color.FILE_PSD},
    {regexp: /sketch/i, icon: CIconType.FILE_SKETCH, color: CIcon.Color.FILE_SKETCH},
]

function getIconByExtension(ext: string): CIconType {
    if (!ext) {
        return CIconType.FILE_UNKNOWN
    }

    for (let value of SupportedExtensionsMap) {
        if (value.regexp.test(ext)) {
            return value.icon
        }
    }
    return CIconType.FILE_UNKNOWN
}

function getColorByExtension(ext: string, inCircle: boolean): CIconColor {
    if (inCircle) {
        return CIcon.Color.LIGHT
    }

    if (!ext) {
        return CIcon.Color.FILE_UNKNOWN
    }

    for (let value of SupportedExtensionsMap) {
        if (value.regexp.test(ext)) {
            return value.color
        }
    }
    return CIcon.Color.FILE_UNKNOWN
}

export default class CFileExtIcon extends Component<CFileExtIconProps, {}> {
    public render() {
        const {inCircle} = this.props
        const backgroundColor = this.props.inCircle === true ? this.props.extensionBgColor : ExtensionBgColor.GREY

        return <CFlex.Item noShrink margin={"small"}>
            {inCircle
                ? <div className={style("roundExtIcon", extensionBgColorMap[backgroundColor])}>
                    <CIcon
                        type={getIconByExtension(this.props.extension)}
                        color={getColorByExtension(this.props.extension, inCircle)}
                        size={this.props.size}
                    />
                </div>
                : <CIcon
                    className={this.props.className}
                    type={getIconByExtension(this.props.extension)}
                    color={getColorByExtension(this.props.extension, inCircle)}
                    size={this.props.size}
                />
            }
        </CFlex.Item>
    }
}
