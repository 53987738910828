import * as React from "react"
import {observer} from "mobx-react"
import {autobind} from "core-decorators"
import {computed} from "mobx"
import {inject} from "src/lib/utils/inject"
import {ApiStore} from "src/lib/entities/store/ApiStore"
import {FeatureStore} from "src/bums/common/stores/FeatureStore"
import {CHint, Component, CStyledContent} from "src/lib/components"
import {Intl} from "src/lib/utils/intl/Intl"
import {PromoStore} from "src/bums/common/promo/stores/PromoStore"
import {CPromo} from "src/bums/common/promo/CPromoProps"
import {createVideochatButtonPromoSettings} from "src/bums/common/promo/CPromoPopup/videocallPromo"
import {PromoData} from "src/bums/common/promo/types"

const messages = require("../messages.yml")

export namespace CVideochatButton {
    export interface Props {
        button: JSX.Element
    }
}

export namespace CVideochatPromo {
    export interface Props extends CPromo.CPromoProps<null> {
        button: JSX.Element
    }
}

@observer
@autobind
export class CVideochatButton extends Component<CVideochatButton.Props, {}> {

    @inject(ApiStore)
    private apiStore: ApiStore

    @inject(FeatureStore)
    private featureStore: FeatureStore

    @inject(PromoStore)
    private promoStore: PromoStore

    private promoSettings: PromoData<null>

    constructor(props: CVideochatButton.Props, context: {}) {
        super(props, context)
        this.promoSettings = createVideochatButtonPromoSettings(this.apiStore)
    }

    @computed
    private get promoToShow() {
        if (
            this.featureStore.isAvailable(this.promoSettings.feature)
            && this.promoSettings.store.get().state === "fulfilled"
            && this.promoStore.needShowPromo(this.promoSettings)
        ) {
            return this.promoSettings
        }
    }

    render() {
        if (this.promoToShow) {
            const props = {
                actionDone: this.promoStore.actionDone(this.promoToShow.store),
                promoData: this.promoToShow,
                button: this.props.button
            }
            return React.createElement(this.promoToShow.component, props)
        }
        return this.props.button
    }
}

@observer
@autobind
export class CVideochatPromo extends Component<CVideochatPromo.Props, {}> {

    @inject(Intl)
    private intl: Intl

    private onClose() {
        this.props.actionDone()
    }

    render() {
        return <CHint
            open
            element={this.props.button}
            type="dark"
            verticalOrientation="bottom"
            onClose={this.onClose}
        >
            <CStyledContent>
                {this.intl.formatMessage(messages["pressToStartVideochat"])}
            </CStyledContent>
        </CHint>
    }
}

