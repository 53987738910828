import {Component} from "src/lib/components"
import * as React from "react"
import {observer} from "mobx-react"
import classNames from "classnames/bind"
import {shortNumber} from "src/lib/utils/intl/Intl"

const styles = classNames.bind(require("./CCounter.styl"));

export namespace CCounter {
    export interface Props {
        number?: number
        type?: Type
        position?: Position
        style?: React.CSSProperties
        title?: string
        className?: string
        onClick?: () => void
        hideNumber?: boolean
        numberFormatter?: (number: number) => string
    }
    export type Type = "default" | "warning" | "subitem" | "subitem_open" | "gray" | "green" | "orange" | "inactive"
    export type Position  =  "inline" | "top" | "none"
}

@observer
export class CCounter extends Component<CCounter.Props, {}> {

    public static Type: CCounter.Type
    public static Position: CCounter.Position

    public static defaultProps = {
        type: "default",
        position: "inline",
        hideNumber: false,
        numberFormatter: shortNumber
    }

    public render() {

        const {type, style, position, title, onClick, number, hideNumber, numberFormatter} = this.props
        const shortNumberInfo: number | string = numberFormatter(number)

        const className = styles(
            "counter",
            {
                ["counter_" + type]: Boolean(type),
                ["counter_" + position]: Boolean(position),
                hasOnClick: Boolean(onClick),
            },
            this.props.className
        )

        return number || hideNumber
            ? <div
                className={className}
                title={title}
                style={style}
                onClick={onClick}
            >
                {hideNumber ? null : shortNumberInfo}
            </div>
        : null
    }
}
