import {inject} from "src/lib/utils/inject"
import {Intl} from "src/lib/utils/intl"
import * as Api from "src/lib/entities/api"
import {Form} from "src/lib/utils/form/form"
import {ContractorHumanTypesStore} from "src/bums/crm/stores/ContractorHumanTypesStore"
import {HumanExtraFieldsStore} from "src/bums/common/stores/HumanExtraFieldsStore"
import {CompanyExtraFieldsStore} from "src/bums/common/stores/CompanyExtraFieldsStore"
import {ContractorCompanyTypesStore} from "src/bums/crm/stores/ContractorCompanyTypesStore"
import {EmployeeFormFactory} from "src/bums/common/form/EmployeeFormFactory"
import {ContractorHumanFormFactory} from "src/bums/crm/form/ContractorHumanForm"
import {ContractorCompanyFormFactory} from "src/bums/crm/form/ContractorCompanyForm"
import {ContractorFormFactoryImpl} from "./types"

export class ContractorFormFactory implements ContractorFormFactoryImpl {

    private readonly contractorHumanFormFactory: ContractorHumanFormFactory

    private readonly contractorCompanyFormFactory: ContractorCompanyFormFactory

    constructor(
        @inject(Intl) private intl: Intl,
        @inject(Api.Store) private apiStore: Api.Store,
        @inject(Api.UserStore) private userStore: Api.UserStore,
        @inject(ContractorHumanTypesStore) private contractorHumanTypesStore: ContractorHumanTypesStore,
        @inject(ContractorCompanyTypesStore) private contractorCompanyTypesStore: ContractorCompanyTypesStore,
        @inject(HumanExtraFieldsStore) private humanExtraFieldsStore: HumanExtraFieldsStore,
        @inject(CompanyExtraFieldsStore) private companyExtraFieldStore: CompanyExtraFieldsStore,
        @inject(EmployeeFormFactory) private employeeFormFactory: EmployeeFormFactory,
    ) {
        this.contractorHumanFormFactory = new ContractorHumanFormFactory(
            this.intl,
            this.apiStore,
            this.userStore,
            this.humanExtraFieldsStore,
            this.contractorHumanTypesStore,
            this,
            this.employeeFormFactory,
        )

        this.contractorCompanyFormFactory = new ContractorCompanyFormFactory(
            this.intl,
            this.apiStore,
            this.userStore,
            this.companyExtraFieldStore,
            this.contractorCompanyTypesStore,
            this,
            this.employeeFormFactory,
        )
    }

    public getContractorHumanFormFactory() {
        return this.contractorHumanFormFactory
    }

    public getContractorCompanyFormFactory() {
        return this.contractorCompanyFormFactory
    }

    public createFormForContactor(contractor: Api.Contractor) {
        if (contractor && contractor.id && !Api.isIdUnknown(contractor.id)) {
            return new Form<Api.Contractor>(() => contractor)
        }

        if (Api.isContractorHuman(contractor)) {
            return this.getContractorHumanFormFactory().createForm(contractor)
        }

        if (Api.isContractorCompany(contractor)) {
            return this.getContractorCompanyFormFactory().createForm(contractor)
        }
    }
}
