/* tslint:disable */
import * as H from "../metadataHelper"
import {StringField} from "./stringField"
import {BoolField} from "./boolField"

export interface InvitedEmployee extends H.BaseValue {
    firstName?: string;
    lastName?: string;
    mail?: string;
    rawString?: string;
    parsed?: boolean;
    contentType: "InvitedEmployee"; // Object type
}

export module InvitedEmployee {
    export const contentType: "InvitedEmployee" = "InvitedEmployee";
    export const endpoint = "/api/v3/invitedEmployee";

    export const newObject: InvitedEmployee = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const firstName = {contentType: "StringField" as "StringField", name: "firstName", isMultiple: false, isSortable: false, id: "InvitedEmployee.firstName", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const lastName = {contentType: "StringField" as "StringField", name: "lastName", isMultiple: false, isSortable: false, id: "InvitedEmployee.lastName", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const mail = {contentType: "StringField" as "StringField", name: "mail", isMultiple: false, isSortable: false, id: "InvitedEmployee.mail", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const rawString = {contentType: "StringField" as "StringField", name: "rawString", isMultiple: false, isSortable: false, id: "InvitedEmployee.rawString", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const parsed = {contentType: "BoolField" as "BoolField", name: "parsed", isMultiple: false, isSortable: false, id: "InvitedEmployee.parsed", defaultValue: null as BoolField["defaultValue"]};
    }
}

export function isInvitedEmployee(arg: any): arg is InvitedEmployee {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "InvitedEmployee"
}
