import * as React from "react"
import {CStyledContent, CLink} from "src/lib/components"
import {Tracker} from "src/bums/common/stores/Tracker"
import {bindArg} from "src/lib/utils/func"


// Переводы
import {FormattedMessage, FormattedMessageHtml} from "src/lib/utils/intl"
import {TaskHintIdsType} from "src/bums/common/onboarding/taskTutorial/hintsMap";

const messages = require("./messages.yml") as any


const buildVersionNames: {[index: string]: number} = {
    r1811: 1811,
    r1812: 1812
}



const TRACKER_PREFIX = "click_on_help_link_in_"

export const HintIdsType = {
    milestone: "milestone",
    taskActionsList: "taskActionsList",
    filterEdit: "filterEdit",
    listMode: "listMode",
    hierarchyMode: "hierarchyMode",
    diagramMode: "diagramMode",
    showWorkTimeButton: "showWorkTimeButton",
    taskFormFieldSelect: "taskFormFieldSelect",
    whomNotify: "whomNotify",
    nanoCard: "nanoCard",
    tagSelect: "tagSelect",
    fieldSelectorTable: "fieldSelectorTable",
    todoParser: "todoParser",
    journalSettings: "journalSettings",
    taskIsGroup: "taskIsGroup",
    mentionTask: "mentionTask",
    planned: "planned",
}


/* tslint:disable:max-line-length */
/* tslint:disable:no-cyrillic-in-string-literals */

export function onboardingHintsMap(tracker: Tracker): {
    [index: string]:
        {
            title: JSX.Element,
            content: JSX.Element,
            buildVersion: number | null | "oldOnly", // в случае подсказки для всех null
        }
} {
    return {
        [HintIdsType.milestone]: {
            "title": <FormattedMessage {...messages["milestoneTitle"]} />,
            "content": <React.Fragment>
                <CStyledContent>
                    <div>
                        <FormattedMessage {...messages["milestoneContent"]} />
                    </div>
                    <CLink
                        to="/help/article/tasks/work/mark"
                        target="_blank"
                        forceTarget
                        onClick={bindArg(tracker.trackEvent, "click", TRACKER_PREFIX + HintIdsType.milestone)}
                    >
                        <FormattedMessage {...messages["milestouneLink"]} />
                    </CLink>
                </CStyledContent>
            </React.Fragment>,
            buildVersion: buildVersionNames.r1811
        },
        [HintIdsType.taskActionsList]: {
            "title": <FormattedMessage {...messages["taskActionsListTitle"]} />,
            "content": <React.Fragment>
                <CStyledContent>
                    <div>
                        <FormattedMessage {...messages["taskActionsListContent"]} />
                    </div>
                    <CLink
                        to="/help/article/tasks/work/taskcard#карточка-задачи-основной-блок-задачи"
                        target="_blank"
                        forceTarget
                        onClick={bindArg(tracker.trackEvent, "click", TRACKER_PREFIX + HintIdsType.taskActionsList)}
                    >
                        <FormattedMessage {...messages["taskActionsListLink"]} />
                    </CLink>
                </CStyledContent>
            </React.Fragment>,
            buildVersion: buildVersionNames.r1811
        },
        [HintIdsType.filterEdit]: {
            "title": <FormattedMessage {...messages["filterEditTitle"]} />,
            "content": <React.Fragment>
                <CStyledContent>
                    <div>
                        <FormattedMessage {...messages["filterEditContent"]} />
                    </div>
                    <CLink
                        to="/help/article/tasks/list/filters"
                        target="_blank"
                        forceTarget
                        onClick={bindArg(tracker.trackEvent, "click", TRACKER_PREFIX + HintIdsType.filterEdit)}
                    >
                        <FormattedMessage {...messages["filterEditLink"]} />
                    </CLink>
                </CStyledContent>
            </React.Fragment>,
            "buildVersion": buildVersionNames.r1812,
        },
        [HintIdsType.listMode]: {
            "title": <FormattedMessage {...messages["listModeTitle"]} />,
            "content": <React.Fragment>
                <CStyledContent>
                    <FormattedMessage {...messages["listModeContent"]} />
                </CStyledContent>
            </React.Fragment>,
            "buildVersion": buildVersionNames.r1811,
        },
        [HintIdsType.hierarchyMode]: {
            "title": <FormattedMessage {...messages["hierarchyModeTitle"]} />,
            "content": <React.Fragment>
                <CStyledContent>
                    <FormattedMessageHtml {...messages["hierarchyModeContent"]} />
                </CStyledContent>
            </React.Fragment>,
            "buildVersion": buildVersionNames.r1811,
        },
        [HintIdsType.diagramMode]: {
            "title": <FormattedMessage {...messages["diagramModeTitle"]} />,
            "content": <React.Fragment>
                <CStyledContent>
                    <div>
                        <FormattedMessage {...messages["diagramModeContent"]} />
                    </div>
                    <CLink
                        to="/help/article/tasks/list/Gant"
                        target="_blank"
                        forceTarget
                        onClick={bindArg( tracker.trackEvent, "click", TRACKER_PREFIX + HintIdsType.diagramMode)}
                    >
                        <FormattedMessage {...messages["diagramModeLink"]} />
                    </CLink>
                </CStyledContent>
            </React.Fragment>,
            "buildVersion": buildVersionNames.r1811,
        },
        [HintIdsType.showWorkTimeButton]: {
            "title": <FormattedMessage {...messages["showWorkTimeButtonTitle"]} />,
            "content": <React.Fragment>
                <CStyledContent>
                    <FormattedMessage {...messages["showWorkTimeButtonContent"]} />
                </CStyledContent>
            </React.Fragment>,
            "buildVersion": buildVersionNames.r1811,
        },
        [HintIdsType.taskFormFieldSelect]: {
            "title": <FormattedMessage {...messages["taskFormFieldSelectTitle"]} />,
            "content": <React.Fragment>
                <CStyledContent>
                    <FormattedMessage {...messages["taskFormFieldSelectContent"]} />
                </CStyledContent>
            </React.Fragment>,
            "buildVersion": "oldOnly",
        },
        [HintIdsType.whomNotify]: {
            "title": <FormattedMessage {...messages["whomNotifyTitle"]} />,
            "content": <React.Fragment>
                <CStyledContent>
                    <FormattedMessage {...messages["whomNotifyContent"]} />
                </CStyledContent>
            </React.Fragment>,
            "buildVersion": null,
        },
        [HintIdsType.nanoCard]: {
            "title": <FormattedMessage {...messages["nanoCardTitle"]} />,
            "content": <React.Fragment>
                <CStyledContent>
                    <FormattedMessage {...messages["nanoCardContent"]} />
                </CStyledContent>
            </React.Fragment>,
            "buildVersion": buildVersionNames.r1812,
        },
        [HintIdsType.tagSelect]: {
            "title": <FormattedMessage {...messages["tagSelectTitle"]} />,
            "content": <React.Fragment>
                <CStyledContent>
                    <div>
                        <FormattedMessage {...messages["tagSelectContent"]} />
                    </div>
                    <CLink
                        to="/help/article/tasks/list/tags"
                        onClick={bindArg( tracker.trackEvent, "click", TRACKER_PREFIX + HintIdsType.tagSelect)}
                        target="_blank"
                        forceTarget
                    >
                        <FormattedMessage {...messages["tagSelectLink"]} />
                    </CLink>
                </CStyledContent>
            </React.Fragment>,
            "buildVersion": buildVersionNames.r1812,
        },
        [HintIdsType.fieldSelectorTable]: {
            "title": <FormattedMessage {...messages["fieldSelectorTableTitle"]} />,
            "content": <React.Fragment>
                <CStyledContent>
                    <FormattedMessage {...messages["fieldSelectorTableContent"]} />
                </CStyledContent>
            </React.Fragment>,
            "buildVersion": buildVersionNames.r1812,
        },
        [HintIdsType.todoParser]: {
            "title": <FormattedMessage {...messages["todoParserTitle"]} />,
            "content": <React.Fragment>
                <CStyledContent>
                    <div>
                        <FormattedMessage {...messages["todoParserContent"]} />
                    </div>
                    <CLink
                        to="/help/article/tasks/work/communication"
                        onClick={bindArg( tracker.trackEvent, "click", TRACKER_PREFIX + HintIdsType.todoParser)}
                        target="_blank"
                        forceTarget
                    >
                        <FormattedMessage {...messages["todoParserLink"]} />
                    </CLink>
                </CStyledContent>
            </React.Fragment>,
            "buildVersion": buildVersionNames.r1812,
        },
        [HintIdsType.taskIsGroup]: {
            "title": <FormattedMessage {...messages["taskIsGroupTitle"]} />,
            "content": <React.Fragment>
                <CStyledContent>
                    <div>
                        <FormattedMessage {...messages["taskIsGroupContent"]} />
                    </div>
                    <CLink
                        to="/help/article/tasks/newtask/masstask"
                        onClick={bindArg( tracker.trackEvent, "click", TRACKER_PREFIX + HintIdsType.taskIsGroup)}
                        target="_blank"
                        forceTarget
                    >
                        <FormattedMessage {...messages["taskIsGroupLink"]} />
                    </CLink>
                </CStyledContent>
            </React.Fragment>,
            "buildVersion": buildVersionNames.r1812,
        },
        [HintIdsType.journalSettings]: {
            "title": <FormattedMessage {...messages["journalSettingsTitle"]} />,
            "content": <React.Fragment>
                <CStyledContent>
                    <div>
                        <FormattedMessageHtml {...messages["journalSettingsContent"]} />
                    </div>
                    <CLink
                        to="/help/article/tasks/work/taskcard#карточка-задачи-комментирование-задачи"
                        onClick={bindArg( tracker.trackEvent, "click", TRACKER_PREFIX + HintIdsType.journalSettings)}
                        target="_blank"
                        forceTarget
                    >
                        <FormattedMessage {...messages["journalSettingsLink"]} />
                    </CLink>
                </CStyledContent>
            </React.Fragment>,
            "buildVersion": null,
        },

        [HintIdsType.mentionTask]: {
            "title": <FormattedMessage {...messages["mentionTaskTitle"]} />,
            "content": <React.Fragment>
                <div>
                    <CStyledContent>
                        <FormattedMessage {...messages["mentionTaskContent"]} />
                    </CStyledContent>
                </div>
                <CLink
                    to="/help/article/tasks/work/note"
                    onClick={bindArg( tracker.trackEvent, "click", TRACKER_PREFIX + HintIdsType.mentionTask)}
                    target="_blank"
                    forceTarget
                >
                    <FormattedMessage {...messages["mentionTaskLink"]} />
                </CLink>
            </React.Fragment>,
            "buildVersion": null,
        },
        [TaskHintIdsType.planned]: {
            "title": <FormattedMessage {...messages["plannedTitle"]} />,
            "content": <React.Fragment>
                <CStyledContent>
                    <div>
                        <FormattedMessage {...messages["plannedContent"]} />
                    </div>
                    <CLink
                        to="/help/article/tasks/work/time"
                        target="_blank"
                        forceTarget
                        onClick={tracker
                            ? bindArg(tracker.trackEvent, "click", TRACKER_PREFIX + TaskHintIdsType.actionButtons)
                            : void 0
                        }
                    >
                        <FormattedMessage {...messages["plannedLink"]} />
                    </CLink>
                </CStyledContent>
            </React.Fragment>,
            buildVersion: buildVersionNames.r1812
        },
    }
}
