import * as React from "react"
import {observer} from "mobx-react"
import {OnBoardingHintActionStore} from "src/bums/common/onboarding/store/OnBoardingHintActionStore"
import {Component, CHint, CSmartCard} from "src/lib/components"
import {inject} from "src/lib/utils/inject"
import {autobind} from "core-decorators";
import {observable, action, computed} from "mobx";
import {onboardingHintsMap} from "../onboardingHintsMap"
import {FeatureStore} from "src/bums/common/stores/FeatureStore";
import {Tracker} from "src/bums/common/stores/Tracker";

export namespace COnBoardHintContainer {
   export interface Props {
       hintId: string | Array<string>
       direction?: "horizontal" | "vertical"
       horizontalOrientation?: "left" | "right" // left - от правой границы элемента влево, right - от левой границы элемента вправо
       open?: boolean
       isFromCenter?: boolean
   }
}

declare const window: any

@observer
@autobind
export class COnBoardHintContainer extends Component<COnBoardHintContainer.Props, {}> {

    @inject(OnBoardingHintActionStore)
    private hintStore: OnBoardingHintActionStore

    @inject(Tracker)
    private tracker: Tracker

    @inject(FeatureStore)
    private featureStore: FeatureStore

    @observable
    open: boolean = this.props.open

    public static defaultProps = {
        direction: "vertical",
    }

    @computed
    private get isCurrentHint() {

        if (!this.hintStore.currentHint) {
            return false
        }

        return Array.isArray(this.props.hintId) && this.props.hintId.includes(this.hintStore.currentHint)
                || this.hintStore.currentHint === this.props.hintId
    }

    @action
    private onClick(event: React.MouseEvent<HTMLElement>) {
        if (this.props.children && React.isValidElement(this.props.children)) {
            const {onClick} = React.Children.only(
                this.props.children as React.ReactElement<{onClick: (event?: React.MouseEvent<HTMLElement>) => void}>
            ).props

            if (onClick) {
                onClick(event)
            }
        }

        if (Array.isArray(this.props.hintId)) {
            return
        }

        this.open = true
        this.hintStore.triggerAction(this.props.hintId)
    }

    @action
    private onClose() {
        this.hintStore.dismissHints()
        this.open = false
    }

    renderContent() {
        if (!this.hintStore.currentHint) {
            return
        }

        const description = onboardingHintsMap(this.tracker)[this.hintStore.currentHint]

        if (!description) {
            return null
        }
        return <CSmartCard animated={false}>
            {description.title
                && <CSmartCard.Header>
                    {description.title}
                </CSmartCard.Header>
            }
            <CSmartCard.Content>
                {description.content}
            </CSmartCard.Content>
        </CSmartCard>
    }

    public render() {
        //не показываем подсказки в браузерных тестах, если не получен стор или если выключена фича
        if (!this.hintStore.isReady || window.bt_mode || !this.featureStore.isAvailable("bums.common.onboarding_hint")) {
            return this.props.children
        }

        let element: JSX.Element

        if (React.Children.count(this.props.children) === 1 && React.isValidElement(this.props.children)) {
            element = React.cloneElement(
                React.Children.only(this.props.children as React.ReactElement<{onClick: (event?: React.MouseEvent<HTMLElement>) => void}>),
                {onClick: this.onClick}
            )
        } else {
            element = <div onClick={this.onClick}>{this.props.children}</div>
        }

        return <CHint
            element={element}
            open={this.isCurrentHint && this.open}
            onClose={this.onClose}
            type="dark"
            direction={this.props.direction}
            horizontalOrientation={this.props.horizontalOrientation}
            noStyle
            isFromCenter={this.props.isFromCenter}
        >
            {this.renderContent()}
        </CHint>
    }
}
