import * as React from "react"
import {observer} from "mobx-react"
import {Component} from "src/lib/components"
import {inject} from "src/lib/utils/inject"
import {PromoStore} from "src/bums/common/promo/stores/PromoStore"


@observer
export class CPromoContainer  extends Component<{}, {}> {
    @inject(PromoStore)
    private promoStore: PromoStore

    public render() {
        const promo = this.promoStore.currentPromo
        if (promo) {
            return React.createElement(
                promo.component,
                {
                    actionDone: this.promoStore.actionDone(promo.store),
                    promoData: promo
                }
            )
        }

        return null
    }
}
