import * as React from "react"
import classNames from "classnames/bind"
import {observer} from "mobx-react"
import DOM from "react-dom-factories"
import {CSpinner, Component} from "src/lib/components"

const style: any = classNames.bind(require("./CPagerButton.styl"))

interface CPagerButtonProps {
    onLoad: () => void
    isLoading?: boolean
    disabled?: boolean
    text: string
    className?: string
    noOffset?: boolean
}

@observer
export class CPagerButton extends Component<CPagerButtonProps, {}> {

    private clickHandler = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()

        if (!this.props.isLoading && !this.props.disabled) {
            this.props.onLoad()
        }
    }

    public render() {
        if (this.props.disabled) {
            return DOM.noscript()
        }
        return <div
            className={style(
                "wrapper",
                {
                    noOffset: this.props.noOffset,
                    loading: this.props.isLoading
                },
                this.props.className)
            }
            onClick={this.clickHandler}
        >
            {this.props.isLoading ? <CSpinner size={CSpinner.Size.SMALL}/> : this.props.text}
        </div>
    }
}
