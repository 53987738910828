import * as Collections from "src/lib/collections"
import * as Actions from "./actions/index"

export type State = Collections.Map<string, CardState>

const emptyState: State = Collections.Map()

interface CardState {
    instanceCount: number
    editableSection?: string
    unsavedValues?: Collections.Map<string, any>
    collapse?: boolean
    editMode?: boolean
    visiblePopup?: boolean
}

const emptyCardState: CardState = {
    instanceCount: 1,
    editableSection: null,
    unsavedValues: Collections.Map({}),
    editMode: false,
    visiblePopup: false,
}

function changeInstanceCount(state: CardState, diff: number): CardState {
    return {...state, instanceCount: state.instanceCount + diff}
}

export function reducer (state: State = emptyState, action: any) {

    if (Actions.isAddCardAction(action)) {
        if (state.has(action.payload)) {
            return state.set(action.payload, changeInstanceCount(state.get(action.payload), 1))
        } else {
            return state.set(action.payload, emptyCardState)
        }
    } else if (Actions.isRemoveCardAction(action)) {
        if (!state.has(action.payload)) {
            return state
        }
        if (state.get(action.payload).instanceCount > 1) {
            return state.set(action.payload, changeInstanceCount(state.get(action.payload), -1))
        } else {
            return state.remove(action.payload)
        }
    }

    if (!Actions.isAnyCardAction(action)) {
        return state
    }

    const {cardId} = action.meta
    const payload = action.payload

    if (!state.has(cardId)) {
        throw new Error(`There is not exists Card with id "${cardId}" in state`)
    }

    const instance = state.get(cardId)

    if (Actions.isRefreshCard(action)) {
        return state.set(cardId, emptyCardState)
    } else if (Actions.isSwitchCollapseAction(action)) {
        return state.update(
            cardId,
            (card) => Object.assign({}, card, {collapse: payload})
        )
    } else if (Actions.isSwitchEditModeAction(action)) {
        return state.update(
            cardId,
            (card) => Object.assign({}, card, {editMode: payload})
        )
    } else if (Actions.isUpdateUnsavedValueAction(action)) {
        const {field, value} = payload
        return state.update(
            cardId,
            (card) => Object.assign({}, card, {unsavedValues: instance.unsavedValues.set(field, value)})
        )
    } else if (Actions.isRemoveUnsavedValueAction(action)) {
        const {field} = payload
        return state.update(
            cardId,
            (card) => Object.assign({}, card, {unsavedValues: instance.unsavedValues.remove(field)})
        )
    } else if (Actions.isClearUnsavedValues(action)) {
        return state.update(
            cardId,
            (card) => Object.assign({}, card, {unsavedValues: Collections.Map()})
        )
    } else if (Actions.isShowPopupAction(action)) {
        return state.update(
            cardId,
            (card) => Object.assign({}, card, {visiblePopup: payload})
        )
    } else if (Actions.isSwitchEditableSectionAction(action)) {
        const editableSection = instance && instance.editableSection === payload ? null : payload
        return state.update(
            cardId,
            (card) => Object.assign({}, card, {editableSection})
        )
    }
}
