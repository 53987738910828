import {PromoData} from "src/bums/common/promo/types"
import {UserSettingStore} from "src/bums/common/stores/UserSettingStore"
import {ApiStore} from "src/lib/entities/store/ApiStore"
import {CPromoNewsContainer} from "src/bums/common/promo/CPromoNews/CPromoNewsContainer"
import {bindArg} from "src/lib/utils/func";

export const PROMO_NEWS_SHOWN = "bums.common.product_news_popup_shown"

declare global {
    interface Window {
        MegaplanPromoNewsShow: () => void
    }
}

export const createPromoNewsSettings = (apiStore: ApiStore): PromoData<null> => {
    const userSettingStore = new UserSettingStore<number>(
        apiStore,
        () => PROMO_NEWS_SHOWN,
        () => (new Date()).getTime()
    )

     window.MegaplanPromoNewsShow = bindArg(userSettingStore.set, 0)

    return {
        id: "bums.common.product_news_popup",
        feature: "bums.common.product_news_popup",
        component: CPromoNewsContainer,
        store: userSettingStore
    }
}

