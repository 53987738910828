/* tslint:disable */

import {BigIntField} from "./bums/bigIntField"
import {BoolField} from "./bums/boolField"
import {DateField} from "./bums/dateField"
import {DateTimeField} from "./bums/dateTimeField"
import {DurationField} from "./bums/durationField"
import {EnumField} from "./bums/enumField"
import {ExternalSourceField} from "./bums/externalSourceField"
import {FloatField} from "./bums/floatField"
import {IntegerField} from "./bums/integerField"
import {MoneyField} from "./bums/moneyField"
import {RefLinkField} from "./bums/refLinkField"
import {StringField} from "./bums/stringField"
import {RatingField} from "./bums/ratingField"
import {ContractorCompany, isContractorCompany} from "./bums/contractorCompany"
import {ContractorHuman, isContractorHuman} from "./bums/contractorHuman"
import {FilterTermEnum} from "./bums/filterTermEnum"
import {FilterTermNumber} from "./bums/filterTermNumber"
import {FilterTermRef} from "./bums/filterTermRef"
import {FilterTermString} from "./bums/filterTermString"
import {FilterTermDate} from "./bums/filterTermDate"
import {FilterTermBool} from "./bums/filterTermBool"
import {FilterTermFormula} from "./bums/filterTermFormula"
import {ActivityDesktopWidget} from "./bums/activityDesktopWidget"
import {CrmDesktopWidget} from "./bums/crmDesktopWidget"
import {InvoiceDesktopWidget} from "./bums/invoiceDesktopWidget"
import {ReportDesktopWidget} from "./bums/reportDesktopWidget"
import {ExampleDesktopWidget} from "./bums/exampleDesktopWidget"
import {EmployeeBirthdayDesktopWidget} from "./bums/employeeBirthdayDesktopWidget"
import {TaskDesktopWidget} from "./bums/taskDesktopWidget"
import {TodoDesktopWidget} from "./bums/todoDesktopWidget"
import {DealDesktopWidget} from "./bums/dealDesktopWidget"
import {EmployeeEffectivenessDesktopWidget} from "./bums/employeeEffectivenessDesktopWidget"
import {Comment} from "./bums/comment"
import {Message} from "./bums/message"
import {Changeset} from "./bums/changeset"
import {BasedOnHistory} from "./bums/basedOnHistory"
import {TriggerLogSet} from "./bums/triggerLogSet"
import {SendingLog} from "./bums/sendingLog"
import {LoyaltyActionLogSet} from "./bums/loyaltyActionLogSet"

export type ExtraField =
    BigIntField
    | BoolField
    | DateField
    | DateTimeField
    | DurationField
    | EnumField
    | ExternalSourceField
    | FloatField
    | IntegerField
    | MoneyField
    | RefLinkField
    | StringField
    | RatingField

export type Contractor = ContractorCompany | ContractorHuman

export function isContractor(arg: any): arg is Contractor {
    return isContractorCompany(arg) || isContractorHuman(arg)
}

export type FilterTerm =
    FilterTermEnum
    | FilterTermNumber
    | FilterTermRef
    | FilterTermString
    | FilterTermDate
    | FilterTermBool
    | FilterTermFormula

export type BaseDesktopWidget =
    ActivityDesktopWidget
    | CrmDesktopWidget
    | InvoiceDesktopWidget
    | ReportDesktopWidget
    | ExampleDesktopWidget
    | EmployeeBirthdayDesktopWidget
    | TaskDesktopWidget
    | TodoDesktopWidget
    | DealDesktopWidget
    | EmployeeEffectivenessDesktopWidget

export type CommentEntity =
    Comment
    | Message
    | Changeset
    | BasedOnHistory<any, any>
    | TriggerLogSet
    | SendingLog
    | LoyaltyActionLogSet
