import * as React from "react"
import {observer} from "mobx-react"
import {computed} from "mobx"
import * as Api from "src/lib/entities/api"
import classNames from "classnames/bind"
import {CUrlify, CContactInfo, Component, CEllipsis} from "src/lib/components";
import * as Url from "src/lib/utils/url"

const style = classNames.bind(require("./CLink.styl"));

export namespace CLink {
    export interface Props {
        to?: string | Api.BaseEntity | Api.CallInfo<any>
        onClick?: React.MouseEventHandler<HTMLElement>
        onMouseDown?: React.MouseEventHandler<HTMLElement>
        onIntegrationWidgetMouseDown?: React.MouseEventHandler<HTMLElement>
        title?: string
        type?: "primary" | "secondary" | "breadcrumbs",
        className?: string,
        target?: "_blank" | "_self" | "_parent" | "_top"
        forceTarget?: boolean
        isEllipsis?: boolean
        style?: React.CSSProperties
        //для интеграции с телефонией на обычном js
        "data-phone"?: string
        "data-item"?: string
        "data-client"?: string
        "data-employee"?: string
        "data-alias"?: string
    }
}

declare var window: {
    internal_link_target_self: boolean
}

/**
 * оформление ссылок
 */
@observer
export class CLink extends Component<CLink.Props, {}> {

    public static defaultProps = {
        type: "primary"
    }

    @computed
    private get target() {
        const {to, target, forceTarget} = this.props

        if (target == null || (window.internal_link_target_self && !forceTarget)) {
            const href = typeof to === "string" ? to : Url.generateUrlByEntity(to)
            return href && (Url.hasInternalLink(href) || href.includes("#")) ? "_self" : "_blank"
        }

        return target
    }

    public render() {
        const {onClick, to, title, className, isEllipsis, type, onMouseDown} = this.props

        const dataPhone = this.props["data-phone"]

        const link = <a
            className={style("CLink", this.props.type, className)}
            onClick={onClick}
            onMouseDown={onMouseDown}
            title={title}
            target={this.target}
            style={this.props.style}
            data-phone={dataPhone}
            data-item={this.props["data-item"]}
            data-client={this.props["data-client"]}
            data-employee={this.props["data-employee"]}
            data-alias={this.props["data-alias"]}
        >
            {this.props.children}
        </a>

        const linkBlock = Api.isContactInfo(to)
            ? <CContactInfo to={to} onIntegrationWidgetMouseDown={this.props.onIntegrationWidgetMouseDown}>
                {link}
            </CContactInfo>
            : <CUrlify to={to}>
                {link}
            </CUrlify>

        return isEllipsis
            ? <CEllipsis linkColor={type === "primary"}>
                {linkBlock}
            </CEllipsis>
            : linkBlock
    }
}
