import * as React from "react"
import {observer} from "mobx-react"
import {CPromo} from "src/bums/common/promo/CPromoProps"
import {RatingStore} from "src/bums/common/promo/stores/RatingStore"
import {Tracker} from "src/bums/common/stores/Tracker"
import {Component} from "src/lib/components"
import {UserStore} from "src/lib/entities/store/UserStore"
import {inject} from "src/lib/utils/inject"
import {RatingParams} from "src/bums/common/promo/CRating/sept2019"
import {ChannelTracker} from "src/bums/common/stores/Tracker/ChannelTracker"
import {CRatingStepComponent} from "src/bums/common/promo/CRating/types"

export namespace CRatingContainer {
    export interface ContainerProps {
        ratingType: string,
        ratingTag?: string,
        customFinalStep?: CRatingStepComponent
    }
    export interface Props extends CPromo.CPromoProps<RatingParams>, ContainerProps {
    }
}

const LazyLoadedRating = React.lazy(() => import("./CRating"))

const noRender = () => null as any

@observer
export class CRatingContainer extends Component<CRatingContainer.Props, {}> {

    @inject(Tracker)
    private tracker: Tracker

    @inject(UserStore)
    private currentUserStore: UserStore

    private $ratingStore: RatingStore

    public static injectProps = (injectedProps: CRatingContainer.ContainerProps) => {
        return (props: CPromo.CPromoProps<RatingParams>) => <CRatingContainer {...injectedProps} {...props} />
    }

    private get ratingStore() {
        const {ratingType, ratingTag} = this.props
        if (!this.$ratingStore) {
            this.$ratingStore = new RatingStore(
                new ChannelTracker(this.tracker, ["rating"]),
                {
                    type: ratingType,
                    tag: ratingTag || this.props.promoData.id,
                    userIsDirector: this.currentUserStore.isAdmin,
                    userPosition: this.currentUserStore.user.position
                }
            )
        }
        return this.$ratingStore
    }

    public render() {
        return <React.Suspense fallback={noRender}>
            <LazyLoadedRating
                ratingStore={this.ratingStore}
                actionDone={this.props.actionDone}
                promoData={this.props.promoData}
                customFinalStep={this.props.customFinalStep}
            />
        </React.Suspense>
    }
}
