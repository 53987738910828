import {Action} from "redux-actions"
import {LOAD} from "redux-storage"

import {LoadState} from "src/lib/utils/loadState"

export type State = LoadState;

const initialState: State = LoadState.Pending();

export function reducer(state = initialState, action: Action<void> = null): State {
    if (action && action.type === LOAD) {
        return LoadState.Completed();
    } else {
        return state;
    }
}
