import Bluebird from "bluebird"
import {
    observable, action, runInAction, autorun, untracked
} from "mobx"
import {
    RequestOptions, ApiResponse, BaseEntity, BaseValue, isBaseValue, isBaseEntity,
} from "../types"
import {
    ApiCall,
    BulkApiCall,
    isUserSetting,
    TreeNode,
    isTask
} from "src/lib/entities/api"
import {TransportLayer, PreloadListDescriptor, UpdateEvent} from "./types"
import {ObservableListClass} from "src/lib/collections/ListClass"
import {List, isList} from "src/lib/collections"
import {
    getLink, getListName, getGID, getFetchNamespace, getEntityTypeEndpoint, getListEndpoint,
    getNextUnknownId, getFetchNameInSet, isIdUnknown, isEntityEquals, prepareEntityToSave,
    getEntityFetchState,
} from "../utils"
import {PromisesStorage} from "../promiseStorage"
import {LoadState} from "src/lib/utils/loadState"
import {LockStore} from "src/lib/utils/LockStore"
import {waitFor} from "src/lib/utils/waitFor"
import {FetchError, RequestInit, Response} from "src/lib/utils/fetch"
import {isEqual, throttle, noop} from "lodash"
import {realFetch} from "./transport/fetchTransport"
import {generateHash, ListStore} from "./ListStore"
import {EntitiesList} from "./EntitiesList"

import {autobind} from "core-decorators"
import {getJsonProperty} from "src/lib/entities/store/JsonProperty"
import {IdentityMap} from "src/lib/entities/store/IdentityMap"
import {MetadataProvider} from "src/lib/entities/metadataProvider"
import {ApiErrorSubscriber} from "src/bums/common/stores/ApiErrorSubscriber"
import {isCacheableRequest} from "src/lib/utils/etag"
import {PromiseBasedObservable} from "src/lib/utils/fromPromise"
import {NopTransport, Tracker} from "src/bums/common/stores/Tracker"
import {EntitiesNullStorage} from "src/lib/entities/store/EntitiesStorage/EntitiesNullStorage"
import {EntitiesStorageInterface} from "src/lib/entities/store/EntitiesStorage/EntitiesStorageInterface"

export {realFetch} from "./transport/fetchTransport"

export const FETCH_FULL_NAME = "fetchFull"
const DEFAULT_UPDATE_TIMEOUT = 10000
const UPDATE_ENTITIES_LIMIT = 100
const STORE_USER_SETTINGS_IN_LOCAL_STORAGE = false;

const FULL_ENTITY_TYPES = new Set<string>([
    "UserInterfaceLayout",
    "UserSetting"
])

// TODO Вместо promisesStorage следует использовать LockStore.
// TODO Вместо fetchName + namespace использовать массив fetchName
// TODO Перейти на реальные классы сущностей вместо интерефейсов.

export function defineOrGetFetchStates(entity: BaseEntity): Map<string, LoadState> {
    if (!entity._fetchStates) {
        Object.defineProperty(entity, "_fetchStates", {
            value: observable.map<string, LoadState>([], {deep: false}),
            writable: true,
            configurable: true,
        })
    }

    return entity._fetchStates
}

function defineOrGetFieldValueVersions(entity: BaseEntity): Map<string, number> {
    if (!entity._fieldValueVersions) {
        Object.defineProperty(entity, "_fieldValueVersions", {
            value: observable.map<string, number>([], {deep: false}),
            writable: true,
            configurable: true,
        })
    }

    return entity._fieldValueVersions
}

function defineOrGetVerion(entity: BaseEntity): number {
    if (!entity._version) {
        Object.defineProperty(entity, "_version", {
            value: 0,
            writable: true,
            configurable: true,
        })
    }

    return entity._version
}

interface NewEntityToListPusher {
    push(entity: BaseEntity): Promise<void>
}

@autobind
export class ApiStore {
    /**
     * Unsaved entities storage. Indexed by GID
     * @type {ObservableMap<any>|ObservableMap<BaseEntity>}
     */
    @observable
    public unsavedEntities = observable.map<string, BaseEntity>([], {deep: false});

    private transport: TransportLayer
    private noUnsavedPromise: Bluebird<{}>;
    private noUnsavedPromiseResolver: () => void = noop;

    private promisesStorage: PromisesStorage;
    private identityMap: IdentityMap;
    private readonlyEmptyEntitiesList: EntitiesList.ReadonlyEntitiesList;
    private entitiesStorage: EntitiesStorageInterface
    // очередь на обновление сущностей в одном запросе, ключи - GID, для уникальности
    private lazyFetchEntitiesStack = new Map<string, BaseEntity>()

    private tracker = new Tracker(new NopTransport())

    private locale: string | void
    public defaultLimit = 25

    constructor(
        transport: TransportLayer = realFetch,
        private lockStore: LockStore = new LockStore(),
        private newEntityToListPusher: NewEntityToListPusher = { push: async () => void 0 },
        private metadataProvider: MetadataProvider = new MetadataProvider(),
        private apiErrorSubscriber: ApiErrorSubscriber = new ApiErrorSubscriber(),
    ) {
        this.promisesStorage = new PromisesStorage(this.lockStore);
        this.identityMap = new IdentityMap(this.metadataProvider);
        this.readonlyEmptyEntitiesList = new EntitiesList.NoneObservableEntitiesList()
        this.noUnsavedPromise = Bluebird.resolve({})
        this.entitiesStorage = new EntitiesNullStorage()

        autorun (() => {
            if (Array.from(this.unsavedEntities.values()).length && !this.noUnsavedPromise.isPending()) {
                this.noUnsavedPromise = new Bluebird((resolve) => {
                    this.noUnsavedPromiseResolver = resolve
                })
                return
            }

            if (Array.from(this.unsavedEntities.values()).length === 0) {
                this.noUnsavedPromiseResolver()
            }
        })

        this.setTransport(transport)
    }

    @action
    private async onUpdate(data: UpdateEvent) {
        const entities = data.response.data
        const {pagination} = data.response.meta

        await this.updateEntitiesFromJson(entities)

        let endpoint = data.url
        const parts = endpoint.split("?")

        let paramsString = ""
        let params: any = {}

        if (parts.length > 1) {
            paramsString += parts.slice(1).reduce((val, p) => {
                return val += p
            }, "")
        }

        try {
            params = JSON.parse(decodeURIComponent(paramsString))
            endpoint = parts[0]
        } catch (e) {
            // noop
        } finally {

            delete params.limit
            delete params.pageAfter
            delete params.pageBefore
            delete params.pageWith

            const hash = generateHash(endpoint, params)

            this.identityMap.getLists().forEach((list, key) => {
                if (
                    list.loadedEndpoint
                    && hash === generateHash(list.loadedEndpoint, list.loadedWithOptions)
                ) {
                    if (!pagination.hasMorePrev) {
                        this.resetList(key, {
                            items: entities,
                            totalItemsCount: Array.isArray(entities) ? pagination.count : 1
                        })
                    } else {
                        if (this.getLists().has(key)) {
                            list.totalItemsCount = Array.isArray(entities) ? pagination.count : 1
                            list.loadStatePrev = LoadState.Completed()
                            list.loadStateNext = LoadState.Completed()
                            list.invalidatedItems = null

                            this.getLists().set(key, list)
                        }
                    }
                }
            })
        }

    }

    setLimit(limit: number) {
        this.defaultLimit = limit
    }

    setTransport(transport: TransportLayer) {
        this.transport = transport
        this.transport.on({
            update: this.onUpdate
        })
    }

    setTracker(tracker: Tracker) {
        this.tracker = tracker
    }

    setNewEntityToListPusher(newEntityToListPusherService: NewEntityToListPusher) {
        this.newEntityToListPusher = newEntityToListPusherService
    }

    setEntitiesStorage(entitiesStorage: EntitiesStorageInterface) {
        this.entitiesStorage = entitiesStorage
    }

    public setLocale(newLocale: string | null) {
        this.locale = newLocale
    }

    async clearStorage() {
        await this.entitiesStorage.clear()
    }

    async updateEntitiesStorage(entities: BaseEntity[]) {
        await this.entitiesStorage.save(entities)
    }

    private compressEntitiesList(entities: BaseEntity[]): any {
        const result = []
        let currentType = null
        let ids: string[] = []

        for (let entity of entities) {
            if (!isBaseEntity(entity) || !entity.id || isIdUnknown(entity.id)) {
                return entities
            }

            if (currentType === entity.contentType) {
                ids.push(entity.id)
            } else if (ids.length > 0) {
                if (ids.length === 1) {
                    result.push({contentType: currentType, id: ids[0]})
                } else {
                    result.push({contentType: "@chunk", ids: ids, type: currentType})
                }
                currentType = entity.contentType
                ids = [entity.id]
            } else {
                currentType = entity.contentType
                ids = [entity.id]
            }
        }

        if (ids.length === 1) {
            result.push({contentType: currentType, id: ids[0]})
        } else if (ids.length > 1) {
            result.push({contentType: "@chunk", ids: ids, type: currentType})
        }

        return result
    }

    private compress(entities: any): any {
        if (null != entities && typeof entities === "object") {
            if (typeof entities[Symbol.iterator] === "function") {
                if (entities.length > 5) {
                    const result = this.compressEntitiesList(entities)

                    if (result.length) {
                        return result
                    }
                } else {
                    return [...entities].map(this.compress)
                }
            } else {
                const target: any = {}
                for (const key in entities) {
                    if (entities.hasOwnProperty(key)) {
                        target[key] = this.compress(entities[key])
                    }
                }

                return target
            }
        }

        return entities
    }

    fetch<V>(
        url: string,
        init: RequestInit = {},
        prepareToSave = true,
    ): Bluebird<Response<ApiResponse<V>>> {
        if (init && init.queryParams) {
            init.queryParams = this.compress(prepareEntityToSave(init.queryParams))
        }
        if (init && isBaseValue(init.bodyEntity) && prepareToSave) {
            init.bodyEntity = this.compress(prepareEntityToSave(init.bodyEntity))
        }
        if (init && this.locale) {
            // прокидываем текущий язык в заголовки в целях управления кешированием
            init.headers = {...init.headers, "Accept-Language": this.locale}
        }

        return this.transport.fetch<V>(url, init).catch(e => {
            if (this.apiErrorSubscriber) {
                this.apiErrorSubscriber.handleErrorResponse(e)
            }
            throw e
        });
    }

    @action
    fetchList(
        listName: string,
        endpoint: string,
        options: RequestOptions = {},
        softOptions: RequestOptions = {},
        direction: "Next" | "Prev" = "Next",
        forceOwerwrite = false
    ): Bluebird<BaseEntity[]> {
        this.tracker.trackTiming("fetchList", listName)
        // Предотвращаем мутации объектов
        options = {...options}
        softOptions = {...softOptions}
        // Нормализуем опции. limit должен быть всегда в softOptions
        // TODO Избавиться от softOptions!
        if (options["limit"]) {
            softOptions["limit"] = options["limit"]
            delete options["limit"]
        }
        // TODO this.promisesStorage.after должен вызывать коллбек синхронно, чтобы не писать такую фигни!
        // Создаём список и меняем ему fetchState синхронно, если его ещё не было.
        if (!this.getLists().has(listName)) {
            const list = new EntitiesList();
            this.getLists().set(listName, list);
            list.loadedEndpoint = endpoint;
            list.loadedWithOptions = options;
        }
        {
            // Синхронно обновляем fetchState у списка, до получения блокировки.
            const list = this.getLists().get(listName);

            const overwrite = (list.loadedEndpoint !== void 0 && list.loadedEndpoint !== endpoint ) ||
                    !isEqual(options, list.loadedWithOptions) || forceOwerwrite

            if (overwrite) {
                list.invalidatedItems = list.invalidatedItems
                    ? list.invalidatedItems
                    : list.items.length > 0 ? list.items.slice() : void 0
                list.invalidatedTotalItemsCount = list.invalidatedTotalItemsCount || list.totalItemsCount
            }

            if (direction === "Next") {
                list.loadStateNext = LoadState.Pending();
            } else {
                list.loadStatePrev = LoadState.Pending();
            }
        }
        // Остальную работу делаем внутри критической секции.
        return this.promisesStorage.after(listName + direction, () => runInAction(
            "fetchList.promisesStorage.after",
            () => {
                if (!this.getLists().has(listName)) {
                    const _list = new EntitiesList();
                    this.getLists().set(listName, _list);
                    _list.loadedEndpoint = endpoint;
                    _list.loadedWithOptions = options;
                }
                const list = this.getLists().get(listName);
                // show we overwrite (reload) list
                // let pageWith = options["pageWith"];
                // delete options["pageWith"]
                let overwrite = (list.loadedEndpoint !== void 0 && list.loadedEndpoint !== endpoint ) ||
                    !isEqual(options, list.loadedWithOptions) || forceOwerwrite

                // until https://youtrack.jetbrains.com/issue/WEB-19365 resolved
                //noinspection TypeScriptValidateTypes
                if (overwrite) {
                    list.invalidatedItems = list.invalidatedItems
                        ? list.invalidatedItems
                        : list.items.length > 0 ? list.items.slice() : void 0
                    list.items.splice(0, list.items.length)
                    list.invalidatedTotalItemsCount = list.invalidatedTotalItemsCount || list.totalItemsCount
                    list.totalItemsCount = void 0
                }
                if (direction === "Next") {
                    list.loadStateNext = LoadState.Pending();
                } else {
                    list.loadStatePrev = LoadState.Pending();
                }
                list.loadedWithOptions = options;
                list.loadedEndpoint = endpoint;

                let paginationOptions: {pageAfter: BaseEntity} | {pageBefore: BaseEntity} | {pageWith: BaseEntity}

                if (!overwrite && direction === "Next" && list.items.length > 0) {
                    paginationOptions = {pageAfter: getLink(list.items.last())}
                } else if (!overwrite && direction === "Prev" && list.items.length > 0) {
                    paginationOptions = {pageBefore: getLink(list.items.first())}
                } // TODO work with pageWith

                // until https://youtrack.jetbrains.com/issue/WEB-19365 resolved
                //noinspection TypeScriptValidateTypes
                const requestInit = {
                    queryParams: Object.assign({}, options, softOptions, paginationOptions),
                }
                return this.fetchEntities(endpoint, requestInit).then(response => runInAction(
                    "fetchList.fetchEntities.then",
                    () => {
                        const pagination = response.value.meta.pagination || {count: 0, hasMoreNext: false, hasMorePrev: false}

                        list.totalItemsCount = pagination.count

                        const hasPageWith = options["pageWith"] || softOptions["pageWith"]

                        if (hasPageWith || forceOwerwrite) {
                            list.hasMoreNext = pagination.hasMoreNext
                            list.hasMorePrev = pagination.hasMorePrev
                        }

                        if (direction === "Next") {
                            this.doAppendEntities(listName, response.value.data)
                            list.loadStateNext = response.cached ? LoadState.Pending() : LoadState.Completed()
                            list.hasMoreNext = pagination.hasMoreNext
                        } else {
                            this.doPrependEntities(listName, response.value.data)
                            list.loadStatePrev = response.cached ? LoadState.Pending() : LoadState.Completed()
                            list.hasMorePrev = pagination.hasMorePrev
                        }

                        list.invalidatedItems = void 0
                        list.invalidatedTotalItemsCount = void 0
                        list.totalItemsCount = pagination.count

                        this.tracker.trackTimingEnd("fetchList", listName)
                        return response.value.data
                    }
                )).catch(error => runInAction(
                    "fetchList.fetchEntities.catch",
                    () => {
                        if (error instanceof FetchError) {
                            if (direction === "Next") {
                                list.loadStateNext = LoadState.Error(error.response.value);
                            } else {
                                list.loadStatePrev = LoadState.Error(error.response.value);
                            }
                        }

                        console.error(error) // иначе исключение проглатывается в mobx.module.js::executeAction
                        throw error // keep promise rejected
                    }
                ))
            }
        ))
    }

    @action
    async fetchFullEntity<T extends BaseEntity>(
        entity: T,
        endpoint: string = getEntityTypeEndpoint(entity)
    ): Promise<T> {
        if (!entity.id) {
            throw new Error("You can not fetch full entity without id")
        }
        this.tracker.trackTiming("fetchFullEntity", entity.contentType)
        await this.updateEntitiesFromJson(entity);
        const response = await this.entityBasedPromise(
            entity,
            FETCH_FULL_NAME,
            () => this.fetchEntities<T>(
                `${endpoint}/${entity.id}`,
                {method: "GET"}
            )
        )
        this.tracker.trackTimingEnd("fetchFullEntity", entity.contentType)
        return response.value.data[0]
    }

    @action
    addEntityToList = (
        listName: string,
        entity: BaseEntity,
        endpoint: string,
        fetchName = "update",
        requestEntity?: BaseValue,
        prependEntity?: boolean,
        saveEntity?: boolean,
    ): Bluebird<BaseEntity> => Bluebird.try(async () => {
        let entityWasRegistered = false;

        if (!entity.id) {
            entity = this.registerNewEntity(entity)
            entityWasRegistered = true
        }

        const realEntityToAdd = this.getEntity(entity, null)

        if (!realEntityToAdd) {
            throw new Error(`Entity to add should be registered in ApiStore! ${getGID(entity)} is not registered yet`)
        }

        const fetchStates = defineOrGetFetchStates(realEntityToAdd)
        const [oldValues, newVersion] = await this.applyOptimisticUpdate(entity)
        runInAction(() => fetchStates.set(fetchName, LoadState.Pending()))

        if (prependEntity) {
            await this.listPrependEntities(listName, [entity])
        } else {
            await this.listAppendEntities(listName, [entity])
        }

        return this._fetchEntities(
            endpoint,
            {
                method: "POST",
                bodyEntity: requestEntity ? requestEntity : entity
            },
            entities => {
                if (entities.length > 0 && entity.id && isIdUnknown(entity.id)) {
                    this.registerEntitySave(entity, entities[0])
                }
            },
            newVersion
        ).then(response => runInAction("addEntityToSet.entityBasedPromise.then", () => {
            fetchStates.set(fetchName, LoadState.Completed());
            return response.value.data[0];
        })).catch(e => runInAction("addEntityToSet.entityBasedPromise.catch", async () => {
            fetchStates.set(fetchName, LoadState.Error(e))

            if (!saveEntity) {
                this.listRemoveEntities(listName, [entity])
                await this.rollbackEntity(entity, entityWasRegistered, oldValues)
            }

            throw e
        }))
    })


    @action
    addEntityToSet = (
        entityToAddTo: BaseEntity,
        entityToAdd: BaseEntity,
        fieldToAddTo: string,
        endpoint: string = getListEndpoint(entityToAddTo, fieldToAddTo),
        requestEntity?: BaseValue,
        prependEntity?: boolean
    ): Bluebird<BaseEntity> => Bluebird.try(async () => {

        if (entityToAddTo.id) {
            entityToAddTo = this.getEntity(entityToAddTo)
        } else {
            throw new Error("Can not add to unsaved entity!");
        }

        const fetchName = getFetchNameInSet(entityToAddTo, fieldToAddTo)
        const listName = getListName(entityToAddTo, fieldToAddTo)

        if (!this.getLists().has(listName) && ((entityToAddTo as any)[fieldToAddTo] instanceof ObservableListClass)) {
            const list = new EntitiesList()
            list.items = (entityToAddTo as any)[fieldToAddTo]
            this.getLists().set(listName, list)
        }

        const release = await this.lockStore.get(fetchName).exclusive()

        return this.entityBasedPromise(
            entityToAddTo,
            "update",
            () => this.addEntityToList(listName, entityToAdd, endpoint, fetchName, requestEntity, prependEntity)
        ).finally(release)
    })

    @action
    deleteEntityFromSet(
        entityToDeleteFrom: BaseEntity,
        entityToDelete: BaseEntity,
        fieldToDeleteFrom: string,
        endpoint: string = getListEndpoint(entityToDeleteFrom, fieldToDeleteFrom) +
            "/" + entityToDelete.contentType + "/" + entityToDelete.id
    ): Bluebird<void> {
        if (entityToDeleteFrom.id) {
            entityToDeleteFrom = this.getEntity(entityToDeleteFrom)
        } else {
            entityToDeleteFrom = this.registerNewEntity(entityToDeleteFrom)
        }
        if (entityToDelete.id) {
            entityToDelete = this.getEntity(entityToDelete)
        } else {
            entityToDelete = this.registerNewEntity(entityToDelete)
        }
        const fetchName = getFetchNameInSet(entityToDeleteFrom, fieldToDeleteFrom)
        defineOrGetFetchStates(entityToDelete).set(fetchName, LoadState.Pending())
        return this.entityBasedFetch(
            entityToDeleteFrom,
            "update",
            endpoint,
            {method: "DELETE"}
        ).then(response => runInAction("deleteEntityFromSet.entityBasedPromise.then", () => {
            defineOrGetFetchStates(entityToDelete).set(fetchName, LoadState.Completed());
            const listName = getListName(entityToDeleteFrom, fieldToDeleteFrom)
            this.listRemoveEntities(listName, [entityToDelete]);
        })).catch(e => runInAction("deleteEntityFromSet.entityBasedPromise.catch", () => {
            defineOrGetFetchStates(entityToDelete).set(fetchName, LoadState.Error(e));
            throw e
        }))
    }

    @action
    deleteEntity(
        entity: BaseEntity,
        endpoint: string = getEntityTypeEndpoint(entity)
    ): Bluebird<void> {
        entity = this.getEntity(entity);

        if (isIdUnknown(entity.id)) {
            return Bluebird.try(() => {
                this.unsavedEntities.delete(getGID(entity))
            })
        }

        return this.entityBasedFetch(
            entity,
            "delete",
            `${endpoint}/${entity.id}`,
            {method: "DELETE"}
        ).then(response => runInAction("deleteEntity.entityBasedFetch.then", () => this.deleteEntityFromAllLists([entity])))
    }

    @action
    deleteEntityFromAllLists(entities: BaseEntity[]) {
        const entitiesMap = this.getEntities()

        entities.forEach(entity => {
            if (entity.id && entitiesMap.has(entity.contentType)) {
                entitiesMap.get(entity.contentType).delete(entity.id)
            }
        })

        this.getLists().forEach((val, listName) => this.listRemoveEntities(listName, entities))
        // todo удалять ссылки из других сущностей.
    }

    @action
    registerNewEntity<T extends BaseEntity>(entity: T): T {
        const hasId = void 0 !== entity.id
        if (hasId) {
            const {id, contentType} = entity
            // Нельзя зарегистрировать сущность, которая уже есть в сторейдже
            if (id && this.getEntities().has(contentType) && this.getEntities().get(contentType).has(id)) {
                throw new Error("Can not register new entity with id!")
            }
        }
        entity = this.identityMap.getNewObjectOf<T>(entity.contentType, entity)
        if (!hasId) {
            this.identityMap.setValues<BaseEntity>(entity, {id: getNextUnknownId()})
        }
        defineOrGetFetchStates(entity)
        defineOrGetFieldValueVersions(entity)
        defineOrGetVerion(entity)
        this.unsavedEntities.set(getGID(entity), entity);
        return entity;
    }

    getNoUnsavedPromise () {
        return this.noUnsavedPromise
    }

    @action
    public update <T extends BaseEntity>(
        entity: T,
        params: {
            endpoint?: string
            fetchName?: string
            requestEntity?: BaseValue
            prepareToSave?: boolean
            mixinId?: boolean
            applyOptimisticUpdate?: boolean
        } = {}
    ) {
        // на случай, если интерграции или расширения используют старую версию этого метода
        if ("string" === typeof arguments[1] || arguments.length > 2) {
            return this._update.apply(this, arguments) as Bluebird<T>
        }

        return this._update<T>(
            entity,
            params.endpoint,
            params.fetchName,
            params.requestEntity,
            params.prepareToSave,
            params.mixinId,
            params.applyOptimisticUpdate
        )
    }

    @action
    private _update<T extends BaseEntity>(
        entity: T,
        endpoint: string = getEntityTypeEndpoint(entity),
        fetchName = "update",
        requestEntity?: BaseValue,
        prepareToSave = true,
        mixinId = true,
        applyOptimisticUpdate = true,
    ): Bluebird<T> {

        return Bluebird.try(async() => {
            let entityWasRegistered = false;
            if (!entity.id) {
                entity = this.registerNewEntity(entity);
                entityWasRegistered = true;
            }

            let updateEndpoint: string
            if (entity.id && !isIdUnknown(entity.id) && mixinId) {
                updateEndpoint = `${endpoint}/${entity.id}`
            } else {
                updateEndpoint = `${endpoint}`
            }

            const [oldValues, newVersion] = applyOptimisticUpdate
                ? await this.applyOptimisticUpdate(entity)
                : [null, null]

            return this.entityBasedPromise(
                entity,
                fetchName,
                () => this._fetchEntities<T>(
                    updateEndpoint,
                    {
                        method: "POST",
                        bodyEntity: requestEntity || entity
                    },
                    entities => {
                        if (entity.id && entities.length > 0 && isIdUnknown(entity.id)) {
                            this.registerEntitySave(entity, entities[0])
                        }
                    },
                    newVersion,
                    prepareToSave
                )
                    .then(response => response.value.data[0])
                    .catch(async err => {
                        if (applyOptimisticUpdate) {
                            await this.rollbackEntity(entity, entityWasRegistered, oldValues)
                        }

                        throw err
                    })
            )
        })
    }

    /**
     * Массовое обновление сущностей разных типов через bulk-запрос
     */
    @action
    async updateAll<T extends BaseEntity>(entitiesToUpdate: T[]) {
        type UnsavedEntityData = {
            wasRegistered: boolean,
            values: BaseEntity,
            newVersion: {[gid: string]: number}
        }
        const oldEntities: Array<UnsavedEntityData> = []

        // регистрируем новые сущности, применяем оптимистичное обновление и готовим запросы для BulkCall
        const apiCalls: List<ApiCall> = List(await Promise.all(entitiesToUpdate.map(async (entity) => {
            const oldEntity: UnsavedEntityData = {
                wasRegistered: void 0,
                values: void 0,
                newVersion: void 0,
            }

            if (!entity.id) {
                entity = this.registerNewEntity(entity)
                oldEntity.wasRegistered = true
            } else {
                oldEntity.wasRegistered = false
            }

            const [oldValues, newVersion] = await this.applyOptimisticUpdate(entity)
            oldEntity.values = oldValues
            oldEntity.newVersion = newVersion
            oldEntities.push(oldEntity)

            const updateEndpoint = entity.id && !isIdUnknown(entity.id)
                ? `${getEntityTypeEndpoint(entity)}/${entity.id}`
                : getEntityTypeEndpoint(entity)

            const requestInit = JSON.stringify(prepareEntityToSave(entity))

            return {
                ...ApiCall.newObject,
                method: ApiCall.Method.POST,
                url: updateEndpoint,
                body: requestInit,
            }
        })))

        return this.fetch<Array<ApiResponse<T>>>("/api/v3/bulk", {
            method: "POST",
            bodyEntity: {
                ...BulkApiCall.newObject,
                calls: apiCalls
            }
        })
            .then(async (bulkResponse) => {
                const responses = bulkResponse.value.data

                responses.forEach((response, index) => {
                    const entity = response.data

                    if (entitiesToUpdate[index].id && isIdUnknown(entitiesToUpdate[index].id)) {
                        this.registerEntitySave(entitiesToUpdate[index], entity)
                    }
                })

                const newEntities = responses.map(response => response.data)
                const result = await Promise.all(newEntities.map((newEntity, index) =>
                    this.updateEntitiesFromJson(newEntity, oldEntities[index].newVersion)
                ))

                void this.entitiesStorage.save(result)
                return newEntities
            })
            .catch(async err => {
                for (let i = 0; i < entitiesToUpdate.length; i++) {
                    await this.rollbackEntity(entitiesToUpdate[i], oldEntities[i].wasRegistered, oldEntities[i].values)
                }

                throw err
            })
    }

    public getList = (listName: string): EntitiesList.ReadonlyEntitiesList => this.getLists().has(listName)
        ? this.getLists().get(listName)
        : this.readonlyEmptyEntitiesList

    @action
    public removeList = (listName: string) =>
        this.getLists().delete(listName)


    public resolveList<T extends BaseValue>(
        factory: () => ListStore.SettingFactory<T> | PromiseBasedObservable<ListStore.SettingFactory<T>>,
        disposeTimeout?: number,
        updateTimeout?: number
    ): ListStore<T> {
        return new ListStore(this, factory, disposeTimeout, updateTimeout)
    }

    /**
     * Позволяет вручную добавить сущности в начало списка
     * @param listName
     * @param entities
     */
    @action
    public async listPrependEntities<T extends BaseEntity>(listName: string, entities: Array<T>) {
        this.doPrependEntities(listName, await this.updateEntitiesFromJson(entities));
    }

    @action
    private doPrependEntities<T extends BaseEntity>(listName: string, entities: Array<T>) {
        if (!this.getLists().has(listName)) {
            this.getLists().set(listName, new EntitiesList())
        }
        const list = this.getLists().get(listName);
        const items = list.items;
        const filteredMappedEntities: Array<BaseEntity> = [];
        entities.forEach(entity => {
            entity = this.getEntity(entity);
            if (items.indexOf(entity) < 0) {
                filteredMappedEntities.push(entity);
            }
        })

        items.splice(0, 0, ...filteredMappedEntities)
        list.totalItemsCount = (list.totalItemsCount || 0) + filteredMappedEntities.length;
    }

    /**
     * Позволяет вручную добавить сущности в конец списка
     * @param listName
     * @param entities
     */
    @action
    public async listAppendEntities(listName: string, entities: Array<BaseEntity>) {
        this.doAppendEntities(listName, await this.updateEntitiesFromJson(entities))
    }

    @action
    private doAppendEntities(listName: string, entities: Array<BaseEntity>) {
        if (!this.getLists().has(listName)) {
            this.getLists().set(listName, new EntitiesList())
        }
        const list = this.getLists().get(listName);
        const items = list.items;
        const filteredMappedEntities: Array<BaseEntity> = [];
        entities.forEach(entity => {
            entity = this.getEntity(entity);
            if (items.indexOf(entity) < 0) {
                filteredMappedEntities.push(entity);
            }
        })

        items.splice(items.length, 0, ...filteredMappedEntities)
        list.totalItemsCount = (list.totalItemsCount || 0) + filteredMappedEntities.length;
    }

    @action
    public listRemoveEntities(listName: string, entities: Array<BaseEntity>): number {
        if (!this.getLists().has(listName)) {
            return 0;
        }
        const list = this.getLists().get(listName);
        const oldLength = list.items.length;

        entities.forEach(entity => {
            const index = list.items.findIndex(v => isEntityEquals(v, entity));
            if (index > -1) {
                list.items.splice(index, 1);
            }
        })
        list.totalItemsCount = (list.totalItemsCount || 0) + (list.items.length - oldLength)
        return oldLength - list.items.length
    }

    @action
    public resetList(key: string, descriptor: PreloadListDescriptor) {
        if (this.getLists().has(key)) {
            const list = this.getLists().get(key)
            list.totalItemsCount = descriptor.totalItemsCount
            const items = Array.isArray(descriptor.items) ? descriptor.items : [descriptor.items];

            list.items = new ObservableListClass(items.map(item => this.getEntity(item)))
            list.loadStatePrev = LoadState.Completed()
            list.loadStateNext = LoadState.Completed()
            list.invalidatedItems = null
            list.hasMoreNext = items.length < descriptor.totalItemsCount
            this.getLists().set(key, list)
        }
    }

    /**
     * Скопированный лист помечается особым образом, так что его (пере/до)загрузка не приведет к его инвалидации
     * @param toListName
     * @param fromListName
     */
    @action
    public async copyList(toListName: string, fromListName: string) {
        if (this.getLists().has(toListName)) {
            return
        }

        const fromList = this.getLists().has(fromListName) ? this.getLists().get(fromListName) : new EntitiesList()

        if (fromList.loadStateNext.isPending() || fromList.loadStatePrev.isPending()) {
            throw new Error(`List with the name "${fromListName}" is in the loading state. Can not copy`)
        }

        if (fromList.loadStateNext.isNone() && fromList.loadStatePrev.isNone()) {
            return
        }

        const toList = new EntitiesList()
        this.getLists().set(toListName, toList)
        await this.listAppendEntities(toListName, fromList.items.toArray())

        runInAction(() => {
            toList.loadStatePrev = fromList.loadStatePrev
            toList.loadStateNext = fromList.loadStateNext
            toList.totalItemsCount = fromList.totalItemsCount
            toList.invalidatedItems = fromList.invalidatedItems
            toList.invalidatedTotalItemsCount = fromList.invalidatedTotalItemsCount
            toList.hasMorePrev = fromList.hasMorePrev
            toList.hasMoreNext = fromList.hasMoreNext
            toList.loadedEndpoint = fromList.loadedEndpoint
            toList.loadedWithOptions = fromList.loadedWithOptions
        })
    }

    /**
     * Вмёрживает переданный json в текущие entities & lists
     * @param json
     * @param versions   Версии сущностей, в которой пришёл json. Если пришедшая версия являтся более старой, то она
     *                  игнорируется. Проверка версий производится по полям, то поля сущности могут быть в разных
     *                  версиях.
     */
    @action
    async updateEntitiesFromJson<T extends BaseEntity>(json: Array<T> | T, versions?: {[entityGid: string]: number}): Promise<any> {
        return getJsonProperty(json).normalize(this.identityMap, versions)
    }

    @action
    public fetchEntities<T extends BaseEntity>(
        endpoint: string,
        requestInit?: RequestInit
    ): Bluebird<Response<ApiResponse<T[]>>> {
        return this._fetchEntities<T>(endpoint, requestInit)
    }

    /**
     * запрашивает сущность с опциями, нужными для листа
     * и вставляет (если нужно) сущность в лист с учетом её позиции в данном листе
     */
    @action
    async listLoadAndMergeEntityWithIndex(listName: string, entity: BaseEntity) {
        const list = this.getLists().get(listName)
        if (list && list.items.indexOf(entity) < 0 && list.loadedEndpoint) {
            const response = await this._fetchEntities(list.loadedEndpoint, {
                queryParams: {
                    ...list.loadedWithOptions,
                    pageWith: entity,
                    limit: 1
                }
            })

            const pagination = response.value.meta.pagination
            const responsedEntity = this.getEntity(response.value.data[0])

            await this.updateEntitiesFromJson(responsedEntity)
            await this.listAppendEntityByIndex(listName, responsedEntity, pagination.elementIndex)
        }
    }

    @action
    async listAppendEntityByIndex(listName: string, entity: BaseEntity, elementIndex: number) {
        const list = this.getLists().get(listName)
        if (list && list.items.indexOf(entity) < 0) {
            if (elementIndex < list.items.length) {
                list.items.splice(elementIndex, 0, entity)
                list.totalItemsCount = (list.totalItemsCount || 0) + 1
            } else if (!list.hasMoreNext) {
                await this.listAppendEntities(listName, [entity])
            }
        }
    }

    /**
     * Приватный метод для выполнения запроса, который предполагается что вернёт сущности.
     * Позволяет выполнить произвольный код сразу после ответа
     * @param endpoint
     * @param requestInit
     * @param afterFetchCallback
     * @param version
     * @returns {Bluebird<T|Response<ApiResponse<T[]>>>}
     * @private
     */
    @action
    private _fetchEntities<T extends BaseEntity>(
        endpoint: string,
        requestInit?: RequestInit,
        afterFetchCallback = (entities: BaseEntity[]): any => void 0,
        versions?: {[gid: string]: number},
        prepareToSave = true,
    ): Bluebird<Response<ApiResponse<T[]>>> {
        return this.fetch<T | T[]>(endpoint, requestInit, prepareToSave).then(response => runInAction(
            "fetchEntities.fetch.then",
            async () => {
                let entities: BaseEntity[] = [];
                let data = response.value.data;
                if (Array.isArray(data)) {
                    entities = data
                } else if (isBaseValue(data)) {
                    entities = [data]
                }

                runInAction(() => afterFetchCallback(entities));
                const result = (await this.updateEntitiesFromJson(entities, versions)).toArray()


                void this.entitiesStorage.save(result)

                // normalize response:
                return {
                    url: response.url,
                    status: response.status,
                    statusText: response.statusText,
                    headers: response.headers,
                    value: {
                        meta: response.value.meta,
                        data: result,
                    },
                } as Response<ApiResponse<T[]>>;
            }
        ))
    }

    @action
    public entityBasedFetch(
        entity: BaseEntity,
        fetchName: string,
        endpoint: string,
        requestInit?: RequestInit
    ): Bluebird<Response<ApiResponse<BaseEntity[]>>> {
        return this.entityBasedPromise(
            entity,
            fetchName,
            () => this.fetchEntities(endpoint, requestInit)
        )
    }

    /**
     * Применяет оптимистичное обновление сущности.
     * Возвращает старые значения полей сущности, на которую если что надо откатиться.
     * @param entity
     */
    @action
    private async applyOptimisticUpdate(entity: BaseEntity): Promise<[BaseEntity, {[gid: string]: number}]> {
        const oldValues: BaseEntity = getLink(entity);
        const currentEntity = this.getEntity(entity);
        const newVersions = {
            [getGID(entity)]: (currentEntity._version || 0) + 1
        };
        Object.keys(entity).forEach(key => {
            let currentValue = (currentEntity as any)[key]
            if (isList(currentValue)) {
                currentValue = currentValue.slice()
            }
            (oldValues as any)[key] = currentValue
        })
        await this.updateEntitiesFromJson(Object.assign({}, entity), newVersions);
        return [oldValues, newVersions];
    }

    /**
     * Откатывает оптимистичное обновление сущности.
     */
    @action
    private async rollbackEntity(entity: BaseEntity, isNewEntity: boolean, oldValues: BaseEntity) {
        if (isNewEntity) {
            this.unsavedEntities.delete(getGID(entity))
        } else if (entity.id && !isIdUnknown(entity.id)) {
            await this.updateEntitiesFromJson(Object.assign({}, entity, oldValues))
        }
    }

    @action
    public entityBasedPromise<T>(
        entity: BaseEntity,
        fetchName: string,
        createPromiseCb: () => Bluebird<T>
    ): Bluebird<T> {
        const fetchNamespace = getFetchNamespace(fetchName)
        const promiseName = getGID(entity) + "_" + fetchNamespace
        const realEntity = this.getEntity(entity)
        const entityFetchStates = defineOrGetFetchStates(realEntity);
        const fetchStateIsFulfilled = entityFetchStates.has(fetchName) && !entityFetchStates.get(fetchName).isNone()

        // Переключать в загрузку, уже загруженную сущность - не надо
        if (!fetchStateIsFulfilled) {
            entityFetchStates.set(fetchName, LoadState.Pending())
        }

        return this.promisesStorage.after(promiseName, () => runInAction(
            "entityBasedPromise.promisesStorage.after",
            () => {
                if (!fetchStateIsFulfilled) {
                    entityFetchStates.set(fetchName, LoadState.Pending())
                }

                // Whole namespace also should be tracked by interface
                if (fetchName !== fetchNamespace) {
                    entityFetchStates.set(fetchNamespace, LoadState.Pending())
                }

                return createPromiseCb().then(result => runInAction(
                    "entityBasedPromise.createPromiseCb().then",
                    () => {
                        entityFetchStates.set(fetchName, LoadState.Completed());
                        if (fetchName !== fetchNamespace) {
                            entityFetchStates.set(fetchNamespace, LoadState.Completed());
                        }
                        return result
                    }
                )).catch(error => runInAction(
                    "entityBasedPromise.createPromiseCb().catch",
                    () => {
                        if (error instanceof FetchError) {
                            entityFetchStates.set(fetchName, LoadState.Error(error.response.value));
                            if (fetchName !== fetchNamespace) {
                                entityFetchStates.set(fetchNamespace, LoadState.Error(error.response.value));
                            }
                        }
                        throw error // keep promise rejected
                    }
                ))
            }
        ))
    }

    public getEntity = <T extends BaseEntity>(entityLink: T, notFoundValue: T = entityLink): T => {
        if (null == entityLink) {
            return notFoundValue;
        }
        const {id, contentType} = entityLink;
        if (this.getEntities().has(contentType)) {
            const contentTypeStorage = this.getEntities().get(contentType);
            if (contentTypeStorage.has(String(id))) {
                return contentTypeStorage.get(String(id)) as T;
            }
        }
        const gid = getGID(entityLink);
        if (this.unsavedEntities.has(gid)) {
            return this.unsavedEntities.get(gid) as T;
        }
        return notFoundValue;
    }

    /**
     * Отдаёт загруженную, либо загружает и отдаёт сущность.
     */
    public getOrLoadEntity<T extends BaseEntity>(
        entityLink: T,
        endpoint: string = getEntityTypeEndpoint(entityLink),
        returnFull = true
    ): T | PromiseLike<T> {
        const exists = this.getEntity(entityLink, null)
        if (exists !== null) {
            if (FULL_ENTITY_TYPES.has(exists.contentType) && (!isUserSetting(exists) || exists.value !== void 0)) {
                return exists
            }

            if (untracked(() => getEntityFetchState(exists, FETCH_FULL_NAME).isNone())) {
                setTimeout(() => {
                    if (getEntityFetchState(exists, FETCH_FULL_NAME).isNone()) {
                        void this.fetchFullEntity(entityLink, endpoint)
                    }
                })
            }
            // Если сущность в процессе загрузки, подождём её
            if (untracked(() => getEntityFetchState(exists, FETCH_FULL_NAME).isCompleted())) {
                // Важно вернуть из метода синхронно exists, а не промис с ним.
                return exists
            }

            if (returnFull) {
                return Bluebird.try(async () => {
                    await waitFor(() => getEntityFetchState(this.getEntity(entityLink), FETCH_FULL_NAME).isCompleted())
                    return exists
                })
            }

            return exists
        }

        return Bluebird.try(async () => {
            // Некоторые сущности можно доставать из localStorage в первую очередь
            if (isUserSetting(entityLink) && STORE_USER_SETTINGS_IN_LOCAL_STORAGE) {
                // ленивое получение сущности - сначала пробует отдать сущность из localStorage и фоном обновить ее
                // применять там, где не критична актуальность данных
                const entityFromStorage = await this.entitiesStorage.load([entityLink])
                if (entityFromStorage.length > 0) {
                    void this.updateEntitiesFromJson(entityFromStorage)
                    this.lazyFetchEntitiesStack.set(getGID(entityLink), entityLink)
                    this.processLazyFetchEntitiesStack()
                    runInAction(() => defineOrGetFetchStates(entityFromStorage[0]).set(FETCH_FULL_NAME, LoadState.Completed()))
                    return entityFromStorage[0] as T
                }
            }

            const entity = await this.fetchFullEntity(entityLink, endpoint)
            return this.getEntity(entity)
        })
    }

    // обновляет накопившиеся сущности одним запросом
    private processLazyFetchEntitiesStack = throttle(
        () => {
            if (this.lazyFetchEntitiesStack.size) {
                const entityLinks: BaseEntity[] = []
                for (let entityLink of this.lazyFetchEntitiesStack.values()) {
                    const entityEndpoint = getEntityTypeEndpoint(entityLink)
                    if (isCacheableRequest(entityEndpoint)) {
                        void this.fetchFullEntity(entityLink, entityEndpoint)    // за кэшированными сущностями ходим без объеденений
                    } else {
                        entityLinks.push(entityLink)
                    }
                }
                this.lazyFetchEntitiesStack.clear()
                while (entityLinks.length) {
                    void this.fetchFullEntitiesByOneRequest(entityLinks.splice(0, UPDATE_ENTITIES_LIMIT))
                }
            }
        },
        DEFAULT_UPDATE_TIMEOUT,
        {
            leading: false,
            trailing: true,
        }
    )

    // выполняет один bulk-запрос на получение массива сущностей
    private async fetchFullEntitiesByOneRequest<T extends BaseEntity>(entityLinks: T[]): Promise<T[]> {
        if (entityLinks.length === 1) {
            return [await this.fetchFullEntity<T>(entityLinks[0])]
        }
        return this.fetchEntities<T>("/api/v3/bulk/getEntitiesByLinks", {
            method: "POST",
            bodyEntity: entityLinks
        }).then(r => r.value.data)
    }

    @action
    private registerEntitySave = <T extends BaseEntity>(oldEntity: T, newEntity: T): void => {
        const oldEntityGid = getGID(oldEntity);
        const realOldEntity = this.unsavedEntities.get(oldEntityGid);
        this.unsavedEntities.delete(oldEntityGid);
        realOldEntity.id = newEntity.id;
        const {contentType, id} = realOldEntity;
        if (!this.getEntities().has(contentType)) {
            this.getEntities().set(contentType, observable.map<string, BaseEntity>([], {deep: false}))
        }
        const contentTypeStorage = this.getEntities().get(contentType);
        if (id && contentTypeStorage.has(id)) {
            throw new Error(
                `${getGID(realOldEntity)} already exists in storage.` +
                ` This is wrong, because it could produce broken links!`
            )
        }
        if (id) {
            contentTypeStorage.set(id, realOldEntity)
            void this.newEntityToListPusher.push(realOldEntity)
        }
    }

    getEntities() {
        return this.identityMap.getEntities();
    }

    getLists() {
        return this.identityMap.getLists();
    }

    public isEntityExistInList(entity: BaseEntity, listName: string): boolean {
        const list = this.getList(listName)
        return !!list &&
            !!list.items.find(item => isBaseEntity(item) && getGID(item) === getGID(entity))
    }

    // удаляет из всех листов ноды с сущностью и пересчитывает каунтеры родительских нод
    @action
    deleteAllTreeNodesWithEntity(entity: BaseEntity) {
        const treeNodeEntities = this.getEntities().get(TreeNode.contentType)
        if (!treeNodeEntities) {
            return
        }
        treeNodeEntities.forEach((treeNode: TreeNode) => {
            if (getGID(treeNode.value) === getGID(entity)) {
                // пересчет каунтеров для ближайших родительских задач или проектов
                if (isTask(entity)) {
                    const parentTreeNode = treeNode.parent
                    if (parentTreeNode) {
                        parentTreeNode.childrenCount--
                    }
                }

                this.deleteEntityFromAllLists([treeNode])
            }
        })
    }

    isEntityExist(entity: BaseEntity): boolean {
        return !!this.getEntity(entity, null)
    }

    public subscribeToApiErrors(type: string, cb: ApiErrorSubscriber.Handler) {
        if (this.apiErrorSubscriber) {
            this.apiErrorSubscriber.add(type, cb)
        }
    }

    public unsubscribeToApiErrors(type: string, cb: ApiErrorSubscriber.Handler) {
        if (this.apiErrorSubscriber) {
            this.apiErrorSubscriber.remove(type, cb)
        }
    }
}
