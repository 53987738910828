import classNames from "classnames/bind"
import {noop} from "lodash"
import {action, observable} from "mobx"
import {observer} from "mobx-react"
import * as React from "react"
import {ReactNode} from "react"
import {COnBoardHintContainer} from "src/bums/common/onboarding/containers/COnBoardHintContainer"
import {HintIdsType} from "src/bums/common/onboarding/onboardingHintsMap";
import {CButton, CButtonSize, CCheckbox, CFlex, CIcon, CIconColor, CIconType, Component, CPopover} from "src/lib/components"
import {ShowHint} from "src/lib/types"
import {autobind} from "core-decorators";


const style = classNames.bind(require("./CPopoverMenu.styl"))

/**
 * Меню настроек в поповере
 */

export namespace CPopoverMenu {
    export interface Props extends ShowHint {
        onClose?: () => void,
        onOpen?: () => void,
        //иконка для открытия меню
        icon?: CIconType,
        color?: CIconColor,
        iconRight?: CIconType,
        colorRight?: CIconColor,
        caption?: string | JSX.Element,
        hideCaption?: boolean,
        buttonSize?: CButtonSize
        buttonType?: CButton.Type
        closeOnItemClick?: boolean
        horizontalOrientation?: "left" | "right" | "center"
        title?: string
        className?: string
        element?: JSX.Element
        noPaddingBottom?: boolean
        maxHeight?: number | string
    }
}

@observer
@autobind
export class CPopoverMenu extends Component<CPopoverMenu.Props, {}> {

    public static defaultProps = {
        onClose: noop,
        onOpen: noop,
        icon: CIcon.Type.MORE_VERT,
        buttonType: CButton.Type.FLAT,
        buttonSize: "normal",
        horizontalOrientation: "right"
    }

    @observable
    private open = false

    onClose() {
        this.props.onClose()
        if (this.props.onHintHide) {
            this.props.onHintHide()
        }
    }

    @action
    public toggleOpen(event?: React.MouseEvent<HTMLElement>) {
        if (event) {
            event.stopPropagation()
            event.preventDefault()
        }
        this.open = !this.open

        if (this.open) {
            this.props.onOpen()
            if (this.props.onHintRequest) {
                this.props.onHintRequest()
            }
        } else {
            this.onClose()
        }
    }

    @action
    private closeHandler = () => {
        this.open = false
        this.onClose()
    }

    private renderChildren() {
        return this.props.closeOnItemClick
        ? React.Children.map(this.props.children, (child: any) =>
                React.isValidElement(child) &&
                    React.cloneElement(child as any, { onClick: (e: React.MouseEvent<HTMLElement>) => {
                        if ((child as any).props.onClick) {
                            (child as any).props.onClick(e)
                            this.toggleOpen(e)
                        }
                    }})
            )
        : this.props.children
    }

    private renderPopoverElement() {
        const {element, color, colorRight, buttonType, className, iconRight, icon, buttonSize, caption, hideCaption, title} = this.props

        return element
            ? React.cloneElement(
                element,
                {
                    onClick: (e: React.MouseEvent<HTMLElement>) => {
                        if (element.props.onClick) {
                            element.props.onClick(e)
                        }
                        this.toggleOpen(e)
                    }
                }
            )
            : <CButton
                color={color}
                colorRight={colorRight}
                name="togglePopoverMenu"
                type={buttonType}
                className={className}
                icon={!iconRight ? icon : void 0}
                iconRight={iconRight}
                onClick={this.toggleOpen}
                size={buttonSize}
                caption={caption}
                hideCaption={hideCaption}
                title={title ? title : null}
            />
    }

    public render() {
        const {maxHeight, noPaddingBottom, horizontalOrientation} = this.props

        return <CPopover
            element={this.renderPopoverElement()}
            open={this.open}
            onClose={this.closeHandler}
            noPadding={true}
            takeIntoElementWhenClose={true}
            verticalOrientation="bottom"
            horizontalOrientation={horizontalOrientation}
        >
            <COnBoardHintContainer hintId={[HintIdsType.taskActionsList, HintIdsType.journalSettings]} open>
                <div
                    className={style("CPopoverMenu", {noPaddingBottom: noPaddingBottom, hasMaxHeight: Boolean(maxHeight)})}
                    style={{maxHeight: maxHeight}}
                >
                    {this.renderChildren()}
                </div>
            </COnBoardHintContainer>
        </CPopover>
    }
}

export namespace CPopoverMenu {

    export const DraggableCheckBox = observer((
        props: CCheckbox.Props & {styleContainer?: React.CSSProperties, classNameContainer?: string}
    ) => {
        const {styleContainer, classNameContainer, ...checkboxProps} = props
        return <CFlex style={styleContainer} className={classNameContainer}>
            <CIcon type={CIcon.Type.DRAG_INDICATOR} color={CIcon.Color.GREY} className={style("draggable")} />
            <CFlex.Item width={"auto"}>
                <CCheckbox {...checkboxProps} type="success_button" />
            </CFlex.Item>
        </CFlex>
    })

    //чекбокс
    export const Checkbox = observer((props: CCheckbox.Props) => {
        return <CCheckbox {...props} type="success_button" />
    })

    //кнопка
    export const Button = observer((props: CButton.Props) => {
        return <CButton {...props} type={CButton.Type.SETTING} />
    })

    //разделитель
    export const Divider = observer(() => {
        return <div className={style("divider")} />
    })

    //заголовок группы
    export const HeaderGroup = observer((props: Readonly<{children?: ReactNode}>) => {
        return <div className={style("title")}>
            {props.children}
        </div>
    })

    //обертка списка
    export const WrapperGroup = observer((props: Readonly<{children?: ReactNode}>) => {
        return <div className={style("CPopoverMenu")}>
            {props.children}
        </div>
    })
}
