/* tslint:disable */
import * as H from "../metadataHelper"
import {NegotiationItem} from "./negotiationItem"
import {File} from "./file"
import {Employee} from "./employee"
import {NegotiationVisa} from "./negotiationVisa"
import {StringField} from "./stringField"
import {DateTimeField} from "./dateTimeField"
import {IntegerField} from "./integerField"
import {EnumField} from "./enumField"

export interface NegotiationItemVersion extends H.BaseEntity {
    negotiationItem?: NegotiationItem; // Согласование
    attache?: File; // Вложение
    text?: string; // Текст
    userCreated?: Employee; // Создатель
    timeCreated?: Date; // Время создания
    visas?: H.List<NegotiationVisa>; // Визирование
    visasCount?: number; // Количество визирований
    status?: NegotiationItemVersion.Status; // Статус
    id?: string; // Идентификатор
    contentType: "NegotiationItemVersion"; // Object type
}

export module NegotiationItemVersion {
    export const contentType: "NegotiationItemVersion" = "NegotiationItemVersion";
    export const endpoint = "/api/v3/negotiationItemVersion";
    export type Status = "bad"|"ok";
    export module Status {
        export const bad: Status = "bad";
        export const ok: Status = "ok";
    }
    export const newObject: NegotiationItemVersion = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const negotiationItem = {contentType: "RefLinkField" as "RefLinkField", name: "negotiationItem", isMultiple: false, isSortable: false, id: "NegotiationItemVersion.negotiationItem", refContentType: H.List(["NegotiationItem"])};
        export const attache = {contentType: "RefLinkField" as "RefLinkField", name: "attache", isMultiple: false, isSortable: false, id: "NegotiationItemVersion.attache", refContentType: H.List(["File"])};
        export const text = {contentType: "StringField" as "StringField", name: "text", isMultiple: false, isSortable: false, id: "NegotiationItemVersion.text", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const userCreated = {contentType: "RefLinkField" as "RefLinkField", name: "userCreated", isMultiple: false, isSortable: false, id: "NegotiationItemVersion.userCreated", refContentType: H.List(["Employee"])};
        export const timeCreated = {contentType: "DateTimeField" as "DateTimeField", name: "timeCreated", isMultiple: false, isSortable: false, id: "NegotiationItemVersion.timeCreated", defaultValue: null as DateTimeField["defaultValue"]};
        export const visas = {contentType: "RefLinkField" as "RefLinkField", name: "visas", isMultiple: true, isSortable: false, id: "NegotiationItemVersion.visas", refContentType: H.List(["NegotiationVisa"])};
        export const visasCount = {contentType: "IntegerField" as "IntegerField", name: "visasCount", isMultiple: false, isSortable: false, id: "NegotiationItemVersion.visasCount", defaultValue: null as IntegerField["defaultValue"]};
        export const status = {contentType: "EnumField" as "EnumField", name: "status", isMultiple: false, isSortable: false, id: "NegotiationItemVersion.status", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["bad", "ok"])};
    }
}

export function isNegotiationItemVersion(arg: any): arg is NegotiationItemVersion {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "NegotiationItemVersion"
}
