import {Feature} from "src/lib/entities/bums"
import * as DateHelper from "src/lib/utils/date"
import {CRatingContainer} from "src/bums/common/promo/CRating/CRatingContainer"
import {CStartPackReview} from "src/bums/common/promo/CRating/alternativeFinalStep/CStartPackReview"
import {CTrustLandReview} from "src/bums/common/promo/CRating/alternativeFinalStep/CTrustLandReview"
import {CThankYouStep} from "src/bums/common/promo/CRating/alternativeFinalStep/CThankYouStep"
import {PromoData} from "src/bums/common/promo/types"
import {CRatingStepComponent} from "src/bums/common/promo/CRating/types"

const promoSettings = {
    startPack: {
        feature: "bums.common.promo.december2019.promo_rating_with_start_pack_feedback" as Feature.Name,
        component: CStartPackReview
    },
    trustLand: {
        feature: "bums.common.promo.december2019.promo_rating_with_trust_land_feedback" as Feature.Name,
        component: CTrustLandReview
    },
    none: {
        feature: "bums.common.promo.december2019.promo_rating" as Feature.Name,
        component: CThankYouStep
    },
} as {[type: string]: {feature: Feature.Name, component: CRatingStepComponent}}

export const createDecemberPromoSettings = (sendReview: "startPack" | "trustLand" | "none"): PromoData<null> => {
    const {feature, component} = promoSettings[sendReview]
    return {
        id: "promo.common.promo_rating_dec2019",
        feature,
        component: CRatingContainer.injectProps({ratingTag: "v2", ratingType: "regularRating", customFinalStep: component}),
        userCondition: (userStore, featureStore) => {
            if (featureStore.isAvailable("bums.common.promo.on_boarding_rating")) {
                return false
            }
            const now = DateHelper.getNow().getTime()
            const today10am = DateHelper.getToday().addHours(10).getTime()
            const today17pm = DateHelper.getToday().addHours(17).getTime()
            return today10am < now && now < today17pm
        },
        store: null
    }
}
