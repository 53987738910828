import * as React from "react"
import {observer} from "mobx-react"
import {computed} from "mobx"
import {autobind} from "core-decorators"
import {UserSettingStore} from "src/bums/common/stores/UserSettingStore"
import * as Api from "src/lib/entities/api"
import * as DateHelper from "src/lib/utils/date"
import {FeatureStore} from "src/bums/common/stores/FeatureStore"
import {Component} from "src/lib/components"
import {inject} from "src/lib/utils/inject"
import {Router} from "src/lib/utils/router"

export namespace COnBoardingRating {
   export interface Props {}
}

const LazyComponent = React.lazy(() => import("./COnBoardingRating"))

const noRender = () => null as any

const SETTING_ID = "onboardingrating"
/**
 * Оценка и фидбек, которые собираются через 20 минут после первого логина пользователя.
 * Ленивый. Не грузим форму рейтинга, если фича выключенна или условие не выполняется
 */
@autobind
@observer
export class COnBoardingRatingContainer extends Component {

    @inject(FeatureStore)
    private featureStore: FeatureStore

    @inject(Api.UserStore)
    private userStore: Api.UserStore

    @inject(Api.Store)
    private apiStore: Api.Store

    @inject(Router)
    private router: Router

    private ratingShow: UserSettingStore<boolean>

    private firstLoginStore: UserSettingStore<number>

    componentWillMount() {
        this.ratingShow = new UserSettingStore<boolean>(
            this.apiStore,
            () => this.isFeatureAvailable ? SETTING_ID : void 0,
            () => true
        )
        this.firstLoginStore = new UserSettingStore<number>(
            this.apiStore,
            () => this.isFeatureAvailable ? "sdf.common.user_first_login" : void 0,
            () => null
        )
    }

    @computed
    private get isFeatureAvailable() {
        return this.featureStore.isAvailable("bums.common.promo.on_boarding_rating")
    }

    @computed
    private get isAvailable() {
        if (!this.isFeatureAvailable) {
            return false
        }
        const ratingSetting = this.ratingShow.get()
        const firstLogin = this.firstLoginStore.get()
        if (ratingSetting.state === "fulfilled" && firstLogin.state === "fulfilled") {
            return ratingSetting.value && this.userCondition(firstLogin.value)
        }
        return false
    }

    /**
     * Показываем только админу, через 20 минут после регистрации на рабочем столе
     */
    private userCondition(firstOnline: number) {
        if (!firstOnline) {
            return false
        }
        if (!this.userStore.isAdmin || this.router.location.pathname !== "/newdashboard/") {
            return false
        }
        return DateHelper.diff(DateHelper.getNow(), new Date(firstOnline), DateHelper.Units.MINUTES) > 20
    }

    public render() {
        if (this.isAvailable) {
            return <React.Suspense fallback={noRender}>
                <LazyComponent settingStore={this.ratingShow} />
            </React.Suspense>
        }
        return null
    }

}
