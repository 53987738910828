import React from "react"
import {action, observable} from "mobx"
import * as Api from "src/lib/entities/api"
import {TOPICS_LIMIT} from "./TopicsStore"
import {ALL_CHATS_FOLDER_ID} from "src/lib/utils/chat"

export class TopicFoldersStore {

    private listStoreMap = new Map<string, Api.ListStore<Api.Topic>>()

    constructor(
        private apiStore: Api.Store,
        private fields: string | {}[]
    ) {}

    public getList = (folderName: string) => {
        if (this.listStoreMap.has(folderName)) {
            return this.listStoreMap.get(folderName)
        }
        const listStore = this.apiStore.resolveList<Api.Topic>(() => {
            return {
                endpoint: Api.Topic.endpoint,
                options: {
                    fields: this.fields,
                    folder: {id: folderName},
                },
                limit: TOPICS_LIMIT
            }
        })
        this.listStoreMap.set(folderName, listStore)
        return listStore
    }

    public hasList = (folderName: string) => {
        return this.listStoreMap.has(folderName)
    }
}

export class TopicFolderStore {

    @observable
    public activeFolder = ALL_CHATS_FOLDER_ID

    @action
    public setActiveFolder = (folder: string) => {
        this.activeFolder = folder
    }
}

export const TopicFolderContext = React.createContext<TopicFolderStore>(null)
