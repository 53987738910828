import * as React from "react"
import classNames from "classnames/bind"
import * as Api from "src/lib/entities/api"
import {
    CButton,
    CLink,
    CAvatar,
    CUserVacationCaption,
    CEllipsis,
    CList,
    CPopover,
    Component,
    CHover,
    CRoundCrop,
    CIcon,
} from "src/lib/components"
import {Size} from "src/lib/types"
import {observer} from "mobx-react"
import {computed} from "mobx"
import {uniqueId, noop} from "lodash"
import {inject} from "../../utils/inject"
import {Intl} from "../../utils/intl/Intl"
import {FormattedMessage} from "src/lib/utils/intl"
import {CUserContactList} from "./CUserContactList"
import {isEmployee, isContractorHuman} from "src/lib/entities/api"
import {bindArg} from "src/lib/utils/func"
import {ChatWidgetStore} from "src/bums/common/chat/stores/ChatWidgetStore"

const style: any = classNames.bind(require("./CUser.styl"))
const messages: any = require("./messages.yml")
const messagesLib: any = require("src/lib/messages.yml")

enum ViewType {
    MINI,
    DEFAULT,
    FULL,
    PLAIN
}

export enum ViewTypeContactInfo {
    FULL,
    FIRST,
    SHORT,
    NO_ACCESS,
}

interface CUserState {
    openMembers: boolean
}

interface CUserProps {
    entity: Api.Employee | Api.Contractor | Api.Payer | Api.Group | Api.Variable
    showPicture?: boolean
    viewType?: ViewType
    viewTypeContactInfo?: ViewTypeContactInfo
    customContactInfo?: Array<Api.ContactInfo>
    sizeImg?: Size
    title?: string
    className?: string
    nameCaption?: string | JSX.Element | JSX.Element[]      //Custom name caption
    showPosition?: boolean
    positionCaption?: string | JSX.Element | JSX.Element[]  //Custom position caption
    status?: "positive" | "negative" | "neutral"
    customActions?: string | JSX.Element | JSX.Element[]
    showChatButton?: boolean
    onAfterChatOpen?: () => void
    onUserInPopoverClick?: (value: Api.Employee) => void
    ellipsisName?: boolean
    showOnlyPosition?: boolean
    sameInns?: Array<string>
    customDescription?: string
    // для вывода сущностей с изменяемым юзером(при навдении показывает иконку редактирования на аватарке)
    canChange?: boolean
    onClick?: React.MouseEventHandler<HTMLElement>
    //показывать заглушку "Текущий пользователь" вместо информации о пользователе
    isCurrentUser?: boolean
    showCaptionLink?: boolean   // если true - заголовок выводится как ссылка в принудительном порядке
    hidePosition?: boolean   //скрывает дополнительную информацию
    showIsOnline?: boolean // показывать отметку онлайн
    target: "_blank" | "_self" | "_parent" | "_top" // режим открытия ссылки на пользователя
}

export const SizeVariableMap = {
    [Size.SMALL]: 30,
    [Size.BIG]: 52,
}

@observer
export class CUser extends Component<CUserProps, CUserState> {

    constructor(props: CUserProps, context: CUserState) {
        super(props, context);
        this.state = {
            openMembers: false
        }
        this.openMembers = this.openMembers.bind(this);
    }

    @inject(Intl)
    private intl: Intl

    @inject(ChatWidgetStore)
    private chatWidgetStore: ChatWidgetStore

    public static ViewType = ViewType
    public static ViewTypeContactInfo = ViewTypeContactInfo
    public static SizeType = Size

    public static ContactInfo = CUserContactList

    public static defaultProps = {
        viewType: ViewType.DEFAULT,
        viewTypeContactInfo: ViewTypeContactInfo.FIRST,
        sizeImg: Size.SMALL,
        showPicture: true,
        showPosition: true,
        showIsOnline: true,
        onAfterChatOpen: noop,
        showChatButton: true,
        ellipsisName: false,
        target: ""
    }

    private openChatWithUser = () => {
        if (this.props.showChatButton && isEmployee(this.props.entity)) {
            void this.chatWidgetStore.topicOpen(this.props.entity)
            this.props.onAfterChatOpen()
        }
    }

    private renderMessageButton() {
        if (
            !this.props.showChatButton ||
            this.props.viewType !== ViewType.FULL ||
            !isEmployee(this.props.entity)
        ) {
            return
        }

        return <CButton
            name="chatBeginButton"
            className={style("chatBeginButton")}
            caption={<FormattedMessage {...messages["writeInChat"]}/>}
            onClick={this.openChatWithUser}
        />
    }

    private renderContact(item: Api.ContactInfo, type: CUserContactList.ContactType) {
        return <div className={style("userInfoRow")} key={uniqueId(type)}>
            <div className={style(type, "info")}>
                <CLink to={item} />
            </div>
        </div>
    }

    private renderContractorCompany(entity: Api.Contractor) {
        return entity.company
            ? <div className={style("blockText")}>
                <CLink to={entity.company} type="secondary">{Api.getEntityName(entity.company)}</CLink>
            </div>
            : null
    }

    private renderContractorInn() {
        if (Api.isContractor(this.props.entity)) {
            const payers = this.props.entity.payers

            if (payers && payers.length) {
                return payers.map((payer, index) => {
                    return this.props.sameInns.includes(payer.inn)
                        ? <div className={style("userInfoRow")} key={uniqueId("inn")}>
                            <FormattedMessage {...messages["inn"]}/>{": "}
                            {payer.inn}
                        </div>
                        : null
                })
            }
        }
    }

    private onClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        this.props.onClick(e)
    }

    private getPositionCaption() {

        if (this.props.positionCaption) {
            return this.props.positionCaption
        }

        const {entity} = this.props

        if (isEmployee(entity)) {
            return entity.position
        } else if (Api.isContractor(entity)) {
            return entity.type ? entity.type.name : null
        } else if (Api.isGroup(entity)) {
            return this.intl.formatMessage(messages["groupSize"], {count: entity.childrenCount})
        }

        return null
    }

    private getNearestVacation() {
        const {entity} = this.props
        if (isEmployee(entity) && Api.isVacation(entity.nearestVacation)) {
            return entity.nearestVacation
        }
        return null
    }

    private openMembers() {
        this.setState({openMembers: !this.state.openMembers})
    }

    @computed
    private get showCaptionLink() {
        const {entity} = this.props

        return this.props.showCaptionLink
            || (isEmployee(entity) && entity.isReadable)
            || (Api.isContractor(entity) && entity.canSeeFull)
            || (Api.isPayer(entity) && entity.contractor && entity.contractor.canSeeFull)
    }

    @computed
    private get entityName() {
        const {entity, nameCaption} = this.props
        let userName = nameCaption ? nameCaption : Api.getEntityName(entity)

        if (userName === "currentUser") {
            userName = this.intl.formatMessage(messagesLib["currentUser"])
        }

        return userName
    }

    private onNameClick(event: React.SyntheticEvent<HTMLElement>) {
        event.stopPropagation()
    }

    public render() {
        const {entity, viewType, showPicture, title, sizeImg, status,
            showPosition, customDescription, hidePosition, showIsOnline, target, onUserInPopoverClick} = this.props

        if (!entity) {
            return null
        }
        const position = this.getPositionCaption()
        const userName = this.props.ellipsisName ? <CEllipsis>{this.entityName}</CEllipsis> : this.entityName

        const caption = this.showCaptionLink
            ? <CLink
                to={entity}
                className={style("nameLink", ViewType[viewType])}
                target={target}
                forceTarget={true}
                type="secondary"
                onClick={this.onNameClick}
            >
                {userName}
            </CLink>
            : <div onClick={this.openMembers} className={style("nameText", ViewType)}>
                <span>
                    {userName}
                </span>
            </div>

        return <CHover.Handler>
            <div className={style("user-container", this.props.className)}>
                <div className={style("user-wrapper")}>
                    {showPicture && viewType !== ViewType.PLAIN
                        && <div className={style("pictureWrapper")}>
                            {((isEmployee(entity) && (entity as Api.Employee).isReadable ) || (entity as Api.Contractor).canSeeFull) ?
                                <CAvatar
                                    title={title}
                                    entity={entity}
                                    size={sizeImg}
                                    status={status}
                                    showIsOnline={showIsOnline}
                                /> :
                                <div className={style("nameText")}>
                                    <CAvatar
                                        title={title}
                                        entity={entity}
                                        size={sizeImg}
                                        status={status}
                                        onClick={this.openMembers}
                                        showIsOnline={showIsOnline}
                                    />
                                </div>}
                            {this.props.canChange
                                && <CHover.Element hideOnMobile>
                                <div className={style("editBg")}>
                                    <CRoundCrop className={style("editWrap")} size={sizeImg}/>
                                    <CIcon
                                        size={CIcon.Size.SMALL}
                                        type={CIcon.Type.EDIT}
                                        color={CIcon.Color.LIGHT}
                                        className={style("editIcon")}
                                    />
                                </div>
                            </CHover.Element>
                            }
                        </div>
                    }
                    {viewType !== ViewType.MINI
                        && <div className={style(
                            "content",
                            showPicture && viewType !== ViewType.PLAIN ? "withPicture" : null
                            )}
                        >
                            <div className={style("name")}>
                                {caption}
                            </div>
                            {hidePosition &&
                                <div className={style("vacation")}>
                                    {this.getNearestVacation()?.name}
                                </div>
                            }
                        {hidePosition
                            ? null
                            : (showPosition
                                && viewType !== ViewType.PLAIN
                                && this.state.openMembers
                                && entity.contentType === "Group"
                                && entity.childrenCount
                                && entity.id)
                                ? <CPopover
                                    element={<CUserVacationCaption
                                        position={customDescription ? customDescription : position}
                                        vacation={this.getNearestVacation()}
                                        isFullView={viewType === ViewType.FULL || this.props.showOnlyPosition}
                                    />}
                                    autoPosition={true}
                                    className={style("cPopover-container")}
                                    horizontalOrientation="left"
                                    ref={`popover-${entity.id}`}
                                    key={`popover-${entity.id}`}
                                    onClose={(event: React.MouseEvent<HTMLElement>) => {
                                        this.setState({
                                            openMembers: false
                                        })
                                    }}
                                    animateClass="animate-fadein"
                                    open={this.state.openMembers}
                                    noPadding={true}
                                >
                                    <CList
                                        className={style("cList-container")}
                                        customScroll={true}
                                        customScrollProps={{
                                            autoHeightMax: 251,
                                            autoHeight: true
                                        }}
                                        onEntitiesLoaded={() => {
                                            this.setState({openMembers: true})
                                        }}
                                        endpoint={`/api/v3/group/${entity.id}/participants`}
                                        listName={`list-${entity.id}`}
                                        entityRenderer={(childEntity: any): JSX.Element | JSX.Element[] => {
                                            return (
                                                <CUser
                                                    entity={childEntity}
                                                    onClick={onUserInPopoverClick && bindArg(onUserInPopoverClick, childEntity)}
                                                    className={style("item")}
                                                    sizeImg={Size.SMALL}
                                                />
                                            )
                                        }}

                                    />
                                </CPopover>
                                : <CUserVacationCaption
                                    position={customDescription ? customDescription : position}
                                    vacation={this.getNearestVacation()}
                                    isFullView={viewType === ViewType.FULL || this.props.showOnlyPosition}
                                />
                        }
                        {status && status !== "positive" &&
                        <div className={style("userStatus", status)}>
                            <CEllipsis>
                                {this.intl.formatMessage(
                                    messages[`${status}Status`],
                                    {gender: isEmployee(entity) || isContractorHuman(entity) ? entity.gender : null}
                                    )}
                            </CEllipsis>
                        </div>
                        }
                        </div>}
                    <CHover.Element>
                        <div className={style("actions", {center: Api.isContractor(entity)})}>
                            {this.props.customActions}
                        </div>
                    </CHover.Element>
                </div>
                {viewType === ViewType.FULL && isContractorHuman(entity) &&
                        this.renderContractorCompany(entity)
                }
                <CUserVacationCaption
                    position={position}
                    vacation={this.getNearestVacation()}
                    isFullView={viewType === ViewType.FULL}
                    showOnlyVacation
                    showPeriod={true}
                />
                {viewType === ViewType.FULL &&
                    <CUserContactList
                        entity={entity as Api.ContractorHuman | Api.Employee}
                        viewTypeContactInfo={this.props.viewTypeContactInfo}
                        customContactInfo={this.props.customContactInfo}
                        className={Size[this.props.sizeImg].toLowerCase()}
                        contactRenderer={this.renderContact}
                    >
                        {this.props.sameInns && this.renderContractorInn()}
                        {this.renderMessageButton()}
                    </CUserContactList>
                }
                {this.props.onClick &&
                    <div
                        className={style("dummyUser")}
                        onClick={this.onClick}
                    />}
            </div>
        </CHover.Handler>
    }
}
