import {autobind} from "core-decorators"
import Favico, {IFavico} from "favico.js"
import {FaviconChanger} from "src/bums/common/stores/types"

const bigCounterText = "∞"

@autobind
export class FaviconStore implements FaviconChanger {

    protected $favicons: IFavico[] = []

    constructor() {

        if (!window?.document) {
            return
        }

        const list = window.document.querySelectorAll("link[rel='icon'], link[rel='shortcut icon']")

        Array.prototype.forEach.call(list, (element: HTMLElement) => {
            this.$favicons.push(new Favico({
                animation: "popFade",
                element: element,
                bgColor: "#e22626",
            }))
        })
    }

    public badge(counter: number | string) {
        if (counter > 9) {
            counter = bigCounterText
        }
        this.$favicons.forEach(favicon => favicon.badge(counter))
    }

    public reset() {
        this.$favicons.forEach(favicon => favicon.reset())
    }
}
