/* tslint:disable */
import * as H from "../metadataHelper"
import {StringField} from "./stringField"
import {BoolField} from "./boolField"

export interface EditionExceed extends H.BaseEntity {
    id?: string;
    message?: string; // Сообщение об ошибке
    active?: boolean; // Активность
    contentType: "EditionExceed"; // Object type
}

export module EditionExceed {
    export const contentType: "EditionExceed" = "EditionExceed";
    export const endpoint = "/api/v3/editionExceed";

    export const newObject: EditionExceed = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const message = {contentType: "StringField" as "StringField", name: "message", isMultiple: false, isSortable: false, id: "EditionExceed.message", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const active = {contentType: "BoolField" as "BoolField", name: "active", isMultiple: false, isSortable: false, id: "EditionExceed.active", defaultValue: null as BoolField["defaultValue"]};
    }
}

export function isEditionExceed(arg: any): arg is EditionExceed {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "EditionExceed"
}
