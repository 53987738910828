/* tslint:disable:no-react-component-usage */
import * as React from "react"
import PropTypes from "prop-types"
import {Globalize} from "globalize"
import {Intl} from "./Intl"
import {
    DateFormatter,
    RelativeTimeFormatter,
} from "./formatters"
import {observer} from "mobx-react"
import {inject} from "../inject"

/**
 * Компонент, который умеет отображать переводимое сообщение.
 * Также обрабатывает react-элементы внутри переменных сообщения.
 */
export interface FormattedMessageProps {
    id: string
    defaultMessage: string
    values?: {[index: string]: string | number | React.ReactElement<any>}
    tagName?: string
}
@observer
export class FormattedMessage extends React.Component<FormattedMessageProps, {}> {

    @inject(Intl)
    private intl: Intl

    public static defaultProps = {
        tagName: "span",
        values: {},
    }
    public static propTypes = {
        id: PropTypes.string.isRequired,
        defaultMessage: PropTypes.string,
        values: PropTypes.object,
        tagName: PropTypes.string,
    }

    render() {
        // Contributed with respect from react-intl
        const {
            id,
            defaultMessage,
            values,
            tagName,
        } = this.props;

        let tokenRegexp: RegExp;
        let tokenizedValues: {[index: string]: string | number | React.ReactElement<any>};
        let elements: {[index: string]: string | number | React.ReactElement<any>};

        let hasValues = values && Object.keys(values).length > 0;
        if (hasValues) {
            // Creates a token with a random UID that should not be guessable or
            // conflict with other parts of the `message` string.
            let uid = Math.floor(Math.random() * 0x10000000000).toString(16);

            let generateToken = (() => {
                let counter = 0;
                return () => `@__ELEMENT-${uid}-${counter += 1}__@`;
            })();

            tokenRegexp     = new RegExp(`(@__ELEMENT-${uid}-\\d+__@)`, "g");
            tokenizedValues = {};
            elements        = {};

            // Iterates over the `props` to keep track of any React Element
            // values so they can be represented by the `token` as a placeholder
            // when the `message` is formatted. This allows the formatted
            // message to then be broken-up into parts with references to the
            // React Elements inserted back in.
            Object.keys(values).forEach((name) => {
                let value = values[name];

                if (React.isValidElement(value)) {
                    let token = generateToken();
                    tokenizedValues[name] = token;
                    elements[token]       = value;
                } else {
                    tokenizedValues[name] = value;
                }
            });
        }

        let descriptor       = {id, defaultMessage};
        let formattedMessage = this.intl.formatMessage(descriptor, tokenizedValues || values);

        let nodes: React.ReactNode[];

        let hasElements = elements && Object.keys(elements).length > 0;
        if (hasElements) {
            // Split the message into parts so the React Element values captured
            // above can be inserted back into the rendered message. This
            // approach allows messages to render with React Elements while
            // keeping React's virtual diffing working properly.
            nodes = formattedMessage
                .split(tokenRegexp)
                .filter((part) => !!part)
                .map((part) => elements[part] || part);
        } else {
            nodes = [formattedMessage];
        }

        return React.createElement(tagName, null, ...nodes);
    }
}

@observer
export class FormattedMessageHtml extends React.Component<FormattedMessageProps, {}> {
    @inject(Intl)
    private intl: Intl

    public static defaultProps = {
        tagName: "span",
        values: {},
    }

    render() {
        return React.createElement(
            this.props.tagName || "span",
            {dangerouslySetInnerHTML: {
                __html: this.intl.formatMessage(
                    {id: this.props.id, defaultMessage: this.props.defaultMessage},
                    this.props.values
                )
            }}
        )
    }
}

interface FormattedDateProps {
    value: Date
    format?: DateFormatter.KnownFormat
}

@observer
export class FormattedDate extends React.Component<FormattedDateProps, {}> {
    @inject(Intl)
    private intl: Intl

    render() {
        const formatName = this.props.format || "yMMMd"
        let formatter = this.intl.date.getByName(formatName)
        if (!formatter) {
            console.error(`Unknown date format ${formatName}`)
            formatter = this.intl.date.yMMMd()
        }
        return React.createElement(
            "span",
            {},
            formatter(this.props.value)
        )
    }
}


// TODO Должен быть тикающим
interface FormattedRelativeProps {
    value: number
    format?: RelativeTimeFormatter.KnownFormat | "smart"
}

@observer
export class FormattedRelative extends React.Component<FormattedRelativeProps, {}> {
    @inject(Intl)
    private intl: Intl

    render() {
        let formatter: Globalize.RelativeTimeFormatter
        const relTime = this.intl.relativeTime
        const formatName = this.props.format || "smart";
        if ("smart" === formatName) {
            formatter = relTime.hourLong() // TODO BE SMART
        } else {
            formatter = relTime.getByName(formatName as RelativeTimeFormatter.KnownFormat);
        }
        return React.createElement(
            "span",
            {},
            formatter(this.props.value)
        )
    }
}

interface FormattedNumberProps {
    value: number
}

@observer
export class FormattedNumber extends React.Component<FormattedNumberProps, {}> {
    @inject(Intl)
    private intl: Intl

    render() {
        return React.createElement(
            "span",
            {},
            this.intl.number.decimal()(this.props.value)
        )
    }
}

interface FormattedCurrencyProps {
    value: number
    currency: string
    valueFormatter?: (val: string) => JSX.Element
}

@observer
export class FormattedCurrency extends React.Component<FormattedCurrencyProps, {}> {
    @inject(Intl)
    private intl: Intl

    render() {
        const formatter = this.intl.currency.symbol(this.props.currency)

        // получение символа из нулевого значения
        const emptyValue = formatter(0)
        const symbol = emptyValue.replace(this.intl.number.decimal()(0), "").replace(/\s+/, "")

        // для определения позиции символа относительно значения
        const matches = emptyValue.match(`^(.*)${symbol}(.*)$`)
        // значение без символа
        const value = this.props.value % 1 ? this.intl.number.money()(this.props.value) + "\xa0" :
            formatter(this.props.value).replace(symbol, "")

        const parts = [
            this.props.valueFormatter ? this.props.valueFormatter(value) : value,
            React.createElement("span", {key: "currency-code", className: `currency currency-${this.props.currency}`}, symbol)
        ]

        return React.createElement(
            "span",
            {className: `currency-${this.props.currency}-wrapper`, title: "string" === typeof value ? String(value) : void 0},
            matches[1].length ? parts : parts.reverse()
        )
    }
}
