import {computed, observable} from "mobx"
import {Intl} from "src/lib/utils/intl"
import {ApiStore} from "src/lib/entities/store/ApiStore"
import {Employee, ContactInfo} from "src/lib/entities/api"
import {ContactInfoFormCollection} from "src/bums/common/form/form/ContactInfoForm"
import {AbstractModalForm} from "src/bums/common/modalForm/AbstractModalForm"
import {FormValidator, isEmpty} from "src/lib/utils/form/validation"
import {isIdUnknown} from "src/lib/entities/utils"
import {UserSettingStore} from "src/bums/common/stores/UserSettingStore"
import {autobind} from "core-decorators"
import {pick} from "lodash";

const libMessages: any = require("src/lib/messages.yml")

@autobind
export class EmployeeForm extends AbstractModalForm<Employee> {

    @observable
    public contactInfo: ContactInfoFormCollection

    @observable
    protected fieldsSettingStore: UserSettingStore<string[]>

    constructor(valueProducer: () => Employee, apiStore: ApiStore, private intl: Intl) {
        super(valueProducer, apiStore)
        this.contactInfo = this.form(
        "contactInfo",
        contactInfo => new ContactInfoFormCollection(
            () => contactInfo,
            [ContactInfo.Type.email],
            this.intl
        ))
        this.fieldsSettingStore = new UserSettingStore<string[]>(
            apiStore,
            () => "employee_card_fields",
            () => []
        )
    }

    @computed
    public get changedValues() {
        const changedKeys = Array.from(this.$changedProps.keys())
        this.$childForms.forEach((form, key) => {
            if (form.form && (form.form.isDirty || form.form.ownErrors)) {
                changedKeys.push(key)
            }
        })
        return pick(this.value, changedKeys)
    }

    public getValueForSave(value: Partial<Employee>) {
        if (value.contactInfo) {
            return {
                ...value,
                id: !value.id || isIdUnknown(value.id) ? void 0 : value.id,
                contactInfo: value.contactInfo.filter(contactInfo => contactInfo.value && !isEmpty(contactInfo.value))
            }
        }

        return value
    }

    @computed
    public get validator() {
        return this.allFields.filter(field => field.isNotConfigurable && field.name !== "email")
            .map(field => field.name)
            .reduce((validator, fieldName) => {
                validator.set(fieldName as keyof Employee, FormValidator.nonEmptyFieldValidator(
                    this.intl.formatMessage(libMessages["emptyFieldError"])
                ))
                return validator
            }, new FormValidator<Employee>())
    }

    @computed
    public get selectedFieldNames(): string[] {
        const setting = this.fieldsSettingStore.get()

        if (setting.state === "fulfilled") {
            const fieldNames = setting.value
            return fieldNames.length ? fieldNames : this.allFields.map(field => field.name)
        }

        return []
    }

    public async setSelectedFieldsNames(value: string[]) {
        await this.fieldsSettingStore.set(value)
    }

    @computed
    public get allFields() {
        return [
            {
                ...Employee.fields.lastName,
                hrName: this.intl.formatMessage(libMessages[Employee.fields.lastName.name]),
                isNotConfigurable: true
            },
            {
                ...Employee.fields.firstName,
                hrName: this.intl.formatMessage(libMessages[Employee.fields.firstName.name]),
                isNotConfigurable: true
            },
            {
                ...Employee.fields.middleName,
                hrName: this.intl.formatMessage(libMessages[Employee.fields.middleName.name]),
                isNotConfigurable: false
            },
            {
                ...ContactInfo.newObject,
                name: ContactInfo.Type.email,
                hrName: this.intl.formatMessage(libMessages[ContactInfo.Type.email]),
                isNotConfigurable: true
            },
            {
                ...ContactInfo.newObject,
                name: ContactInfo.Type.phone,
                hrName: this.intl.formatMessage(libMessages[ContactInfo.Type.phone]),
                isNotConfigurable: false
            },
            {
                ...Employee.fields.gender,
                hrName: this.intl.formatMessage(libMessages[Employee.fields.gender.name]),
                isNotConfigurable: false
            },
            {
                ...Employee.fields.position,
                hrName: this.intl.formatMessage(libMessages[Employee.fields.position.name]),
                isNotConfigurable: false
            },
        ]
    }
}
