import {computed} from "mobx"
import {inject} from "src/lib/utils/inject"
import {ListStore} from "src/lib/entities/store/ListStore"
import {ApiStore} from "src/lib/entities/store/ApiStore"
import {ContractorType} from "src/lib/entities/api"

export abstract class AbstractContractorTypesStore {

    protected abstract get $endpoint(): string

    protected $listStore: ListStore<ContractorType>

    constructor(@inject(ApiStore) private $apiStore: ApiStore) {
        this.$listStore = this.$apiStore.resolveList<ContractorType>(() => ({
            endpoint: this.$endpoint
        }))
    }

    @computed
    public get isCompleted() {
        return this.$listStore.loadStateNext.isCompleted() && !this.$listStore.hasMoreNext
    }

    public get listStore() {
        return this.$listStore
    }

    public get loadedItems() {
        return this.$listStore.originalItems
    }
}
