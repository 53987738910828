/* tslint:disable */
import * as H from "../metadataHelper"
import {DateOnly} from "./dateOnly"
import {Module} from "./module"
import {IntegrationInfo} from "./integrationInfo"
import {StringField} from "./stringField"
import {DateField} from "./dateField"
import {IntegerField} from "./integerField"
import {BoolField} from "./boolField"

export interface AccountInfo extends H.BaseEntity {
    permanentHost?: string; // Статичный хост присваивающийся аккаунту при создании.
    accountName?: string; // Название аккаунта
    buildVersion?: string; // Версия билда
    productName?: string; // Название тарифа
    systemProductName?: string; // Системное название тарифа (класс тарифа)
    tarifId?: string; // Ид тарифа (короткое представление, используемое в AccountTarif)
    ecometHost?: string; // Сервер вебсокета
    licenceEndDate?: DateOnly; // Срок действия лицензии
    mobileEndDate?: DateOnly; // Срок действия мобильного приложения
    paidToDate?: DateOnly; // Оплачен до
    licenceQuantity?: number; // Число лицензий
    paidLicenceQuantity?: number; // Число оплаченных лицензий
    partnerId?: number; // Id партнера
    licenseExpired?: boolean; // Лицензия истекла
    enabledModules?: H.List<Module>; // Модули мегаплана
    enabledModulesCount?: number;
    isTest?: boolean; // Является ли данная версия приложения тестовой
    megamailDomain?: string; // Домен аккаунта
    caldavSyncs?: H.List<IntegrationInfo>; // Внешние календари
    caldavSyncsCount?: number; // Количество внешних календарей
    id?: string; // Идентификатор
    contentType: "AccountInfo"; // Object type
}

export module AccountInfo {
    export const contentType: "AccountInfo" = "AccountInfo";
    export const endpoint = "/api/v3/accountInfo";

    export const newObject: AccountInfo = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const permanentHost = {contentType: "StringField" as "StringField", name: "permanentHost", isMultiple: false, isSortable: false, id: "AccountInfo.permanentHost", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const accountName = {contentType: "StringField" as "StringField", name: "accountName", isMultiple: false, isSortable: false, id: "AccountInfo.accountName", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const buildVersion = {contentType: "StringField" as "StringField", name: "buildVersion", isMultiple: false, isSortable: false, id: "AccountInfo.buildVersion", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const productName = {contentType: "StringField" as "StringField", name: "productName", isMultiple: false, isSortable: false, id: "AccountInfo.productName", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const systemProductName = {contentType: "StringField" as "StringField", name: "systemProductName", isMultiple: false, isSortable: false, id: "AccountInfo.systemProductName", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const tarifId = {contentType: "StringField" as "StringField", name: "tarifId", isMultiple: false, isSortable: false, id: "AccountInfo.tarifId", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const ecometHost = {contentType: "StringField" as "StringField", name: "ecometHost", isMultiple: false, isSortable: false, id: "AccountInfo.ecometHost", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const licenceEndDate = {contentType: "DateField" as "DateField", name: "licenceEndDate", isMultiple: false, isSortable: false, id: "AccountInfo.licenceEndDate", defaultValue: null as DateField["defaultValue"], refContentType: H.List(["DateOnly"])};
        export const mobileEndDate = {contentType: "DateField" as "DateField", name: "mobileEndDate", isMultiple: false, isSortable: false, id: "AccountInfo.mobileEndDate", defaultValue: null as DateField["defaultValue"], refContentType: H.List(["DateOnly"])};
        export const paidToDate = {contentType: "DateField" as "DateField", name: "paidToDate", isMultiple: false, isSortable: false, id: "AccountInfo.paidToDate", defaultValue: null as DateField["defaultValue"], refContentType: H.List(["DateOnly"])};
        export const licenceQuantity = {contentType: "IntegerField" as "IntegerField", name: "licenceQuantity", isMultiple: false, isSortable: false, id: "AccountInfo.licenceQuantity", defaultValue: null as IntegerField["defaultValue"]};
        export const paidLicenceQuantity = {contentType: "IntegerField" as "IntegerField", name: "paidLicenceQuantity", isMultiple: false, isSortable: false, id: "AccountInfo.paidLicenceQuantity", defaultValue: null as IntegerField["defaultValue"]};
        export const partnerId = {contentType: "IntegerField" as "IntegerField", name: "partnerId", isMultiple: false, isSortable: false, id: "AccountInfo.partnerId", defaultValue: null as IntegerField["defaultValue"]};
        export const licenseExpired = {contentType: "BoolField" as "BoolField", name: "licenseExpired", isMultiple: false, isSortable: false, id: "AccountInfo.licenseExpired", defaultValue: null as BoolField["defaultValue"]};
        export const enabledModules = {contentType: "RefLinkField" as "RefLinkField", name: "enabledModules", isMultiple: true, isSortable: false, id: "AccountInfo.enabledModules", refContentType: H.List(["Module"])};
        export const enabledModulesCount = {contentType: "IntegerField" as "IntegerField", name: "enabledModulesCount", isMultiple: false, isSortable: false, id: "AccountInfo.enabledModulesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const isTest = {contentType: "BoolField" as "BoolField", name: "isTest", isMultiple: false, isSortable: false, id: "AccountInfo.isTest", defaultValue: null as BoolField["defaultValue"]};
        export const megamailDomain = {contentType: "StringField" as "StringField", name: "megamailDomain", isMultiple: false, isSortable: false, id: "AccountInfo.megamailDomain", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const caldavSyncs = {contentType: "RefLinkField" as "RefLinkField", name: "caldavSyncs", isMultiple: true, isSortable: false, id: "AccountInfo.caldavSyncs", refContentType: H.List(["IntegrationInfo"])};
        export const caldavSyncsCount = {contentType: "IntegerField" as "IntegerField", name: "caldavSyncsCount", isMultiple: false, isSortable: false, id: "AccountInfo.caldavSyncsCount", defaultValue: null as IntegerField["defaultValue"]};
    }
}

export function isAccountInfo(arg: any): arg is AccountInfo {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "AccountInfo"
}
