import {autobind} from "core-decorators"
import {AbstractDragNDropStore} from "src/bums/common/globalDrag/AbstractDragNDropStore"
import {FileDropHandler} from "./types"

@autobind
export class FileDropAreaStore extends AbstractDragNDropStore {

    protected isEventSupported(event: DragEvent): boolean {
        return event.dataTransfer.types.includes("File") || event.dataTransfer.types.includes("Files")
    }

    private $bindCache: {
        onDragEnter: (event: any) => void,
        onDragLeave: (event: any) => void,
        onDrop: (event: any) => void,
    }

    constructor(
        private $globalFileDropStore: AbstractDragNDropStore,
        private dropHandler: FileDropHandler,
        private storeOptions: {
            onBeforeDrop?: () => void,
            onAfterDrop?: () => void,
            isMultiple: boolean
        }
    ) {
        super()
    }

    private onDrop(event: DocumentEventMap["drop"]) {
        if (event.preventDefault) {
            event.preventDefault()
        }

        if (!event.dataTransfer.files || event.dataTransfer.files.length === 0) {
            return false
        }

        if (this.storeOptions.onBeforeDrop) {
            this.storeOptions.onBeforeDrop()
        }
        // convert FileList to File[]
        const fileArr: File[] = []
        const files = event.dataTransfer.files
        const maxCount = this.storeOptions.isMultiple ? files.length : 1
        for (let i = 0; i < maxCount; i++) {
            fileArr.push(files[i])
        }

        if (this.dropHandler) {
            this.dropHandler(fileArr)
        }

        if (this.storeOptions.onAfterDrop) {
            this.storeOptions.onAfterDrop()
        }
        this.reset(event)
        this.$globalFileDropStore.reset(event)
        return false
    }

    public bindElement() {
        if (!this.$bindCache) {
            this.$bindCache = {
                onDragEnter: this.enterLeaveHandler,
                onDragLeave: this.enterLeaveHandler,
                onDrop: this.onDrop
            }
        }
        return this.$bindCache
    }
}
