import * as React from "react"
import classNames from "classnames/bind"
import {observer} from "mobx-react"
import {computed} from "mobx"
import {autobind} from "core-decorators"
import {INFORMER_HINT, MODULES_HINT, PROGRAM_ID, TRACKED_ELEMENT_CLASS_NAME} from "src/bums/common/onboarding/tutorial/tracker"
import {OnBoardingComponent} from "src/bums/common/onboarding/tutorial/types"
import {StickyStore} from "src/bums/common/sticky/StickyStore"
import {Tracker} from "src/bums/common/stores/Tracker"
import {CHint, Component} from "src/lib/components"
import {inject} from "src/lib/utils/inject"
import {Router} from "src/lib/utils/router"
import {CModularHint} from "./CModularHint"
import {CInformerHint} from "./CInformerHint"

export namespace CGlobalHintBubbleContainer {
   export interface Props extends OnBoardingComponent {
   }
}

const style = classNames.bind(require("./styles/CModulesHint.styl"))

@observer
@autobind
export class CGlobalHintBubbleContainer extends Component<CGlobalHintBubbleContainer.Props, {}> {

    @inject(StickyStore)
    private stickyStore: StickyStore

    @inject(Tracker)
    private tracker: Tracker

    @inject(Router)
    private router: Router

    public componentDidMount() {
        document.addEventListener("mousedown", this.onOutsideClick)
    }

    public componentWillUnmount() {
        document.removeEventListener("mousedown", this.onOutsideClick)
    }

    private renderContent() {
        switch (this.props.store.currentStep) {
            case "tutorialModal":
                return <CModularHint onNextClick={this.props.store.nextStep}/>
            case "tutorialInformer":
                return <CInformerHint onNextClick={this.props.store.cancel}/>
            default:
                return null
        }
    }

    @computed
    private get containerPosition() {
        switch (this.props.store.currentStep) {
            case "tutorialModal":
                return CModularHint.getContainerPosition(this.stickyStore)
            case "tutorialInformer":
                return CInformerHint.getContainerPosition()
            default:
                return {}
        }
    }

    @computed
    private get hintProps() {
        switch (this.props.store.currentStep) {
            case "tutorialModal":
                return CModularHint.getHintProps(this.stickyStore)
            case "tutorialInformer":
                return CInformerHint.getHintProps()
            default:
                return {}
        }
    }

    private onOutsideClick(event: MouseEvent) {
        const target = event.target as HTMLElement
        switch (this.props.store.currentStep) {
            case "tutorialModal":
                if (target.closest(`.${TRACKED_ELEMENT_CLASS_NAME}`)) {
                    this.tracker.trackEvent(PROGRAM_ID, MODULES_HINT.step, MODULES_HINT.moduleClick)
                    this.props.store.nextStep()
                }
                break
            case "tutorialInformer":
                if (target.closest(`.informer`)) {
                    this.tracker.trackEvent(PROGRAM_ID, INFORMER_HINT.step, INFORMER_HINT.informerClick)
                    this.props.store.nextStep()
                }
        }
    }

    private onCancelTutorial() {
        switch (this.props.store.currentStep) {
            case "tutorialModal":
                this.tracker.trackEvent(PROGRAM_ID, MODULES_HINT.step, MODULES_HINT.cancel)
                break
            case "tutorialInformer":
                this.tracker.trackEvent(PROGRAM_ID, INFORMER_HINT.step, INFORMER_HINT.cancel)
                break
        }
        this.props.store.cancel()
    }

    public render() {
        if (!this.props.store.currentStep || this.router.location.pathname.includes("prefs")) {
            return null
        }
        return <div style={this.containerPosition} className={style("backDrop")}>
            <CHint
                open
                noStyle
                disableOutsideClick
                type={"dark"}
                onClose={this.onCancelTutorial}
                {...this.hintProps}
            >
                {this.renderContent()}
            </CHint>
        </div>
    }
}
