/* tslint:disable */
import * as H from "../metadataHelper"
import {StringField} from "./stringField"

export interface DeliveryType extends H.BaseEntity {
    name?: string;
    id?: string; // Идентификатор
    contentType: "DeliveryType"; // Object type
}

export module DeliveryType {
    export const contentType: "DeliveryType" = "DeliveryType";
    export const endpoint = "/api/v3/deliveryType";

    export const newObject: DeliveryType = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "DeliveryType.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
    }
}

export function isDeliveryType(arg: any): arg is DeliveryType {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "DeliveryType"
}
