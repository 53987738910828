import {Component} from "src/lib/components/Component/Component";
import {ApiStore} from "src/lib/entities/store/ApiStore";
import {CTestDriveAvailableModal} from "src/bums/common/testDriveFeature/CTestDriveAvailableModal";
import * as React from "react";
import {inject} from "src/lib/utils/inject";
import {autobind} from "core-decorators";
import {action, observable, runInAction} from "mobx";
import {observer} from "mobx-react";
import {ApiError} from "src/lib/entities/types";
import * as Api from "src/lib/entities/api"
import {Tracker} from "src/bums/common/stores/Tracker";
import {UserStore} from "src/lib/entities/api";

const TEST_DRIVE_AVAILABLE_TYPE = "sdf\\feature\\exceptions\\FeatureTestDriveAvailable";

@autobind
@observer
export class CTestDriveFeature extends Component<{}, {}> {

    @inject(ApiStore)
    private apiStore: ApiStore

    @inject(Tracker)
    private tracker: Tracker

    @inject(UserStore)
    private userStore: UserStore

    @observable
    private openTestDriveAvailableModal: boolean

    @observable
    private message: string

    @observable
    private featureName: string

    componentDidMount() {
        if (this.userStore.user.possibleActions.includes(Api.Employee.PossibleActions.can_start_feature_test_drive)) {
            this.apiStore.subscribeToApiErrors(TEST_DRIVE_AVAILABLE_TYPE, this.showTestDriveAvailableModal)
        }
    }

    componentWillUnmount(): void {
        if (this.userStore.user.possibleActions.includes(Api.Employee.PossibleActions.can_start_feature_test_drive)) {
            this.apiStore.unsubscribeToApiErrors(TEST_DRIVE_AVAILABLE_TYPE, this.showTestDriveAvailableModal)
        }
    }

    @action
    onCloseHandler() {
        this.openTestDriveAvailableModal = false
        this.tracker.trackEvent("cancelTestDriveFeature", this.featureName)
    }

    @action
    onConfirmHandler() {
        this.apiStore.fetchEntities(
            `/api/v3/feature/handleTestDrive`,
            {
                method: "POST",
                bodyEntity: {
                    ...Api.Variable.newObject,
                    name: "feature",
                    value: this.featureName
                }
            }
        ).then(res => {
            runInAction(() => {
                this.openTestDriveAvailableModal = false
                this.message = null
                this.featureName = null
            })
            const updatedFeature = res.value.data[0] as Api.Feature
            window.dispatchEvent(new CustomEvent("testDriveStart", {detail: {feature: updatedFeature.name}}))
        })
    }

    @action
    showTestDriveAvailableModal(error: ApiError) {
        this.openTestDriveAvailableModal = true
        this.message = error.message
        this.featureName = (error.meta as {feature: string}).feature
    }

    render() {
        return <CTestDriveAvailableModal
            open={this.openTestDriveAvailableModal}
            message={this.message}
            onClose={this.onCloseHandler}
            onConfirm={this.onConfirmHandler}
        />
    }
}
