import classNames from "classnames/bind"
import * as React from "react"
import {observer} from "mobx-react"
import {autobind} from "core-decorators"
import {CButton, Component, CSmartCard, CStyledContent, CFlex} from "src/lib/components"
import {FormattedMessage, FormattedMessageHtml} from "src/lib/utils/intl/components"
import {INFORMER_PANEL_HEIGHT} from "src/bums/common/constants"

export namespace CInformerHint {
   export interface Props {
       onNextClick: () => void
   }
}

const style = classNames.bind(require("./styles/CModulesHint.styl"))
const messages = require("../messages.yml")
const RIGHT_OFFSET = 420
const HINT_WIDTH = 420
const chatHelpLink = "https://newhelp.megaplan.ru/article/dashboard/cis/chatinf"
const globalSearchHelpLink = "https://newhelp.megaplan.ru/article/dashboard/cis/search"

@observer
@autobind
export class CInformerHint extends Component<CInformerHint.Props, {}> {

    private onNextClick() {
        if (this.props.onNextClick) {
            this.props.onNextClick()
        }
    }

    public render() {
        return <React.Fragment>
            <CSmartCard.Header>
                <FormattedMessage {...messages["informerTutorial"]} />
            </CSmartCard.Header>
            <CSmartCard.Content>
                <CStyledContent className={style("paragraph")}>
                    <FormattedMessage {...messages["informerDescription"]} />
                    <div className={style("list")}>
                        <FormattedMessageHtml {...messages["informerDescriptionRequests"]} values={{linkHref: chatHelpLink}} />
                    </div>
                    <div className={style("list")}>
                        <FormattedMessage {...messages["informerDescriptionDiscussion"]} />
                    </div>
                    <div className={style("list")}>
                        <FormattedMessage {...messages["informerDescriptionFavorite"]} />
                    </div>
                </CStyledContent>
                <CStyledContent>
                    <FormattedMessageHtml {...messages["telephonyDescription"]} values={{linkHref: globalSearchHelpLink}}/>
                </CStyledContent>
            </CSmartCard.Content>
            <CSmartCard.Footer>
                <CFlex.Divider />
                <CFlex.Item>
                    <CButton
                        name="close"
                        caption={<FormattedMessage {...messages["close"]} />}
                        onClick={this.onNextClick}
                        type={CButton.Type.FLAT_SUCCESS}
                    />
                </CFlex.Item>
            </CSmartCard.Footer>
        </React.Fragment>
    }
}

export namespace CInformerHint {
   export function getContainerPosition() {
       return {bottom: INFORMER_PANEL_HEIGHT}
   }
   export function getHintProps() {
       return {
            verticalOrientation: "bottom" as "bottom",
            horizontalOrientation: "right" as "right",
            position: {
                top: window.innerHeight - INFORMER_PANEL_HEIGHT,
                left: window.innerWidth - RIGHT_OFFSET,
            },
            maxWidth: HINT_WIDTH
        }
   }
}
