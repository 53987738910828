export const TYPE_FULL_HEIGHT = 97
export const TYPE_SMALL_HEIGHT = 40
export const TYPE_LINE_HEIGHT = 4

export const PROFILE_MENU_ITEMS_SORTED = ["profile", "news", "settings", "tests", "addons", "faq", "tariff"]
export const SETTINGS_ITEM_ID = "settings"
export const SETTINGS_ITEM_ACCOUNT_ID = "account"

export const TIMEOUT_THROTTLE_RESIZE = 200
export const TIMEOUT_REFRESH_NEWS_UNREAD_COUNTER = 3600000

export const MIN_HEIGHT_FOR_LINE_TYPE = 800

export const DRAGGED_MENU_ITEM_CLASS_NAME = "draggedMenuItem"
export const NOT_DRAGGED_CLASS_NAME = "notDragged"

