import * as React from "react"
import classNames from "classnames/bind"
import {observer} from "mobx-react"
import {autobind} from "core-decorators"
import {bindArg} from "src/lib/utils/func"
import {LayoutCardNameContext, CLayoutStore} from "src/bums/common/userInterface/context"
import {CSticky} from "src/bums/common/sticky/CSticky"
import {CErrorHandler, Component} from "src/lib/components"

const style = classNames.bind(require("./CPageLayout.styl"))

export namespace CPageLayout {
    export interface CommonProps {
        breadcrumbs?: JSX.Element
        header?: JSX.Element
        tabs?: JSX.Element
        forcedContent?: JSX.Element
        cardRender: (wideColumn: boolean, blockName: string) => null | JSX.Element
        //TODO убрать этот пропс
        isTwoIdenticalColumns?: boolean
    }

    export interface Props extends CommonProps {
        layoutStore: CLayoutStore.Stores["layoutStore"]
    }

    export interface LayoutTypeProp {
        //TODO убрать этот пропс
        isTwoIdenticalColumns?: boolean
        layoutStore: CLayoutStore.Stores["layoutStore"]
        cardRender: (wideColumn: boolean, name: string) => null | JSX.Element
    }

    export interface ColumnListProp {
        cardNames: string[]
        cardRender: (name: string) => null | JSX.Element
        layoutStore: CLayoutStore.Stores["layoutStore"]
        useSticky?: boolean
        className?: string
    }

    export interface CardOuterWrapperProp {
        name: string
        cardRender: (name: string) => null | JSX.Element
        layoutStore: CLayoutStore.Stores["layoutStore"]
    }

    export interface CardInnerWrapperProp {
        collapsed?: boolean
        isMainCard?: boolean
        title?: string
        className?: string
    }
}


@observer
export class CCardInnerWrapper extends Component<CPageLayout.CardInnerWrapperProp, {}> {

    public render() {
        return <div className={style("CCardInnerWrapper", {collapsed: this.props.collapsed}, this.props.className)}>
            {this.props.children}
        </div>
    }
}

@observer
@autobind
class CCardOuterWrapper extends Component<CPageLayout.CardOuterWrapperProp, {}> {

    public render() {
        const {name} = this.props

        const element = this.props.cardRender(name)

        if (!element) {
            return null
        }

        return <CErrorHandler
            children={
                <LayoutCardNameContext.Provider value={name}>
                    {element}
                </LayoutCardNameContext.Provider>
            }
        />
    }
}


@observer
export class CColumnList extends Component<CPageLayout.ColumnListProp, {}> {
    public render() {
        return <div className={style("CColumnList", this.props.className)}>
            {this.props.cardNames.map(name =>
                <CCardOuterWrapper
                    key={name}
                    name={name}
                    cardRender={this.props.cardRender}
                    layoutStore={this.props.layoutStore}
                />
            )}
        </div>
    }
}

@observer
@autobind
class CDesktopLayout extends Component<CPageLayout.LayoutTypeProp, {}> {
    public render() {
        const {layoutStore, cardRender, isTwoIdenticalColumns} = this.props

        if ((layoutStore.viewLeft.length === 0 || layoutStore.viewRight.length === 0) && layoutStore.viewCenter.length === 0) {
            return <div className={style("CDesktopLayout", "full", "gridWrapper")}>
                <div className={style("gridColumn")}>
                    <CColumnList
                        cardNames={layoutStore.viewLeft || layoutStore.viewRight}
                        cardRender={bindArg(cardRender, false)}
                        layoutStore={layoutStore}
                    />
                </div>
            </div>
        }
        const threeColumnsLayout = !!layoutStore.viewLeft.length && !!layoutStore.viewCenter.length && !!layoutStore.viewRight.length
        return <div
            className={style(
                "CDesktopLayout",
                "gridWrapper",
                {
                    "threeColumnsLayout": threeColumnsLayout,
                    twoIdenticalColumns: isTwoIdenticalColumns
                }
            )}
        >
            <div className={style("gridColumn")}>
                <CSticky isCard={true} stickyBehavior={CSticky.StickyBehavior.SMART}>
                    <CColumnList
                        cardNames={layoutStore.viewLeft}
                        cardRender={bindArg(cardRender, true)}
                        layoutStore={layoutStore}
                        className={style("left")}
                    />
                </CSticky>
            </div>
            {layoutStore.viewCenter.length > 0 &&
            <div className={style("gridColumn")}>
                <CSticky isCard={true} stickyBehavior={CSticky.StickyBehavior.SMART}>
                    <CColumnList
                        cardNames={layoutStore.viewCenter}
                        cardRender={bindArg(cardRender, false)}
                        layoutStore={layoutStore}
                    />
                </CSticky>
            </div>}
            <div className={style("gridColumn")}>
                <CSticky isCard={true} stickyBehavior={CSticky.StickyBehavior.SMART}>
                    <CColumnList
                        cardNames={layoutStore.viewRight}
                        cardRender={bindArg(cardRender, false)}
                        layoutStore={layoutStore}
                        className={style("right")}
                    />
                </CSticky>
            </div>
        </div>
    }
}

@observer
@autobind
class CTabletLayout extends Component<CPageLayout.LayoutTypeProp, {}> {
    public render() {
        const {layoutStore, cardRender} = this.props

        const isFull = layoutStore.viewLeft.length === 0 || layoutStore.viewRight.length === 0

        return <div className={style("CTabletLayout")}>
            <CColumnList
                cardNames={layoutStore.viewTop}
                cardRender={bindArg(cardRender, true)}
                layoutStore={layoutStore}
                className={style("top")}
            />
            <div className={style("gridWrapper", {full: isFull})}>
                {layoutStore.viewLeft.length > 0 &&
                    <CColumnList
                        cardNames={layoutStore.viewLeft}
                        cardRender={bindArg(cardRender, false)}
                        layoutStore={layoutStore}
                    />
                }
                {layoutStore.viewRight.length > 0 &&
                    <CColumnList
                        cardNames={layoutStore.viewRight}
                        cardRender={bindArg(cardRender, false)}
                        layoutStore={layoutStore}
                    />
                }
            </div>
            <CColumnList
                cardNames={layoutStore.viewBottom}
                cardRender={bindArg(cardRender, true)}
                layoutStore={layoutStore}
                className={style("bottom")}
            />
        </div>
    }
}


@observer
@autobind
class CPageLayoutInner extends Component<CPageLayout.Props, {}> {

    public render() {
        return <div className={style("CPageLayout")}>
            <div className={style("topRow")}>
                {this.props.breadcrumbs}
                {this.props.header}
            </div>
            {this.props.tabs &&
                <div className={style("topRow")}>
                    {this.props.tabs}
                </div>
            }
            {this.props.forcedContent
                ? <div className={style("forcedContent")}>{this.props.forcedContent}</div>
                : this.props.layoutStore.viewMode === "desktop"
                    ? <CDesktopLayout
                        layoutStore={this.props.layoutStore}
                        cardRender={this.props.cardRender}
                        isTwoIdenticalColumns={this.props.isTwoIdenticalColumns}
                    />
                    : <CTabletLayout
                        layoutStore={this.props.layoutStore}
                        cardRender={this.props.cardRender}
                        isTwoIdenticalColumns={this.props.isTwoIdenticalColumns}
                    />
            }
        </div>
    }
}

@observer
export class CPageLayout extends Component<CPageLayout.Props, {}> {
    public render() {
        return <CLayoutStore>
            {(store: CLayoutStore.Stores) => <CPageLayoutInner layoutStore={store.layoutStore} {...this.props} />}
        </CLayoutStore>
    }
}


