/* tslint:disable */
import * as H from "../metadataHelper"
import {Program} from "./program"
import {StringField} from "./stringField"
import {EnumField} from "./enumField"
import {BoolField} from "./boolField"

export interface FilterGroup extends H.BaseEntity {
    name?: string; // Название
    type?: FilterGroup.Type; // Тип
    availableActions?: H.List<FilterGroup.AvailableActions>; // Доступные действия
    filtersOrder?: H.List<string>; // Сохранённый порядок фильтров в группе.
    expanded?: boolean; // Раскрытость группы
    program?: Program; // Схема сделки
    id?: string; // Идентификатор
    contentType: "FilterGroup"; // Object type
}

export module FilterGroup {
    export const contentType: "FilterGroup" = "FilterGroup";
    export const endpoint = "/api/v3/filterGroup";
    export type Type = "crm"|"employee"|"task"|"todo"|"trade";
    export module Type {
        export const crm: Type = "crm";
        export const employee: Type = "employee";
        export const task: Type = "task";
        export const todo: Type = "todo";
        export const trade: Type = "trade";
    }
    export type AvailableActions = "edit";
    export module AvailableActions {
        export const edit: AvailableActions = "edit";
    }
    export const newObject: FilterGroup = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "FilterGroup.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const type = {contentType: "EnumField" as "EnumField", name: "type", isMultiple: false, isSortable: false, id: "FilterGroup.type", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["crm", "employee", "task", "todo", "trade"])};
        export const availableActions = {contentType: "EnumField" as "EnumField", name: "availableActions", isMultiple: true, isSortable: false, id: "FilterGroup.availableActions", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["edit"])};
        export const filtersOrder = {contentType: "StringField" as "StringField", name: "filtersOrder", isMultiple: true, isSortable: false, id: "FilterGroup.filtersOrder", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const expanded = {contentType: "BoolField" as "BoolField", name: "expanded", isMultiple: false, isSortable: false, id: "FilterGroup.expanded", defaultValue: null as BoolField["defaultValue"]};
        export const program = {contentType: "RefLinkField" as "RefLinkField", name: "program", isMultiple: false, isSortable: false, id: "FilterGroup.program", refContentType: H.List(["Program"])};
    }
}

export function isFilterGroup(arg: any): arg is FilterGroup {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "FilterGroup"
}
