/* tslint:disable */
import * as H from "../metadataHelper"
import {Employee} from "./employee"
import {StringField} from "./stringField"
import {BoolField} from "./boolField"
import {IntegerField} from "./integerField"

export interface InviteLink extends H.BaseEntity {
    uuid?: string;
    enabled?: boolean;
    employees?: H.List<Employee>;
    employeesCount?: number;
    id?: string; // Идентификатор
    contentType: "InviteLink"; // Object type
}

export module InviteLink {
    export const contentType: "InviteLink" = "InviteLink";
    export const endpoint = "/api/v3/inviteLink";

    export const newObject: InviteLink = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const uuid = {contentType: "StringField" as "StringField", name: "uuid", isMultiple: false, isSortable: false, id: "InviteLink.uuid", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const enabled = {contentType: "BoolField" as "BoolField", name: "enabled", isMultiple: false, isSortable: false, id: "InviteLink.enabled", defaultValue: null as BoolField["defaultValue"]};
        export const employees = {contentType: "RefLinkField" as "RefLinkField", name: "employees", isMultiple: true, isSortable: false, id: "InviteLink.employees", refContentType: H.List(["Employee"])};
        export const employeesCount = {contentType: "IntegerField" as "IntegerField", name: "employeesCount", isMultiple: false, isSortable: false, id: "InviteLink.employeesCount", defaultValue: null as IntegerField["defaultValue"]};
    }
}

export function isInviteLink(arg: any): arg is InviteLink {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "InviteLink"
}
