import * as React from "react"
import {observer} from "mobx-react"
import classNames from "classnames/bind"
import * as Api from "src/lib/entities/api"
import {CEllipsis, CAvatar, CIconSize, Component} from "src/lib/components"
import CFileExtIcon from "src/lib/components/CFileExtIcon/CFileExtIcon";
import {Size, sizeType} from "src/lib/types";

const style: any = classNames.bind(require("./CBadge.styl"))

export namespace CBadge {
   export interface Props {
       title?: string | JSX.Element
       subTitle?: string | JSX.Element
       avatar?: Api.Employee | Api.Group | Api.Contractor | Api.DocVersion | Api.Payer | string | Api.InvitedEmployee | Api.Topic
       avatarSize?: Size
       noEllipsis?: boolean
       noPadding?: boolean
       onClick?: () => void
       onMouseEnter?: () => void
       hovered?: boolean
       className?: string
       titleClassName?: string
       align?: "left" | "right"
       isColumnSelectOption?: boolean
       size?: sizeType
   }
}

@observer
export class CBadge extends Component<CBadge.Props, {}> {

    public static defaultProps = {
        align: "left"
    }

    private renderAvatar = () => {
        const {avatar, avatarSize} = this.props

        if (Api.isDocVersion(avatar)) {
            const extension = avatar.file ? avatar.file.extension : "txt"
            return <CFileExtIcon extension={extension} className={style("image")} size={CIconSize.MEDIUM}/>
        } else {
            return <CAvatar entity={avatar} size={avatarSize ? avatarSize : CAvatar.SizeType.SMALL} className={style("image")} />
        }
    }

    public render() {
        const {title, subTitle, noEllipsis, noPadding, avatar, align, size, titleClassName, hovered, isColumnSelectOption} = this.props
        const titleIsCustom = React.isValidElement(title)

        const className = style(
            "wrapper",
            this.props.className,
            {hovered, noPadding, columnSelect: isColumnSelectOption, clickable: !!this.props.onClick}
            )

        return <div className={className} onClick={this.props.onClick} onMouseEnter={this.props.onMouseEnter}>
            {avatar && this.renderAvatar()}
            <div className={style("content", align)}>
                <div
                    className={style("title", size, titleClassName, {"noEllipsis": noEllipsis} )}
                    title={titleIsCustom ? void 0 : title as string}
                >
                    {titleIsCustom && title}
                    {!titleIsCustom && (noEllipsis ? title : <CEllipsis>{title}</CEllipsis>)}
                </div>
                {subTitle &&
                    <div className={style("description")}>
                        {noEllipsis ? subTitle : <CEllipsis>{subTitle}</CEllipsis>}
                    </div>
                }
            </div>
        </div>
    }
}
