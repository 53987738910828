import * as React from "react"
import classNames from "classnames/bind"
import {observer} from "mobx-react"
import {computed, reaction, IReactionDisposer} from "mobx"
import * as Api from "src/lib/entities/api"
import {FormattedMessage} from "src/lib/utils/intl"
import {inject} from "src/lib/utils/inject"
import {CIcon, CSmartCard, Component} from "src/lib/components"

const style = classNames.bind(require("./theme/CIntegrationWidgetPlaceholderContainer.styl"))
const libMessages = require("src/lib/messages.yml")

declare var window: Window & {
    $: any
    bums_integration_fill_widgets: (...args: any[]) => void
    a9n: {
        publish: (eventName: string, widgetUuids: string[]) => void
    }
}


export namespace CIntegrationWidgetPlaceholderContainer {
    export interface Props {
        isCard?: boolean
        placeholder: Api.IntegrationWidget.Placeholder
        uuid?: string
        isInline?: boolean
        triggerOnRefresh?: () => any // коллбек должен калькулировать зависимости
        onMouseDown?: (...args: any[]) => void
    }

    export interface ContentProps {
        uuid: string,
        widgetsUUIDs: string[],
        placeholder: string,
        widgetData: any[],
        isInline: boolean,
        onMouseDown?: (...args: any[]) => void
    }
}

@observer
export class CIntegrationWidgetPlaceholderContainer extends Component<CIntegrationWidgetPlaceholderContainer.Props, {}> {

    @inject(Api.Store)
    private apiStore: Api.Store

    private widgetList: Api.ListStore<Api.IntegrationWidget>

    private placeholderUUID = Math.random().toString(36).substr(2)

    private triggerOnRefreshReaction: IReactionDisposer

    private widgetContentRef = React.createRef<Content>()

    constructor(props: CIntegrationWidgetPlaceholderContainer.Props, context: {}) {
        super(props, context)

        this.widgetList = this.apiStore.resolveList(() => ({
            endpoint: Api.IntegrationWidget.endpoint,
            options: {
                placeholders: [this.props.placeholder],
                fields: [Api.IntegrationWidget.fields.application.name],
            }
        }))

        this.triggerOnRefreshReaction = reaction(
            () => this.props.triggerOnRefresh?.(),
            () => this.widgetContentRef.current?.sendUpdateEvent(),
        )
    }

    public componentWillUnmount() {
        this.triggerOnRefreshReaction()
    }

    @computed
    private get widgetsUUIDs() {
        const currentUuid = this.props.uuid

        return this.widgetList.originalItems.reduce((result: string[], widget: Api.IntegrationWidget) => {
            if (currentUuid && widget.uuid !== currentUuid) {
                return result
            }

            result.push(widget.uuid)

            return result
        }, [])
    }

    @computed
    private get widgetData() {
        return this.widgetList.originalItems.toArray()
    }

    public render() {
        if (!this.widgetList.isCompleted || this.widgetList.originalItems.length === 0) {
            return null
        }

        // рендерим div в которые window.bums_integration_fill_widgets потом вставит код виджетов
        const contentElement = <Content
            uuid={this.placeholderUUID}
            widgetsUUIDs={this.widgetsUUIDs}
            placeholder={this.props.placeholder}
            widgetData={this.widgetData}
            isInline={this.props.isInline}
            onMouseDown={this.props.onMouseDown}
            ref={this.widgetContentRef}
        />

        if (!this.props.isCard) {
            return contentElement
        }

        return CSmartCard.cardToggler((isCollapsed, toggle) => {
            const caption = <FormattedMessage {...libMessages["application"]} values={{num: 0}} />

            if (isCollapsed) {
                return <CSmartCard.LayoutWrapper collapsed={true}>
                    <CSmartCard.Collapser icon={CIcon.Type.DEVELOPER_BOARD} caption={caption} onToggle={toggle}/>
                </CSmartCard.LayoutWrapper>
            }

            return <CSmartCard.LayoutWrapper>
                <CSmartCard>
                    <CSmartCard.Header onToggle={toggle}>
                        {caption}
                    </CSmartCard.Header>
                    <CSmartCard.Content>
                        {contentElement}
                    </CSmartCard.Content>
                </CSmartCard>
            </CSmartCard.LayoutWrapper>
        })
    }
}


class Content extends Component<CIntegrationWidgetPlaceholderContainer.ContentProps, {}> {

    componentWillUnmount() {
        window.a9n.publish("componentWillUnmount", this.props.widgetsUUIDs)
    }

    componentDidMount() {
        window.bums_integration_fill_widgets(window.$, this.props.uuid, this.props.widgetData)
        window.a9n.publish("componentDidMount", this.props.widgetsUUIDs)
    }

    public sendUpdateEvent() {
        window.a9n.publish("componentDidUpdate", this.props.widgetsUUIDs);
    }

    render() {
        return <div
            id={this.props.uuid}
            className={"integration widget " + style(this.props.isInline ? "inlineFlex" : "")}
            data-placeholder={this.props.placeholder}
            onMouseDown={this.props.onMouseDown}
        />
    }
}
