/* tslint:disable */
import * as H from "../metadataHelper"
import {StringField} from "./stringField"

export interface InviteLinkRegistration extends H.BaseValue {
    uuid?: string;
    lastName?: string;
    firstName?: string;
    email?: string;
    password?: string;
    photoRawBase64?: string; // Строка с содержимым файла, закодированным в base64
    contentType: "InviteLinkRegistration"; // Object type
}

export module InviteLinkRegistration {
    export const contentType: "InviteLinkRegistration" = "InviteLinkRegistration";
    export const endpoint = "/api/v3/inviteLinkRegistration";

    export const newObject: InviteLinkRegistration = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const uuid = {contentType: "StringField" as "StringField", name: "uuid", isMultiple: false, isSortable: false, id: "InviteLinkRegistration.uuid", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const lastName = {contentType: "StringField" as "StringField", name: "lastName", isMultiple: false, isSortable: false, id: "InviteLinkRegistration.lastName", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const firstName = {contentType: "StringField" as "StringField", name: "firstName", isMultiple: false, isSortable: false, id: "InviteLinkRegistration.firstName", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const email = {contentType: "StringField" as "StringField", name: "email", isMultiple: false, isSortable: false, id: "InviteLinkRegistration.email", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const password = {contentType: "StringField" as "StringField", name: "password", isMultiple: false, isSortable: false, id: "InviteLinkRegistration.password", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const photoRawBase64 = {contentType: "StringField" as "StringField", name: "photoRawBase64", isMultiple: false, isSortable: false, id: "InviteLinkRegistration.photoRawBase64", defaultValue: null as StringField["defaultValue"], maxLength: 0};
    }
}

export function isInviteLinkRegistration(arg: any): arg is InviteLinkRegistration {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "InviteLinkRegistration"
}
