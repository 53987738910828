import {observable, computed, action, runInAction} from "mobx"
import {autobind} from "core-decorators"
import {isFetchError} from "src/lib/entities/api"
import * as Api from "src/lib/entities/api"
import * as Collections from "src/lib/collections"
import {isApiErrorResponse} from "src/lib/entities/types"
import {Form} from "src/lib/utils/form/form"
import {FormCollection} from "src/lib/utils/form/formCollection"
import {createFetchErrors} from "src/lib/utils/form/types"
import {FormValidator} from "src/lib/utils/form/validation"
import {Intl} from "src/lib/utils/intl/Intl"

export namespace InviteForm {
    export interface Value {
        email: string,
        name: string
    }
}

const EMPTY_FORMS_COUNT = 2
const emailMessages = require("src/lib/components/CFieldEmail/messages.yml")

class InviteFormItem extends Form<InviteForm.Value> {

   @computed
   protected get validator() {
        const formValidator = new FormValidator<InviteForm.Value>()
        formValidator.set("email", FormValidator.nonEmptyFieldValidator("email_is_empty"))
        formValidator.set("name", FormValidator.nonEmptyFieldValidator("name_is_empty"))
        return formValidator
    }
}

function createEmployee(formValue: InviteForm.Value) {
    const [firstName, lastName, ...middleName] = formValue.name.split(" ")
    return {
        ...Api.Employee.newObject,
        firstName,
        lastName,
        middleName: middleName.join(" "),
        contactInfo: Collections.List(
            {...Api.ContactInfo.newObject, type: Api.ContactInfo.Type.email, value: formValue.email, isMain: true}
        )
    }
}

@autobind
export class InviteForm extends FormCollection<InviteForm.Value, InviteFormItem> {

    @observable
    public isPending: boolean = false

    constructor(
        valueProducer: () => Collections.List<InviteForm.Value>,
        private apiStore: Api.Store,
        private intl: Intl
    ) {
        super(valueProducer, item => new InviteFormItem(() => item))
    }

    @action
    public async submit(onSubmit?: (value: Collections.List<Api.Employee>) => void) {
        this.isPending = true
        const valueToSave = this.getValueForSave
        try {
            await this.apiStore.updateAll(valueToSave.toArray())
            if (onSubmit) {
                onSubmit(valueToSave)
            }
        } catch (e) {
            this.showErrors()
            if (isFetchError(e) && isApiErrorResponse(e.response.value)) {
                e.response.value.meta.errors.forEach((error, index) => {
                    const form = this.get(index)
                    if (form) {
                        form.form.setErrors(createFetchErrors({message: this.translateError(error.message), type: error.type}))
                    }
                })
            }
        }
        runInAction(() => this.isPending = false)
    }

    private translateError(message: string) {
        if (emailMessages[message]) {
            return this.intl.formatMessage(emailMessages[message])
        }
        return message
    }

    @computed
    public get hasValidInvitation() {
        //проверим, что все поля, в которых есть пользовательские значения валидны
        return this.childForms.find(child => child.form.isDirty)
            && this.validChildren.length === this.childForms.filter(child => child.form.isDirty).length
    }

    public addEmpty() {
        if (this.length - this.validChildren.length < EMPTY_FORMS_COUNT) {
            return this.push({...InviteForm.defaultValue})
        }
    }

    @computed
    private get validChildren() {
        return this.filter(child => child.form && child.form.isValid)
    }

    private get getValueForSave() {
        return this.validChildren
            .map(child => child.form && child.form.value)
            .filter(Boolean)
            .map(createEmployee)
    }
}

export namespace InviteForm {
    export const defaultValue = {email: "", name: ""} as InviteForm.Value
}
