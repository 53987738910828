import * as React from "react"
import {observer} from "mobx-react"
import {Component} from "src/lib/components/Component/Component"
import {BindObject} from "src/lib/utils/form/types"

export namespace FormValue {
    export type Props<V> = {
        bind: BindObject<V>
        render: (bind: BindObject<V>) => JSX.Element | null
    }
    export type ValidChild<V> = React.ReactElement<BindObject<V>>
}

@observer
export class FormValue<V> extends Component<FormValue.Props<V>, {}> {
    public render() {
        return this.props.render(this.props.bind)
    }
}
