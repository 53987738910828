import * as Api from "src/lib/entities/api"
import * as DateHelper from "src/lib/utils/intl/dateHelper"
import {Intl} from "src/lib/utils/intl/Intl"
import * as ReminderHelper from "src/bums/common/notification/reminderHelper"
import * as ScheduleHelper from "src/bums/common/notification/scheduleHelper"
import {isIntervalTime, isIntervalDates, isDateOnly} from "src/lib/entities/api"
import {ParseStore} from "src/lib/components/CParserInput/stores/ParseStore"
import {diff, getNow, Units} from "src/lib/utils/date"
import {DateOnlyToDate} from "src/lib/utils/intl/dateHelper"
import {TodoStatus} from "src/lib/entities/api";
import {TodoFinishActionRequest} from "src/lib/entities/api";
import {TodoModeChange} from "src/bums/todo/components/CTodoEditCard";

const messages: any = require("./messages.yml")

/**
 * Возвращает время, которое отображается в тудухах в списке.
 */
export const getTimeForListItem = (intl: Intl, todo: Api.Todo) => {
    const {when} = todo

    // Тут у нас три варианта:
    if (isIntervalTime(when)) {
        // Дело в рамках одного дня, с промежутком времени
        return DateHelper.getTime(intl, when.from)
    } else if (isIntervalDates(when)) {
        // Для многодневки - пишем "по xxx"
        return intl.formatMessage(messages["untilDate"], {
            date: DateHelper.getDateWithWeekDayShort(intl, DateHelper.DateOnlyToDate(when.to))
        })
    } else if (isDateOnly(when)) {
        // Если однодневка - пишем "целый день"
        return intl.formatMessage(messages["fullDay"])
    }

    // 1. Дело без времени
    return ""
}


export function getStartDate(todo: Api.Todo): Date {
    return getStartFromWhen(todo.when)
}

export function getStartFromWhen(when?: Api.DateOnly|Api.IntervalDates|Api.IntervalTime) {
    if (isDateOnly(when)) {
        return DateHelper.DateOnlyToDate(when)
    } else if (isIntervalDates(when)) {
        return DateHelper.DateOnlyToDate(when.from)
    } else if (isIntervalTime(when)) {
        return when.from
    }

    return null
}

/**
 * Форматирует дату для отображения в подзаголовке списка
 */
export const getDateForListSeparator = (intl: Intl, todo: Api.Todo) => {
    const startDate = getStartDate(todo)
    if (startDate) {
        let dateResult = DateHelper.getDateWithWeekDay(intl, startDate)
        return dateResult.slice(0, 1).toUpperCase() + dateResult.slice(1)
    } else {
        return intl.formatMessage(messages["withoutTime"])
    }
}

/**
 * Форматирует время для отображения в карточке дела
 */
export const getTimeForCard = (intl: Intl, todo: Api.Todo, isWithWeekDay: boolean = true) => {
    // TODO прикрутить сюда интернациональное форматирование интервала
    const {when} = todo
    // Тут у нас четыре варианта:
    if (isIntervalTime(when)) {
        // 1. Дело в рамках одного дня, с промежутком времени
        return DateHelper.getTimeIntervalFull(intl, when.from, when.to, isWithWeekDay)

    } else if (isIntervalDates(when)) {
        // 2. или на несколько дней.
        return DateHelper.getDateIntervalFull(intl, DateHelper.DateOnlyToDate(when.from), DateHelper.DateOnlyToDate(when.to), isWithWeekDay)

    } else if (isDateOnly(when)) {
        // 3. Дело на целый день,
        const theDay = DateHelper.DateOnlyToDate(when)
        return DateHelper.getDateIntervalFull(intl, theDay, theDay)
    }

    // 1. Дело без времени
    return ""
}

/**
 * Форматирует напоминания для отображения в карточке
 */
export const getRemindersTextForCard = (intl: Intl, todo: Api.Todo) => {
    return ReminderHelper.getRemindersTextForSingleLine(intl, todo.reminders)
}


/**
 * Форматирует повторение в читабельном виде
 */
export const getRepetitionTextForCard = (intl: Intl, todo: Api.Todo) => {
    if (!todo.schedule) {
        return
    }
    return ScheduleHelper.getScheduleText(intl, todo.schedule)
}

/**
 * Единая точка для перевода названий мастер-типов статусов в делах
 */
export const translateStatusMasterType = (intl: Intl, masterType: string) => {
    return intl.formatMessage(messages["statusMasterType"], {masterType: masterType})
}

/**
 * Единая точка для перевода названий мастер-типов категорий в делах
 */
export const translateCategoryMasterType = (intl: Intl, masterType: string) => {
    return intl.formatMessage(messages["categoryMasterType"], {masterType: masterType})
}

/**
 * Завершить дело.
 */
export const finishTodo = (apiStore: Api.Store, todo: Api.Todo, entity?: Api.TodoFinishActionRequest) => {
    return apiStore.entityBasedFetch(
        todo,
        "doAction." + Api.Todo.PossibleActions.act_finish,
        `${Api.getEntityTypeEndpoint(todo.contentType)}/${todo.id}/doAction`,
        {
            method: "POST",
            bodyEntity: entity || {contentType: Api.TodoFinishActionRequest.contentType}
        }
    )
}

const getFirstStatusWithMasterType = async (apiStore: Api.Store, masterType: Api.TodoStatus.MasterType) => {
    const list = await apiStore.resolveList<Api.TodoStatus>(() => ({
        endpoint: Api.TodoStatus.endpoint,
        options: { masterType },
        limit: 1,
    }))

    await list.whenComplete()

    return list.originalItems.find(status => status.masterType === masterType)
}

export const finishTodoWithSuccessStatus = async (apiStore: Api.Store, todo: Api.Todo) => {
    const rollbackEntity = {...todo}
    try {
        const status = await getFirstStatusWithMasterType(apiStore, TodoStatus.MasterType.success)
        const finishRequest = status ? {...TodoFinishActionRequest.newObject, status} : null
        await apiStore.updateEntitiesFromJson<Api.Todo>({...todo, status })
        await finishTodo(apiStore, todo, finishRequest)
    } catch (e) {
        await apiStore.updateEntitiesFromJson<Api.Todo>(rollbackEntity)
        throw e
    }
}

export const renewTodoWithOptimisticStatusChange = async (apiStore: Api.Store, todo: Api.Todo) => {
    const rollbackEntity = {...todo}
    try {
        const status = await getFirstStatusWithMasterType(apiStore, TodoStatus.MasterType.scheduled)
        await apiStore.updateEntitiesFromJson<Api.Todo>({...todo, status })
        await renewTodo(apiStore, todo)
    } catch (e) {
        await apiStore.updateEntitiesFromJson<Api.Todo>(rollbackEntity)
        throw e
    }
}

/**
 * Возобновить дело
 */
export const renewTodo = (apiStore: Api.Store, todo: Api.Todo) => {
    return apiStore.entityBasedFetch(
        todo,
        "doAction." + Api.Todo.PossibleActions.act_renew,
        `${Api.getEntityTypeEndpoint(todo.contentType)}/${todo.id}/doAction`,
        {
            method: "POST",
            bodyEntity: {
                contentType: Api.TodoRenewActionRequest.contentType,
            }
        }
    )
}

/**
 * Принять приглашение в дело
 */
export const acceptInvitation = (apiStore: Api.Store, todo: Api.Todo, changeMode: TodoModeChange = "this") => {
    return apiStore.entityBasedFetch(
        todo,
        `doAction.${Api.Todo.PossibleActions.act_accept_invite}`,
        Api.getListEndpoint(todo, "doAction"),
        {
            method: "POST",
            bodyEntity: {
                contentType: Api.TodoAcceptInvitationActionRequest.contentType,
                changeMode: getModeFromChangeType(changeMode)
            }
        }
    )
}

/**
 * Отклонить приглашение в дело
 */
export const rejectInvitation = (apiStore: Api.Store, todo: Api.Todo, changeMode?: TodoModeChange) => {
    return apiStore.entityBasedFetch(
        todo,
        `doAction.${Api.Todo.PossibleActions.act_reject_invite}`,
        Api.getListEndpoint(todo, "doAction"),
        {
            method: "POST",
            bodyEntity: {
                contentType: Api.TodoRejectInvitationActionRequest.contentType,
                changeMode: getModeFromChangeType(changeMode)
            }
        }
    )
}

/**
 * Конвертирует из сокращенного типа удаления/изменения в полное, для передачи на бэкенд.
 */
export function getModeFromChangeType(type?: "all"|"this"|"next") {
    switch (type) {
        default:
        case "this":
            return "changeCurrent"
        case "next":
            return "changeSequent"
        case "all":
            return "changeTemplate"
    }
}

/**
 * Удаляет тудуху на бэкенде
 */
export const deleteTodo = (apiStore: Api.Store, todo: Api.Todo, mode?: "all"|"this"|"next"): any => {
    if (mode) {
        return apiStore.entityBasedFetch(
            todo,
            "delete",
            `${Api.getEntityTypeEndpoint(todo.contentType)}/${todo.id}/deleteRequest`,
            {
                method: "POST",
                bodyEntity: {
                    contentType: Api.TodoDeleteRepeatableActionRequest.contentType,
                    todo: Api.getLink(todo),
                    deleteMode: getModeFromChangeType(mode)
                }
            }
        )
    }

    return apiStore.deleteEntity(todo)
}


/**
 * Проверка прав
 */
type Rights = "finish" | "renew" | "add_participant" | "remove_participant" | "change_responsible"
    | "remove_responsible" | "edit" | "delete" | "accept_invite" | "reject_invite"
export const hasRights = (rights: Rights, todo: Api.Todo) => {
    if (!todo.possibleActions) {
        return false
    }
    switch (rights) {
        case "finish":
            return todo.possibleActions.indexOf(Api.Todo.PossibleActions.act_finish) !== -1
        case "renew":
            return todo.possibleActions.indexOf(Api.Todo.PossibleActions.act_renew) !== -1
        case "accept_invite":
            return todo.possibleActions.indexOf(Api.Todo.PossibleActions.act_accept_invite) !== -1
        case "reject_invite":
            return todo.possibleActions.indexOf(Api.Todo.PossibleActions.act_reject_invite) !== -1
        default:
            return todo.possibleActions.indexOf(Api.Todo.PossibleActions.act_edit) !== -1
    }
}


export function prepareTodoEntityWithParse(store: ParseStore) {
    const entity: Api.Todo = {contentType: Api.Todo.contentType, name: store.rawText} as Api.Todo
    const activeItems = store.parsedItems.filter((item, index) => !store.hasIgnore(index)).map(item => item.target)
    const category = activeItems.find(item => Api.isTodoCategory(item)) as Api.TodoCategory
    const responsible = activeItems.find(item => Api.isEmployee(item)) as Api.Employee
    const whenDate = activeItems.find(item => Api.isIntervalDates(item) || item instanceof Date)
    const whenTime = activeItems.find(item => Api.isIntervalTime(item))
    let fromDate = new Date()
    let toDate = new Date()

    if (Api.isIntervalDates(whenDate)) {
        fromDate = new Date(DateHelper.DateOnlyToDate(whenDate.from))
        toDate = new Date(DateHelper.DateOnlyToDate(whenDate.to))
    } else if (whenDate instanceof Date) {
        fromDate = new Date(whenDate)
        toDate = new Date(whenDate)
    }

    fromDate.setHours(0, 0, 0, 0)
    toDate.setHours(0, 0, 0, 0)

    if (Api.isIntervalTime(whenTime)) {
        fromDate.setHours(whenTime.from.getHours(), whenTime.from.getMinutes())
        toDate.setHours(whenTime.to.getHours(), whenTime.to.getMinutes())

        if (fromDate.getTime() === toDate.getTime()) {
            toDate.addMinutes(30)
        }

        entity.when = {
            contentType: Api.IntervalTime.contentType,
            from: fromDate,
            to: toDate,
        }
    } else if (whenDate) {
        entity.when = {
            contentType: Api.IntervalDates.contentType,
            from: DateHelper.DateToDateOnly(fromDate),
            to: DateHelper.DateToDateOnly(toDate),
        }
    }

    entity.category = category ? category : void 0
    entity.responsible = responsible ? responsible : void 0

    if (entity.name === "" && category) {
        entity.name = category.name
    }

    return entity
}

export const finishedStatusMasterTypes = [
    Api.TodoStatus.MasterType.finished,
    Api.TodoStatus.MasterType.fail,
    Api.TodoStatus.MasterType.success,
    Api.TodoStatus.MasterType.finish_without_result
] as ReadonlyArray<Api.TodoStatus.MasterType>

export const actualStatusMasterTypes = [
    Api.TodoStatus.MasterType.scheduled
] as ReadonlyArray<Api.TodoStatus.MasterType>

export function isTodoFinished(todo: Api.Todo) {
    if (todo.status) {
        return finishedStatusMasterTypes.includes(todo.status.masterType)
    }
    return false
}

export function isOldTodo(todo: Api.Todo) {
    let isOld = false
    if (isTodoFinished(todo)) {
        return false
    }
    if (todo.when && todo.when.to) {
        isOld = Api.isDateOnly(todo.when.to)
            ? diff(DateOnlyToDate(todo.when.to as Api.DateOnly), getNow()) < 0
            : diff(todo.when.to as Date, getNow(), Units.MILLISECONDS) < 0
    }
    return isOld
}
