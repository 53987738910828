import * as React from "react"
import classNames from "classnames/bind"
import {autobind} from "core-decorators"
import {observer} from "mobx-react"
import {computed, observable, runInAction} from "mobx"
import {inject} from "src/lib/utils/inject"
import {FormattedMessage} from "src/lib/utils/intl"
import {noop, omit, partition} from "lodash"
import * as Api from "src/lib/entities/api"
import * as Collections from "src/lib/collections"
import {ExtendsFilterOptions, FilterOptions, KeyedValue} from "src/lib/types"
import {DEFAULT_ENDPOINT} from "src/lib/utils/search"
import {filterOnlyWorkingEmployees, filterNotDeletedContractors} from "./filters"
import {CFilterList} from "./components/CFilterList"
import {CSmartSelect, Component, CLink} from "src/lib/components"
import {AutocompleteFilterStore, getContentTypesByEndpoint} from "./AutocompleteFilterStore"
import {makeRefHandler} from "src/lib/utils/func"
import {EmployeeFilterListStore} from "./stores/EmployeeFilterListStore"
import {TaskFilterListStore} from "./stores/TaskFilterListStore"
import {ContractorFilterListStore} from "./stores/ContractorFilterListStore"
import {UNKNOWN_ID_PREFIX} from "src/lib/entities/types";
import {FormattedMessageProps} from "src/lib/utils/intl/components";

const style: any = classNames.bind(require("./CAutocomplete.styl"))
const messages: any = require("../../messages.yml")

const autocompleteDefaultFilters = [filterOnlyWorkingEmployees, filterNotDeletedContractors]

export namespace CAutocomplete {
    type BaseProps<V> =  {
        fieldName: string
        endpoint?: string
        onCreateWithCtrlEnter?: (value: string) => void,
        excludes?: Collections.List<V>
        filters?: ((list: Collections.List<KeyedValue<V, V>>, options?: ExtendsFilterOptions) => Collections.List<KeyedValue<V, V>>)[]
        showFilterPanel?: boolean
        isSingleGroupAdd?: boolean
        showBottomBorder?: boolean
        maxWidth?: string
        //Установить ширину инпута 100%
        setInputMaxWidth?: boolean
        selectAllFiltersByDefault?: boolean
    }

    export type SingleProps<V> = CSmartSelect.SingleProps<V, V> & BaseProps<V>
    export type MultiProps<V> = CSmartSelect.MultiProps<V, V> & BaseProps<V>

    export type Props<V> = (SingleProps<V> | MultiProps<V>)

    export type Filters<V> = ((list: Collections.List<KeyedValue<V, V>>, options?: ExtendsFilterOptions)
        => Collections.List<KeyedValue<V, V>>)[]
}

const supportedFilteredContentTypes: string[] = [
    Api.Employee.contentType,
    Api.ContractorHuman.contentType,
    Api.ContractorCompany.contentType,
    Api.Task.contentType,
];

const sortOrder = [
    Api.Employee.contentType,
    "Contractor",
    Api.Task.contentType,
    Api.Topic.contentType,
]

@observer
class CMore extends Component<{}, {}> {
    public render() {
        return <div className={style("moreWrapper")}>
            <FormattedMessage {...messages["notAllElements"]} tagName="div" />
            <FormattedMessage {...messages["refineYourSearch"]} tagName="div" />
        </div>
    }
}

interface CAddCtrlEnterProps {
    onCommandHandler: () => void
    title?: FormattedMessageProps
    useMoreWrapper?: boolean
}

@observer
@autobind
export class CAddCtrlEnter extends Component<CAddCtrlEnterProps, {}> {
    public static defaultProps = {
        useMoreWrapper: true
    }

    componentDidMount() {
        window.addEventListener("keydown", this.commandHandler, true)
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.commandHandler, true)
    }

    private commandHandler(event: KeyboardEvent) {
        if (event.ctrlKey && event.keyCode === 13) {
            event.preventDefault()
            event.stopPropagation()
            this.props.onCommandHandler()
        }
    }

    public render() {
        const title = this.props.title
            ? this.props.title
            : messages["add"]

        return <div className={style({moreWrapper: this.props.useMoreWrapper}, "commandWrapper")}>
            <CLink
                onClick={this.props.onCommandHandler}
            >
                <FormattedMessage {...title} />
            </CLink>
            <div className={style("adviseChip")}>
                <FormattedMessage {...messages["commandCtrlEnter"]} />
            </div>
        </div>
    }
}

@observer
@autobind
export class CAutocomplete<V extends Api.BaseEntity> extends Component<CAutocomplete.Props<V>, {}> {

    static defaultProps: CAutocomplete.Props<Api.BaseEntity> = {
        fieldName: "autocompleteField",
        items: Collections.List(),
        onClose: noop,
        onFocus: noop,
        endpoint: DEFAULT_ENDPOINT,
        excludes: Collections.List(),
        theme: "chip",
        removable: true,
        showElementIfFocused: false,
        filters: autocompleteDefaultFilters,
        onChangeSearchText: noop,
        showDropDownStatus: false,
        showFilterPanel: true,
        setInputMaxWidth: false
    }

    public selectNode: CSmartSelect<Api.BaseEntity, Api.BaseEntity>

    @inject(Api.Store)
    private apiStore: Api.Store

    @inject(EmployeeFilterListStore)
    private employeeFilterListStore: EmployeeFilterListStore

    @inject(TaskFilterListStore)
    private taskFilterListStore: TaskFilterListStore

    @inject(ContractorFilterListStore)
    private contractorFilterListStore: ContractorFilterListStore

    private autocompleteFilterStore: AutocompleteFilterStore<V>

    @observable
    private loadEmployees = false

    public componentWillMount() {
        this.autocompleteFilterStore = new AutocompleteFilterStore<V>(
            this.apiStore,
            () => ({
                endpoint: this.props.endpoint,
                filters: this.filters,
                defaultFilter: this.defaultFilter,
                excludes: this.props.excludes.toArray()
                    .concat(Collections.List(this.props.value as V).toArray())
                    .filter(entity => Api.isBaseEntity(entity) && entity.id !== UNKNOWN_ID_PREFIX)
                    .map(entity => Api.getLink(entity))
            })
        )
    }

    @computed
    private get baseFilters() {
        if (!this.props.endpoint) {
            return []
        }

        const contentTypes = getContentTypesByEndpoint(this.props.endpoint)

        if (contentTypes.length === 1 && !supportedFilteredContentTypes.includes(contentTypes[0])) {
            return []
        }

        const endpointContentTypes = new Set<string>(contentTypes)

        if (endpointContentTypes.has(Api.ContractorHuman.contentType) || endpointContentTypes.has(Api.ContractorCompany.contentType)) {
            endpointContentTypes.add("Contractor")
            endpointContentTypes.delete(Api.ContractorHuman.contentType)
            endpointContentTypes.delete(Api.ContractorCompany.contentType)
        }

        const resultContentTypes = Array.from(endpointContentTypes.values())
        const [sorted, unsorted] = partition(resultContentTypes, filter => sortOrder.includes(filter))
        return sortOrder.filter(filter => sorted.includes(filter)).concat(unsorted)
    }

    @computed
    private get filters() {
        let extendedFilters: Api.BaseEntity[] = []

        if (this.baseFilters.includes(Api.Employee.contentType)) {
            extendedFilters = this.employeeFilterListStore.filters.toArray()
        } else if (this.baseFilters.includes("Contractor")) {
            extendedFilters = this.contractorFilterListStore.filters.toArray()
        } else if (this.baseFilters.includes(Api.Task.contentType)) {
            extendedFilters = this.taskFilterListStore.filters.toArray()
        }

        return [].concat(this.baseFilters, extendedFilters)
    }

    @computed
    private get defaultFilter() {
        if (!this.baseFilters.length) {
            return void 0
        }

        if (this.baseFilters.includes(Api.Employee.contentType)) {
            return Api.Employee.contentType
        }

        return this.baseFilters[0]
    }

    public componentWillUnmount() {
        this.autocompleteFilterStore.dispose()
    }

    public focus() {
        this.selectNode.focus()
    }

    public blur() {
        if (this.selectNode) {
            this.selectNode.blur()
        }
    }

    public reset() {
        this.autocompleteFilterStore.reset()
    }

    @computed
    public get items() {
        return !this.autocompleteFilterStore.loading
            ? Collections.List(this.props.items).concat(this.autocompleteFilterStore.items)
            : Collections.List(this.props.items)
    }

    @computed
    private get showCreateWithHotKeyItem() {
        if (
            !this.props.onCreateWithCtrlEnter
            || !this.autocompleteFilterStore.querySearch
            || this.autocompleteFilterStore.loading
            || this.loadEmployees
        ) {
            return false
        }

        return this.autocompleteFilterStore.items
            .filter(item => Api.isBaseEntity(item) && (Api.getEntityName(item) === this.autocompleteFilterStore.querySearch)).length === 0
    }

    private filterList(list: Collections.List<KeyedValue<V, V>>, options: FilterOptions) {
        return this.props.filters.filter(filter => !!filter).reduce((value, filter) => {
            return filter(value, Object.assign({}, options, {types: this.autocompleteFilterStore.baseFilters}))
        }, list)
    }

    private closeHandler() {
        this.autocompleteFilterStore.changeActive(false)
        this.props.onClose()
    }

    private focusHandler() {
        this.autocompleteFilterStore.changeActive(true)
        this.props.onFocus()
    }

    private onChangeSearchText(value: string, operation: "change" | "clear") {
        this.autocompleteFilterStore.searchFromServer(value)
        this.props.onChangeSearchText(value, operation)
    }

    private async loadAndSelectEmployees(group: Api.Group | Api.Department | Api.Position) {
        const {value, onChange} = this.props
        if (!onChange) {
            return
        }

        let limit = !this.props.multi ? 1 : void 0
        let limitLoad: number
        let maxValues: number

        if (this.props.multi && this.props.value && this.props.maxValues !== void 0) {
            if (this.props.value.length >= this.props.maxValues) {
                return
            }
            maxValues = this.props.maxValues
            limitLoad = maxValues - this.props.value.length
            limit = Math.min(100, limitLoad)
        }

        if (limit === 0) {
            return
        }

        runInAction(() => this.loadEmployees = true)
        try {
            const listStore = this.apiStore.resolveList<V>(() => ({
                endpoint: "/api/v3/autocomplete/groupEmployees",
                options: {
                    endpoint: this.props.endpoint,
                    isMultiple: !!this.props.multi,
                    group
                },
                limit
            }), 60000)

            if (this.props.multi) {
                if ("number" === typeof limitLoad) {
                    await listStore.whenComplete()
                    while (listStore.totalItemsCount >= limitLoad && listStore.originalItems.length < limitLoad) {
                        listStore.loadNext(Math.min(limitLoad - listStore.originalItems.length, 100))
                        await listStore.whenComplete()
                    }
                } else {
                    listStore.supportFullLoad()
                }
            }

            await listStore.whenComplete()
            const employees = listStore.originalItems

            if (this.props.multi) {
                const initialCollection = Collections.List<V>(value as V);
                (onChange as (value: Collections.List<V>) => void)(
                    initialCollection
                        .concat(employees.filter((entity) => !initialCollection.includes(entity)))
                        .slice(0, maxValues === void 0 ? void 0 : maxValues)
                )
            } else {
                (onChange as (value: V) => void)(employees.first())
            }

            listStore.dispose()
        } finally {
            runInAction(() => this.loadEmployees = false)
        }
    }

    private createWithCtrlEnterHandler() {
        if (this.selectNode && this.props.onCreateWithCtrlEnter) {
            const query = this.autocompleteFilterStore.querySearch
            this.selectNode.clear()
            this.closeHandler()
            this.blur()
            this.props.onCreateWithCtrlEnter(query)
        }
    }

    @computed
    public get querySearch() {
        return this.autocompleteFilterStore.querySearch
    }

    public render() {
        const loading = this.loadEmployees || this.autocompleteFilterStore.loading

        const prependDropDownElement = this.props.prependDropDownElement
            ? this.props.prependDropDownElement
            : this.showCreateWithHotKeyItem
                ? <CAddCtrlEnter onCommandHandler={this.createWithCtrlEnterHandler} useMoreWrapper={false}/>
                : void 0

        const options = {
            ...omit(this.props, ["endpoint"]),
            fieldName: this.props.fieldName,
            loading: loading,
            showLoaderIfEmptyList: false,
            onChangeSearchText: this.onChangeSearchText,
            onClose: this.closeHandler,
            onFocus: this.focusHandler,
            items: this.loadEmployees ? Collections.List(this.props.items) : this.items,
            showFilterInput: true,
            emptyListPlaceholder: loading ? <CSmartSelect.EmptyLoading /> : <CSmartSelect.NotFound />,
            appendDropDownElement: this.loadEmployees
                ? void 0
                : !this.autocompleteFilterStore.loading && this.autocompleteFilterStore.hasMore ? <CMore /> : void 0,
            prependDropDownElement: prependDropDownElement,
            filters: [this.filterList],
            ref: makeRefHandler(this, "selectNode"),
            width: 300,
            showBottomBorder: this.props.showBottomBorder,
            onKeyDown: this.props.onKeyDown
        }

        const autocompleteFilterContainer = this.props.showFilterPanel && this.autocompleteFilterStore.baseFilters.length > 0 &&
            <CFilterList
                store={this.autocompleteFilterStore}
                loadAndSelectEmployees={this.loadAndSelectEmployees}
                selectAllFiltersByDefault={this.props.selectAllFiltersByDefault}
            />

        if (this.props.multi) {
            return CSmartSelect.multi<V, V>(options as CSmartSelect.MultiProps<V, V>, autocompleteFilterContainer)
        } else {
            return CSmartSelect.single<V, V>(options as CSmartSelect.SingleProps<V, V>, autocompleteFilterContainer)
        }

    }
}

export namespace CAutocomplete {

    export const defaultFilters = autocompleteDefaultFilters

    export function single<V>(props: SingleProps<V> & {ref?: React.Ref<CAutocomplete<any>>}): JSX.Element
    export function single(props: SingleProps<any> & {ref?: React.Ref<CAutocomplete<any>>}) {
        return React.createElement(CAutocomplete, {...props, multi: false})
    }

    export function multi<V>(props: MultiProps<V> & {ref?: React.Ref<CAutocomplete<any>>}): JSX.Element
    export function multi(props: MultiProps<any> & {ref?: React.Ref<CAutocomplete<any>>}) {
        return React.createElement(CAutocomplete, {...props, multi: true})
    }
}
