/* tslint:disable */
import * as H from "../metadataHelper"
import {ContractorCompany} from "./contractorCompany"
import {ContractorHuman} from "./contractorHuman"
import {Employee} from "./employee"
import {Group} from "./group"
import {Comment} from "./comment"
import {File} from "./file"
import {RelationLink} from "./relationLink"
import {TopicFolder} from "./topicFolder"
import {FileGroup} from "./fileGroup"
import {AttachesInfo} from "./attachesInfo"
import {StringField} from "./stringField"
import {IntegerField} from "./integerField"
import {BoolField} from "./boolField"
import {DateTimeField} from "./dateTimeField"
import {EnumField} from "./enumField"

export interface Topic extends H.BaseEntity {
    name?: string;
    content?: string;
    owner?: ContractorCompany|ContractorHuman|Employee;
    visavis?: ContractorCompany|ContractorHuman|Employee;
    participants?: H.List<ContractorHuman | Employee | Group>;
    participantsCount?: number;
    lastComment?: Comment;
    avatars?: H.List<string>;
    isPrivate?: boolean;
    attaches?: H.List<File>;
    attachesCount?: number;
    timeCreated?: Date;
    soundDisable?: boolean;
    isDiscussable?: boolean;
    possibleActions?: H.List<Topic.PossibleActions>; // Список возможных действий
    attachedTill?: Date;
    isAttachedForever?: boolean;
    links?: H.List<RelationLink>;
    linksCount?: number;
    isHidden?: boolean;
    avatar?: File; // Поле для загрузки аватара для обсуждения
    isAttached?: boolean; // Закреплено ли обсуждение для пользователя
    folders?: H.List<TopicFolder>;
    foldersCount?: number;
    id?: string; // Идентификатор
    isFavorite?: boolean; // Избранная сущность или нет
    unreadCommentsCount?: number; // Количество непрочитанных комментариев
    attachesCountInComments?: number; // Количество вложений в комментариях
    subscribed?: boolean; // Подписан ли текущий пользователь на комментарии по этой сущности
    possibleSubscribers?: H.List<ContractorCompany | ContractorHuman | Employee>; // Пользователи-участники сущности, которые могут получать уведомления по ней.
    possibleSubscribersCount?: number; // Количество возможных подписчиков
    subscribers?: H.List<ContractorCompany | ContractorHuman | Employee>; // Пользователи-участники, которые подписаны на уведомления по сущности.
    subscribersCount?: number; // Количество подписчиков
    comments?: H.List<Comment>; // Массив комментариев
    commentsCount?: number; // Количество комментариев
    hiddenCommentsCount?: number; // Количество скрытых комментариев
    isUnread?: boolean; // Помечен как непрочитанный
    firstUnreadComment?: Comment; // Первый непрочитанный комментарий
    unreadAnswer?: boolean; // Есть ли непрочитанный ответ или упоминание
    videocallParticipants?: H.List<ContractorHuman | Employee>; // Участники видеозвонка
    videocallParticipantsCount?: number; // Количество участников видеозвонка
    allFiles?: H.List<File | FileGroup>; // Массив файлов
    allFilesCount?: number; // Количество файлов
    attachesInfo?: AttachesInfo; // Информация о вложении
    contentType: "Topic"; // Object type
}

export module Topic {
    export const contentType: "Topic" = "Topic";
    export const endpoint = "/api/v3/topic";
    export type PossibleActions = "act_comment"|"act_delete"|"act_edit"|"act_edit_participants"|"act_read";
    export module PossibleActions {
        export const act_comment: PossibleActions = "act_comment";
        export const act_delete: PossibleActions = "act_delete";
        export const act_edit: PossibleActions = "act_edit";
        export const act_edit_participants: PossibleActions = "act_edit_participants";
        export const act_read: PossibleActions = "act_read";
    }
    export const newObject: Topic = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "Topic.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const content = {contentType: "StringField" as "StringField", name: "content", isMultiple: false, isSortable: false, id: "Topic.content", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const owner = {contentType: "RefLinkField" as "RefLinkField", name: "owner", isMultiple: false, isSortable: false, id: "Topic.owner", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee"])};
        export const visavis = {contentType: "RefLinkField" as "RefLinkField", name: "visavis", isMultiple: false, isSortable: false, id: "Topic.visavis", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee"])};
        export const participants = {contentType: "RefLinkField" as "RefLinkField", name: "participants", isMultiple: true, isSortable: false, id: "Topic.participants", refContentType: H.List(["ContractorHuman", "Employee", "Group"])};
        export const participantsCount = {contentType: "IntegerField" as "IntegerField", name: "participantsCount", isMultiple: false, isSortable: false, id: "Topic.participantsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const lastComment = {contentType: "RefLinkField" as "RefLinkField", name: "lastComment", isMultiple: false, isSortable: false, id: "Topic.lastComment", refContentType: H.List(["Comment"])};
        export const avatars = {contentType: "StringField" as "StringField", name: "avatars", isMultiple: true, isSortable: false, id: "Topic.avatars", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const isPrivate = {contentType: "BoolField" as "BoolField", name: "isPrivate", isMultiple: false, isSortable: false, id: "Topic.isPrivate", defaultValue: null as BoolField["defaultValue"]};
        export const attaches = {contentType: "RefLinkField" as "RefLinkField", name: "attaches", isMultiple: true, isSortable: false, id: "Topic.attaches", refContentType: H.List(["File"])};
        export const attachesCount = {contentType: "IntegerField" as "IntegerField", name: "attachesCount", isMultiple: false, isSortable: false, id: "Topic.attachesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const timeCreated = {contentType: "DateTimeField" as "DateTimeField", name: "timeCreated", isMultiple: false, isSortable: false, id: "Topic.timeCreated", defaultValue: null as DateTimeField["defaultValue"]};
        export const soundDisable = {contentType: "BoolField" as "BoolField", name: "soundDisable", isMultiple: false, isSortable: false, id: "Topic.soundDisable", defaultValue: null as BoolField["defaultValue"]};
        export const isDiscussable = {contentType: "BoolField" as "BoolField", name: "isDiscussable", isMultiple: false, isSortable: false, id: "Topic.isDiscussable", defaultValue: null as BoolField["defaultValue"]};
        export const possibleActions = {contentType: "EnumField" as "EnumField", name: "possibleActions", isMultiple: true, isSortable: false, id: "Topic.possibleActions", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["act_comment", "act_delete", "act_edit", "act_edit_participants", "act_read"])};
        export const attachedTill = {contentType: "DateTimeField" as "DateTimeField", name: "attachedTill", isMultiple: false, isSortable: false, id: "Topic.attachedTill", defaultValue: null as DateTimeField["defaultValue"]};
        export const isAttachedForever = {contentType: "BoolField" as "BoolField", name: "isAttachedForever", isMultiple: false, isSortable: false, id: "Topic.isAttachedForever", defaultValue: null as BoolField["defaultValue"]};
        export const links = {contentType: "RefLinkField" as "RefLinkField", name: "links", isMultiple: true, isSortable: false, id: "Topic.links", refContentType: H.List(["RelationLink"])};
        export const linksCount = {contentType: "IntegerField" as "IntegerField", name: "linksCount", isMultiple: false, isSortable: false, id: "Topic.linksCount", defaultValue: null as IntegerField["defaultValue"]};
        export const isHidden = {contentType: "BoolField" as "BoolField", name: "isHidden", isMultiple: false, isSortable: false, id: "Topic.isHidden", defaultValue: null as BoolField["defaultValue"]};
        export const avatar = {contentType: "RefLinkField" as "RefLinkField", name: "avatar", isMultiple: false, isSortable: false, id: "Topic.avatar", refContentType: H.List(["File"])};
        export const isAttached = {contentType: "BoolField" as "BoolField", name: "isAttached", isMultiple: false, isSortable: false, id: "Topic.isAttached", defaultValue: null as BoolField["defaultValue"]};
        export const folders = {contentType: "RefLinkField" as "RefLinkField", name: "folders", isMultiple: true, isSortable: false, id: "Topic.folders", refContentType: H.List(["TopicFolder"])};
        export const foldersCount = {contentType: "IntegerField" as "IntegerField", name: "foldersCount", isMultiple: false, isSortable: false, id: "Topic.foldersCount", defaultValue: null as IntegerField["defaultValue"]};
        export const isFavorite = {contentType: "BoolField" as "BoolField", name: "isFavorite", isMultiple: false, isSortable: false, id: "Topic.isFavorite", defaultValue: null as BoolField["defaultValue"]};
        export const unreadCommentsCount = {contentType: "IntegerField" as "IntegerField", name: "unreadCommentsCount", isMultiple: false, isSortable: false, id: "Topic.unreadCommentsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const attachesCountInComments = {contentType: "IntegerField" as "IntegerField", name: "attachesCountInComments", isMultiple: false, isSortable: false, id: "Topic.attachesCountInComments", defaultValue: null as IntegerField["defaultValue"]};
        export const subscribed = {contentType: "BoolField" as "BoolField", name: "subscribed", isMultiple: false, isSortable: false, id: "Topic.subscribed", defaultValue: null as BoolField["defaultValue"]};
        export const possibleSubscribers = {contentType: "RefLinkField" as "RefLinkField", name: "possibleSubscribers", isMultiple: true, isSortable: false, id: "Topic.possibleSubscribers", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee"])};
        export const possibleSubscribersCount = {contentType: "IntegerField" as "IntegerField", name: "possibleSubscribersCount", isMultiple: false, isSortable: false, id: "Topic.possibleSubscribersCount", defaultValue: null as IntegerField["defaultValue"]};
        export const subscribers = {contentType: "RefLinkField" as "RefLinkField", name: "subscribers", isMultiple: true, isSortable: false, id: "Topic.subscribers", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee"])};
        export const subscribersCount = {contentType: "IntegerField" as "IntegerField", name: "subscribersCount", isMultiple: false, isSortable: false, id: "Topic.subscribersCount", defaultValue: null as IntegerField["defaultValue"]};
        export const comments = {contentType: "RefLinkField" as "RefLinkField", name: "comments", isMultiple: true, isSortable: false, id: "Topic.comments", refContentType: H.List(["Comment"])};
        export const commentsCount = {contentType: "IntegerField" as "IntegerField", name: "commentsCount", isMultiple: false, isSortable: false, id: "Topic.commentsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const hiddenCommentsCount = {contentType: "IntegerField" as "IntegerField", name: "hiddenCommentsCount", isMultiple: false, isSortable: false, id: "Topic.hiddenCommentsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const isUnread = {contentType: "BoolField" as "BoolField", name: "isUnread", isMultiple: false, isSortable: false, id: "Topic.isUnread", defaultValue: null as BoolField["defaultValue"]};
        export const firstUnreadComment = {contentType: "RefLinkField" as "RefLinkField", name: "firstUnreadComment", isMultiple: false, isSortable: false, id: "Topic.firstUnreadComment", refContentType: H.List(["Comment"])};
        export const unreadAnswer = {contentType: "BoolField" as "BoolField", name: "unreadAnswer", isMultiple: false, isSortable: false, id: "Topic.unreadAnswer", defaultValue: null as BoolField["defaultValue"]};
        export const videocallParticipants = {contentType: "RefLinkField" as "RefLinkField", name: "videocallParticipants", isMultiple: true, isSortable: false, id: "Topic.videocallParticipants", refContentType: H.List(["ContractorHuman", "Employee"])};
        export const videocallParticipantsCount = {contentType: "IntegerField" as "IntegerField", name: "videocallParticipantsCount", isMultiple: false, isSortable: false, id: "Topic.videocallParticipantsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const allFiles = {contentType: "RefLinkField" as "RefLinkField", name: "allFiles", isMultiple: true, isSortable: false, id: "Topic.allFiles", refContentType: H.List(["File", "FileGroup"])};
        export const allFilesCount = {contentType: "IntegerField" as "IntegerField", name: "allFilesCount", isMultiple: false, isSortable: false, id: "Topic.allFilesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const attachesInfo = {contentType: "RefLinkField" as "RefLinkField", name: "attachesInfo", isMultiple: false, isSortable: false, id: "Topic.attachesInfo", refContentType: H.List(["AttachesInfo"])};
    }
}

export function isTopic(arg: any): arg is Topic {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Topic"
}
