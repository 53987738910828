
/**
 * Paste Plugin
 * Преобразует данные, вставленные из офисных редакторов в html виде в корректный html
 *
 * @return {Object} Object defining the draft-js API methods
 */

import convertMsoHtml from "./utils/mso-paste-converter"
import convertLibreHtml from "./utils/libre-paste-converter"
import convertHtmlTables from "./utils/html-tables-paste-converter";
import {
    DraftHandleValue,
    EditorState,
    Modifier,
} from "draft-js"

import {convertFromHtml} from "./utils/converter"

import {
    getSafeBodyWithHTML,
} from "src/lib/utils/draft"

// Это работает независимо, от наличия тега body
const beforeBodyRegExp = /[\s\S.]*<body.*?>/g
const afterBodyRegExp = /<\/body>[\s\S.]*/g
const preserveEmptyLineRegExp = /<br data-text="true">/g
const htmlCommentsRegExp = /<!--(?:.|\n)*?-->/g

const wordGeneratorRegExp = /<meta name="?generator"? content="Microsoft Word.*?"\/?>/im
const libreOfficeGeneratorRegExp = /<meta name="?generator"? content="LibreOffice.*?"\/?>/im

class PastePlugin {
    /**
     * @param {String} text
     * @param {String} html
     * @param {EditorState} editorState
     * @param  {Function} pluginFunctions
     * @returns {String}
     */
    handlePastedText(text: string, html: string, editorState: EditorState, pluginFunctions: any): DraftHandleValue {

        if (!html) {
            return "not-handled"
        }

        // Если скопировано из word
        if (wordGeneratorRegExp.test(html)) {
            html = convertMsoHtml(html)
        }

        // Если скопировано из libreOffice
        if (libreOfficeGeneratorRegExp.test(html)) {
            html = convertLibreHtml(html)
        }

        html = html
            // Удалить всё, что до тега body и после него, включая их самих. Это работает независимо, от наличия тега body
            .replace(beforeBodyRegExp, "").replace(afterBodyRegExp, "")
            // Удалить комментарии
            .replace(htmlCommentsRegExp, "")
            // Сохраняет нужное кол-во интервалов между строками при копировании из редактора
            .replace(preserveEmptyLineRegExp, "")


        html = convertHtmlTables(html)

        const blockMap = convertFromHtml(getSafeBodyWithHTML(html).innerHTML).getBlockMap()
        const contentState = Modifier.replaceWithFragment(editorState.getCurrentContent(), editorState.getSelection(), blockMap)
        editorState = EditorState.push(editorState, contentState, "insert-fragment")
        pluginFunctions.setEditorState(editorState)

        return "handled"
    }
}

export default function() {
    return new PastePlugin()
}
