import {observer} from "mobx-react"
import classNames from "classnames/bind"
import {autobind} from "core-decorators"
import * as Api from "src/lib/entities/api"
import {inject} from "src/lib/utils/inject"
import {ModalFormStore} from "./ModalFormStore"
import {FormInstance} from "./types"
import {Form} from "src/lib/utils/form/form"
import {CSmartCard, CDisguisedValue, Component} from "src/lib/components"

export namespace CModalFormBuilder {
   export interface Props {
       onClose: () => void
       onSubmit: () => void
   }
}

const style = classNames.bind(require("./CModalFormBuilder.styl"))

export const CSuspenseFallback = <CSmartCard className={style("suspenseCard")}>
    <CSmartCard.Content>
        <CDisguisedValue />
    </CSmartCard.Content>
</CSmartCard>


type FormArg<T> = T extends Form<Api.BaseEntity>
    ? Form<Api.BaseEntity>
    : {form: Form<Api.BaseEntity>}

@observer
@autobind
export class CModalFormBuilder<T> extends Component<CModalFormBuilder.Props, {}> {

    @inject(ModalFormStore)
    protected modalFormStore: ModalFormStore

    constructor(props: CModalFormBuilder.Props, context: {}) {
        super(props, context)

        void this.modalFormStore.resolveForms(this.resolveForms())
    }

    protected async resolveForms(): Promise<FormInstance[]> {
        throw new Error("Not implemented method ::resolveForms")
    }

    protected renderForm(forms: FormInstance[]): JSX.Element {
        return null
    }

    public render() {
        if (!this.modalFormStore.isComplete) {
            return CSuspenseFallback
        }

        const forms = this.modalFormStore.forms as FormArg<T>[]

        return this.renderForm(forms)
    }
}
