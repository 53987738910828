import * as React from "react"
import classNames from "classnames/bind"
import {Component} from "src/lib/components"
import {inject} from "src/lib/utils/inject"
import {FeatureStore} from "src/bums/common/stores/FeatureStore"

const style: any = classNames.bind(require("./CStyledContent.styl"))

class CSecondary extends Component<React.HTMLAttributes<HTMLDivElement>, {}> {
    public render() {
        const {children, ...htmlProps} = this.props
        return <div {...htmlProps} className={style("secondary", htmlProps.className)}>
            {children}
        </div>
    }
}

class CInfo extends Component<React.HTMLAttributes<HTMLDivElement>, {}> {
    public render() {
        const {children, ...htmlProps} = this.props
        return <div {...htmlProps} className={style("info", htmlProps.className)}>
            {children}
        </div>
    }
}

class CError extends Component<React.HTMLAttributes<HTMLDivElement>, {}> {
    public render() {
        const {children, ...htmlProps} = this.props
        return <div {...htmlProps} className={style("error", htmlProps.className)}>
            {children}
        </div>
    }
}

class CStyledInfo extends Component<React.HTMLAttributes<HTMLDivElement>, {}> {
    public render() {
        const {children, ...htmlProps} = this.props
        return <div {...htmlProps} className={style("styledContentInfo", htmlProps.className)}>
            {children}
        </div>
    }
}

class CSmall extends Component<React.HTMLAttributes<HTMLDivElement>, {}> {
    public render() {
        const {children, ...htmlProps} = this.props
        return <div {...htmlProps} className={style("styledContentInfo", htmlProps.className)}>
            {children}
        </div>
    }
}

class CTitle extends Component<React.HTMLAttributes<HTMLDivElement>, {}> {
    public render() {
        const {children, ...htmlProps} = this.props
        return <div {...htmlProps} className={style("title", htmlProps.className)}>
            {children}
        </div>
    }
}

class CBigTitle extends Component<React.HTMLAttributes<HTMLDivElement>, {}> {
    public render() {
        const {children, ...htmlProps} = this.props
        return <div {...htmlProps} className={style("bigTitle", htmlProps.className)}>
            {children}
        </div>
    }
}

export namespace CStyledContent {
    export interface Props extends React.HTMLAttributes<HTMLElement> {
        containerRef?: React.Ref<HTMLElement>
        tagName?: "div" | "span"
    }
}

export class CStyledContent extends Component<CStyledContent.Props, {}> {
    static Secondary = CSecondary
    static Small = CSmall
    static Info = CInfo
    static Error = CError
    static StyledInfo = CStyledInfo
    static Title = CTitle
    static BigTitle = CBigTitle

    public static defaultProps = {
        tagName: "div"
    }

    @inject(FeatureStore)
    private featureStore: FeatureStore

    public render() {
        const {containerRef, children, tagName, ...props} = this.props

        return React.createElement(
            tagName,
            {
                ...props,
                ref: containerRef,
                className: style(
                    "styledContent",
                    {preserveWhitespace: this.featureStore.isAvailable("bums.common.common.preserve_whitespaces")},
                    props.className
                )
            },
            children
        )
    }
}
