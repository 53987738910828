import {autobind} from "core-decorators"
import {action} from "mobx"
import {TransportEvents, TransportLayer} from "../types"
import * as HttpStatus from "http-status-codes"
import * as Bluebird from "bluebird"
import {debounce} from "lodash"
import * as localForage from "src/lib/utils/localForage"
import * as Api from "src/lib/entities/api"
import {ApiResponse} from "src/lib/entities/types"
import {Response, RequestInit} from "src/lib/utils/fetch"

const CacheUrlPattern = [
    /api\/v3\/userSetting\/?/,
    /api\/v3\/systemSetting\/?/,
    /api\/v3\/menu\/?/,
    /api\/v3\/accountInfo\/?/,
    /api\/v3\/currentUser\/?/,
    /api\/v3\/feature\/?/,
    /api\/v3\/userInterfaceLayout\/?/,
]

@autobind
export class PreloadTransport implements TransportLayer {

    private storageItemsPromise: Promise<{[index: string]: ApiResponse<any>}>

    private apiStore: Api.Store

    private queueList = new Set<[string, (RequestInit)]>()

    private queueFinished = true

    constructor(
        private inner: TransportLayer,
        private storage = localForage.makeSafeStorage(localForage.createInstance({
            name: "preload",
        }))
    ) {
        this.storageItemsPromise = this.storage.getItems(null)
    }

    public setApiStore(store: Api.Store) {
        this.apiStore = store
    }

    private queueHandler = debounce(async () => {
        this.queueFinished = false

        const bulkRequests: Api.ApiCall[] = []

        this.queueList.forEach((value) => {
            bulkRequests.push(
                {
                    ...Api.ApiCall.newObject,
                    method: Api.ApiCall.Method.GET,
                    url: value[0],
                    body:  value[1] && value[1].queryParams ? JSON.stringify(value[1].queryParams) : void 0
                }
            )
        })

        this.queueList.clear()

        const bulkResponses = await this.apiStore.fetch<ApiResponse<{}>[]>("/api/v3/bulk", {
            method: "POST",
            bodyEntity: {
                ...Api.BulkApiCall.newObject,
                calls: bulkRequests
            }
        })

        const lists = this.apiStore.getLists()

        bulkResponses.value.data.forEach(async (response: ApiResponse<Api.BaseEntity[]>, index: number) => {
            if (response.meta.status !== HttpStatus.OK) {
                return
            }

            const request = bulkRequests[index]
            const listName = request.url

            void this.storage.setItem(listName, response)
            const data = await this.apiStore.updateEntitiesFromJson(response.data)

            if (lists.has(request.url)) {
                this.listHandler(response, lists.get(request.url), listName, data)
            }
        })

        if (this.queueList.size === 0) {
            this.queueFinished = true
        }

    }, 500)

    public queueComplete() {
        return this.queueList.size === 0 && !!this.queueFinished
    }

    @action
    private listHandler(response: ApiResponse<Api.BaseEntity[]>, list: Api.EntitiesList, listName: string, entities: Api.BaseEntity[]) {
        const pagination = response.meta?.pagination

        if (pagination?.elementIndex) {
            void this.apiStore.listAppendEntities(listName, entities)
            list.totalItemsCount = pagination.count

            return
        }

        list.items.splice(0, list.items.length, ...entities)

        if (pagination) {
            list.totalItemsCount = pagination.count
            list.hasMoreNext = pagination.hasMoreNext
            list.hasMorePrev = pagination.hasMorePrev
        } else {
            list.totalItemsCount = entities.length
            list.hasMoreNext = false
            list.hasMorePrev = false
        }
    }


    fetch<V>(url: string, init?: RequestInit): Bluebird<Response<ApiResponse<V>>> {
        if (!!CacheUrlPattern.find(pattern => pattern.test(url))) {
            return Bluebird.resolve(this.storageItemsPromise.then(storageItems => {
                if ((!init || !init.method || init.method === "GET")) {

                    if (storageItems && storageItems[url]) {
                        this.queueList.add([url, init])
                        void this.queueHandler()
                        const res = storageItems[url]

                        return Bluebird.resolve({
                            url,
                            status: 200,
                            statusText: "",
                            headers: {},
                            value: res,
                        })

                    }

                    return (this.inner.fetch(url, init) as Bluebird<Response<ApiResponse<V>>>).then(response => {
                        if (response.status === HttpStatus.OK) {
                            void this.storage.setItem(url, response.value)
                        }

                        return response
                    })
                } else if (init?.method === "POST") {
                    return (this.inner.fetch(url, init) as Bluebird<Response<ApiResponse<V>>>).then(response => {
                        if (response.status === HttpStatus.OK) {
                            void this.storage.setItem(url, response.value)
                        }

                        return response
                    })
                }

                return this.inner.fetch(url, init)
            }))
        }

        return this.inner.fetch(url, init)
    }

    on(events: TransportEvents): void {
        this.inner.on(events)
    }
}
