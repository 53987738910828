import {observable, action, computed, IComputedValue} from "mobx"
import {autobind} from "core-decorators"
import {BaseEntity, isBaseEntity} from "src/lib/entities/types"
import * as Api from "src/lib/entities/api"
import * as Collections from "src/lib/collections"
import {ApiStore} from "src/lib/entities/store/ApiStore"
import {ListStore} from "src/lib/entities/store/ListStore"
import {getEndpointTypesByMethod} from "src/lib/entities/api"

export interface ListOptions {
    endpoint: string
    excludes?: BaseEntity[]
    filters?: (string | BaseEntity)[]
    defaultFilter?: string | BaseEntity
}

type Filter = void | string | BaseEntity

export const endpointWithAny = "/api/v3/autocomplete/anyTypes"
const contentTypesWithAnyExpr = /\"contentTypes\"\:\[(.+)\]/
const contentTypesWithAnyTrashExpr = /\"/g
const DELAY_SEARCH_FROM_SERVER = 500
const LIMIT_BY_SEARCH = 20

const autocompleteEndpoints = [
    "/api/v3/autocomplete/mentions",
    "/api/v3/autocomplete/issuePossibleParents"
]

export function getContentTypesByEndpoint(endpoint: string): string[] {
    if (endpoint.indexOf(endpointWithAny) === 0) {
        const matched = endpoint.match(contentTypesWithAnyExpr)
        if (!matched) {
            return []
        }
        return matched[1].replace(contentTypesWithAnyTrashExpr, "").split(",") as Api.ContentType[]
    }

    const types = getEndpointTypesByMethod(endpoint) || []
    return types.filter((type: string) => type.charAt(0).toUpperCase() === type.charAt(0)) as Api.ContentType[]
}

function getFields(endpoint: string) {
    const types = getContentTypesByEndpoint(endpoint)
    const fields: (string|{})[] = []

    if (types.includes(Api.Task.contentType)) {
        fields.push(Api.Task.fields.project.name)
    }

    if (endpoint.indexOf("task/availableParents") !== -1) {
        fields.push({"rights": ["createTask", "createSubtask", "createSubproject"]})
    }

    if (endpoint.indexOf("project/availableParents") !== -1) {
        fields.push({"rights": ["createSubproject"]})
    }

    return fields
}

@autobind
export class AutocompleteFilterStore<T extends BaseEntity> {

    private $optionsFactory: IComputedValue<ListOptions>

    private $autocompleteListStore: ListStore<BaseEntity>

    private $filterGroupExpandMap = observable.map<string, boolean>()

    @observable
    private $querySearch = ""

    @observable
    private $changedFilterValue: Filter

    @observable
    private $active = false

    constructor(
        private $apiStore: ApiStore,
        optionsFactory: () => ListOptions,
        disposeTimeout?: number
    ) {
        this.$optionsFactory = computed(optionsFactory)

        this.$autocompleteListStore = this.$apiStore.resolveList<T>(() => {
            if (!this.$active) {
                return void 0
            }

            const options = this.$optionsFactory.get()

            if (options.endpoint === void 0) {
                return void 0
            }

            return {
                endpoint: autocompleteEndpoints.includes(options.endpoint)  ? options.endpoint : "/api/v3/autocomplete",
                options: {
                    endpoint: options.endpoint,
                    filter: this.filter || void 0,
                    querySearch: this.$querySearch,
                    excludes: options.excludes,
                    fields: getFields(options.endpoint)
                },
                limit: LIMIT_BY_SEARCH,
            }
        }, disposeTimeout, DELAY_SEARCH_FROM_SERVER)
    }

    public get querySearch() {
        return this.$querySearch
    }

    @computed
    private get filter() {
        if (void 0 !== this.$changedFilterValue) {
          return this.$changedFilterValue
        }
        return this.$optionsFactory.get().defaultFilter
    }

    @action
    public filterGroupExpand(group: string) {
        this.$filterGroupExpandMap.set(group, !(this.$filterGroupExpandMap.get(group)))
    }

    @action
    public dispose() {
        this.$autocompleteListStore.dispose()
    }

    @action
    public changeFilter(filter: Filter) {
        this.$changedFilterValue = filter
    }

    public hasFilterSelected(filter: Filter) {
        return this.filter === filter
    }

    public hasFilterGroupExpand(group: string) {
        return !!this.$filterGroupExpandMap.get(group)
    }

    public get listStore() {
        return this.$autocompleteListStore
    }

    @computed
    public get items(): Collections.List<T>  {
        return this.$autocompleteListStore.originalItems as Collections.List<T>
    }

    @computed
    public get filters() {
        return this.$optionsFactory.get().filters || []
    }

    @computed
    public get baseFilters() {
        return this.filters.filter(filter => "string" === typeof filter)
    }

    @computed
    public get groupedFilters() {
        const filters = this.filters.filter(filter => isBaseEntity(filter)) as BaseEntity[]
        const groupMap = new Map<Api.ContentType, BaseEntity[]>()

        filters.forEach(filter => {
            const contentType = filter.contentType as Api.ContentType
            if (groupMap.has(contentType)) {
                groupMap.get(contentType).push(filter)
            } else {
                groupMap.set(contentType, [filter])
            }
        })

        return groupMap
    }

    @computed
    public get hasMore() {
        return this.$autocompleteListStore.hasMoreNext
    }

    @computed
    public get loading() {
        const loadStateNext = this.$autocompleteListStore.loadStateNext
        return loadStateNext.isPending() || loadStateNext.isNone()
    }

    @action
    public searchFromServer(search: string) {
        if (!this.$active) {
            this.$active = true
        }
        this.$querySearch = search.trim()
    }

    @action
    public changeActive(value: boolean) {
        this.$active = value
        if (value === false) {
            this.$querySearch = ""
        }
    }

    @action
    public reset() {
        this.$autocompleteListStore.resetList()
    }
}
