/* tslint:disable */
import * as H from "../metadataHelper"
import {Integration} from "./integration"
import {ContractorCompany} from "./contractorCompany"
import {ContractorHuman} from "./contractorHuman"
import {Employee} from "./employee"
import {Group} from "./group"
import {EnumField} from "./enumField"
import {StringField} from "./stringField"
import {IntegerField} from "./integerField"

export interface IntegrationWidget extends H.BaseEntity {
    placeholder?: IntegrationWidget.Placeholder; // Название
    content?: string; // Версия
    appId?: number; // id приложения
    application?: Integration; // Приложение, с кот. связан виджет
    appName?: string; // Название приложения
    name?: string; // Название виджета
    availableFor?: H.List<ContractorCompany | ContractorHuman | Employee | Group>; // Кому доступен данный виджет
    availableForCount?: number;
    restrictedFor?: H.List<ContractorCompany | ContractorHuman | Employee | Group>; // Кому доступен данный виджет
    restrictedForCount?: number;
    uuid?: string;
    id?: string; // Идентификатор
    contentType: "IntegrationWidget"; // Object type
}

export module IntegrationWidget {
    export const contentType: "IntegrationWidget" = "IntegrationWidget";
    export const endpoint = "/api/v3/integrationWidget";
    export type Placeholder = "client_add"|
        "client_card_block"|
        "client_card_tabs"|
        "client_card_top"|
        "client_list"|
        "common_comment_body"|
        "common_comment_form"|
        "common_informer"|
        "common_phone_number"|
        "common_telephony_widget"|
        "common_telephony_widget_config"|
        "common_telephony_widget_header"|
        "deal_add"|
        "deal_card_block"|
        "deal_card_status"|
        "deal_card_tabs"|
        "deal_card_top"|
        "deal_list"|
        "deal_list_buttons"|
        "deal_positions"|
        "doc_card_top"|
        "employee_add"|
        "employee_card_block"|
        "employee_card_description"|
        "employee_card_info"|
        "employee_list"|
        "events_list"|
        "settings_api_telephony"|
        "task_add"|
        "task_card_block"|
        "task_card_status"|
        "task_card_top"|
        "task_list";
    export module Placeholder {
        export const client_add: Placeholder = "client_add";
        export const client_card_block: Placeholder = "client_card_block";
        export const client_card_tabs: Placeholder = "client_card_tabs";
        export const client_card_top: Placeholder = "client_card_top";
        export const client_list: Placeholder = "client_list";
        export const common_comment_body: Placeholder = "common_comment_body";
        export const common_comment_form: Placeholder = "common_comment_form";
        export const common_informer: Placeholder = "common_informer";
        export const common_phone_number: Placeholder = "common_phone_number";
        export const common_telephony_widget: Placeholder = "common_telephony_widget";
        export const common_telephony_widget_config: Placeholder = "common_telephony_widget_config";
        export const common_telephony_widget_header: Placeholder = "common_telephony_widget_header";
        export const deal_add: Placeholder = "deal_add";
        export const deal_card_block: Placeholder = "deal_card_block";
        export const deal_card_status: Placeholder = "deal_card_status";
        export const deal_card_tabs: Placeholder = "deal_card_tabs";
        export const deal_card_top: Placeholder = "deal_card_top";
        export const deal_list: Placeholder = "deal_list";
        export const deal_list_buttons: Placeholder = "deal_list_buttons";
        export const deal_positions: Placeholder = "deal_positions";
        export const doc_card_top: Placeholder = "doc_card_top";
        export const employee_add: Placeholder = "employee_add";
        export const employee_card_block: Placeholder = "employee_card_block";
        export const employee_card_description: Placeholder = "employee_card_description";
        export const employee_card_info: Placeholder = "employee_card_info";
        export const employee_list: Placeholder = "employee_list";
        export const events_list: Placeholder = "events_list";
        export const settings_api_telephony: Placeholder = "settings_api_telephony";
        export const task_add: Placeholder = "task_add";
        export const task_card_block: Placeholder = "task_card_block";
        export const task_card_status: Placeholder = "task_card_status";
        export const task_card_top: Placeholder = "task_card_top";
        export const task_list: Placeholder = "task_list";
    }
    export const newObject: IntegrationWidget = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const placeholder = {contentType: "EnumField" as "EnumField", name: "placeholder", isMultiple: false, isSortable: false, id: "IntegrationWidget.placeholder", defaultValue: null as EnumField["defaultValue"], enumValues: H.List([
            "client_add",
            "client_card_block",
            "client_card_tabs",
            "client_card_top",
            "client_list",
            "common_comment_body",
            "common_comment_form",
            "common_informer",
            "common_phone_number",
            "common_telephony_widget",
            "common_telephony_widget_config",
            "common_telephony_widget_header",
            "deal_add",
            "deal_card_block",
            "deal_card_status",
            "deal_card_tabs",
            "deal_card_top",
            "deal_list",
            "deal_list_buttons",
            "deal_positions",
            "doc_card_top",
            "employee_add",
            "employee_card_block",
            "employee_card_description",
            "employee_card_info",
            "employee_list",
            "events_list",
            "settings_api_telephony",
            "task_add",
            "task_card_block",
            "task_card_status",
            "task_card_top",
            "task_list"
        ])};
        export const content = {contentType: "StringField" as "StringField", name: "content", isMultiple: false, isSortable: false, id: "IntegrationWidget.content", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const appId = {contentType: "IntegerField" as "IntegerField", name: "appId", isMultiple: false, isSortable: false, id: "IntegrationWidget.appId", defaultValue: null as IntegerField["defaultValue"]};
        export const application = {contentType: "RefLinkField" as "RefLinkField", name: "application", isMultiple: false, isSortable: false, id: "IntegrationWidget.application", refContentType: H.List(["Integration"])};
        export const appName = {contentType: "StringField" as "StringField", name: "appName", isMultiple: false, isSortable: false, id: "IntegrationWidget.appName", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "IntegrationWidget.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const availableFor = {contentType: "RefLinkField" as "RefLinkField", name: "availableFor", isMultiple: true, isSortable: false, id: "IntegrationWidget.availableFor", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee", "Group"])};
        export const availableForCount = {contentType: "IntegerField" as "IntegerField", name: "availableForCount", isMultiple: false, isSortable: false, id: "IntegrationWidget.availableForCount", defaultValue: null as IntegerField["defaultValue"]};
        export const restrictedFor = {contentType: "RefLinkField" as "RefLinkField", name: "restrictedFor", isMultiple: true, isSortable: false, id: "IntegrationWidget.restrictedFor", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee", "Group"])};
        export const restrictedForCount = {contentType: "IntegerField" as "IntegerField", name: "restrictedForCount", isMultiple: false, isSortable: false, id: "IntegrationWidget.restrictedForCount", defaultValue: null as IntegerField["defaultValue"]};
        export const uuid = {contentType: "StringField" as "StringField", name: "uuid", isMultiple: false, isSortable: false, id: "IntegrationWidget.uuid", defaultValue: null as StringField["defaultValue"], maxLength: 0};
    }
}

export function isIntegrationWidget(arg: any): arg is IntegrationWidget {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "IntegrationWidget"
}
