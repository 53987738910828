import * as Api from "src/lib/entities/api"
import {observable, runInAction, computed, action} from "mobx"
import {autobind} from "core-decorators"
import * as Collections from "src/lib/collections"
import {ownErrorProp} from "src/lib/utils/form/types"
import {FormValidator} from "src/lib/utils/form/validation"
import {inject} from "src/lib/utils/inject"
import {Form} from "src/lib/utils/form/form"
import {Intl} from "src/lib/utils/intl/Intl"
import {ApiStore} from "src/lib/entities/store/ApiStore"
import ExtendableError from "es6-error"

interface FileUri {
    uri: string
    type: string
    name: string
    size: number
    metadata?: Api.ImageFileMetadata | Api.AudioFileMetadata
}

export function isFileUri(arg: any): arg is FileUri {
    return Boolean(arg.uri)
}

export type FileStoreFile = File | FileUri
type FileStoreProgressEvent = ProgressEvent

const libMessages: any = require("src/lib/messages.yml")

export interface UploadingFileProps {
    file: FileStoreFile,
    progress?: number,
    error?: any
}

export class FileUploadError extends ExtendableError {
    options: any
}

@autobind
export class PendingFile {

    @observable
    private $progress: number = 0

    @observable
    private $error: string | FileUploadError

    @observable.ref
    private $bindObject: UploadingFileProps

    @observable
    public isDropped: boolean

    constructor(private $file: FileStoreFile) {}

    @action
    public setProgress(event: FileStoreProgressEvent) {
        this.$progress = Math.round((event.loaded / event.total) * 100)
    }

    @action
    public setError(errorMsg: string | FileUploadError) {
        this.$error = errorMsg
    }

    public getProps() {
        if (!this.$bindObject) {
            const self = this
            this.$bindObject = {
                get file() { return self.$file },
                get progress() { return self.$progress },
                get error() { return self.$error }
            }
        }
        return this.$bindObject
    }
}

@autobind
export class FileStore {

    @observable
    private $uploadingFiles: PendingFile[] = []

    constructor(@inject(ApiStore) private apiStore: ApiStore) {}

    public upload(
        files: FileStoreFile[]|FileStoreFile,
        onSuccess?: (value: Collections.List<Api.File>) => void,
        onFail?: (e: any) => void,
        beforeLoad?: (file: FileStoreFile) => void,
        onUploadProgress?: () => void,
        noVisibleUploading?: boolean
    ) {
        if (Array.isArray(files)) {
            files.forEach(file => this.startUpload(file, onSuccess, onFail, beforeLoad, onUploadProgress, noVisibleUploading))
        } else {
            void this.startUpload(files, onSuccess, onFail, beforeLoad, onUploadProgress, noVisibleUploading)
        }
    }

    @action
    private async startUpload(
        file: FileStoreFile,
        onSuccess?: (value: Collections.List<Api.File>) => void,
        onFail?: (e: any) => void,
        beforeLoad?: (file: FileStoreFile) => void,
        onUploadProgress?: () => void,
        noVisibleUploading?: boolean
    ) {
        const data = new FormData()
        data.append("files[]", file as any)

        if (isFileUri(file) && file.metadata) {
            data.append("metadata[0][name]", file.name)
            if (Api.isImageFileMetadata(file.metadata)) {
                data.append("metadata[0][width]", file.metadata.width.toString())
                data.append("metadata[0][height]", file.metadata.height.toString())
            } else if (Api.isAudioFileMetadata(file.metadata)) {
                data.append("metadata[0][duration]", file.metadata.duration.toString())
            }
        }

        const pendingFile = new PendingFile(file)

        if (!noVisibleUploading) {
            this.$uploadingFiles.push(pendingFile)
        }

        try {
            if (beforeLoad) {
                beforeLoad(file)
            }
            const response = await this.apiStore.fetch<Api.File[]>("/api/file", {
                method: "POST",
                body: data,
                onUploadProgress: (event) => {
                    pendingFile.setProgress(event)
                    if (onUploadProgress) {
                        onUploadProgress()
                    }
                }
            })
            if (onSuccess && !pendingFile.isDropped) {
                onSuccess(Collections.List<Api.File>(response.value.data))
            }
        } catch (err) {
            if (!pendingFile.isDropped) {
                if (err instanceof FileUploadError) {
                    pendingFile.setError(err)
                } else {
                    pendingFile.setError(err.message && err.message.indexOf("413") !== -1 ? "fileIsTooLarge" : "connectionProblem")
                }
                if (onFail) {
                    onFail(err)
                }
            }
        } finally {
            runInAction(() => {
                const idx = this.$uploadingFiles.findIndex(f => f === pendingFile)
                if (idx !== -1 && !pendingFile.getProps().error) {
                    this.$uploadingFiles.splice(idx, 1)
                }
            })
        }
    }

    @computed
    public get uploadingFiles() {
        return this.$uploadingFiles.filter(file => !file.isDropped)
    }

    @computed
    public get isLoading() {
        return !!this.uploadingFiles.length
    }

    @action
    public abortUpload(file: PendingFile) {
        const instance = this.$uploadingFiles.find(pendingFile => pendingFile === file)
        if (instance) {
            instance.isDropped = true
        }
    }
}

class FileFormValidator extends FormValidator<Collections.List<Api.File>> {

    constructor(private fileStore: FileStore, private intl: Intl) {
        super()
    }

    validate(file: Collections.List<Api.File>) {
        if (this.fileStore && this.fileStore.isLoading) {
            return {
                [ownErrorProp]: Collections.List({
                    message: this.intl.formatMessage(libMessages["fileIsUploading"]),
                    type: "fileStore"
                })
            }
        }
        return {}
    }
}

@autobind
export class FileForm extends Form<Collections.List<Api.File>> {

    private $fileFormValidator: FileFormValidator

    constructor(
        valueProducer: () => Collections.List<Api.File>,
        private $fileStore: FileStore,
        @inject(Intl) private intl: Intl,
    ) {
        super(valueProducer)
        this.$fileFormValidator = new FileFormValidator(this.$fileStore, this.intl)
    }

    protected get validator() {
        return this.$fileFormValidator
    }

    public get isLoading() {
        return this.$fileStore.isLoading
    }

    public get fileStore() {
        return this.$fileStore
    }
}
