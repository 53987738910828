import {Component} from "src/lib/components/Component/Component"
import * as React from "react"
import {observer} from "mobx-react"
import {AdaptiveObservable, AdaptiveType, AdaptPoints, ObservableMediaQuery} from "./AdaptiveObservable"
import {CAdaptProvider} from "./CAdaptProvider"
import * as PropTypes from "prop-types"
import {CAdaptSelector} from "src/lib/components/CAdapt/CAdaptSelector"
import {indexOf} from "lodash"

const mobileAgentExpr = /(Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone)/i

/**
 *  вывод блоков в звисимости от разрешения экрана
 */
@observer
export class CAdapt extends Component<CAdapt.Props, {}> {

    //для тестов
    public static windowMatchMedia = window.matchMedia

    public adaptive = new AdaptiveObservable()

    public static contextTypes = {
        [CAdaptProvider.contextTypeKey]: PropTypes.object,
    }

    private get currentContextAdaptiveType() {
        return this.context[CAdaptProvider.contextTypeKey] ? this.context[CAdaptProvider.contextTypeKey].get() : null
    }

    private get currentAdaptiveType() {
        return this.currentContextAdaptiveType || this.adaptive.type
    }

    public renderChildren() {
        let classNameProp = this.props.className ? this.props.className : ""
        const children = "function" === typeof this.props.children
            ? (this.props.children as Function)(this.currentAdaptiveType)
            : this.props.children

        const childrenCount = React.Children.count(children)

        if (childrenCount === 0) {return null}

        const addClassName = (childrenCount === 1) && React.Children.only(children)
            ? React.Children.only(children).props.className
            : ""

        const wrapChildren = childrenCount > 1 ||
            typeof children === "string" ||
            typeof children === "undefined";

        if (wrapChildren) {
            return React.createElement(
                "div",
                {className: `${this.currentAdaptiveType} ${classNameProp}`},
                children
            );
        }

        return React.cloneElement(
            children as React.ReactElement<any>,
            {className: `${this.currentAdaptiveType} ${addClassName} ${classNameProp}`}
        );
    }

    public render() {
        const {type} = this.props
        const isMobile = CAdapt.isMobileDevice()

        if (
            !type
            || indexOf(type, this.currentAdaptiveType) !== -1
            || type === this.currentAdaptiveType
            || type === "untouch" && !isMobile
            || type === "touch" && isMobile
        ) {
            return this.renderChildren()
        } else {
            return null
        }
    }
}

export namespace CAdapt {
    export interface Props {
        type?:  AdaptiveType | AdaptiveType[]
        children?: (React.ReactNode | React.ReactNode[]) | ((type?: AdaptiveType) => (React.ReactNode | React.ReactNode[]))
        className?: string
    }

    export type Type = AdaptiveType | Array<AdaptiveType>
    export const Points = AdaptPoints

    export const Observable = AdaptiveObservable

    export const Provider = CAdaptProvider
    export type Provider = CAdaptProvider

    export const Selector = CAdaptSelector

    export const MediaQuery = ObservableMediaQuery

    export function isMobileDevice() {
        return !!document.documentElement.ontouchstart || !!navigator.userAgent.match(mobileAgentExpr)
    }
}
