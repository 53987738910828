import {autobind} from "core-decorators"
import {FileDropAreaStore} from "src/bums/common/globalDrag/FileDropAreaStore"
import {AbstractDragNDropStore} from "src/bums/common/globalDrag/AbstractDragNDropStore"
import {FileDropHandler} from "./types"

@autobind
export class WindowFileDropStore extends AbstractDragNDropStore {

    protected isEventSupported(event: DragEvent): boolean {
        return event.dataTransfer.types.includes("File") || event.dataTransfer.types.includes("Files")
    }

    public create(
        dropHandler: FileDropHandler,
        isMultiple: boolean = true,
        onBeforeDrop?: () => void,
        onAfterDrop?: () => void,
    ) {
        return new FileDropAreaStore(this, dropHandler, { isMultiple, onBeforeDrop, onAfterDrop })
    }

    public windowListener(event: any) {
        this.enterLeaveHandler(event)
    }
}
