import * as Api from "src/lib/entities/api"
import {MENU_EVENT_TYPE} from "src/bums/common/CMainMenu/tracker";

export function prepareDataForHotKeysTracking(args: any[]) {
    let item: Api.MenuItem
    let openModal: boolean
    let shortcut: string | number
    [item, openModal, shortcut] = [...args]
    const event = openModal ? "Go To Submenu" : "Go To Module"
    const module = item.isCustom ? "customModule" : item.id
    const customModuleName = item.isCustom ? item.title : ""
    const transitionType = "Shortcut"
    if (!openModal) {
        return {eventType: MENU_EVENT_TYPE, event, params: {module, transitionType, customModuleName, shortcut}}
    }
    return false
}
