import {action} from "mobx"
import {union, pickBy} from "lodash"
import {autobind} from "core-decorators";
import * as HttpStatus from "http-status-codes"
import * as Api from "src/lib/entities/api"
import * as Collections from "src/lib/collections"
import {
    getEntityFromSeriralizedJSON,
    getEntityTypeEndpoint,
    getGID,
    getLink,
    isEntityEquals,
} from "src/lib/entities/utils"
import {ListNamesByFilterGidStore} from "src/bums/common/stores/ListNamesByFilterGidStore"
import {StorageWithTTL} from "src/lib/storages/StorageWithTTL"
import {MemoryStorage} from "src/lib/storages/MemoryStorage"
import {SocketTransport} from "src/lib/services/types"
import {EntityEventInterface, RealTimeSubscription} from "src/bums/common/subscriptions/RealTimeSubscription"
import {
    TabMessageEvent,
    AbstractBrowserTab,
    AbstractBrowserTabFactory,
    AbstractMainTab
} from "src/bums/common/browserTabs/transports/AbstractTabTransport"
import {isDocumentHidden} from "src/lib/types"
import {getCyclesFreeNormalizedEntity} from "src/lib/entities/store/EntitiesStorage/entitiesStorageUtils";

const DEFAULT_UPDATE_TIMEOUT = 5000
const UPDATE_ENTITIES_LIMIT = 100
const LOADED_ENDPOINTS_HEARTBEAT_INTERVAL = 60000

const getLoadedEndpointsTtl = () => LOADED_ENDPOINTS_HEARTBEAT_INTERVAL + 10000 // как долго хранить эндпоинты соседних вкладок
const isResponseStatusOk = (response: Api.ApiResponse<any>) => response.meta.status === HttpStatus.OK

const ENTITY_UPDATED_KEY = "RealtimeEntitiesUpdater-updateEntity"
const ENTITY_IN_FILTERS_UPDATED_KEY = "RealtimeEntitiesUpdater-updateEntityInFilters"

export const LOADED_ENDPOINTS_KEY = "RealtimeEntitiesUpdater-loadedEndpoints"

type AllowedFilter = Api.TaskFilter | Api.CrmFilter

interface EntityInFilters {
    entity: Api.BaseEntity,
    filters: AllowedFilter[]
}

interface SetIntervalFunctionInterface {
    (handler: (...args: any[]) => void, timeout: number): number
}

// хранит логику обновления/удаления сущностей по событию из ecomet
@autobind
export class RealtimeEntitiesUpdater {

    // хранит события, по которым будут запросы на обновление сущностей
    private updateEvents = new EntityEventsStack()

    // события, по которым будут обновлены счетчики фильтров
    private updateFiltersCounterEvents = new EntityEventsStack()

    // хранит эндпоинты загруженных листов соседних вкладок
    private loadedEndpointsStorage = new StorageWithTTL(new MemoryStorage())

    private browserTab: AbstractBrowserTab

    private time: number = (new Date()).getTime()

    private $realTimeSubscription: RealTimeSubscription

    constructor(
        private apiStore: Api.Store,
        private listNamesByFilterGidStore: ListNamesByFilterGidStore,
        browserTabFactory: AbstractBrowserTabFactory,
        private ecomet: SocketTransport,
        setIntervalFunction: SetIntervalFunctionInterface = setInterval,
    ) {
        this.browserTab = browserTabFactory.createBrowserTabChannel("RealtimeEntitiesUpdater")
        this.$realTimeSubscription = new RealTimeSubscription(this.ecomet)

        this.browserTab.getReadyPromise().then((activeTab: AbstractMainTab) => {
            this.$realTimeSubscription.addListener<EntityEventInterface>("update", event => this.onEntityUpdated(new EntityEvent(event)))
            this.$realTimeSubscription.addListener<EntityEventInterface>("drop", event => this.onEntityDropped(new EntityEvent(event)))
            this.$realTimeSubscription.subscribe()
            if (activeTab.isMainTab) {
                // установка дефолтного интеравала обновления сущностей (нужно для тестов) и его последующее
                // асинхронное переопределение в зависимости от системной настройки
                const setIntervalHandle = setIntervalFunction(this.processUpdateEvents, DEFAULT_UPDATE_TIMEOUT)
                void this.resetUpdateTimeout(setIntervalHandle, setIntervalFunction)
            }
            this.subscribeOnActveTabEvents()
            this.subscribeOnLoadedEndpointsWindowsMessages()
            this.setLoadedEndpointsHeartbeatInterval()
        }).catch((e) => { throw e; })
    }

    // функция переопределяет частоту обновления сущностей
    private async resetUpdateTimeout(setIntervalHandle: number, setIntervalFunction: SetIntervalFunctionInterface) {
        // время на обновление сущностей и каунтеров
        const updateTimeout = await this.fetchUpdateFrequencyTimeout()
        clearInterval(setIntervalHandle)
        return setIntervalFunction(this.processUpdateEvents, updateTimeout)
    }

    private async processUpdateEvents() {
        this.doUpdateFiltersCounter(this.updateFiltersCounterEvents.take(UPDATE_ENTITIES_LIMIT))
        await this.doUpdateEvents(this.updateEvents.take(UPDATE_ENTITIES_LIMIT))
    }

    private subscribeOnActveTabEvents() {
        // подписка на обновление сущностей через хранилище
        // если мы получили это событие, то мы дочерняя вкладка, главная вкладка уже сделала все запросы
        // и раздает их нам через хранилище
        this.browserTab.on(ENTITY_UPDATED_KEY, (message: TabMessageEvent) => {
            if (message.isFromSameTab) {
                return
            }
            const data = message.data as any
            void this.updateEntity(getEntityFromSeriralizedJSON(data))
        })

        this.browserTab.on(ENTITY_IN_FILTERS_UPDATED_KEY, (message: TabMessageEvent) => {
            if (message.isFromSameTab) {
                return
            }
            const data = message.data as any
            const serializedObjects: {entity: string, filters: string}[] = JSON.parse(data)
            const entitiesInFilters = serializedObjects.map<EntityInFilters>(obj => {
                return {
                    entity: getEntityFromSeriralizedJSON(obj.entity),
                    filters: getEntityFromSeriralizedJSON(obj.filters),
                }
            })

            void this.updateFiltersLists(entitiesInFilters)
        })
    }

    // сохраняет список загруженных ендпоинтов соседних владок с определенным временем жизни
    private subscribeOnLoadedEndpointsWindowsMessages() {
        this.browserTab.on(LOADED_ENDPOINTS_KEY, (message: TabMessageEvent) => {
            const data = message.data as any
            this.loadedEndpointsStorage.setItem(this.time.toString(), data, getLoadedEndpointsTtl())
        })
    }

    private async fetchUpdateFrequencyTimeout() {
        // пытаемся получить настройку с частотой запросов по обновлению сущности
        // применяем дефолтную, если что-то пойдет не так
        if (process.env.REACT_NATIVE) {
            return DEFAULT_UPDATE_TIMEOUT
        }

        try {
            const setting = await this.apiStore.getOrLoadEntity<Api.SystemSetting<number>>({
                contentType: Api.SystemSetting.contentType,
                id: "realtimeEntitiesUpdater/updateFrequencyTime"
            })
            return setting.value || DEFAULT_UPDATE_TIMEOUT
        } catch (e) {
            return DEFAULT_UPDATE_TIMEOUT
        }
    }

    // обновляет сущность и списки куда она входит
    public onEntityUpdated(event: EntityEvent) {
        const collectUpdateEvent = ($event: EntityEvent) => {
            this.updateFiltersCounterEvents.push($event)
            this.updateEvents.merge($event)
        }

        // главная влкадка делает все необходимые запросы, обновляет свои сущности,
        // но так же раздает свои ответы на запросы через хранилище другим вкладкам, чтобы они тоже обновились
        return this.browserTab.getReadyPromise().then((activeTab: AbstractMainTab) => {
            if (activeTab.isMainTab()) {
                collectUpdateEvent(event)
            }
        }).catch(console.error)
    }

    // удаляет сущность из всех списков
    public onEntityDropped(event: EntityEvent) {
        const {entity} = event
        this.apiStore.deleteEntityFromAllLists([entity])
        this.apiStore.deleteAllTreeNodesWithEntity(entity)
        this.updateFiltersCounterEvents.push(event)
    }

    private async doUpdateEvents(eventsStack: EntityEventsStack) {
        if (eventsStack.isEmpty()) {
            return
        }

        await Promise.all(eventsStack.events.map(event => this.updateEntity(event.entity)))
        const entities = await this.fetchEntitiesWithMissinUpdatedFields(eventsStack)
        if (!entities.length) {
            return
        }

        this.generateUpdateEventForOtherTabs(entities)

        const entitiesInFilters = await this.fetchEntitiesInFiltersByEvents(eventsStack)
        this.generateUpdateEventForOtherTabs(entitiesInFilters)

        await this.updateFiltersLists(entitiesInFilters)
    }

    private generateUpdateEventForOtherTabs(data: Api.BaseEntity[] | EntityInFilters[]) {
        if (!process.env.REACT_NATIVE && data.length) {
            if (Api.isBaseEntity(data[0])) {
                (data as Api.BaseEntity[]).forEach(entity => {
                    const serializedJSON = JSON.stringify(getCyclesFreeNormalizedEntity(entity))
                    this.browserTab.broadcast(ENTITY_UPDATED_KEY, serializedJSON)
                })
            } else {
                const serializedJSON = JSON.stringify((data as EntityInFilters[]).map(entityInFilters => {
                    const {entity, filters} = entityInFilters
                    return {    // сущности и фильтры сериализуем отдельно, чтобы они не сконвертились просто в линк-сущность
                        entity: JSON.stringify(getCyclesFreeNormalizedEntity(entity)),
                        filters: JSON.stringify(filters.map(filter => getCyclesFreeNormalizedEntity(filter)))
                    }
                }))
                this.browserTab.broadcast(ENTITY_IN_FILTERS_UPDATED_KEY, serializedJSON)
            }
        }
    }

    private async fetchEntitiesWithMissinUpdatedFields(eventsStack: EntityEventsStack) {
        const entitiesForRequest = eventsStack.getEntitiesAndMissingUpdatedFields()

        if (entitiesForRequest.length) {
            let mergedFields: string[] = []     // недостающие поля по всем сущностям смержим в один массив
            entitiesForRequest.forEach(entityForRequest => {
                mergedFields = union(mergedFields, entityForRequest.missingFields)
            })

            const response = await this.apiStore.fetchEntities("/api/v3/bulk/getEntitiesByLinks", {
                method: "POST",
                bodyEntity: entitiesForRequest.map(entityForRequest => entityForRequest.entity),
                queryParams: {
                    fields: mergedFields,
                    onlyRequestedFields: true
                }
            })

            const responsedEntities = response.value.data

            entitiesForRequest.forEach(entityForRequest => {
                if (!responsedEntities.find(entity => isEntityEquals(entity, entityForRequest.entity))) {
                    this.onEntityDropped(new EntityEvent({entity: entityForRequest.entity}))
                }
            })
        }

        return eventsStack.events.map(event => this.apiStore.getEntity(event.entity))
    }

    @action
    private async updateEntity(entity: Api.BaseEntity) {
        await this.apiStore.updateEntitiesFromJson(entity)
        await this.updateSubTasksLists(entity)
    }

    private async fetchEntitiesInFiltersByEvents(eventsStack: EntityEventsStack) {
        const entitiesInFilters: EntityInFilters[] = []

        const allowedEntities = eventsStack.events
            .filter(event => Api.isTask(event.entity) || Api.isProject(event.entity) || Api.isContractor(event.entity))
            .map(event => event.entity)

        const apiCalls = Collections.List<Api.ApiCall>(allowedEntities
            .map(entity => {
                const endpoint = `${getEntityTypeEndpoint(entity)}/${entity.id}`

                return {
                    ...Api.ApiCall.newObject,
                    method: Api.ApiCall.Method.GET,
                    url: `${endpoint}/groups?${JSON.stringify({
                        withFixedFilters: true
                    })}`,
            } as Api.ApiCall
        }))

        if (apiCalls.length > 0) {
            const response = await this.apiStore.fetch<Api.ApiResponse<AllowedFilter[]>[]>("/api/v3/bulk", {
                method: "POST",
                bodyEntity: {
                    ...Api.BulkApiCall.newObject,
                    calls: apiCalls
                } as Api.BulkApiCall
            })

            response.value.data.forEach((apiCallResponse, index) => {
                if (isResponseStatusOk(apiCallResponse)) {
                    const filters = apiCallResponse.data
                    entitiesInFilters.push({
                        entity: this.apiStore.getEntity(allowedEntities[index]),
                        filters: filters
                    })
                }
            })
        }

        return entitiesInFilters
    }

    @action
    private async updateFiltersLists(entitiesInFilters: EntityInFilters[]) {
        if (!entitiesInFilters.length) {
            return
        }

        for (const entityInFilters of entitiesInFilters) {
            await this.updateEntity(entityInFilters.entity)
            await Promise.all(entityInFilters.filters.map(this.updateEntity))
        }

        const entitiesWithFilters = entitiesInFilters.filter(entityInFilter =>
            Object.keys(entityInFilter.filters).length ? entityInFilter.filters : false
        )

        const listApiCalls: {   // массив имя листа - вызов апи с сущностью для листа
            listName: string,
            apiCall: Api.ApiCall
        }[] = []

        // наполняем массив вызовов для включения в списки
        entitiesWithFilters.forEach(entityWithFilters => {
            const {entity} = entityWithFilters
            this.getListNames(entityWithFilters, "include").forEach(listName =>
                listApiCalls.push({listName: listName, apiCall: this.getApiCallForList(entity, listName)})
            )
        })

        // если есть листы для добавления
        if (listApiCalls.length) {
                // шлем запрос
            const responses = await this.apiStore.fetch<Api.ApiResponse<Api.BaseEntity[]>[]>("/api/v3/bulk", {
                method: "POST",
                bodyEntity: {
                    ...Api.BulkApiCall.newObject,
                    calls: Collections.List<Api.ApiCall>(listApiCalls.map(listApiCall => listApiCall.apiCall))
                } as Api.BulkApiCall
            })

            // для каждого удачного ответа на запрос - добавим сущность в список с учетом индекса
            for (let index = 0; index < responses.value.data.length; index++) {
                const response = responses.value.data[index]
                if (isResponseStatusOk(response)) {
                    const entity = response.data[0]
                    const elementIndex = response.meta.pagination.elementIndex

                    await this.updateEntity(entity)

                    const updatedEntity = this.apiStore.getEntity(entity)
                    const listName = listApiCalls[index].listName

                    await this.apiStore.listAppendEntityByIndex(listName, updatedEntity, elementIndex)
                }
            }
        }

        // убираем из листов
        entitiesWithFilters.forEach(entityWithFilters => {
            this.getListNames(entityWithFilters, "exclude")
                .forEach(listName => this.apiStore.listRemoveEntities(listName, [entityWithFilters.entity]))
        })
    }

    // возвращает имена листов в которые нужно включить/исключить сущность, в зависимости от ее фильтров
    private getListNames(entityInFilters: EntityInFilters, actionType: "include" | "exclude"): string[] {
        const {entity, filters} = entityInFilters
        const filterGIDs = filters.map((filter: AllowedFilter) => getGID(filter))

        // TODO Сделать обновление списков для иерархии
        const isTreeNodeList = (listName: string) => listName.indexOf("/api/v3/task/treeLevel:hash:") >= 0

        const listNames = this.listNamesByFilterGidStore.getListNamesWithFilterGIDs(filterGIDs, actionType === "include")
            .filter(listName => !isTreeNodeList(listName))

        return actionType === "include"
            ? listNames.filter(listName =>
                !this.apiStore.isEntityExistInList(entity, listName) &&
                this.apiStore.getList(listName).loadedEndpoint
              )
            : listNames.filter(listName => this.apiStore.isEntityExistInList(entity, listName))
    }

    // возвращает запрос на одну сущность в листе
    private getApiCallForList(entity: Api.BaseEntity, listName: string): Api.ApiCall {
        const list = this.apiStore.getList(listName)

        const jsonOptions = JSON.stringify({
            ...list.loadedWithOptions,
            pageWith: getLink(entity),
            limit: 1
        })

        return {
            ...Api.ApiCall.newObject,
            method: Api.ApiCall.Method.GET,
            url: `${list.loadedEndpoint}?${jsonOptions}`,
            body: jsonOptions
        }
    }

    // Обновляет листы с подзадачами
    @action
    private async updateSubTasksLists(entity: Api.BaseEntity) {
        if (
            entity &&
            (Api.isTask(entity) || Api.isProject(entity)) &&
            entity.parent &&
            this.apiStore.getEntities().get(entity.parent.contentType).get(entity.parent.id)
        ) {
            const parent = this.apiStore.getEntity(entity.parent)

            // если подзадач небыло - просто изменим их количество и ApiStore сам перезагрузит списки
            if (Api.isTask(parent) && parent.subTasksCount === 0) {
                parent.subTasksCount++
                return
            } else if (Api.isProject(parent) && parent.issuesCount === 0) {
                parent.issuesCount++
                return
            }

            const fieldNameInList = Api.isTask(parent)
                ? Api.Task.fields.subTasks.name
                : Api.Project.fields.issues.name

            const subTasksListName = `/api/v3/${parent.contentType.toLowerCase()}/${parent.id}/${fieldNameInList}`

            for (const listName of this.apiStore.getLists().keys()) {
                if (listName.indexOf(`${subTasksListName}`) >= 0) {
                    await this.apiStore.listLoadAndMergeEntityWithIndex(listName, entity)
                }
            }
        }
    }

    private doUpdateFiltersCounter(eventsStack: EntityEventsStack) {
        if (eventsStack.isEmpty()) {
            return
        }

        const getFilterContentTypeByEntity = (entity: Api.BaseEntity) => Api.isTask(entity) || Api.isProject(entity)
            ? Api.TaskFilter.contentType
            : Api.isContractor(entity)
                ? Api.CrmFilter.contentType
                : null

        const filtersContentTypes = union(
            eventsStack.events
            .map(event => getFilterContentTypeByEntity(event.entity))
            .filter(Boolean)
        )

        filtersContentTypes.filter(this.isNeedUpdateFiltersCounters).forEach(this.updateFiltersCounter)
    }

    private updateFiltersCounter(filtersContentType: string) {
        const endpoint = `${getEntityTypeEndpoint(filtersContentType)}`
        void this.apiStore.fetchEntities(endpoint, {
            queryParams: {
                fields: [Api.TaskFilter.fields.count.name],
                onlyRequestedFields: true
            }
        })
    }

    private isNeedUpdateFiltersCounters(filtersContentType: string): boolean {
        const endpoint = getEntityTypeEndpoint(filtersContentType)

        return this.getAllLoadedEnpoints().includes(endpoint)
    }

    private getAllLoadedEnpoints() {
        const loadedEndpoints: string[] = []

        if (process.env.REACT_NATIVE || !document.hidden) { // свои эндпоинты берем, только если вкладку видно
            this.apiStore.getLists().forEach(list => {  // берем свои эндпоинты
                if (list.loadedEndpoint) {
                    loadedEndpoints.push(list.loadedEndpoint)
                }
            })
        }
        if (!process.env.REACT_NATIVE) {
            this.loadedEndpointsStorage.forEach((value) => {  // добавляем эндопинты соседних вкладок
                if (value) {
                    const endpoints = JSON.parse(value) as Array<string>
                    endpoints.forEach(endpoint => loadedEndpoints.push(endpoint))
                }
            })
        }
        return union(loadedEndpoints)
    }

    // с определенным интервалом сообщаем соседним владкам о загруженных эндпоинтах
    private setLoadedEndpointsHeartbeatInterval() {
        if (!process.env.REACT_NATIVE) {
            const sendLoadedEndpointsToOtherWindows = () => {
                const loadedEndpoints: string[] = []
                this.apiStore.getLists().forEach(list => {
                    if (list.loadedEndpoint) {
                        loadedEndpoints.push(list.loadedEndpoint)
                    }
                })
                this.browserTab.broadcast(LOADED_ENDPOINTS_KEY, JSON.stringify(loadedEndpoints))
            }

            if (window && document) {
                setInterval(() => {
                    if (!isDocumentHidden()) {  // сердце перестает биться при закрытой вкладке
                        sendLoadedEndpointsToOtherWindows()
                    }
                }, LOADED_ENDPOINTS_HEARTBEAT_INTERVAL)

                // при открытии окна сразу всем скажем о своих эндпоинтах, иначе они обновятся только через минуту
                window.addEventListener("visibilitychange", () => {
                    if (!isDocumentHidden()) {
                        sendLoadedEndpointsToOtherWindows()
                    }
                })
            }
        }
    }
}

export class EntityEvent {

    constructor(
        private readonly event: EntityEventInterface,
    ) {

    }

    get entity() {
        return this.event.entity
    }

    get fields() {
        return this.event.fields || []
    }

    // возвращает имена полей которые обновились, но не пришли вместе с entity
    missingUpdatedFields() {
        const entityFields = Object.keys(pickBy(this.entity, value => typeof value !== "undefined"))
        return this.fields.filter(field => entityFields.indexOf(field) < 0)
    }
}

export class EntityEventsStack {
    constructor(private $events: EntityEvent[]= []
    ) {

    }

    get events() {
        return this.$events.slice()
    }

    get(index: number) {
        return this.$events[index]
    }

    clear() {
        this.$events = []
    }

    push(event: EntityEvent) {
        this.$events.push(event)
    }

    merge(event: EntityEvent) {
        // если событие с сущностью существет, то просто добавим отсутствующие поля, чтобы не плодить подзапросов
        const index = this.events.findIndex($event => isEntityEquals(event.entity, $event.entity))
        if (index >= 0) {
            this.$events.splice(index, 1, new EntityEvent({
                entity: {
                    ...this.get(index).entity,
                    ...event.entity
                },
                fields: union(this.get(index).fields, event.fields)
            }))
        } else {
            this.push(event)
        }
    }

    isEmpty() {
        return !this.$events.length
    }

    clone() {
        return new EntityEventsStack(this.events)
    }

    // создать новый стек, изъяв из текущего некоторое количество событий
    take(count: number) {
        return new EntityEventsStack(this.$events.splice(0, count))
    }

    // возвращает сущности и обновленные поля, которые отсутствуют в самих сущностях и их нужно запросить
    getEntitiesAndMissingUpdatedFields(): {entity: Api.BaseEntity, missingFields: string[]}[] {
        return this.events.map(event => {
            const missingFields = event.missingUpdatedFields()
            if (missingFields.length) {
                return {
                    entity: event.entity,
                    missingFields: missingFields
                }
            }

            return null
        }).filter(Boolean)   // выбирает не null элементы
    }
}
