export const simpleScale = {
    hidden: {
        scale: 0
    },
    visible: {
        scale: 1
    }
}

export const opacityEaseInOut = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.2,
            ease: "easeInOut"
        }
    }
}

export const immediateOpacity = {
    hidden: {
        opacity: 0,
        transition: {
            duration: 0
        }
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.2,
            ease: "easeInOut"
        }
    }
}
