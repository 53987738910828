import {autobind} from "core-decorators"
import {computed, observable} from "mobx"
import {KnownMetadata, isknownFieldMetaData} from "src/lib/components/CFieldValue/utils"
import * as Api from "src/lib/entities/api"
import {AbstractModalForm} from "src/bums/common/modalForm/AbstractModalForm"
import {TaskExtraFieldsStore} from "src/bums/common/stores/TaskExtraFieldsStore"
import {UserSettingStore} from "src/bums/common/stores/UserSettingStore"
import {ProjectExtraFieldsStore} from "src/bums/common/stores/ProjectExtraFieldsStore"
import {ApiStore} from "src/lib/entities/store/ApiStore"
import {FormValidator} from "src/lib/utils/form/validation"
import {ContractorFormFactory} from "src/bums/crm/form/ContractorFormFactory"
import {isAnyTypeUserField} from "src/lib/components/CFieldValue/CRefValue/utils"
import {IssueFormField} from "src/bums/task/form/types";

@autobind
export abstract class IssueForm<T extends Api.Task | Api.Project> extends AbstractModalForm<T> {

    @observable
    protected fieldsSettingStore: UserSettingStore<string[]>

    constructor(
        valueProducer: () => T,
        apiStore: ApiStore,
        validationMap: FormValidator<T>,
        protected contractorFormFactory: ContractorFormFactory,
        public extraFieldsStore: TaskExtraFieldsStore | ProjectExtraFieldsStore
    ) {
        super(valueProducer, apiStore, validationMap)

        this.extraFields.forEach(field => {
            if (isAnyTypeUserField(field)) {
                (this as any)[field.name] = this.form<any, any>(
                    field.name,
                    fieldValue => this.contractorFormFactory.createFormForContactor(fieldValue)
                )
            }
        })
    }

    @computed
    public get defaultSelectedStaticFieldsNames(): string[] {
        return this.staticCardFields
            .filter(field => {
                return this.get("isTemplate") || !field.selectedForTemplateOnly
            })
            .map(field => field.name)
    }

    @computed
    public get extraFields(): KnownMetadata[] {
        return this.extraFieldsStore.listStore.originalItems
            // Оставляем известные экстраполя которые отмечены как видимые для карточки создания
            .filter(field =>
                isknownFieldMetaData(field)
                && Api.getFieldSettingValue(field, "add_form_visibility", false)
            )
            .sort((field1, field2) => {
                const field1orderPos = field1.orderPos === void 0 ? -Infinity : field1.orderPos
                const field2orderPos = field2.orderPos === void 0 ? -Infinity : field2.orderPos
                return field1orderPos > field2orderPos ? 1
                    : field1orderPos < field2orderPos ? -1
                        : 0
            })
            .toArray() as KnownMetadata[]
    }

    public async whenComplete() {
        await Promise.all([
            this.fieldsSettingStore.get().promise
        ])
    }

    @computed
    public get extraFieldsByNames() {
        const extFields = {} as {[fieldName: string]: KnownMetadata}

        this.extraFields.forEach(field => {
            extFields[field.name] = field
        })

        return extFields
    }

    @computed
    public get allFields(): IssueFormField[] {
        return this.staticCardFields.concat(this.extraFields
            .map(field => {
                return {
                    name: field.name,
                    hrName: field.hrName,
                    isNotConfigurable: field.isRequired
                }
            }))
    }

    public get selectedFieldsNames(): string[] {
        const setting = this.fieldsSettingStore.get()

        if (setting.state === "fulfilled") {
            let fieldNames = setting.value

            // Если сохранена пустая настройка то выводим все поля, таким образом на всех существующих аккаунтах
            // будут отображаться все поля, а на новых только дефолтные поля
            if (!fieldNames.length) {
                return this.defaultSelectedStaticFieldsNames.concat(this.extraFields.map(field => field.name))
            }

            // обязательные филды должны быть отображены даже если они не выбраны
            const requiredExtraFieldsNames = this.extraFields
                .filter(field => field.isRequired && !fieldNames.includes(field.name))
                .map(field => field.name)

            return fieldNames.concat(requiredExtraFieldsNames)
        }

        return []
    }

    public async setSelectedFieldsNames(value: string[]) {
        await this.fieldsSettingStore.set(value)
    }

    public abstract get syncFields(): string[]

    public sync(issue: Api.Task | Api.Project): void {
        this.syncFields.forEach(field => {
            const value = issue[field]
            if (value !== void 0) {
                this.set(field, value)
            }
        })
    }

    protected abstract get staticCardFields(): IssueFormField[]
}
