import {autobind} from "core-decorators"
import {action, computed, observable, runInAction} from "mobx"
import {isFloatField, Task, Project, isTask, isProject} from "src/lib/entities/api"
import {FileForm, FileStore} from "src/lib/entities/store/FileStore"
import * as Collections from "src/lib/collections"
import {OwnError} from "src/lib/utils/formError"
import {FormValidator} from "src/lib/utils/form/validation"
import {ApiStore} from "src/lib/entities/store/ApiStore"
import {Intl} from "src/lib/utils/intl/Intl"
import {isEntityEquals} from "src/lib/entities/utils"
import {TaskExtraFieldsStore} from "src/bums/common/stores/TaskExtraFieldsStore"
import {inject} from "src/lib/utils/inject"
import {bindArg} from "src/lib/utils/func"
import {hasRights} from "src/lib/entities/api"
import {UserStore} from "src/lib/entities/store/UserStore"
import * as Api from "src/lib/entities/api"
import {ContractorFormFactory} from "src/bums/crm/form/ContractorFormFactory"
import {UserSettingStore} from "src/bums/common/stores/UserSettingStore"
import {StaticTaskCardFields} from "src/bums/task/utils"
import {IssueForm} from "src/bums/task/form/IssueForm"
import {EmployeeFormFactory} from "src/bums/common/form/EmployeeFormFactory"
import {TaskFormField} from "src/bums/task/form/types";
import {FeatureStore} from "src/bums/common/stores/FeatureStore";
import {NegotiationFormCollection} from "src/bums/task/form/NegotiationFormCollection";
import {AccountInfoStore} from "src/lib/entities/store/AccountInfoStore";
import {prepareExtraFieldDefaultValue} from "src/lib/utils/form/utils";

const messages: any = require("../messages.yml")
const libMessages: any = require("src/lib/messages.yml")

function refLinkValidateMessageFn(intl: Intl, fieldValue: Task | Project, formValue: Task | Project) {
    return isTask(formValue)
        ? intl.formatMessage(messages["parentTaskNoRightTask"])
        : intl.formatMessage(messages["parentTaskNoRightProject"])
}

function checkRights(fieldValue: Task | Project, formValue: Task | Project) {
    if (isTask(fieldValue)) {
        return hasRights(fieldValue, "act_subtask")
    }

    if (isProject(fieldValue)) {
        const contentType = formValue.contentType
        if (contentType === Task.contentType) {
            return hasRights(fieldValue, "act_create_task")
        } else {
            return hasRights(fieldValue, "act_create_subproject")
        }
    }

    return true
}

export const TASK_DEFAULT_REQUIRED_FIELDS = [
    Task.fields.name,
    Task.fields.responsible
]

const DEFAULT_CRM_LITE_FIELDS = [
    Task.fields.name,
    Task.fields.deadline,
    Task.fields.responsible,
    Task.fields.executors,
    Task.fields.contractor,
]

const DEFAULT_OTHER_EDITION_FIELDS = [
    Task.fields.name,
    Task.fields.statement,
    Task.fields.deadline,
    Task.fields.responsible,
    Task.fields.auditors,
    Task.fields.parent,
]

@autobind
export class TaskFormFactory {

    constructor(
        @inject(Intl) private $intl: Intl,
        @inject(ApiStore) private $apiStore: ApiStore,
        @inject(UserStore) private $userStore: UserStore,
        @inject(TaskExtraFieldsStore) private $taskExtraFieldsStore: TaskExtraFieldsStore,
        @inject(ContractorFormFactory) private $contractorFormFabric: ContractorFormFactory,
        @inject(EmployeeFormFactory) private $employeeFormFactory: EmployeeFormFactory,
        @inject(FeatureStore) private $featureStore: FeatureStore,
        @inject(AccountInfoStore) private $accountInfoStore: AccountInfoStore,
    ) {}

    public newTaskValue(value: Task) {
        return {
            name: "",
            deadline: null,
            parent: null,
            isUrgent: false,
            isTemplate: false,
            owner: value.isTemplate ? null : this.$userStore.user,
            responsible: this.$userStore.user,
            contractor: null,
            attaches: Collections.List<File>(),
            negotiationItems: Collections.List<Api.NegotiationItem>({
                contentType: Api.NegotiationItem.contentType,
                versions: Collections.List<Api.NegotiationItemVersion>({
                    contentType: Api.NegotiationItemVersion.contentType,
                })
            }),
            ...(value.isTemplate ? { isTemplateOwnerCurrentUser: true} : {}),
            ...this.defaultExtraFields,
            ...value,
            contentType: Task.contentType
        } as Task
    }

    /**
     * Расширенные поля с дефолтными значениями
     * (берутся только для полей с типом Bool и Enum: только их значения пользователь может указывать явно
     */
    @computed
    private get defaultExtraFields() {
        return this.$taskExtraFieldsStore
            .items
            .map(field => field.item)
            .filter(field => field && !field.isSystem)
            .reduce((result, field) => {
                if (!field) {
                    return result
                }

                result[field.name] = prepareExtraFieldDefaultValue(field)
                return result
            }, {} as {[fieldName: string]: any})
    }

    /**
     * Какой филд как валидировать и какую ошибку возвращать
     */
    @computed
    public get formValidationMap() {
        const validationMap = this.$taskExtraFieldsStore
            .items
            .map(field => field.item)
            .filter(field => field && !field.isSystem)
            .reduce((result, field) => {
                if (!field) {
                    return result
                }

                if (isFloatField(field)) {
                    const {minValue, maxValue} = field
                    result.set(field.name, FormValidator.floatFieldValidator(minValue, maxValue, {
                        emptyError: this.$intl.formatMessage(libMessages["emptyFieldError"]),
                        rangeError: this.$intl.formatMessage(libMessages["constraintNumberPlaceholder"], {maxValue, minValue})
                    }))
                }

                if (field.isRequired) {
                    result.set(field.name, FormValidator.nonEmptyFieldValidator(
                        this.$intl.formatMessage(libMessages["emptyFieldError"])
                    ))
                }

                return result
            }, new FormValidator<Task>())

        TASK_DEFAULT_REQUIRED_FIELDS.forEach((field) => {
            validationMap.set(field.name, FormValidator.nonEmptyFieldValidator(
                this.$intl.formatMessage(libMessages["emptyFieldError"])
            ))
        })

        validationMap.set(Task.fields.parent.name, FormValidator.rightsValidator(
            checkRights,
            bindArg(refLinkValidateMessageFn, this.$intl)
        ))

        // Если мобила - убираем валидацию надзадачи
        if (process.env.REACT_NATIVE) {
            validationMap.remove(Task.fields.parent.name)
        }

        return validationMap
    }

    public async create(value: Task, fixedFields: (keyof Task)[] = []) {

        await Promise.all([
            ...this.$contractorFormFabric.getContractorHumanFormFactory().getDependencies(),
            ...this.$contractorFormFabric.getContractorCompanyFormFactory().getDependencies(),
            this.$taskExtraFieldsStore.listStore.whenComplete()
        ])

        const formValue = this.newTaskValue(value)

        const form = new TaskForm(
            () => formValue,
            this.$apiStore,
            this.formValidationMap,
            this.$intl,
            this.$contractorFormFabric,
            this.$employeeFormFactory,
            this.$taskExtraFieldsStore,
            this.$featureStore,
            this.$userStore,
            this.$accountInfoStore,
        )

        form.setFixedFields(fixedFields)

        await form.whenComplete()

        return form as TaskForm
    }
}

const employeeChildFormFields = [
    Task.fields.responsible.name,
    Task.fields.auditors.name,
    Task.fields.executors.name
]

@autobind
export class TaskForm extends IssueForm<Task> {

    private fileStore: FileStore

    @observable
    public attaches: FileForm

    constructor(
        valueProducer: () => Task,
        apiStore: ApiStore,
        validationMap: FormValidator<Task>,
        private intl: Intl,
        contractorFormFactory: ContractorFormFactory,
        private employeeFormFactory: EmployeeFormFactory,
        extraFieldsStore: TaskExtraFieldsStore,
        featureStore: FeatureStore,
        private userStore: UserStore,
        accountInfoStore: AccountInfoStore,
    ) {
        super(valueProducer, apiStore, validationMap, contractorFormFactory, extraFieldsStore)
        this.fileStore = new FileStore(apiStore)
        this.attaches = this.form("attaches", (files) =>  new FileForm(() => files, this.fileStore, this.intl))
        this.fieldsSettingStore = new UserSettingStore<string[]>(
            apiStore,
            () => `task_${this.get("isTemplate") ? "template_" : ""}card_fields`,
            () => StaticTaskCardFields(intl, featureStore.isAvailable("bums.task.negotiation_new_card"))
                .filter(field => {
                    const defaultFields = accountInfoStore.isEdition("BumsEdition_CrmLite")
                        ? DEFAULT_CRM_LITE_FIELDS
                        : DEFAULT_OTHER_EDITION_FIELDS

                    const isDefaultField = defaultFields.find(defaultField => defaultField.name === field.name)

                    return (isDefaultField
                        || (this.get("isTemplate") && field.selectedForTemplateOnly)
                        || (this.get("isNegotiation") && field.selectedForNegotiation))
                        && (!field.right || hasRights(this.userStore.user, field.right))
                })
                .map(field => field.name)
        )

        employeeChildFormFields.forEach((fieldName) => {
            (this as any)[fieldName] = this.form(fieldName, (fieldValue) => fieldValue && this.employeeFormFactory.create(fieldValue))
        })

        // Если груповая задача, делаем ответственного необязательным полем
        this.setFieldValidated(Task.fields.responsible.name, !this.get("isGroup"))
    }

    @observable
    public negotiationItems = this.form(
        "negotiationItems",
        negotiationItems => new NegotiationFormCollection(
            () => negotiationItems,
            this.intl,
            () => this.get("isNegotiation")
        )
    )

    @observable
    public contractor = this.form(
        "contractor",
        contractor => this.contractorFormFactory.createFormForContactor(contractor)
    )

    public async changeTemplateHandler(template: Api.Task) {
        if (template) {
            this.changePendingState(true)
            try {
                const response = await this.apiStore.entityBasedFetch(
                    template,
                    "loadTaskTemplates",
                    Api.getListEndpoint(template, "preparedTemplate")
                )
                const entity = response.value.data[0] as Api.Task

                runInAction(() => {
                    Object.keys(entity).forEach(key => entity[key] && this.set(key, entity[key]))
                    this.set("originalTemplate", template)
                })
            } finally {
                this.changePendingState(false)
            }
        } else if (this.get("originalTemplate")) {
            this.reset()
        }
    }

    @computed
    public get exludesParticipants() {
        const {owner, responsible, isTemplateOwnerCurrentUser} = this.value
        if (owner && responsible && isEntityEquals(owner, responsible)) {
            return Collections.List([owner])
        }
        if (isTemplateOwnerCurrentUser) {
            return Collections.List([this.userStore.user, responsible])
        }
        return Collections.List([owner, responsible])
    }

    @computed
    public get exludesExecutors() {
        if (this.value.isNegotiation) {
            return Collections.List()
        }

        return this.exludesParticipants
    }

    public get syncFields() {
        return Object.keys(Task.newObject).filter(field => !["contentType", "originalTemplate"].includes(field))
    }

    @action
    public setFieldValidated(fieldName: string,  value: boolean) {
        this.$validator.remove(fieldName)

        if (value) {
            this.$validator.set(fieldName, FormValidator.nonEmptyFieldValidator(
                this.intl.formatMessage(libMessages["emptyFieldError"])
            ))
        }
    }

    @computed
    protected get staticCardFields(): TaskFormField[] {
        return StaticTaskCardFields(this.intl, this.get("isNegotiation"))
            .filter(field => {
                return (!this.get("isTemplate") || !field.disableForTemplate) // Поля только для шаблонов и для задач-согласования
                    && (this.get("isNegotiation") || !field.enabledForNegotiationOnly) // Поля только для задач-согласования
                    && (!field.right || hasRights(this.userStore.user, field.right))
            })
    }

    public get selectedFieldsNames(): string[] {
        const selectedFieldsNames = super.selectedFieldsNames

        // Убедимся, что обязательные поля будут отображены
        const fieldNames = Array.from(new Set(TASK_DEFAULT_REQUIRED_FIELDS.map(field => field.name).concat(...selectedFieldsNames)))

        // Если задача-согласование добавим поля, которые обязательно должны быть выбраны для них
        if (this.get("isNegotiation")) {
            this.allFields
                .filter((field: TaskFormField) => field.selectedForNegotiation && !fieldNames.includes(field.name))
                .forEach(field => fieldNames.push(field.name))
        }

        // Если обычная задача и в сохраненных полях есть поле "Материалы", нужно его убрать
        return fieldNames
            .filter(fieldName => {
                return this.get("isNegotiation") || fieldName !== "negotiationItems"
            })
    }

    getValueForSave(value: Partial<Task>) {

        value = super.getValueForSave(value)

        if (!value.isNegotiation) {
            delete value.negotiationItems
        }

        if (value.isTemplateOwnerCurrentUser) {
            return {...value, owner: null}
        }

        return value
    }

    @computed
    public get parentError() {
        const parentValueErrors = this.getErrors("parent")

        if (parentValueErrors.length) {
            return parentValueErrors
        }

        return Collections.List(FormValidator.rightsValidator(
            checkRights,
            bindArg(refLinkValidateMessageFn, this.intl)
        )(this.get("parent"), this.value) as OwnError)
    }
}
