/* tslint:disable */
import * as H from "../metadataHelper"
import {StringField} from "./stringField"

export interface Position extends H.BaseEntity {
    name?: string;
    id?: string; // Идентификатор
    contentType: "Position"; // Object type
}

export module Position {
    export const contentType: "Position" = "Position";
    export const endpoint = "/api/v3/position";

    export const newObject: Position = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "Position.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
    }
}

export function isPosition(arg: any): arg is Position {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Position"
}
