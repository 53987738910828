import * as React from "react"
import * as Dom from "react-dom"
import {autobind} from "core-decorators"
import {observer} from "mobx-react"
import {observable, computed, reaction, action, runInAction} from "mobx"
import {inject} from "src/lib/utils/inject"
import {uniqueId, noop} from "lodash"
import {List, Map as CollectionMap} from "src/lib/collections"
import classNames from "classnames/bind"
import {convertClientRectToPageRect} from "src/lib/utils/window"
import {makeRefHandler} from "src/lib/utils/func"
import {CPopover, CStyledContent, Component} from "src/lib/components"
import {CFormFieldBorderBottom} from "src/lib/components"
import CEditorToolbar from "./CEditorToolbar"
import LinkEntityDecorator from "./entities/LinkEntity"
import {createPlugin, MentionPlugin} from "./components/CMentionSuggestions"
import EditorStore from "./EditorStore"
import * as WindowHelper from "src/lib/utils/window"
import {formFieldStatus} from "src/lib/types"
import {convertToHtml, convertFromHtml} from "./utils/converter"
import Editor from "draft-js-plugins-editor"
import * as CodeUtils from "draft-js-code"
import PrismDecorator from "draft-js-prism"
import MultiDecorator from "draft-js-multidecorators"
import {
    EditorState,
    EditorChangeType,
    RichUtils,
    Modifier,
    getVisibleSelectionRect,
    FakeClientRect,
    Entity,
    getDefaultKeyBinding,
    SelectionState,
    CompositeDecorator,
    KeyBindingUtil,
    DefaultDraftBlockRenderMap,
    ContentState,
    ContentBlock,
    DraftHandleValue,
} from "draft-js"

import {
    splitBlockBySelectionBorder,
    convertSelectionInBlocks,
    convertSelectionInBlock,
    createSelection,
    moveSelectionToEndFirstBlock,
    insertBlockToTheEnd,
    hasPlainBlock,
    removeAllInlineStyle,
} from "src/lib/utils/draft"

import createAutoListPlugin from "draft-js-autolist-plugin"
import createPastePlugin from "./draft-js-paste-plugin"

const style: any = classNames.bind(require("./CEditor.styl"))

const autoListPlugin = createAutoListPlugin()
const pastePlugin = createPastePlugin()

const plugins = [
    autoListPlugin,
    pastePlugin,
]

require("./prism-default.styl")

const inlineStyles = List<string>(["BOLD", "ITALIC", "UNDERLINE", "STRIKETHROUGH", "CODE", "LINK", "NONE"])
const singleInlineStyles = List<string>(["LINK"])

const lineEndRegExp = /\n/g
const lastBrInParagraphRegExp = /<br><\/p>/g

const blockMapToInline = CollectionMap({
    "code-block": "CODE",
    "unstyled": "NONE"
})

const blockRenderMap = DefaultDraftBlockRenderMap
    .set("code-block", {
        element: "code",
        wrapper: <pre/>
    }).set("blockquote", {
        element: "blockquote",
        wrapper: void 0
    }).set("paragraph", {
        element: "p",
        wrapper: void 0
    }).set("unstyled", {
        element: "div",
        wrapper: void 0
    })


const blockStyleFn = (contentBlock: ContentBlock) => {
    const type = contentBlock.getType()
    switch (type) {
        case "unstyled":
        case "paragraph":
            return style("public-CEditor-unstyled")
    }
}

const customStyleMap = {
    "CODE": {
        fontFamily: "monospace",
        fontSize: "14px",
        backgroundColor: "rgba(0, 0, 0, 0.05)",
        padding: "0 4px",
        margin: "0 8px"
    }
}

export interface CEditorProps {
    field: string
    value?: string
    placeholder?: string
    onCtrlEnter?: () => void
    onEscape?: () => void
    enterAndCtrlEnter?: boolean
    onChange?: (content: string) => void
    onBlur?: () => void
    onAttachFiles?: (files: File[]) => void
    className?: string
    editorClassName?: string
    id?: string
    disabled?: boolean
    autoFocus?: boolean
    toolbarTheme?: "full" | "mini"
    noStyled?: boolean
    scrollIntoView?: boolean
    status?: formFieldStatus
    submitHandlers?: submitHandlers
    borderBottom?: boolean
    enableMentions?: boolean
    plugins?: object[]
    onFocus?: () => void
}

export type submitHandlers = {
    blockSubmit: () => void
    unblockSubmit: () => void
}

const decoratorInitializer = (propsMapper: () => any) => {
    return new MultiDecorator([
        new PrismDecorator({defaultSyntax: "javascript"}),
        new CompositeDecorator([
            LinkEntityDecorator(propsMapper),
        ])
    ])
}

interface CDisableEditorProps {
    value: string
    placeholder: string
    className: string
}

const CDisableEditor = observer((props: CDisableEditorProps) => {
    let content: JSX.Element
    if (props.value) {
        content = <div className="public-DraftEditor-content">
            <div dangerouslySetInnerHTML={{__html: props.value}} />
        </div>
    } else {
        content = <div className="public-DraftEditorPlaceholder-root">
            <div className="public-DraftEditorPlaceholder-inner">
                {props.placeholder}
            </div>
        </div>
    }
    return <div className={style(props.className, "disabled", {withPlaceholder: !props.value})}>{content}</div>
})

export namespace CEditor {
    export type Props = CEditorProps
}

declare var window: any

@observer
@autobind
export class CEditor extends Component<CEditorProps, {}> {
    static defaultProps = {
        onChange: noop,
        onBlur: noop,
        toolbarTheme: "full",
        noStyled: false,
        status: "normal",
        borderBottom: true,
        enableMentions: false,
        plugins: [] as object[]
    }

    public editor: Editor
    private editorId: string

    private mentionPlugin: MentionPlugin

    public constructor(props: CEditorProps) {
        super(props)
        this.editorId = props.id || uniqueId("editor")
        this.mentionPlugin = createPlugin()
    }

    @inject(EditorStore)
    private editorStore: EditorStore

    @observable
    private clientRectSelection: FakeClientRect = null

    @observable
    private outerToolbarState = false

    @observable
    private needCalcPosition = false

    @observable
    private isFocused = false

    private isFocusPreserved = false

    @computed
    private get editorState() {
       return this.editorStore.get(this.editorId)
    }

    @computed
    private get decorators() {
        return [LinkEntityDecorator(this.decoratorPropsMapper)]
    }

    @computed
    private get plugins() {
        const editorPlugins = this.props.plugins.concat(plugins)
        if (this.useMentions) {
            editorPlugins.push(this.mentionPlugin.plugin)
        }
        return editorPlugins
    }

    private scrollContainer: HTMLElement

    @action
    private changeOuterToolbarState = (state: boolean) => {
        if (this.outerToolbarState !== state) {
            this.outerToolbarState = state
            if (state === false) {
                this.focus()
            }
        }
    }

    @action
    private recalculatePositionToolbarIfNeeded = (force = false) => {
        if (this.needCalcPosition || force) {
            const clientRectSelection = getVisibleSelectionRect(window)
            if (clientRectSelection) {
                this.clientRectSelection = convertClientRectToPageRect(clientRectSelection)
                this.needCalcPosition = false
            }
        }
    }

    private recalculatePositionCaret() {
        const editorSelection = this.editorState.getSelection()
        if (editorSelection.getHasFocus() && editorSelection.isCollapsed()) {
            const editorNode = Dom.findDOMNode(this.editor)

            if (!editorNode) {
                return
            }

            if (!this.scrollContainer) {
                this.scrollContainer = WindowHelper.getScrollContainer(editorNode as HTMLElement)
            }

            const container = this.scrollContainer

            if (WindowHelper.getSelection().rangeCount === 0) {
                return
            }

            const rangeAt = WindowHelper.getSelection().getRangeAt(0)
            let clientRect: ClientRect

            if (rangeAt.startContainer.nodeName === "#text") {
                clientRect = getVisibleSelectionRect(window)
            } else {
                clientRect = (rangeAt.startContainer as Element).getBoundingClientRect()
            }

            if (!clientRect) {
                return
            }

            const containerHeightNoScroll = container.scrollHeight - container.scrollTop

            if (clientRect.bottom + 103 >= containerHeightNoScroll) {
                container.scrollTop = clientRect.bottom + 103 + container.scrollTop - container.clientHeight
                if (clientRect.bottom + 103 >= document.body.clientHeight) {
                    // обернуто в try/catch, не все браузеры поддерживают опции в scrollIntoView (например Safari)
                    try {
                        container.scrollIntoView({block: "center"})
                    } catch (e) {
                        container.scrollIntoView()
                    }
                }
            } else if ((container.clientHeight - clientRect.bottom) < 40) {
                container.scrollTop += (40 - (container.clientHeight - clientRect.bottom))
            } else if ((clientRect.top < 46)) {
                container.scrollTop -= (46 - clientRect.top)
            }
        }
    }

    @action
    private updateEditorState(editorState: EditorState) {
        if (this.props.disabled) {
            return
        }
        const oldState = this.editorState
        const oldSelectionState = oldState.getSelection()
        const newSelectionState = editorState.getSelection()
        const hasSelectedActive = !newSelectionState.isCollapsed()
        if ((oldSelectionState.isCollapsed() && hasSelectedActive) || (hasSelectedActive && (
            (oldSelectionState.getStartKey() !== newSelectionState.getStartKey()) ||
            (oldSelectionState.getStartOffset() !== newSelectionState.getStartOffset())
        ))) {
            this.needCalcPosition = true
        }
        this.editorStore.update(this.editorId, editorState)
    }

    public componentWillMount() {
        let editorState = this.createEditorState(this.props)

        if (this.props.autoFocus) {
            editorState = EditorState.moveFocusToEnd(editorState)
        }

        this.editorStore.update(this.editorId, editorState)

        reaction(() => this.editorState, this.onChangeHandler)
    }

    public componentDidMount() {
        document.addEventListener("scroll", this.scrollHandler)
        window.addEventListener("focus", this.restoreFocus)
        window.addEventListener("blur", this.preserveFocus)
        if (this.props.autoFocus) {
            this.focus()
        }
    }

    public componentWillUnmount() {
        document.removeEventListener("scroll", this.scrollHandler)
        window.removeEventListener("focus", this.restoreFocus)
        window.removeEventListener("blur", this.preserveFocus)
    }

    public componentDidUpdate(prevProps: CEditorProps) {
        this.recalculatePositionToolbarIfNeeded()
        this.recalculatePositionCaret()
    }

    private restoreFocus = () => {
        if (this.isFocusPreserved) {
            this.focus()
        }
        this.isFocusPreserved = false
    }

    private preserveFocus = () => {
        if (this.isFocused) {
            this.isFocusPreserved = true
        }
    }

    private stateToHtml(content: ContentState): string {
        const resultContent = convertToHtml(content)
        return resultContent.replace(lineEndRegExp, "").replace(lastBrInParagraphRegExp, "</p><p><br></p>")
    }

    public componentWillReceiveProps(nextProps: CEditorProps) {
        const currentContent = this.editorState.getCurrentContent()
        const text = (currentContent.hasText() ? this.stateToHtml(currentContent) : "")

        if (nextProps.autoFocus && !this.props.autoFocus) {
            this.focus()
        }
        if (text !== nextProps.value) {
            this.editorStore.update(this.editorId, this.createEditorState(nextProps))
        }
    }

    private decoratorPropsMapper() {
        return {
            editorState: this.editorState,
            onChange: this.changeHandler,
            changeOuterToolbarState: this.changeOuterToolbarState,
            submitHandlers: this.props.submitHandlers
        }
    }

    private createEditorState(props: CEditorProps): EditorState {
        if (props.value) {
            const contentState = convertFromHtml(props.value)

            return EditorState.createWithContent(contentState, decoratorInitializer(this.decoratorPropsMapper))
        } else {
            return EditorState.createEmpty(decoratorInitializer(this.decoratorPropsMapper))
        }
    }

    private scrollHandler() {
        if (!this.editorState.getSelection().isCollapsed()) {
            this.recalculatePositionToolbarIfNeeded(true)
        }
    }

    private hasBlockSelection() {
        const content = this.editorState.getCurrentContent()
        const selection = this.editorState.getSelection()
        const block = content.getBlockForKey(selection.getStartKey())
        return (
            (selection.getStartKey() !== selection.getEndKey()) ||
            (selection.getStartOffset() === 0 && selection.getEndOffset() === block.getText().length)
        )
    }

    private onChangeHandler() {
        const currentContent = this.editorState.getCurrentContent()
        if (!this.props.value && !currentContent.hasText()) {
            return
        }

        if (!currentContent.hasText()) {
            this.props.onChange("")
            return
        }

        this.props.onChange(this.stateToHtml(currentContent))
    }

    private changeHandler = (editorState: EditorState) => {
        this.updateEditorState(editorState)
    }

    public isEmpty() {
        return !this.editorState.getCurrentContent().getPlainText().trim()
    }

    public insertBlock(type: string, text: string) {
        this.editorStore.insertBlock(this.editorId, type, text)
    }

    public moveSelectionToStart() {
        let newEditorState = EditorState.forceSelection(
            this.editorState,
            createSelection(
            [this.editorState.getCurrentContent().getFirstBlock().getKey(), 0]
            )
        )

        this.updateEditorState(newEditorState)
    }

    public clearContent() {
        let newEditorState = EditorState.createWithContent(ContentState.createFromText(""))

        this.updateEditorState(newEditorState)
    }

    public insertTextInCurrentPosition(text: string) {
        const oldState = this.editorState
        const newContentState = Modifier.insertText(oldState.getCurrentContent(), oldState.getSelection(), text)
        this.updateEditorState(EditorState.push(oldState, newContentState, "insert-characters"))
    }

    private handleKeyCommand = (command: string): DraftHandleValue => {
        if (this.props.disabled) {
            return "not-handled"
        }

        let newState: EditorState

        if (command === "user-command-ctrl-enter") {
            this.props.onCtrlEnter()
            return "handled"
        }

        if (CodeUtils.hasSelectionInBlock(this.editorState)) {
            newState = CodeUtils.handleKeyCommand(this.editorState, command);
        }
        if (!newState) {
            newState = RichUtils.handleKeyCommand(this.editorState, command)
        }
        if (newState) {
            this.changeHandler(newState)
            return "handled"
        }
        return "not-handled"
    }

    private handleKeyBinding = (event: React.KeyboardEvent<HTMLElement>): string => {
        if (this.props.disabled) {
            event.preventDefault()
            return null
        }

        if (event.key === "PageUp") {
            this.blur()
            return null
        }

        if (event.key === "Enter" && this.isEmpty()) {
            return null
        }

        let command: string
        const {onCtrlEnter, enterAndCtrlEnter} = this.props

        if (event.key === "Enter"
            && !event.shiftKey
            && (enterAndCtrlEnter || onCtrlEnter && KeyBindingUtil.hasCommandModifier(event))) {
            command = "user-command-ctrl-enter"
        } else if (CodeUtils.hasSelectionInBlock(this.editorState)) {
            command = CodeUtils.getKeyBinding(event)
        }

        if (command) {
            return command
        }

        return getDefaultKeyBinding(event)
    }

    private handleReturn = (event: React.KeyboardEvent<HTMLElement>): DraftHandleValue => {
        if (this.props.disabled) {
            event.preventDefault()
            return "not-handled"
        }
        if (!CodeUtils.hasSelectionInBlock(this.editorState)) {
            return "not-handled"
        }
        this.changeHandler(CodeUtils.handleReturn(event, this.editorState))
        return "handled"
    }

    private handleTab = (event: React.KeyboardEvent<HTMLElement>) => {
        this.changeHandler(RichUtils.onTab(event, this.editorState, 5))
    }

    private handleToggleToolbarButton = (type: string, event?: React.MouseEvent<HTMLElement>) => {
        if (event) {
            event.preventDefault()
        }

        let appliedType = type
        let nextState =  this.editorState
        let cancelableSelection = false
        let appliedAction: EditorChangeType
        const currentBlockType = RichUtils.getCurrentBlockType(nextState)
        const currentInlineStyle = nextState.getCurrentInlineStyle()
        const hasBlockSelection = this.hasBlockSelection()

        if (nextState.getSelection().isCollapsed()) {
            return
        }

        if (!hasBlockSelection && blockMapToInline.has(appliedType) && (appliedType === "unstyled" || currentBlockType !== appliedType)) {
            appliedType = blockMapToInline.get(appliedType)
        }

        if (inlineStyles.includes(appliedType)) {
            if (singleInlineStyles.includes(appliedType)) {
                cancelableSelection = true
                const entityKey = Entity.create("NO-CONFIRM-LINK", "MUTABLE")
                if (hasBlockSelection) {
                    nextState = moveSelectionToEndFirstBlock(nextState)
                }
                nextState = RichUtils.toggleInlineStyle(nextState, "NONE")
                nextState = RichUtils.toggleLink(nextState, nextState.getSelection(), entityKey)
            } else {
                nextState = RichUtils.toggleLink(nextState, nextState.getSelection(), null)
                if (appliedType === "NONE") {
                    nextState = removeAllInlineStyle(nextState)
                } else {
                    nextState = RichUtils.toggleInlineStyle(nextState, appliedType)
                }
            }
            appliedAction = "change-inline-style"
        } else {
            cancelableSelection = true

            const offsetBlock = nextState.getCurrentContent().getBlockForKey(nextState.getSelection().getStartKey())
            // Выносим выделяемую область в отдельный блок
            if (!hasPlainBlock(offsetBlock) && nextState.getSelection().getStartOffset() !== 0) {
                nextState = splitBlockBySelectionBorder(nextState, "start")
            }

            const focusBlock = nextState.getCurrentContent().getBlockForKey(nextState.getSelection().getEndKey())

            if (!hasPlainBlock(focusBlock) && nextState.getSelection().getEndOffset() !== focusBlock.getText().length) {
                nextState = splitBlockBySelectionBorder(nextState, "end")
            }

            nextState = convertSelectionInBlocks(nextState, "unstyled")

            if (currentBlockType !== appliedType) {
                if (["ordered-list-item", "unordered-list-item"].includes(appliedType)) {
                    nextState = EditorState.push(
                        nextState,
                        Modifier.setBlockType(nextState.getCurrentContent(), nextState.getSelection(), appliedType),
                        "change-block-type"
                    )
                } else {
                    nextState = convertSelectionInBlock(nextState, appliedType)
                }
            }

            currentInlineStyle.forEach(inlineStyle => {
                nextState = RichUtils.toggleInlineStyle(nextState, inlineStyle)
            })

            appliedAction = "change-block-type"
        }

        if (!hasPlainBlock(nextState.getCurrentContent().getLastBlock())) {
            nextState = insertBlockToTheEnd(nextState, "unstyled", "")
        }

        nextState = EditorState.push(this.editorState, nextState.getCurrentContent(), appliedAction)

        if (cancelableSelection) {
            nextState = EditorState.forceSelection(nextState, createSelection([
                nextState.getSelection().getStartKey(),
                nextState.getSelection().getStartOffset()
            ]));
        }

        this.changeHandler(nextState)
    }

    private handlePastedText = (text: string, html?: string): DraftHandleValue => {

        if (html) {

            return "not-handled"

        } else {
            const cleanText = text.trim()
            const currentBlockType = (RichUtils.getCurrentBlockType(this.editorState))
            let contentState: ContentState

            if (currentBlockType === "unstyled") {
                const blockMap = ContentState.createFromText(cleanText).getBlockMap()
                contentState = Modifier.replaceWithFragment(this.editorState.getCurrentContent(), this.editorState.getSelection(), blockMap)
            } else {
                const currentSelection = this.editorState.getSelection()
                const actionFn = currentSelection.isCollapsed() ? Modifier.insertText : Modifier.replaceText
                contentState = actionFn(this.editorState.getCurrentContent(), currentSelection, text)
            }

            this.changeHandler(EditorState.push(this.editorState, contentState, "insert-fragment"))

            return "handled"
        }
    }

    private handlePastedFiles = (files: Blob[]): DraftHandleValue => {
        if (this.props.disabled) {
            event.preventDefault()
            return "handled"
        }
        if (this.props.onAttachFiles) {
            this.props.onAttachFiles(files.slice(0, 1).map((blobFile, index) => {
                return new File([blobFile], `Clipboard${(new Date()).getTime()}-${uniqueId()}.png`)
            }))
        }
        return "handled"
    }

    private focusHandler = (event: React.MouseEvent<HTMLElement>) => {
        if (this.props.disabled) {
            event.preventDefault()
            event.stopPropagation()
            return
        }
        this.focus()
    }

    public focus() {
        setTimeout(action(() => {
            if (this.editor) {
                this.editor.focus()
                this.isFocused = true

                if (this.props.onFocus) {
                    this.props.onFocus()
                }

            }
        }), 50)
    }

    @action
    public blur() {
        if (this.outerToolbarState) {
            return
        }
        const currentSelection = this.editorState.getSelection()
        this.changeHandler(EditorState.acceptSelection(this.editorState, new SelectionState({
            anchorKey: currentSelection.getEndKey(),
            anchorOffset: currentSelection.getEndOffset(),
            focusKey: currentSelection.getEndKey(),
            focusOffset: currentSelection.getEndOffset(),
        })))
        this.editor.blur()
        this.props.onBlur()
        // Отправляем в конец очереди, что бы во время выполнения глобального лисенера значение сохранилось
        setTimeout(() => runInAction(() => this.isFocused = false), 0)
    }

    private get useMentions() {
        return window.feature_mentions && this.props.enableMentions
    }

    public render() {
        const currentSelection = this.editorState.getSelection()
        const contentState = this.editorState.getCurrentContent()
        const editorClassName = style("editor", this.props.editorClassName, {
            hidePlaceholder: contentState.hasText(),
            noStyled: this.props.noStyled
        })

        const top = this.clientRectSelection ? this.clientRectSelection.top : 0
        const left = this.clientRectSelection ? this.clientRectSelection.left : 0
        const isAndroidDevice = !!navigator.userAgent.match(/Android/i)

        return <CStyledContent className={style("root", this.props.className)}>
            <CPopover
                element={<span />}
                position={{top, left}}
                open={!currentSelection.isCollapsed() && !this.outerToolbarState}
                className={style("popover", "popoverContainer")} >
                <CEditorToolbar
                    editorState={this.editorState}
                    onClickButton={this.handleToggleToolbarButton}
                    theme={this.props.toolbarTheme}
                />
            </CPopover>
            {this.props.disabled ?
                <CDisableEditor value={this.props.value} placeholder={this.props.placeholder} className={editorClassName} />
                :
                <div data-field={this.props.field} className={editorClassName} onClick={this.focusHandler} onBlur={this.blur} >
                    <div className={style("box")}>
                        <Editor
                            blockRenderMap={blockRenderMap}
                            blockStyleFn={blockStyleFn}
                            customStyleMap={customStyleMap}
                            editorState={this.editorState}
                            onChange={this.changeHandler}
                            onTab={this.handleTab}
                            placeholder={this.props.placeholder}
                            ref={makeRefHandler(this, "editor")}
                            handleKeyCommand={this.handleKeyCommand}
                            keyBindingFn={this.handleKeyBinding}
                            handleReturn={this.handleReturn}
                            spellCheck={true}
                            handlePastedText={this.handlePastedText}
                            handlePastedFiles={this.handlePastedFiles}
                            plugins={this.plugins}
                            decorators={this.decorators}
                            autoCapitalize={isAndroidDevice ? "none" : void 0}
                            autoComplete={isAndroidDevice ? "off" : void 0}
                            autoCorrect={isAndroidDevice ? "off" : void 0}
                            onEscape={this.props.onEscape}
                        />
                        {this.useMentions &&
                            <this.mentionPlugin.component onTab={this.handleTab} editorState={this.editorState}/>}
                    </div>
                    {this.props.borderBottom &&
                         <CFormFieldBorderBottom
                             status={this.isFocused ? "focus" : this.props.status || "normal"}
                         />
                    }
                </div>
            }
        </CStyledContent>
    }
}
