import * as React from "react"
import classNames from "classnames/bind"
import {action, computed, observable} from "mobx"
import {findDOMNode} from "react-dom"
import {observer} from "mobx-react"
import {autobind} from "core-decorators"
import Scrollbars, {positionValues} from "react-custom-scrollbars"
import {makeRefHandler} from "src/lib/utils/func"
import {CScrollView, Component} from "src/lib/components"

// для тестов
type _Element = Element
type _HTMLDivElement = HTMLDivElement

const style = classNames.bind(require("./CCustomScrollbars.styl"))

export namespace CCustomScrollbars {
    export interface Props {
        type?: "default" | "bigTable" | "horizontal" | "page"
        onScrollFrame?: (values: positionValues) => void
        draggableScroll?: boolean
        customScrollProps?: any
        autoScrollLeft?: number
        // кастомный контейнер для скролла
        customVerticalRef?: Element | Window
        // направление драг скролла
        draggableScrollType?: "horizontal" | "vertical" | "both"
        // стиль для полоски скроллбара
        trackStyle?: React.CSSProperties
        viewClassName?: string
        dragCursor?: boolean
        shadowBoxClass?: string
        useTouchEventScroll?: boolean
    }
}

type ScrollbarsInstance = Scrollbars & {
    view: React.ReactInstance
    handleWindowResize: () => void
}

/**
 * дизайнерский скролл
 */
@observer
@autobind
export class CCustomScrollbars extends Component<CCustomScrollbars.Props, {}> {

    public static defaultProps = {
        type: "default",
        draggableScrollType: "horizontal",
        shadowBoxClass: false,
    }

    @observable
    private scrollTop = 0

    @observable.ref
    public scrollView: _Element

    public scrollbarsInstance: ScrollbarsInstance

    public componentDidMount() {
        if (!this.scrollbarsInstance) {
            return
        }

        this.setScrollRef(findDOMNode(this.scrollbarsInstance.view) as HTMLDivElement)

        if (this.scrollView && this.props.autoScrollLeft) {
            this.scrollView.scrollLeft = this.props.autoScrollLeft
        }
    }

    public componentDidUpdate(prevProps: CCustomScrollbars.Props) {
        if (this.scrollView && prevProps.autoScrollLeft !== this.props.autoScrollLeft) {
            this.scrollView.scrollLeft = this.props.autoScrollLeft
        }
    }

    @action
    private setScrollRef(element: _HTMLDivElement) {
        if (element) {
            this.scrollView = element
        }
    }

    private renderTrackHorizontal(value: {style: React.CSSProperties}) {
        const displayStyle = (value.style && value.style.display)
            ? {display: value.style.display}
            : {}

        const trackStyle = this.props.trackStyle
            ? this.props.trackStyle
            : {}

        return <div
            className={
                style(
                    "track",
                    "trackHorizontal",
                    {
                        "fixed": this.props.type === "bigTable",
                        "page": this.props.type === "page"
                    }
                )
            }
            style={{...displayStyle, ...trackStyle}}
        />
    }

    private renderThumbVertical() {
        return <div className={style("thumb", "thumbVertical")} />
    }

    private renderThumbHorizontal() {
        return <div className={style("thumb", "thumbHorizontal")} />
    }

    private renderView(value: { style: React.CSSProperties }) {
        const styles = this.props.viewClassName ? {} : {...value.style, position: "static"}
        return <div className={style("view", this.props.viewClassName ? this.props.viewClassName : void 0)}
                    style={styles}
        />
    }

    @action
    private onScrollFrame(values: positionValues) {
        if (this.props.onScrollFrame) {
            this.props.onScrollFrame(values)
        }
        this.scrollTop = values.scrollTop
    }

    private showShadow(): boolean {
        return this.scrollTop > 5 && Boolean(this.props.shadowBoxClass)
    }

    @action
    public scrollLeft(scrollLeft: number) {
        if (this.scrollbarsInstance) {
            this.scrollbarsInstance.scrollLeft(scrollLeft)
        }
    }

    public render() {
        const {type, draggableScroll, shadowBoxClass} = this.props

        let scrollProps = (!type || type === "default")
            ? {
                style: {height: "100%"},
            }
            : {
                autoHeight: false,
                universal: true,
                renderTrackHorizontal: this.renderTrackHorizontal,
                renderThumbHorizontal: this.renderThumbHorizontal,
                renderView: this.renderView,
            }

        scrollProps = Object.assign(scrollProps, this.props.customScrollProps)

        let content = <Scrollbars
                {...scrollProps}
                onScrollFrame={this.onScrollFrame}
                renderThumbVertical={this.renderThumbVertical}
                ref={makeRefHandler(this, "scrollbarsInstance")}
                hideTracksWhenNotNeeded={true}
            >
                {this.props.children}
            </Scrollbars>

        content = draggableScroll
            ? <CScrollView
                scrollType={this.props.draggableScrollType}
                refElement={this.scrollView}
                customVerticalRef={this.props.customVerticalRef}
                dragCursor={this.props.dragCursor}
                useTouchEventScroll={this.props.useTouchEventScroll}
            >
                {content}
            </CScrollView>
            : content

        if (shadowBoxClass) {
            return <div className={style("shadowContainer")}>
                <CScrollbarsBoxShadow
                    show={this.showShadow}
                    className={shadowBoxClass} />
                {content}
            </div>
        }

        return content
    }
}

@observer
class CScrollbarsBoxShadow extends Component<{show: () => boolean, className?: string}, {}> {

    @computed
    private get show() {
        return this.props.show()
    }

    render() {
        const {className} = this.props

        if (this.show) {
            return <div className={`${style("shadowBox")} ${className ? className : ""}`}/>
        } else {
            return null
        }
    }
}

