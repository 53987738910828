import * as React from "react"
import {observer} from "mobx-react"
import {autobind} from "core-decorators"
import {CButton, Component, CSticker} from "src/lib/components"
import {CRatingStepProps} from "src/bums/common/promo/CRating/types"
import {CRatingBlock} from "src/bums/common/promo/components/CRatingBlock/CRatingBlock"
import {FormattedMessage, FormattedMessageHtml} from "src/lib/utils/intl"
import {track} from "src/lib/tracking"
import {SHARE_CLICK, trackClickAtHref} from "./track"
import {RatingStore} from "src/bums/common/promo/stores/RatingStore"

export namespace CStartPackReview {
}

const messages = require("../messages.yml")
const href = "https://startpack.ru/articles/new?type=reviews&app=megaplan-business-automation"
const site = "startpack.ru"

@track({channels: ["product"], eventType: SHARE_CLICK})
@observer
@autobind
export class CStartPackReview extends Component<CRatingStepProps, {}> {

    public static condition(store: RatingStore) {
        return store.isVoted && store.rating === 5
    }

    @track(trackClickAtHref(href, site))
    private onLinkClick(event: React.MouseEvent<Node> | void) {
        // empty
    }

    public render() {
        return <React.Fragment>
            <CRatingBlock
                image={<CSticker stickerName={CSticker.Name.WomanOK} />}
                title={<FormattedMessage {...messages["thanksForAnswerTitle"]} />}
                explanation={<span onClick={this.onLinkClick}>
                    <FormattedMessageHtml {...messages["reviewExplanation"]} values={{href, site}} />
                </span>}
            />
            <CButton
                name={"sendReview"}
                type={CButton.Type.FLAT_SUCCESS}
                caption={<FormattedMessage {...messages["shareYourOpinion"]} />}
                href={href}
                onClick={this.onLinkClick}
                target={"_blank"}
            />
        </React.Fragment>
    }
}
