import {Component} from "src/lib/components"
import {observer} from "mobx-react"
import {computed} from "mobx"
import {inject} from "src/lib/utils/inject"
import {InformerStore} from "src/bums/common/informerPanel/informerStore"
import {FeatureStore} from "src/bums/common/stores/FeatureStore"
import {BubbleType} from "src/bums/common/informerPanel/types"
import {ProductQuestRatingStore} from "src/bums/common/promo/CProductQuestRating/ProductQuestRatingStore"

@observer
export class CProductQuestRatingContainer  extends Component<{}, {}> {

    @inject(FeatureStore)
    private featureStore: FeatureStore

    @inject(InformerStore)
    private informerStore: InformerStore

    @inject(ProductQuestRatingStore)
    private productQuestRatingStore: ProductQuestRatingStore

    @computed
    private get isAvailable() {
        if (this.featureStore.isAvailable("bums.common.product_quest_rating")
            && this.productQuestRatingStore.isQuarterRatingFeatureAvailable) {
            const userSetting = this.productQuestRatingStore.setting
            if (userSetting.state === "fulfilled" && !userSetting.value) {
                return true
            }
        }
        return false
    }

    public render(): null {
        if (this.isAvailable) {
            this.informerStore.setOpenedBubble(BubbleType.ProductQuest)
        }
        return null
    }
}
