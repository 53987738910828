import {
    Group,
    Employee,
    ContactInfo,
    isEmployee,
} from "src/lib/entities/bums"

import {Intl} from "src/lib/utils/intl"
import {List, isList} from "src/lib/collections"
import {ApiStore} from "src/lib/entities/store/ApiStore"
import {EmployeeForm} from "src/bums/common/form/form/EmployeeForm"
import * as Collections from "src/lib/collections"
import {UNKNOWN_ID_PREFIX} from "src/lib/entities/types"
import {AbstractModalFormCollection} from "src/bums/common/modalForm/AbstractModalFormCollection"
import {GroupForm} from "src/bums/common/form/form/GroupForm"
import {inject} from "src/lib/utils/inject"
import {isIdUnknown} from "src/lib/entities/utils";
import {Form} from "src/lib/utils/form/form";

const spaceRegex = /\s+/g
const phoneRegex = /^(\+?)\d{9,12}$/g
const emailRegex = /@/

export function createPresetFromString(inputValue: string): Employee {

    let value: string | string[] = inputValue.trim().replace(spaceRegex, " ")

    const entity = {
        id: UNKNOWN_ID_PREFIX,
        contentType: Employee.contentType,
        lastName: "",
        firstName: "",
        middleName: "",
        gender: Employee.Gender.male,
        contactInfo: Collections.List([
            {...ContactInfo.newObject, type: ContactInfo.Type.email, value: "", isMain: true},
            {...ContactInfo.newObject, type: ContactInfo.Type.phone, value: "", isMain: true}
        ])
    }

    if (value.split(" ").length > 1) {
        value = value.split(" ")
        entity.lastName = value.shift()
        entity.firstName = value.shift()
        entity.middleName = value.join(" ")
    } else if (value.match(emailRegex)) {
        entity.contactInfo.first().value = value
    } else if (value.match(phoneRegex)) {
        entity.contactInfo.last().value = value
    } else {
        entity.lastName = value
    }

    return entity
}


type BaseFormType = Employee | Group

export class EmployeeFormFactory {

    constructor(@inject(ApiStore) private apiStore: ApiStore, @inject(Intl) private intl: Intl) {
    }

    public create(value: BaseFormType | List<BaseFormType>, customSubmitHandler?: (valueToSave: Employee) => void) {
        if (isList(value)) {
            // нужна чтобы иметь возможность дёргать getValueForSave у childForms
            return new AbstractModalFormCollection(() => value, entity =>
                isEmployee(entity) ? this.createEmployeeForm(entity) : this.createGroupForm(entity)
            )
        }

        const form = isEmployee(value) ? this.createEmployeeForm(value) : this.createGroupForm(value)
        if (customSubmitHandler && form instanceof EmployeeForm) {
            form.setCustomSubmitHandler(customSubmitHandler)
        }

        return form
    }

    private createGroupForm(group: Group) {
        return new GroupForm(() => group, this.apiStore)
    }

    private createEmployeeForm(employee: Employee) {
        return employee && employee.id && !isIdUnknown(employee.id)
            ? new Form(() => employee)
            : new EmployeeForm(() => employee, this.apiStore, this.intl)
    }
}
