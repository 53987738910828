import {autobind} from "core-decorators";
import {FeatureStore} from "src/bums/common/stores/FeatureStore";
import {FirstConfigureFormValue} from "src/bums/common/onboarding/utils"
import {action, computed} from "mobx";
import {
    OnboardingPollStore,
    StepConfig,
    StepId
} from "src/bums/common/onboarding/firstConfigure/store/OnboardingPollStore";
import {Intl} from "src/lib/utils/intl/Intl";
import {UserStore} from "src/lib/entities/store/UserStore"

const messages = require("../messages.yml")

@autobind
export class OnboardingPollStepsFactory {

    private readonly isRegistrationWithEmail: boolean = false

    constructor(
        private store: OnboardingPollStore,
        private featureStore: FeatureStore,
        private intl: Intl,
        private userStore: UserStore
    ) {
     if (this.featureStore.isAvailable("bums.common.registration_with_email") && !this.userStore.phone) {
         this.isRegistrationWithEmail = true
     }
    }

    @computed
    private get isPhoneAtTheEnd() {
        return this.featureStore.isAvailable("bums.common.onboarding_poll_phone_end")
    }

    @computed
    public get stepsMap() {
        const config = this.isPhoneAtTheEnd ? this.phoneAtTheEndVersion : this.mainVersion

        return new Map<StepId, StepConfig>(config)
    }

    @computed
    private get mainVersion() {
        return [
            ...(this.isRegistrationWithEmail
            ? [[
                StepId.NameAndPhone,
                {
                    nextStepFactory: () => StepId.ForWhom,
                    customButtons: true
                }
                ]] as Array<[StepId, StepConfig]>
            : [[
                StepId.Greeting,
                {
                    nextStepFactory: () => StepId.ForWhom
                }
            ]] as Array<[StepId, StepConfig]>
            ),
            [
                StepId.ForWhom,
                {
                    nextStepFactory: () => "forMyself" === this.store.form.get("forWhom")
                        ? null
                        : StepId.NameOfYourCompany
                    ,
                    canStepNext: () => !!this.store.form.get("forWhom"),
                }
            ],
            [
                StepId.NameOfYourCompany,
                {
                    nextStepFactory: () => StepId.Industry,
                    onEnterToStep: () => this.setFormValueIfNull("nameOfYourCompany", this.intl.formatMessage(messages.myCompanyOOO)),
                    canStepNext: () => !!this.store.form.get("nameOfYourCompany")
                }
            ],
            [
                StepId.Industry,
                {
                    nextStepFactory: () => StepId.YourRole,
                    canStepNext: () => !!this.store.form.get("chooseIndustry"),
                }
            ],
            [
                StepId.YourRole,
                {
                    nextStepFactory: () => StepId.HowManyEmployees,
                    canStepNext: () => !!this.store.form.get("chooseRole"),
                }
            ],
            [
                StepId.HowManyEmployees,
                {
                    title: () => "forDepartment" === this.store.form.get("forWhom")
                        ? this.intl.formatMessage(messages.howManyEmployeesInDepartment)
                        : this.intl.formatMessage(messages.howManyEmployeesInCompany)
                    ,
                    nextStepFactory: () => StepId.ExperienceWithCRM,
                    canStepNext: () => !!this.store.form.get("howManyEmployees"),
                }
            ],
            [
                StepId.ExperienceWithCRM,
                {
                    nextStepFactory: () => null,
                    canStepNext: () => !!this.store.form.get("experienceWithCRM"),
                }
            ],

        ] as Array<[StepId, StepConfig]>
    }

    @computed
    private get phoneAtTheEndVersion() {
        return [
            [
                StepId.Greeting,
                {
                    nextStepFactory: () => StepId.ForWhom,
                    skipPollFactory: () => this.isRegistrationWithEmail ? StepId.NamePhoneAndCompany : null
                }
            ],
            [
                StepId.ForWhom,
                {
                    nextStepFactory: () => "forMyself" === this.store.form.get("forWhom")
                        ? null
                        : StepId.Industry
                    ,
                    canStepNext: () => !!this.store.form.get("forWhom"),
                }
            ],
            [
                StepId.Industry,
                {
                    nextStepFactory: () => StepId.YourRole,
                    canStepNext: () => !!this.store.form.get("chooseIndustry"),
                }
            ],
            [
                StepId.YourRole,
                {
                    nextStepFactory: () => StepId.HowManyEmployees,
                    canStepNext: () => !!this.store.form.get("chooseRole"),
                }
            ],
            [
                StepId.HowManyEmployees,
                {
                    title: () => "forDepartment" === this.store.form.get("forWhom")
                        ? this.intl.formatMessage(messages.howManyEmployeesInDepartment)
                        : this.intl.formatMessage(messages.howManyEmployeesInCompany)
                    ,
                    nextStepFactory: () => this.isRegistrationWithEmail ? StepId.NamePhoneAndCompany : null,
                    canStepNext: () => !!this.store.form.get("howManyEmployees"),
                }
            ],
            [
                StepId.NamePhoneAndCompany,
                {
                    nextStepFactory: () => null,
                    onEnterToStep: () => this.setFormValueIfNull("nameOfYourCompany", this.intl.formatMessage(messages.myCompanyOOO)),
                    customButtons: true,
                }
            ]
        ] as Array<[StepId, StepConfig]>
    }

    @action
    private setFormValueIfNull<K extends keyof FirstConfigureFormValue>(key: K, value: FirstConfigureFormValue[K]) {
        if (null === this.store.form.get<K>(key)) {
            this.store.form.set<K>(key, value)
        }
    }
}
