import * as Collections from "src/lib/collections"
import {Some} from "src/lib/utils/option"
import {Address, ContactInfo, isContactInfo, Payer} from "src/lib/entities/api"
import {BubbleType} from "src/bums/common/informerPanel/types"

const publicMailDomain = [
    "123.ru", "163.com", "aaanet.ru", "akerd.com", "aol.com", "bigmir.net", "bk.ru", "bmail.ru", "drdrb.com", "drdrb.net",
    "e-mail.ua", "e-xe.ru", "e1.ru", "email.ru", "email.ua", "eml.ru", "freemail.ru", "fromru.com", "front.ru", "gala.net",
    "gmail.com", "gmail.ru", "gmx.com", "gmx.de", "google.com", "googlemail.com", "hotbox.ru", "hotmail.com", "hotmail.ru",
    "i.ua", "icloud.com", "inbox.lv", "inbox.ru", "irk.ru", "jnxjn.com", "klzlk.com", "km.ru", "kmtlang.com", "land.ru",
    "lenta.ru", "li.ru", "list.ru", "live.com", "live.ru", "mac.com", "mail.by", "mail.com", "mail.kz", "mail.ru", "mail.ua",
    "mail333.com", "mailforspam.com", "mailinator.com", "mailspeed.ru", "me.com", "meta.ua", "mfsa.ru", "msn.com", "my.com",
    "narod.ru", "nepwk.com", "newmail.ru", "nextmail.ru", "ngs.ru", "nm.ru", "nur.kz", "nxt.ru", "online.ua", "open.by",
    "outlook.com", "perm.ru", "pisem.net", "pjjkp.com", "pochta.ru", "pochtamt.ru", "post.ru", "prbb.ru", "prtnx.com",
    "qip.ru", "rambler.ru", "rbcmail.ru", "rmqkr.net", "ro.ru", "rppkn.com", "ru.ru", "russia.ru", "seznam.cz", "sharklasers.com",
    "sibmail.com", "smtp.ru", "spaces.ru", "temporamail.com", "trbvm.com", "tut.by", "ua.fm", "ukr.net", "vtomske.ru", "xakep.ru",
    "XAKER.RU", "ya.ru", "yahoo.co.uk", "yahoo.com", "yandex.by", "yandex.com", "yandex.kz", "yandex.ru", "yandex.ua", "ymail.com",
    "yopmail.com", "aiesec.net", "74.ru", "email.cz", "gmai.com", "gmail.ru", "yndex.ru", "flurred.com", "gmail.comm", "shitmail.me",
    "mail.r", "66.ru", "yhg.biz", "iaoss.com", "megaplan.ru", "icloud.ru", "yomail.info", "phystech.edu", "yandex.ry", "rx24.ru",
    "trbvn.com", "leeching.net", "mos.ru", "post.com", "mail2000.ru", "emltmp.com", "yahoo.es", "hotmail.com", "hotmail.es", "mail.com"
]

export function findFirstInfo(
    contactInfo: Collections.List<ContactInfo | Address>,
    type: ContactInfo.Type
): ContactInfo {
    const info: ContactInfo = contactInfo.find((item: ContactInfo | Address) => {
        return isContactInfo(item) && (item as ContactInfo).type === type
    }) as ContactInfo
    return Some(info).filterNot(void 0).map((item: ContactInfo) => item).getOrElse(void 0)
}

export function findFirstPhone(contactInfo: Collections.List<ContactInfo | Address>): ContactInfo {
    const info = contactInfo.find((item: ContactInfo) => {
        return isContactInfo(item) && ContactInfo.Type.phone === item.type
    }) as ContactInfo
    return Some(info).filterNot(void 0).getOrElse(void 0)
}

export function formatFirstPhone(contactInfo: Collections.List<ContactInfo | Address>): string {
    return Some(findFirstPhone(contactInfo)).filterNot(void 0).map((item: ContactInfo) => {
        return formatPhone(item)
    }).getOrElse("")
}

export function formatPhone(phone: ContactInfo) {
    return phone.value || ""
}

export function isPhone(arg: any): arg is ContactInfo {
    return Boolean(arg) && typeof arg === "object" && arg.type === ContactInfo.Type.phone
}

export function isTelegram(arg: any): arg is ContactInfo {
    return Boolean(arg) && typeof arg === "object" && arg.type === ContactInfo.Type.telegram
}

export function isInstagram(arg: any): arg is ContactInfo {
    return Boolean(arg) && typeof arg === "object" && arg.type === ContactInfo.Type.instagram
}

export function isWhatsapp(arg: any): arg is ContactInfo {
    return Boolean(arg) && typeof arg === "object" && arg.type === ContactInfo.Type.whatsapp
}

export function isEmail(arg: any): arg is ContactInfo {
    return Boolean(arg) && typeof arg === "object" && arg.type === ContactInfo.Type.email
}

export function isSkype(arg: any): arg is ContactInfo {
    return Boolean(arg) && typeof arg === "object" && arg.type === ContactInfo.Type.skype
}

export function isMessenger(arg: any): arg is ContactInfo {
    return Boolean(arg) && typeof arg === "object" && isMessengerType(arg.type)
}

export const messengerTypes = [
    ContactInfo.Type.skype,
    ContactInfo.Type.jabber,
    ContactInfo.Type.icq,
    ContactInfo.Type.telegram,
    ContactInfo.Type.whatsapp,
    ContactInfo.Type.viber,
    ContactInfo.Type.instagram,
]

export function isMessengerType(arg: any): arg is ContactInfo.Type {
    return Boolean(arg) && typeof arg === "string" && messengerTypes.includes(arg as ContactInfo.Type)
}

export function isLink(arg: any): arg is ContactInfo {

    const types = [
        "site",
        "link",
    ]

    return Boolean(arg) && typeof arg === "object" && types.indexOf(arg.type) !== -1
}

// Убираем дубли в контактах
export function uniqueContacts(contacts: Collections.List<ContactInfo | Address>) {

    if (!Collections.isList(contacts)) {
        return []
    }

    const _uniqueContacts = new Map<string, ContactInfo | Address>()
    contacts.forEach(contact => "string" === typeof contact.value && _uniqueContacts.set(contact.value, contact))
    return Array.from(_uniqueContacts.values())
}

/**
 *
 * Возвращает контакты из contacts1 схожие с контактами contacts2
 *
 * @param contacts1
 * @param contacts2
 * @returns {Array<ContactInfo>}
 */
export function findSameContacts(
    targetContacts: Collections.List<Address | ContactInfo>,
    comparisonContacts: Collections.List<Address | ContactInfo>
): Array<ContactInfo> {
    const emailDomenRegExp = /(?!@)([a-z0-9_\.-]+)\.([a-z\.]{2,6})$/
    const sameContacts: Array<ContactInfo> = []

    if (!Collections.isList(targetContacts)) {
        return sameContacts
    }

    targetContacts.forEach(tContact => {
        if (!tContact || !isContactInfo(tContact)) {
            return
        }

        for (let cContact of comparisonContacts) {
            if (!cContact || !isContactInfo(cContact) || tContact.type !== cContact.type) {
                continue
            }

            if (tContact.value === cContact.value) {
                sameContacts.push(tContact)
                break
            } else if (isEmail(tContact) && isEmail(cContact)) {
                const tDomenMatch = tContact.value.match(emailDomenRegExp)
                const cDomenMatch = cContact.value.match(emailDomenRegExp)

                if (!tDomenMatch || !tDomenMatch.length || !cDomenMatch || !cDomenMatch.length) {
                    continue
                }

                const tDomen = tDomenMatch[0] ? tDomenMatch[0] : ""
                const cDomen = cDomenMatch[0] ? cDomenMatch[0] : ""

                if (tDomen && !publicMailDomain.includes(tDomen) && cDomen && (tDomen === cDomen)) {
                    sameContacts.push(tContact)
                    break
                } else if (tContact.value && cContact.value && tContact.value === cContact.value) {
                    sameContacts.push(tContact)
                    break
                }
            }
        }
    })

    return sameContacts
}

/**
 *
 * Возвращает список из общих ИНН плательщиков
 *
 * @param payers1
 * @param payers2
 * @returns {Array<string>}
 */
export function findSameInns(
    payers1: Collections.List<Payer>,
    payers2: Collections.List<Payer>
): Array<string> {
    const sameInns: Array<string> = []

    if (!payers1 || !payers1.length || !payers2 || !payers2.length) {
        return
    }

    for (let payer1 of payers1) {
        if (!payer1 || !payer1.inn) {
            continue
        }

        for (let payer2 of payers2) {
            if (payer2 && (payer2.inn === payer1.inn)) {
                sameInns.push(payer2.inn)
                break
            }
        }
    }

    return sameInns
}

export function getBubbleTypeBySaasStatName(saasStatName: string): BubbleType | void {
    if (saasStatName === "Approvals") {
        return BubbleType.Approvals
    } else if (saasStatName === "Favorites") {
        return BubbleType.Favorites
    } else if (saasStatName === "Burnings") {
        return BubbleType.Burnings
    } else if (saasStatName === "Notifications") {
        return BubbleType.Notifications
    }
}
