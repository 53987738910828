import * as ReduxActions from "redux-actions"
import {CARD_SUBJECT} from "../constants"
import {CardMeta} from "../types"

export const SWITCH_EDITABLE_SECTION = "bums/task/action/SWITCH_EDITABLE_SECTION"

type SwitchEditableSectionAction = ReduxActions.Action<string> & CardMeta

export function switchEditableSection(cardId: string, sectionId: string): SwitchEditableSectionAction {
    return {
        type: SWITCH_EDITABLE_SECTION,
        payload: sectionId,
        meta: {
            cardId,
            subject: CARD_SUBJECT
        }
    }
}

export function isSwitchEditableSectionAction(action: any): action is SwitchEditableSectionAction {
    return "object" === typeof action && SWITCH_EDITABLE_SECTION === action.type
}
