import {computed, observable} from "mobx"
import {autobind} from "core-decorators"
import * as Collections from "src/lib/collections"
import * as Api from "src/lib/entities/api"
import {Intl} from "src/lib/utils/intl"
import {createFormError, FormValidator, isEmpty} from "src/lib/utils/form/validation"
import {HumanExtraFieldsStore} from "src/bums/common/stores/HumanExtraFieldsStore"
import {ContractorHumanTypesStore} from "src/bums/crm/stores/ContractorHumanTypesStore"
import {
    ContactInfoFormCollection,
    getContactInfoType,
    isAddressField,
    isContactInfoField
} from "src/bums/common/form/form/ContactInfoForm"
import {isknownFieldMetaData, KnownMetadata} from "src/lib/components/CFieldValue/utils"
import {AbstractModalForm} from "src/bums/common/modalForm/AbstractModalForm"
import {FileForm, FileStore} from "src/lib/entities/store/FileStore"
import {Form} from "src/lib/utils/form/form"
import {AbstractModalFormCollection} from "src/bums/common/modalForm/AbstractModalFormCollection"
import {isContractorField} from "src/lib/components/CFieldValue/CRefValue/utils"
import {EmployeeFormFactory} from "src/bums/common/form/EmployeeFormFactory"
import {
    ContractorFormFactoryImpl,
    ContractorHumanFormImpl,
    ContractorCompanyFormImpl,
    ContractorHumanFormFactoryImpl
} from "./types"
import {StaticContractorFormFields} from "src/bums/crm/utils"
import {ContractorType, getFieldSettingValue} from "src/lib/entities/api"
import {UserSettingStore} from "src/bums/common/stores/UserSettingStore"
import {prepareExtraFieldDefaultValue} from "src/lib/utils/form/utils";

const libMessages: any = require("src/lib/messages.yml")
const messages: any = require("../messages.yml")

export const contactPersonRenderRequiredFields = [
    Api.ContractorHuman.fields.isPublic.name,
    Api.ContractorHuman.fields.birthday.name,
    Api.ContractorHuman.fields.position.name,
]

export const contactPersonNotRenderFields = [
    Api.ContractorHuman.fields.activityType.name,
    Api.ContractorHuman.fields.preferTransport.name,
    Api.ContractorHuman.fields.advertisingWay.name,
    Api.ContractorHuman.fields.status.name,
    Api.ContractorHuman.fields.partners.name,
    Api.ContractorHuman.fields.competitors.name
]

function humanNameValidator(intl: Intl) {
    return (name: string, contractorHuman: Api.ContractorHuman) => {
        if (isEmpty(contractorHuman.firstName) && isEmpty(contractorHuman.lastName)) {
            return createFormError(intl.formatMessage(libMessages["emptyFieldError"]))
        }
    }
}

@autobind
export class ContractorHumanFormFactory implements ContractorHumanFormFactoryImpl {

    public addressTypes: Api.ListStore<Api.AddressType>

    constructor(
        private $intl: Intl,
        private $apiStore: Api.Store,
        private $userStore: Api.UserStore,
        private $humanExtraFieldsStore: HumanExtraFieldsStore,
        private $contractorHumanTypesStore: ContractorHumanTypesStore,
        private contractorFormFabric: ContractorFormFactoryImpl,
        private employeeFormFactory: EmployeeFormFactory,
    ) {
        this.addressTypes = this.$apiStore.resolveList<Api.AddressType>(
            () => ({ endpoint: `/api/v3/contractor/addressTypes` })
        )
    }

    @computed
    private get contactInfoFields() {
        return this.$humanExtraFieldsStore
            .listStore
            .originalItems
            .filter(field => isContactInfoField(field.name) || isAddressField(field.name))
    }

    @computed
    private get contactInfoEntitiesByTypeMap() {
        return this.contactInfoFields
            .reduce((result, field) => {
                if (isAddressField(field.name)) {
                    return result.set(field.name, {
                        ...Api.Address.newObject,
                        type: this.addressTypes.originalItems.first()
                    })
                }
                if (isContactInfoField(field.name)) {
                    const type = getContactInfoType(field.name)
                    return result.set(type, {...Api.ContactInfo.newObject, type})
                }
                return result
            }, new Map<string, Api.ContactInfo | Api.Address>())
    }

    public get contactInfoTypes() {
        return Array.from(this.contactInfoEntitiesByTypeMap.keys())
    }

    @computed
    private get requiredContactInfoTypes() {
        return this.contactInfoFields
            .filter(field => Api.getFieldSettingValue(field, "card_required", false))
            .map(field => {
                return (isContactInfoField(field.name) ? getContactInfoType(field.name) : field.name) as string
            })
    }

    /**
     * Поля с дефолтными значениями
     * (берутся только для полей с типом Bool и Enum: только их значения пользователь может указывать явно
     */
    private get visibleExtraFields() {
        return this.$humanExtraFieldsStore
            .getFieldsWithSetting("add_form_visibility")
            .filterNot(f => f.name === "textDescription" || isContactInfoField(f.name) || isAddressField(f.name))
            .reduce((result, field) => {
                if (!field) {
                    return result
                }

                result[field.name] = prepareExtraFieldDefaultValue(field)
                return result
            }, {} as { [fieldName: string]: any })
    }

    public getDependencies() {
        return [
            this.addressTypes.whenComplete(),
            this.$humanExtraFieldsStore.listStore.whenComplete(),
            this.$contractorHumanTypesStore.listStore.whenComplete()
        ]
    }

    public async create(
        value: Api.ContractorHuman,
        fixedFields: (keyof Api.ContractorHuman)[] = [],
        customSubmitHandler?: (entity: Api.Contractor) => void
    ) {
        await Promise.all(this.getDependencies())
        return this.createForm(value, fixedFields, customSubmitHandler)
    }

    public createForm(
        value: Api.ContractorHuman,
        fixedFields: (keyof Api.ContractorHuman)[] = [],
        customSubmitHandler?: (entity: Api.Contractor) => void
    ) {
        const formValue = {
            ...this.visibleExtraFields,
            lastName: "",
            firstName: "",
            middleName: "",
            type: this.$contractorHumanTypesStore.listStore.originalItems.first(),
            gender: Api.ContractorHuman.Gender.male,
            company: null,
            position: "",
            attaches: Collections.List<Api.File>(),
            responsibles: Collections.List<Api.User | Api.Group>([this.$userStore.user]),
            description: "",
            contactInfo: Collections.List(this.contactInfoEntitiesByTypeMap.values()),
            ...value,
            contentType: Api.ContractorHuman.contentType
        } as Api.ContractorHuman

        const form = new ContractorHumanForm(
            () => formValue,
            this.$apiStore,
            this.$intl,
            new ContractorHumanValidationFactory(this.$intl),
            this.requiredContactInfoTypes.toArray(),
            Array.from(this.contactInfoEntitiesByTypeMap.keys()),
            this.$humanExtraFieldsStore,
            this.contractorFormFabric,
            this.employeeFormFactory,
        )
        form.setFixedFields(fixedFields)
        if (customSubmitHandler) {
            form.setCustomSubmitHandler(customSubmitHandler)
        }

        return form
    }
}

@autobind
class ContractorHumanValidationFactory {

    constructor(
        private intl: Intl
    ) {}

    private setCommonValidationFields(validator: FormValidator<Api.ContractorHuman>, fields: Collections.List<KnownMetadata>) {
        return fields
            .filterNot(f => isContactInfoField(f.name) || isAddressField(f.name)
                || f.name === Api.ContractorHuman.fields.textDescription.name
            )
            .reduce((result, field) => {
                if (Api.isFloatField(field)) {
                    const {minValue, maxValue} = field
                    result.set(field.name, FormValidator.floatFieldValidator(minValue, maxValue, {
                        emptyError: this.intl.formatMessage(libMessages["emptyFieldError"]),
                        rangeError: this.intl.formatMessage(libMessages["constraintNumberPlaceholder"], {maxValue, minValue})
                    }))
                } else {
                    result.set(field.name, FormValidator.nonEmptyFieldValidator(
                        this.intl.formatMessage(libMessages["emptyFieldError"])
                    ))
                }
                return result
            }, validator)
    }

    private setContractorTypeValidationFields(validator: FormValidator<Api.ContractorHuman>, fields: Collections.List<KnownMetadata>) {
        return fields
            .filter(field => contactPersonRenderRequiredFields.includes(field.name))
            .concat([{...Api.ContractorHuman.fields.company}])
            .reduce((result, field) => {
                result.set(
                    field.name,
                    FormValidator.nonEmptyFieldValidator(this.intl.formatMessage(libMessages["emptyFieldError"]))
                )
                return result
            }, validator)
    }

    public create(contractorType: Api.ContractorType, fields: Collections.List<KnownMetadata>) {
        const validationMap = new FormValidator<Api.ContractorHuman>()
        if (contractorType.type === Api.ContractorType.Type.contact) {
            this.setContractorTypeValidationFields(validationMap, fields)
        } else {
            this.setCommonValidationFields(validationMap, fields)
        }
        validationMap.set(Api.ContractorHuman.fields.firstName.name, humanNameValidator(this.intl))
        validationMap.set(Api.ContractorHuman.fields.lastName.name, humanNameValidator(this.intl))
        return validationMap
    }

}

@autobind
export class ContractorHumanForm extends AbstractModalForm<Api.ContractorHuman> {

    public $fileStore: FileStore

    @observable
    public attaches: FileForm

    @observable
    protected fieldsSettingStore: UserSettingStore<string[]>

    constructor(
        valueFactory: () => Api.ContractorHuman,
        apiStore: Api.Store,
        private intl: Intl,
        private validationFactory: ContractorHumanValidationFactory,
        private $requiredContactInfoTypes: string[],
        public allContactInfoTypes: string[],
        public extraFieldsStore: HumanExtraFieldsStore,
        private contractorFormFabric: ContractorFormFactoryImpl,
        private employeeFormFactory: EmployeeFormFactory,
    ) {
        super(valueFactory, apiStore, null)
        this.$fileStore = new FileStore(apiStore)
        this.attaches = this.form("attaches", (files) =>  new FileForm(() => files, this.$fileStore, this.intl))

        this.extraFieldsStore.listStore.originalItems.forEach(field => {

            if (isContractorField(field)) {
                (this as any)[field.name] = this.form(field.name, contractor => {
                    if (!field.isMultiple) {
                        return this.contractorFormFabric.createFormForContactor(contractor)
                    } else {
                        return new AbstractModalFormCollection<
                            Api.Contractor,
                            ContractorCompanyFormImpl | ContractorHumanFormImpl | Form<Api.Contractor>
                        >(
                            () => contractor,
                            item => this.contractorFormFabric.createFormForContactor(item)
                        )
                    }
                })
            }
        })

        this.fieldsSettingStore = new UserSettingStore<string[]>(
            apiStore,
            () => `contractor_human_${this.get("type") ? this.get("type").type : ""}_card_fields`,
            () => []
        )

    }

    public onContractorTypeChange(newValue: Api.ContractorType) {
        this.set("type", newValue)
    }

    @observable
    public contactInfo = this.form(
        "contactInfo",
        contacts => new ContactInfoFormCollection(() => contacts, this.$requiredContactInfoTypes, this.intl)
    )

    @observable
    public responsibles = this.form(
        Api.ContractorHuman.fields.responsibles.name, (fieldValue) => this.employeeFormFactory.create(fieldValue)
    )

    public getValueForSave(value: Partial<Api.ContractorHuman>) {
        value = super.getValueForSave(value)
        if (value.contactInfo) {
            return {
                ...value,
                contactInfo: value.contactInfo.filter(c => c.value && !isEmpty(c.value))
            }
        }

        return value
    }

    @computed
    public get validator() {
        return this.validationFactory.create(this.get("type"), this.extraFieldsStore.getFieldsWithSetting("card_required"))
    }


    private get staticCardFields() {
        return StaticContractorFormFields(this.intl, true)
    }

    @computed
    public get extraFields(): KnownMetadata[] {
        return this.extraFieldsStore.listStore.originalItems
        // Оставляем известные экстраполя которые отмечены как видимые для карточки создания
            .filter(field =>
                !!field.name
                || (isknownFieldMetaData(field) && getFieldSettingValue(field, "add_form_visibility", false))
            )
            .filter(field => ![
                Api.ContractorHuman.fields.textDescription.name,
                Api.ContractorHuman.fields.type.name,
            ].includes(field.name))
            .filterNot(field => this.get("type").type === Api.ContractorType.Type.contact
                ? contactPersonNotRenderFields.includes(field.name)
                : false
            )
            .sort((field1, field2) => {
                const field1orderPos = field1.orderPos === void 0 ? -Infinity : field1.orderPos
                const field2orderPos = field2.orderPos === void 0 ? -Infinity : field2.orderPos
                return field1orderPos > field2orderPos ? 1
                    : field1orderPos < field2orderPos ? -1
                        : 0
            })
            .toArray() as KnownMetadata[]
    }

    @computed
    public get allFields() {
        return this.staticCardFields.concat(this.extraFields
            .filter(field => {
                return this.get("type").type === ContractorType.Type.contact
                    ? field.isSystem ? !contactPersonNotRenderFields.includes(field.name) : false
                    : true
            })
            .map(field => {
            let hrName = field.hrName
            if (!hrName) {
                if (field.name === "responsibles") {
                    hrName = this.intl.formatMessage(messages[field.name], {num: 1})
                } else if (field.name === "gender") {
                    hrName = this.intl.formatMessage(libMessages[field.name])
                } else if (isContactInfoField(field.name)) {
                    const contactField = this.allContactInfoTypes.find(item => field.name.includes(item))
                    hrName = this.intl.formatMessage(messages[contactField])
                } else {
                    hrName = this.intl.formatMessage(messages[field.name])
                }
            }
            return {
                name: field.name,
                hrName: hrName,
                isNotConfigurable: field.isRequired || this.isRequired(field.name)
            }
        }))
    }

    @computed
    public get selectedFieldsNames(): string[] {
        const setting = this.fieldsSettingStore.get()

        if (setting.state === "fulfilled") {
            let fieldNames = setting.value

            // Если сохранена пустая настройка то выводим все поля, таким образом на всех существующих аккаунтах
            // будут отображаться все поля, а на новых только дефолтные поля
            if (!fieldNames.length) {
                return this.staticCardFields
                    .map(field => field.name)
                    .concat(this.extraFields.filter(field => getFieldSettingValue(field, "add_form_visibility", false))
                        .map(field => field.name))
            }

            // обязательные филды должны быть отображены даже если они не выбраны
            const requiredExtraFieldsNames = this.extraFields
                .filter(field => (field.isRequired || this.isRequired(field.name)) && !fieldNames.includes(field.name))
                .map(field => field.name)

            // убедимся, что в сохраненной настройке будут только поля, доступные в клиенте
            const allFieldNames = this.allFields.map(field => field.name)

            return fieldNames.concat(requiredExtraFieldsNames).filter(value => allFieldNames.includes(value))
        }

        return []
    }

    public async setSelectedFieldsNames(value: string[]) {
        await this.fieldsSettingStore.set(value)
    }

}

export function isContractorHumanForm(form: any): form is ContractorHumanForm {
    return !!form && form.get("contentType") === Api.ContractorHuman.contentType
}
