import {ContractorCompany, ContractorHuman} from "src/lib/entities/api"
import {BaseEntity} from "src/lib/entities/types"
import {BaseListField, ComponentListField} from "src/bums/common/listPage/types"
import {Intl} from "src/lib/utils/intl"
import {FeatureStore} from "src/bums/common/stores/FeatureStore";
import * as Api from "src/lib/entities/api";

const CrmFields = ContractorHuman.fields

const commonMessages: any = require("src/lib/messages.yml")
const messages: any = require("./messages.yml")

export const crmFixedFilterIds = (
    featureStore: FeatureStore,
    filterListStore?: Api.ListStore<Api.CrmFilter>,
): {[id: string]: 0 | 1 | 2} => {
    if (featureStore.isAvailable("bums.crm.new_crm_filters")) {
        return {}
    }

    const ids: {[id: string]: 0 | 1 | 2} = {
        "all": 0 as 0,
        "myClients": 1 as 1,
        "myContacts": 1 as 1,
        "favorites": 1 as 1,
        "deleted": 1 as 1,
    };

    if (featureStore.isAvailable("bums.common.new_system_filters")) {
        ids.favorites = 0
        ids.deleted = 0

        if (filterListStore) {
            const allFilter = filterListStore.originalItems.find(v => v.id === "all")

            if (allFilter && !allFilter.expanded) {
                ids["myClients"] = null
                ids["myContacts"] = null
            }
        }
    } else {
        delete ids.myContacts
    }

    return ids
};

export function AvailableCrmTableFields(intl: Intl, user: Api.User): (BaseListField & ComponentListField<BaseEntity>)[] {
    const dealsFields = Api.hasRights(user, Api.Employee.PossibleActions.deal_enter)
        ? [
            {
                name: "countDeals",
                isSortable: CrmFields["countDeals"].isSortable,
                hrName: intl.formatMessage(messages["countDeals"]),
                isDefault: false,
                width: "8%"
            },
            {
                name: "countActiveDeals",
                isSortable: CrmFields["countActiveDeals"].isSortable,
                hrName: intl.formatMessage(messages["countActiveDeals"]),
                isDefault: false,
                width: "8%"
            },
            {
                name: "countPositiveDeals",
                isSortable: CrmFields["countPositiveDeals"].isSortable,
                hrName: intl.formatMessage(messages["countPositiveDeals"]),
                isDefault: false,
                width: "8%"
            },
            {
                name: "summActiveDeals",
                isSortable: CrmFields["summActiveDeals"].isSortable,
                hrName: intl.formatMessage(messages["summActiveDeals"]),
                isDefault: false,
                width: "8%"
            },
            {
                name: "summDeals",
                isSortable: CrmFields["summDeals"].isSortable,
                hrName: intl.formatMessage(messages["summDeals"]),
                isDefault: false,
                width: "8%"
            },
            {
                name: "summPositiveDeals",
                isSortable: CrmFields["summPositiveDeals"].isSortable,
                hrName: intl.formatMessage(messages["summPositiveDeals"]),
                isDefault: false,
                width: "12%"
            },
            {
                name: "lastDealDate",
                isSortable: CrmFields["lastDealDate"].isSortable,
                hrName: intl.formatMessage(messages["lastDealDate"]),
                isDefault: false,
                width: "115"
            },
        ]
        : []

    const invoicesFields = Api.hasRights(user, Api.Employee.PossibleActions.invoice_enter)
        ? [
            {
                name: "countInvoice",
                isSortable: CrmFields["countInvoice"].isSortable,
                hrName: intl.formatMessage(messages["countInvoice"]),
                isDefault: false,
                width: "8%"
            },
            {
                name: "countNotPaidInvoice",
                isSortable: CrmFields["countNotPaidInvoice"].isSortable,
                hrName: intl.formatMessage(messages["countNotPaidInvoice"]),
                isDefault: false,
                width: "8%"
            },
            {
                name: "summInvoice",
                isSortable: CrmFields["summInvoice"].isSortable,
                hrName: intl.formatMessage(messages["summInvoice"]),
                isDefault: false,
                width: "8%"
            },
            {
                name: "summNotPaidInvoice",
                isSortable: CrmFields["summNotPaidInvoice"].isSortable,
                hrName: intl.formatMessage(messages["summNotPaidInvoice"]),
                isDefault: false,
                width: "8%"
            },
        ]
        : []

    const financeFields = Api.hasRights(user, Api.Employee.PossibleActions.fin_operation)
        ? [
            {
                name: "summAccountReceivable",
                isSortable: CrmFields["summAccountReceivable"].isSortable,
                hrName: intl.formatMessage(messages["summAccountReceivable"]),
                isDefault: false,
                width: "8%"
            },
            {
                name: "summAccountReceivableOverdue",
                isSortable: CrmFields["summAccountReceivableOverdue"].isSortable,
                hrName: intl.formatMessage(messages["summAccountReceivableOverdue"]),
                isDefault: false,
                width: "8%"
            },
            {
                name: "balance",
                isSortable: CrmFields["balance"].isSortable,
                hrName: intl.formatMessage(messages["balance"]),
                isDefault: false,
                width: "8%"
            },
        ]
        : []

    return [
        {
            name: "name",
            isSortable: true,
            hrName: intl.formatMessage(messages["contractorName"]),
            isDefault: true,
            isNotConfigurable: true,
            width: "20%"
        },
        {
            name: "type",
            isSortable: CrmFields["type"].isSortable,
            hrName: intl.formatMessage(messages["contractorTypePlaceholder"]),
            isDefault: true,
            width: "8%"
        },
        {
            name: "position",
            isSortable: CrmFields["position"].isSortable,
            hrName: intl.formatMessage(messages["position"]),
            isDefault: false,
            width: "16%"
        },
        {
            name: "locations",
            isSortable: CrmFields["contactInfo"].isSortable,
            hrName: intl.formatMessage(messages["locations"]),
            isDefault: false,
            width: "20%",
            fields: [
                "contactInfo"
            ]
        },
        {
            name: "totalActiveIssuesCount",
            isSortable: CrmFields["totalActiveIssuesCount"].isSortable,
            hrName: intl.formatMessage(messages["totalActiveIssuesCount"]),
            isDefault: false,
            width: "8%"
        },
        {
            name: "activityType",
            isSortable: CrmFields["activityType"].isSortable,
            hrName: intl.formatMessage(messages["activityType"]),
            isDefault: false,
            width: "16%"
        },
        {
            name: "isPublic",
            isSortable: CrmFields["isPublic"].isSortable,
            hrName: intl.formatMessage(messages["isPublic"]),
            isDefault: false,
            width: "8%"
        },
        {
            name: "allFiles",
            isSortable: CrmFields["allFiles"].isSortable,
            hrName: intl.formatMessage(messages["allFiles"]),
            isDefault: false,
            width: "20%"
        },
        {
            name: "totalIssuesCount",
            isSortable: CrmFields["totalIssuesCount"].isSortable,
            hrName: intl.formatMessage(messages["totalIssuesCount"]),
            isDefault: false,
            width: "8%"
        },
        {
            name: "timeUpdated",
            isSortable: CrmFields["timeUpdated"].isSortable,
            hrName: intl.formatMessage(messages["timeUpdated"]),
            isDefault: false,
            width: "115"
        },
        {
            name: "lastCommunicationDate",
            isSortable: CrmFields["lastCommunicationDate"].isSortable,
            hrName: intl.formatMessage(messages["lastCommunicationDate"]),
            isDefault: true,
            width: "115"
        },
        {
            name: "nextCommunicationDate",
            isSortable: CrmFields["nextCommunicationDate"].isSortable,
            hrName: intl.formatMessage(messages["nextCommunicationDate"]),
            isDefault: false,
            width: "115"
        },
        {
            name: "timeCreated",
            isSortable: CrmFields["timeCreated"].isSortable,
            hrName: intl.formatMessage(messages["timeCreated"]),
            isDefault: false,
            width: "115"
        },
        {
            name: "birthday",
            isSortable: CrmFields["birthday"].isSortable,
            hrName: intl.formatMessage(messages["birthday"]),
            isDefault: false,
            width: "115"
        },
        {
            name: "totalInactiveIssuesCount",
            isSortable: CrmFields["totalInactiveIssuesCount"].isSortable,
            hrName: intl.formatMessage(messages["totalInactiveIssuesCount"]),
            isDefault: false,
            width: "8%"
        },
        {
            name: "advertisingWay",
            isSortable: CrmFields["advertisingWay"].isSortable,
            hrName: intl.formatMessage(messages["advertisingWay"]),
            isDefault: false,
            width: "16%"
        },
        {
            name: "countScheduledCommunications",
            isSortable: CrmFields["countScheduledCommunications"].isSortable,
            hrName: intl.formatMessage(messages["countScheduledCommunications"]),
            isDefault: false,
            width: "8%"
        },
        {
            name: "countCommunications",
            isSortable: CrmFields["countCommunications"].isSortable,
            hrName: intl.formatMessage(messages["countCommunications"]),
            isDefault: false,
            width: "8%"
        },
        {
            name: "competitors",
            isSortable: CrmFields["competitors"].isSortable,
            hrName: intl.formatMessage(messages["competitors"]),
            isDefault: false,
            width: "12%"
        },
        {
            name: "links",
            isSortable: CrmFields["links"].isSortable,
            hrName: intl.formatMessage(messages["links"]),
            isDefault: false,
            width: "12%",
            fields: [
                "contactInfo",
                "links"
            ]
        },
        {
            name: "company",
            isSortable: CrmFields["company"].isSortable,
            hrName: intl.formatMessage(messages["company"]),
            isDefault: false,
            width: "12%"
        },
        {
            name: "textDescription",
            isSortable: CrmFields["description"].isSortable,
            hrName: intl.formatMessage(messages["description"]),
            isDefault: false,
            width: "24%",
            fields: [
                "description"
            ]
        },
        {
            name: "responsibles",
            isSortable: CrmFields["responsibles"].isSortable,
            hrName: intl.formatMessage(messages["responsible"]),
            isDefault: false,
            width: "12%"
        },
        {
            name: "partners",
            isSortable: CrmFields["partners"].isSortable,
            hrName: intl.formatMessage(messages["partners"]),
            isDefault: false,
            width: "12%"
        },
        {
            name: "payers",
            isSortable: CrmFields["payers"].isSortable,
            hrName: intl.formatMessage(messages["payers"]),
            isDefault: false,
            width: "16%"
        },
        {
            name: "gender",
            isSortable: CrmFields["gender"].isSortable,
            hrName: intl.formatMessage(commonMessages["gender"]),
            isDefault: false,
            width: "8%"
        },
        {
            name: "lastComment",
            isSortable: CrmFields["lastComment"].isSortable,
            hrName: intl.formatMessage(messages["lastComment"]),
            isDefault: false,
            width: "24%"
        },
        {
            name: "lastCommentTimeCreated",
            isSortable: CrmFields["lastCommentTimeCreated"].isSortable,
            hrName: intl.formatMessage(messages["lastCommentTimeCreated"]),
            isDefault: false,
            width: "24%"
        },
        {
            name: "email",
            isSortable: CrmFields["contactInfo"].isSortable,
            hrName: intl.formatMessage(messages["email"]),
            isDefault: true,
            width: "12%",
            fields: [
                "contactInfo"
            ]
        },
        {
            name: "preferTransport",
            isSortable: CrmFields["preferTransport"].isSortable,
            hrName: intl.formatMessage(messages["preferTransport"]),
            isDefault: false,
            width: "12%"
        },
        {
            name: "status",
            isSortable: CrmFields["status"].isSortable,
            hrName: intl.formatMessage(messages["status"]),
            isDefault: true,
            width: "8%"
        },
        {
            name: "phones",
            isSortable: CrmFields["contactInfo"].isSortable,
            hrName: intl.formatMessage(messages["phones"]),
            isDefault: true,
            width: "177px",
            fields: [
                "contactInfo"
            ]
        },
        {
            name: "isFavorite",
            isSortable: CrmFields["isFavorite"].isSortable,
            hrName: intl.formatMessage(messages["isFavorite"]),
            isDefault: true,
            width: "50"
        },
        {
            name: "messengers",
            isSortable: CrmFields["contactInfo"].isSortable,
            hrName: intl.formatMessage(messages["messengers"]),
            isDefault: false,
            width: "12%",
            fields: [
                "contactInfo"
            ]
        },
        {
            name: "contacts",
            isSortable: ContractorCompany.fields.contacts.isSortable,
            hrName: intl.formatMessage(messages["contacts"]),
            isDefault: false,
            width: "240"
        },
        {
            name: "tags",
            isSortable: CrmFields["tags"].isSortable,
            hrName: intl.formatMessage(commonMessages["tags"]),
            isDefault: false,
            width: "170"
        },
        {
            name: "humanNumber",
            isSortable: CrmFields["humanNumber"].isSortable,
            hrName: intl.formatMessage(commonMessages["numberSymbol"]),
            isDefault: false,
            width: "80"
        },
    ].concat(
        dealsFields,
        invoicesFields,
        financeFields
    )
}

export function StaticContractorFormFields(intl: Intl, forHumanForm = false): (BaseListField & ComponentListField<BaseEntity>)[] {
    const humanFields =  [
        {
            name: "lastName",
            hrName: intl.formatMessage(commonMessages["lastName"]),
            isNotConfigurable: true
        },
        {
            name: "firstName",
            hrName: intl.formatMessage(commonMessages["firstName"]),
            isNotConfigurable: true
        },
        {
            name: "middleName",
            hrName: intl.formatMessage(commonMessages["middleName"]),
        }
    ]

    const companyFields = [
        {
            name: "companyName",
            hrName: intl.formatMessage(messages["companyName"]),
            isNotConfigurable: true
        }
    ]

    return (forHumanForm ? humanFields : companyFields)
}

export const CONTRACTOR_EVENT_TYPE = "Contractor"
export const OPEN_CONTRACTOR_FORM_EVENT = "Open Contractor form"
export const CONTRACTOR_CARD_EVENT_TYPE = "Contractor Card"
