import {Component} from "src/lib/components"
import * as React from "react"
import classNames from "classnames/bind"
const style = classNames.bind(require("./CFormFieldBorderBottom.styl"));
import {formFieldStatus} from "src/lib/types"

interface CFormFieldBorderBottomProps {
    status?: formFieldStatus
    type?: "solid" | "dotted"
}

/**
 * Нижний бордер у элементов форм
 */
export class CFormFieldBorderBottom extends Component<CFormFieldBorderBottomProps, {}> {
    public render() {
        return <div className={style("CFormFieldBorderBottom", this.props.status, this.props.type)}/>
    }
}
