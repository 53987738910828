import {autobind} from "core-decorators"
import {List} from "src/lib/collections"
import {FormCollection} from "src/lib/utils/form/formCollection"
import {FormContract} from "src/lib/utils/form/types"

@autobind
export class AbstractModalFormCollection<T extends {}, F extends FormContract<T>> extends FormCollection<T, F> {

    public getValueForSave(value: List<Partial<T>>) {
        const valueCopy = List<Partial<T>>(value)
        this.childForms.forEach((child, index) => {
            if (child.form && "getValueForSave" in child.form) {
                valueCopy.set(index, (child.form as any).getValueForSave(value.get(index)))
            }
        })

        return valueCopy
    }
}
