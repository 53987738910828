/* tslint:disable */
import * as H from "../metadataHelper"
import {TodoStatus} from "./todoStatus"
import {Place} from "./place"
import {TodoCategory} from "./todoCategory"
import {Reminder} from "./reminder"
import {Comment} from "./comment"
import {IntervalDates} from "./intervalDates"
import {IntervalTime} from "./intervalTime"
import {ContractorCompany} from "./contractorCompany"
import {ContractorHuman} from "./contractorHuman"
import {Employee} from "./employee"
import {Schedule} from "./schedule"
import {File} from "./file"
import {Participant} from "./participant"
import {CallInfo} from "./callInfo"
import {Deal} from "./deal"
import {Project} from "./project"
import {Task} from "./task"
import {ContactInfo} from "./contactInfo"
import {RelationLink} from "./relationLink"
import {FileGroup} from "./fileGroup"
import {AttachesInfo} from "./attachesInfo"
import {StringField} from "./stringField"
import {IntegerField} from "./integerField"
import {DateTimeField} from "./dateTimeField"
import {BoolField} from "./boolField"
import {EnumField} from "./enumField"

export interface Todo extends H.BaseEntity {
    name?: string; // Название дела
    status?: TodoStatus; // Статус
    place?: Place; // Место проведения события
    category?: TodoCategory; // Категория
    reminders?: H.List<Reminder>; // Массив напоминаний
    remindersCount?: number; // Количество напоминаний
    resultComment?: Comment; // Результирующий комментарий
    timeFinished?: Date; // Время окончания
    isNeedResult?: boolean; // Нужен результат
    when?: IntervalDates|IntervalTime; // Временной интервал события
    responsible?: ContractorCompany|ContractorHuman|Employee; // Ответственный
    isTemplate?: boolean; // Является ли шаблоном для повторяющихся задач
    schedule?: Schedule; // Событие
    description?: string; // Описаиние
    attaches?: H.List<File>; // Вложения
    attachesCount?: number; // Количество вложений
    participants?: H.List<Participant>; // Участники
    participantsCount?: number; // Количество участников
    relations?: H.List<CallInfo<any> | ContractorCompany | ContractorHuman | Deal | Project | Task>; // Массив связанных сущностей
    relationsCount?: number; // Количество связей
    possibleActions?: H.List<Todo.PossibleActions>; // Список возможных действий
    contactInfo?: H.List<ContactInfo>; // Массив с контактной информацией
    contactInfoCount?: number; // Количество элементов в массиве с контактной информацией
    links?: H.List<RelationLink>;
    linksCount?: number;
    coincidentTodos?: H.List<Todo>;
    coincidentTodosCount?: number;
    busyParticipants?: H.List<ContractorCompany | ContractorHuman | Employee>; // Участники дела, которые заняты во время текущего дела
    busyParticipantsCount?: number; // Количество занятых участников дела
    id?: string; // Идентификатор
    isFavorite?: boolean; // Избранная сущность или нет
    unreadCommentsCount?: number; // Количество непрочитанных комментариев
    attachesCountInComments?: number; // Количество вложений в комментариях
    subscribed?: boolean; // Подписан ли текущий пользователь на комментарии по этой сущности
    possibleSubscribers?: H.List<ContractorCompany | ContractorHuman | Employee>; // Пользователи-участники сущности, которые могут получать уведомления по ней.
    possibleSubscribersCount?: number; // Количество возможных подписчиков
    subscribers?: H.List<ContractorCompany | ContractorHuman | Employee>; // Пользователи-участники, которые подписаны на уведомления по сущности.
    subscribersCount?: number; // Количество подписчиков
    comments?: H.List<Comment>; // Массив комментариев
    commentsCount?: number; // Количество комментариев
    hiddenCommentsCount?: number; // Количество скрытых комментариев
    isUnread?: boolean; // Помечен как непрочитанный
    firstUnreadComment?: Comment; // Первый непрочитанный комментарий
    unreadAnswer?: boolean; // Есть ли непрочитанный ответ или упоминание
    allFiles?: H.List<File | FileGroup>; // Массив файлов
    allFilesCount?: number; // Количество файлов
    attachesInfo?: AttachesInfo; // Информация о вложении
    videocallParticipants?: H.List<ContractorHuman | Employee>; // Участники видеозвонка
    videocallParticipantsCount?: number; // Количество участников видеозвонка
    contentType: "Todo"; // Object type
}

export module Todo {
    export const contentType: "Todo" = "Todo";
    export const endpoint = "/api/v3/todo";
    export type PossibleActions = "act_accept_invite"|"act_cancel"|"act_delete"|"act_edit"|"act_finish"|"act_give"|"act_read"|"act_reject_invite"|"act_renew"|"act_take";
    export module PossibleActions {
        export const act_accept_invite: PossibleActions = "act_accept_invite";
        export const act_cancel: PossibleActions = "act_cancel";
        export const act_delete: PossibleActions = "act_delete";
        export const act_edit: PossibleActions = "act_edit";
        export const act_finish: PossibleActions = "act_finish";
        export const act_give: PossibleActions = "act_give";
        export const act_read: PossibleActions = "act_read";
        export const act_reject_invite: PossibleActions = "act_reject_invite";
        export const act_renew: PossibleActions = "act_renew";
        export const act_take: PossibleActions = "act_take";
    }
    export const newObject: Todo = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "Todo.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const status = {contentType: "RefLinkField" as "RefLinkField", name: "status", isMultiple: false, isSortable: false, id: "Todo.status", refContentType: H.List(["TodoStatus"])};
        export const place = {contentType: "RefLinkField" as "RefLinkField", name: "place", isMultiple: false, isSortable: false, id: "Todo.place", refContentType: H.List(["Place"])};
        export const category = {contentType: "RefLinkField" as "RefLinkField", name: "category", isMultiple: false, isSortable: false, id: "Todo.category", refContentType: H.List(["TodoCategory"])};
        export const reminders = {contentType: "RefLinkField" as "RefLinkField", name: "reminders", isMultiple: true, isSortable: false, id: "Todo.reminders", refContentType: H.List(["Reminder"])};
        export const remindersCount = {contentType: "IntegerField" as "IntegerField", name: "remindersCount", isMultiple: false, isSortable: false, id: "Todo.remindersCount", defaultValue: null as IntegerField["defaultValue"]};
        export const resultComment = {contentType: "RefLinkField" as "RefLinkField", name: "resultComment", isMultiple: false, isSortable: false, id: "Todo.resultComment", refContentType: H.List(["Comment"])};
        export const timeFinished = {contentType: "DateTimeField" as "DateTimeField", name: "timeFinished", isMultiple: false, isSortable: false, id: "Todo.timeFinished", defaultValue: null as DateTimeField["defaultValue"]};
        export const isNeedResult = {contentType: "BoolField" as "BoolField", name: "isNeedResult", isMultiple: false, isSortable: false, id: "Todo.isNeedResult", defaultValue: null as BoolField["defaultValue"]};
        export const when = {contentType: "RefLinkField" as "RefLinkField", name: "when", isMultiple: false, isSortable: false, id: "Todo.when", refContentType: H.List(["IntervalDates", "IntervalTime"])};
        export const responsible = {contentType: "RefLinkField" as "RefLinkField", name: "responsible", isMultiple: false, isSortable: false, id: "Todo.responsible", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee"])};
        export const isTemplate = {contentType: "BoolField" as "BoolField", name: "isTemplate", isMultiple: false, isSortable: false, id: "Todo.isTemplate", defaultValue: null as BoolField["defaultValue"]};
        export const schedule = {contentType: "RefLinkField" as "RefLinkField", name: "schedule", isMultiple: false, isSortable: false, id: "Todo.schedule", refContentType: H.List(["Schedule"])};
        export const description = {contentType: "StringField" as "StringField", name: "description", isMultiple: false, isSortable: false, id: "Todo.description", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const attaches = {contentType: "RefLinkField" as "RefLinkField", name: "attaches", isMultiple: true, isSortable: false, id: "Todo.attaches", refContentType: H.List(["File"])};
        export const attachesCount = {contentType: "IntegerField" as "IntegerField", name: "attachesCount", isMultiple: false, isSortable: false, id: "Todo.attachesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const participants = {contentType: "RefLinkField" as "RefLinkField", name: "participants", isMultiple: true, isSortable: false, id: "Todo.participants", refContentType: H.List(["Participant"])};
        export const participantsCount = {contentType: "IntegerField" as "IntegerField", name: "participantsCount", isMultiple: false, isSortable: false, id: "Todo.participantsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const relations = {contentType: "RefLinkField" as "RefLinkField", name: "relations", isMultiple: true, isSortable: false, id: "Todo.relations", refContentType: H.List(["CallInfo", "ContractorCompany", "ContractorHuman", "Deal", "Project", "Task"])};
        export const relationsCount = {contentType: "IntegerField" as "IntegerField", name: "relationsCount", isMultiple: false, isSortable: false, id: "Todo.relationsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const possibleActions = {contentType: "EnumField" as "EnumField", name: "possibleActions", isMultiple: true, isSortable: false, id: "Todo.possibleActions", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["act_accept_invite", "act_cancel", "act_delete", "act_edit", "act_finish", "act_give", "act_read", "act_reject_invite", "act_renew", "act_take"])};
        export const contactInfo = {contentType: "RefLinkField" as "RefLinkField", name: "contactInfo", isMultiple: true, isSortable: false, id: "Todo.contactInfo", refContentType: H.List(["ContactInfo"])};
        export const contactInfoCount = {contentType: "IntegerField" as "IntegerField", name: "contactInfoCount", isMultiple: false, isSortable: false, id: "Todo.contactInfoCount", defaultValue: null as IntegerField["defaultValue"]};
        export const links = {contentType: "RefLinkField" as "RefLinkField", name: "links", isMultiple: true, isSortable: false, id: "Todo.links", refContentType: H.List(["RelationLink"])};
        export const linksCount = {contentType: "IntegerField" as "IntegerField", name: "linksCount", isMultiple: false, isSortable: false, id: "Todo.linksCount", defaultValue: null as IntegerField["defaultValue"]};
        export const coincidentTodos = {contentType: "RefLinkField" as "RefLinkField", name: "coincidentTodos", isMultiple: true, isSortable: false, id: "Todo.coincidentTodos", refContentType: H.List(["Todo"])};
        export const coincidentTodosCount = {contentType: "IntegerField" as "IntegerField", name: "coincidentTodosCount", isMultiple: false, isSortable: false, id: "Todo.coincidentTodosCount", defaultValue: null as IntegerField["defaultValue"]};
        export const busyParticipants = {contentType: "RefLinkField" as "RefLinkField", name: "busyParticipants", isMultiple: true, isSortable: false, id: "Todo.busyParticipants", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee"])};
        export const busyParticipantsCount = {contentType: "IntegerField" as "IntegerField", name: "busyParticipantsCount", isMultiple: false, isSortable: false, id: "Todo.busyParticipantsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const isFavorite = {contentType: "BoolField" as "BoolField", name: "isFavorite", isMultiple: false, isSortable: false, id: "Todo.isFavorite", defaultValue: null as BoolField["defaultValue"]};
        export const unreadCommentsCount = {contentType: "IntegerField" as "IntegerField", name: "unreadCommentsCount", isMultiple: false, isSortable: false, id: "Todo.unreadCommentsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const attachesCountInComments = {contentType: "IntegerField" as "IntegerField", name: "attachesCountInComments", isMultiple: false, isSortable: false, id: "Todo.attachesCountInComments", defaultValue: null as IntegerField["defaultValue"]};
        export const subscribed = {contentType: "BoolField" as "BoolField", name: "subscribed", isMultiple: false, isSortable: false, id: "Todo.subscribed", defaultValue: null as BoolField["defaultValue"]};
        export const possibleSubscribers = {contentType: "RefLinkField" as "RefLinkField", name: "possibleSubscribers", isMultiple: true, isSortable: false, id: "Todo.possibleSubscribers", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee"])};
        export const possibleSubscribersCount = {contentType: "IntegerField" as "IntegerField", name: "possibleSubscribersCount", isMultiple: false, isSortable: false, id: "Todo.possibleSubscribersCount", defaultValue: null as IntegerField["defaultValue"]};
        export const subscribers = {contentType: "RefLinkField" as "RefLinkField", name: "subscribers", isMultiple: true, isSortable: false, id: "Todo.subscribers", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee"])};
        export const subscribersCount = {contentType: "IntegerField" as "IntegerField", name: "subscribersCount", isMultiple: false, isSortable: false, id: "Todo.subscribersCount", defaultValue: null as IntegerField["defaultValue"]};
        export const comments = {contentType: "RefLinkField" as "RefLinkField", name: "comments", isMultiple: true, isSortable: false, id: "Todo.comments", refContentType: H.List(["Comment"])};
        export const commentsCount = {contentType: "IntegerField" as "IntegerField", name: "commentsCount", isMultiple: false, isSortable: false, id: "Todo.commentsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const hiddenCommentsCount = {contentType: "IntegerField" as "IntegerField", name: "hiddenCommentsCount", isMultiple: false, isSortable: false, id: "Todo.hiddenCommentsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const isUnread = {contentType: "BoolField" as "BoolField", name: "isUnread", isMultiple: false, isSortable: false, id: "Todo.isUnread", defaultValue: null as BoolField["defaultValue"]};
        export const firstUnreadComment = {contentType: "RefLinkField" as "RefLinkField", name: "firstUnreadComment", isMultiple: false, isSortable: false, id: "Todo.firstUnreadComment", refContentType: H.List(["Comment"])};
        export const unreadAnswer = {contentType: "BoolField" as "BoolField", name: "unreadAnswer", isMultiple: false, isSortable: false, id: "Todo.unreadAnswer", defaultValue: null as BoolField["defaultValue"]};
        export const allFiles = {contentType: "RefLinkField" as "RefLinkField", name: "allFiles", isMultiple: true, isSortable: false, id: "Todo.allFiles", refContentType: H.List(["File", "FileGroup"])};
        export const allFilesCount = {contentType: "IntegerField" as "IntegerField", name: "allFilesCount", isMultiple: false, isSortable: false, id: "Todo.allFilesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const attachesInfo = {contentType: "RefLinkField" as "RefLinkField", name: "attachesInfo", isMultiple: false, isSortable: false, id: "Todo.attachesInfo", refContentType: H.List(["AttachesInfo"])};
        export const videocallParticipants = {contentType: "RefLinkField" as "RefLinkField", name: "videocallParticipants", isMultiple: true, isSortable: false, id: "Todo.videocallParticipants", refContentType: H.List(["ContractorHuman", "Employee"])};
        export const videocallParticipantsCount = {contentType: "IntegerField" as "IntegerField", name: "videocallParticipantsCount", isMultiple: false, isSortable: false, id: "Todo.videocallParticipantsCount", defaultValue: null as IntegerField["defaultValue"]};
    }
}

export function isTodo(arg: any): arg is Todo {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Todo"
}
