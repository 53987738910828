import * as Api from "src/lib/entities/api"
import * as Collections from "src/lib/collections"
import {levenshtein} from "src/lib/utils/text"
import {Intl} from "src/lib/utils/intl/Intl"
import {KeyedValue} from "src/lib/types"
import {translateCategoryMasterType, translateStatusMasterType} from "src/bums/todo/todoHelper"

export type SearchIndexEntities = Map<string, Map<string, Set<string>>>

/* tslint:disable:no-cyrillic-in-string-literals */
const manySpaceExp = /\s+/g
const cyrillicExp = /ё/g
const allowSymbolsExp = /[^\s\wа-яё]+/g
/* tslint:enable:no-cyrillic-in-string-literals */
const NOT_FOUND_WEIGHT = 1000
const MAX_WEIGHT = 2
const MIN_SEARCH_LENGTH = 3

export const DEFAULT_ENDPOINT = "/api/v3/autocomplete"
export const FULL_LOAD_COLLECTIONS = [Api.Employee.contentType, Api.Group.contentType] as string[]
export const FULL_LOAD_TYPE_MAP = {[Api.Employee.contentType]: true, [Api.Group.contentType]: true}
export const FULL_LOAD_LIST_NAME = "autocomplete/fullLoad"

function getOptiomalMaxWeight(length: number) {
    if (length < 3) {
        return 0
    } else if (length < 4) {
        return 1
    } else {
        return MAX_WEIGHT
    }
}

function getNameAliases(name: string, intl: Intl): string[] {
    const normalizedName = name.toLowerCase()
    intl.getNameAliases().forEach((symbols: string[]) => normalizedName.replace(symbols[0], symbols[1]))
    const foundAliases = intl.getNameAliases().find((aliases: string[]) => aliases.indexOf(normalizedName) >= 0)
    return foundAliases ? foundAliases : []
}

function getUserAliases(entity: Api.BaseEntity, currentUser: Api.User, intl: Intl): string[] {
    if (Api.isEntityEquals(entity, currentUser)) {
        return intl.getMeAliases()
    }
    return []
}

const SEARCH_INDEX_ENTITIES_MAP: {
    [index: string]: (entity: Api.BaseEntity, currentUser: Api.User, intl: Intl) => (string | void)[]
} = {
    [Api.Employee.contentType]: (entity: Api.Employee, currentUser: Api.User, intl: Intl) => [
        entity.lastName,
        entity.firstName,
        ...(entity.firstName ? getNameAliases(entity.firstName, intl) : []),
        entity.middleName,
        entity.position,
    ],
    [Api.ContractorHuman.contentType]: (entity: Api.ContractorHuman, currentUser: Api.User, intl: Intl) => [
        entity.lastName,
        entity.firstName,
        ...(entity.firstName ? getNameAliases(entity.firstName, intl) : []),
        entity.middleName,
        entity.position
    ],
    [Api.TodoCategory.contentType]: (entity: Api.TodoCategory, currentUser: Api.User, intl: Intl) => {
        if (entity.masterType) {
            return [entity.name,  translateCategoryMasterType(intl, entity.masterType)]
        }
        return [entity.name]
    },
    [Api.TodoStatus.contentType]: (entity: Api.TodoStatus, currentUser: Api.User, intl: Intl) => {
        if (entity.masterType) {
            return [entity.name,  translateStatusMasterType(intl, entity.masterType)]
        }
        return [entity.name]
    },
    [Api.Doc.contentType]: (entity: Api.Doc, currentUser: Api.User, intl: Intl) => {
        if (entity.actualVersion) {
            return [entity.actualVersion.name]
        }
        return []
    },
    [Api.Deal.contentType]: (entity: Api.Deal, currentUser: Api.User, intl: Intl) => {
        const data = [entity.number, entity.name]
        if (entity.contractor) {
            data.push(entity.contractor.name)
        }
        if (entity.manager) {
            data.push(entity.manager.name)
        }
        if (entity.state) {
            data.push(entity.state.name)
        }
        return data
    },
    ExtraField: (entity: Api.ExtraField, currentUser: Api.User, intl: Intl) => [entity.hrName, entity.name],
    FilterEntity: (entity: Api.FilterEntity, currentUser: Api.User, intl: Intl) => [entity.title],
    [Api.RelationLink.contentType]: (entity: Api.RelationLink, currentUser: Api.User, intl: Intl) => [entity.representation],
    [Api.SelectableArrayVariant.contentType]: (entity: Api.SelectableArrayVariant, currentUser: Api.User, intl: Intl) => [entity.value],
    [Api.Offer.contentType]: (entity: Api.Offer, currentUser: Api.User, intl: Intl) => [entity.name, entity.article],
}

const SORT_INDEX_ENTITIES_MAP: {
    [index: string]: (entity: Api.BaseEntity, currentUser: Api.User, intl: Intl) => (string | void)[]
} = {
    [Api.Employee.contentType]: (entity: Api.Employee, currentUser: Api.User, intl: Intl) => [
        ...getUserAliases(entity, currentUser, intl),
        entity.lastName,
        entity.firstName,
        entity.middleName,
    ],
    [Api.ContractorHuman.contentType]: (entity: Api.ContractorHuman, currentUser: Api.User, intl: Intl) =>
        [entity.lastName, entity.firstName, entity.middleName],
    [Api.TodoCategory.contentType]: (entity: Api.TodoCategory, currentUser: Api.User, intl: Intl) => {
        if (entity.masterType) {
            return [entity.name,  translateCategoryMasterType(intl, entity.masterType)]
        }
        return [entity.name]
    },
    [Api.TodoStatus.contentType]: (entity: Api.TodoStatus, currentUser: Api.User, intl: Intl) => {
        if (entity.masterType) {
            return [entity.name,  translateStatusMasterType(intl, entity.masterType)]
        }
        return [entity.name]
    },
    [Api.Doc.contentType]: (entity: Api.Doc, currentUser: Api.User, intl: Intl) => {
        if (entity.actualVersion) {
            return [entity.actualVersion.name]
        }
        return []
    },
    [Api.Offer.contentType]: (entity: Api.Offer, currentUser: Api.User, intl: Intl) => [entity.name],
    ExtraField: (entity: Api.ExtraField, currentUser: Api.User, intl: Intl) => [entity.hrName],
    FilterEntity: (entity: Api.FilterEntity, currentUser: Api.User, intl: Intl) => [entity.title],
    [Api.SelectableArrayVariant.contentType]: (entity: Api.SelectableArrayVariant, currentUser: Api.User, intl: Intl) => [entity.value],
    [Api.Deal.contentType]: (entity: Api.Deal, currentUser: Api.User, intl: Intl) => {
        const data = [entity.number, entity.name]
        if (entity.contractor) {
            data.push(entity.contractor.name)
        }
        if (entity.manager) {
            data.push(entity.manager.name)
        }
        if (entity.state) {
            data.push(entity.state.name)
        }
        return data
    },
    [Api.Offer.contentType]: (entity: Api.Offer, currentUser: Api.User, intl: Intl) => [entity.name],
}

const SORT_CONTENT_TYPE_PRIORITY: {[index: string]: number} = {
    [Api.Employee.contentType]: 1,
    [Api.ContractorHuman.contentType]: 2,
    [Api.ContractorCompany.contentType]: 3,
    [Api.Group.contentType]: 4,
    [Api.Project.contentType]: 1,
    [Api.Task.contentType]: 2,
}

export function createSearchIndex<T>(value: T, currentUser: Api.User, intl: Intl) {
    const chunks: (string | void)[] = []
    if (Api.isBaseEntity(value)) {
        chunks.push(value.id)
    }
    if (Api.isBaseValue(value)) {
        if (SEARCH_INDEX_ENTITIES_MAP[value.contentType]) {
            chunks.push(...SEARCH_INDEX_ENTITIES_MAP[value.contentType](value, currentUser, intl))
        } else if (Api.isExtraField(value)) {
            chunks.push(...SEARCH_INDEX_ENTITIES_MAP["ExtraField"](value, currentUser, intl))
        } else if (Api.isFilterEntity(value)) {
            chunks.push(...SEARCH_INDEX_ENTITIES_MAP["FilterEntity"](value, currentUser, intl))
        } else if (Api.isNamedEntity(value)) {
            chunks.push(value.name)
        }

        return chunks.filter(item => !!item).join(" ")
    } else {
        return String(value)
    }
}

export function createSortIndex<T>(value: T, currentUser: Api.User, intl: Intl, indexes: Map<{}, string>) {
    if (indexes.has(value)) {
        return indexes.get(value)
    }
    let result: string = ""
    if (Api.isBaseValue(value)) {
        const chunks: (string | void)[] = []
        if (SORT_INDEX_ENTITIES_MAP[value.contentType]) {
            chunks.push(...SORT_INDEX_ENTITIES_MAP[value.contentType](value, currentUser, intl))
        } else if (Api.isExtraField(value)) {
            chunks.push(...SORT_INDEX_ENTITIES_MAP["ExtraField"](value, currentUser, intl))
        } else if (Api.isFilterEntity(value)) {
            chunks.push(...SORT_INDEX_ENTITIES_MAP["FilterEntity"](value, currentUser, intl))
        } else if (Api.isNamedEntity(value)) {
            chunks.push(value.name)
        }
        result = chunks.filter(item => !!item).join(" ")
    } else {
        result = String(value)
    }
    result = result.toLowerCase()
    indexes.set(value, result)
    return result;
}

/**
 *  Базовое создание ключей индекс
 */
export function createSearchIndexKeys(value: string) {
    /* tslint:disable:no-cyrillic-in-string-literals */
    return value.toLowerCase()
        .trim()
        .replace(manySpaceExp, " ")
        .replace(allowSymbolsExp, "")
        .replace(cyrillicExp, "е")
        .split(" ")
    /* tslint:enable:no-cyrillic-in-string-literals */
}

/**
 * Базовая сортировка по значению
 */
export function sortBySortIndex<V, V2>(
    a: [KeyedValue<V, V2>, number],
    b: [KeyedValue<V, V2>, number],
    currentUser: Api.User,
    intl: Intl,
    indexes: Map<{}, string>
) {
    const sortA = createSortIndex(a[0].value, currentUser, intl, indexes)
    const sortB = createSortIndex(b[0].value, currentUser, intl, indexes)
    return sortA.localeCompare(sortB, void 0, {numeric: true})
}

export function sortByPriority<V, V2>(
    a: [KeyedValue<V, V2>, number],
    b: [KeyedValue<V, V2>, number],
    currentUser: Api.User,
    intl: Intl,
    indexes: Map<{}, string>
) {
    const valueA = a[0].value
    const valueB = b[0].value
    const priorityA = (Api.isBaseEntity(valueA) ? SORT_CONTENT_TYPE_PRIORITY[valueA.contentType] : 10) || 10
    const priorityB = (Api.isBaseEntity(valueB) ? SORT_CONTENT_TYPE_PRIORITY[valueB.contentType] : 10) || 10
    return priorityA < priorityB ? -1 : (priorityA > priorityB ? 1 : sortBySortIndex<V, V2>(a, b, currentUser, intl, indexes))
}

/**
 * Двойная сортировка. Сначала по релевантности совпадения, затем по базовому значению
 */
export function sortByWeight<V, V2>(
    currentUser: Api.User,
    intl: Intl,
    indexes: Map<{}, string>,
    a: [KeyedValue<V, V2>, number],
    b: [KeyedValue<V, V2>, number]
) {
    return a[1] < b[1] ? -1 : (a[1] > b[1] ? 1 : sortByPriority<V, V2>(a, b, currentUser, intl, indexes))
}

/**
 * Расчет веса релевантности совпадения двух строк
 */
export function calcWeight(indexKey: string, searchKey: string): boolean | number {
    const searchKeyLength = searchKey.length
    const indexKeyLength = indexKey.length
    const searchKeyIsNumber = !Number.isNaN(Number(searchKey))
    const indexKeyIsNumber = !Number.isNaN(Number(indexKey))
    const searchKeyIndexPosition = indexKey.indexOf(searchKey)

    /**
    const sIn = searchKey.indexOf(indexKey)
    const iIn = indexKey.indexOf(searchKey)
    const abs = (Math.abs(searchKeyLength - indexKeyLength) > MAX_WEIGHT)
     */

    // искомое - слово, индекс - число
    if (!searchKeyIsNumber && indexKeyIsNumber) {
        return false
    }

    // искомое или индекс - число
    if (searchKeyIsNumber || indexKeyIsNumber) {
        // вхождение искомого в индекс
        if (searchKeyIndexPosition !== -1) {
            return searchKeyIndexPosition + Math.abs(searchKeyLength - indexKeyLength)
        }

        return false
    }

    // точное соответствие искомого и индекса
    if (searchKey.localeCompare(indexKey) === 0) {
        return -MAX_WEIGHT
    }

    // вхождение искомого в индекса
    if (searchKeyIndexPosition !== -1) {
        return searchKeyIndexPosition
    }

    // искомое меньше резрешенной минимальной длины
    if (searchKeyLength < MIN_SEARCH_LENGTH) {
        return false
    }

    let weight = levenshtein(indexKey, searchKey)

    // расстояние Левенштейна больше допустимого - false, иначе +100 к весу для конкретизации весов точного и неточного поиска
    return weight > getOptiomalMaxWeight(searchKeyLength) ? false : weight + 100
}

/**
 * Поиск в наборе данных
 */
export function searchInDataSet<V, V2>(search: string, data: Collections.List<KeyedValue<V, V2>>, currentUser: Api.User, intl: Intl) {
    const searchKeys = createSearchIndexKeys(search)

    if (searchKeys.length === 1 && searchKeys[0] === "") {
        return data.map<[KeyedValue<V, V2>, number]>(value => [value, 0])
    }

    const weightMap = new Map<KeyedValue<V, V2>, number>()


    data.forEach(value => {
        const meAliases = Api.isBaseEntity(value.value) ? getUserAliases(value.value, currentUser, intl) : []

        // Me aliases have exclusive priority
        if (meAliases.indexOf(search) >= 0) {
            weightMap.set(value, -MAX_WEIGHT)
        } else {
            const indexKeys = createSearchIndexKeys(createSearchIndex(value.value, currentUser, intl))
            for (let searchKey of searchKeys) {
                let minWeight = NOT_FOUND_WEIGHT
                let removeKeyIndex: number | void = void 0
                indexKeys.forEach((indexKey, index) => {
                    let weight = calcWeight(indexKey, searchKey)

                    if (weight === false) {
                        return
                    }

                    weight = weight as number + (index * 10)

                    if (weight < minWeight) {
                        removeKeyIndex = index
                        minWeight = weight
                    }
                })

                if (removeKeyIndex !== void 0) {
                    indexKeys.splice(removeKeyIndex as number, 1)
                }

                // если не нашли слово, то остальные слова не имеет смысла искать
                if (minWeight === NOT_FOUND_WEIGHT) {
                    if (weightMap.has(value)) {
                        weightMap.delete(value)
                    }
                    return
                } else {
                    const lastWeight = weightMap.has(value) ? (weightMap.get(value) || 0) : 0
                    weightMap.set(value, lastWeight + minWeight)
                }
            }
        }
    })

    return Collections.List(Array.from(weightMap.entries()))
}

/**
 * Создание условий поискового запроса
 */
export function createFilterOptions(types: string[], filter: string, limit?: number): Api.RequestOptions {
    return {
        filters: {
            contentTypes: types,
            q: filter || void 0,
        },
        q: filter || void 0,
        limit,
    }
}
