import * as ReduxActions from "redux-actions"
import {CARD_SUBJECT} from "../constants"
import {CardMeta} from "../types"

export const SWITCH_EDITMODE = "src/lib/CCardContainer/action/SWITCH_EDITMODE"

export type SwitchEditModeAction = ReduxActions.Action<boolean> & CardMeta

export function switchEditMode(cardId: string, editMode: boolean): SwitchEditModeAction {
    return {
        type: SWITCH_EDITMODE,
        payload: editMode,
        meta: {
            cardId,
            subject: CARD_SUBJECT
        }
    }
}

export function isSwitchEditModeAction(action: any): action is SwitchEditModeAction {
    return "object" === typeof action && SWITCH_EDITMODE === action.type
}

