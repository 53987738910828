import * as React from "react"
import {observer} from "mobx-react"
import {AbstractDragNDropStore} from "src/bums/common/globalDrag/AbstractDragNDropStore"
import {WindowFileDropStore} from "src/bums/common/globalDrag/WindowFileDropStore"
import {Component, CWindowEvent} from "src/lib/components"
import {inject} from "src/lib/utils/inject"

export namespace CGlobalDragContainer {
   export interface Props {}
}

// Документ обрабатывает файлы и пытается их открыть в текущем окне. And this is UNACCEPTABLE!
function preventDocumentHandle(e: DragEvent) {
    e.preventDefault()
}

@observer
export class CGlobalDragContainer extends Component<CGlobalDragContainer.Props, {}> {

    @inject(WindowFileDropStore)
    private globalDropStore: WindowFileDropStore

    public componentDidMount() {
        document.addEventListener("dragover", preventDocumentHandle)
        document.addEventListener("drop", preventDocumentHandle)
    }

    public componentWillUnmount() {
        document.addEventListener("dragover", preventDocumentHandle)
        document.addEventListener("drop", preventDocumentHandle)
    }

    public render() {
        return <React.Fragment>
            <CWindowEvent
                key={AbstractDragNDropStore.dragleave}
                event={AbstractDragNDropStore.dragleave}
                handler={this.globalDropStore.windowListener}
            />
            <CWindowEvent
                key={AbstractDragNDropStore.dragenter}
                event={AbstractDragNDropStore.dragenter}
                handler={this.globalDropStore.windowListener}
            />
            <CWindowEvent
                key={AbstractDragNDropStore.drop}
                event={AbstractDragNDropStore.drop}
                handler={this.globalDropStore.reset}
            />
        </React.Fragment>
    }
}
