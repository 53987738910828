import * as React from "react"
import classNames from "classnames/bind"
import {autobind} from "core-decorators"
import {observer} from "mobx-react"
import {CIcon, CButton, CModal, CSmartCard, Component, CLink} from "src/lib/components"
import {FormattedMessage} from "src/lib/utils/intl/components"
import {Intl} from "src/lib/utils/intl/Intl"
import {inject} from "src/lib/utils/inject"
import * as Api from "src/lib/entities/api"
import {UserSettingStore} from "src/bums/common/stores/UserSettingStore"
import {CSwitcher} from "src/bums/common/switcher/components/CSwitcher"
import {action, observable} from "mobx"
import {InformerStore} from "src/bums/common/informerPanel/informerStore"
import {BubbleType} from "src/bums/common/informerPanel/types"
import {bindArg} from "src/lib/utils/func"
import {FeatureStore} from "src/bums/common/stores/FeatureStore"

const style = classNames.bind(require("./theme/CNewInterfaceWarningModal.styl"))
const messages: any = require("./messages.yml")

declare const window: any

@observer
@autobind
export class CNewInterfaceWarningModal extends Component<{}, {}> {

    @inject(Intl)
    private intl: Intl

    @inject(Api.Store)
    private apiStore: Api.Store

    @inject(InformerStore)
    private informerStore: InformerStore

    @inject(FeatureStore)
    private featureStore: FeatureStore

    private isOpen: boolean = false

    // Настройки попапа
    private hrModuleName: string
    private settingName: string
    private video: string
    private timestamp: number
    private daysLeft: number

    @observable
    private loaded: boolean = false

    // Сеттинг
    private warningModalSettingStore: UserSettingStore<boolean>
    private interfaceSwitcherStore: UserSettingStore<CSwitcher.SettingShape>

    private modules: {[moduleName: string]: {setting: string, forceSetting: boolean, video: string}} = {
        "tasks": {
            setting: "task_module_enablement_date",
            forceSetting: this.featureStore.isAvailable("bums.force_switch_task_list_to_react"),
            video: "https://megaplan.ru/redirect/task_module_features.php"
        },
        "clients": {
            setting: "crm_module_enablement_date",
            forceSetting: this.featureStore.isAvailable("bums.force_switch_crm_list_to_react"),
            video: "https://megaplan.ru/redirect/crm_module_features.php"
        },
        "deals": {
            setting: "deal_module_enablement_date",
            forceSetting: this.featureStore.isAvailable("bums.force_switch_trade_list_to_react"),
            video: "https://megaplan.ru/redirect/deal_module_features.php"
        },
    }

    componentWillMount() {
        this.interfaceSwitcherStore = new UserSettingStore<CSwitcher.SettingShape>(
            this.apiStore,
            () => "bums.common.new_interface_enabled"
        )
        void this.init()
    }

    @action
    setLoaded(val: boolean) {
        this.loaded = val
    }

    private async init () {
        const interfaceSwitcherValue = this.interfaceSwitcherStore.get()
        await interfaceSwitcherValue.promise

        if (interfaceSwitcherValue.state === "fulfilled") {
            for (let module in this.modules) {
                const moduleData = this.modules[module]

                if (!window[moduleData.setting]) {
                    continue
                }

                const todayMidnight = new Date()
                todayMidnight.setHours(0, 0, 0, 0)
                const daysUntilModuleEnable = Math.max(todayMidnight.getDaysBetween(new Date(window[moduleData.setting] * 1000)), 0)

                // основной случай, когда до даты переключения меньше 7 дней
                if (daysUntilModuleEnable < 8 && daysUntilModuleEnable > 0
                    && (!interfaceSwitcherValue.value || interfaceSwitcherValue.value.switchState !== true)) {
                    this.hrModuleName = this.intl.formatMessage(messages[module as string])
                    this.settingName = moduleData.setting
                    this.timestamp = window[moduleData.setting]
                    this.video = moduleData.video
                    this.daysLeft = daysUntilModuleEnable

                    this.warningModalSettingStore = new UserSettingStore<boolean>(
                        this.apiStore,
                        () => this.settingName + this.daysLeft
                    )

                    this.setLoaded(true)

                    break
                } else if (daysUntilModuleEnable === 0 && moduleData.forceSetting) {
                    // второй случай, когда интерфейс уже переключен
                    // тут обязательно нужно смотреть, был ли до этого закрыт попап
                    this.warningModalSettingStore = new UserSettingStore<boolean>(
                        this.apiStore,
                        () => moduleData.setting + daysUntilModuleEnable
                    )

                    const warningModalSetting = this.warningModalSettingStore.get()
                    await warningModalSetting.promise

                    if (warningModalSetting.state === "fulfilled") {

                        if (warningModalSetting.value !== true) {
                            this.hrModuleName = this.intl.formatMessage(messages[module as string])
                            this.settingName = moduleData.setting
                            this.timestamp = window[moduleData.setting]
                            this.video = moduleData.video
                            this.daysLeft = daysUntilModuleEnable
                            this.setLoaded(true)

                            break
                        }
                    }
                }
            }
        }
    }

    static isEnabled(timestamp: number) {
        return timestamp > 0 && timestamp < Date.now() / 1000
    }

    @action
    private close() {
        this.isOpen = false
        void this.warningModalSettingStore.set(true)
    }

    @action
    private async tryNewInterface() {
        this.close()

        const interfaceSwitcherValue = this.interfaceSwitcherStore.get()
        await interfaceSwitcherValue.promise

        if (interfaceSwitcherValue.state === "fulfilled") {
            let currentValue = Object.assign({}, interfaceSwitcherValue.value)
            currentValue.switchState = true
            await this.interfaceSwitcherStore.set(currentValue)
        }

        window.location.reload()
    }

    @action
    private writeFeedback() {
        this.close()
        this.informerStore.setOpenedBubble(BubbleType.Feedback)
    }

    @action
    private showNewPossibilities(url: string) {
        this.close()
        window.open(url, "_blank")
    }

    render() {
        if (!this.loaded) {
            return null
        }

        const warningModalSetting = this.warningModalSettingStore.get()

        if (warningModalSetting.state === "fulfilled") {
            if (warningModalSetting.value !== true) {
                this.isOpen = true
            }
        }

        return <CModal open={this.isOpen} onClose={this.close} closeOnEsc={true}>
            <CSmartCard className={style("card")}>
                <div className={style("modalBackground")}>
                    <CSmartCard.Content>
                        <div className={style("textContainer")}>
                            <div className={style("heading")}>
                                <FormattedMessage {...messages["title"]} />
                            </div>
                            {this.daysLeft > 0 && <div>
                                    <div className={style("messageText")}>
                                        <FormattedMessage {...messages["weAreFinishing"]} values={{"module": this.hrModuleName}}/>
                                    </div>
                                    < div className={style("messageText")}>
                                        <FormattedMessage
                                            {...messages["untilEnable"]}
                                                values={{
                                                date: this.intl.date.MMMMd()(new Date(this.timestamp * 1000)),
                                                left: this.intl.formatMessage(messages["left"], {count: this.daysLeft}),
                                                daysCount: <strong>{this.intl.unit.dayLong()(this.daysLeft)}</strong>
                                            }}
                                        />
                                    </div>
                                    <div className={style("messageText")}>
                                        <CLink onClick={this.tryNewInterface}><FormattedMessage {...messages["enableNow"]} /></CLink>
                                    </div>
                                </div>
                            }
                            {this.daysLeft <= 0 && <div>
                                    <div className={style("messageText")}>
                                        <FormattedMessage {...messages["weHaveFinished"]} values={{"module": this.hrModuleName}}/>
                                    </div>
                                    <div className={style("messageText")}>
                                        <CLink onClick={this.writeFeedback}><FormattedMessage {...messages["feedbackMessage"]} /></CLink>
                                    </div>
                                </div>
                            }
                            <CIcon
                                type={CIcon.Type.CANCEL}
                                className={style("closeIcon")}
                                onClick={this.close}
                            />
                        </div>
                    </CSmartCard.Content>
                    <CSmartCard.Footer className={style("footer")}>
                        <CButton
                            onClick={bindArg(this.showNewPossibilities, this.video)}
                            name="changePlan"
                            caption={<FormattedMessage {...messages["glancePossibilities"]} />}
                            type={CButton.Type.SUCCESS}
                        />
                    </CSmartCard.Footer>
                </div>
            </CSmartCard>
        </CModal>
    }
}
