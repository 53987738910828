/* tslint:disable */
import * as H from "../metadataHelper"
import {StringField} from "./stringField"

export interface Department extends H.BaseEntity {
    name?: string;
    id?: string; // Идентификатор
    contentType: "Department"; // Object type
}

export module Department {
    export const contentType: "Department" = "Department";
    export const endpoint = "/api/v3/department";

    export const newObject: Department = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "Department.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
    }
}

export function isDepartment(arg: any): arg is Department {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Department"
}
