// all polyfills, shims and normalizers should be required here
require("normalize.css/normalize.css")
require("./lib/components/theme/normalize.styl")
require("./lib/components/theme/fonts.styl")
require("./lib/components/theme/rubfonts.styl")
require("./lib/components/theme/typography.styl")
require("./lib/components/theme/animation.styl")
require("./lib/components/theme/themes.styl")

/**
 * This file is connection point between old js code and new application. It exports to global namespace (window)
 * MegaplanSPA classes.
 */

import Kernel from "./Kernel"
import {lazy} from "src/lib/utils/lazy"

import BumsCommonInformerPanel from "./bums/common/informerPanel/index"
import BumsReportCard from "./bums/report/card/index"
import BumsReportEdit from "./bums/report/edit/index"
import BumsTradeFunnel from "./bums/trade/funnel/index"
import BumsFunnelPromo from "./bums/trade/promo/index"
import BumsProgramSettingForm from "./bums/trade/list/page/index"
import TryEffectivenessFeature from "./bums/employee/index"
import BpmSettingSwitch from "./bums/trade/settings/index"
import BumsReportPromo from "./bums/report/list/index"
import BumsSwitcher from "./bums/common/switcher/index"
import MegaplanRouter from "./MegaplanRouter"
import BumsCommonLogin from "./bums/common/login/index"
import convertMsoHtml from "src/lib/components/CEditor/utils/mso-paste-converter"
import {MainTab} from "src/bums/common/browserTabs/MainTab"
import {BrowserTabFactory} from "src/bums/common/browserTabs"
import {BrowserEcomet} from "src/lib/services/BrowserEcomet"
import {runMigrations} from "src/bums/common/migrate"

const CMenuContainer = lazy(async () => (await import("./bums/common/CMainMenu/CMenuContainer")).CMenuContainer)
const CGlobalInputSearch = lazy(async () => (await import("./bums/common/informerPanel/components/CGlobalInputSearch")).CGlobalInputSearch)
const CRegistrationByInviteLinkForm = lazy(async () =>
    (await import("src/bums/employee/form/CRegistrationByInviteLinkForm")).CRegistrationByInviteLinkForm
)

runMigrations()

// Грузим трекинг памяти, если включена фича
window.MemoryUsageTracker = {}
if (window.memory_track) {
    void import("./lib/tracking/MemoryUsageTracker")
}

declare let window: any;

const browserTab = new BrowserTabFactory(new MainTab())

const MegaplanSPA = {
    BrowserTabFactoryInstance: browserTab,
    Ecomet: new BrowserEcomet(browserTab),
    Kernel,
    MegaplanRouter: {
        component: MegaplanRouter
    },
    BumsCommonInformerPanel,
    CGlobalInputSearch: {
        component: CGlobalInputSearch
    },
    BumsReportCard,
    BumsReportEdit,
    BumsTradeFunnel,
    BumsFunnelPromo,
    BumsReportPromo,
    BumsSwitcher,
    BumsCommonMainMenu: {
        component: CMenuContainer
    },
    BumsCommonLogin,
    BumsProgramSettingForm,
    convertMsoHtml,
    TryEffectivenessFeature,
    BpmSettingSwitch,
    PublicInviteLinkForm: {
        component: CRegistrationByInviteLinkForm,
    },
}

declare global {
    interface Window {
        MegaplanSPA: typeof MegaplanSPA
        bt_mode: boolean
        /**
         * Флаг, который говорит о том, что `beforeunload` событие вызвалось из-за клика по mailto:
         *
         * @see src/sdf/common/v/html/xhtml-with-sdf-settings.phtml
         */
        fakeBeforeUnload?: boolean
    }
}

window.MegaplanSPA = MegaplanSPA

if (process.env.NODE_ENV === "production") {
    /* tslint:disable:no-console */
    /* tslint:disable:no-cyrillic-in-string-literals */
    console.log(
        "%c Хочешь знать, как всё устроено? https://hh.ru/employer/581294",
        [
            "color:white",
            "font-weight: bold",
            "font-size: 18pt",
            "text-shadow: " + ([
                "2px 0 0 blue",
                "-2px 0 0 blue",
                "0 2px 0 blue",
                "0 -2px 0 blue",
                "1px 1px blue",
                "-1px -1px 0 blue",
                "1px -1px 0 blue",
                "-1px 1px 0 blue",
            ].join(",")),
        ].join(";"),
    )
    /* tslint:enable:no-console */
    /* tslint:enable:no-cyrillic-in-string-literals */
}
