import * as Api from "src/lib/entities/api"
import {inject} from "src/lib/utils/inject"


export class TaskFilterListStore {

    private $filterListStore: Api.ListStore<Api.TaskFilter>

    constructor(
        @inject(Api.Store) private $apiStore: Api.Store
    ) {
        this.$filterListStore = this.$apiStore.resolveList<Api.TaskFilter>(() => ({
            endpoint: `/api/v3/taskFilter`
        }))
    }

    public get filters() {
        return this.$filterListStore.originalItems
    }
}
