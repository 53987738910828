import {observer} from "mobx-react"
import {inject} from "src/lib/utils/inject"
import {CSpinner, Component} from "src/lib/components"
import * as React from "react"
import * as Api from "src/lib/entities/api"
import {EntityRendererFn} from "./types"
import classNames from "classnames/bind"
import * as ReactDOM from "react-dom"
import {bindArg, makeRefHandler} from "src/lib/utils/func"
import * as Collections from "src/lib/collections"
import CPaginatedVirtualScroll from "../CPaginatedVirtualScroll/CPaginatedVirtualScroll"
import {isEqual, noop} from "lodash"
import {CWindowEvent} from "../CHotKey/CWindowEvent"

const style = classNames.bind(require("./CList.styl"))

interface CListProps {
    endpoint: string,
    options?: Api.RequestOptions,
    softOptions?: Api.RequestOptions,
    listName: string,
    flip?: boolean,
    entityRenderer: EntityRendererFn
    listPreProcessor?: (list: Api.LinksList) => Api.LinksList
    renderEmpty?: () => JSX.Element|JSX.Element[]
    onError?: (e?: Error) => void
    className?: string
    clearOnUnmount?: boolean
    customScroll?: boolean
    customScrollProps?: any
    onEntitiesLoaded?: () => void
}


/**
 * Данная функция должна быть чистой, поэтому я её вынес из-за класса, чтобы не было
 * соблазнов обращаться к this
 */
function rowRenderer(
    flip: boolean,
    items: Api.LinksList,
    entityRenderer: EntityRendererFn,
    index: number
): JSX.Element|JSX.Element[] {
    if (!items || index >= items.length) {
        return;
    }
    const targetIndex = flip ? items.length - 1 - index : index
    const previousIndex = flip ? targetIndex + 1 : (targetIndex - 1 < 0 ? null : targetIndex - 1)
    const nextIndex = flip ? (targetIndex - 1 < 0 ? null : targetIndex - 1) : targetIndex + 1
    const entity = items.get(targetIndex)
    const prev = items.get(previousIndex, null)
    const next = items.get(nextIndex, null)
    const element = entityRenderer(entity, prev, next)

    if (!element) {
        return
    } else if (Array.isArray(element)) {
        return element.map((el: JSX.Element) => {
            return React.cloneElement(el)
        })
    } else {
        return React.cloneElement(
            element,
            {key: entity ? Api.getGID(entity) : ""}
        )
    }
}

@observer
export default class CList extends Component<CListProps, {}> {

    static defaultProps: any = {
        clearOnUnmount: true,
        onError: noop,
        customScroll: false,
        onEntitiesLoaded: noop
    }

    //noinspection TypeScriptUnresolvedVariable
    public innerVirtualScroll: CPaginatedVirtualScroll

    /*
     * Свойства для блокирования скрола
     */
    private preventScroll: boolean = false

    @inject(Api.Store)
    private apiStore: Api.Store

    private get list() {
        return this.apiStore.getList(this.props.listName)
    }

    private get items() {
        let items = this.list.items
        if (this.props.listPreProcessor) {
            items = this.props.listPreProcessor(items)
        }
        return items;
    }

    /**
     * bindArg не может переиспользвоать забинженные результаты функции, если они биндятся не на
     * объекты. Кешируем самостоятельно.
     */
    private cachedFlipRowRenderer: (
        items: Collections.List<Api.BaseEntity>,
        entityRenderer: EntityRendererFn,
        index: number
    ) => JSX.Element|JSX.Element[]

    public scrollToBottom = () => {
        if (!this.innerVirtualScroll) {
            return
        }
        const node = ReactDOM.findDOMNode(this.innerVirtualScroll.virtualScrollNode)
        // scroll to bottom
        if (node instanceof Element) {
            node.scrollTop = node.scrollHeight
        }
    }

    private cancelScrollEvent = function (event?: Event) {
        event.stopImmediatePropagation()
        event.preventDefault()
        event.returnValue = false
    }

    private handleWindowScroll: (event: any) => void = (event: any) => {
        if (!this.preventScroll) {
            return void 0
        }

        const element = ReactDOM.findDOMNode(this.innerVirtualScroll)
        if (!(element instanceof Element)) {
            return void 0
        }
        const scrollTop = element.scrollTop
        const scrollHeight = element.scrollHeight
        const height = element.clientHeight
        const wheelDelta = event.deltaY
        const isDeltaPositive = wheelDelta > 0

        // Не блокируем дефолтный скрол, если в списке нет скрола
        if (element.scrollHeight === element.clientHeight) {
            return void 0
        }

        if (isDeltaPositive && wheelDelta > scrollHeight - height - scrollTop) {
            element.scrollTop = scrollHeight
            return this.cancelScrollEvent(event)
        } else if (!isDeltaPositive && -wheelDelta > scrollTop) {
            element.scrollTop = 0
            return this.cancelScrollEvent(event)
        }
    }

    private onMouseEnter = ()  => {
        this.preventScroll = true
    }

    private onMouseLeave = ()  => {
        this.preventScroll = false
    }

    public componentDidMount(): void {
        if (
            this.list.loadStateNext.isNone() ||
            (void 0 !== this.list.loadedEndpoint && this.list.loadedEndpoint !== this.props.endpoint) ||
            !isEqual(this.list.loadedWithOptions, this.props.options)
        ) {
            this.loadMoreRows().then(() => {
                if (this.props.flip) {
                    this.scrollToBottom()
                }
            })
        }
    }

    public componentWillUnmount(): void {
        if (this.props.clearOnUnmount) {
            this.apiStore.removeList(this.props.listName);
        }
    }

    public loadMoreRows = () => {
        return this.loadRows(this.props)
    }

    private loadRows(props: CListProps) {
        return this.apiStore.fetchList(
            props.listName,
            props.endpoint,
            props.options,
            props.softOptions
        ).then(this.props.onEntitiesLoaded).catch(this.props.onError)
    }

    componentWillUpdate(nextProps: CListProps): void {
        if (this.props.flip !== nextProps.flip) {
            this.cachedFlipRowRenderer = null
        }
        if (nextProps.clearOnUnmount && nextProps.listName !== this.props.listName) {
            this.apiStore.removeList(this.props.listName)
        }
        if (
            this.props.listName !== nextProps.listName ||
            this.props.endpoint !== nextProps.endpoint ||
            !isEqual(this.props.options, nextProps.options) ||
            !isEqual(this.props.softOptions, nextProps.softOptions)
        ) {
            this.loadRows(nextProps)
        }
    }

    /**
     * Биндим функцию на аргументы.
     * @returns
     */
    private getRowRenderer = () => {
        if (!this.cachedFlipRowRenderer) {
            this.cachedFlipRowRenderer = bindArg(rowRenderer, this.props.flip)
        }
        return bindArg(this.cachedFlipRowRenderer, this.items, this.props.entityRenderer)
    }

    public render() {
        return <CPaginatedVirtualScroll
            customScrollProps={this.props.customScrollProps}
            className={this.props.className}
            ref={makeRefHandler(this, "innerVirtualScroll")}
            rowsCount={this.items.length}
            rowRenderer={this.getRowRenderer()}
            renderEmpty={this.props.renderEmpty}
            pagination={{
                hasMore: this.list.hasMoreNext,
                isLoading: this.list.loadStateNext.isPending(),
                loadTriggerOnUpwards: this.props.flip,
                loaderRowElement: (
                    <div className={style("spinnerContainer")} key={"loader"}>
                        <CSpinner />
                    </div>
                ),
                doLoad: this.loadMoreRows,
            }}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
            customScroll={this.props.customScroll}
        >
            <CWindowEvent event="wheel" handler={this.handleWindowScroll}/>
        </CPaginatedVirtualScroll>
    }
}
