import {MenuItem} from "src/lib/entities/bums"
import {MainMenuStore} from "src/bums/common/CMainMenu/MainMenuStore"

export const CONTEXT_HELP_EVENT_TYPE = "Context help" // блок событий контекстного хэлпа
export const CLICK_ABOUT_EVENT = "Click About" // клик на "Подробнее" в контекстном хэлпе
export const EXPAND_EVENT = "Expand Context Help" // развернуть контекстный хэлп
export const CLOSE_EXPANDED_EVENT = "Close Expanded Context Help" // закрыть развернутый контекстный хэлп
export const CLOSE_EVENT = "Close Mini Context Help" // закрыть маленький контекстный хэлп
export const LINK_CLICK_EVENT = "Link Click" // клик на ссылку из контекстного хэлпа
export const MENU_STATE_EVENT = "Menu State" // свернуть/развернуть главное меню
export const EDIT_MENU_EVENT = "Edit Menu" // редактирование главного меню и логотипа
export const OPEN_PROFILE_EVENT = "Open Profile" // действия с профилем (под аватаркой)
export const PROFILE_ITEM_EVENT = "Profile Item" // клик по элементу меню под аватаркой
export const LOGIN_LOGOUT_EVENT_TYPE = "Login-logout" // блок счетчиков вход/выход
export const MENU_EVENT_TYPE = "Menu" // блок счетчиков для меню
export const CHANGE_LOGO_ACTION = "Change Logo" //изменение логотипа
export const DELETE_LOGO_ACTION = "Delete Logo" //удаление логотипа
export const MOVE_MENU_ITEM_ACTION = "Move" //перемещение пункта меню
export const CHANGE_HOME_MODULE_ACTION = "Change Home Module" //изменение модуля по умолчанию
const GO_TO_SUBMENU_EVENT = "Go To Submenu" // переход в пункт подменю модуля
const GO_TO_MODULE_EVENT = "Go To Module" // переход в модуль

export function getEventName(entity: MenuItem, itemType?: "profile" | "fastButton" | "submenu") {
    const eventType = MENU_EVENT_TYPE
    const channels = ["product", "amplitude"]
    if (itemType === "profile") {
        return {eventType, event: PROFILE_ITEM_EVENT, channels}
    }
    return {eventType, event: entity.parent ? GO_TO_SUBMENU_EVENT : GO_TO_MODULE_EVENT, channels}
}

export function prepareDataForModulesTracking(entity: MenuItem, itemType?: "profile" | "fastButton" | "submenu") {
    let params = {}
    const transitionType = "Menu click"
    if (itemType === "profile") {
        params = {itemName: entity.id}
    } else {
        if (!entity.parent) {
            const customModuleName = entity.isCustom ? entity.title : ""
            const module = entity.isCustom ? "customModule" : entity.id
            if (entity.isCustom) {
                params = {module, transitionType, customModuleName}
            } else {
                params = {module, transitionType}
            }
        } else {
            const module = entity.isCustom ? "customModule" : entity.parent.id
            const customModuleName = entity.isCustom ? entity.parent.title : ""
            params = {module, subItemName: entity.title, transitionType, customModuleName}
        }
    }
    return {params}
}

export function prepareDataForMenuStateTracking(store: MainMenuStore) {
    const state = store.isMenuCollapsed ? "Expand" : "Collapse"
    return {params: {menuState: state}}
}

export function prepareDataForEditMenuTracking(args: any[]) {
    let editModeValue: boolean
    [editModeValue] = [...args]
    return {event: EDIT_MENU_EVENT, params: {action: editModeValue ? "Open" : "Close"}}
}

export function prepareDataForToggleShowItemTracking(entity: MenuItem) {
    return {event: EDIT_MENU_EVENT, params: {action: entity.isEnabled ? "SwitchOn" : "SwitchOff", moduleName: entity.title}}
}
