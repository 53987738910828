/* tslint:disable */
import * as H from "../metadataHelper"
import {DateOnly} from "./dateOnly"
import {DateField} from "./dateField"
import {IntegerField} from "./integerField"
import {EnumField} from "./enumField"
import {BoolField} from "./boolField"

export interface Schedule extends H.BaseEntity {
    startDate?: DateOnly|Date; // Дата начала
    endDate?: DateOnly; // Дата окончания
    repetitions?: number; // Количество повторений
    interval?: Schedule.Interval; // Временной интервал
    each?: number; // Каждый период
    workDaysOnly?: boolean; // Только по рабочим дням
    weekDays?: H.List<number>; // Будние дни
    week?: number; // Номер недели
    hour?: number; // Часы
    minute?: number; // Минуты
    month?: number; // Номер месяца
    day?: number; // Номер дня
    id?: string; // Идентификатор
    contentType: "Schedule"; // Object type
}

export module Schedule {
    export const contentType: "Schedule" = "Schedule";
    export const endpoint = "/api/v3/schedule";
    export type Interval = "day"|"month"|"week"|"year";
    export module Interval {
        export const day: Interval = "day";
        export const month: Interval = "month";
        export const week: Interval = "week";
        export const year: Interval = "year";
    }
    export const newObject: Schedule = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const startDate = {contentType: "RefLinkField" as "RefLinkField", name: "startDate", isMultiple: false, isSortable: false, id: "Schedule.startDate", refContentType: H.List(["DateOnly", "DateTime"])};
        export const endDate = {contentType: "DateField" as "DateField", name: "endDate", isMultiple: false, isSortable: false, id: "Schedule.endDate", defaultValue: null as DateField["defaultValue"], refContentType: H.List(["DateOnly"])};
        export const repetitions = {contentType: "IntegerField" as "IntegerField", name: "repetitions", isMultiple: false, isSortable: false, id: "Schedule.repetitions", defaultValue: null as IntegerField["defaultValue"]};
        export const interval = {contentType: "EnumField" as "EnumField", name: "interval", isMultiple: false, isSortable: false, id: "Schedule.interval", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["day", "month", "week", "year"])};
        export const each = {contentType: "IntegerField" as "IntegerField", name: "each", isMultiple: false, isSortable: false, id: "Schedule.each", defaultValue: null as IntegerField["defaultValue"]};
        export const workDaysOnly = {contentType: "BoolField" as "BoolField", name: "workDaysOnly", isMultiple: false, isSortable: false, id: "Schedule.workDaysOnly", defaultValue: null as BoolField["defaultValue"]};
        export const weekDays = {contentType: "IntegerField" as "IntegerField", name: "weekDays", isMultiple: true, isSortable: false, id: "Schedule.weekDays", defaultValue: null as IntegerField["defaultValue"]};
        export const week = {contentType: "IntegerField" as "IntegerField", name: "week", isMultiple: false, isSortable: false, id: "Schedule.week", defaultValue: null as IntegerField["defaultValue"]};
        export const hour = {contentType: "IntegerField" as "IntegerField", name: "hour", isMultiple: false, isSortable: false, id: "Schedule.hour", defaultValue: null as IntegerField["defaultValue"]};
        export const minute = {contentType: "IntegerField" as "IntegerField", name: "minute", isMultiple: false, isSortable: false, id: "Schedule.minute", defaultValue: null as IntegerField["defaultValue"]};
        export const month = {contentType: "IntegerField" as "IntegerField", name: "month", isMultiple: false, isSortable: false, id: "Schedule.month", defaultValue: null as IntegerField["defaultValue"]};
        export const day = {contentType: "IntegerField" as "IntegerField", name: "day", isMultiple: false, isSortable: false, id: "Schedule.day", defaultValue: null as IntegerField["defaultValue"]};
    }
}

export function isSchedule(arg: any): arg is Schedule {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Schedule"
}
