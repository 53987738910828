import * as React from "react"
import {observer} from "mobx-react"
import {autobind} from "core-decorators"
import {noop} from "lodash"
import {CButton, Component, CSticker} from "src/lib/components"
import {CRatingStepProps} from "src/bums/common/promo/CRating/types"
import {CRatingBlock} from "src/bums/common/promo/components/CRatingBlock/CRatingBlock"
import {FormattedMessage, FormattedMessageHtml} from "src/lib/utils/intl"
import {RatingStore} from "src/bums/common/promo/stores/RatingStore"

export namespace CThankYouStep {
}

const messages = require("../messages.yml")

@observer
@autobind
export class CThankYouStep extends Component<CRatingStepProps, {}> {

    public static condition(store: RatingStore) {
        return store.isVoted && store.rating === 5
    }

    public render() {
        const {onClose = noop} = this.props

        return <React.Fragment>
            <CRatingBlock
                image={<CSticker stickerName={CSticker.Name.WomanOK} />}
                title={<FormattedMessage {...messages["thanksForAnswerTitle"]} />}
                explanation={<FormattedMessageHtml {...messages["weLoveYouExplanationHtml"]} />}
            />
            <CButton
                name={"understand"}
                type={CButton.Type.FLAT_SUCCESS}
                caption={<FormattedMessage {...messages["understand"]} />}
                onClick={onClose}
            />
        </React.Fragment>
    }
}
