import {Component} from "src/lib/components"
import * as React from "react"
import {observer} from "mobx-react";
import classNames from "classnames/bind"
const style = classNames.bind(require("./CDisguisedValue.styl"))

/**
 * Лоадинг в блоках
 */

interface CDisguisedValueProps {
    className?: string
}

@observer
export class CDisguisedValue extends Component<CDisguisedValueProps, {}> {
    render() {
        //  data-loading="true" для браузерных тестов
        return <div className={style("disguised", this.props.className)}  data-loading="true">
            <div className={style("line")}/>
            <div className={style("line")}/>
        </div>
    }
}
