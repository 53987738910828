/* tslint:disable */
import * as H from "../metadataHelper"
import {IntegerField} from "./integerField"

export interface ImageFileMetadata extends H.BaseValue {
    width?: number;
    height?: number;
    contentType: "ImageFileMetadata"; // Object type
}

export module ImageFileMetadata {
    export const contentType: "ImageFileMetadata" = "ImageFileMetadata";
    export const endpoint = "/api/v3/imageFileMetadata";

    export const newObject: ImageFileMetadata = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const width = {contentType: "IntegerField" as "IntegerField", name: "width", isMultiple: false, isSortable: false, id: "ImageFileMetadata.width", defaultValue: null as IntegerField["defaultValue"]};
        export const height = {contentType: "IntegerField" as "IntegerField", name: "height", isMultiple: false, isSortable: false, id: "ImageFileMetadata.height", defaultValue: null as IntegerField["defaultValue"]};
    }
}

export function isImageFileMetadata(arg: any): arg is ImageFileMetadata {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "ImageFileMetadata"
}
