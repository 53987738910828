import * as React from "react"
import {autobind} from "core-decorators"
import {observable, action} from "mobx"
import {observer} from "mobx-react"
import classNames from "classnames/bind"
import * as Collections from "src/lib/collections"
import {bindArg, makeRefHandler} from "src/lib/utils/func"
import {scrollToNodeInContainerIfNeeded} from "src/lib/utils/window"
import {CSelectOption, CCustomScrollbars, CPopoverMenu, Component} from "src/lib/components"

const style: any = classNames.bind(require("./CDropDown.styl"))

const KeyboardEnterKey = "Enter"
const KeyboardArrowUpKey = "ArrowUp"
const KeyboardArrowDownKey = "ArrowDown"
const KeyboardArrowKeys = [KeyboardArrowUpKey, KeyboardArrowDownKey]
const KeyboardKeys = KeyboardArrowKeys.concat(KeyboardEnterKey)

export namespace CDropDown {
    export interface Props<T> {
        items: Collections.List<T>
        appendElement?: JSX.Element
        prependElement?: JSX.Element
        onSelect?: (index?: number) => void
        className?: string
        customComponent?: React.ComponentClass<CSelectOption.Props<T>> | React.StatelessComponent<CSelectOption.Props<T>>
        fieldName: string
        width?: number
        initialHoverIndex?: number
        initialScrollValue?: number
    }
}

@observer
@autobind
export class CDropDown<T> extends Component<CDropDown.Props<T>, {}> {

    @observable
    private hoverIndex = 0

    public wrapperRef: CCustomScrollbars

    public componentDidMount() {
        document.addEventListener("keydown", this.keyDownHandler, true)
        if (this.props.initialHoverIndex) {
            this.setHoverIndex(this.props.initialHoverIndex)
        }
        if (this.props.initialScrollValue) {
            const scrollViewElement = this.wrapperRef.scrollView as HTMLElement
            scrollViewElement.scrollTop = this.props.initialScrollValue
        }
    }

    public componentWillUnmount() {
        document.removeEventListener("keydown", this.keyDownHandler, true)
    }

    @action
    componentWillReceiveProps(nextProps: CDropDown.Props<T>) {
        if (nextProps.items.length !== this.props.items.length || !nextProps.items.has(this.hoverIndex)) {
            this.hoverIndex = 0
        }
    }

    @action
    private setHoverIndex(index: number) {
        this.hoverIndex = index
    }

    private keyDownHandler(event: KeyboardEvent) {
        const {key} = event
        const {items} = this.props

        if (KeyboardKeys.includes(key)) {
            event.preventDefault()
            event.stopPropagation()
        }

        if (KeyboardArrowKeys.includes(key)) {
            const nextHoverIndex = key === KeyboardArrowUpKey
                ? Math.max(0, this.hoverIndex - 1)
                : Math.min(items.length - 1, this.hoverIndex + 1)

            if (this.hoverIndex !== nextHoverIndex) {
                this.setHoverIndex(nextHoverIndex)
                const scrollViewElement = this.wrapperRef.scrollView as HTMLElement
                scrollToNodeInContainerIfNeeded(scrollViewElement, scrollViewElement.childNodes[nextHoverIndex] as HTMLElement)
            }
        } else if (key === KeyboardEnterKey) {
            this.props.onSelect(this.hoverIndex)
            if (this.hoverIndex === items.length - 1) {
                this.setHoverIndex(this.hoverIndex - 1)
            }
        }
    }

    public render() {
        const {customComponent, width} = this.props

        const customScrollProps = {
            className: style("wrapper", this.props.className),
            "data-autocomplete-options": this.props.fieldName,
            autoHeight: true,
            autoHeightMax: 300,
            style: width !== void 0 ? {width: width, minWidth: width, maxWidth: width} : void 0
        }

        return <CCustomScrollbars customScrollProps={customScrollProps} ref={makeRefHandler(this, "wrapperRef")}>
            <CPopoverMenu.WrapperGroup>
                {this.props.prependElement}
                {this.props.items.map((value, index) => {
                    const props = {
                        key: `option-${index}`,
                        value: value,
                        isHovered: this.hoverIndex === index,
                        onSelect: bindArg(this.props.onSelect, index),
                        onHovered: bindArg(this.setHoverIndex, index),
                    }
                    if (customComponent) {
                        return React.createElement(customComponent, props)
                    } else {
                        return CSelectOption.create(props)
                    }
                })}
                {this.props.appendElement}
            </CPopoverMenu.WrapperGroup>
        </CCustomScrollbars>
    }
}

export namespace CDropDown {
    export function create<T>(props: Props<T>) {
        return <CDropDown {...props} />
    }
}
