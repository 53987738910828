import {computed} from "mobx"
import {createTransformer} from "mobx-utils"
import * as Api from "src/lib/entities/api"
import {inject} from "src/lib/utils/inject"
import * as Collections from "src/lib/collections"

export class FeatureStore {

    private $listStore: Api.ListStore<Api.Feature>

    constructor(
        @inject(Api.Store) private $apiStore: Api.Store,
        @inject(Api.UserStore) private $userStore: Api.UserStore,
    ) {}

    public async initialize() {
        this.$listStore = this.$apiStore.resolveList<Api.Feature>(() => ({
            endpoint: Api.Feature.endpoint
        }))
        await this.$listStore.whenComplete()
    }

    @computed
    public get isCompleted() {
        return this.$listStore.loadStateNext.isCompleted() && !this.$listStore.hasMoreNext
    }

    @computed
    private get featureMap() {
        const result = new Map<string, Api.Feature>()

        this.$listStore.originalItems.forEach(feature => {
            result.set(feature.name, feature)
        })

        return result
    }

    public getByName(name: Api.Feature.Name): Api.Feature {
        if (!this.isCompleted) {
            throw new Error("Feature list is not loaded yet!")
        }

        return this.featureMap.get(name)
    }

    public isAvailable = createTransformer((featureNames: Api.Feature.Name | Api.Feature.Name[]) => {
        if (!this.isCompleted) {
            return false
        }

        if (typeof featureNames === "string") {
            featureNames = [featureNames]
        }

        return featureNames.every(featureName => {
            const feature = this.getByName(featureName)
            return feature && (feature.isAvailable || this.isTestDriveAvailable(feature))
        })
    })

    public isEnabledForUser = createTransformer((featureNames: Api.Feature.Name | Api.Feature.Name[]) => {
        if (!this.isAvailable(featureNames)) {
            return false
        }

        if (typeof featureNames === "string") {
            featureNames = [featureNames]
        }

        return featureNames.every(featureName => {
            const feature = this.getByName(featureName)
            return feature?.isEnabledForUser
        })
    })

    public get items(): Collections.List<Api.Feature> {
        return this.$listStore.originalItems
    }

    public isTestDriveAvailable(feature: Api.Feature) {
        return feature.isTestDriveAvailable
            && this.$userStore.user.possibleActions.includes(Api.Employee.PossibleActions.can_start_feature_test_drive)
    }

    public verifyTestDriveFeature(feature: Api.Feature) {
        void this.$apiStore.fetch("/api/v3/feature/verifyFeature", {
            method: "POST",
            body: { feature: feature.name }
        })
    }
}
