import * as React from "react"
import {observer} from "mobx-react"
import * as Api from "src/lib/entities/api"
import {Intl} from "src/lib/utils/intl"
import {inject} from "src/lib/utils/inject"
import {CUserVacationCaption, CBadge, Component, CPrice} from "src/lib/components"
import * as Url from "src/lib/utils/url"
import {isDiscount} from "src/lib/entities/utils"

const messages: any = require("../../messages.yml")
const messagesChat = require("src/bums/common/chat/messages.yml")

export namespace CSelectOption {
    export interface Props<T> {
        key?: string | number
        value: T
        onSelect?: () => void
        isHovered?: boolean
        onHovered?: () => void
    }
}

const noEllipsisContentTypes = new Set<Api.ContentType>([
    Api.Offer.contentType,
])

@observer
export class CSelectOption<T> extends Component<CSelectOption.Props<T>, {}> {

    @inject(Intl)
    private intl: Intl

    public render() {
        const {value, isHovered} = this.props

        let subTitle: string | JSX.Element
        let avatarEntity: Api.Employee | Api.Group | Api.Contractor | Api.DocVersion | Api.Topic | string

        let title = Api.getValueName(value, this.intl)

        if (!Api.isExtraField(value) && Api.isBaseEntity(value)) {
            if (Api.isEmployee(value)) {
                subTitle = <CUserVacationCaption position={value.position} vacation={value.nearestVacation} />
                avatarEntity = value
            } else if (Api.isGroup(value)) {
                subTitle = this.intl.formatMessage(messages["groupSubtitle"], {count: value.childrenCount})
                avatarEntity = value
            } else if (Api.isContractorHuman(value))  {
                subTitle = value.position
                avatarEntity = value
            } else if (Api.isContractorCompany(value)) {
                avatarEntity = value
            } else if (Api.isTask(value)) {
                subTitle = value.project ? Api.getEntityName(value.project) : void 0
                avatarEntity = value.responsible
            } else if (Api.isDeal(value)) {
                title = value.shortDescription
                subTitle = value.manager
                    ? Api.getEntityName(value.manager)
                    : void 0
            } else if (Api.isProject(value)) {
                avatarEntity = value.owner
                subTitle = value.parent ? Api.getEntityName(value.parent) : void 0
            } else if (Api.isTopic(value)) {
                subTitle =  value.isPrivate ? value.name : `${this.intl.formatMessage(messagesChat["participantsCount"], {count: value.participantsCount})}`
                avatarEntity = value.visavis ? value.visavis : value
            } else if (Api.isProgramState(value)) {
                if ((value as any).program) {
                    subTitle = Api.getEntityName((value as any).program)
                }
            } else if (Api.isRelationLink(value)) {
                title = value.representation
                subTitle = value.origin
            } else if (Api.isDoc(value)) {
                const {actualVersion} = value

                title = actualVersion && actualVersion.name || this.intl.formatMessage(messages["emptyDocFileName"])
                subTitle = actualVersion && actualVersion.description
                avatarEntity = actualVersion
            } else if (Api.isOffer(value)) {
                title = value.name
                subTitle = <CPrice single={true} value={value.price}/>
            } else if (Api.isMegamail(value) || Api.isSmtp(value)) {
                title = value.email
            }
        } else if (Api.isSelectableArrayVariant(value)) {
            title = value.value
            subTitle = value.valueModel ? Url.generateUrlNameByEntity(value.valueModel) : ""
        } else if (Api.isMoney(value)) {
            title = this.intl.formatMoney(value.value, value.currency)
        } else if (isDiscount(value)) {
            if (value.type === Api.Discount.Type.absolute) {
                title = this.intl.formatMoney(value.value, value.currency.abbreviation)
            } else {
                title = this.intl.formatPercent(value.value)
            }
        }

        /* если элемент состоит только из текста показываем название полностью*/
        return <CBadge
            title={title}
            subTitle={subTitle}
            avatar={avatarEntity}
            hovered={isHovered}
            onClick={this.props.onSelect}
            onMouseEnter={this.props.onHovered}
            noEllipsis={(!subTitle && !avatarEntity)
                || (Api.isBaseValue(value) && noEllipsisContentTypes.has(value.contentType as Api.ContentType))}
        />
    }
}

export namespace CSelectOption {
    export function create<T>(props: Props<T>) {
        return React.createElement(CSelectOption, props)
    }
}
