import * as React from "react"
import {autobind} from "core-decorators"
import {observer} from "mobx-react"
import {action, computed, IReactionDisposer, reaction} from "mobx"
import {inject} from "src/lib/utils/inject"
import {ModalFormStore} from "./ModalFormStore"
import {CSuspenseFallback} from "./CModalFormBuilder"
import {CButton, CIconType, CModal, Component} from "src/lib/components"
import {CModalFormRestorer} from "src/bums/common/modalForm/CModalFormRestorer"
import {FeatureStore} from "src/bums/common/stores/FeatureStore"
import {track, TrackingProp} from "src/lib/tracking"
import {isTask} from "src/lib/entities/bums"
import {noop} from "lodash";
import {FormType, formTypeFeatureMap} from "src/bums/common/modalForm/types"
import {UserStore, hasRights} from "src/lib/entities/api"

const CTaskModalFormBuilder = React.lazy(async () => {
    return {default: (await import("src/bums/task/form/CIssueModalFormBuilder")).CTaskModalFormBuilder}
})

const CProjectModalFormBuilder = React.lazy(async () => {
    return {default: (await import("src/bums/task/form/CIssueModalFormBuilder")).CProjectModalFormBuilder}
})

const CTaskProjectModalFormBuilder = React.lazy(async () => {
    return {default: (await import("src/bums/task/form/CIssueModalFormBuilder")).CTaskProjectModalFormBuilder}
})

const CContractorCompanyModalFormBuilder = React.lazy(async () => {
    return {default: (await import("src/bums/crm/form/CContractorModalFormBuilder")).CContractorCompanyModalFormBuilder}
})

const CContractorHumanModalFormBuilder = React.lazy(async () => {
    return {default: (await import("src/bums/crm/form/CContractorModalFormBuilder")).CContractorHumanModalFormBuilder}
})

const CContractorModalFormBuilder = React.lazy(async () => {
    return {default: (await import("src/bums/crm/form/CContractorModalFormBuilder")).CContractorModalFormBuilder}
})

const CEmployeeModalFormBuilder = React.lazy(async () => {
    return {default: (await import("src/bums/common/form/components/CEmployeeModalFormBuilder")).CEmployeeModalFormBuilder}
})

const CDealModalFormBuilder = React.lazy(async () => {
    return {default: (await import("src/bums/trade/form/CDealModalFormBuilder")).CDealModalFormBuilder}
})

const CClientsImportModalFormBuilder = React.lazy(async () => {
    return {default: (await import ("src/bums/common/clientsImport/components/CClientsImportModalFormBuilder")).CClientsImportFormBuilder}
})

const CTodoModalFormBuilder = React.lazy(async () => {
    return {default: (await import("src/bums/todo/form/CTodoModalFormBuilder")).CTodoModalFormBuilder}
})

const CMessengerChannelModalFormBuilder = React.lazy(async () => {
    return {default:
        (await import("src/bums/settings/integrations/messengers/forms/CMessengerChannelModalFormBuilder"))
            .CMessengerChannelModalFormBuilder
    }
})

const CMailIntegrationModalFormBuilder = React.lazy(async () => {
    return {default:
        (await import("src/bums/settings/integrations/mail/forms/CMailIntegrationModalFormBuilder"))
            .CMailIntegrationModalFormBuilder
    }
})

const CEmailFormBuilder = React.lazy(async () => {
    return {default: (await import("src/bums/common/emailWidget/CEmailFormBuilder")).CEmailFormBuilder}
})
export namespace CModalFormContainer {
    export interface Props extends TrackingProp {}
}

@track({channels: ["product", "amplitude"]})
@observer
@autobind
export class CModalFormContainer extends Component<CModalFormContainer.Props, {}> {

    @inject(ModalFormStore)
    private modalFormStore: ModalFormStore

    @inject(FeatureStore)
    private featureStore: FeatureStore

    @inject(UserStore)
    private userStore: UserStore

    private modalFormTypeReaction: IReactionDisposer = noop as IReactionDisposer

    constructor(props: CModalFormContainer.Props, context: {}) {
        super(props, context)
        this.modalFormTypeReaction = reaction(
            () => this.modalFormStore.type,
            (type) => {
                if (!type) {
                    return
                }
                const trackingOptions = this.modalFormStore.getOptions()
                this.props.tracking.trackEvent({
                    eventType: this.modalTypeName,
                    event: `Open ${this.modalTypeName} form`,
                    params: trackingOptions.type ? {method: trackingOptions.type} : null
                })
            }
        )
        window.addEventListener("hashchange", this.hashChangeListener)
        //вызовем сразу, на тот случай, если страница загружается уже с хэшом
        this.hashChangeListener()
    }

    componentDidMount() {
        window.openModalForm = () => this.modalFormStore.open("email")
    }

    componentWillUnmount() {
        if (this.modalFormTypeReaction) {
            this.modalFormTypeReaction()
        }
        window.removeEventListener("hashchange", this.hashChangeListener)
    }

    private hashChangeListener() {
        if (window.location.hash) {
            const formTypeHash = window.location.hash.slice(1)
            if (formTypeFeatureMap.has(formTypeHash as FormType)) {
                const formType = formTypeFeatureMap.get(formTypeHash as FormType)
                //проверим, что включены фичи, необходимые для формы и достаточно прав
                const featuresAvailable = formType.features
                    ? formType.features.every(featureName => this.featureStore.isAvailable(featureName))
                    : true
                const hasRight = formType.rights
                    ? formType.rights.every(right => hasRights(this.userStore.user, right))
                    : true
                if (featuresAvailable && hasRight) {
                    this.modalFormStore.open(formTypeHash as FormType)
                } else {
                    window.location.hash = ""
                }
            }

        }
    }

    @track((self: CModalFormContainer) => ({
        eventType: self.modalTypeName,
        event: `Cancel ${self.modalTypeName} form`,
        params: {method: "cancel"}
    }), {dispatchFirst: true})
    @action
    private closeAndClearForm() {
        this.modalFormStore.clearPreset()
        this.modalFormStore.closeForm()
        window.location.hash = ""
    }

    @track((self: CModalFormContainer) => {
        const activeFormType = self.getActiveFormType()
        if (activeFormType) {
            const trackingOptions = self.modalFormStore?.getOptions()
            return {
                eventType: self.modalTypeName,
                event: `Submit ${activeFormType} form`,
                params: trackingOptions?.type ? {method: trackingOptions.type} : null
            }
        }
    }, {dispatchFirst: true})
    @action
    private confirmForm() {
        this.modalFormStore.clearPreset()
        this.modalFormStore.closeForm(true)
        window.location.hash = ""
    }

    @track((self: CModalFormContainer) => ({
        eventType: self.modalTypeName,
        event: `Cancel ${self.modalTypeName} form`,
        params: {method: "button"}
    }), {dispatchFirst: true})
    @action
    private closeAndSaveForm() {
        this.modalFormStore.savePreset()
        this.modalFormStore.closeForm()
        window.location.hash = ""
    }

    private getActiveFormType() {
        const forms: any[] = this.modalFormStore.forms
        let activeForm
        if (forms) {
            if (forms.length === 1) {
                activeForm = forms[0].value ? forms[0] : forms[0].form
            } else {
                activeForm = forms.map(form => form.value ? form : form.form).find(form => form.active)
            }
            if (isTask(activeForm.value) && activeForm.value.isNegotiation) {
                return "taskNegotiation"
            }
        }
        return activeForm ? activeForm.value.contentType : null
    }

    @computed
    private get modalTypeName() {
        const {type} = this.modalFormStore
        const name = type[0].toUpperCase() + type.slice(1)
        if (name.includes("Template")) {
            return "TaskTemplate"
        } else if (name.includes("Task")) {
            return "TaskNew"
        } else if (name.includes("Kanban")) {
            return "Deal"
        } else {
            return name
        }
    }

    @computed
    private get modalMaxWidth() {
        if (this.modalFormStore.type === "email") {
            return 760
        }
        if (this.modalFormStore.type !== "clientsImport"
            && this.modalFormStore.type !== "contractor"
            && this.modalFormStore.type !== "employee"
            && !(this.modalFormStore.type === "deal" && this.featureStore.isAvailable("bums.trade.deal_card_with_tabs"))
            && this.modalFormStore.type !== "inviteLink"
            && this.modalFormStore.type !== "groupOfEmployees"
            && this.modalFormStore.type !== "taskProject"
            && this.modalFormStore.type !== "todo") {
            return 650
        }
    }

    public render() {
        const isEnabledModalFormRestore = this.featureStore.isAvailable("bums.common.restore_modal_form")
        const commonProps = {
            onClose: this.closeAndClearForm,
            onSubmit: this.confirmForm,
            closeAndSaveFormBtn: isEnabledModalFormRestore
                ? <CButton
                    name="closeFormBtn"
                    icon={CIconType.CANCEL}
                    type={CButton.Type.FLAT}
                    onClick={this.closeAndSaveForm}
                />
                : null
        }

        const type = this.modalFormStore.type

        if (void 0 === type) {
            return isEnabledModalFormRestore
                ? <CModalFormRestorer/>
                : null
        }

        return <CModal
            open={!!type}
            onClose={this.closeAndSaveForm}
            closeOnEsc={true}
            autoOverflow={true}
            animateClass="animate-fadein"
            maxWidth={this.modalMaxWidth}
            contentPosition={type !== "clientsImport" && CModal.Position.TOPCENTER}
            disableCloseOnOutsideClick={isEnabledModalFormRestore}
        >
            <React.Suspense fallback={CSuspenseFallback}>
                {(type === "task" || type === "taskTemplate") && <CTaskModalFormBuilder {...commonProps} />}
                {(type === "project" || type === "projectTemplate") && <CProjectModalFormBuilder {...commonProps} />}
                {(type === "taskProject" || type === "taskProjectTemplate") && <CTaskProjectModalFormBuilder {...commonProps} />}
                {type === "contractorCompany" && <CContractorCompanyModalFormBuilder {...commonProps} />}
                {type === "contractorHuman" && <CContractorHumanModalFormBuilder {...commonProps} />}
                {type === "contractor" && <CContractorModalFormBuilder {...commonProps} />}
                {type === "employee" && <CEmployeeModalFormBuilder {...commonProps} />}
                {type === "inviteLink" && <CEmployeeModalFormBuilder {...commonProps} />}
                {type === "groupOfEmployees" && <CEmployeeModalFormBuilder {...commonProps}/>}
                {(type === "deal" || type === "kanbanDeal") && <CDealModalFormBuilder {...commonProps} />}
                {type === "clientsImport" && <CClientsImportModalFormBuilder {...commonProps} />}
                {type === "todo" && <CTodoModalFormBuilder {...commonProps} />}
                {type === "messengerChannel" && <CMessengerChannelModalFormBuilder {...commonProps} />}
                {type === "mailIntegration" && <CMailIntegrationModalFormBuilder {...commonProps} />}
                {type === "email" && <CEmailFormBuilder {...commonProps} />}
            </React.Suspense>
        </CModal>
    }
}
