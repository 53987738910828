import {computed} from "mobx"
import {inject} from "src/lib/utils/inject"
import {ListStore} from "src/lib/entities/store/ListStore"
import {ApiStore} from "src/lib/entities/store/ApiStore"
import {Currency} from "src/lib/entities/api"
import {getEntityTypeEndpoint} from "src/lib/entities/utils"

export class CurrencyStore {

    private $listStore: ListStore<Currency>

    constructor(
        @inject(ApiStore) private $apiStore: ApiStore,
    ) {
        this.$listStore = this.$apiStore.resolveList<Currency>(() => ({
            endpoint: getEntityTypeEndpoint(Currency.contentType)
        }))
    }

    @computed
    public get isCompleted() {
        return this.$listStore.loadStateNext.isCompleted()
    }

    public get listStore() {
        return this.$listStore
    }

    @computed
    public get formattedCurrencies() {
        return this.currencies.map(currency => ({
            key: currency.abbreviation,
            name: currency.sign,
        }))
    }

    public getNameCurrencyByISO(iso: string) {
        const find = this.formattedCurrencies.find(currency => currency.key === iso)
        return find ? find.name : null
    }

    public getCurrencyByISO(iso: string) {
        const find = this.currencies.find(currency => currency.abbreviation === iso)
        return find ? find : null
    }

    @computed
    public get baseCurrency() {
        return this.currencies.filter(currency => currency.isBase).first()
    }

    public get currencies() {
        return this.$listStore.originalItems
    }

    @computed
    public get currencyByAbbr() {
        return this.currencies.reduce((сurrent, next) => сurrent.set(next.abbreviation, next), new Map<string, Currency>())
    }
}
