import * as React from "react"
import {observer} from "mobx-react"
import {computed} from "mobx"
import {BaseLayoutStore, BaseCardLayout, TypeStore} from "./types"
import {ViewStore} from "./stores/ViewStore"
import {Component} from "src/lib/components"

type AnyBaseLayoutStore = BaseLayoutStore<any, any>

export const LayoutContext = React.createContext<AnyBaseLayoutStore>(null)
export const LayoutCardNameContext = React.createContext<string>(null)

type RenderCallback<T> = (stores?: {layoutStore: AnyBaseLayoutStore, viewStore: ViewStore, cardStore: TypeStore<T>}) => React.ReactNode


export namespace CLayoutStore {
    export type Stores<T extends BaseCardLayout = BaseCardLayout> = {
        layoutStore: AnyBaseLayoutStore,
        viewStore: ViewStore,
        cardStore: TypeStore<T>
    }
}

interface CLayoutStoreProps<T> {
    layoutStore: AnyBaseLayoutStore
    name: string
    children: RenderCallback<T>
}

@observer
class CLayoutStoreInner<T extends BaseCardLayout> extends Component<CLayoutStoreProps<T>, {}> {

    @computed
    private get viewStore() {
        return this.props.name && this.props.layoutStore.getViewStoreByName(this.props.name)
    }

    @computed
    private get cardStore() {
        return this.props.name && this.props.layoutStore.getCardStoreByName(this.props.name) as TypeStore<T>
    }

    @computed
    private get stores() {
        return {
            layoutStore: this.props.layoutStore,
            viewStore: this.viewStore,
            cardStore: this.cardStore
        }
    }

    public render() {
        return this.props.children(this.stores)
    }
}

export const CLayoutStore = observer(<T extends BaseCardLayout>(props: {children: RenderCallback<T>}) => {
    return <LayoutContext.Consumer>
        {(layoutStore) => {
            return <LayoutCardNameContext.Consumer>
                {(name) => {
                    if (layoutStore) {
                        return <CLayoutStoreInner layoutStore={layoutStore} name={name}>
                            {props.children}
                        </CLayoutStoreInner>
                    }
                    return props.children()
                }}
            </LayoutCardNameContext.Consumer>
        }}
    </LayoutContext.Consumer>
})
