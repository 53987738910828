import * as Api from "src/lib/entities/api"
import {Form} from "src/lib/utils/form/form"
import {Imap, ListStore, Todo} from "src/lib/entities/api"
import {MessengerChannel} from "src/lib/entities/api";

export type FormType = "task" | "project" | "taskNegotiation" | "taskProject" | "taskProjectTemplate" | "taskTemplate" | "projectTemplate"
    | "contractor" | "contractorHuman" | "contractorCompany" | "deal" | "kanbanDeal" | "employee" | "clientsImport"
    | "inviteLink" | "groupOfEmployees" | "todo" | "messengerChannel" | "mailIntegration"
    | "inviteLink" | "groupOfEmployees" | "todo" | "email"

export const formTypeFeatureMap = new Map<FormType, {features?: Api.Feature.Name[], rights?: Api.Employee.PossibleActions[]}>([
    [
        "task",
        {rights: [Api.Employee.PossibleActions.tasks_add]}
    ],
    [
        "project",
        {rights: [Api.Employee.PossibleActions.project_admin]}
        ],
    [
        "taskProject",
        {rights: [Api.Employee.PossibleActions.tasks_add, Api.Employee.PossibleActions.project_admin]}
    ],
    [
        "contractor",
        {rights: [Api.Employee.PossibleActions.crm_add]}
    ],
    [
        "contractorHuman",
        {rights: [Api.Employee.PossibleActions.crm_add]}
    ],
    [
        "contractorCompany",
        {rights: [Api.Employee.PossibleActions.crm_add]}
    ],
    [
        "deal",
        {rights: [Api.Employee.PossibleActions.deal_add]}
    ],
    [
        "employee",
        {rights: [Api.Employee.PossibleActions.employee_add]}
    ],
    [
        "clientsImport",
        {
            features: ["bums.crm.add_contractor_redesign", "bums.crm.clients_import_new_design"],
            rights: [Api.Employee.PossibleActions.crm_add]
        }
    ],
    [
        "inviteLink",
        {
            features: ["bums.staff.employee_card_with_tabs", "bums.staff.invite_employee_link"],
            rights: [Api.Employee.PossibleActions.crm_add]
        }
    ],
    [
        "groupOfEmployees",
        {
            features: ["bums.staff.employee_card_with_tabs"],
            rights: [Api.Employee.PossibleActions.crm_add]
        }
    ],
    [
        "todo",
        {
            features: ["bums.time.todo_card_with_tabs"],
            rights: [Api.Employee.PossibleActions.todos_add]
        }
    ],
    [
        "email",
        {
            features: ["bums.common.common.email_widget_redesign"],
        }
    ],
])

export type MultiFormType = "taskProject" | "taskProjectTemplate" | "contractor"

export type BaseOptions<T> = {
    presets?: T,
    confirmHandler?: () => void
    cancelHandler?: () => void
    withoutFixPreset?: boolean
    fixedFields?: string[][]
    type?: string
}

export type Options<T extends FormType> =
    T extends ("task" | "taskTemplate" | "project" | "projectTemplate")
        ? BaseOptions<[Api.Task | Api.Project]> & {isTemplate?: boolean, redirect?: boolean}
    : T extends ("taskProject" | "taskProjectTemplate")
        ? BaseOptions<[Api.Task, Api.Project]> & {activeForm?: "task" | "project", isTemplate?: boolean, redirect?: boolean}
    : T extends "contractorHuman" ? BaseOptions<[Api.ContractorHuman]> & {submitHandler?: (entity: Api.ContractorHuman) => void}
    : T extends "contractorCompany" ? BaseOptions<[Api.ContractorCompany]> & {submitHandler?: (entity: Api.ContractorCompany) => void}
    : T extends "contractor" ? BaseOptions<[Api.ContractorCompany, Api.ContractorHuman]> & {
        submitHandler?: (entity: Api.Contractor) => void
        activeForm?: "company" | "human"
        disableFormTabs?: boolean
    }
    : T extends ("deal" | "kanbanDeal")
        ? BaseOptions<[Api.Deal]> & {fixedProgram?: boolean, baseOn?: Api.BaseEntity, slug?: string, redirect?: boolean}
    : T extends "employee" ? BaseOptions<[Api.Employee]> & {submitHandler?: (entity: Api.Employee) => void}
    : T extends "clientsImport" ? BaseOptions<[Api.ContractorImport]>
    : T extends "todo" ? BaseOptions<[Todo]> & {redirect?: boolean, onCreateEntity?: (entity: Todo) => void}
    : T extends "messengerChannel" ? BaseOptions<[MessengerChannel]> & {submitHandler?: (entity: MessengerChannel) => void}
    : T extends "mailIntegration" ? BaseOptions<[Imap]> & {imapListStore?: ListStore<Imap>}
    : T extends "email" ? BaseOptions<[Api.Message]> & {emailAccount: Api.Smtp | Api.Imap | Api.Megamail, parentEntity?: Api.Message}
    : never

export type FormInstance = Form<Api.BaseEntity> | {form: Form<Api.BaseEntity>}
