import * as React from "react"
import {observer} from "mobx-react"
import {observable, action, computed} from "mobx"
import {autobind} from "core-decorators"
import {MentionProps} from "draft-js-mention-plugin"
import {inject} from "src/lib/utils/inject"
import {CUser, CPopover, Component} from "src/lib/components"
import * as Api from "src/lib/entities/api"

export namespace CMention {
   export type Props = MentionProps<Api.Employee>
}

@observer
@autobind
export class CMention extends Component<CMention.Props, {}> {

    @inject(Api.Store)
    private apiStore: Api.Store

    @inject(Api.UserStore)
    private userStore: Api.UserStore

    @observable
    private userInfo = false

    @action
    private showUserInfo() {
        this.userInfo = true
    }

    @action
    private hideUserInfo() {
        this.userInfo = false
    }

    @computed
    private get entity() {
        return this.apiStore.getEntity(this.props.mention.toJS())
    }

    public render() {
        return <CPopover
            element={<span onClick={this.showUserInfo} className={this.props.className}>{this.props.children}</span>}
            open={this.userInfo}
            onClose={this.hideUserInfo}
        >
            <CUser
                sizeImg={CUser.SizeType.BIG}
                viewType={CUser.ViewType.FULL}
                entity={this.entity}
                viewTypeContactInfo={CUser.ViewTypeContactInfo.FULL}
                showChatButton={!Api.isEntityEquals(this.entity, this.userStore.user)}
                onAfterChatOpen={this.hideUserInfo}
            />
        </CPopover>
    }
}
