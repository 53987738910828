
export enum StickerSize {
   Medium = "medium",
   Small = "small",
   XSmall = "xSmall"
}

export enum StickerName {
   WomanQuestion = "womanQuestion",
   WomanParty = "womanParty",
   WomanOK = "womanOk",
   ManRating = "manRating",
   ManAttention = "manAttention"
}
