import {action, observable} from "mobx"
import {isString, isNumber} from "lodash"
import * as Collections from "src/lib/collections"
import {DateOnly, IntervalNumbers, isBaseEntity} from "src/lib/entities/api"
import {BaseEntity} from "src/lib/entities/types"
import {validateDateOnly} from "src/lib/entities/utils"
import {FormError} from "src/lib/utils/form/types"
import {EmailRegExp} from "src/bums/common/comment/transports/EmailTransport";

export const formValidationErrorType = "formValidationErrorType"

export type Validator<T> = (v: any, formValue: T) => FormError | void

export interface IFromValidator<V> {
    set(prop: keyof V, validator: Validator<V>): void
    remove(prop: keyof V, validator?: Validator<V>): void
    getError(prop: keyof V, value: V[keyof V], formValue?: V): Collections.List<FormError>
    validate(value: V): {[prop: string]: Collections.List<FormError>}
}

export class FormValidator<T> implements IFromValidator<T> {

    public $validators = observable.map<keyof T, Validator<T>[]>()

    public has<K extends keyof T>(prop: K) {
        return this.$validators.has(prop)
    }

    @action
    public set<K extends keyof T>(prop: K, validator: Validator<T>) {
        if (this.$validators.has(prop)) {
            if (!this.$validators.get(prop).includes(validator)) {
                this.$validators.set(prop, [...this.$validators.get(prop), validator])
            }
        } else {
            this.$validators.set(prop, [validator])
        }
    }

    @action
    public remove<K extends keyof T>(prop: K, validator?: Validator<T>) {
        if (!validator) {
            return this.$validators.delete(prop)
        }
        if (this.$validators.has(prop)) {
            this.$validators.set(prop, this.$validators.get(prop).filter(v => v === validator))
            if (!this.$validators.get(prop).length) {
                return this.$validators.delete(prop)
            }
        }
    }

    public getError(prop: keyof T, value: T[keyof T], formValue: T): Collections.List<FormError> {
        if (this.$validators.has(prop)) {
            return Collections.List(
                this.$validators.get(prop)
                    .map(validator => validator(value, formValue))
                    .filter(err => !!err) as FormError[]
            )
        }
        return Collections.List()
    }

    public validate(value: T): {[prop: string]: Collections.List<FormError>} {
        const errors = {} as {[prop: string]: Collections.List<FormError>}
        this.$validators.forEach((validators, prop: keyof T) => {
            const propErrors = validators.map(validator => validator(value[prop], value)).filter(e => !!e)
            if (propErrors.length) {
                errors[prop] = Collections.List(propErrors) as Collections.List<FormError>
            }
        })
        return errors
    }
}

export function isEmpty(value: any): boolean {
    return (
        value === null ||
        value === void 0 ||
        (isString(value) && value.trim() === "") ||
        ((Collections.isList(value) || Array.isArray(value)) && value.length === 0)
    )
}

export function createFormError(message: string) {
    return { message, type: formValidationErrorType }
}

export namespace FormValidator {

    export function floatFieldValidator(min: number, max: number, messages: {emptyError?: string, rangeError: string}) {
        if (min == null) {
            min = -Infinity
        }
        if (max == null) {
            max = Infinity
        }
        return (value: any) => {
            if (value == null) {
                return
            }
            if (!isNumber(value)) {
                return { message: messages.emptyError, type: formValidationErrorType }
            }
            if (value > max || value < min) {
                return { message: messages.rangeError, type: formValidationErrorType }
            }
        }
    }

    export function nonEmptyFieldValidator(message: string, allowedNull = false) {
        return (value: any) => {
            if (allowedNull && value === null) {
                return
            }
            if (isEmpty(value)) {
                return { message, type: formValidationErrorType }
            }
        }
    }

    export function passwordConfirmValidator(message: string, confirmPasswordValue: string) {
        return (value: any) => {
            if (value.trim() && value.trim() !== confirmPasswordValue) {
                return { message, type: formValidationErrorType }
            }
        }
    }

    export function intervalNumbersFieldValidator(messages: {invalidFrom: string, invalidTo: string, invalidRange: string }) {
        return (range: IntervalNumbers) => {
            if (!isNumber(range.from)) {
                return { message: messages.invalidFrom, type: formValidationErrorType }
            }
            if (!isNumber(range.to)) {
                return { message: messages.invalidTo, type: formValidationErrorType }
            }
            if (range.to < range.from) {
                return { message: messages.invalidRange, type: formValidationErrorType }
            }
        }
    }

    export function dateOnlyValidator(message: string) {
        return (date: DateOnly) => {
            if (!validateDateOnly(date)) {
                return { message: message, type: formValidationErrorType }
            }
        }
    }

    export function rightsValidator(
        checkRights: (fieldValue: BaseEntity, formValue?: BaseEntity) => boolean,
        messageFactory: (fieldValue: BaseEntity, formValue?: BaseEntity) => string
    ) {
        return (fieldValue: BaseEntity | BaseEntity[], formValue: any) => {
            if (isBaseEntity(fieldValue) && !checkRights(fieldValue, formValue)) {
                return {message: messageFactory(formValue, formValue), type: formValidationErrorType}
            }
        }
    }

    export function minimumSymbolsValidator(message: string, minimumSymbols: number) {
        return (value: any) => {
            if (value.length < minimumSymbols) {
                return { message, type: formValidationErrorType }
            }
        }
    }

    export function emailValidator(message: string) {
        return (value: any) => {
            if (
                !isString(value)
                || !EmailRegExp.test(value)
            ) {
                return { message, type: formValidationErrorType }
            }
        }
    }
}
