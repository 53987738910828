import {Component} from "src/lib/components"
import * as React from "react"
import classNames from "classnames/bind"

const style = classNames.bind(require("./CLogo.styl"))

/**
 * логотип Мегаплан
 */
export default class CLogo extends Component<{}, {}> {
    public render() {
        return <span
            className={style("CLogo")}
            dangerouslySetInnerHTML={{__html: require("!svg-inline-loader!./logo.svg") as string}}
        />
    }
}
