/* tslint:disable */
import * as H from "../metadataHelper"
import {StringField} from "./stringField"
import {EnumField} from "./enumField"

export interface TodoCategory extends H.BaseEntity {
    name?: string; // Имя категории
    masterType?: TodoCategory.MasterType; // Тип коммуникации
    activeBgColor?: string; // Активный цвет фона
    bgColor?: string; // Цвет фона
    color?: string; // Цвет
    id?: string; // Идентификатор
    contentType: "TodoCategory"; // Object type
}

export module TodoCategory {
    export const contentType: "TodoCategory" = "TodoCategory";
    export const endpoint = "/api/v3/todoCategory";
    export type MasterType = "call"|"event"|"meeting"|"private"|"todo";
    export module MasterType {
        export const call: MasterType = "call";
        export const event: MasterType = "event";
        export const meeting: MasterType = "meeting";
        export const _private: MasterType = "private";
        export const todo: MasterType = "todo";
    }
    export const newObject: TodoCategory = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "TodoCategory.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const masterType = {contentType: "EnumField" as "EnumField", name: "masterType", isMultiple: false, isSortable: false, id: "TodoCategory.masterType", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["call", "event", "meeting", "private", "todo"])};
        export const activeBgColor = {contentType: "StringField" as "StringField", name: "activeBgColor", isMultiple: false, isSortable: false, id: "TodoCategory.activeBgColor", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const bgColor = {contentType: "StringField" as "StringField", name: "bgColor", isMultiple: false, isSortable: false, id: "TodoCategory.bgColor", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const color = {contentType: "StringField" as "StringField", name: "color", isMultiple: false, isSortable: false, id: "TodoCategory.color", defaultValue: null as StringField["defaultValue"], maxLength: 0};
    }
}

export function isTodoCategory(arg: any): arg is TodoCategory {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "TodoCategory"
}
