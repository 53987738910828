import * as React from "react"
import * as Api from "src/lib/entities/api"
import * as Url from "src/lib/utils/url"
import {bindArg} from "src/lib/utils/func"
import {observer} from "mobx-react"
import {Component} from "src/lib/components"
import {isHasTarget} from "src/lib/types"
import {inject} from "src/lib/utils/inject"
import {Router} from "src/lib/utils/router"

declare var window: any

export function isModifiedEvent(event: React.MouseEvent<HTMLElement>) {
    return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)
}

export type Target = string | Api.BaseEntity

interface CUrlDecoratorProps extends React.HTMLAttributes<HTMLElement> {
    to: Target
    disabled?: boolean
}

export module CUrlDecorator {
    export type To = Target;
}

@observer
export default class CUrlify extends Component<CUrlDecoratorProps, {}> {

    @inject(Router)
    private router: Router

    private handleClick = (handler: React.MouseEventHandler<HTMLElement>, url: string, event: React.MouseEvent<HTMLElement>) => {
        if (handler) {
            handler(event)
        }

        const child = React.Children.only(this.props.children as React.ReactElement<any>)
        const isLink = child.type === "a"
        const isTargetSelf = !isHasTarget(child.props) || (child.props.target === "_self")
        const isInternalLink = Url.hasInternalLink(url)

        // Если нажали не левой кнопкой,
        // были нажаты дополнительный функциональные кнопки на клавиатуре,
        // отменено дефолтное поведение,
        // ссылка на внешний ресурс и тэг А
        // ничего не делаем.
        if (event.button !== 0
            || isModifiedEvent(event)
            || event.defaultPrevented === true) {
            return
        }

        if (window.ReactRouterFound && isInternalLink && isTargetSelf) {
            event.preventDefault()
            this.router.push(url);
        } else if (isLink) {
            return
        } else {
            window.open(url)
        }
    }

    public render() {
        const {disabled, to} = this.props
        const handler = React.Children.only(this.props.children as React.ReactElement<any>).props.onClick;
        const url: string = typeof to === "string"
            ? Url.normalizeUrl(to, false)
            : Url.generateUrlByEntity(to)

        let childProps: {
            href?: string,
            onClick?: React.MouseEventHandler<HTMLElement>,
            disabled: boolean,
        } = {
            disabled,
            onClick: bindArg(this.handleClick, handler, url)
        }

        if (url) {
            childProps.href = url
        }

        return React.cloneElement(
            this.props.children as React.ReactElement<any>,
            childProps
        );
    }
}
