/* tslint:disable */
import * as H from "../metadataHelper"
import {StringField} from "./stringField"

export interface Unit extends H.BaseEntity {
    name?: string; // Единица измерения
    id?: string; // Идентификатор
    contentType: "Unit"; // Object type
}

export module Unit {
    export const contentType: "Unit" = "Unit";
    export const endpoint = "/api/v3/unit";

    export const newObject: Unit = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "Unit.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
    }
}

export function isUnit(arg: any): arg is Unit {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Unit"
}
