import {Component} from "src/lib/components"
import * as React from "react"
import classNames from "classnames/bind"
import {observer} from "mobx-react"
import {CIconType} from "./iconMetadata"
import {CIconSize, CIconColor} from "./types"
import {isUserColor, UserColor} from "src/lib/entities/api"
import "./webfont.styl"

export interface CIconProps {
    className?: string
    type: CIconType
    size?: CIconSize
    color?: UserColor | CIconColor
    key?: number|string
    title?: string
    name?: string   // Используется в автотестах для поиска кнопок на странице, давайте осмысленные имена.
    onClick?: (event: React.MouseEvent<Element>) => void  // Указал onClick, укажи и name!
    onMouseDown?: (event: React.MouseEvent<Element>) => void // Указал onMouseDown, укажи и name!
    buttonSize?: boolean
}

const style = classNames.bind(require("./CIcon.styl"))

/**
 * Иконка
 */
@observer
export class CIcon extends Component<CIconProps, {}> {
    public static Type = CIconType;
    public static Size = CIconSize;
    public static Color = CIconColor

    public static defaultProps = {
        size: CIconSize.MEDIUM,
        color: CIconColor.DEFAULT
    }

    public render() {
        const {type, size, color, className, title} = this.props

        const addClassName = style("icon", "icon-svg", className, {
            [CIcon.Size[size]]: Boolean(CIcon.Size[size]),
            [CIconColor[!isUserColor(color) ? color : 0]]: !isUserColor(color) && Boolean(CIcon.Color[color]),
            pointer: !!this.props.onClick,
            buttonSize: this.props.buttonSize
        })

        return <span
            data-name={this.props.name ? this.props.name : this.props.type}
            onClick={this.props.onClick}
            onMouseDown={this.props.onMouseDown}
            className={addClassName}
            style={{color: isUserColor(color) ? color.hexColor : void 0}}
            title={title}
            data-icon={String.fromCharCode(type)}
        />
    }
}
