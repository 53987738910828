import * as React from "react"
import {observer} from "mobx-react"
import {autobind} from "core-decorators"
import {Component} from "src/lib/components"
import {CPromoNews} from "src/bums/common/promo/CPromoNews/CPromoNews"

const LazyCPromoNews = React.lazy(async () => {
    return {default: (await import ("./CPromoNews")).CPromoNews}
})

const noRender = () => null as any

@observer
@autobind
export class CPromoNewsContainer extends Component<CPromoNews.Props, {}> {

    public render() {
       return <React.Suspense fallback={noRender}>
            <LazyCPromoNews
                actionDone={this.props.actionDone}
                promoData={this.props.promoData}
            />
       </React.Suspense>
    }
}
