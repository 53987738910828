import * as React from "react"
import {observer} from "mobx-react"
import classNames from "classnames/bind"
import * as Collections from "src/lib/collections"
import {fieldRowDisplayType, alignItem, sizeType} from "src/lib/types"
import CFieldRowStretching from "./CFieldRowStretching";
import CFieldRowItem from "./CFieldRowItem"
import CFieldRowText from "./CFieldRowText"
import {OwnError} from "src/lib/utils/formError"
import {
    CAdapt,
    CLabel,
    CIcon,
    CIconType,
    CButton,
    CCheckbox,
    CStyledContent,
    Component
} from "src/lib/components"

const style: any = classNames.bind(require("./CFieldRow.styl"))

declare var window: any

/*
    Компонент для отрисовки элемнтов формы
 */

interface CFieldRowProps {
    caption?: string | JSX.Element
    // true если хотим в caption всю фразу в одну строку
    captionNowrap?: boolean
    className?: string | string[]
    onClick?: (event?: React.MouseEvent<HTMLElement>) => void
    visible?: boolean
    icon?: CIconType
    //редактируемый
    editable?: boolean
    //в состоянии редактирования
    editMode?: boolean
    displayType?: fieldRowDisplayType
    customActions?: JSX.Element | Array<JSX.Element> //принимает список кнопок действий
    errors?: Collections.List<OwnError | {type: string, message: JSX.Element}>  //принимает список ошибок поля
    description?: string | JSX.Element // описание под полем формы
    required?: boolean //Обязательное поле, добавляет оформление к label
    width?: "full" | "auto"
    align?: alignItem
    bottomMargin?: boolean
    lastDetect?: boolean
    size?: sizeType
    subGroup?: boolean// строки подгруппы
    textAlign?: "right" | "left"
    labelShrink?: boolean // сжимает label для экономии места
    paddingIcon?: boolean // паддинг справа 16 px
    labelWidth?: number
}

interface CFieldRowErrorsProps {
    errors?: Collections.List<OwnError | {type: string, message: JSX.Element}>
    className?: string
    bottomMargin?: boolean
}

class CFieldRowErrors extends Component<CFieldRowErrorsProps, {}> {

    render() {

        if (!this.props.errors || !this.props.errors.length) {
            return null
        }

        return <div className={style("errorsList", {bottomMargin: this.props.bottomMargin, className: this.props.className})}>{
            this.props.errors.map((item, index: number) => {
                return <div key={`error-${index}`} className={style("errorsItem", {warning: "warning" === item.type})}>{item.message}</div>
            })
        }</div>
    }
}




@observer
export default class CFieldRow extends Component<CFieldRowProps, {}> {

    public static StretchingElement = CFieldRowStretching
    public static Element = CFieldRowItem
    public static TextElement = CFieldRowText
    public static Errors = CFieldRowErrors

    public static defaultProps = {
        visible: true,
        width: "full",
        bottomMargin: false,
        lastDetect: true,
    }

    /*
    * если компонет имеет прозрачную подложку добавляем обертку для изменеия выравнивания первых и последних элементов в строке
     */
    private renderChildElement(element: React.ReactElement<any>) {

        if ((
            (element.type as React.ComponentClass<any>) === CButton
                && (element.props.type === CButton.Type.FLAT
                    || element.props.type === CButton.Type.FLAT_WARNING
                    || element.props.type === CButton.Type.FLAT_SUCCESS
                )
            )
            || ((element.type as React.ComponentClass<any>) === CCheckbox)
        ) {
            return React.cloneElement(
                element as React.ReactElement<{className: any; key: string}>,
                {className: style("transparentBgElement", element.props.className)}
            )
        } else {
            return element
        }
    }

    private isPropsElement(child: React.ReactElement<any>) {
        return(child.type as React.ComponentClass<any>) === CFieldRowStretching ||
            (child.type as React.ComponentClass<any>) === CFieldRowItem
    }

    public renderChildren(children: React.ReactNode): React.ReactNode {

        const isOne = React.Children.count(children) === 1

        return React.Children.map(children, (child: JSX.Element, index: number) =>
            React.isValidElement(child)
                ?  this.isPropsElement(child as React.ReactElement<any>)
                    ? React.cloneElement((child as React.ReactElement<{className: any; key: string}>), {className: style(
                        "itemChild",
                        `${this.props.displayType}Child`,
                        {
                            "stretch": (child.type as React.ComponentClass<any>) === CFieldRowStretching,
                            "bottomMargin": this.props.bottomMargin
                        },
                        this.props.size
                    ), key: `field-${index}`})
                    : <div
                        className={style(
                            "itemChild",
                            `${this.props.displayType}Child`,
                            this.props.align,
                            {
                                "stretch": isOne,
                                "isOne": isOne,
                                "bottomMargin": this.props.bottomMargin,
                            },
                            this.props.size
                        )}
                    >

                        {this.renderChildElement(child)}
                    </div>
                : child
        )
    }

    private renderErrors() {
        return <CFieldRow.Errors errors={this.props.errors}/>
    }

    private renderDescription() {
        if (this.props.description) {
            return <CStyledContent.Secondary>
                {this.props.description}
            </CStyledContent.Secondary>
        }
    }

    private renderActions() {
        const {customActions} = this.props

        return customActions &&
            <div className={style("actions")}>
                {customActions}
            </div>

    }

    private onClickHandler = (event: React.MouseEvent<HTMLElement>) => {
        if (window.getSelection() && window.getSelection().toString().length && !this.props.editMode) {return}
        if (this.props.onClick) {
            this.props.onClick(event)
        }
    }

    public render() {
        const {
                editable,
                displayType,
                className,
                icon,
                caption,
                editMode,
                width,
                required,
                lastDetect,
                errors,
                size,
                captionNowrap,
                subGroup,
                textAlign,
                labelShrink,
                paddingIcon,
                labelWidth
            } = this.props

        if (!this.props.visible) {
            return null
        }

        const textCaption = caption && <CLabel
                caption={caption}
                type={CLabel.Type.FORM}
                className={style("label", {captionNowrap, labelShrink})}
                required={required}
                width={labelWidth}
            />

        const iconCaption = icon && <div className={style("iconLabel")}>
                <CIcon type={icon}/>
            </div>

        const isValueOnly = !(caption || icon)

        const isMobile = CAdapt.isMobileDevice()

        const elementClassName = style(
                "fieldRow",
                width,
                displayType,
                className,
                textAlign,
                {
                    subGroup: subGroup,
                    editable: editable,
                    edit : editMode,
                    lastDetect: lastDetect,
                    simple: isValueOnly,
                    isMobile: isMobile
                },
                size
            )

        return isValueOnly
            ? <div>
                <div className={elementClassName} onClick={this.onClickHandler}>
                    {this.renderChildren(this.props.children)}
                    {this.renderActions()}
                </div>
                {!!(errors && errors.length) && this.renderErrors()}
                {this.renderDescription()}
            </div>
            : <div className={elementClassName}>
                {textCaption}
                {iconCaption}
                <div
                    className={style("value", {"withCaption": caption, paddingIcon})}
                    onClick={this.onClickHandler}
                >
                    <div
                        className={style(
                            "valueWrapper",
                            width,
                            {"fixedWidth": React.Children.count(this.props.children) === 1}
                        )}
                    >
                        {this.renderChildren(this.props.children)}
                    </div>
                    {this.renderErrors()}
                    {this.renderDescription()}
                </div>
                {this.renderActions()}
            </div>
    }
}
