/* tslint:disable */
import * as H from "../metadataHelper"
import {ContractorCompany} from "./contractorCompany"
import {ContractorHuman} from "./contractorHuman"
import {Employee} from "./employee"
import {StringField} from "./stringField"
import {DateTimeField} from "./dateTimeField"
import {IntegerField} from "./integerField"
import {BoolField} from "./boolField"

export interface Notification extends H.BaseEntity {
    type?: string;
    time?: Date;
    content?: string;
    sender?: ContractorCompany|ContractorHuman|Employee;
    size?: number;
    subject?: H.BaseEntity;
    isActive?: boolean;
    isMention?: boolean;
    isHistoryLog?: boolean;
    id?: string; // Идентификатор
    contentType: "Notification"; // Object type
}

export module Notification {
    export const contentType: "Notification" = "Notification";
    export const endpoint = "/api/v3/notification";

    export const newObject: Notification = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const type = {contentType: "StringField" as "StringField", name: "type", isMultiple: false, isSortable: false, id: "Notification.type", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const time = {contentType: "DateTimeField" as "DateTimeField", name: "time", isMultiple: false, isSortable: false, id: "Notification.time", defaultValue: null as DateTimeField["defaultValue"]};
        export const content = {contentType: "StringField" as "StringField", name: "content", isMultiple: false, isSortable: false, id: "Notification.content", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const sender = {contentType: "RefLinkField" as "RefLinkField", name: "sender", isMultiple: false, isSortable: false, id: "Notification.sender", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee"])};
        export const size = {contentType: "IntegerField" as "IntegerField", name: "size", isMultiple: false, isSortable: false, id: "Notification.size", defaultValue: null as IntegerField["defaultValue"]};
        export const subject = {contentType: "RefLinkField" as "RefLinkField", name: "subject", isMultiple: false, isSortable: false, id: "Notification.subject", refContentType: H.List(["BaseEntity"])};
        export const isActive = {contentType: "BoolField" as "BoolField", name: "isActive", isMultiple: false, isSortable: false, id: "Notification.isActive", defaultValue: null as BoolField["defaultValue"]};
        export const isMention = {contentType: "BoolField" as "BoolField", name: "isMention", isMultiple: false, isSortable: false, id: "Notification.isMention", defaultValue: null as BoolField["defaultValue"]};
        export const isHistoryLog = {contentType: "BoolField" as "BoolField", name: "isHistoryLog", isMultiple: false, isSortable: false, id: "Notification.isHistoryLog", defaultValue: null as BoolField["defaultValue"]};
    }
}

export function isNotification(arg: any): arg is Notification {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Notification"
}
