module.exports = function(locale, cb) {
    switch (locale) {
        case "az":
            require.ensure([], function(require) { cb(require("!!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/globalize-config-loader/lib/compiler.js?sync=false&locale=az!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/json-loader/index.js!/opt/buildagent/work/b10a8ca1892374f8/front/.build/extensions/extract-globalize-formatters-loader/index.js!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/thread-loader/dist/cjs.js??ref--4-0!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/babel-loader/lib/index.js??ref--4-1!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/ts-loader/index.js??ref--4-2!/opt/buildagent/work/b10a8ca1892374f8/front/src/web/lib/utils/intl/formatters.ts")); });
            break;
        case "be":
            require.ensure([], function(require) { cb(require("!!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/globalize-config-loader/lib/compiler.js?sync=false&locale=be!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/json-loader/index.js!/opt/buildagent/work/b10a8ca1892374f8/front/.build/extensions/extract-globalize-formatters-loader/index.js!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/thread-loader/dist/cjs.js??ref--4-0!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/babel-loader/lib/index.js??ref--4-1!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/ts-loader/index.js??ref--4-2!/opt/buildagent/work/b10a8ca1892374f8/front/src/web/lib/utils/intl/formatters.ts")); });
            break;
        case "cs":
            require.ensure([], function(require) { cb(require("!!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/globalize-config-loader/lib/compiler.js?sync=false&locale=cs!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/json-loader/index.js!/opt/buildagent/work/b10a8ca1892374f8/front/.build/extensions/extract-globalize-formatters-loader/index.js!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/thread-loader/dist/cjs.js??ref--4-0!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/babel-loader/lib/index.js??ref--4-1!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/ts-loader/index.js??ref--4-2!/opt/buildagent/work/b10a8ca1892374f8/front/src/web/lib/utils/intl/formatters.ts")); });
            break;
        case "de":
            require.ensure([], function(require) { cb(require("!!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/globalize-config-loader/lib/compiler.js?sync=false&locale=de!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/json-loader/index.js!/opt/buildagent/work/b10a8ca1892374f8/front/.build/extensions/extract-globalize-formatters-loader/index.js!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/thread-loader/dist/cjs.js??ref--4-0!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/babel-loader/lib/index.js??ref--4-1!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/ts-loader/index.js??ref--4-2!/opt/buildagent/work/b10a8ca1892374f8/front/src/web/lib/utils/intl/formatters.ts")); });
            break;
        case "en":
            require.ensure([], function(require) { cb(require("!!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/globalize-config-loader/lib/compiler.js?sync=false&locale=en!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/json-loader/index.js!/opt/buildagent/work/b10a8ca1892374f8/front/.build/extensions/extract-globalize-formatters-loader/index.js!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/thread-loader/dist/cjs.js??ref--4-0!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/babel-loader/lib/index.js??ref--4-1!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/ts-loader/index.js??ref--4-2!/opt/buildagent/work/b10a8ca1892374f8/front/src/web/lib/utils/intl/formatters.ts")); });
            break;
        case "fr":
            require.ensure([], function(require) { cb(require("!!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/globalize-config-loader/lib/compiler.js?sync=false&locale=fr!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/json-loader/index.js!/opt/buildagent/work/b10a8ca1892374f8/front/.build/extensions/extract-globalize-formatters-loader/index.js!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/thread-loader/dist/cjs.js??ref--4-0!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/babel-loader/lib/index.js??ref--4-1!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/ts-loader/index.js??ref--4-2!/opt/buildagent/work/b10a8ca1892374f8/front/src/web/lib/utils/intl/formatters.ts")); });
            break;
        case "id":
            require.ensure([], function(require) { cb(require("!!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/globalize-config-loader/lib/compiler.js?sync=false&locale=id!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/json-loader/index.js!/opt/buildagent/work/b10a8ca1892374f8/front/.build/extensions/extract-globalize-formatters-loader/index.js!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/thread-loader/dist/cjs.js??ref--4-0!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/babel-loader/lib/index.js??ref--4-1!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/ts-loader/index.js??ref--4-2!/opt/buildagent/work/b10a8ca1892374f8/front/src/web/lib/utils/intl/formatters.ts")); });
            break;
        case "kk":
            require.ensure([], function(require) { cb(require("!!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/globalize-config-loader/lib/compiler.js?sync=false&locale=kk!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/json-loader/index.js!/opt/buildagent/work/b10a8ca1892374f8/front/.build/extensions/extract-globalize-formatters-loader/index.js!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/thread-loader/dist/cjs.js??ref--4-0!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/babel-loader/lib/index.js??ref--4-1!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/ts-loader/index.js??ref--4-2!/opt/buildagent/work/b10a8ca1892374f8/front/src/web/lib/utils/intl/formatters.ts")); });
            break;
        case "ru":
            require.ensure([], function(require) { cb(require("!!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/globalize-config-loader/lib/compiler.js?sync=false&locale=ru!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/json-loader/index.js!/opt/buildagent/work/b10a8ca1892374f8/front/.build/extensions/extract-globalize-formatters-loader/index.js!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/thread-loader/dist/cjs.js??ref--4-0!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/babel-loader/lib/index.js??ref--4-1!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/ts-loader/index.js??ref--4-2!/opt/buildagent/work/b10a8ca1892374f8/front/src/web/lib/utils/intl/formatters.ts")); });
            break;
        case "tr":
            require.ensure([], function(require) { cb(require("!!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/globalize-config-loader/lib/compiler.js?sync=false&locale=tr!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/json-loader/index.js!/opt/buildagent/work/b10a8ca1892374f8/front/.build/extensions/extract-globalize-formatters-loader/index.js!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/thread-loader/dist/cjs.js??ref--4-0!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/babel-loader/lib/index.js??ref--4-1!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/ts-loader/index.js??ref--4-2!/opt/buildagent/work/b10a8ca1892374f8/front/src/web/lib/utils/intl/formatters.ts")); });
            break;
        case "uk":
            require.ensure([], function(require) { cb(require("!!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/globalize-config-loader/lib/compiler.js?sync=false&locale=uk!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/json-loader/index.js!/opt/buildagent/work/b10a8ca1892374f8/front/.build/extensions/extract-globalize-formatters-loader/index.js!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/thread-loader/dist/cjs.js??ref--4-0!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/babel-loader/lib/index.js??ref--4-1!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/ts-loader/index.js??ref--4-2!/opt/buildagent/work/b10a8ca1892374f8/front/src/web/lib/utils/intl/formatters.ts")); });
            break;
        case "vi":
            require.ensure([], function(require) { cb(require("!!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/globalize-config-loader/lib/compiler.js?sync=false&locale=vi!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/json-loader/index.js!/opt/buildagent/work/b10a8ca1892374f8/front/.build/extensions/extract-globalize-formatters-loader/index.js!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/thread-loader/dist/cjs.js??ref--4-0!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/babel-loader/lib/index.js??ref--4-1!/opt/buildagent/work/b10a8ca1892374f8/front/node_modules/ts-loader/index.js??ref--4-2!/opt/buildagent/work/b10a8ca1892374f8/front/src/web/lib/utils/intl/formatters.ts")); });
            break;
    default:
        throw new Error("Cannot find module " + locale + ".");
    }
}