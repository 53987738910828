/* tslint:disable */
import * as H from "../metadataHelper"
import {StringField} from "./stringField"

export interface Variable extends H.BaseValue {
    name?: string;
    value?: string;
    contentType: "Variable"; // Object type
}

export module Variable {
    export const contentType: "Variable" = "Variable";
    export const endpoint = "/api/v3/variable";

    export const newObject: Variable = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "Variable.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const value = {contentType: "StringField" as "StringField", name: "value", isMultiple: false, isSortable: false, id: "Variable.value", defaultValue: null as StringField["defaultValue"], maxLength: 0};
    }
}

export function isVariable(arg: any): arg is Variable {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Variable"
}
