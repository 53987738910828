import * as React from "react"
import {observer} from "mobx-react"
import {inject} from "src/lib/utils/inject"
import * as Api from "src/lib/entities/api"
import {CSpinner, Component} from "src/lib/components"
import {Some, None} from "src/lib/utils/option"
import {EntityReadyCallback, UpdateEntityFunction} from "./types"


// Smart component to fetch and show full entity

export module CEntity {
    export interface Props {
        contentType: Api.ContentType
        id: Api.Id
        onRenderComplete?: () => void
        loader?: JSX.Element
        endpoint?: string
        needFullEntity?: boolean
        asyncUpdate?: boolean
    }
}
interface CEntityProps extends CEntity.Props {
    readyCallback: EntityReadyCallback<any>
}

function errorRenderHandler(): null {
    console.error(new Error("CEntity: Failed to render the component with an error"))
    return null
}

@observer
export class CEntity extends Component<CEntityProps, {}> {

    public static defaultProps: any = {
        needFullEntity: false
    };

    @inject(Api.Store)
    private apiStore: Api.Store

    private loadEntityIfNeeded() {
        const fetchState = this.getFetchState()
        if (fetchState.isNone() && (!this.props.needFullEntity || !this.apiStore.getEntity(Api.getLink(this.props), null))) {
            void this.apiStore.fetchFullEntity({contentType: this.props.contentType, id: this.props.id}, this.props.endpoint)
        }

        if ((fetchState.isCompleted() && this.props.asyncUpdate)) {
            const endpoint = this.props.endpoint || Api.getEntityTypeEndpoint(this.props.contentType)
            this.apiStore.fetchEntities(`${endpoint}/${this.props.id}`)
        }
    }

    /**
     * Триггер загрузки сущности при появлении компонента в доме
     */
    componentWillMount() {
        this.loadEntityIfNeeded()
    }

    componentDidUpdate(prevProps: CEntityProps) {
        if (prevProps.contentType !== this.props.contentType || prevProps.id !== this.props.id) {
            this.loadEntityIfNeeded()
        }
        if (this.props.onRenderComplete) {
            this.props.onRenderComplete()
        }
    }

    componentDidMount() {
        if (this.props.onRenderComplete) {
            this.props.onRenderComplete()
        }
    }

    /**
     * Обновление сущности
     * @param entity
     */
    private update: UpdateEntityFunction<Api.BaseEntity> = entity =>
        this.apiStore.update(entity).then(this.getEntity)

    /**
     * Удаление сущности
     */
    private delete = () => this.apiStore.deleteEntity(this.getEntity())

    private getEntity = () => this.apiStore.getEntity({
        contentType: this.props.contentType,
        id: this.props.id
    })

    /**
     * Достаёт состояние загрузки текущей сущности.
     */
    private getFetchState = () => Api.getEntityFetchState(
        this.getEntity(),
        Api.FETCH_FULL_NAME
    )

    public render() {
        const fetchState = this.getFetchState();
        if (fetchState.isPending() || fetchState.isNone()) {
            if (!this.props.needFullEntity && this.props.contentType !== Api.UserSetting.contentType) {
                const entity = this.apiStore.getEntity(Api.getLink(this.props), null);
                if (null !== entity) {
                    return this.props.readyCallback({
                        entity: Some(entity),
                        update: this.update,
                        delete: this.delete
                    }).getOrElse(errorRenderHandler);
                }
            }
            return this.props.loader !== void 0 ? this.props.loader : <CSpinner />;
        }
        if (fetchState.isCompleted()) {
            return this.props.readyCallback({
                entity: Some(this.getEntity()),
                update: this.update,
                delete: this.delete
            }).getOrElse(errorRenderHandler)
        }
        if (fetchState.isError()) {
            return this.props.readyCallback({
                entity: None,
                update: this.update,
                delete: this.delete
            }).getOrElse(errorRenderHandler)
        }

        return null;
    }
}
