import {Component} from "src/lib/components"
import * as React from "react"
import classNames from "classnames/bind"
import {observer} from "mobx-react"
import {observable, action} from "mobx"
import {makeRefHandler} from "src/lib/utils/func"

const style = classNames.bind(require("./CEllipsis.styl"))

/**
 * сокращает текст до одной строки, остальное скрывает за многоточием. Полный текст - во всплывающей подсказке.
 */

namespace CEllipsis {
    export interface Props {
        linkColor?: Boolean
        showHint?: boolean
        customHeight?: number
    }
}

@observer
export class CEllipsis extends Component<CEllipsis.Props, {}> {

    public static defaultProps = {
        showHint: true
    }

    public contentElement: HTMLElement
    public contentChild: HTMLElement

    @observable
    public textContent: string

    componentDidMount() {
        const {customHeight} = this.props

        this.setTextContent()

        if (customHeight) {
            this.contentElement.style.height = `${customHeight}px`
            this.contentChild.style.lineHeight = `${customHeight}px`
        }
    }

    componentDidUpdate() {
        this.setTextContent()
    }

    @action
    private setTextContent = () => {
        this.textContent = this.contentElement.textContent.trim()
    }

    public render() {
        const children = this.props.children
        //усложненеие структуры и css для  работы элемента в ячейках таблиц
        // data-text используется в css для расчёта ширины блока - всегда должен сотвествовать текстовому содержимому
        return <div
                className={style("CEllipsis", {"linkColor": Boolean(this.props.linkColor)})}
                data-text={this.textContent}
                title={this.props.showHint ? this.textContent : void 0}
                ref={makeRefHandler(this, "contentElement")}
            >
            <div className={style("content")} ref={makeRefHandler(this, "contentChild")}>
                {children}
            </div>
        </div>
    }
}
