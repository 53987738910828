import * as React from "react"
import {observer} from "mobx-react"
import {autobind} from "core-decorators"
import {inject} from "src/lib/utils/inject"
import {CIcon, Component} from "src/lib/components"
import {UserStore} from "src/lib/entities/api"
import {CExtendedConfirmModal} from "src/lib/components/CConfirmModal/CExtendedConfirmModal"
import {Intl} from "src/lib/utils/intl/Intl"
import classNames from "classnames/bind"
import {track} from "src/lib/tracking"
import {CPromo} from "src/bums/common/promo/CPromoProps"
import {ChatWidgetStore} from "src/bums/common/chat/stores/ChatWidgetStore"

const messages = require("../messages.yml")
const style = classNames.bind(require("./theme/CPromoPopup.styl"))
const videochatImg = require("./theme/img_videochat.png")

const PROMO_EVENT_TYPE = "Promo"
const VIDEO_POPUP_CLICK_EVENT = "Video Popup Click"
const VIDEO_POPUP_DISMISS_EVENT = "Video Popup Dismiss"

export namespace CPromoPopup {
    export interface Props extends CPromo.CPromoProps<null> {
    }
}

@track({eventType: PROMO_EVENT_TYPE, channels: ["product", "amplitude"]})
@observer
@autobind
export class CPromoPopup extends Component<CPromoPopup.Props, {}> {

    @inject(Intl)
    private intl: Intl

    @inject(UserStore)
    private userStore: UserStore

    @inject(ChatWidgetStore)
    private chatWidgetStore: ChatWidgetStore

    @track({event: VIDEO_POPUP_DISMISS_EVENT})
    private onClose() {
        this.props.actionDone()
    }

    @track({event: VIDEO_POPUP_CLICK_EVENT})
    private onConfirm() {
        this.props.actionDone()
        void this.chatWidgetStore.topicOpen(this.userStore.user)
    }

    private renderAdditionalContent() {
        return <div className={style("additionalContent")}>
            <img src={videochatImg}/>
        </div>
    }

    private renderDescription() {
        return <React.Fragment>
            {this.intl.formatMessage(messages["videoChatDescription"])}
            <CIcon
                type={CIcon.Type.VIDEOCAM}
                className={style("videocamIcon")}
            />
        </React.Fragment>
    }

    render() {
        return <CExtendedConfirmModal
            open
            header={this.intl.formatMessage(messages["meetVideoChat"])}
            description={this.renderDescription()}
            confirmButtonCaption={this.intl.formatMessage(messages["tryNow"])}
            cancelButtonCaption={this.intl.formatMessage(messages["tryLater"])}
            additionalContent={this.renderAdditionalContent()}
            onConfirm={this.onConfirm}
            onCancel={this.onClose}
        />
    }
}
