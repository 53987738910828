import {Component} from "src/lib/components"
import {HotKeyManager, KeyMapItem, HandlerBag} from "./HotKeyManager"
import {inject} from "src/lib/utils/inject"

export interface CHotKeyProps {
    keyMap: KeyMapItem | KeyMapItem[]
    stack?: boolean
}

/**
 * e.g. <CHotKey keyMap={["alt", "t", this.openForm]}/>
 *
 * CHotKey - компонент для привязки глобальных нажатий клавиатуры
 * Хранит единый реестр "горячих клавиш", сообщает о конфликтах,
 * Автоматически привязывает и отвязывает eventListeners
 *
 * @param replace - если true то в случае конфликта не будет кидать exception,
 * а заместит текущий обработчик на время. Актуально для ESC.
 */

export class CHotKey extends Component<CHotKeyProps, {}> {

    @inject(HotKeyManager)
    private hotKeyManager: HotKeyManager
    private handlers: HandlerBag[]

    componentDidUpdate(): void {
        this.hotKeyManager.removeKeyHandler(this.handlers)
        this.handlers = this.hotKeyManager.addKeyHandler(this.props.keyMap, this.props.stack)
    }

    componentDidMount(): void {
        this.handlers = this.hotKeyManager.addKeyHandler(this.props.keyMap, this.props.stack)
    }

    componentWillUnmount(): void {
        this.hotKeyManager.removeKeyHandler(this.handlers)
    }

    render(): JSX.Element | null {
        return null
    }
}
