import * as React from "react"
import {observer} from "mobx-react"
import {autobind} from "core-decorators"
import {computed} from "mobx"
import {
    CButton, CCopyToClipboardButton, CCustomScrollbars,
    CEllipsis, CFieldRow,
    CFlex, CIcon, CIconType,
    CLink,
    Component,
    CPagerButton,
    CSmartCard,
    CSpinner,
    CStyledContent, CUser
} from "src/lib/components"
import {bindArg} from "src/lib/utils/func"
import {inject} from "src/lib/utils/inject"
import {InviteLinkStore} from "src/bums/common/onboarding/tutorial/CInviteTeam/InviteLinkStore"
import {INVITE_EMPLOYEE_LINK, INVITED_EMPLOYEES_LIMIT} from "src/bums/employee/utils"
import {CButtonType} from "src/lib/components/CButton/CButton"
import CRadioSelect from "src/lib/components/CRadioSelect/CRadioSelect"
import {Intl} from "src/lib/utils/intl/Intl"
import {Size} from "src/lib/types"
import * as Collections from "src/lib/collections"
import classNames from "classnames/bind"

const style = classNames.bind(require("./styles/CInviteLinkModal.styl"))

const messages = require("../messages.yml")
const filterMessages = require("../../../filter/messages.yml")
const libMessages = require("src/lib/messages.yml")

export namespace CInviteLinkForm {
    export interface Props {
        closeAndSaveFormBtn?: JSX.Element
    }
}

@observer
@autobind
export class CInviteLinkForm extends Component<CInviteLinkForm.Props, {}> {

    @inject(InviteLinkStore)
    private store: InviteLinkStore

    @inject(Intl)
    private intl: Intl

    @computed
    private get accessOptions() {
        return Collections.List([
            {key: 1, value: this.intl.formatMessage(filterMessages["on"])},
            {key: 0, value: this.intl.formatMessage(filterMessages["off"])},
        ])
    }

    @computed
    private get nextLoadCount() {
        const employees = this.store.invitedEmployees
        return Math.min((employees.totalItemsCount || 0) - employees.originalItems.length, INVITED_EMPLOYEES_LIMIT)
    }

    private async switchAccess(value: 1 | 0) {
        await this.store.switchEnabledLink(!!value)
    }

    private renderContent() {
        const {isEnabled, inviteLink} = this.store
        const link = `${window.location.origin}${INVITE_EMPLOYEE_LINK}${inviteLink.uuid}`

        return <React.Fragment>
            <CSmartCard.Header>
                <CFlex justify="between" width="auto" className={style("fullWidth")}>
                    <CFieldRow displayType="inlineItemSmMargin">
                        <CFieldRow.Element align="middle">
                            {this.intl.formatMessage(messages["inviteLink"])}
                        </CFieldRow.Element>
                    </CFieldRow>
                    <CFlex.Item rightShift>
                        {this.props.closeAndSaveFormBtn}
                    </CFlex.Item>
                </CFlex>
            </CSmartCard.Header>
            <CSmartCard.Content className={style("cardContent")}>
                <CFieldRow align="middle">
                    <CStyledContent className={style("contentBlockDescription")}>
                        {this.intl.formatMessage(messages["accessSwitcher"])}
                    </CStyledContent>
                    <CRadioSelect
                        optionClassName={style("linkEnabledSelector")}
                        options={this.accessOptions}
                        value={+isEnabled}
                        onChange={this.switchAccess}
                        inline
                    />
                </CFieldRow>
                <CFieldRow align="middle" width="full">
                    <CStyledContent className={style("contentBlockDescription")}>
                        {this.intl.formatMessage(messages["link"])}
                    </CStyledContent>
                    <CFlex justify="between" width="auto" className={style("fullWidth")}>
                        <CFieldRow displayType="inlineItemSmMargin">
                            <CLink
                                to={isEnabled && `${INVITE_EMPLOYEE_LINK}${inviteLink.uuid}`}
                                className={style("inviteLink", {"inactiveLink": !isEnabled})}
                            >
                                <CEllipsis>{link}</CEllipsis>
                            </CLink>
                        </CFieldRow>
                        <CFlex.Item rightShift noShrink>
                            <CButton
                                name="refreshUuid"
                                icon={CIconType.CHANGE}
                                type={CButtonType.FLAT}
                                disabled={!isEnabled}
                                onClick={this.store.refreshUuid}
                                title={this.intl.formatMessage(messages["refreshUuid"])}
                            />
                            <CCopyToClipboardButton
                                contentToCopy={isEnabled && link}
                                disabled={!isEnabled}
                                size="normal"
                            />
                        </CFlex.Item>
                    </CFlex>
                </CFieldRow>
                <CStyledContent className={style("contentBlockDescription", "employeesBlock")}>
                    {this.intl.formatMessage(messages["invitationAccepted"])}
                </CStyledContent>
                {this.renderInvitedEmployees()}
            </CSmartCard.Content>
        </React.Fragment>
    }

    private renderInvitedEmployees() {
        if (!this.store.inviteLink.employeesCount) {
            return <CStyledContent>
                {this.intl.formatMessage(messages["invitationAcceptedCount"], {count: 0})}
            </CStyledContent>
        }
        const {invitedEmployees} = this.store
        if (!invitedEmployees.isCompleted) {
            return <CSpinner/>
        }

        return <CFlex wrap className={style("invitedEmployeesList")}>
            <CCustomScrollbars customScrollProps={{autoHeight: true, autoHeightMax: 240, autoHeightMin: 240}}>
                {invitedEmployees.originalItems.map((employee, index) => {
                    const buttonAction: string = employee.canLogin ? "block" : "unblock"
                    return <CFlex width="auto" className={style("employeeWrapper")}>
                        <CUser
                            key={index}
                            sizeImg={Size.MEDIUM}
                            entity={employee}
                            className={style("employee", {"inactiveEmployee": !employee.canLogin})}
                        />
                        <div
                            className={style("employeeButton")}
                            onClick={bindArg(this.store.employeeAction, employee, buttonAction)}
                            data-title={this.intl.formatMessage(messages[buttonAction])}
                        >
                            <CIcon
                                type={employee.canLogin ? CIcon.Type.UNLINK : CIcon.Type.LINK}
                                className={style("employeeButtonIcon")}
                            />
                        </div>
                    </CFlex>
                })}
            </CCustomScrollbars>
        </CFlex>
    }

    render() {
        if (!this.store.inviteLink) {
            void this.store.tryGetInviteLink()
        }

        return <CSmartCard className={style("inviteCard")}>
            {this.store.isLoading ? <CSpinner/> : this.renderContent()}
            {
                this.store.invitedEmployees.hasMoreNext
                && <CPagerButton
                    isLoading={this.store.invitedEmployees.loadStateNext.isPending()}
                    onLoad={bindArg(this.store.invitedEmployees.loadNext, this.nextLoadCount)}
                    text={this.intl.formatMessage(libMessages["showMoreWithCount"], {count: this.nextLoadCount})}
                    noOffset
                    className={style("pagerButton")}
                />
            }
        </CSmartCard>
    }
}
