/* tslint:disable */
import * as H from "../metadataHelper"
import {ContractorHuman} from "./contractorHuman"
import {ContractorType} from "./contractorType"
import {Employee} from "./employee"
import {Group} from "./group"
import {Address} from "./address"
import {ContactInfo} from "./contactInfo"
import {DateOnly} from "./dateOnly"
import {ContractorAdvertisingWay} from "./contractorAdvertisingWay"
import {ContractorActivityType} from "./contractorActivityType"
import {ContractorStatus} from "./contractorStatus"
import {Payer} from "./payer"
import {File} from "./file"
import {RelationLink} from "./relationLink"
import {Todo} from "./todo"
import {Money} from "./money"
import {Comment} from "./comment"
import {FileGroup} from "./fileGroup"
import {AttachesInfo} from "./attachesInfo"
import {Tag} from "./tag"
import {IntegerField} from "./integerField"
import {StringField} from "./stringField"
import {FloatField} from "./floatField"
import {DateField} from "./dateField"
import {EnumField} from "./enumField"
import {BoolField} from "./boolField"
import {DateTimeField} from "./dateTimeField"
import {MoneyField} from "./moneyField"

export interface ContractorCompany extends H.BaseEntity {
    [fieldName: string]: any;
    contacts?: H.List<ContractorHuman>; // Массив клиентов-физических лиц
    contactsCount?: number; // Количество клиентов-физических лиц
    position?: string; // Должность
    name?: string; // Название
    promisingRate?: number; // Перспективность
    type?: ContractorType;
    responsibles?: H.List<ContractorCompany | ContractorHuman | Employee | Group>;
    responsiblesCount?: number;
    contactInfo?: H.List<Address | ContactInfo>;
    contactInfoCount?: number;
    description?: string;
    textDescription?: string;
    birthday?: DateOnly;
    preferTransport?: ContractorCompany.PreferTransport;
    advertisingWay?: ContractorAdvertisingWay;
    activityType?: ContractorActivityType;
    status?: ContractorStatus;
    partners?: H.List<ContractorCompany | ContractorHuman>;
    partnersCount?: number;
    competitors?: H.List<ContractorCompany | ContractorHuman>;
    competitorsCount?: number;
    payers?: H.List<Payer>;
    payersCount?: number;
    isPublic?: boolean;
    attaches?: H.List<File>;
    attachesCount?: number;
    links?: H.List<RelationLink>;
    linksCount?: number;
    relationLinks?: H.List<RelationLink>;
    relationLinksCount?: number;
    timeCreated?: Date;
    timeUpdated?: Date;
    userCreated?: ContractorCompany|ContractorHuman|Employee;
    userUpdated?: ContractorCompany|ContractorHuman|Employee;
    nextCommunicationDate?: DateOnly|Date;
    nextCommunication?: Todo;
    lastCommunicationDate?: DateOnly|Date;
    countCommunications?: number;
    countScheduledCommunications?: number;
    countOverdueCommunications?: number;
    lastDealDate?: Date;
    countDeals?: number;
    countActiveDeals?: number;
    countPositiveDeals?: number;
    summDeals?: Money;
    summActiveDeals?: Money;
    summPositiveDeals?: Money;
    countInvoice?: number;
    countNotPaidInvoice?: number;
    summInvoice?: Money;
    summNotPaidInvoice?: Money;
    summAccountReceivable?: Money;
    summAccountReceivableOverdue?: Money;
    totalIssuesCount?: number;
    totalActiveIssuesCount?: number;
    totalInactiveIssuesCount?: number;
    totalAttachesCount?: number;
    lastComment?: Comment;
    lastCommentTimeCreated?: Date;
    balance?: Money;
    nextTaskDate?: Date;
    canSeeFull?: boolean;
    possibleActions?: H.List<ContractorCompany.PossibleActions>; // Список возможных действий
    isDropped?: boolean; // Удален ли клиент
    isFavorite?: boolean; // Is it favorite for current user?
    tasksCount?: number;
    actualTasksCount?: number;
    countBalance?: number;
    messagesCount?: number;
    callsCount?: number;
    humanNumber?: number;
    actualTodosCount?: number; // Количество актуальных дел
    finishedTodosCount?: number; // Количество завершенных дел
    comments?: H.List<Comment>; // Массив комментариев
    commentsCount?: number; // Количество комментариев
    unreadCommentsCount?: number; // Количество непрочитанных комментариев
    attachesCountInComments?: number; // Количество вложений в комментариях
    subscribed?: boolean; // Подписан ли текущий пользователь на комментарии по этой сущности
    allFiles?: H.List<File | FileGroup>; // Массив файлов
    allFilesCount?: number; // Количество файлов
    attachesInfo?: AttachesInfo; // Информация о вложении
    commentsWithoutTransportCount?: number; // Количество комментариев без транспорта
    emailsCount?: number; // Количество емейлов
    whatsappCount?: number; // Количество сообщений whatsapp
    telegramCount?: number; // Количество сообщений telegram
    instagramCount?: number; // Количество сообщений instagram
    id?: string; // Идентификатор
    todos?: H.List<Todo>; // Массив дел
    todosCount?: number; // Количество дел
    avatar?: File;
    photo?: File;
    possibleSubscribers?: H.List<ContractorCompany | ContractorHuman | Employee>; // Пользователи-участники сущности, которые могут получать уведомления по ней.
    possibleSubscribersCount?: number; // Количество возможных подписчиков
    subscribers?: H.List<ContractorCompany | ContractorHuman | Employee>; // Пользователи-участники, которые подписаны на уведомления по сущности.
    subscribersCount?: number; // Количество подписчиков
    hiddenCommentsCount?: number; // Количество скрытых комментариев
    isUnread?: boolean; // Помечен как непрочитанный
    firstUnreadComment?: Comment; // Первый непрочитанный комментарий
    unreadAnswer?: boolean; // Есть ли непрочитанный ответ или упоминание
    lastView?: Date; // Дата последнего просмотра
    tags?: H.List<Tag>; // Метки
    tagsCount?: number; // Количество меток
    reminderTime?: Date; // Дата напоминания
    contentType: "ContractorCompany"; // Object type
}

export module ContractorCompany {
    export const contentType: "ContractorCompany" = "ContractorCompany";
    export const endpoint = "/api/v3/contractorCompany";
    export type PreferTransport = "email"|"facebook"|"informer"|"jabber"|"skype"|"sms"|"twitter";
    export module PreferTransport {
        export const email: PreferTransport = "email";
        export const facebook: PreferTransport = "facebook";
        export const informer: PreferTransport = "informer";
        export const jabber: PreferTransport = "jabber";
        export const skype: PreferTransport = "skype";
        export const sms: PreferTransport = "sms";
        export const twitter: PreferTransport = "twitter";
    }
    export type PossibleActions = "act_attaches"|
        "act_delete"|
        "act_edit"|
        "act_fake_drop"|
        "act_invite"|
        "act_read"|
        "act_restore"|
        "act_view_fin_operation"|
        "add_deal"|
        "add_item"|
        "message"|
        "read_full"|
        "task";
    export module PossibleActions {
        export const act_attaches: PossibleActions = "act_attaches";
        export const act_delete: PossibleActions = "act_delete";
        export const act_edit: PossibleActions = "act_edit";
        export const act_fake_drop: PossibleActions = "act_fake_drop";
        export const act_invite: PossibleActions = "act_invite";
        export const act_read: PossibleActions = "act_read";
        export const act_restore: PossibleActions = "act_restore";
        export const act_view_fin_operation: PossibleActions = "act_view_fin_operation";
        export const add_deal: PossibleActions = "add_deal";
        export const add_item: PossibleActions = "add_item";
        export const message: PossibleActions = "message";
        export const read_full: PossibleActions = "read_full";
        export const task: PossibleActions = "task";
    }
    export const newObject: ContractorCompany = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const contacts = {contentType: "RefLinkField" as "RefLinkField", name: "contacts", isMultiple: true, isSortable: false, id: "ContractorCompany.contacts", refContentType: H.List(["ContractorHuman"])};
        export const contactsCount = {contentType: "IntegerField" as "IntegerField", name: "contactsCount", isMultiple: false, isSortable: false, id: "ContractorCompany.contactsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const position = {contentType: "StringField" as "StringField", name: "position", isMultiple: false, isSortable: true, id: "ContractorCompany.position", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "ContractorCompany.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const promisingRate = {contentType: "FloatField" as "FloatField", name: "promisingRate", isMultiple: false, isSortable: false, id: "ContractorCompany.promisingRate", defaultValue: null as FloatField["defaultValue"]};
        export const type = {contentType: "RefLinkField" as "RefLinkField", name: "type", isMultiple: false, isSortable: true, id: "ContractorCompany.type", refContentType: H.List(["ContractorType"])};
        export const responsibles = {contentType: "RefLinkField" as "RefLinkField", name: "responsibles", isMultiple: true, isSortable: true, id: "ContractorCompany.responsibles", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee", "Group"])};
        export const responsiblesCount = {contentType: "IntegerField" as "IntegerField", name: "responsiblesCount", isMultiple: false, isSortable: false, id: "ContractorCompany.responsiblesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const contactInfo = {contentType: "RefLinkField" as "RefLinkField", name: "contactInfo", isMultiple: true, isSortable: false, id: "ContractorCompany.contactInfo", refContentType: H.List(["Address", "ContactInfo"])};
        export const contactInfoCount = {contentType: "IntegerField" as "IntegerField", name: "contactInfoCount", isMultiple: false, isSortable: false, id: "ContractorCompany.contactInfoCount", defaultValue: null as IntegerField["defaultValue"]};
        export const description = {contentType: "StringField" as "StringField", name: "description", isMultiple: false, isSortable: false, id: "ContractorCompany.description", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const textDescription = {contentType: "StringField" as "StringField", name: "textDescription", isMultiple: false, isSortable: false, id: "ContractorCompany.textDescription", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const birthday = {contentType: "DateField" as "DateField", name: "birthday", isMultiple: false, isSortable: true, id: "ContractorCompany.birthday", defaultValue: null as DateField["defaultValue"], refContentType: H.List(["DateOnly"])};
        export const preferTransport = {contentType: "EnumField" as "EnumField", name: "preferTransport", isMultiple: false, isSortable: true, id: "ContractorCompany.preferTransport", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["email", "facebook", "informer", "jabber", "skype", "sms", "twitter"])};
        export const advertisingWay = {contentType: "RefLinkField" as "RefLinkField", name: "advertisingWay", isMultiple: false, isSortable: true, id: "ContractorCompany.advertisingWay", refContentType: H.List(["ContractorAdvertisingWay"])};
        export const activityType = {contentType: "RefLinkField" as "RefLinkField", name: "activityType", isMultiple: false, isSortable: true, id: "ContractorCompany.activityType", refContentType: H.List(["ContractorActivityType"])};
        export const status = {contentType: "RefLinkField" as "RefLinkField", name: "status", isMultiple: false, isSortable: true, id: "ContractorCompany.status", refContentType: H.List(["ContractorStatus"])};
        export const partners = {contentType: "RefLinkField" as "RefLinkField", name: "partners", isMultiple: true, isSortable: true, id: "ContractorCompany.partners", refContentType: H.List(["ContractorCompany", "ContractorHuman"])};
        export const partnersCount = {contentType: "IntegerField" as "IntegerField", name: "partnersCount", isMultiple: false, isSortable: false, id: "ContractorCompany.partnersCount", defaultValue: null as IntegerField["defaultValue"]};
        export const competitors = {contentType: "RefLinkField" as "RefLinkField", name: "competitors", isMultiple: true, isSortable: true, id: "ContractorCompany.competitors", refContentType: H.List(["ContractorCompany", "ContractorHuman"])};
        export const competitorsCount = {contentType: "IntegerField" as "IntegerField", name: "competitorsCount", isMultiple: false, isSortable: false, id: "ContractorCompany.competitorsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const payers = {contentType: "RefLinkField" as "RefLinkField", name: "payers", isMultiple: true, isSortable: false, id: "ContractorCompany.payers", refContentType: H.List(["Payer"])};
        export const payersCount = {contentType: "IntegerField" as "IntegerField", name: "payersCount", isMultiple: false, isSortable: false, id: "ContractorCompany.payersCount", defaultValue: null as IntegerField["defaultValue"]};
        export const isPublic = {contentType: "BoolField" as "BoolField", name: "isPublic", isMultiple: false, isSortable: true, id: "ContractorCompany.isPublic", defaultValue: null as BoolField["defaultValue"]};
        export const attaches = {contentType: "RefLinkField" as "RefLinkField", name: "attaches", isMultiple: true, isSortable: false, id: "ContractorCompany.attaches", refContentType: H.List(["File"])};
        export const attachesCount = {contentType: "IntegerField" as "IntegerField", name: "attachesCount", isMultiple: false, isSortable: false, id: "ContractorCompany.attachesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const links = {contentType: "RefLinkField" as "RefLinkField", name: "links", isMultiple: true, isSortable: false, id: "ContractorCompany.links", refContentType: H.List(["RelationLink"])};
        export const linksCount = {contentType: "IntegerField" as "IntegerField", name: "linksCount", isMultiple: false, isSortable: false, id: "ContractorCompany.linksCount", defaultValue: null as IntegerField["defaultValue"]};
        export const relationLinks = {contentType: "RefLinkField" as "RefLinkField", name: "relationLinks", isMultiple: true, isSortable: false, id: "ContractorCompany.relationLinks", refContentType: H.List(["RelationLink"])};
        export const relationLinksCount = {contentType: "IntegerField" as "IntegerField", name: "relationLinksCount", isMultiple: false, isSortable: false, id: "ContractorCompany.relationLinksCount", defaultValue: null as IntegerField["defaultValue"]};
        export const timeCreated = {contentType: "DateTimeField" as "DateTimeField", name: "timeCreated", isMultiple: false, isSortable: true, id: "ContractorCompany.timeCreated", defaultValue: null as DateTimeField["defaultValue"]};
        export const timeUpdated = {contentType: "DateTimeField" as "DateTimeField", name: "timeUpdated", isMultiple: false, isSortable: true, id: "ContractorCompany.timeUpdated", defaultValue: null as DateTimeField["defaultValue"]};
        export const userCreated = {contentType: "RefLinkField" as "RefLinkField", name: "userCreated", isMultiple: false, isSortable: false, id: "ContractorCompany.userCreated", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee"])};
        export const userUpdated = {contentType: "RefLinkField" as "RefLinkField", name: "userUpdated", isMultiple: false, isSortable: false, id: "ContractorCompany.userUpdated", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee"])};
        export const nextCommunicationDate = {contentType: "RefLinkField" as "RefLinkField", name: "nextCommunicationDate", isMultiple: false, isSortable: true, id: "ContractorCompany.nextCommunicationDate", refContentType: H.List(["DateOnly", "DateTime"])};
        export const nextCommunication = {contentType: "RefLinkField" as "RefLinkField", name: "nextCommunication", isMultiple: false, isSortable: false, id: "ContractorCompany.nextCommunication", refContentType: H.List(["Todo"])};
        export const lastCommunicationDate = {contentType: "RefLinkField" as "RefLinkField", name: "lastCommunicationDate", isMultiple: false, isSortable: true, id: "ContractorCompany.lastCommunicationDate", refContentType: H.List(["DateOnly", "DateTime"])};
        export const countCommunications = {contentType: "IntegerField" as "IntegerField", name: "countCommunications", isMultiple: false, isSortable: true, id: "ContractorCompany.countCommunications", defaultValue: null as IntegerField["defaultValue"]};
        export const countScheduledCommunications = {contentType: "IntegerField" as "IntegerField", name: "countScheduledCommunications", isMultiple: false, isSortable: true, id: "ContractorCompany.countScheduledCommunications", defaultValue: null as IntegerField["defaultValue"]};
        export const countOverdueCommunications = {contentType: "IntegerField" as "IntegerField", name: "countOverdueCommunications", isMultiple: false, isSortable: false, id: "ContractorCompany.countOverdueCommunications", defaultValue: null as IntegerField["defaultValue"]};
        export const lastDealDate = {contentType: "DateTimeField" as "DateTimeField", name: "lastDealDate", isMultiple: false, isSortable: true, id: "ContractorCompany.lastDealDate", defaultValue: null as DateTimeField["defaultValue"]};
        export const countDeals = {contentType: "IntegerField" as "IntegerField", name: "countDeals", isMultiple: false, isSortable: true, id: "ContractorCompany.countDeals", defaultValue: null as IntegerField["defaultValue"]};
        export const countActiveDeals = {contentType: "IntegerField" as "IntegerField", name: "countActiveDeals", isMultiple: false, isSortable: true, id: "ContractorCompany.countActiveDeals", defaultValue: null as IntegerField["defaultValue"]};
        export const countPositiveDeals = {contentType: "IntegerField" as "IntegerField", name: "countPositiveDeals", isMultiple: false, isSortable: true, id: "ContractorCompany.countPositiveDeals", defaultValue: null as IntegerField["defaultValue"]};
        export const summDeals = {contentType: "MoneyField" as "MoneyField", name: "summDeals", isMultiple: false, isSortable: true, id: "ContractorCompany.summDeals", defaultValue: null as MoneyField["defaultValue"], refContentType: H.List(["Money"])};
        export const summActiveDeals = {contentType: "MoneyField" as "MoneyField", name: "summActiveDeals", isMultiple: false, isSortable: true, id: "ContractorCompany.summActiveDeals", defaultValue: null as MoneyField["defaultValue"], refContentType: H.List(["Money"])};
        export const summPositiveDeals = {contentType: "MoneyField" as "MoneyField", name: "summPositiveDeals", isMultiple: false, isSortable: true, id: "ContractorCompany.summPositiveDeals", defaultValue: null as MoneyField["defaultValue"], refContentType: H.List(["Money"])};
        export const countInvoice = {contentType: "IntegerField" as "IntegerField", name: "countInvoice", isMultiple: false, isSortable: true, id: "ContractorCompany.countInvoice", defaultValue: null as IntegerField["defaultValue"]};
        export const countNotPaidInvoice = {contentType: "IntegerField" as "IntegerField", name: "countNotPaidInvoice", isMultiple: false, isSortable: true, id: "ContractorCompany.countNotPaidInvoice", defaultValue: null as IntegerField["defaultValue"]};
        export const summInvoice = {contentType: "MoneyField" as "MoneyField", name: "summInvoice", isMultiple: false, isSortable: true, id: "ContractorCompany.summInvoice", defaultValue: null as MoneyField["defaultValue"], refContentType: H.List(["Money"])};
        export const summNotPaidInvoice = {contentType: "MoneyField" as "MoneyField", name: "summNotPaidInvoice", isMultiple: false, isSortable: true, id: "ContractorCompany.summNotPaidInvoice", defaultValue: null as MoneyField["defaultValue"], refContentType: H.List(["Money"])};
        export const summAccountReceivable = {contentType: "MoneyField" as "MoneyField", name: "summAccountReceivable", isMultiple: false, isSortable: true, id: "ContractorCompany.summAccountReceivable", defaultValue: null as MoneyField["defaultValue"], refContentType: H.List(["Money"])};
        export const summAccountReceivableOverdue = {contentType: "MoneyField" as "MoneyField", name: "summAccountReceivableOverdue", isMultiple: false, isSortable: true, id: "ContractorCompany.summAccountReceivableOverdue", defaultValue: null as MoneyField["defaultValue"], refContentType: H.List(["Money"])};
        export const totalIssuesCount = {contentType: "IntegerField" as "IntegerField", name: "totalIssuesCount", isMultiple: false, isSortable: true, id: "ContractorCompany.totalIssuesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const totalActiveIssuesCount = {contentType: "IntegerField" as "IntegerField", name: "totalActiveIssuesCount", isMultiple: false, isSortable: true, id: "ContractorCompany.totalActiveIssuesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const totalInactiveIssuesCount = {contentType: "IntegerField" as "IntegerField", name: "totalInactiveIssuesCount", isMultiple: false, isSortable: true, id: "ContractorCompany.totalInactiveIssuesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const totalAttachesCount = {contentType: "IntegerField" as "IntegerField", name: "totalAttachesCount", isMultiple: false, isSortable: false, id: "ContractorCompany.totalAttachesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const lastComment = {contentType: "RefLinkField" as "RefLinkField", name: "lastComment", isMultiple: false, isSortable: false, id: "ContractorCompany.lastComment", refContentType: H.List(["Comment"])};
        export const lastCommentTimeCreated = {contentType: "DateTimeField" as "DateTimeField", name: "lastCommentTimeCreated", isMultiple: false, isSortable: true, id: "ContractorCompany.lastCommentTimeCreated", defaultValue: null as DateTimeField["defaultValue"]};
        export const balance = {contentType: "MoneyField" as "MoneyField", name: "balance", isMultiple: false, isSortable: true, id: "ContractorCompany.balance", defaultValue: null as MoneyField["defaultValue"], refContentType: H.List(["Money"])};
        export const nextTaskDate = {contentType: "DateTimeField" as "DateTimeField", name: "nextTaskDate", isMultiple: false, isSortable: false, id: "ContractorCompany.nextTaskDate", defaultValue: null as DateTimeField["defaultValue"]};
        export const canSeeFull = {contentType: "BoolField" as "BoolField", name: "canSeeFull", isMultiple: false, isSortable: false, id: "ContractorCompany.canSeeFull", defaultValue: null as BoolField["defaultValue"]};
        export const possibleActions = {contentType: "EnumField" as "EnumField", name: "possibleActions", isMultiple: true, isSortable: false, id: "ContractorCompany.possibleActions", defaultValue: null as EnumField["defaultValue"], enumValues: H.List([
            "act_attaches",
            "act_delete",
            "act_edit",
            "act_fake_drop",
            "act_invite",
            "act_read",
            "act_restore",
            "act_view_fin_operation",
            "add_deal",
            "add_item",
            "message",
            "read_full",
            "task"
        ])};
        export const isDropped = {contentType: "BoolField" as "BoolField", name: "isDropped", isMultiple: false, isSortable: false, id: "ContractorCompany.isDropped", defaultValue: null as BoolField["defaultValue"]};
        export const isFavorite = {contentType: "BoolField" as "BoolField", name: "isFavorite", isMultiple: false, isSortable: true, id: "ContractorCompany.isFavorite", defaultValue: null as BoolField["defaultValue"]};
        export const tasksCount = {contentType: "IntegerField" as "IntegerField", name: "tasksCount", isMultiple: false, isSortable: false, id: "ContractorCompany.tasksCount", defaultValue: null as IntegerField["defaultValue"]};
        export const actualTasksCount = {contentType: "IntegerField" as "IntegerField", name: "actualTasksCount", isMultiple: false, isSortable: false, id: "ContractorCompany.actualTasksCount", defaultValue: null as IntegerField["defaultValue"]};
        export const countBalance = {contentType: "IntegerField" as "IntegerField", name: "countBalance", isMultiple: false, isSortable: false, id: "ContractorCompany.countBalance", defaultValue: null as IntegerField["defaultValue"]};
        export const messagesCount = {contentType: "IntegerField" as "IntegerField", name: "messagesCount", isMultiple: false, isSortable: false, id: "ContractorCompany.messagesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const callsCount = {contentType: "IntegerField" as "IntegerField", name: "callsCount", isMultiple: false, isSortable: false, id: "ContractorCompany.callsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const humanNumber = {contentType: "IntegerField" as "IntegerField", name: "humanNumber", isMultiple: false, isSortable: true, id: "ContractorCompany.humanNumber", defaultValue: null as IntegerField["defaultValue"]};
        export const actualTodosCount = {contentType: "IntegerField" as "IntegerField", name: "actualTodosCount", isMultiple: false, isSortable: false, id: "ContractorCompany.actualTodosCount", defaultValue: null as IntegerField["defaultValue"]};
        export const finishedTodosCount = {contentType: "IntegerField" as "IntegerField", name: "finishedTodosCount", isMultiple: false, isSortable: false, id: "ContractorCompany.finishedTodosCount", defaultValue: null as IntegerField["defaultValue"]};
        export const comments = {contentType: "RefLinkField" as "RefLinkField", name: "comments", isMultiple: true, isSortable: false, id: "ContractorCompany.comments", refContentType: H.List(["Comment"])};
        export const commentsCount = {contentType: "IntegerField" as "IntegerField", name: "commentsCount", isMultiple: false, isSortable: false, id: "ContractorCompany.commentsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const unreadCommentsCount = {contentType: "IntegerField" as "IntegerField", name: "unreadCommentsCount", isMultiple: false, isSortable: false, id: "ContractorCompany.unreadCommentsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const attachesCountInComments = {contentType: "IntegerField" as "IntegerField", name: "attachesCountInComments", isMultiple: false, isSortable: false, id: "ContractorCompany.attachesCountInComments", defaultValue: null as IntegerField["defaultValue"]};
        export const subscribed = {contentType: "BoolField" as "BoolField", name: "subscribed", isMultiple: false, isSortable: false, id: "ContractorCompany.subscribed", defaultValue: null as BoolField["defaultValue"]};
        export const allFiles = {contentType: "RefLinkField" as "RefLinkField", name: "allFiles", isMultiple: true, isSortable: false, id: "ContractorCompany.allFiles", refContentType: H.List(["File", "FileGroup"])};
        export const allFilesCount = {contentType: "IntegerField" as "IntegerField", name: "allFilesCount", isMultiple: false, isSortable: false, id: "ContractorCompany.allFilesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const attachesInfo = {contentType: "RefLinkField" as "RefLinkField", name: "attachesInfo", isMultiple: false, isSortable: false, id: "ContractorCompany.attachesInfo", refContentType: H.List(["AttachesInfo"])};
        export const commentsWithoutTransportCount = {contentType: "IntegerField" as "IntegerField", name: "commentsWithoutTransportCount", isMultiple: false, isSortable: false, id: "ContractorCompany.commentsWithoutTransportCount", defaultValue: null as IntegerField["defaultValue"]};
        export const emailsCount = {contentType: "IntegerField" as "IntegerField", name: "emailsCount", isMultiple: false, isSortable: false, id: "ContractorCompany.emailsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const whatsappCount = {contentType: "IntegerField" as "IntegerField", name: "whatsappCount", isMultiple: false, isSortable: false, id: "ContractorCompany.whatsappCount", defaultValue: null as IntegerField["defaultValue"]};
        export const telegramCount = {contentType: "IntegerField" as "IntegerField", name: "telegramCount", isMultiple: false, isSortable: false, id: "ContractorCompany.telegramCount", defaultValue: null as IntegerField["defaultValue"]};
        export const instagramCount = {contentType: "IntegerField" as "IntegerField", name: "instagramCount", isMultiple: false, isSortable: false, id: "ContractorCompany.instagramCount", defaultValue: null as IntegerField["defaultValue"]};
        export const todos = {contentType: "RefLinkField" as "RefLinkField", name: "todos", isMultiple: true, isSortable: false, id: "ContractorCompany.todos", refContentType: H.List(["Todo"])};
        export const todosCount = {contentType: "IntegerField" as "IntegerField", name: "todosCount", isMultiple: false, isSortable: false, id: "ContractorCompany.todosCount", defaultValue: null as IntegerField["defaultValue"]};
        export const avatar = {contentType: "RefLinkField" as "RefLinkField", name: "avatar", isMultiple: false, isSortable: false, id: "ContractorCompany.avatar", refContentType: H.List(["File"])};
        export const photo = {contentType: "RefLinkField" as "RefLinkField", name: "photo", isMultiple: false, isSortable: false, id: "ContractorCompany.photo", refContentType: H.List(["File"])};
        export const possibleSubscribers = {contentType: "RefLinkField" as "RefLinkField", name: "possibleSubscribers", isMultiple: true, isSortable: false, id: "ContractorCompany.possibleSubscribers", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee"])};
        export const possibleSubscribersCount = {contentType: "IntegerField" as "IntegerField", name: "possibleSubscribersCount", isMultiple: false, isSortable: false, id: "ContractorCompany.possibleSubscribersCount", defaultValue: null as IntegerField["defaultValue"]};
        export const subscribers = {contentType: "RefLinkField" as "RefLinkField", name: "subscribers", isMultiple: true, isSortable: false, id: "ContractorCompany.subscribers", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee"])};
        export const subscribersCount = {contentType: "IntegerField" as "IntegerField", name: "subscribersCount", isMultiple: false, isSortable: false, id: "ContractorCompany.subscribersCount", defaultValue: null as IntegerField["defaultValue"]};
        export const hiddenCommentsCount = {contentType: "IntegerField" as "IntegerField", name: "hiddenCommentsCount", isMultiple: false, isSortable: false, id: "ContractorCompany.hiddenCommentsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const isUnread = {contentType: "BoolField" as "BoolField", name: "isUnread", isMultiple: false, isSortable: false, id: "ContractorCompany.isUnread", defaultValue: null as BoolField["defaultValue"]};
        export const firstUnreadComment = {contentType: "RefLinkField" as "RefLinkField", name: "firstUnreadComment", isMultiple: false, isSortable: false, id: "ContractorCompany.firstUnreadComment", refContentType: H.List(["Comment"])};
        export const unreadAnswer = {contentType: "BoolField" as "BoolField", name: "unreadAnswer", isMultiple: false, isSortable: false, id: "ContractorCompany.unreadAnswer", defaultValue: null as BoolField["defaultValue"]};
        export const lastView = {contentType: "DateTimeField" as "DateTimeField", name: "lastView", isMultiple: false, isSortable: false, id: "ContractorCompany.lastView", defaultValue: null as DateTimeField["defaultValue"]};
        export const tags = {contentType: "RefLinkField" as "RefLinkField", name: "tags", isMultiple: true, isSortable: true, id: "ContractorCompany.tags", refContentType: H.List(["Tag"])};
        export const tagsCount = {contentType: "IntegerField" as "IntegerField", name: "tagsCount", isMultiple: false, isSortable: false, id: "ContractorCompany.tagsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const reminderTime = {contentType: "DateTimeField" as "DateTimeField", name: "reminderTime", isMultiple: false, isSortable: false, id: "ContractorCompany.reminderTime", defaultValue: null as DateTimeField["defaultValue"]};
    }
}

export function isContractorCompany(arg: any): arg is ContractorCompany {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "ContractorCompany"
}
