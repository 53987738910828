import * as React from "react"
import {computed} from "mobx";
import {observer} from "mobx-react"
import createFragment from "react-addons-create-fragment"
import TouchRipple from "material-ui/internal/TouchRipple"
import getText from "react-addons-text-content"
import classNames from "classnames/bind"
import {
    CIcon,
    CIconType,
    CIconColor,
    CUrlify,
    CCounter,
    Component,
    CEllipsis,
    CIconSize,
} from "src/lib/components"

export enum CButtonType {
    DEFAULT,
    DEFAULT_SUCCESS,
    DEFAULT_WARNING,
    SUCCESS,
    WARNING,
    FLAT,
    FLAT_SUCCESS,
    FLAT_WARNING,
    SETTING,
    SETTING_SUCCESS,
    SETTING_WARNING,
    PALE,
    /*спецкнопки отдльных модулей*/
    INFORMER,
    CARD,
    MENU,
    MENU_GRAY,
    MENU_DARK,
    FILTER,
    OUTLINE
}

export type CButtonSize = "xsmall" | "small" | "normal"

enum CButtonMargin {
    OFF = 0,
    SM = 8,
    DEFAULT = 12
}


let style = classNames.bind(require("./CButton.styl"));

/**
 * Простая кнопка
 */
@observer
export class CButton extends Component<CButton.Props, {}> {
    public static Margin = CButtonMargin
    public static Type = CButtonType

    public static defaultProps = {
        target: "",
        hideCaption: false,
        type: CButtonType.DEFAULT
    }

    public isEventLock() {
        return this.props.disabled || this.props.loading
    }

    private onClick = (e: React.MouseEvent<HTMLElement>) => {
        if (this.props.onClick && !this.isEventLock()) {
            e.stopPropagation();
            this.props.onClick(e);
        }
        if (this.props.onDisabledClick && this.isEventLock()) {
            e.stopPropagation()
            this.props.onDisabledClick(e)
        }
    }

    private onMouseEnter = (e: React.MouseEvent<HTMLElement>) => {
        if (this.props.onMouseEnter && !this.isEventLock()) {
            this.props.onMouseEnter(e);
        }
    }

    private onMouseLeave = (e: React.MouseEvent<HTMLElement>) => {
        if (this.props.onMouseLeave && !this.isEventLock()) {
            this.props.onMouseLeave(e);
        }
    }

    private createChildFragment(fragments: any) {
        const newFragments: any = {};
        let validChildrenCount = 0;
        let firstKey: string;

        // Only create non-empty key fragments
        for (const key in fragments) {
            const currentChild = fragments[key]

            if (currentChild) {
                if (validChildrenCount === 0) {
                    firstKey = key
                }
                newFragments[key] = currentChild
                validChildrenCount++
            }
        }

        if (validChildrenCount === 0) {
            return undefined
        }
        if (validChildrenCount === 1) {
            return newFragments[firstKey]
        }
        return createFragment(newFragments)
    }

    private renderIcon(icon: CIconType | JSX.Element, className?: string, isRightIcon?: boolean) {
        if (icon !== void 0 && icon !== null) {
            if (typeof icon === "number") {
                return (
                    <CIcon
                        type={icon as CIconType}
                        size={this.getIconSize(isRightIcon)}
                        color={this.getIconColor(isRightIcon)}
                        className={style(
                            "icon",
                            {"iconOnly": Boolean(!this.props.caption || this.props.hideCaption)},
                            className
                        )}
                    />
                )
            }
            return icon
        }
        return null
    }

    private getIconSize(isRightIcon?: boolean): CIconSize {
        const {type, size} = this.props
        if (
            (type === CButton.Type.SETTING
            || type === CButton.Type.SETTING_WARNING
            || type === CButton.Type.SETTING_SUCCESS)
            && !isRightIcon
            || size === "small"
        ) {
            return CIconSize.SMALL
        }

        if (size === "xsmall") {
            return CIconSize.XSMALL
        }

        return CIconSize.MEDIUM
    }

    private getIconColor(isRightIcon?: boolean): CIconColor {
        const {type, color, colorRight} = this.props

        const iconColor = isRightIcon ? colorRight : color

        if (
            type === CButtonType.WARNING ||
            type === CButtonType.SUCCESS
        ) {
            return CIconColor.LIGHT
        }

        return iconColor ? iconColor : CIconColor.DARK
    }

    private renderCounter() {
        if (typeof this.props.counter !== "undefined") {
            return (
                <CCounter
                    number={this.props.counter}
                    type={this.props.counterType}
                    className={this.props.counterClassName}
                    position="top"
                    hideNumber={this.props.hideNumberOnCounter}
                />
            )
        }
    }

    private renderCaption() {
        if (this.props.hideCaption) {
            return
        }

        const caption = this.props.maxWidth
            ? <CEllipsis showHint={false}>
                {this.props.caption}
            </CEllipsis>
            : this.props.caption

        return <span
            className={style("buttonContent", {buttonContentWithIcon: Boolean(this.props.icon)})}
        >
            {caption}
        </span>
    }

    private createButtonChildren() {
        const {
            children,
            disabled,
            caption,
            loading,
            href,
            icon,
            iconRight,
            maxWidth,
        } = this.props;

        let content = <span className={style({hasMaxWidth: Boolean(maxWidth)}, "inlineWrapper")}>
            {this.renderIcon(icon)}
            {caption ? this.renderCaption() : null}
            {this.renderCounter()}
            {children}
            {this.renderIcon(iconRight, "rightIcon", true)}
        </span>

        const buttonBox = <span className={style("buttonBox")}> {content} </span>

        // Touch Ripple
        const touchRipple = !disabled && !loading && (href || this.props.onClick || this.props.touchRipple) ? (
            this.props.touchRipple === false
                ? buttonBox
                : (<TouchRipple centerRipple={!caption}> {buttonBox} </TouchRipple>)
        ) : undefined;

        return this.createChildFragment({
                touchRipple,
                children: touchRipple ? undefined : <span
                    className={style("buttonBox")}
                    onClick={this.onClick}
                >{content}</span>,
            })
    }

    @computed
    private get description() {
        // TODO кажется, что title и description делают одно и то же, надо бы выпилить
        return this.props.title
            || this.props.description
            || getText(this.props.caption)
            || null
    }

    public render() {
        let {
            type,
            size,
            marginRight,
            styles,
            disabled,
            className,
            caption,
            target,
            href,
            color,
            active,
            loading,
            colorRight,
            maxWidth
        } = this.props;

        className = typeof className === "string" ? className : style(className)

        const addClassName = style(
            "button",
            "base",
            {
                "buttonIconOnly": !caption,
                "colorIcon": Boolean(color) || Boolean(colorRight),
                "noHover": !(href || this.props.onClick),
                [CButtonType[type]]: Boolean(CButtonType[type]),
                [size]: Boolean(size),
                [CButtonMargin[marginRight]]: Boolean(CButtonMargin[marginRight]),
                active: active,
                disabled: disabled,
                loading: loading,
            },
            className,
        );

        const buttonChildren = this.createButtonChildren();

        const buttonProps = {
            className: addClassName,
            onMouseEnter: this.onMouseEnter,
            onMouseLeave: this.onMouseLeave,
            onMouseDown: this.props.onMouseDown,
            style: Object.assign({}, styles, {marginRight}, {maxWidth: maxWidth ? maxWidth : "auto"}),
            title: this.description,
            onClick: this.onClick,
            tabIndex: this.props.tabIndex,
            disabled
        }

        if (href) {
            return (
                <CUrlify to={href}>
                    <a
                        {...buttonProps}
                        data-name={this.props.name}
                        target={target}
                    >
                        {buttonChildren}
                    </a>
                </CUrlify>
            )
        } else {
            return (
                <button
                    {...buttonProps}
                    data-name={this.props.name}
                    type="button"
                >
                    {buttonChildren}
                </button>
            )
        }
    }
}

export namespace CButton {
    export type Margin = CButtonMargin
    export type Type = CButtonType
    export type Size = CButtonSize
    export interface Props {
        className?: string | string[]
        name: string                    // Используется в автотестах для поиска кнопок на странице, давайте осмысленные имена
        type?: CButtonType
        icon?: CIconType | JSX.Element
        iconRight?: CIconType | JSX.Element // TODO Катя, не забудь с этим что-нибудь сдлеать потом :)
        // icon?: CIconType | CHint // можно добавлять необходимые элементы, но их размер должен совпадать с размером иконки
        size?: CButtonSize
        marginRight?: CButtonMargin
        styles?: {}
        active?: boolean
        disabled?: boolean
        loading?: boolean
        hideCaption?: boolean
        caption?: string | JSX.Element
        description?: string
        onClick?: React.MouseEventHandler<HTMLElement>
        onDisabledClick?: React.MouseEventHandler<HTMLElement>
        onMouseDown?: React.MouseEventHandler<HTMLElement>
        onMouseEnter?: React.MouseEventHandler<HTMLElement>
        onMouseLeave?: React.MouseEventHandler<HTMLElement>
        counter?: number
        counterType?: CCounter.Type
        hideNumberOnCounter?: boolean //скрывать число на каунтере
        counterClassName?: string
        tabIndex?: number
        touchRipple?: boolean
        title?: string
        href?: string
        target?: string
        color?: CIconColor
        colorRight?: CIconColor
        maxWidth?: number | string
    }
}
