import {Intl, timePeriodLongHr} from "src/lib/utils/intl"
import * as Api from "src/lib/entities/api"
import * as Collections from "src/lib/collections"
import {UnitFormatter} from "src/lib/utils/intl/formatters"

const messages: any = require("./messages.yml")

const intervals = ["minute", "hour", "day", "week", "month"] as string[]

/**
 *
 * @returns {Api.Reminder.Interval[]}
 */
export function getAllIntervals() {
    return intervals
}

/**
 * Возвращает текстовое представление напоминания
 * @param intl
 * @param reminder
 * @returns {string}
 */
export function getReminderText(intl: Intl, reminder: Api.Reminder) {
    return intl.formatMessage(messages["remindTransportAndInterval"], {
        transport: intl.formatMessage(messages["toTransport"], {
            transport: reminder.transport
        }),
        interval: timePeriodLongHr(intl)(reminder.timeBefore.getSeconds())
    })
}


/**
 * Возвращает текстовове представление для пачки напоминаний
 * @param intl
 * @param reminders
 * @returns {string}
 */
export function getRemindersTextForSingleLine(intl: Intl, reminders: Collections.List<Api.Reminder>) {
    if (!reminders || reminders.length === 0) {
        return intl.formatMessage(messages["doNotRemind"])
    } else if (reminders.length === 1) {
        return intl.formatMessage(messages["remindSingle"], {
            reminder: getReminderText(intl, reminders.first()),
        })
    } else {
        return intl.formatMessage(messages["remindMultiple"], {
            reminders: reminders.map((reminder) => getReminderText(intl, reminder)).join(", ")
        })
    }
}

/**
 * Переводит название интервала "мин.", "ч." и т.п.
 * @param intl
 * @param interval
 * @returns {string}
 */
export function getIntervalUnitTranslation(intl: Intl, interval: string) {
    return intl.formatMessage(messages["reminderInterval"], {interval})
}

export function getFutureFormattedUnit(intl: Intl, count: number = 0, unitName: UnitFormatter.KnownFormat): string {
    const interval = unitName.replace("Long", "")
    return intl.formatMessage(messages["beforeTimeInterval"], { interval, count })
}

export function getDefaultReminder(user: Api.Employee, subjectType: Api.ContentType) {
    if (user && user.defaultReminders && subjectType) {
        return getDefaultReminders(user, subjectType)?.last()
    }
}

export function getDefaultReminders(user: Api.Employee, subjectType: Api.ContentType) {
    if (user && user.defaultReminders && subjectType) {
        let reminderType: Api.DefaultReminder.Type
        switch (subjectType) {
            case Api.Todo.contentType:
                reminderType = Api.DefaultReminder.Type.todo
                break
            case Api.Milestone.contentType:
                reminderType = Api.DefaultReminder.Type.milestone
                break
            case Api.Task.contentType:
                reminderType = Api.DefaultReminder.Type.task
                break
            case Api.Project.contentType:
                reminderType = Api.DefaultReminder.Type.project
                break
            default:
                return
        }

        if (!reminderType) {
            return
        }

        return user.defaultReminders
            .filter(defaultReminder => defaultReminder.type === reminderType)
            .map(defaultReminder => defaultReminder.reminder)
            .filter(reminder => reminder.transport !== "inbox")
    }
}
