import * as React from "react"
import {COnBoardingGlobalContainer} from "src/bums/common/onboarding/containers/COnBoardingGlobalContainer"
import {OnBoardingProgramStore} from "src/bums/common/onboarding/stores/OnBoardingProgramStore"
import {CInviteTeam} from "src/bums/common/onboarding/tutorial/CInviteTeam/CInviteTeam"
import {CGlobalHintBubbleContainer} from "src/bums/common/onboarding/tutorial/CGlobalHintBubble/CGlobalHintBubbleContainer"
import {CSimpleTaskForm} from "src/bums/common/onboarding/tutorial/CSimpleTaskForm/CSimpleTaskForm"

function mapStepToComponent(store: OnBoardingProgramStore): JSX.Element {
    switch (store.currentStep) {
        case "crm":
        case "cp":
            return null
        case "newEmployersForm":
            return <CInviteTeam store={store} />
        case "newTaskForm":
            return <CSimpleTaskForm store={store} />
        case "tutorialModal":
        case "tutorialInformer":
            return <CGlobalHintBubbleContainer store={store} />
        default:
            return null
    }
}

export const CTutorialGlobalContainer = COnBoardingGlobalContainer.create("tutorial", mapStepToComponent)
