import {action, computed, observable} from "mobx";
import {autobind} from "core-decorators";
import {Form} from "src/lib/utils/form/form";
import * as Api from "src/lib/entities/api";
import * as Collections from "src/lib/collections"
import {FormCollection} from "src/lib/utils/form/formCollection";

@autobind
export class NegotiationForm extends Form<Api.NegotiationItem> {

    constructor($initialValueFactory: () => Api.NegotiationItem) {
        super($initialValueFactory);
    }

    @observable
    public versions = this.form("versions", versions => {
        return new FormCollection<Api.NegotiationItemVersion, Form<Api.NegotiationItemVersion>>(
            () => versions
        )
    })

    @computed
    public get version() {
        return this.versions.map(child => child.form).first()
    }

    @action
    public setText(value: string) {
        this.version.set("text", value)
    }

    @action
    public setAttache(value?: Collections.List<Api.File>) {
        this.version.set("attache", value ? value.first() : null)
    }

}
