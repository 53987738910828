/* tslint:disable */
import * as H from "../metadataHelper"
import {StringField} from "./stringField"
import {FloatField} from "./floatField"
import {BoolField} from "./boolField"

export interface Currency extends H.BaseEntity {
    name?: string; // Название
    abbreviation?: string; // Аббревиатура
    sign?: string; // Знак
    rate?: number; // Курс
    isBase?: boolean; // Основная ли
    id?: string; // Идентификатор
    contentType: "Currency"; // Object type
}

export module Currency {
    export const contentType: "Currency" = "Currency";
    export const endpoint = "/api/v3/currency";

    export const newObject: Currency = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "Currency.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const abbreviation = {contentType: "StringField" as "StringField", name: "abbreviation", isMultiple: false, isSortable: false, id: "Currency.abbreviation", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const sign = {contentType: "StringField" as "StringField", name: "sign", isMultiple: false, isSortable: false, id: "Currency.sign", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const rate = {contentType: "FloatField" as "FloatField", name: "rate", isMultiple: false, isSortable: false, id: "Currency.rate", defaultValue: null as FloatField["defaultValue"]};
        export const isBase = {contentType: "BoolField" as "BoolField", name: "isBase", isMultiple: false, isSortable: false, id: "Currency.isBase", defaultValue: null as BoolField["defaultValue"]};
    }
}

export function isCurrency(arg: any): arg is Currency {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Currency"
}
