/* tslint:disable */
import * as H from "../metadataHelper"
import {BoolField} from "./boolField"

export interface ProductQuestItem extends H.BaseEntity {
    id?: ProductQuestItem.Id;
    completed?: boolean;
    contentType: "ProductQuestItem"; // Object type
}

export module ProductQuestItem {
    export const contentType: "ProductQuestItem" = "ProductQuestItem";
    export const endpoint = "/api/v3/productQuestItem";
    export type Id = "create_contractor"|"create_employee"|"create_task"|"edit_main_menu"|"registred_in_megaplan";
    export module Id {
        export const create_contractor: Id = "create_contractor";
        export const create_employee: Id = "create_employee";
        export const create_task: Id = "create_task";
        export const edit_main_menu: Id = "edit_main_menu";
        export const registred_in_megaplan: Id = "registred_in_megaplan";
    }
    export const newObject: ProductQuestItem = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const completed = {contentType: "BoolField" as "BoolField", name: "completed", isMultiple: false, isSortable: false, id: "ProductQuestItem.completed", defaultValue: null as BoolField["defaultValue"]};
    }
}

export function isProductQuestItem(arg: any): arg is ProductQuestItem {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "ProductQuestItem"
}
