import * as React from "react"
import * as ReactDOM from "react-dom"
import {throttle, noop} from "lodash"
import {observable, runInAction} from "mobx"
import {observer} from "mobx-react"
import classNames from "classnames/bind"
import {makeRefHandler} from "src/lib/utils/func"
import {CCustomScrollbars, Component} from "src/lib/components"
import {CSticky} from "src/bums/common/sticky/CSticky"
import * as Api from "src/lib/entities/api"
import * as Collections from "src/lib/collections"

const style: any = classNames.bind(require("./CPaginatedVirtualScroll.styl"))

export interface PaginationProps {
    hasMore: boolean | void,
    hasMorePrev?: boolean | void
    loadTriggerOnUpwards?: boolean, // by default false and trigger comes on scrolling downward
    isLoading: boolean,
    isLoadingPrev?: boolean,
    loaderRowElement: JSX.Element,
    doLoad: () => void // this callback should set `isLoading` props to true!
    doLoadPrev?: () => void
}

interface CPaginatedVirtualScrollProps {
    shadowBoxClass?: string
    rowsCount: number,
    rowRenderer: (item: number | Api.BaseEntity, prevItem?: Api.BaseEntity) => JSX.Element|JSX.Element[]|string,
    pagination?: PaginationProps,
    renderEmpty?: () => JSX.Element|JSX.Element[]|string
    onMouseEnter?: () => void
    onMouseLeave?: () => void
    className?: string
    contentClassName?: string
    customScroll?: Boolean
    customScrollProps?: any
    grouping?: boolean
    groupFilter?: (item: Api.BaseEntity) => string
    items?: Collections.List<Api.BaseEntity>
}

const containerStyle = {
    width: "100%",
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
}

const containerWithCustomScrollStyle = {
    width: "100%",
    height: "100%",
}

@observer
export default class CPaginatedVirtualScroll extends Component<CPaginatedVirtualScrollProps, {}> {

    public _virtualScroll: HTMLElement

    public _customScroll: CCustomScrollbars

    private onScrollThrottled: typeof CPaginatedVirtualScroll.prototype.onScroll

    private scrollBottom: number;

    public static defaultProps = {
        customScroll: false,
        onMouseEnter: noop,
        onMouseLeave: noop
    }

    constructor(props: CPaginatedVirtualScrollProps, context: any) {
        super(props, context)
        this.onScrollThrottled = throttle(this.onScroll, 100)
    }

    @observable
    private isScrolling = false

    private timeout: number

    private onMouseEnter = () => {
        this.props.onMouseEnter()
    }

    private onMouseLeave = () => {
        this.props.onMouseLeave()
    }

    public setScrollTop(scrollTop: number) {
        if (this.props.customScroll) {
            this._customScroll.scrollView.scrollTop = scrollTop
        } else {
            this._virtualScroll.scrollTop = scrollTop
        }
    }

    public get virtualScrollNode() {
        return this._virtualScroll
    }

    componentWillUpdate(nextProps: CPaginatedVirtualScrollProps, nextState: {}, nextContext: any): void {
        if (
            this.props.pagination &&
            this.props.pagination.loadTriggerOnUpwards &&
            this.props.rowsCount !== nextProps.rowsCount
        ) {
            const node = this.props.customScroll
                ? ReactDOM.findDOMNode(this._customScroll.scrollView)
                : ReactDOM.findDOMNode(this._virtualScroll)
            if (node instanceof Element) {
                this.scrollBottom = node.scrollHeight - node.scrollTop
            }
        }
    }

    componentDidUpdate(prevProps: CPaginatedVirtualScrollProps, prevState: {}, prevContext: any) {
        if (
            this.props.pagination &&
            this.props.pagination.loadTriggerOnUpwards &&
            this.props.rowsCount !== prevProps.rowsCount
        ) {
            const node = this.props.customScroll
                ? ReactDOM.findDOMNode(this._customScroll.scrollView)
                : ReactDOM.findDOMNode(this._virtualScroll)
            if (node instanceof Element && prevProps.pagination.isLoading) {
                node.scrollTop = node.scrollHeight - this.scrollBottom
            }
        }
    }

    private loadPrev = () => {
        const node = this.props.customScroll
            ? ReactDOM.findDOMNode(this._customScroll.scrollView)
            : ReactDOM.findDOMNode(this._virtualScroll)
        if (node instanceof Element) {
            const scrollDistanceFromBottom = node.scrollHeight - node.clientHeight - node.scrollTop
            if (scrollDistanceFromBottom === 0) {
                this.props.pagination.doLoadPrev()
            }
        }
    }

    private onScroll = (event: React.UIEvent<HTMLElement>) => {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => runInAction(() => this.isScrolling = false), 2000)
        runInAction(() => this.isScrolling = true)

        if (
            !this.props.pagination.isLoading &&
            this.props.pagination.hasMore &&
            typeof this.props.pagination.doLoad === "function"
        ) {
            const node = this.props.customScroll
                ? ReactDOM.findDOMNode(this._customScroll.scrollView)
                : ReactDOM.findDOMNode(this._virtualScroll)
            if (node instanceof Element) {
                let scrollLeft: number
                if (this.props.pagination.loadTriggerOnUpwards) {
                    scrollLeft = node.scrollTop
                    if (scrollLeft === 0) {
                        this.props.pagination.doLoad()
                    }
                } else {
                    // calculate rowsLeft
                    scrollLeft = node.scrollHeight - node.scrollTop - node.clientHeight
                    if (scrollLeft <= node.clientHeight * 2) {
                        this.props.pagination.doLoad()
                    }
                }
            }
        }

        if (
            !this.props.pagination.isLoadingPrev &&
            this.props.pagination.hasMorePrev &&
            typeof this.props.pagination.doLoadPrev === "function"
        ) {
            const node = this.props.customScroll
                ? ReactDOM.findDOMNode(this._customScroll.scrollView)
                : ReactDOM.findDOMNode(this._virtualScroll)
            if (node instanceof Element) {
                if (this.props.pagination.loadTriggerOnUpwards) {
                    const scrollDistanceFromBottom = node.scrollHeight - node.clientHeight - node.scrollTop
                    if (scrollDistanceFromBottom === 0) {
                        setTimeout(this.loadPrev, 300)
                    }
                }
            }
        }
    }

    private rowRendererWithLoader = (index: number) => {
        // this callback calls only if this.props.pagination exists, so, we should not check this here
        // this callback calls only if this.props.pagination.isLoading
        if (
            (!this.props.pagination.loadTriggerOnUpwards && index === this.props.rowsCount) ||
            (this.props.pagination.loadTriggerOnUpwards && index === 0)
        ) {
            return this.props.pagination.loaderRowElement
        } else {
            let innerIndex = index
            if (this.props.pagination.loadTriggerOnUpwards) {
                innerIndex = index - 1
            }
            return this.props.rowRenderer(innerIndex)
        }
    }

    private renderGroupHeader = (group: string) => {

        return <CSticky
            stickyBehavior={CSticky.StickyBehavior.INSIDE}
            positionRelatively={CSticky.PositionRelatively.MIX}
            fixClassName={style("fixedHead")}
            priority={100}
            ignoreStickyStore
            key={`${group}-sticky`}
        >
            <div className={style("groupHeader")} key={group}>
                <div className={style("wrapperHead", {active: this.isScrolling})} key={group}>
                    <span className={style("group")}>{group}</span>
                </div>
            </div>
        </CSticky>
    }

    private renderGroups() {
        const {items} = this.props
        const result: JSX.Element[] = []
        let prevGroup: string
        let prevItem: Api.BaseEntity = null
        let resultGroup = []

        if (this.props.pagination.isLoading) {
            result.unshift(this.props.pagination.loaderRowElement)
        }

        for (const item of items) {
            if (this.props.grouping) {
                const group = this.props.groupFilter(item)
                if (prevGroup !== group) {
                    result.push(
                        <div key={`${group}-wrapper`}>
                            {resultGroup}
                        </div>
                    )
                    resultGroup = []
                    prevGroup = group
                    resultGroup.push(this.renderGroupHeader(group))
                }
            }

            resultGroup.push(this.props.rowRenderer(item, prevItem))
            prevItem = item
        }

        result.push(
            <div className={style("wrapperGroup")} key={"wrapper"}>
                {resultGroup}
            </div>
        )

        if (this.props.pagination.isLoadingPrev) {
            result.push(this.props.pagination.loaderRowElement)
        }

        return result
    }

    private renderContent(props: CPaginatedVirtualScrollProps): Array<JSX.Element | string> | JSX.Element {
        const {contentClassName} = props

        let res: Array<JSX.Element | string> = []
        if (props.rowsCount) {

            if (this.props.grouping) {
                return this.renderGroups()
            }

            for (let i = 0; i < props.rowsCount; i++) {
                let row = props.rowRenderer(i)
                if (Array.isArray(row)) {
                    res = res.concat(row)
                } else {
                    res.push(row)
                }
            }
            if (contentClassName) {
                return <div className={contentClassName}>
                    {res}
                </div>
            } else {
                return res
            }
        }
        if (props.renderEmpty) {
            let row = props.renderEmpty()
            if (Array.isArray(row)) {
                res = res.concat(row)
            } else {
                res.push(row)
            }
        }
        return res
    }

    public render(): JSX.Element {
        let props: CPaginatedVirtualScrollProps = Object.assign({}, this.props);
        let onScroll: typeof CPaginatedVirtualScroll.prototype.onScroll = null;
        if (this.props.pagination) {
            onScroll = this.onScrollThrottled;
            props.rowRenderer = this.props.pagination.isLoading || this.props.pagination.isLoadingPrev
                ? this.rowRendererWithLoader
                : this.props.rowRenderer;
            props.rowsCount = this.props.pagination.isLoading
                ? this.props.rowsCount + 1
                : this.props.rowsCount;
            delete props.pagination
        }
        return <div
            ref={makeRefHandler(this, "_virtualScroll")}
            style={this.props.customScroll ? containerWithCustomScrollStyle : containerStyle}
            className={this.props.className}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
            onScroll={onScroll}
        >
            {this.props.customScroll
                ? <CCustomScrollbars
                    ref={makeRefHandler(this, "_customScroll")}
                    shadowBoxClass={this.props.shadowBoxClass}
                    customScrollProps={this.props.customScrollProps}
                >
                    {this.renderContent(props)}
                </CCustomScrollbars>
                : this.renderContent(props)
            }
        </div>
    }
}
