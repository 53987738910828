import * as React from "react"
import {Component} from "src/lib/components/Component/Component"
import {inject} from "src/lib/utils/inject"
import {ModalFormStore} from "src/bums/common/modalForm/ModalFormStore"
import {computed} from "mobx"
import {observer} from "mobx-react"
import {autobind} from "core-decorators"
import {CButton, CFlex, CIcon, CStyledContent, CTheme} from "src/lib/components"
import {Intl} from "src/lib/utils/intl/Intl"
import classNames from "classnames/bind"
import {CSSTransitionGroup} from "react-transition-group"

const style: any = classNames.bind(require("./CModalFormRestorer.styl"))
const messages: any = require("./messages.yml")

@observer
@autobind
export class CModalFormRestorer extends Component<{}, {}> {

    @inject(ModalFormStore)
    private modalFormStore: ModalFormStore

    @inject(Intl)
    private intl: Intl

    private clearFormTimer: number

    @computed
    private get formToRestore() {
        return this.modalFormStore.formToRestore
    }

    componentWillUnmount(): void {
        clearTimeout(this.clearFormTimer)
    }

    private restoreForm() {
        const {type, activeFormType, isTemplate} = this.formToRestore
        this.modalFormStore.open(type, {activeForm: activeFormType, isTemplate})
    }

    private clearForm() {
        clearTimeout(this.clearFormTimer)
        this.modalFormStore.clearPreset(this.formToRestore.type)
    }

    public render() {
        if (!this.formToRestore) {
            return null
        }
        clearTimeout(this.clearFormTimer)
        this.clearFormTimer = setTimeout(() => this.clearForm(), 5000)
        const {type} = this.formToRestore
        return <CTheme>
            <CSSTransitionGroup
                transitionName="animate-fadein"
                transitionEnter={false}
                transitionLeave={false}
                transitionAppear
                transitionAppearTimeout={500}
                key={type}
            >
                <CFlex className={style("hint")} key={type} align="middle" justify="between">
                    <CStyledContent>
                        {this.intl.formatMessage(messages["restoreFormHint"])}
                    </CStyledContent>
                    <span className={style("hintActions", "darkSide")}>
                        <CButton
                            name="restoreForm"
                            caption={this.intl.formatMessage(messages["restore"])}
                            type={CButton.Type.FLAT_SUCCESS}
                            onClick={this.restoreForm}
                        />
                        <CButton
                            name="closeHint"
                            icon={CIcon.Type.CANCEL}
                            type={CButton.Type.FLAT}
                            color={CIcon.Color.LIGHT}
                            onClick={this.clearForm}
                        />
                    </span>
                </CFlex>
            </CSSTransitionGroup>
        </CTheme>
    }
}
