import {Component} from "src/lib/components"
import * as React from "react"
import classNames from "classnames/bind"

const style: any = classNames.bind(require("./CLabel.styl"))

enum LabelType {
    BOLD,
    LIGHTER,
    FORM,
}

interface CLabelProps {
    caption: string | JSX.Element
    for?: string
    type?: LabelType
    className?: string
    required?: boolean
    width?: number
}

export default class CLabel extends Component<CLabelProps, {}> {

    public static Type = LabelType

    public static defaultProps = {
        type: LabelType.BOLD
    }

    public render() {
        const {type, caption, width} = this.props
        const className = style(
            "label",
            LabelType[type],
            {wrapper: !!this.props.children},
            this.props.className
        )
        return <label htmlFor={this.props.for} className={className} style={{width: width}}>
            {this.props.children ? <div className={style("children")}>{this.props.children}</div> : null}
            <span className={style("caption", {"required": this.props.required})}>{caption}</span>
        </label>
    }
}
