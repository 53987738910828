import {autobind} from "core-decorators"
import {observable, computed, action} from "mobx"
import {MainMenuStateStore} from "src/bums/common/CMainMenu/MainMenuStateStore"
import * as Api from "src/lib/entities/api"
import {inject} from "src/lib/utils/inject"
import {Router} from "src/lib/utils/router"
import {isIterable} from "src/lib/collections/utils"
import {hasInternalLink} from "src/lib/utils/url"
import {
    generateUrls,
    weightDetermination,
    normalizeQueryParams,
    getUrlAddStruct,
    getAllItems,
    getAllParentIds,
    prepareOldSortSettingValue,
} from "./utils"
import {
    PROFILE_MENU_ITEMS_SORTED,
    SETTINGS_ITEM_ID,
    SETTINGS_ITEM_ACCOUNT_ID,
    TIMEOUT_REFRESH_NEWS_UNREAD_COUNTER,
    MIN_HEIGHT_FOR_LINE_TYPE,
    TYPE_SMALL_HEIGHT,
    TYPE_FULL_HEIGHT,
    TYPE_LINE_HEIGHT
} from "./constants"
import {UserSettingStore} from "src/bums/common/stores/UserSettingStore"
import {FeatureStore} from "src/bums/common/stores/FeatureStore"
import {CAdapt} from "src/lib/components/CAdapt/CAdapt"


type MenuType = "full" | "small" | "line"

export const DEFAULT_HOME_MODULE = "desktop"

@autobind
export class MainMenuStore {

    public readonly $itemsListStore: Api.ListStore<Api.MenuItem>

    private $sortOrderUserSetting: UserSettingStore<string[]>

    private $collapsibleMenuUserSetting: UserSettingStore<boolean>

    private $homeModuleSetting: UserSettingStore<string>

    private $unreadCounter: Api.EntityStore<Api.Counter>

    private $currentState: MainMenuStateStore

    @observable
    private $type: MenuType = "full"

    private $customUserLogo: Api.EntityStore<Api.SystemSetting<Api.File>>

    private mediaQueryForLineType = new CAdapt.MediaQuery(`(max-height: ${MIN_HEIGHT_FOR_LINE_TYPE}px)`)

    constructor (
        @inject(Api.Store) private $apiStore: Api.Store,
        @inject(FeatureStore) private $featureStore: FeatureStore,
        @inject(Router) private $router: Router
    ) {
        this.$itemsListStore = this.$apiStore.resolveList<Api.MenuItem>(() => ({
            endpoint: "/api/v3/menu",
            onSuccess: this.$featureStore.isAvailable("bums.common.collapsible_main_menu") ? this.onMenuLoad : void 0
        }))

        this.$sortOrderUserSetting = new UserSettingStore<string[]>(
            this.$apiStore,
            () => "mainMenuSortOrder",
            () => this.initialSortSettingValue
        )

        this.$collapsibleMenuUserSetting = new UserSettingStore(
            this.$apiStore,
            () => {
                if (this.$featureStore.isAvailable("bums.common.collapsible_main_menu")) {
                    return "collapsedMenu"
                }
            },
            () => false
        )

        this.$unreadCounter = new Api.EntityStore(this.$apiStore, () => ({
            contentType: Api.Counter.contentType,
            id: "news"
        }))

        this.$customUserLogo = new Api.EntityStore(this.$apiStore, () => ({
            contentType: Api.SystemSetting.contentType,
            id: "logo/CompanyLogo"
        }))

        this.$homeModuleSetting = new UserSettingStore(
            this.$apiStore,
            () => {
                if (this.$featureStore.isAvailable("bums.staff.flexible_home_module")) {
                    return "user_selected_home_module"
                }
            },
            () => DEFAULT_HOME_MODULE
        )

        setInterval(() => this.$unreadCounter.refresh(), TIMEOUT_REFRESH_NEWS_UNREAD_COUNTER)

    }

    @computed
    public get sortOrderUserSetting() {
        return this.$sortOrderUserSetting
    }

    @computed
    public get collapsibleMenuUserSetting() {
        return this.$collapsibleMenuUserSetting
    }

    @computed
    public get homeModuleSetting() {
        return this.$homeModuleSetting
    }

    private onMenuLoad() {
        const menuContainer = document.getElementById("t-header-main-menu")
        if (menuContainer) {
            menuContainer.className += " js-react-menu-is-loaded"
        }
    }

    @computed
    public get customUserLogoSrc(): string {
        const state = this.$customUserLogo.get()

        if (state.state === "fulfilled" && Api.isSystemSetting(state.value)) {
            const settingValue = state.value.value

            if (Api.isFile(settingValue)) {
                return settingValue.path
            }
        }

        return  ""
    }


    @computed
    private get hashMapItems() {
        const resultMap = new Map<string, Api.MenuItem>()

        const allItems = getAllItems(this.$itemsListStore.originalItems.toArray())

        allItems.forEach(item => {
            resultMap.set(item.id, item)
        })

        return resultMap
    }

    @computed
    private get queryParams() {
        return normalizeQueryParams(this.$router.location.search)
    }

    @computed
    public get isCompleted() {
        const orderSetting = this.$sortOrderUserSetting.get()

        return orderSetting.state === "fulfilled" && this.$itemsListStore.loadStateNext.isCompleted()
    }

    @computed
    public get sortOrderValue() {
        const orderSetting = this.$sortOrderUserSetting.get()
        if (orderSetting.state === "fulfilled") {
            return isIterable(orderSetting.value)
                ? orderSetting.value
                : !!orderSetting.value
                    ? prepareOldSortSettingValue(orderSetting.value)
                    : []
        }

        return [];
    }

    @computed
    private get initialSortSettingValue() {
        const initialMenuItems = Array.from(this.rootItems.keys()).filter(item => !PROFILE_MENU_ITEMS_SORTED.includes(item))

        if (this.isEnabledSettingsModule) {
            //если включена фича модуль Настройки
            //добавим их в конец списка
            initialMenuItems.push("settings")
        }

        if (this.isEnabledUpgradesModule) {
            //если включена фича модуль Расширения
            //переместим Расширения в конец списка
            initialMenuItems.splice(initialMenuItems.indexOf("upgrades"), 1)
            initialMenuItems.push("upgrades")
        }

        return initialMenuItems
    }

    @computed
    private get rootItems() {
        const resultMap = new Map<string, Api.MenuItem>()

        this.hashMapItems.forEach((item, key) => {
            if (!item.parent) {
                resultMap.set(key, item)
            }
        })

        return resultMap
    }

    @computed
    private get items() {
        if (!this.isCompleted) {
            return []
        }

        const intermediateResult: Api.MenuItem[] = []

        const hashMap = new Map(this.rootItems)

        this.sortOrderValue.concat(PROFILE_MENU_ITEMS_SORTED).forEach(itemKey => {
            if (hashMap.has(itemKey)) {
                intermediateResult.push(hashMap.get(itemKey))
            }
            hashMap.delete(itemKey)
        })

        return [...hashMap.values(), ...intermediateResult]
    }
    @computed
    public get allMainItems() {
        return this.items
            .filter(item => !PROFILE_MENU_ITEMS_SORTED.includes(item.id) || this.isEnabledSettingsModule && item.id === SETTINGS_ITEM_ID)
    }

    @computed
    public get mainItems() {
        return this.allMainItems
            .filter(item => item.isEnabled)
    }

    @computed
    public get profileItems() {
        return this.items
            .filter(item => PROFILE_MENU_ITEMS_SORTED.includes(item.id))
            .sort((a, b) => PROFILE_MENU_ITEMS_SORTED.indexOf(a.id) - PROFILE_MENU_ITEMS_SORTED.indexOf(b.id))
    }

    private get isEnabledSettingsModule() {
        return this.$featureStore.isAvailable("bums.common.setting_module")
    }

    private get isEnabledUpgradesModule() {
        return this.$featureStore.isAvailable("bums.common.upgrades_module")
    }

    public async saveItems() {
        const preparedItems = this.allMainItems.map(item => {
            return {
                contentType: Api.MenuItem.contentType,
                id: item.id,
                isEnabled: item.isEnabled
            }
        })

        await this.$apiStore.fetch(
            this.$itemsListStore.endpoint,
            {
                method: "POST",
                body: preparedItems
            }
        )
    }

    @computed
    public get settingsRootItem() {
        return this.hashMapItems.get(SETTINGS_ITEM_ID)
    }

    @computed
    public get settingsRootItemSubmenu() {
        return this.settingsRootItem?.subMenu.filter(this.checkSubItemIsActive).first()
    }

    @computed
    public get settingsAccountItem() {
        return this.settingsRootItem
            ? this.settingsRootItem.subMenu.find(item => item.id === SETTINGS_ITEM_ACCOUNT_ID)
            : void 0
    }

    @computed
    private get itemUrlsMap(): Map<string, string[]> {
        const result = new Map<string, string[]>()
        const cacheMap = new Map<string, string[]>()

        this.hashMapItems.forEach(item => {
            result.set(item.id, generateUrls(item, cacheMap))
        })

        return result
    }

    @computed
    public get selectedItemsSet() {
        const activeElements = new Map<string, number>()
        const currentUrl = this.$router.location.pathname
        const currentOrigin = this.$router.location.origin

        this.itemUrlsMap.forEach((urls, key) => {
            const closestUrls = weightDetermination(currentOrigin, currentUrl, this.queryParams, urls)
            if (closestUrls.length !== 0) {
                activeElements.set(key, Math.max(...closestUrls))
            }
        })

        if (activeElements.size === 0) {
            return new Set<string>()
        }

        // сортируем по большему весу
        const [oneRelevant, twoRelevant] = Array.from(activeElements).sort((a, b) => b[1] - a[1])
        let relevantItemId = oneRelevant[0]

        // Если есть два элемента с одни весом, то это могут быть пункты по умолчанию (вложенные)
        if (!!twoRelevant && oneRelevant[1] === twoRelevant[1])  {
            const oneRelevantItem = this.hashMapItems.get(oneRelevant[0])
            if (oneRelevantItem.subMenu && !!oneRelevantItem.subMenu.find(item => item.id === twoRelevant[0])) {
                relevantItemId = twoRelevant[0]
            }
        }

        return new Set<string>(getAllParentIds(this.hashMapItems.get(relevantItemId)))
    }

    @computed
    public get activeRootItemId() {
        return Array.from(this.selectedItemsSet).find(itemId => !this.hashMapItems.get(itemId).parent)
    }

    @computed
    public get activeRootItemName() {
        if (this.activeRootItemId) {
            const activeItem = this.allMainItems.find(item => item.id === this.activeRootItemId)
            return activeItem ? activeItem.title : ""
        }
        return ""
    }

    @computed
    public get potentialHeight() {

        if (this.$featureStore.isAvailable("bums.common.collapsible_main_menu")) {
            return this.isMenuCollapsed ? TYPE_SMALL_HEIGHT : TYPE_FULL_HEIGHT
        }

        return this.isPotentialLineType ? TYPE_SMALL_HEIGHT : TYPE_FULL_HEIGHT
    }

    @computed
    public get realHeight() {
        if (this.isLineType) {
            return TYPE_LINE_HEIGHT
        } else if (this.isSmallType) {
            return TYPE_SMALL_HEIGHT
        } else {
            return TYPE_FULL_HEIGHT
        }
    }

    public checkItemIsActive(entity: Api.MenuItem) {
        return entity.id === this.activeRootItemId
    }

    public checkSubItemIsActive(entity: Api.MenuItem) {
        return this.selectedItemsSet.has(entity.id)
    }

    @computed
    public get currentColor() {
        if (!this.activeRootItemId || !this.hashMapItems.has(this.activeRootItemId)) {
            return {...Api.UserColor.newObject}
        }

        return this.hashMapItems.get(this.activeRootItemId).color
    }

    @computed
    public get unreadCount() {
        const unreadCountValue = this.$unreadCounter.get()
        return unreadCountValue.state === "fulfilled" ? unreadCountValue.value.count : 0
    }

    @action
    public changeMenuType(type: MenuType) {
        if (this.$type === type) {
            return
        }

        this.$type = type
    }

    @computed
    public get isMenuCollapsed() {
        const setting = this.$collapsibleMenuUserSetting.get()
        if (setting.state === "fulfilled") {
            return setting.value
        }
        return false
    }

    @computed
    public get isPotentialLineType() {
        return this.mediaQueryForLineType.matches
    }

    @computed
    public get isFullType() {
        if (this.$featureStore.isAvailable("bums.common.collapsible_main_menu")) {
            return !this.isMenuCollapsed
        }
        return !this.isPotentialLineType
    }

    @computed
    public get isLineType() {
        return this.isPotentialLineType && this.$type !== "small"
    }

    @computed
    public get isSmallType() {
        return this.$type === "small"
    }

    public getUrlAddStruct(entity: Api.MenuItem) {
        if (!(hasInternalLink(entity.url) && entity.url.indexOf("/add") !== -1)) {
            return void 0
        }

        const scope = getUrlAddStruct(entity.url)

        if (!scope) {
            return void 0
        }

        const isEnable = scope.features.length === 0 || this.$featureStore.isEnabledForUser(scope.features)

        if (isEnable) {
            return scope
        }

        return void 0
    }

    public getItemById(id: string) {
        return this.hashMapItems.get(id)
    }

    public setCurrentState(mainMenuState: MainMenuStateStore) {
        this.$currentState = mainMenuState
    }

    public getCurrentState() {
        return this.$currentState
    }

    public getOrCreateCurrentState() {
        if (!this.$currentState) {
            this.$currentState = new MainMenuStateStore(this)
        }
        return this.$currentState
    }
}
