import {Component} from "src/lib/components"
import {observer} from "mobx-react"
import {action, observable, computed} from "mobx"
import * as React from "react"
import * as PropTypes from "prop-types"
import {AdaptiveType, AdaptPoints} from "./AdaptiveObservable"
import {AutoSizer, Dimensions} from "react-virtualized"

import classNames from "classnames/bind"

const style = classNames.bind(require("./CAdapt.styl"));

/**
 * Позволяет пробросить форсированный режим адаптивности всем детям
 * при отсутствии типа использует размер своего блока для проброса режима адаптивности всем потомкам.
 * Точки перехода наследуются от обычной адативности.
 */
@observer
export class CAdaptProvider extends Component<CAdaptProvider.Props, {}> {

    @observable
    public blockAdaptType: AdaptiveType = "desktop"

    @action
    setBlockAdaptType = (info: Dimensions) => {

        if (info.width > AdaptPoints.DESKTOP) {
            this.blockAdaptType = "desktop"
        }

        if (AdaptPoints.DESKTOP >= info.width &&
            info.width > AdaptPoints.TABLET) {
            this.blockAdaptType = "tablet"
        }

        if (AdaptPoints.TABLET >= info.width) {
            this.blockAdaptType = "mobile"
        }
    }

    public static contextTypeKey = "CAdaptProvider_adaptiveType"

    public getChildContext() {
        return {
            [CAdaptProvider.contextTypeKey]: computed(() => this.props.type ? this.props.type : this.blockAdaptType)
        }
    }

    public static childContextTypes = {
        [CAdaptProvider.contextTypeKey]: PropTypes.object
    }

    public render() {
        return this.props.type
            ? React.Children.only(this.props.children)
            : <div className={style("provider")}>
                <AutoSizer onResize={(info) => this.setBlockAdaptType(info)}>
                    {() => <div/>}
                </AutoSizer>
                {this.props.children}
            </div>
    }
}

export namespace CAdaptProvider {
    export interface Props {
        type?: AdaptiveType
    }
}
