/* tslint:disable */
import * as H from "../metadataHelper"
import {Reminder} from "./reminder"
import {EnumField} from "./enumField"

export interface DefaultReminder extends H.BaseValue {
    type?: DefaultReminder.Type;
    reminder?: Reminder;
    contentType: "DefaultReminder"; // Object type
}

export module DefaultReminder {
    export const contentType: "DefaultReminder" = "DefaultReminder";
    export const endpoint = "/api/v3/defaultReminder";
    export type Type = "milestone"|"project"|"task"|"todo";
    export module Type {
        export const milestone: Type = "milestone";
        export const project: Type = "project";
        export const task: Type = "task";
        export const todo: Type = "todo";
    }
    export const newObject: DefaultReminder = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const type = {contentType: "EnumField" as "EnumField", name: "type", isMultiple: false, isSortable: false, id: "DefaultReminder.type", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["milestone", "project", "task", "todo"])};
        export const reminder = {contentType: "RefLinkField" as "RefLinkField", name: "reminder", isMultiple: false, isSortable: false, id: "DefaultReminder.reminder", refContentType: H.List(["Reminder"])};
    }
}

export function isDefaultReminder(arg: any): arg is DefaultReminder {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "DefaultReminder"
}
