import * as React from "react"
import classNames from "classnames/bind"
import {observer} from "mobx-react"
import {autobind} from "core-decorators"
import {CIcon, Component, CEllipsis} from "src/lib/components"

const style: any = classNames.bind(require("../CAutocomplete.styl"))

export namespace CFilterGroup {
   export interface Props {
       title: string
       expanded: boolean
       onExpand: () => void
       selected?: boolean
   }
   export interface ItemProps {
       title: string
       selected: boolean
       onSelect?: () => void
   }
}

@observer
@autobind
export class CFilterGroup extends Component<CFilterGroup.Props, {}> {

    public render() {
        const {expanded, selected} = this.props

        return <div className={style("filterGroup", {expanded})}>
            <div className={style("filterTitle", {selected})} onClick={this.props.onExpand}>
                <CEllipsis>{this.props.title}</CEllipsis>
                <CIcon
                    type={expanded ? CIcon.Type.KEYBOARD_ARROW_DOWN : CIcon.Type.KEYBOARD_ARROW_RIGHT}
                    className={style("filterGroupArrow")}
                    size={CIcon.Size.SMALL}
                />
            </div>
            {expanded && this.props.children}
        </div>
    }
}

@observer
@autobind
class CFilterItem extends Component<CFilterGroup.ItemProps, {}> {
    public render() {
        return <div className={style("filterItem", "filterTitle", {selected: this.props.selected})} onClick={this.props.onSelect}>
            <CEllipsis>{this.props.title}</CEllipsis>
        </div>
    }
}

export namespace CFilterGroup {
    export const Item = CFilterItem
}
