import {CARD_SUBJECT} from "../constants"
import {CardMeta} from "../types"

export {addCard, isAddCardAction} from "./addCard"
export {removeCard, isRemoveCardAction} from "./removeCard"
export {resetCard, isRefreshCard} from "./resetCard"
export {clearUnsavedValues, isClearUnsavedValues} from "./clearUnsavedValues"
export {updateUnsavedValue, isUpdateUnsavedValueAction} from "./updateUnsavedValue"
export {removeUnsavedValue, isRemoveUnsavedValueAction} from "./removeUnsavedValue"
export {switchCollapse, isSwitchCollapseAction} from "./switchCollapse"
export {switchEditMode, isSwitchEditModeAction} from "./switchEditMode"
export {showPopup, isShowPopupAction} from "./showPopup"
export {switchEditableSection, isSwitchEditableSectionAction} from "./switchEditableSection"

export function isAnyCardAction(action: any): action is CardMeta {
    return "object" === typeof action && action.meta && action.meta.subject === CARD_SUBJECT
}
