import * as React from "react"
import classNames from "classnames/bind"
import {observer} from "mobx-react"
import {Component} from "src/lib/components"

const style = classNames.bind(require("./CHeaderCaption.styl"));

export namespace CHeaderCaption {
    export interface Props {
        short?: boolean
        hoverEffect?: boolean
        className?: string | string[]
    }
}

/**
 * Заголовок карточки
 */
@observer
export class CHeaderCaption extends Component<CHeaderCaption.Props, {}> {

    public render() {
        return <div
            className={style("CHeaderCaption", {short: this.props.short, hoverEffect: this.props.hoverEffect}, this.props.className)}
        >
            {this.props.children}
        </div>
    }
}
