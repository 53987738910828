// @see https://gitlab.com/catamphetamine/libphonenumber-js#customizing-metadata
import {
    parsePhoneNumberFromString as _parsePhoneNumberFromString,
    findPhoneNumbersInText as _findPhoneNumbersInText,
    AsYouType as _AsYouType
} from "libphonenumber-js/core"
import {CountryCode, NumberFound, PhoneNumber} from "libphonenumber-js/types"

const customMetadata = require("./metadata.custom.json")

function call<T extends (...args: any) => any>(func: T, _arguments: Parameters<T>) {
    const args = Array.prototype.slice.call(_arguments)
    args.push(customMetadata)
    return func.apply(this, args)
}

export class AsYouType extends _AsYouType {
    constructor(locale: string) {
        super(iso639ToCountyCode(locale), customMetadata)
    }
}

export function parsePhoneNumberFromString(text: string, defaultCountry: CountryCode = "RU"): PhoneNumber | undefined {
    return call(_parsePhoneNumberFromString, arguments as any)
}

export function findPhoneNumbersInText(text: string, options: CountryCode | { defaultCountry?: CountryCode } = "RU"): NumberFound[] {
    return call(_findPhoneNumbersInText, arguments as any)
}

export function iso639ToCountyCode(locale: string): CountryCode {
    switch (locale) {
        case "uk":
            return "UA"
        case "be":
            return "BE"
        case "kk":
            return "KZ"
        default:
            return locale.toUpperCase() as CountryCode
    }
}
