export const PROGRAM_ID = "tutorial"

export namespace GREETING_PROMO {
    export const step = "GreetingPromo"
    export const promoClick = "TutorialPromoClick"
}

export namespace GATHER_THE_TEAM {
    export const step = "GatherTheTeam"
    export const teamSize = "TeamSize"
    export const inviteClick = "InviteClick"
    export const skip = "SkipClick"
}

export namespace TASK_CREATE {
    export const step = "CreateFirstTask"
    export const created = "TaskCreated"
    export const skip = "SkipClick"
}

export namespace MODULES_HINT {
    export const step = "ModulesHint"
    export const settingClick = "SettingsClick"
    export const next = "NextClick"
    export const moduleClick = "AnyModuleClick"
    export const cancel = "CancelClick"
}

export namespace INFORMER_HINT {
    export const step = "InformerHint"
    export const informerClick = "InformerClick"
    export const cancel = "CancelClick"
}

export const TRACKED_ELEMENT_CLASS_NAME = "___tracked_element"

export const PROMO_DASHBOARD_EVENT_TYPE = "Promo dashboard" // блок события на промо рабочем столе
export const START_WORK_EVENT = "Start Work" // "Начать работу" на промо дашборде

export const POPUP_TOUR_EVENT_TYPE = "Popup tour" // блок событий для попап тура
export const SKIP_FIRST_TASK_EVENT = "First Task: skip" // пропустить создание первой задачи
export const CREATE_TASK_EVENT = "First Task: create" // создать первую задачу

export const COPY_LINK_EVENT = "Invite: copy link" // скопировать ссылку для приглашения сотрудников
export const SKIP_EVENT = "Invite: skip" // пропустить приглашение сотрудников
export const SUBMIT_EVENT = "Invite: submit" // пригласить сотрудников

export const OPEN_RATING_EVENT = "Open Rating" // открыть рейтинг в продуктовом туре
export const RATE_EVENT = "Rate" // поставить оценку
export const SEND_RATING_EVENT = "Send Rating" // отправить отзыв
export const PRODUCT_QUEST_EVENT_TYPE = "Help widget" // блок событий по продуктовому туру
export const SKIP_QUEST_ITEM_EVENT = "Skip Tour Item" // пропустить пункт продуктового тура
export const OPEN_QUEST_EVENT = "Open Tour" // открыть продуктовый тур
export const CLICK_ITEM_EVENT = "Open Tour Item" // открыть пункт продуктового тура
export const COMPLETE_ITEM_EVENT = "Complete Tour Item" // выполнить пункт продуктового тура
export const OPEN_PROMOCODE_EVENT = "Open Promocode" // перейти к скидке после прохождения продуктового тура
export const RETURN_EVENT = "Return" // вернуться на предыдущий экран
export const OPEN_HELP_EVENT = "Open Help" // открыть контекстный хэлп
export const OPEN_LINK_EVENT = "Open Link" // перейти в FAQ или обращение в тех поддержку
export const OPEN_HELP_LINK_EVENT = "Open Help Link" // перейти в Помощь из контекстного хэлпа
export const HELP_PARAM = "help"
export const MODULE_HELP_PARAM = "moduleHelp"
export const MODULE_HELP_ARTICLE_PARAM = "moduleHelpArticle"
