import * as React from "react"
import {observer} from "mobx-react"
import {Component, CFieldRow} from "src/lib/components"
import {Form} from "src/lib/utils/form/form"

export namespace CFormOwnErrors {
   export interface Props {
       form: Form<any>
   }
}

@observer
export class CFormOwnErrors extends Component<CFormOwnErrors.Props, {}> {

    public render() {
        if (this.props.form.ownErrors.length <= 0) {
            return null
        }
        return <CFieldRow errors={this.props.form.ownErrors}/>
    }
}
