import * as React from "react"
import {observer} from "mobx-react"
import {action, observable} from "mobx"
import {autobind} from "core-decorators"
import classNames from "classnames/bind"
import {makeRefHandler} from "src/lib/utils/func"
import {Component} from "src/lib/components"

export interface CScrollViewProps {
    scrollType: "horizontal" | "vertical" | "both"
    className?: string
    refElement?: Element
    customVerticalRef?: Element | Window
    customHorizontalRef?: Element
    // включает опцию доскролливания после отпускания мышки
    smoothScroll?: boolean
    dragCursor?: boolean
    useTouchEventScroll?: boolean
}

const baseImpulse = 10 // Scroll base impulse
const wheelImpulse = 0.7 // Mouse wheel base impulse
const maxImpulse = 100
const friction = 0.8

const style = classNames.bind(require("./CScrollView.styl"))

// Class that allow you to scroll it's content with mouseWheel, finger or mouse
@autobind
@observer
export class CScrollView extends Component<CScrollViewProps, {}> {

    public static defaultProps = {
        dragCursor: true,
        useTouchEventScroll: true,
    }

    public scrollView: Element

    public horizontalScrollView: Element

    private verticalScrollView: Element | Window

    @observable
    private isBeingMoved: boolean

    // To calculate immediate delta
    public previousPoint = {
        x: 0,
        y: 0
    }

    // To calculate velocity after stop
    private startPoint = {
        x: 0,
        y: 0
    }

    private startTime: number

    private postScrollHandler: any = null

    private scrollImpulse = 0

    public componentWillUnmount() {
        this.dragCleanUp()
        this.stopPostScroll()
    }

    private onMouseDown (e: React.MouseEvent<HTMLElement>) {
        this.stopPostScroll()
        if (e.button === 0) { // Left button only
            this.prepareElements()
            this.setStart(e.screenX, e.screenY)
            window.addEventListener("mouseup", this.nativeOnUp)
            window.addEventListener("mousemove", this.nativeOnMouseMove)
        }
    }

    private onTouchStart(e: React.TouchEvent<HTMLElement>) {
        this.prepareElements()
        this.stopPostScroll()
        this.setStart(e.touches[0].screenX, e.touches[0].screenY)
        window.addEventListener("touchend", this.nativeOnUp)
        window.addEventListener("touchmove", this.nativeOnToucheMove)
    }

    public prepareElements() {
        const scrollView = this.props.refElement
            ? this.props.refElement
            : this.scrollView

        this.verticalScrollView = this.props.customVerticalRef
            ? this.props.customVerticalRef
            : scrollView

        this.horizontalScrollView = this.props.customHorizontalRef
            ? this.props.customHorizontalRef
            : scrollView
    }

    @action
    private dragCleanUp() {
        this.isBeingMoved = false
        window.removeEventListener("mouseup", this.nativeOnUp)
        window.removeEventListener("mousemove", this.nativeOnMouseMove)
        window.removeEventListener("touchend", this.nativeOnUp)
        window.removeEventListener("touchmove", this.nativeOnToucheMove)
    }

    @action
    private setStart(x: number, y: number) {
        this.isBeingMoved = true
        this.startPoint.x = x
        this.startPoint.y = y
        this.previousPoint.x = x
        this.previousPoint.y = y
        this.startTime = Date.now()
    }

    // Wrapper for native addEventListener
    private nativeOnUp(e: MouseEvent | TouchEvent) {
        e.preventDefault()
        e.stopImmediatePropagation()
        this.dragCleanUp()
        // Continue scrolling a little bit

        if (this.props.smoothScroll) {
            const scrollDelta = this.startPoint.x - this.previousPoint.x

            const scrollTime: number = Date.now() - this.startTime
            if (scrollDelta !== 0 && scrollTime !== 0) {
                this.startPostScroll((scrollDelta / scrollTime) * baseImpulse)
            }
        }
    }

    private nativeOnMouseMove(e: MouseEvent) {
        if (this.isBeingMoved) {
            e.stopPropagation()
            e.preventDefault()
            this.setScroll(e.screenX, e.screenY)
        }
    }

    private nativeOnToucheMove = (e: TouchEvent) => {
        if (this.isBeingMoved) {
            e.stopPropagation()
            e.preventDefault()
            this.setScroll(e.touches[0].screenX, e.touches[0].screenY)
        }
    }

    public setScroll(x: number, y: number) {
        const deltaX = this.previousPoint.x - x
        const deltaY = this.previousPoint.y - y

        if (this.props.scrollType === "horizontal") {
            this.previousPoint.x = x

            this.scrollHorizontal(deltaX)
        } else if (this.props.scrollType === "vertical") {
            this.previousPoint.y = y

            this.scrollVertical(deltaY)
        } else if (this.props.scrollType === "both") {
            this.previousPoint.x = x
            this.previousPoint.y = y

            this.scrollHorizontal(deltaX)
            this.scrollVertical(deltaY)
        }
    }

    private startPostScroll(impulse: number) {
        if (this.postScrollHandler) {
            // Wind it up! Uh, uh, uh, uh! yodellay, yodallay, yodal-low
            this.scrollImpulse = Math.min(this.scrollImpulse + impulse, maxImpulse)
        } else {
            this.scrollImpulse = Math.min(impulse, maxImpulse)
            this.postScrollHandler = setInterval(() => {
                if (this.props.scrollType === "horizontal") {
                    this.scrollHorizontal(this.scrollImpulse)
                } else if (this.props.scrollType === "vertical") {
                    this.scrollVertical(this.scrollImpulse)
                } else {
                    this.scrollHorizontal(this.scrollImpulse)
                    this.scrollVertical(this.scrollImpulse)
                }

                this.scrollImpulse *= friction
                if (this.scrollImpulse > -2 && this.scrollImpulse < 2) {
                    this.stopPostScroll()
                }
            }, 20)
        }
    }

    private scrollHorizontal(scrollLeft: number) {
        this.horizontalScrollView.scrollLeft = this.horizontalScrollView.scrollLeft + scrollLeft
    }

    private scrollVertical(scrollTop: number) {
        if (this.verticalScrollView instanceof Window) {
            this.verticalScrollView.scrollTo(0, this.verticalScrollView.scrollY + scrollTop)
        } else {
            this.verticalScrollView.scrollTop = this.verticalScrollView.scrollTop + scrollTop
        }
    }

    public stopPostScroll() {
        clearInterval(this.postScrollHandler)
        this.postScrollHandler = null
    }

    private onMouseWheel(e: React.WheelEvent<HTMLElement>) {
        e.stopPropagation()
        e.preventDefault()
        this.startPostScroll(e.deltaY * wheelImpulse)
    }

    public render() {
        return <div
            className={`${this.props.className || ""} ${this.props.dragCursor && this.isBeingMoved ? style("draggingCursor") : ""}`}
            ref={makeRefHandler(this, "scrollView")}
            onMouseDown={this.onMouseDown}
            onTouchStart={this.props.useTouchEventScroll ? this.onTouchStart : void 0}
            onWheel={this.props.scrollType !== "vertical" ? void 0 : this.onMouseWheel}
        >
            {this.props.children}
        </div>
    }
}
