/* tslint:disable */
import * as H from "../metadataHelper"


export interface TodoAcceptInvitationActionRequest extends H.BaseValue {
    contentType: "TodoAcceptInvitationActionRequest"; // Object type
}

export module TodoAcceptInvitationActionRequest {
    export const contentType: "TodoAcceptInvitationActionRequest" = "TodoAcceptInvitationActionRequest";
    export const endpoint = "/api/v3/todoAcceptInvitationActionRequest";

    export const newObject: TodoAcceptInvitationActionRequest = {contentType}
    Object.freeze(newObject)

    export module fields {}
}

export function isTodoAcceptInvitationActionRequest(arg: any): arg is TodoAcceptInvitationActionRequest {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "TodoAcceptInvitationActionRequest"
}
