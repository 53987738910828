import {IAtom, createAtom, computed} from "mobx"

export type AdaptiveType = "desktop" | "tablet" | "mobile" | "touch" | "untouch"

export const AdaptPoints = {
    "DESKTOP": 1280,
    "TABLET": 768,
    "MOBILE": 320
}

/**
 * Media query в виде observable значения
 * @see https://mobx.js.org/refguide/extending.html
 */
export class ObservableMediaQuery {

    private atom: IAtom
    private mediaQuery: MediaQueryList
    private currentMatches: boolean

    constructor(mediaQuery: string) {
        this.atom = createAtom (
            `Observable media query: ${mediaQuery}`,
            () => this.subscribe(),
            () => this.unsubscribe()
        )
        this.mediaQuery = window.matchMedia(mediaQuery)
    }

    public get matches(): boolean {
        if (this.atom.reportObserved()) {
            return this.currentMatches
        } else {
            return this.mediaQuery.matches
        }
    }

    private onMediaQueryChanged = () => {
        const tmpMatches = this.mediaQuery.matches
        if (this.currentMatches !== tmpMatches) {
            this.currentMatches = tmpMatches
            this.atom.reportChanged()
        }
    }

    private subscribe() {
        this.mediaQuery.addListener(this.onMediaQueryChanged)
        this.onMediaQueryChanged() // init calculation
    }

    private unsubscribe() {
        this.mediaQuery.removeListener(this.onMediaQueryChanged)
    }
}

/**
 * Вычисленное значение режима адаптивности
 */
class ComputedAdaptiveType {
    private desktopMq = new ObservableMediaQuery(`(min-width: ${AdaptPoints.DESKTOP}px)`)
    private tabletMq = new ObservableMediaQuery(`(min-width: ${AdaptPoints.TABLET}px) and (max-width: ${AdaptPoints.DESKTOP - 1}px)`)
    private mobileMq = new ObservableMediaQuery(`(max-width: ${AdaptPoints.TABLET - 1}px)`)
    private printMq = new ObservableMediaQuery(`print`)

    private cachedQuery = "desktop"

    @computed
    public get type() {
        if (this.isPrint) {
            return this.cachedQuery
        }
        if (this.desktopMq.matches) {
            this.cachedQuery = "desktop"
            return "desktop"
        } else if (this.tabletMq.matches) {
            this.cachedQuery = "tablet"
            return "tablet"
        } else if (this.mobileMq.matches) {
            this.cachedQuery = "mobile"
            return "mobile"
        } else {
            return this.cachedQuery
        }
    }

    @computed
    public get isPrint() {
        return this.printMq.matches
    }
}

export class AdaptiveObservable {

    private static computedAdaptive: ComputedAdaptiveType

    public get type() {
        if (!AdaptiveObservable.computedAdaptive) {
            AdaptiveObservable.computedAdaptive = new ComputedAdaptiveType()
        }

        return AdaptiveObservable.computedAdaptive.type
    }

    // Определяем если страница в режиме печати
    public get getIsPrint() {
        if (!AdaptiveObservable.computedAdaptive) {
            AdaptiveObservable.computedAdaptive = new ComputedAdaptiveType()
        }

        return AdaptiveObservable.computedAdaptive.isPrint
    }
}
