import {computed, observable, action} from "mobx"
import {autobind} from "core-decorators"
import {EventEmitter} from "events"
import {DocumentVisibility, DocumentVisibilityListener} from "./types"

@autobind
export class DocumentVisibilityStore implements DocumentVisibility {

    protected $eventEmmiter = new EventEmitter()

    @observable
    protected $visible: boolean

    constructor() {
        if (!process.env.REACT_NATIVE && window.document) {
            this.$visible = !window.document.hidden
            window.document.addEventListener("visibilitychange", this.visibilityChangeListener, false)
        } else {
            this.$visible = true
        }
    }

    @action
    protected visibilityChangeListener() {
        this.$visible = !window.document.hidden
        this.$eventEmmiter.emit("change", this.$visible)
    }

    public subscribe(listener: DocumentVisibilityListener) {
        this.$eventEmmiter.addListener("change", listener)
    }

    public unsubscribe(listener: DocumentVisibilityListener) {
        this.$eventEmmiter.removeListener("change", listener)
    }

    @computed
    public get visible() {
        return this.$visible
    }
}
