/* tslint:disable */
import * as H from "../metadataHelper"
import {SelectableArrayVariable} from "./selectableArrayVariable"
import {StringField} from "./stringField"
import {IntegerField} from "./integerField"

export interface SelectableArrayVariant extends H.BaseValue {
    value?: string;
    valueModel?: H.BaseEntity;
    variable?: SelectableArrayVariable;
    key?: number;
    contentType: "SelectableArrayVariant"; // Object type
}

export module SelectableArrayVariant {
    export const contentType: "SelectableArrayVariant" = "SelectableArrayVariant";
    export const endpoint = "/api/v3/selectableArrayVariant";

    export const newObject: SelectableArrayVariant = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const value = {contentType: "StringField" as "StringField", name: "value", isMultiple: false, isSortable: false, id: "SelectableArrayVariant.value", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const valueModel = {contentType: "RefLinkField" as "RefLinkField", name: "valueModel", isMultiple: false, isSortable: false, id: "SelectableArrayVariant.valueModel", refContentType: H.List(["BaseEntity"])};
        export const variable = {contentType: "RefLinkField" as "RefLinkField", name: "variable", isMultiple: false, isSortable: false, id: "SelectableArrayVariant.variable", refContentType: H.List(["SelectableArrayVariable"])};
        export const key = {contentType: "IntegerField" as "IntegerField", name: "key", isMultiple: false, isSortable: false, id: "SelectableArrayVariant.key", defaultValue: null as IntegerField["defaultValue"]};
    }
}

export function isSelectableArrayVariant(arg: any): arg is SelectableArrayVariant {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "SelectableArrayVariant"
}
