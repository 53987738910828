import {autobind} from "core-decorators"
import {action, computed, observable, runInAction} from "mobx"
import React from "react"
import moment from "moment"
import * as Api from "src/lib/entities/api"
import {inject} from "src/lib/utils/inject"
import {LockStore} from "src/lib/utils/LockStore"
import {Ecomet} from "src/lib/services/Ecomet"
import {MessageSubscription} from "src/bums/common/subscriptions/MessageSubscription"
import {MobileFeaturesStore} from "src/bums/common/stores/MobileFeaturesStore"
import {List} from "src/lib/collections"
import {FeatureStore} from "src/bums/common/stores/FeatureStore"
import {CorporateDiscussion} from "src/lib/utils/chat"
import {CommentsCacheStore} from "src/bums/common/stores/CommentsCacheStore"
import {InformerStore} from "src/bums/common/informerPanel/informerStore"
import {isCorporateDiscussion} from "src/lib/utils/chat"
import {EVENT_TYPE_CHAT_WIDGET} from "src/bums/employee/utils"
import {
    CHAT_MENU_ITEM_OPEN_EVENT,
    HIDE_TOPIC_ACTION,
    MARK_AS_READ_ACTION,
    MARK_AS_UNREAD_ACTION,
    NOTIFICATION_OFF_ACTION,
    NOTIFICATION_ON_ACTION,
    PIN_TOPIC_ACTION,
    RESTORE_TOPIC_ACTION,
    UNPIN_TOPIC_ACTION,
} from "src/bums/common/chat/stores/utils"
import {TopicFoldersStore} from "src/bums/common/stores/TopicFoldersStore"
import {AutocompleteFilterStore} from "src/lib/components/CAutocomplete/AutocompleteFilterStore"

export const TOPICS_LIMIT = process.env.REACT_NATIVE ? 10 : 25

export const TopicsSearchContext = React.createContext<AutocompleteFilterStore<Api.Topic | Api.Employee>>(null)

const defaultFields = [
    Api.Topic.fields.isUnread.name,
    Api.Topic.fields.subscribed.name,
    Api.Topic.fields.isDiscussable.name,
    {"lastComment": [Api.Comment.fields.owner.name]}
]

@autobind
export class TopicsStore {

    @observable.ref
    public messagesList: Api.ListStore<Api.Topic>

    @observable.ref
    public messagesListFolder: Api.ListStore<Api.Topic>

    @observable.ref
    public foldersList: Api.ListStore<Api.TopicFolder>

    @observable.ref
    public corporateDiscussionsList: Api.ListStore<Api.Topic>

    @observable.ref
    public hiddenTopicsListCounter: Api.ListStore<Api.Topic>

    @observable.ref
    public subordinateTopicsListCounter: Api.ListStore<Api.Topic>

    private hiddenTopicsList: Api.ListStore<Api.Topic>

    private subordinateTopicsList: Api.ListStore<Api.Topic>

    protected messageSubscription: MessageSubscription

    @observable
    public listOptionsFields: string|{}[]

    public topicFoldersStore: TopicFoldersStore

    constructor(
        @inject(Api.Store) public apiStore: Api.Store,
        @inject(LockStore) public lockStore: LockStore,
        @inject(Api.UserStore) public userStore: Api.UserStore,
        @inject(Ecomet) public ecomet: Ecomet,
        @inject(MobileFeaturesStore) public mobileFeaturesStore: MobileFeaturesStore,
        @inject(FeatureStore) public featureStore: FeatureStore,
        @inject(CommentsCacheStore) public commentsCacheStore: CommentsCacheStore,
        @inject(InformerStore) public informerStore: InformerStore,
    ) {
        this.messageSubscription = new MessageSubscription(
            this.ecomet,
            this.apiStore,
            this.userStore,
            this.lockStore,
            () => this.messagesList
        )
        this.messageSubscription.subscribe()
    }

    @action
    public init() {

        if (this.messagesList) {
            void this.reloadList()
            return
        }

        let options: {
            endpoint: string
            limit: number
            options: {fields: string|{}[], folder?: {}}
        } = {
            endpoint: "/api/v3/topic",
            options: {
                fields: [],
            },
            limit: TOPICS_LIMIT
        }

        if (this.mobileFeaturesStore.isFeatureEnabled("topicListActions")) {
            options = {
                endpoint: "/api/v3/topic/attachedFirst",
                options: {
                    fields: defaultFields,
                },
                limit: TOPICS_LIMIT
            }
        }

        if (!this.mobileFeaturesStore.isFeatureEnabled("topicsOptimisation")) {
            options.options = {
                fields: [...options.options.fields, Api.Topic.fields.participants.name]
            }
        }

        if (this.mobileFeaturesStore.isFeatureEnabled("categories")) {
            options.options = {
                fields: [...options.options.fields, Api.Topic.fields.folders.name]
            }

            const foldersOptions = {
                endpoint: Api.TopicFolder.endpoint,
                options: {
                    fields: ["topicsCount"]
                }
            }

            this.foldersList = this.apiStore.resolveList(() => foldersOptions)
        }

        if (this.mobileFeaturesStore.isFeatureEnabled("isAvatarInTopic")) {
            options.options = {
                fields: [...options.options.fields, Api.Topic.fields.avatar.name]
            }
        }

        if (this.mobileFeaturesStore.isFeatureEnabled("isAttachedForUser")) {
            options.options = {
                fields: [...options.options.fields, Api.Topic.fields.isAttached.name]
            }
        }

        if (this.mobileFeaturesStore.isFeatureEnabled("mentionsInEntities")) {
            options.options = {
                fields: [...options.options.fields, Api.Topic.fields.unreadAnswer.name]
            }
        }

        runInAction(() => this.listOptionsFields = options.options.fields)

        this.topicFoldersStore = new TopicFoldersStore(this.apiStore, this.listOptionsFields)

        const corporateDiscussionsOptions = {
            endpoint: Api.Topic.endpoint,
            options: {
                fields: [...options.options.fields],
                filters: ["CorporateDiscussionsOnly"]
            },
            limit: TOPICS_LIMIT
        }

        this.messagesList = this.apiStore.resolveList(() => options)

        this.corporateDiscussionsList = this.apiStore.resolveList(() => corporateDiscussionsOptions)

        if (this.mobileFeaturesStore.isFeatureEnabled("topicListActions")) {
            this.hiddenTopicsListCounter = this.apiStore.resolveList(() => {
                return {
                    preloadedListName: "hiddenTopics",
                    endpoint: "/api/v3/topic/hidden",
                    limit: 0,
                }
            })
        }

        if (this.featureStore.isAvailable("bums.discuss.subordinates_chats_feature")) {
            this.subordinateTopicsListCounter = this.apiStore.resolveList(() => {
                return {
                    endpoint: "/api/v3/topic/subordinatesChats",
                    limit: 0,
                }
            })
        }
    }

    public getHiddenTopicsList = () => {
        if (!this.hiddenTopicsList) {
            this.hiddenTopicsList = this.apiStore.resolveList(() => {
                return {
                    preloadedListName: "hiddenTopics",
                    endpoint: "/api/v3/topic/hidden",
                    options: {
                        fields: defaultFields,
                    },
                    limit: TOPICS_LIMIT,
                }
            })
        }

        return this.hiddenTopicsList
    }

    public getSubordinateTopicsList = () => {
        if (!this.subordinateTopicsList) {
            this.subordinateTopicsList = this.apiStore.resolveList(() => {
                return {
                    endpoint: "/api/v3/topic/subordinatesChats",
                    options: {
                        fields: defaultFields,
                    },
                    limit: TOPICS_LIMIT,
                }
            })
        }

        return this.subordinateTopicsList
    }

    public getSubordinatesCount = () => {
        if (!this.featureStore.isAvailable("bums.discuss.subordinates_chats_feature")) {
            return
        }

        if (!this.subordinateTopicsListCounter) {
            this.subordinateTopicsListCounter = this.apiStore.resolveList(() => {
                return {
                    endpoint: "/api/v3/topic/subordinatesChats",
                    limit: 0,
                }
            })
        }

        return this.subordinateTopicsListCounter.totalItemsCount
    }

    public createSearchStore = () => {
        return new AutocompleteFilterStore<Api.Topic | Api.Employee>(
            this.apiStore,
            () => ({
                endpoint: "/api/v3/chatListSearch",
                defaultFilter: void 0,
            })
        )
    }

    @action
    public deleteFolder = async (id: string) => {
        await this.apiStore.fetch<Api.TopicFolder>(
            `${Api.TopicFolder.endpoint}/${id}`,
            {
                method: "DELETE",
            }

        )
        this.foldersList.reloadList()
    }

    @computed
    public get hiddenTopicsCount() {
        if (this.hiddenTopicsList?.totalItemsCount !== undefined) {
            return this.hiddenTopicsList.totalItemsCount
        }

        return this.hiddenTopicsListCounter?.totalItemsCount
    }

    @computed
    public get subordinatesTopicsCount() {

        if (!this.subordinateTopicsListCounter) {
            return 0
        }

        if (this.subordinateTopicsList?.totalItemsCount !== undefined) {
            return this.subordinateTopicsList.totalItemsCount
        }

        return this.subordinateTopicsListCounter?.totalItemsCount
    }

    @computed
    public get count() {
        return this.messagesList.totalItemsCount ? this.messagesList.totalItemsCount : 0
    }

    @computed
    public get totalItemsCount() {

        if (typeof this.corporateDiscussionsList.totalItemsCount === "number" && typeof this.messagesList.totalItemsCount === "number") {
            return this.corporateDiscussionsList.totalItemsCount + this.messagesList.totalItemsCount
        }

        return this.messagesList.totalItemsCount
    }

    @computed
    public get isValid() {
        return this.messagesList.isValid
    }

    public loadNext() {
        return this.messagesList.loadNext()
    }

    public reloadList() {
        return Promise.all([
            this.informerStore.reloadCounters(),
            this.messagesList.reloadList(),
            this.hiddenTopicsListCounter.reloadList(),
            this.corporateDiscussionsList.reloadList(),
        ])
    }

    @computed
    public get loadStateNext() {
        return this.messagesList.loadStateNext
    }

    @computed
    public get itemsSorted() {
        return this.messagesList
            .originalItems
            .sort((a: Api.Topic, b: Api.Topic) => {

                if (a.isAttached && b.isAttached) {
                    return 0
                }

                if (!a.isAttached && b.isAttached) {
                    return 1
                }

                if (a.isAttached && !b.isAttached) {
                    return -1
                }

                const dateA = this.getCommentTimeCreated(a)
                const dateB = this.getCommentTimeCreated(b)

                return + dateB - + dateA
            })
    }

    private getCommentTimeCreated(topic: Api.Topic) {
        const commentCache = this.commentsCacheStore.comments(topic)[0]

        if (Api.isComment(commentCache)) {
            return commentCache.timeCreated
        }

        return topic.lastComment ? topic.lastComment.timeCreated : topic.timeCreated
    }

    @computed
    public get originalItems() {
        const arr = List<Api.Topic>()
        if (this.corporateDiscussionsItems?.length > 0) {
            arr.push({...this.corporateDiscussionsItems.first()})
        }

        return arr.concat(this.itemsSorted)
    }

    @computed
    public get corporateDiscussionsItems() {
        return this.corporateDiscussionsList?.originalItems
            .map(entity => {
                return {...entity, isCorporateDiscussion: true} as CorporateDiscussion
            })
            .sort((a: Api.Topic, b: Api.Topic) => {
                const dateA = a.lastComment ? a.lastComment.timeCreated : a.timeCreated
                const dateB = b.lastComment ? b.lastComment.timeCreated : b.timeCreated

                return + dateB - + dateA
            })
    }

    public markAsRead = (topic: Api.Topic) => {
        this.informerStore.tracker.trackEvent(
            EVENT_TYPE_CHAT_WIDGET,
            CHAT_MENU_ITEM_OPEN_EVENT,
            {
                action: MARK_AS_READ_ACTION
            },
            ["product", "amplitude"]
        )

        if (isCorporateDiscussion(topic)) {
            delete topic.isCorporateDiscussion
        }
        void this.apiStore.updateEntitiesFromJson({...topic, unreadCommentsCount: 0, isUnread: false})
        void this.apiStore.fetchEntities(Api.getListEndpoint(topic, "comments") + "/markAsRead", {method: "POST"})
            .then(() => {
                this.informerStore.reloadCounters()
            })
    }

    public markTopicAsUnread = (topic: Api.Topic) => {
        this.informerStore.tracker.trackEvent(
            EVENT_TYPE_CHAT_WIDGET,
            CHAT_MENU_ITEM_OPEN_EVENT,
            {
                action: MARK_AS_UNREAD_ACTION
            },
            ["product", "amplitude"]
        )

        if (isCorporateDiscussion(topic)) {
            delete topic.isCorporateDiscussion
        }
        void this.apiStore.updateEntitiesFromJson({...topic, isUnread: true})
        void this.apiStore.fetchEntities(Api.getListEndpoint(topic, "unread"), {method: "POST"})
            .then(() => {
                this.informerStore.reloadCounters()
            })
    }

    public markTopicAsRead = (topic: Api.Topic) => {
        this.informerStore.tracker.trackEvent(
            EVENT_TYPE_CHAT_WIDGET,
            CHAT_MENU_ITEM_OPEN_EVENT,
            {
                action: MARK_AS_READ_ACTION
            },
            ["product", "amplitude"]
        )

        if (isCorporateDiscussion(topic)) {
            delete topic.isCorporateDiscussion
        }
        void this.apiStore.update({...topic, isUnread: false})
        void this.apiStore.fetchEntities(Api.getListEndpoint(topic, "read"), {method: "POST"})
            .then(() => {
                this.informerStore.reloadCounters()
            })
    }

    public pinTopic = (topic: Api.Topic, value: boolean) => {
        this.informerStore.tracker.trackEvent(
            EVENT_TYPE_CHAT_WIDGET,
            CHAT_MENU_ITEM_OPEN_EVENT,
            {
                action: value ? PIN_TOPIC_ACTION : UNPIN_TOPIC_ACTION
            },
            ["product", "amplitude"]
        )

        void this.messagesList.moveEntities([{...topic, isAttached: value}])
        void this.apiStore.fetchEntities(Api.getListEndpoint(topic, "attach"), {method: value ? "PUT" : "DELETE"})
    }

    public toggleNotifications = (topic: Api.Topic) => {
        this.informerStore.tracker.trackEvent(
            EVENT_TYPE_CHAT_WIDGET,
            CHAT_MENU_ITEM_OPEN_EVENT,
            {
                action: topic.subscribed ? NOTIFICATION_OFF_ACTION : NOTIFICATION_ON_ACTION
            },
            ["product", "amplitude"]
        )

        const topicForRequest: Api.Topic = {
            contentType: Api.Topic.contentType,
            id: topic.id,
            subscribed: !topic.subscribed,
        }
        void this.apiStore.fetch(
            Api.Topic.endpoint,
            {
                method: "POST",
                bodyEntity: topicForRequest
            }
        )
            .then(this.informerStore.reloadCounters)

        void this.apiStore.updateEntitiesFromJson(topicForRequest)
    }

    public enableNotifications = (topic: Api.Topic) => {
        const topicForRequest: Api.Topic = {
            contentType: Api.Topic.contentType,
            id: topic.id,
            subscribed: true,
        }
        void this.apiStore.fetch(
            Api.Topic.endpoint,
            {
                method: "POST",
                bodyEntity: topicForRequest
            }
        )
            .then(this.informerStore.reloadCounters)

        void this.apiStore.updateEntitiesFromJson(topicForRequest)
    }

    public disableNotifications = (topic: Api.Topic) => {
        const topicForRequest: Api.Topic = {
            contentType: Api.Topic.contentType,
            id: topic.id,
            subscribed: false,
        }
        void this.apiStore.fetch(
            Api.Topic.endpoint,
            {
                method: "POST",
                bodyEntity: topicForRequest
            }
        )
            .then(this.informerStore.reloadCounters)

        void this.apiStore.updateEntitiesFromJson(topicForRequest)
    }

    public hideTopic = async (topic: Api.Topic) => {
        this.informerStore.tracker.trackEvent(
            EVENT_TYPE_CHAT_WIDGET,
            CHAT_MENU_ITEM_OPEN_EVENT,
            {
                action: HIDE_TOPIC_ACTION
            },
            ["product", "amplitude"]
        )

        await this.apiStore.entityBasedFetch(
            topic,
            "toggleChat",
            `/api/v3/chat`,
            {
                method: "DELETE",
                bodyEntity: Api.getLink(topic)
            })

        this.informerStore.reloadCounters()
        this.messagesList.removeEntities([topic])
        this.hiddenTopicsListCounter.reloadList()
        void this.hiddenTopicsList.reloadList()
    }

    public restoreTopic = async (topic: Api.Topic) => {
        this.informerStore.tracker.trackEvent(
            EVENT_TYPE_CHAT_WIDGET,
            CHAT_MENU_ITEM_OPEN_EVENT,
            {
                action: RESTORE_TOPIC_ACTION
            },
            ["product", "amplitude"]
        )

        await this.apiStore.fetch<Api.Topic>(
            `/api/v3/chat/${topic.id}/restore`,
            {method: "POST"},
        )

        this.informerStore.reloadCounters()
        this.hiddenTopicsList.removeEntities([topic])
        this.enableNotifications(topic)
        void this.reloadList()
    }

    public initiateChatWithEmployee = async (entity: Api.Employee | Api.Contractor) => {
        const time = new Date()

        const response = await this.apiStore.fetchEntities<Api.Topic>(
            Api.Topic.endpoint,
            {
                method: "POST",
                bodyEntity: {
                    contentType: Api.Topic.contentType,
                    timeCreated: time,
                    participants: [Api.getLink(entity)]
                }
            }
        )

        const topic = response.value.data[0]
        const diffInSeconds = moment(topic.timeCreated).diff(moment(time), "seconds")

        if (Math.abs(diffInSeconds) <= 5) {
            void this.messagesList.prependEntities([topic])
        }

        return topic
    }
}
