import {
    observable, extendObservable, ObservableMap, action
} from "mobx"

import {BaseEntity, BaseValue} from "src/lib/entities/types";
import {MetadataProvider} from "src/lib/entities/metadataProvider";
import {EntitiesList} from "./EntitiesList";

const observableDecorators = {}
const observableOptions = {deep: false}

/**
 *
 */
export class IdentityMap {
    /**
     * this is identity map of all entities.
     * grouped by content types
     * @type {ObservableMap<ObservableMap<BaseEntity>>}
     */
    @observable
    private entities = observable.map<string, ObservableMap<string, BaseEntity>>([], { deep: false });

    @observable
    private lists = observable.map<string, EntitiesList>();

    constructor(private metadataProvider: MetadataProvider = new MetadataProvider()) {}

    getRepository(contentType: string): ObservableMap<string, BaseEntity> {
        if (!this.entities.has(contentType)) {
            this.entities.set(contentType, observable.map<string, BaseEntity>([], { deep: false }))
        }
        return this.entities.get(contentType);
    }

    @action
    getNewObjectOf<T extends BaseValue = BaseValue>(contentType: string, values: Partial<T> = {}): T {
        const contentTypeMetadata = this.metadataProvider.getContentTypeMetadata(contentType)
        const newObject = (contentTypeMetadata ? contentTypeMetadata.newObject : {}) as T;
        const entity = extendObservable({}, newObject, observableDecorators, observableOptions);
        this.setValues(entity, values)
        return entity
    }

    @action
    setValues<T extends BaseValue = BaseEntity>(object: T, values: Partial<T>) {
        for (const propName in values) {
            // TODO https://github.com/mobxjs/mobx/issues/1512
            // TODO После решения заменить на set()
            if (propName in object) {
                object[propName] = values[propName]
            } else {
                extendObservable(object, {[propName]: values[propName]}, {}, {deep: false})
            }
        }
    }

    hasList(listName: string) {
        return this.lists.has(listName);
    }

    getList(listName: string) {
        return this.lists.get(listName);
    }

    addList(listName: string, list: EntitiesList) {
        this.lists.set(listName, list);
    }

    getLists() {
        return this.lists;
    }

    getEntities() {
        return this.entities;
    }

}
