import {CGlobalDragContainer} from "src/bums/common/globalDrag/CGlobalDragContainer"
import {Component, CThemeBg} from "src/lib/components"
import * as React from "react"
import {observer} from "mobx-react"
import {CModalFormContainer} from "../modalForm/CModalFormContainer"
import {CEditionExceedModal} from "../editionExceedModal/CEditionExceedModal"
import {CNewInterfaceWarningModal} from "../newInterfaceWarningModal/CNewInterfaceWarningModal";
import {CPromoContainer} from "../promo/CPromoContainer"
import {CFirstConfigureGlobalContainer} from "../onboarding/firstConfigure/CFirstConfigureGlobalContainer";
import {CTutorialGlobalContainer} from "src/bums/common/onboarding/tutorial/CTutorialGlobalContainer"
import {CTestDriveFeature} from "src/bums/common/testDriveFeature/CTestDriveFeature";
import {CHotKeysHandler} from "src/lib/components/CHotKey/CHotKeysHandler";
import {COnBoardingRatingContainer} from "src/bums/common/promo/COnboardingRating/COnBoardingRatingContainer"
import {CInviteLinkModal} from "src/bums/common/onboarding/tutorial/CInviteTeam/CInviteLinkModal"
import {autobind} from "core-decorators";
import {CGlobalWindowTracker} from "src/bums/common/globalContainer/CGlobalWindowTracker";
import {CProductQuestRatingContainer} from "src/bums/common/promo/CProductQuestRating/CProductQuestRatingContainer"
import {CGlobalSubscriptions} from "src/bums/common/globalSubscriptions/CGlobalSubscriptions";

export namespace CGlobalContainer {
   export interface Props {}
}

@autobind
@observer
export class CGlobalContainer extends Component<CGlobalContainer.Props, {}> {
    public render() {
        return <React.Fragment>
            <CThemeBg/>
            {!window.bt_mode && <CFirstConfigureGlobalContainer/>}
            <CModalFormContainer />
            <CEditionExceedModal />
            <CNewInterfaceWarningModal />
            <CPromoContainer />
            <COnBoardingRatingContainer />
            <CProductQuestRatingContainer />
            <CTutorialGlobalContainer />
            <CGlobalDragContainer />
            <CTestDriveFeature />
            <CHotKeysHandler />
            <CInviteLinkModal />
            <CGlobalWindowTracker />
            <CGlobalSubscriptions />
        </React.Fragment>
    }
}
