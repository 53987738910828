import {action, observable, computed} from "mobx"
import {EventEmitter} from "events"

export class BlockedPopupContentStore {

    private $eventEmmiter = new EventEmitter()

    @observable
    private $isBlocked: boolean

    @computed
    public get isBlocked() {
        return this.$isBlocked
    }

    @action
    public blockContent() {
        this.$isBlocked = true
        this.$eventEmmiter.emit("change", this.$isBlocked)
    }

    @action
    public unblockContent() {
        this.$isBlocked = false
        this.$eventEmmiter.emit("change", this.$isBlocked)
    }

    public subscribe(listener: () => void) {
        this.$eventEmmiter.addListener("change", listener)
    }

    public unsubscribe(listener: () => void) {
        this.$eventEmmiter.removeListener("change", listener)
    }
}
