import {inject} from "src/lib/utils/inject"
import {AccountInfoStore} from "src/lib/entities/store/AccountInfoStore"

export class MobileFeaturesStore {

    constructor(
        @inject(AccountInfoStore) private accountInfoStore: AccountInfoStore
    ) {}

    public isFeatureEnabled(key: MobileFeaturesKeys) {
        return !process.env.REACT_NATIVE ||
            (MobileFeatures[key] && MobileFeatures[key].version <= this.accountInfoStore.currentBackendVersion)
    }
}

type MobileFeaturesKeys = "inviteLink"
    | "isOnline"
    | "isUnread"
    | "topicListActions"
    | "isCommentOnTopicCheckout"
    | "isAllFilesInTopic"
    | "isExternalLink"
    | "isLinksInTopic"
    | "isMediaInAttachments"
    | "isSearchInFiles"
    | "isAvatarInTopic"
    | "isAttachedForUser"
    | "likes"
    | "mentionsInEntities"
    | "categories"
    | "topicsOptimisation"

const MobileFeatures = {
    inviteLink: {
        version: 1918
    },
    isOnline: {
        version: 1918
    },
    isUnread: {
        version: 1922
    },
    topicListActions: {
        version: 1922
    },
    isCommentOnTopicCheckout: {
        version: 1921
    },
    isAllFilesInTopic: {
        version: 1921
    },
    isExternalLink: {
        version: 1921
    },
    isLinksInTopic: {
        version: 1922
    },
    isMediaInAttachments: {
        version: 1923
    },
    isSearchInFiles: {
        version: 2001
    },
    isAvatarInTopic: {
        version: 2002
    },
    isAttachedForUser: {
        version: 2001
    },
    likes: {
        version: 2004
    },
    mentionsInEntities: {
        version: 2012,
    },
    categories: {
        version: 2015,
    },
    topicsOptimisation: {
        version: 2012,
    }
}
