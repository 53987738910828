export const CREATE_COMMENT_EVENT = "Create Comment"
export const JOURNAL_EVENT_TYPE = "Journal"
export const SHOW_MORE_EVENT = "Show More"
export const CHANGE_JOURNAL_SETTINGS_EVENT = "Change Journal Settings"
export const SWITCH_JOURNAL_TAB_EVENT = "Switch Journal Tab"
export const COMMENT_LIKING_EVENT = "Comment Liking"
export const CHAT_EVENT_TYPE = "Chat"
export const DO_COMMENT_EVENT = "Do Comment"
export const MAKE_TASK_EVENT = "Make Task"
export const MAKE_TODO_EVENT = "Make Todo"
export const MAKE_CONTRACTOR_EVENT = "Make Client"
export const MAKE_DEAL_EVENT = "Make Process"
export const READERS_MODAL_EVENT = "Read Employees Click"
export const REPLY_COMMENT_EVENT = "Reply Comment"
export const EDIT_HIDDEN_EVENT = "Edit Hidden"
export const QUOTE_COMMENT_EVENT = "Quote Comment"
export const DELETE_COMMENT_EVENT = "Delete Comment"
export const RESTORE_COMMENT_EVENT = "Restore Comment"
export const EDIT_COMMENT_CLICK_EVENT = "Edit Comment Click"
export const ATTACH_FILE_CLICK_EVENT = "Attach File Click"
export const COPY_COMMENT_EVENT = "Copy Comment"
export const COPY_LINK_EVENT = "Copy Link"
export const TIMING_CLICK_EVENT = "Timing Click"
export const NOTIFY_CLICK_EVENT = "Notify Click"
export const MAKE_MENSION_EVENT = "Make Mension"
export const TIMING_EDIT_EVENT = "Timing Edit"
export const EMOJI_LIST_OPEN_EVENT = "Emoji List Open"
export const READ_LIKES_EVENT = "Read Likes"
