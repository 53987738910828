/* tslint:disable */
import * as H from "../metadataHelper"
import {Money} from "./money"
import {MoneyField} from "./moneyField"
import {IntegerField} from "./integerField"
import {BoolField} from "./boolField"

export interface ObjectPacket extends H.BaseEntity {
    id?: string;
    price?: Money; // Цена
    size?: number; // Размер
    buyPossible?: boolean; // Возможность покупки
    contentType: "ObjectPacket"; // Object type
}

export module ObjectPacket {
    export const contentType: "ObjectPacket" = "ObjectPacket";
    export const endpoint = "/api/v3/objectPacket";

    export const newObject: ObjectPacket = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const price = {contentType: "MoneyField" as "MoneyField", name: "price", isMultiple: false, isSortable: false, id: "ObjectPacket.price", defaultValue: null as MoneyField["defaultValue"], refContentType: H.List(["Money"])};
        export const size = {contentType: "IntegerField" as "IntegerField", name: "size", isMultiple: false, isSortable: false, id: "ObjectPacket.size", defaultValue: null as IntegerField["defaultValue"]};
        export const buyPossible = {contentType: "BoolField" as "BoolField", name: "buyPossible", isMultiple: false, isSortable: false, id: "ObjectPacket.buyPossible", defaultValue: null as BoolField["defaultValue"]};
    }
}

export function isObjectPacket(arg: any): arg is ObjectPacket {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "ObjectPacket"
}
