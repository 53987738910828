import {PlainForm} from "src/lib/utils/form/types"
import {Employee, getLink, IntervalDates, IntervalTime} from "src/lib/entities/api"
import {DateOnly} from "src/lib/entities/bums/dateOnly"
import moment from "moment"
import {List} from "src/lib/collections"
import {getTomorrow} from "src/lib/utils/date"
import {DateOnlyToDate} from "src/lib/utils/intl/dateHelper"
import {TodoListType} from "src/bums/todo/list/types"

/**
 * Settings
 */
export const SWITCH_TODO_CARD_SETTING_NAME = "bums.todo.new.card"
export type PartialTodoForm<K extends keyof Employee> = PlainForm<Pick<Employee, K>>

const lettersRegExp = /[a-z]|[а-я]/i
export function containLetters(string: string) {
    return lettersRegExp.test(string)
}

export function getFilterIntervalDates(period: TodoListType.MONTH | TodoListType.WEEK | TodoListType.DAY , date: Date) {
    switch (period) {
        case "day":
            let tomorrow = getTomorrow(date);
        return {
            ...IntervalDates.newObject,
            from: {
                ...DateOnly.newObject,
                year: date.getFullYear(),
                month: date.getMonth(),
                day: date.getDate()
            },
            to: {
                ...DateOnly.newObject,
                year: tomorrow.getFullYear(),
                month: tomorrow.getMonth(),
                day: tomorrow.getDate()
            }
        }
        case "week":
            const momentToday = moment(date)
            const start = momentToday.clone().startOf("isoWeek").toDate()
            const end = momentToday.clone().endOf("isoWeek").toDate()
            return {
                ...IntervalDates.newObject,
                from: {
                    ...DateOnly.newObject,
                    year: start.getFullYear(),
                    month: start.getMonth(),
                    day: start.getDate()
                },
                to: {
                    ...DateOnly.newObject,
                    year: end.getFullYear(),
                    month: end.getMonth(),
                    day: end.getDate()
                }
            }
        case "month":
        default:
            return         {
                ...IntervalDates.newObject,
                from: {
                    ...DateOnly.newObject,
                    year: date.getFullYear(),
                    month: date.getMonth(),
                    day: 1
                },
                to: {
                    ...DateOnly.newObject,
                    year: date.getFullYear(),
                    month: date.getMonth(),
                    day: new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
                }
            }
    }
}


export function getFilterFromIntervalStartToToday(period: "day"|"week"|"month", date: Date) {
    let from = {
        ...DateOnly.newObject,
        year: date.getFullYear(),
        month: date.getMonth(),
        day: date.getDate()
    }

    if (period === "week") {
        const momentToday = moment(date)
        const start = momentToday.clone().startOf("isoWeek").toDate()

        from = {
            ...DateOnly.newObject,
            year: start.getFullYear(),
            month: start.getMonth(),
            day: start.getDate()
        }
    }

    if (period === "month") {
        from = {
            ...DateOnly.newObject,
            year: date.getFullYear(),
            month: date.getMonth(),
            day: 1
        }
    }

    const startDateTime = DateOnlyToDate(from)

    return {
        ...IntervalTime.newObject,
        from: startDateTime,
        to: date
    }
}


export function getFilterEmployees(employees: List<Employee>) {
    return employees.map(getLink)
}

/*
* название раздела в делах зависит от выбранного периода
*/

export function getSectionNameForCalendarByPeriod(periodName: string) {
    return periodName === "list" ? "todo" : "time"
}

export const CALENDAR_EVENT = "Calendar"
export const SWITCH_TAB_EVENT = "Switch Tab"
export const MOVE_CALENDAR = "Move Calendar"
export const EXPAND_HOURS = "Expand Hours"
export const PARTICIPANTS_CLICK = "Participants Click"
export const PARTICIPANTS_CANCEL = "Participants Cancel"
export const PARTICIPANTS_SUBMIT = "Participants Submit"
export const PARTICIPANTS_ADD = "Participants Add"
export const FROM_CALENDAR_LIST_QUICK = "fromCalendarListQuick"
export const EVENT_CARD = "eventCard"
export const EVENT_LIST_CHECK_BOX = "eventListCheckBox"
export const CALENDAR_CHECK_BOX = "calendarCheckBox"
export const MINI_CARD = "miniCard"
export const FULL_CARD = "fullCard"
export const FROM_CALENDAR_BUTTON = "fromCalendarButton"
export const  MINI_CALENDAR_CLICK = "Mini Calendar Click"
export const  EXTRA_CALENDARS_EXPAND = "Extra Calendars Expand"
export const  EXTRA_CALENDAR_CLICK = "Extra Calendar Click"
export const  PLACE_CLICK = "Place Click"
export const  PLACE_SUBMIT = "Place Submit"

