import * as React from "react"
import {observer} from "mobx-react"
import {autobind} from "core-decorators"
import {inject} from "src/lib/utils/inject"
import {Component, CSticker, CStyledContent} from "src/lib/components"
import {FormattedMessageHtml} from "src/lib/utils/intl"
import {CExtendedConfirmModal} from "src/lib/components/CConfirmModal/CExtendedConfirmModal"
import {CPromo} from "src/bums/common/promo/CPromoProps"
import {Intl} from "src/lib/utils/intl/Intl"
import {StickerName} from "src/lib/components/CSticker/utils"
import {track} from "src/lib/tracking"
import classNames from "classnames/bind"

const messages = require("../messages.yml")
const style = classNames.bind(require("./theme/CUnsupportedBrowserPopup.styl"))

const microsoftEdgeLink = "https://www.microsoft.com/ru-ru/edge/"
const googleChromeLink = "https://www.google.com/chrome/"
const firefoxLink = "https://www.mozilla.org/firefox/"

export namespace CUnsupportedBrowserPopup {
    export interface Props extends CPromo.CPromoProps<null> {
    }
}

const POPUP_EVENT_TYPE = "Popup"
const POPUP_EVENT = "IE Expired Show"
const POPUP_CLICK_EVENT = "IE Expired Click"

@track({
    eventType: POPUP_EVENT_TYPE,
    event: POPUP_EVENT,
    channels: ["product"],
    params: {}
}, {dispatchOnMount: true})
@observer
@autobind
export class CUnsupportedBrowserPopup extends Component<CUnsupportedBrowserPopup.Props, {}> {

    @inject(Intl)
    private intl: Intl

    @track((self: CUnsupportedBrowserPopup, args: Parameters<CUnsupportedBrowserPopup["onClose"]>) => {
        const btnName = args[0]?.currentTarget.dataset["name"]
        let type = "close"
        if (btnName === "cancel") {
            type = "later"
        }
        return {event: POPUP_CLICK_EVENT, params: {type}}
    })
    private onClose(e?: React.MouseEvent<HTMLElement>) {
        this.props.actionDone()
    }

    @track((self: CUnsupportedBrowserPopup, args: Parameters<CUnsupportedBrowserPopup["contentClickHandler"]>) => {
        const e = args[0]
        if (!e.defaultPrevented && e.target) {
            const target: any = e.target
            if (target.tagName === "A"
                && target.href
                && target.getAttribute("data-name")) {
                return {
                    event: POPUP_CLICK_EVENT,
                    params: {type: target.getAttribute("data-name")}
                }
            }
        }
    })
    private contentClickHandler(e?: React.MouseEvent<HTMLElement>) {
        //
    }

    private renderContent() {
        return <CStyledContent onClick={this.contentClickHandler}>
            <CStyledContent className={style("popupContent")}>
                <FormattedMessageHtml{...messages["oldBrowsersStopSupportingDescription"]}/>
            </CStyledContent>
            <CStyledContent>
                {this.intl.formatMessage(messages["youCanDownload"])}
            </CStyledContent>
            <FormattedMessageHtml
                {...messages["browserLinks"]}
                values={{
                    microsoftEdge: microsoftEdgeLink,
                    googleChrome: googleChromeLink,
                    firefox: firefoxLink
                }}
            />
        </CStyledContent>
    }

    private renderAdditionalContent() {
        return <div className={style("additionalContent")}>
            <CSticker stickerName={StickerName.ManAttention}/>
        </div>
    }

    render() {
        return <CExtendedConfirmModal
            open
            header={this.intl.formatMessage(messages["stopSuppotringOldBrowsers"])}
            content={this.renderContent()}
            cancelButtonCaption={this.intl.formatMessage(messages["downloadLater"])}
            additionalContent={this.renderAdditionalContent()}
            onCancel={this.onClose}
        />
    }
}
