import * as React from "react"
import PropTypes from "prop-types"
import {StoresMap, StoreClass, StoresContext} from "src/StoresProvider"

const nativeExp = /\{\s*\[native code\]\s*\}/

const injectParametersByTarget = new Map<StoreClass, Map<number, StoreClass>>()

/**
 *  Позволяет внедрить стор в объект
 *  Примеры:
 *     // Внедрение в React.Component
 *     import {inject} from "src/lib/utils/inject"
 *     import {Component} from "src/lib/components"
 *     import MyStore from "path/to/MyStore"
 *     class MyComponent extends Component<any, any> {
 *
 *        @inject(MyStore)
 *        private store: MyStore
 *
 *        private function myMethod() {
 *            this.store.storeMethod()
 *        }
 *     }
 *
 *     // Внедрение стора в стор
 *     import {inject} from "src/lib/utils/inject"
 *     import OtherStore from "path/to/OtherStore"
 *
 *     class MyStore {
 *
 *        private otherStore: OtherStore
 *
 *        constructor(@inject(OtherStore) otherStore: OtherStore) {
 *            this.otherStore = otherStore
 *        }
 *     }
 *
 */

export function inject<S>(store: StoreClass<S>) {
    return function (target: any, propertyName: string, propertyIndex?: number) {
        if (propertyName && propertyIndex === void 0) {
            return propertyDecorator(target, propertyName, store)
        } else if (!propertyName && propertyIndex !== void 0) {
            return parameterDecorator(target, propertyIndex, store)
        }

        throwError("Decorator is to be applied to property, or to a constructor parameter", target)
    }
}

export function useInject<S>(store: StoreClass<S>): S {
    const storesContext = React.useContext(StoresContext)
    return storesContext.megaplanStores.get(getStoreConstructor(store, null))
}

/**
 *  Создание инстанса стора с разрешением его зависимостей
 */
export function createStoreWithResolvedDependencies(store: StoreClass, storesMap: StoresMap) {
    const resolvedDependencies = resolveDependencies(store, storesMap).map((dependency) => storesMap.get(dependency))
    return new store(...resolvedDependencies)
}

function getStoreConstructor(store: StoreClass, target: any): StoreClass {
    if ("function" === typeof store && !store.name) {
        store = (store as any as (() => StoreClass))()
    }

    checkValidDependency(target, store)

    return store
}

/**
 * Определяет getter для свойства в target, который возвращает определенный Store
 */
function propertyDecorator<S>(target: any, propertyName: string, store: StoreClass) {
    if (!(target instanceof React.Component)) {
        throwError("Injection store can implement only in React.Component", target)
    }

    const targetConstructor = target.constructor

    if (!process.env.REACT_NATIVE) {

        if (targetConstructor.contextTypes == null) {
            targetConstructor.contextTypes = {}
        }

        if (targetConstructor.contextTypes.megaplanStores == null) {
            targetConstructor.contextTypes.megaplanStores = PropTypes.instanceOf(StoresMap).isRequired
        }

    } else {
        targetConstructor.contextType = StoresContext
    }

    let storeInstance: StoreClass = null

    Object.defineProperty(target, propertyName, {
        get: function () {
            if (!storeInstance) {
                storeInstance = this.context.megaplanStores.get(getStoreConstructor(store, target))
            }

            return storeInstance
        }
    })
}

/**
 * Добавляет в метаданные прототипа объекта, информацию о том, что к какому-то параметру конструктора была внедрена зависимость
 */
function parameterDecorator<S>(target: any, parameterIndex: number, store: StoreClass) {
    if (!injectParametersByTarget.has(target)) {
        injectParametersByTarget.set(target, new Map<number, StoreClass>())
    }

    const params = injectParametersByTarget.get(target)
    params.set(parameterIndex, store)
}

/**
 *  Определение наличия циклической зависимости и если она обнаружена, то функция выбрасывает исключение
 */
function detectCircularDependencies(dependencies: Set<StoreClass>, store: StoreClass) {
    if (dependencies.has(store)) {
        const chains = Array.from(dependencies.values()).map(dependency => dependency.name).join(" -> ")
        throwError(`Cyclic dependencies are found in the following chain "${chains}"`)
    }
}

function getConstructorDependencies(store: StoreClass): Map<number, StoreClass> {
    const deps =  injectParametersByTarget.get(store)
    return deps || (isNative(store.constructor) && !!store.prototype && !!store.prototype.constructor
        ? getConstructorDependencies(Object.getPrototypeOf(store.prototype.constructor))
        : new Map<number, StoreClass>()
    )
}

/**
 *  Разрешение зависимостей
 */
function resolveDependencies(store: StoreClass, storesMap: StoresMap, dependenciesChain = new Set<StoreClass>()) {
    const injecDependencyPosition = getConstructorDependencies(store)
    const resolvedDependencies: StoreClass[] = []

    if (injecDependencyPosition.size === 0) {
        return resolvedDependencies
    }

    const args = Array.from(injecDependencyPosition.keys()).sort()

    args.forEach(position => {
        const dependency = getStoreConstructor(injecDependencyPosition.get(position), store)
        checkValidDependency(store, dependency)

        if (!storesMap.has(dependency)) {
            detectCircularDependencies(dependenciesChain, dependency)
            resolveDependencies(dependency, storesMap, dependenciesChain)
        }

        resolvedDependencies.push(dependency)
    })

    dependenciesChain.delete(store)

    return resolvedDependencies
}

/**
 * Выброс стилизованного исключения
 */
function throwError(message: string, target?: any) {
    throw new Error(`${message}.${target ? ` Error occurred in ${target.name}` : ""}`)
}

/**
 *  Является ли функция нативной реализацией
 */
function isNative(fn: Function) {
    return nativeExp.test("" + fn)
}

/**
 *  Проверка на валидность зависимости
 */
function checkValidDependency(target: any, dependency: Function) {
    if (!dependency || !("constructor" in dependency)) {
        throwError("Dependency must have a constructor", target)
    }
    if (isNative(dependency)) {
        throwError("Dependency may not be native implementation", target)
    }
}
