import * as React from "react"
import * as ReactDom from "react-dom"
import {observer} from "mobx-react"
import {computed} from "mobx"
import {autobind} from "core-decorators"
import {MentionEntryProps} from "draft-js-mention-plugin"
import * as Api from "src/lib/entities/api"
import {inject} from "src/lib/utils/inject"
import {makeRefHandler} from "src/lib/utils/func"
import {scrollToNodeInContainerIfNeeded} from "src/lib/utils/window"
import {Component, CSelectOption} from "src/lib/components"

export namespace CMentionEntry {
   export type Props = MentionEntryProps<Api.Employee>
}

@observer
@autobind
export class CMentionEntry extends Component<CMentionEntry.Props, {}> {

    @inject(Api.Store)
    private apiStore: Api.Store

    public optionNode: HTMLElement

    private scroll = false

    @computed
    private get entity() {
        return this.apiStore.getEntity(this.props.mention.toJS())
    }

    componentWillMount() {
        this.scroll = !!this.props.className
    }

    componentWillReceiveProps(nextProps: CMentionEntry.Props) {
        if (!this.props.className && nextProps.className) {
            this.scroll = true
        }
    }

    componentDidUpdate() {
        if (this.scroll) {
            this.scrollToNode()
        }
    }

    componentDidMount() {
        if (this.scroll) {
            this.scrollToNode()
        }
    }

    private scrollToNode() {
        const node = ReactDom.findDOMNode(this.optionNode) as HTMLElement
        if (node) {
            scrollToNodeInContainerIfNeeded(node.parentElement, node)
            this.scroll = false
        }
    }

    public render() {
        const {mention, theme, searchValue, isFocused, ...parentProps} = this.props
        return <div key={Api.getGID(this.entity)} {...parentProps} ref={makeRefHandler(this, "optionNode")}>
            {CSelectOption.create<Api.Employee>({
                value: this.entity,
            })}
        </div>
    }
}
