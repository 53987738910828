const tableContentRegExp = /<table(?:.|\s)*?>((?:.|\s)*?)<\/table>/g
const trContentRegExp = /<tr.*?>\s*((?:.|\s)*?)\s*<\/tr>/g
const tdThContentRegExp = /<(?:td|th).*?>\s*((?:.|\s)*?)\s*<\/(?:td|th)>/g
const captionContentRegExp = /<caption.*?>((?:.|\s)*?)<\/caption>/g
const lineEndBetweenTagRegExp = /(<.*?>)\s*?\n\s*?</g
const singleBrTagInOtherTagRegExp = /><br(.*?)></g
const colgroupTagRegExp = /<colgroup.*?\/colgroup>/g
const colTagRegExp = /<col.*?>/g
const pTagRegExp = /<p.*?>((?:.|\s)*?)<\/p>/g

const convertHtmlTables = (pastedHtml: string): string => {
    return pastedHtml.replace(tableContentRegExp, (tableMatch, tableContent: string) => {
        return tableContent
            .replace(singleBrTagInOtherTagRegExp, "><")
            .replace(pTagRegExp, "$1")                      // <- сначала убираем существующие параграфы внутри таблицы
            .replace(captionContentRegExp, "<p>$1</p>")
            .replace(trContentRegExp, "<p>$1</p>")
            .replace(tdThContentRegExp, "<span>$1&nbsp;</span>")
            .replace(lineEndBetweenTagRegExp, "$1<")
            .replace(colgroupTagRegExp, "")
            .replace(colTagRegExp, "")
    })
}

export default convertHtmlTables
