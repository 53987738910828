/* tslint:disable */
import * as H from "../metadataHelper"
import {LayoutSetting} from "./layoutSetting"
import {ContractorTypeRights} from "./contractorTypeRights"
import {EnumField} from "./enumField"
import {StringField} from "./stringField"

export interface ContractorType extends H.BaseEntity {
    type?: ContractorType.Type; // Тип
    fixedFields?: H.List<string>;
    layoutSetting?: LayoutSetting; // Настройка макета
    layoutTabletSetting?: LayoutSetting; // Настройка макета компактного вида
    rights?: ContractorTypeRights; // Общие права сотрудников
    responsibleRights?: ContractorTypeRights; // Права ответственных
    name?: string; // Название
    id?: string; // Идентификатор
    contentType: "ContractorType"; // Object type
}

export module ContractorType {
    export const contentType: "ContractorType" = "ContractorType";
    export const endpoint = "/api/v3/contractorType";
    export type Type = "client"|"competitor"|"contact"|"employee"|"guest"|"own"|"partner"|"spam"|"subcontractor"|"supplier";
    export module Type {
        export const client: Type = "client";
        export const competitor: Type = "competitor";
        export const contact: Type = "contact";
        export const employee: Type = "employee";
        export const guest: Type = "guest";
        export const own: Type = "own";
        export const partner: Type = "partner";
        export const spam: Type = "spam";
        export const subcontractor: Type = "subcontractor";
        export const supplier: Type = "supplier";
    }
    export const newObject: ContractorType = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const type = {contentType: "EnumField" as "EnumField", name: "type", isMultiple: false, isSortable: false, id: "ContractorType.type", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["client", "competitor", "contact", "employee", "guest", "own", "partner", "spam", "subcontractor", "supplier"])};
        export const fixedFields = {contentType: "StringField" as "StringField", name: "fixedFields", isMultiple: true, isSortable: false, id: "ContractorType.fixedFields", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const layoutSetting = {contentType: "RefLinkField" as "RefLinkField", name: "layoutSetting", isMultiple: false, isSortable: false, id: "ContractorType.layoutSetting", refContentType: H.List(["LayoutSetting"])};
        export const layoutTabletSetting = {contentType: "RefLinkField" as "RefLinkField", name: "layoutTabletSetting", isMultiple: false, isSortable: false, id: "ContractorType.layoutTabletSetting", refContentType: H.List(["LayoutSetting"])};
        export const rights = {contentType: "RefLinkField" as "RefLinkField", name: "rights", isMultiple: false, isSortable: false, id: "ContractorType.rights", refContentType: H.List(["ContractorTypeRights"])};
        export const responsibleRights = {contentType: "RefLinkField" as "RefLinkField", name: "responsibleRights", isMultiple: false, isSortable: false, id: "ContractorType.responsibleRights", refContentType: H.List(["ContractorTypeRights"])};
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "ContractorType.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
    }
}

export function isContractorType(arg: any): arg is ContractorType {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "ContractorType"
}
