/* tslint:disable */
import * as H from "../metadataHelper"
import {Task} from "./task"
import {Employee} from "./employee"
import {NegotiationItemVersion} from "./negotiationItemVersion"
import {DateTimeField} from "./dateTimeField"
import {IntegerField} from "./integerField"

export interface NegotiationItem extends H.BaseEntity {
    task?: Task; // Задача
    userCreated?: Employee; // Создатель
    userUpdated?: Employee; // Обновил
    timeUpdated?: Date; // Дата обновления
    timeCreated?: Date; // Дата создания
    versions?: H.List<NegotiationItemVersion>; // Версии
    versionsCount?: number; // Количество версий
    actualVersion?: NegotiationItemVersion; // Актуальная версия
    id?: string; // Идентификатор
    contentType: "NegotiationItem"; // Object type
}

export module NegotiationItem {
    export const contentType: "NegotiationItem" = "NegotiationItem";
    export const endpoint = "/api/v3/negotiationItem";

    export const newObject: NegotiationItem = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const task = {contentType: "RefLinkField" as "RefLinkField", name: "task", isMultiple: false, isSortable: false, id: "NegotiationItem.task", refContentType: H.List(["Task"])};
        export const userCreated = {contentType: "RefLinkField" as "RefLinkField", name: "userCreated", isMultiple: false, isSortable: false, id: "NegotiationItem.userCreated", refContentType: H.List(["Employee"])};
        export const userUpdated = {contentType: "RefLinkField" as "RefLinkField", name: "userUpdated", isMultiple: false, isSortable: false, id: "NegotiationItem.userUpdated", refContentType: H.List(["Employee"])};
        export const timeUpdated = {contentType: "DateTimeField" as "DateTimeField", name: "timeUpdated", isMultiple: false, isSortable: false, id: "NegotiationItem.timeUpdated", defaultValue: null as DateTimeField["defaultValue"]};
        export const timeCreated = {contentType: "DateTimeField" as "DateTimeField", name: "timeCreated", isMultiple: false, isSortable: false, id: "NegotiationItem.timeCreated", defaultValue: null as DateTimeField["defaultValue"]};
        export const versions = {contentType: "RefLinkField" as "RefLinkField", name: "versions", isMultiple: true, isSortable: false, id: "NegotiationItem.versions", refContentType: H.List(["NegotiationItemVersion"])};
        export const versionsCount = {contentType: "IntegerField" as "IntegerField", name: "versionsCount", isMultiple: false, isSortable: false, id: "NegotiationItem.versionsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const actualVersion = {contentType: "RefLinkField" as "RefLinkField", name: "actualVersion", isMultiple: false, isSortable: false, id: "NegotiationItem.actualVersion", refContentType: H.List(["NegotiationItemVersion"])};
    }
}

export function isNegotiationItem(arg: any): arg is NegotiationItem {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "NegotiationItem"
}
