import {observable, action, when, computed} from "mobx"

type State = "pending" | "fulfilled" | "rejected"
type Value<T> = T | {} | void;

interface PendingPromiseBased {
    readonly state: "pending"
}

interface FulfilledPromiseBased<T> {
    readonly state: "fulfilled"
    readonly value: T
}

interface RejectedPromiseBased {
    readonly state: "rejected"
    readonly value: {}
}

export type PromiseBasedObservable<T> = (
    PendingPromiseBased |
    FulfilledPromiseBased<T> |
    RejectedPromiseBased
) & {
    readonly promise: PromiseLike<T>
}

function isPromiseLike(v: any): v is PromiseLike<any> {
    return v != null && typeof v.then === "function"
}

abstract class PromiseBasedValue<T> {

    readonly state: State
    readonly value?: Value<T>

    private static $pending: ConstantPromiseBasedValue<void>

    public static pending() {
        if (!PromiseBasedValue.$pending) {
            PromiseBasedValue.$pending = new ConstantPromiseBasedValue("pending")
        }
        return PromiseBasedValue.$pending
    }

    static fulfilled<T>(value: PromiseBasedValue<T>): PromiseBasedValue<T>
    static fulfilled<T>(value: T): PromiseBasedValue<T>
    static fulfilled<T>(value: T) {
        if (isPromiseLike(value)) {
            return new PromiseBasedValueCreatedFromPromise(value)
        }
        if (value instanceof PromiseBasedValue) {
            return value
        }
        return new ConstantPromiseBasedValue("fulfilled", value)
    }

    static rejected(e: {}) {
        return new ConstantPromiseBasedValue("rejected", e)
    }
}

class ConstantPromiseBasedValue<T> extends PromiseBasedValue<T> {

    public constructor(
        public readonly state: State,
        public readonly value: Value<T> = void 0
    ) {
        super()
    }
}

class PromiseBasedValueCreatedFromPromise<T> extends PromiseBasedValue<T> {

    @observable.ref
    private current: PromiseBasedValue<T>

    constructor(
        public readonly promise: PromiseLike<T>
    ) {
        super()
        this.current = ConstantPromiseBasedValue.pending()
        promise.then(
            action((fulfillment: T) => {
                this.current = ConstantPromiseBasedValue.fulfilled(fulfillment)
            }),
            action((reason: {}) => {
                this.current = ConstantPromiseBasedValue.rejected(reason)
            }),
        )
    }

    @computed
    public get state() {
        return this.current.state
    }

    @computed
    public get value() {
        return this.current.value
    }
}


interface SourceGetter<S> {
    (): PromiseBasedObservable<S>
    (): PromiseLike<S>
    (): S
}

interface ResultProducer<S, R> {
    (source: S): PromiseLike<PromiseBasedObservable<R>>
    (source: S): PromiseBasedObservable<R>
    (source: S): PromiseLike<R>
    (source: S): R
}

function identity<T>(v: T): T {
    return v
}

class PromiseBasedValueCreatedFromFactory<S, R = S> extends PromiseBasedValue<R> {

    constructor(
        private sourceGetter: SourceGetter<S>,
        private resultProducer: ResultProducer<S, R> = identity,
    ) {
        super()
    }

    @computed
    private get source() {
        try {
            const sourceGetter = this.sourceGetter
            return PromiseBasedValue.fulfilled(sourceGetter())
        } catch (e) {
            return PromiseBasedValue.rejected(e)
        }
    }

    @computed
    private get result() {
        const source = this.source
        if (source.state !== "fulfilled") {
            return source
        }
        try {
            const resultProducer = this.resultProducer
            return PromiseBasedValue.fulfilled(resultProducer(source.value as S))
        } catch (e) {
            return PromiseBasedValue.rejected(e)
        }
    }

    @computed
    public get state(): State {
        return this.result.state
    }

    @computed
    public get value(): R | {} | void {
        return this.state === "pending" ? void 0 : this.result.value
    }

    public get promise() {
        return new Promise<R>((resolve, reject) => {
            when(
                () => this.state !== "pending",
                () => {
                    if (this.state === "fulfilled") {
                        resolve(this.value as R)
                    } else {
                        reject(this.value)
                    }
                }
            )
        })
    }
}

export function fromPromise<T>(
    promiseOrFactory: PromiseLike<T> | (() => PromiseLike<T> | T)
): PromiseBasedObservable<T> {
    return new PromiseBasedValueCreatedFromFactory(
        (isPromiseLike(promiseOrFactory) ? () => promiseOrFactory : promiseOrFactory) as SourceGetter<T>,
    ) as PromiseBasedObservable<T>
}
export namespace fromPromise {
    export function map<T, U>(
        from: PromiseBasedObservable<T>,
        mapper: (value: T) => PromiseLike<PromiseBasedObservable<U>>
    ): PromiseBasedObservable<U>
    export function map<T, U>(
        from: PromiseBasedObservable<T>,
        mapper: (value: T) => PromiseLike<U>
    ): PromiseBasedObservable<U>
    export function map<T, U>(
        from: PromiseBasedObservable<T>,
        mapper: (value: T) => PromiseBasedObservable<U>
    ): PromiseBasedObservable<U>
    export function map<T, U>(
        from: PromiseBasedObservable<T>,
        mapper: (value: T) => U
    ): PromiseBasedObservable<U>
    export function map<T, U>(
        from: PromiseBasedObservable<T>,
        mapper: (value: T) => PromiseLike<PromiseBasedObservable<U>> | PromiseLike<U> | PromiseBasedObservable<U> | U
    ): PromiseBasedObservable<U> {
        return new PromiseBasedValueCreatedFromFactory<T, U>(
            (() => from) as SourceGetter<T>,
            mapper as ResultProducer<T, U>,
        ) as PromiseBasedObservable<U>
    }
}
