import * as React from "react"
import {action, observable} from "mobx"
import {autobind} from "core-decorators"
import * as Api from "src/lib/entities/api"
import {ContextMenu, TopicModals} from "src/bums/common/chat/type"
import {Router} from "src/lib/utils/router"
import {TopicsStore} from "src/bums/common/stores/TopicsStore"

export const ChatsPageContext = React.createContext<ChatsPageStore>(null)

@autobind
export class ChatsPageStore {

    @observable
    public isCorporateAttachedListOpen = false

    @observable
    public isWidgetArchiveOpen = false

    @observable
    private topic: Api.Topic

    @observable
    private modals: TopicModals = {
        userInfo: null,
        chatInfo: null,
        chatForm: null,
        foldersForm: false,
        folders: false,
        viewers: Api.Comment,
    }

    @observable
    public topicContextMenu: ContextMenu<Api.Topic>

    @observable
    public messageContextMenu: ContextMenu<Api.Comment>

    constructor(
        private apiStore: Api.Store,
        private router: Router,
        private topicsStore: TopicsStore,
        public isFullScreen = true,
    ) {}

    public get currentTopic() {
        return this.apiStore.getEntity(this.topic)
    }

    public getModalValue = <K extends keyof TopicModals>(key: K): TopicModals[K] => {
        return this.modals[key]
    }

    @action
    public modalToggle = <K extends keyof TopicModals>(key: K, value: TopicModals[K] = null) => {
        this.modals[key] = value
    }

    @action
    public setCurrentTopic(topic: Api.Topic) {
        this.topic = topic
    }

    public topicClose = () => {
        if (!this.isFullScreen) {
            this.setCurrentTopic(void 0)
        } else {
            this.replaceUrl(void 0)
        }
    }

    public async topicOpen (entity: Api.Topic | Api.Employee | Api.Contractor, forceFullScreen = false) {

        if (Api.isEmployee(entity) || Api.isContractor(entity)) {
            entity = await this.topicsStore.initiateChatWithEmployee(entity)
        }

        if (forceFullScreen !== true && !this.isFullScreen) {
            this.setCurrentTopic(entity)
        } else {
            this.replaceUrl(entity?.id)
        }
    }

    private replaceUrl(id?: string) {
        if (id) {
            this.router.replace(`/discuss/chat/${id}/`)
        } else {
            this.router.replace(`/discuss/chat`)
        }
    }

    @action
    public corporateAttachedListToggle = () => {
        this.isCorporateAttachedListOpen = !this.isCorporateAttachedListOpen
    }

    @action
    public widgetArchiveToggle = () => {
        this.isWidgetArchiveOpen = !this.isWidgetArchiveOpen
    }

    @action
    public topicContextMenuOpen = (topic: Api.Topic, event?: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        this.topicContextMenu = {
            entity: topic,
            position: {
                left: event.pageX,
                top: event.pageY + 5,
            }
        }
    }

    @action
    public topicContextMenuClose = () => {
        this.topicContextMenu = null
    }

    @action
    public messageContextMenuOpen = (message: Api.Comment, event?: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        this.messageContextMenu = {
            entity: message,
            position: {
                left: event.pageX,
                top: event.pageY + 5,
            }
        }
    }

    @action
    public messageContextMenuClose = () => {
        this.messageContextMenu = null
    }
}
