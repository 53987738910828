import * as React from "react"
import PropTypes from "prop-types"
import {createStoreWithResolvedDependencies} from "src/lib/utils/inject"

export interface StoreClass<Instance = any> {
    new(...args: any[]): Instance
    constructor: Function
}

type S<I> = [StoreClass<I>, I]

export class StoresMap {
    private map: Map<any, any>

    // constructor<I1>(iterable?: [S<I1>])
    public constructor(iterable: Array<S<any>>) {
        this.map = new Map(iterable)
        this.map.set(StoresMap, this)
    }
    /* tslint:disable */
    public static create<I1>(iterable?: [S<I1>]): StoresMap
    public static create<I1, I2>(iterable?: [S<I1>, S<I2>]): StoresMap
    public static create<I1, I2, I3>(iterable?: [S<I1>, S<I2>, S<I3>]): StoresMap
    public static create<I1, I2, I3, I4>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>]): StoresMap
    public static create<I1, I2, I3, I4, I5>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8, I9>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>, S<I9>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8, I9, I10>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>, S<I9>, S<I10>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8, I9, I10, I11>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>, S<I9>, S<I10>, S<I11>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8, I9, I10, I11, I12>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>, S<I9>, S<I10>, S<I11>, S<I12>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8, I9, I10, I11, I12, I13>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>, S<I9>, S<I10>, S<I11>, S<I12>, S<I13>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8, I9, I10, I11, I12, I13, I14>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>, S<I9>, S<I10>, S<I11>, S<I12>, S<I13>, S<I14>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8, I9, I10, I11, I12, I13, I14, I15>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>, S<I9>, S<I10>, S<I11>, S<I12>, S<I13>, S<I14>, S<I15>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8, I9, I10, I11, I12, I13, I14, I15, I16>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>, S<I9>, S<I10>, S<I11>, S<I12>, S<I13>, S<I14>, S<I15>, S<I16>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8, I9, I10, I11, I12, I13, I14, I15, I16, I17>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>, S<I9>, S<I10>, S<I11>, S<I12>, S<I13>, S<I14>, S<I15>, S<I16>, S<I17>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8, I9, I10, I11, I12, I13, I14, I15, I16, I17, I18>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>, S<I9>, S<I10>, S<I11>, S<I12>, S<I13>, S<I14>, S<I15>, S<I16>, S<I17>, S<I18>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8, I9, I10, I11, I12, I13, I14, I15, I16, I17, I18, I19>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>, S<I9>, S<I10>, S<I11>, S<I12>, S<I13>, S<I14>, S<I15>, S<I16>, S<I17>, S<I18>, S<I19>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8, I9, I10, I11, I12, I13, I14, I15, I16, I17, I18, I19, I20>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>, S<I9>, S<I10>, S<I11>, S<I12>, S<I13>, S<I14>, S<I15>, S<I16>, S<I17>, S<I18>, S<I19>, S<I20>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8, I9, I10, I11, I12, I13, I14, I15, I16, I17, I18, I19, I20, I21>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>, S<I9>, S<I10>, S<I11>, S<I12>, S<I13>, S<I14>, S<I15>, S<I16>, S<I17>, S<I18>, S<I19>, S<I20>, S<I21>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8, I9, I10, I11, I12, I13, I14, I15, I16, I17, I18, I19, I20, I21, I22>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>, S<I9>, S<I10>, S<I11>, S<I12>, S<I13>, S<I14>, S<I15>, S<I16>, S<I17>, S<I18>, S<I19>, S<I20>, S<I21>, S<I22>]): StoresMap
    /* tslint:enable */
    public static create(iterable: Array<S<any>> = []) {
        return new StoresMap(iterable)
    }

    has(storeClass: StoreClass) {
        return this.map.has(storeClass)
    }

    get<I>(storeClass: StoreClass<I>): I {
        if (!this.map.has(storeClass)) {
            const storeInstance = createStoreWithResolvedDependencies(storeClass, this)
            this.map.set(storeClass, storeInstance)
        }
        return this.map.get(storeClass)
    }
}
interface StoresProviderProps {
    storesMap: StoresMap
}
/* tslint:disable:no-react-component-usage */
export class StoresProvider extends React.Component<StoresProviderProps, {}> {

    public static childContextTypes = {
        megaplanStores: PropTypes.instanceOf(StoresMap)
    }

    getChildContext() {
        return {
            megaplanStores: this.props.storesMap,
        }
    }

    render() {
        return <StoresContext.Provider value={{megaplanStores: this.props.storesMap}}>
            {React.Children.only(this.props.children)}
        </StoresContext.Provider>
    }
}

export const StoresContext = React.createContext<{megaplanStores: StoresMap}>({megaplanStores: new StoresMap([])})

export class NativeStoresProvider extends React.Component<{store: StoresMap}, {}> {

    render() {
        return <StoresContext.Provider value={{megaplanStores: this.props.store}}>
            {React.Children.only(this.props.children)}
        </StoresContext.Provider>
    }
}
