import {Component} from "src/lib/components"
import * as React from "react"
import {observer} from "mobx-react"

enum WheelDirection {
    Bottom,
    Top,
}

@observer
export class CStopMouseWheelContainer extends Component<{}, {}> {

    private wheelEndDirection: WheelDirection

    private wheel = (event: React.WheelEvent<HTMLElement>) => {
        if (event.deltaY > 0) {
            if (this.wheelEndDirection === WheelDirection.Bottom) {
                event.preventDefault()
            }
        } else {
            if (this.wheelEndDirection === WheelDirection.Top) {
                event.preventDefault()
            }
        }
    }

    private scroll = (event: React.SyntheticEvent<HTMLElement>) => {
        const {scrollTop, scrollHeight, offsetHeight} = event.nativeEvent.target as HTMLElement

        if (scrollHeight === scrollTop + offsetHeight) {
            this.wheelEndDirection = WheelDirection.Bottom
        } else if (scrollTop === 0) {
            this.wheelEndDirection = WheelDirection.Top
        } else {
            this.wheelEndDirection = null
        }

    }

    render() {
        return <div onWheel={this.wheel} onScroll={this.scroll} style={{display: "inherit"}}>
            {this.props.children}
        </div>
    }
}
