import {autobind} from "core-decorators"
import * as Api from "src/lib/entities/api"
import {inject} from "src/lib/utils/inject"
import {FeatureStore} from "src/bums/common/stores/FeatureStore"
import {UserSettingStore} from "src/bums/common/stores/UserSettingStore"
import {computed} from "mobx"

const QUARTER_RATING_SETTING = "quarter_rating_setting"

@autobind
export class ProductQuestRatingStore {

    private $settingStore: UserSettingStore<number>

    constructor(
        @inject(Api.Store) private $apiStore: Api.Store,
        @inject(FeatureStore) private $featureStore: FeatureStore,
    ) {
        this.$settingStore = new UserSettingStore<number>(
            this.$apiStore,
            () => QUARTER_RATING_SETTING,
            () => null
        )
    }

    @computed
    public get setting() {
        return this.$settingStore.get()
    }

    @computed
    public get isQuarterRatingFeatureAvailable() {
        return this.$featureStore.isAvailable("bums.common.promo.product_quest_rating_march2020")
    }

    public setUserSetting() {
        void this.$settingStore.set((new Date()).getTime())
    }

}
