import {Component} from "src/lib/components"
import * as React from "react"
import {observer} from "mobx-react"
import CFieldRowItem from "./CFieldRowItem"
import {alignItem} from "src/lib/types"


interface CFieldRowStretchingProps {
    className?: string | string[]
    align?: alignItem
}

/**
 * Элемент, занимающий все оставшееся в строке место
 */
@observer
export default class CFieldRowStretching extends Component<CFieldRowStretchingProps, {}> {
    public render() {
        return <CFieldRowItem {...this.props}>
            {this.props.children}
        </CFieldRowItem>

    }
}
