import {noop, debounce} from "lodash"
import {UserStoreInterface} from "src/lib/entities/store/UserStore"
import {rawFetch} from "src/lib/utils/fetch"
import {PurchaseEvent, Transport} from "./Transport"
import {TrackEventValueType} from "src/bums/common/stores/Tracker/Tracker"
import moment from "moment"

declare const window: Window & {browser: {[key: string]: boolean|string|number}}
type SessionIdGenerator = () => Promise<number|void>

const browserInfo = function (): typeof window.browser | null {
    if (process.env.REACT_NATIVE || !window?.browser) {
        return null;
    }

    const result: typeof window.browser = {}
    for (let key in window.browser) {
        if (window.browser[key]) {
            result[key] = window.browser[key]
        }
    }

    return result
}();

/**
 * Транспорт для отправки данных в еластик через бекенд аккаунта
 * См \sdf\common\s\ActionTracker
 */
export class BackendTransport implements Transport {

    private trackQueue: Array<object> = []
    private baseURL: () => string
    private currentUser: UserStoreInterface
    private sessionIdGenerator: SessionIdGenerator | null
    protected isBeforeUnloadEventNow = false      // это нужно, чтобы треки, вызванные в событии beforeunload сразу отправлялись,
                                                // а не ждали очереди на отправку

    constructor(
        currentUser: UserStoreInterface,
        baseURL: string | (() => string),
        private extraData: {
            origin: string,
            trackMemory?: boolean
        },
        private endpoint = "trackBatch"
    ) {
        this.currentUser = currentUser
        if (typeof baseURL === "function") {
            this.baseURL = baseURL
        } else {
            this.baseURL = () => baseURL
        }
        this.subscribeOnPageUnload()
    }

    protected flush = (useBeaconSender: boolean = false) => {
        const baseURL = this.baseURL()
        if (this.trackQueue.length === 0 || !baseURL) {
            return;
        }

        const data = this.trackQueue;
        this.trackQueue = [];
        // используем `sendBeacon` если это возможно
        if (useBeaconSender && navigator?.sendBeacon) {
            navigator.sendBeacon(`${baseURL}/${this.endpoint}`, JSON.stringify(data))
        } else {
        // Используем обычный фетч, потому что здесь нам не нужна ни обработка ошибок, ни парсинг ответа
            rawFetch({
                method: "POST",
                url: `/${this.endpoint}`,
                baseURL: baseURL,
                data
            }).catch(noop) // Просто глушим ошибку
        }
    }

    private flushDebounced = debounce(() => this.flush(), 5000);

    public async trackEvent(type: string, key: string, value: TrackEventValueType = null, channels?: string[]) {
        // TODO Если нет хоста, то надо отсылать куда-то в общее место, пока что просто игнорим
        if (!this.baseURL()) {
            return
        }
        const data: {[index: string]: TrackEventValueType | number} = {
            type: type,
            key,
            uid: this.currentUser.uid || "no-user",
            ...this.extraData,
            timestamp: Date.now(),
            timezone: moment().format("Z"),
        }
        if (value) {
            data["value"] = value
        }
        if (channels) {
            data["channels"] = JSON.stringify(channels)
        }

        if (window && !process.env.REACT_NATIVE) {
            data["url"] = window.location.href
        }
        if (browserInfo) {
            data["browserInfo"] = JSON.stringify(browserInfo)
        }

        if (!process.env.REACT_NATIVE && this.extraData.trackMemory) {
            data["memoryUsage"] = JSON.stringify(window.MemoryUsageTracker.memoryUsageTrackingData)
        }

        if (this.sessionIdGenerator) {
            const sessionId = await this.sessionIdGenerator()
            if (sessionId) {
                data["sessionId"] = sessionId
            }
        }

        this.trackQueue.push(data)

        this.flushDebounced()
    }

    public trackScreen(name: string) {
        void this.trackEvent("access screen", name.replace(" ", "_"))
        if (!process.env.REACT_NATIVE && window && document) {
            void this.trackEvent(
                "Window",
                "Opened",
                {
                    url: window.location.pathname,
                    ref: document.referrer,
                    interface: "new"
                },
                ["product", "amplitude"]
            )
        }
    }

    public trackTiming(type: string, name: string, time: number) {
        void this.trackEvent(`timing ${type}`, name, time.toString())
    }

    public trackPurchase(event: PurchaseEvent): void {
        //
    }

    public setSessionIdGenerator(sessionIdGenerator: SessionIdGenerator) {
        this.sessionIdGenerator = sessionIdGenerator
    }

    private subscribeOnPageUnload() {
        if (window && window.addEventListener) {
            window.addEventListener("unload", () => {
                this.isBeforeUnloadEventNow = true
                this.flush(true)
            })
        }
    }
}
