import * as React from "react"
import {observer} from "mobx-react"
import classnames from "classnames/bind"
import {Component} from "src/lib/components"
import {CAdapt} from "src/lib/components/CAdapt/CAdapt"

const style: any = classnames.bind(require("./CHover.styl"))

/*
Набор компонентов для решения проблемы показа элементов при наведении.
Часто бывает так, что, например, при навадении на строку таблицы надо показывать какие-то элементы.
Для решения придумано следующее:

import {CHover, Component} from "src/lib/components"

render() {
    return (
        <CHover.Handler>
            <tr>
                <td><CHover.Element><CButton /></CHover.Element></td>
            </tr>
        </CHover.Handler>
    )
}

В приведённом выше примере кнопка в ячейке будет видна только если мышка находится над строкой таблицы.
Строка таблицы соответственно является обработчиком наведения, а кнопка - элементом, который виден только если есть ховер.

В некоторых случаях пригодится свойство visibleAlways, которое может отключить поведение для Element при некоторых условиях,
например, когда чекбокс в таблице должен быть виден всегда если он отмечен.

Важный нюанс: элементы, которые обёрнуты в Handler и Element должны иметь возможность пробрасывать кастомный css-класс
через свойство className.
 */

export namespace CHover {
    /**
     * В данный компонент необходимо обернуть элемент, при наведении на который требуется показать Element
     */
    export namespace Handler {
        export interface Props {
            className?: string | string[]
            children: React.ReactElement<{className?: string}>
        }
    }

    export namespace Element {
        export interface Props {
            visibleAlways?: boolean
            // скрывать на мобильных устройствах
            hideOnMobile?: boolean
            className?: string | string[]
            children: React.ReactElement<{className?: string}>
        }
    }

    @observer
    export class Handler extends Component<Handler.Props, {}> {

        public render() {
            const child = React.Children.only(this.props.children)
            return React.cloneElement(
                child,
                {className: style(child.props.className, this.props.className, "hoverHandler")}
            )
        }
    }

    /**
     * В данный компонент необходимо обернуть элемент, который должен быть виден только если есть ховер на Handler
     */
    @observer
    export class Element extends Component<Element.Props, {}> {
        public render() {
            const child = React.Children.only(this.props.children)
            const isMobile = CAdapt.isMobileDevice()
            return React.cloneElement(
                child,
                {className: style(
                    this.props.className,
                    child.props.className,
                    {
                        isMobile: isMobile,
                        hoverElement: !this.props.visibleAlways && !isMobile,
                        hideOnMobile: isMobile && this.props.hideOnMobile,

                    }
                )}
            )
        }
    }
}
