import * as Api from "src/lib/entities/api"
import * as Collections from "src/lib/collections"
import {isKeyedValue, ExtendsFilterOptions, KeyedValue, FilterOptions} from "../../types"
import {Intl} from "src/lib/utils/intl"
import {sortByWeight} from "src/lib/utils/search"
import {bindArg} from "src/lib/utils/func"

export function filterOnlyWorkingEmployees(list: Collections.List<KeyedValue<any, any>>, options: ExtendsFilterOptions) {
    if (!options.types.includes(Api.Employee.contentType)) {
        return list
    }
    return list.filter(option => {
        const entity = isKeyedValue(option) ? option.value : option
        if (Api.isEmployee(entity) && !entity.isWorking) {
            return false
        }
        return true
    })
}

export function filterNotDeletedContractors(list: Collections.List<KeyedValue<any, any>>, options: ExtendsFilterOptions) {
    if (!options.types.includes(Api.ContractorHuman.contentType) &&
        !options.types.includes(Api.ContractorCompany.contentType)) {
        return list
    }

    return list.filter(option => {
        const entity = isKeyedValue(option) ? option.value : option
        if (Api.isContractor(entity) && entity.isDropped) {
            return false
        }
        return true
    })
}

export function filterContractotTypes(list: Collections.List<KeyedValue<any, any>>, options: ExtendsFilterOptions) {
    if (!options.types.includes(Api.ContractorType.contentType)) {
        return list
    }

    return list.filter(option => {
        const entity = isKeyedValue(option) ? option.value : option
        if (Api.isContractorType(entity) && !Api.ContractorType.fields.type.enumValues.includes(entity.type)) {
            return false
        }
        return true
    })
}

export function filterSliceCollection(list: Collections.List<KeyedValue<any, any>>, options: FilterOptions) {
    return list.slice(0, 20)
}

export function filterInjectFirstCurrentUser(
    currentUser: Api.UserStore,
    list: Collections.List<KeyedValue<any, any>>,
    options: FilterOptions
) {
    if (!options.filter) {
        return Collections.List([{key: currentUser.user, value: currentUser.user}]).concat(list.filter(option => {
            const entity = isKeyedValue(option) ? option.value : option
            return !Api.isEntityEquals(entity, currentUser.user)
        }))
    }
    return list
}


export function filterForcedSortByWeight(
    currentUser: Api.UserStore,
    intl: Intl,
    list: Collections.List<KeyedValue<any, any>>,
    options: FilterOptions
) {
    if (!options.focused || options.filter !== "") {
        return list
    }

    const weightList = list.map<[KeyedValue<any, any>, number]>(value => [value, 0])

    return filterFirstEmpty(
        weightList.sort(bindArg(sortByWeight, currentUser.user, intl, new Map())).map(value => value[0]),
        options
    )
}

export function filterFirstEmpty(list: Collections.List<KeyedValue<any, any>>, options: FilterOptions) {
    const result = list.slice()
    const emptyItem = result.findEntry(item => item.key === null)

    if (emptyItem) {
        result.splice(emptyItem[0], 1)
        result.unshift(emptyItem[1])
    }

    return result
}
