import {computed} from "mobx"
import * as Collections  from "src/lib/collections"
import {List} from "src/lib/collections"
import {ListItem} from "src/lib/entities/store/types"
import {ApiStore} from "src/lib/entities/store/ApiStore"
import {ListStore} from "src/lib/entities/store/ListStore"
import {ExtraField} from "src/lib/entities/api"
import {isknownFieldMetaData, KnownMetadata} from "src/lib/components/CFieldValue/utils"
import {getFieldSettingValue} from "src/lib/entities/api"

export interface ExtraFieldsStoreProps {
    isCompleted: boolean
    listStore: ListStore<ExtraField>
    fields: Promise<List<ExtraField>>
    items: List<ListItem<ExtraField>>
    knownFieldValueFields?: List<ExtraField>
}

export abstract class AbstractExtraFieldsStore implements ExtraFieldsStoreProps {

    protected $listStore: ListStore<ExtraField>

    protected $apiStore: ApiStore

    protected async init() {
        this.$listStore = this.initializeListStore()
        await this.$listStore.supportFullLoad()
    }

    protected abstract initializeListStore(): ListStore<ExtraField>

    /** заводим хэш чтобы быстро получать поле по имени */
    @computed
    private get hash() {
        const hash: {[index: string] : ExtraField} = {}
        this.$listStore.originalItems.forEach(item => hash[item.name] = item)
        return hash
    }

    /**
     * Возвращает поле по имени
     * Должна вызываться только после полной загрузки всех полей (this.isCompleted === true)
     * @returns {null}
     */
    public getFieldByName(name: string): ExtraField {
        return this.hash[name]
    }

    @computed
    public get isCompleted() {
        return this.$listStore.loadStateNext.isCompleted() && !this.$listStore.hasMoreNext
    }

    public get listStore() {
        return this.$listStore
    }

    public get fields() {
        return (async () => {
            await this.listStore.whenComplete()
            return this.$listStore.originalItems
        })()
    }

    @computed
    public get items() {
        return this.$listStore.items
    }

    public getFieldsWithSetting(settingName: string) {
        return this.$listStore.originalItems
            .filter(field => isknownFieldMetaData(field)
                && getFieldSettingValue(field, settingName, false)) as Collections.List<KnownMetadata>
    }

    public dispose() {
        if (this.$listStore) {
            this.$listStore.dispose()
        }
    }
}
