import {Component} from "src/lib/components"
import * as React from "react"

export type CThemeName = "darkSide"

interface CThemeProps extends React.Props<CTheme> {
    name?: CThemeName
}

/**
 * обертка задающая отображение элементов на темном фоне
 */
export default class CTheme extends Component<CThemeProps, {}> {
    public static defaultProps = {
        name: "darkSide"
    }

    public static darkThemeClass = "darkSide"

    public render() {
        const {name, children} = this.props

        return <div className={name}>{children}</div>
    }
}
