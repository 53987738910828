/* tslint:disable */
import * as H from "../metadataHelper"
import {ContractorCompany} from "./contractorCompany"
import {ContractorHuman} from "./contractorHuman"
import {Deal} from "./deal"
import {Doc} from "./doc"
import {FinOperation} from "./finOperation"
import {Project} from "./project"
import {Task} from "./task"
import {Todo} from "./todo"
import {Topic} from "./topic"
import {Employee} from "./employee"
import {File} from "./file"
import {RelationLink} from "./relationLink"
import {FacebookMessage} from "./facebookMessage"
import {InstagramMessage} from "./instagramMessage"
import {Message} from "./message"
import {TelegramMessage} from "./telegramMessage"
import {WhatsappMessage} from "./whatsappMessage"
import {CommentReader} from "./commentReader"
import {StringField} from "./stringField"
import {IntegerField} from "./integerField"
import {DateTimeField} from "./dateTimeField"
import {BoolField} from "./boolField"
import {DurationField} from "./durationField"
import {EnumField} from "./enumField"

export interface Comment extends H.BaseEntity {
    content?: string; // Содержание
    subject?: ContractorCompany|ContractorHuman|Deal|Doc|FinOperation|Project|Task|Todo|Topic; // Сущность
    owner?: ContractorCompany|ContractorHuman|Employee; // Владелец
    attaches?: H.List<File>; // Вложения
    attachesCount?: number; // Количество вложений
    timeCreated?: Date; // Дата создания
    timeUpdated?: Date; // Дата обновления
    isDropped?: boolean; // Удален
    completed?: number; // Процент завершения задачи
    relationLinks?: H.List<RelationLink>; // Связанные ссылки
    relationLinksCount?: number; // Количество связанных ссылок
    workTime?: H.DateInterval; // Время работы
    workDate?: Date; // Дата списания времени workTime
    isUnread?: boolean; // Не прочитан
    possibleActions?: H.List<Comment.PossibleActions>; // Список возможных действий
    transportMessages?: H.List<FacebookMessage | InstagramMessage | Message | TelegramMessage | WhatsappMessage>; // Если комментарий был отправлен через какие-то транспорты то здесь содержатся соотв. сущности сообщений
    transportMessagesCount?: number; // Количество сообщений отправленных через какие-либо транспорты
    originalComment?: Comment; // Комментарий на который был дан ответ
    isHidden?: boolean; // Скрытый
    wasRead?: boolean; // Был прочитан
    isLiked?: boolean; // Был ли проставлен лайк текущим пользователем
    likes?: H.List<ContractorCompany | ContractorHuman | Employee>; // Список пользователей проставивших лайк
    likesCount?: number; // Количество лайков
    answers?: H.List<Comment>; // Список ответов на комментарий
    answersCount?: number; // Количество ответов на комментарий
    readers?: H.List<CommentReader>; // Список пользователей прочитавших комментарий с датой прочтения
    readersCount?: number; // Количество пользователей прочитавших комментарий
    id?: string; // Идентификатор
    isFavorite?: boolean; // Избранная сущность или нет
    contentType: "Comment"; // Object type
}

export module Comment {
    export const contentType: "Comment" = "Comment";
    export const endpoint = "/api/v3/comment";
    export type PossibleActions = "act_delete_comment"|"act_edit_comment"|"act_read"|"act_restore_comment";
    export module PossibleActions {
        export const act_delete_comment: PossibleActions = "act_delete_comment";
        export const act_edit_comment: PossibleActions = "act_edit_comment";
        export const act_read: PossibleActions = "act_read";
        export const act_restore_comment: PossibleActions = "act_restore_comment";
    }
    export const newObject: Comment = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const content = {contentType: "StringField" as "StringField", name: "content", isMultiple: false, isSortable: false, id: "Comment.content", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const subject = {contentType: "RefLinkField" as "RefLinkField", name: "subject", isMultiple: false, isSortable: false, id: "Comment.subject", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Deal", "Doc", "FinOperation", "Project", "Task", "Todo", "Topic"])};
        export const owner = {contentType: "RefLinkField" as "RefLinkField", name: "owner", isMultiple: false, isSortable: false, id: "Comment.owner", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee"])};
        export const attaches = {contentType: "RefLinkField" as "RefLinkField", name: "attaches", isMultiple: true, isSortable: false, id: "Comment.attaches", refContentType: H.List(["File"])};
        export const attachesCount = {contentType: "IntegerField" as "IntegerField", name: "attachesCount", isMultiple: false, isSortable: false, id: "Comment.attachesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const timeCreated = {contentType: "DateTimeField" as "DateTimeField", name: "timeCreated", isMultiple: false, isSortable: false, id: "Comment.timeCreated", defaultValue: null as DateTimeField["defaultValue"]};
        export const timeUpdated = {contentType: "DateTimeField" as "DateTimeField", name: "timeUpdated", isMultiple: false, isSortable: false, id: "Comment.timeUpdated", defaultValue: null as DateTimeField["defaultValue"]};
        export const isDropped = {contentType: "BoolField" as "BoolField", name: "isDropped", isMultiple: false, isSortable: false, id: "Comment.isDropped", defaultValue: null as BoolField["defaultValue"]};
        export const completed = {contentType: "IntegerField" as "IntegerField", name: "completed", isMultiple: false, isSortable: false, id: "Comment.completed", defaultValue: null as IntegerField["defaultValue"]};
        export const relationLinks = {contentType: "RefLinkField" as "RefLinkField", name: "relationLinks", isMultiple: true, isSortable: false, id: "Comment.relationLinks", refContentType: H.List(["RelationLink"])};
        export const relationLinksCount = {contentType: "IntegerField" as "IntegerField", name: "relationLinksCount", isMultiple: false, isSortable: false, id: "Comment.relationLinksCount", defaultValue: null as IntegerField["defaultValue"]};
        export const workTime = {contentType: "DurationField" as "DurationField", name: "workTime", isMultiple: false, isSortable: false, id: "Comment.workTime", defaultValue: null as DurationField["defaultValue"]};
        export const workDate = {contentType: "DateTimeField" as "DateTimeField", name: "workDate", isMultiple: false, isSortable: false, id: "Comment.workDate", defaultValue: null as DateTimeField["defaultValue"]};
        export const isUnread = {contentType: "BoolField" as "BoolField", name: "isUnread", isMultiple: false, isSortable: false, id: "Comment.isUnread", defaultValue: null as BoolField["defaultValue"]};
        export const possibleActions = {contentType: "EnumField" as "EnumField", name: "possibleActions", isMultiple: true, isSortable: false, id: "Comment.possibleActions", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["act_delete_comment", "act_edit_comment", "act_read", "act_restore_comment"])};
        export const transportMessages = {contentType: "RefLinkField" as "RefLinkField", name: "transportMessages", isMultiple: true, isSortable: false, id: "Comment.transportMessages", refContentType: H.List(["FacebookMessage", "InstagramMessage", "Message", "TelegramMessage", "WhatsappMessage"])};
        export const transportMessagesCount = {contentType: "IntegerField" as "IntegerField", name: "transportMessagesCount", isMultiple: false, isSortable: false, id: "Comment.transportMessagesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const originalComment = {contentType: "RefLinkField" as "RefLinkField", name: "originalComment", isMultiple: false, isSortable: false, id: "Comment.originalComment", refContentType: H.List(["Comment"])};
        export const isHidden = {contentType: "BoolField" as "BoolField", name: "isHidden", isMultiple: false, isSortable: false, id: "Comment.isHidden", defaultValue: null as BoolField["defaultValue"]};
        export const wasRead = {contentType: "BoolField" as "BoolField", name: "wasRead", isMultiple: false, isSortable: false, id: "Comment.wasRead", defaultValue: null as BoolField["defaultValue"]};
        export const isLiked = {contentType: "BoolField" as "BoolField", name: "isLiked", isMultiple: false, isSortable: false, id: "Comment.isLiked", defaultValue: null as BoolField["defaultValue"]};
        export const likes = {contentType: "RefLinkField" as "RefLinkField", name: "likes", isMultiple: true, isSortable: false, id: "Comment.likes", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee"])};
        export const likesCount = {contentType: "IntegerField" as "IntegerField", name: "likesCount", isMultiple: false, isSortable: false, id: "Comment.likesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const answers = {contentType: "RefLinkField" as "RefLinkField", name: "answers", isMultiple: true, isSortable: false, id: "Comment.answers", refContentType: H.List(["Comment"])};
        export const answersCount = {contentType: "IntegerField" as "IntegerField", name: "answersCount", isMultiple: false, isSortable: false, id: "Comment.answersCount", defaultValue: null as IntegerField["defaultValue"]};
        export const readers = {contentType: "RefLinkField" as "RefLinkField", name: "readers", isMultiple: true, isSortable: false, id: "Comment.readers", refContentType: H.List(["CommentReader"])};
        export const readersCount = {contentType: "IntegerField" as "IntegerField", name: "readersCount", isMultiple: false, isSortable: false, id: "Comment.readersCount", defaultValue: null as IntegerField["defaultValue"]};
        export const isFavorite = {contentType: "BoolField" as "BoolField", name: "isFavorite", isMultiple: false, isSortable: false, id: "Comment.isFavorite", defaultValue: null as BoolField["defaultValue"]};
    }
}

export function isComment(arg: any): arg is Comment {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Comment"
}
