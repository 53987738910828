export const TEXT_METRIC_FONT = "13px Arial"

const striptags: any = require("striptags")

export interface TextDimension {
    width: number
    height: number
}

const nbspExp = /&nbsp;/g
const brokenNbspExp = /&amp;nbsp;/g
const tableExp = /<table.*?>([\s\S]*)<\/table>/gi

export const spaceExp = /\s+/
export const notDigitExp = /\D/g

export function measureText(value: string, fontFace = TEXT_METRIC_FONT): TextDimension {
    const c = document.createElement("canvas")
    const ctx = c.getContext("2d")
    if (ctx) {
        ctx.font = fontFace
        return {width: Math.ceil(ctx.measureText(value).width), height: 13}
    } else {
        return {width: 0, height: 0}
    }
}

function getMaxTextWidth(str1: string, str2: string): number {
    let ret = str1
    if (str1.length < str2.length) {
        ret = str2
    }
    return measureText(ret).width
}


export function wordWrap(text: Array<string>, width = 140): {wordLines: Array<string>, maxWidth: number} {
    let wordLines: Array<string> = []
    let wordLine = ""
    let maxWidth = 0
    for (let i = 0; i < text.length - 1; i++) {
        const str1 = text[i]
        const str2 = text[i + 1] ? text[i + 1] : ""
        const strWidth = getMaxTextWidth(str1, str2)
        if (strWidth > maxWidth) {
            maxWidth = strWidth
        }
    }
    text.forEach((textLine: string) => {
        if (maxWidth > width) {
            let words = textLine.split(/\s+/)
            maxWidth = width
            // определяем максимальную ширину при условии длинного слова, иначе оно никогда не отобразится
            // и цикл разбора по строкам придет в бесконечный
            words.forEach((_word) => {
                const wordWidth = Math.round(measureText(_word + " ").width)
                if (maxWidth < wordWidth) {
                    maxWidth = wordWidth
                }
            })
            // разбираем по строкам слова для отображения
            for (let i = 0; i <= words.length; i++) {
                const word = words[i]
                if (word === undefined) {
                    // следующе слова нет, заканчиваем и добавляем на отображение последнюю строку
                    wordLines.push(wordLine)
                    break
                }
                if (measureText(`${wordLine} ${word}`).width <= maxWidth) {
                    wordLine += ` ${word}`
                } else {
                    wordLines.push(wordLine)
                    wordLine = word
                }
            }
        } else {
            wordLines.push(textLine)
        }
    })
    return {
        wordLines,
        maxWidth
    }
}

interface Costs {
    replace?: number
    insert?: number
    remove?: number
}

/**
 * Реализация алгоритма "Расстояние Левенштейна"
 *
 * @param s1 исходная строка
 * @param s2 искомая строка
 * @param costs объект весов
 * @returns number
 */
export function levenshtein(s1: string, s2: string, costs: Costs = {}) {
    if (s1 === s2) {
        return 0
    }

    let ch: string
    let ii: number
    let ii2: number
    let cost: number
    let l1 = s1.length
    let l2 = s2.length

    const cr = costs.replace || 1
    const ci = costs.insert || 1
    const cd = costs.remove || 1

    const cutHalf = Math.max(l1, l2)
    let flip = cutHalf

    const minCost = Math.min(cd, ci, cr)
    const minD = Math.max(minCost, (l1 - l2) * cd)
    const minI = Math.max(minCost, (l2 - l1) * ci)
    const buf = new Array((cutHalf * 2) - 1)

    for (let i = 0; i <= l2; ++i) {
        buf[i] = i * minD
    }

    for (let i = 0; i < l1; ++i, flip = cutHalf - flip) {
        ch = s1[i]

        buf[flip] = (i + 1) * minI

        ii = flip
        ii2 = cutHalf - flip

        for (let j = 0; j < l2; ++j, ++ii, ++ii2) {
            cost = ch === s2[j] ? 0 : cr
            buf[ii + 1] = Math.min(buf[ii2 + 1] + cd, buf[ii] + ci, buf[ii2] + cost)
        }
    }
    return buf[l2 + cutHalf - flip]
}

export function stripTags(text: string, allowedTags?: string | string[] ): string {
    text = text || ""
    const findParagraphExp = /<\/p>\s*<p>/mg
    const findLineBreakExp = /<br\s*\/?>/mg
    const findBlockquoteExp = /(<blockquote>[\s\S]*?<\/blockquote>)/g
    const findOldQuoteExp = /(<span style\=\"color\: Gray\; font\-size\: 0.9em\">&gt;[\s\S]*?<\/span>\n?)/g
    const styleExp = /<style>.*?<\/style>/g
    text = text.replace(findParagraphExp, "\n")
    text = text.replace(findLineBreakExp, "\n")
    text = text.replace(findBlockquoteExp, "")
    text = text.replace(findOldQuoteExp, "")
    text = text.replace(styleExp, "")
    text = striptags(text, allowedTags ? allowedTags : ["a"] )
    return text
}

export function replaceNbsp(text: string): string {
    return text.replace(nbspExp, "\u00a0").replace(brokenNbspExp, "\u00a0")
}

export function convertTagsForMsWord(html: string): string {
    return html
        .replace(/<del>/g, "<s>")
        .replace(/<\/del>/g, "</s>")
        .replace(/<ins>/g, "<u>")
        .replace(/<\/ins>/g, "</u>")
}

export function hasTable(html: string): boolean {
    return html.search(tableExp) > 0
}

export function firstLetterToUpperCase(text?: string): string {
    if (text) {
        return text.charAt(0).toUpperCase() + text.slice(1)
    }
    return ""
}

export function firstLetterToLowerCase(text?: string): string {
    if (text) {
        return text.charAt(0).toLowerCase() + text.slice(1)
    }
    return ""
}
