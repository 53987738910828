import {autobind} from "core-decorators"
import * as Api from "src/lib/entities/api"
import {action, computed, observable} from "mobx"
import {ApiError} from "src/lib/entities/types"
import {inject} from "src/lib/utils/inject"
import {EditionExceed} from "src/lib/entities/bums";
import {AccountInfoStore} from "src/lib/entities/store/AccountInfoStore";

const exceptionType = "bums\\common\\edition\\Exception\\EditionExceedException"

interface EditionExceedMeta {
    exceedType?: string
    canBuy?: boolean
}

type ExceedType = "task" | "employee" | "contractor" | "project" | "event" | "disk_space"

@autobind
export class EditionExceedModalStore {

    @observable
    private $message: string

    @observable
    private $exceedType: ExceedType

    @observable
    private $canBuy: boolean = false

    constructor(
        @inject(Api.Store) private apiStore: Api.Store,
        @inject(AccountInfoStore) private accountInfoStore: AccountInfoStore
    ) {
        this.apiStore.subscribeToApiErrors(exceptionType, this.setError)
        window.addEventListener("checkExceed", this.windowCheckEditionExceed)
        this.windowCheckEditionExceed()
    }

    public destroy() {
        this.apiStore.unsubscribeToApiErrors(exceptionType, this.setError)
        window.removeEventListener("checkExceed", this.windowCheckEditionExceed)
    }

    @action
    private setError(error: ApiError) {
        if (EditionExceedModalStore.isEditionException(error)) {
            this.$message = error.message
            const meta = <EditionExceedMeta>error.meta
            this.$exceedType = meta.exceedType as ExceedType
            this.$canBuy = meta.canBuy
        }
    }

    @action
    public close() {
        this.$message = void 0
        this.$exceedType = void 0
    }

    @computed
    public get isOpen() {
        return !!this.$message || !!this.$exceedType
    }

    @computed
    public get message() {
        return this.$message
    }

    @computed
    public get exceedType() {
        return this.$exceedType
    }

    @computed
    public get canBuy() {
        return this.$canBuy
    }

    public windowCheckEditionExceed() {
        /** Упрощенный вариант для страниц со старым дизайном. Убрать после перевода на новый диз */
        const editionExceed = (<any>window).editionExceed
        if (editionExceed !== undefined) {
            void this.handleFromOpen(editionExceed.exceedType)
        }
    }

    public async handleFromOpen(formType: string, activeFormIndex = 0) {
        let loaded = this.accountInfoStore.isLoaded
        if (! this.accountInfoStore.isLoaded) {
            await this.accountInfoStore.initialize().then(() => {
                loaded = true
            }).catch((e) => {
                throw e
            })
        }
        if (loaded && this.accountInfoStore.isEdition("BumsEdition_Start2018")) {
            if (["contractorHuman", "contractorCompany"].includes(formType)) {
                formType = "contractor";
            }
            if (["taskProject"].includes(formType)) {
                formType = activeFormIndex === 1 ? "project" : "task";
            }

            if (["task", "employee", "contractor", "project", "event"].includes(formType)) {
                void this.showExceedModalIfNeed(formType as ExceedType)
            }
        }
    }

    public async showExceedModalIfNeed(type: ExceedType) {
        try {
            const exceed: void | EditionExceed = await this.apiStore.fetchFullEntity({
                id: type,
                contentType: EditionExceed.contentType
            })

            if (exceed.active) {
                this.showExceed(type)
            }
        } catch (e) {
            throw e
        }
    }

    @action
    public showExceed(exceedType: ExceedType) {
        this.$exceedType = exceedType
    }
}

export namespace EditionExceedModalStore {
    export function isEditionError(value: any): boolean {
        return Api.isFetchError<any>(value) && Api.isApiErrorResponse(value.response.value) &&
            value.response.value.meta.errors.some(isEditionException)
    }
    export function isEditionException(error: ApiError) {
        return error.type === exceptionType
    }
}
