/* tslint:disable */
import * as H from "../metadataHelper"
import {IntegerField} from "./integerField"

export interface AudioFileMetadata extends H.BaseValue {
    duration?: number;
    contentType: "AudioFileMetadata"; // Object type
}

export module AudioFileMetadata {
    export const contentType: "AudioFileMetadata" = "AudioFileMetadata";
    export const endpoint = "/api/v3/audioFileMetadata";

    export const newObject: AudioFileMetadata = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const duration = {contentType: "IntegerField" as "IntegerField", name: "duration", isMultiple: false, isSortable: false, id: "AudioFileMetadata.duration", defaultValue: null as IntegerField["defaultValue"]};
    }
}

export function isAudioFileMetadata(arg: any): arg is AudioFileMetadata {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "AudioFileMetadata"
}
