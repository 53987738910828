import {
    BigIntField,
    BoolField,
    DateField,
    DateTimeField,
    DurationField,
    EnumField,
    FloatField,
    IntegerField,
    isBigIntField,
    isBoolField,
    isDateField,
    isDateTimeField,
    isDiscount,
    isDurationField,
    isEnumField,
    isExternalSourceField,
    isFloatField,
    isIntegerField,
    isMoneyField,
    isRatingField,
    isRefLinkField,
    isStringField,
    MoneyField,
    RefLinkField,
    StringField
} from "src/lib/entities/api"

// Метаданные, которые могут быть обработаны этим компонентом

export type KnownMetadata = StringField | FloatField | IntegerField | DateField | DateTimeField |
    DurationField | EnumField | BoolField | RefLinkField | BigIntField | MoneyField

export function isknownFieldMetaData(arg: any): arg is KnownMetadata {
    return Boolean(arg) && typeof arg === "object" && arg.contentType && !isExternalSourceField(arg) &&
        (isStringField(arg) ||
            isIntegerField(arg) ||
            isFloatField(arg) ||
            isBigIntField(arg) ||
            isDateField(arg) ||
            isDateTimeField(arg) ||
            isDurationField(arg) ||
            isEnumField(arg) ||
            isBoolField(arg) ||
            isRefLinkField(arg) ||
            isRatingField(arg) ||
            isMoneyField(arg) ||
            isDiscount(arg)
        )
}
