/* tslint:disable */
import * as H from "../metadataHelper"
import {Address} from "./address"
import {ContactInfo} from "./contactInfo"
import {DateOnly} from "./dateOnly"
import {UserSetting} from "./userSetting"
import {DefaultReminder} from "./defaultReminder"
import {Vacation} from "./vacation"
import {Department} from "./department"
import {EmployeeStatus} from "./employeeStatus"
import {GoogleSyncSetting} from "./googleSyncSetting"
import {File} from "./file"
import {StringField} from "./stringField"
import {IntegerField} from "./integerField"
import {EnumField} from "./enumField"
import {BoolField} from "./boolField"
import {DateField} from "./dateField"
import {DateTimeField} from "./dateTimeField"

export interface Employee extends H.BaseEntity {
    firstName?: string; // Имя
    lastName?: string; // Фамилия
    middleName?: string; // Отчество
    name?: string; // Имя
    position?: string;
    uid?: number;
    gender?: Employee.Gender;
    contactInfo?: H.List<Address | ContactInfo>; // Контактная информация
    address?: Address; // Адрес
    contactInfoCount?: number;
    isWorking?: boolean;
    fireInProgress?: boolean;
    birthday?: DateOnly;
    inn?: string;
    age?: number;
    possibleActions?: H.List<Employee.PossibleActions>;
    settings?: H.List<UserSetting<any> | UserSetting<any>>;
    settingsCount?: number;
    dateLastReadNews?: string;
    availableTransports?: H.List<string>;
    defaultReminders?: H.List<DefaultReminder>;
    defaultRemindersCount?: number;
    nearestVacation?: Vacation; // Ближайший отпуск сотрудника
    nearestVacationsCount?: number; // Количество подтвержденных отпусков сотрудника в ближайшем месяце
    emailFooter?: string; // Подпись исходящих почтовый сообщений
    isReadable?: boolean;
    effectiveness?: number; // Эффективность
    description?: string; // О себе
    megaMail?: string; // Мегапочта
    appearanceDay?: Date; // Дата начала работы в компании
    isOnline?: boolean;
    lastOnline?: Date;
    canLogin?: boolean;
    fireDay?: Date; // Дата увольнения
    passportData?: string; // Паспортные данные
    lastAssignedTasksCount?: number; // Всего задач
    lastClosedTasksCount?: number; // Закрыто задач
    lastOverdueTasksCount?: number; // Просрочено задач
    lastOverdueClosedTasksCount?: number; // Закрыто просроченных задач
    drinking?: number; // Пью
    smoking?: number; // Курю
    department?: Department; // Отдел
    status?: EmployeeStatus; // Статус В штате/Внештатник
    notificationsUnreadCount?: number; // Количество непрочитанных сообщений
    login?: string; // Логин
    password?: string; // Пароль
    behaviour?: string; // Режим работы
    hideMyBirthday?: boolean; // Скрытвать день рождения
    googleSyncSetting?: GoogleSyncSetting; // Настройки синхронизации с Google календарем
    id?: string; // Идентификатор
    avatar?: File;
    photo?: File;
    contentType: "Employee"; // Object type
}

export module Employee {
    export const contentType: "Employee" = "Employee";
    export const endpoint = "/api/v3/employee";
    export type Gender = "female"|"male";
    export module Gender {
        export const female: Gender = "female";
        export const male: Gender = "male";
    }
    export type PossibleActions = "act_can_block"|
        "act_can_fire"|
        "act_can_restore_fired"|
        "act_can_unblock"|
        "act_create_deal"|
        "act_delete"|
        "act_edit"|
        "act_read"|
        "addons_enter"|
        "assign_coordinators"|
        "blog_news_read"|
        "canChangePosition"|
        "canChangeStatus"|
        "canChangeTeam"|
        "can_create_application"|
        "can_create_widgets"|
        "can_delete_the_account"|
        "can_edit_employees_settings"|
        "can_edit_structure"|
        "can_hide_menu_items"|
        "can_install_application"|
        "can_see_productivity"|
        "can_start_feature_test_drive"|
        "can_use_telephony"|
        "certificate_enter"|
        "crm_add"|
        "crm_enter"|
        "crm_filter_export"|
        "crm_leadforms"|
        "crm_loyalty"|
        "crm_sending"|
        "crm_view_onboarding_hint"|
        "deal_add"|
        "deal_enter"|
        "discuss_add"|
        "discuss_enter"|
        "doc_add"|
        "doc_enter"|
        "employee_add"|
        "employee_bonus"|
        "employee_enter"|
        "employee_filter_export"|
        "employee_groups"|
        "employee_relation"|
        "employee_report"|
        "employee_salary"|
        "employee_structure"|
        "employee_vacation"|
        "feature_download_all"|
        "feature_new_crm_card"|
        "feature_new_crm_list"|
        "feature_new_employee_card"|
        "feature_new_employee_list"|
        "feature_new_project_card"|
        "feature_new_task_card"|
        "feature_new_task_list"|
        "feature_new_todo_card"|
        "feature_new_todo_list"|
        "feature_new_topic_list"|
        "feature_new_trade_card"|
        "feature_new_trade_list"|
        "fin_operation"|
        "fin_operation_add"|
        "fin_report"|
        "fin_settings"|
        "invoice_add"|
        "invoice_enter"|
        "issues_templates_enter"|
        "project_admin"|
        "project_attaches_available"|
        "project_attaches_available_in_add_form"|
        "project_enter"|
        "project_tags_available"|
        "project_tags_available_in_add_form"|
        "project_todos_available"|
        "project_todos_available_in_add_form"|
        "report_add"|
        "report_enter"|
        "report_sales_department"|
        "report_sales_funnel"|
        "settings_account_area"|
        "settings_cards_advertising_ways"|
        "settings_cards_business_processes"|
        "settings_cards_contractors"|
        "settings_cards_contractors_activity_types"|
        "settings_cards_contractors_custom_fields"|
        "settings_cards_contractors_types"|
        "settings_cards_countries"|
        "settings_cards_currencies"|
        "settings_cards_discussion_sections"|
        "settings_cards_employees"|
        "settings_cards_enter"|
        "settings_cards_event_categories"|
        "settings_cards_events_places"|
        "settings_cards_events_statuses"|
        "settings_cards_invoice_templates"|
        "settings_cards_invoice_units"|
        "settings_cards_managers_rights"|
        "settings_cards_offers"|
        "settings_cards_offers_custom_fields"|
        "settings_cards_positions"|
        "settings_cards_projects_custom_fields"|
        "settings_cards_requisites"|
        "settings_cards_sending"|
        "settings_cards_tasks_and_projects"|
        "settings_cards_tasks_custom_fields"|
        "settings_cards_taxes"|
        "settings_cards_vacation_types"|
        "settings_deals_deals_programs"|
        "settings_deals_delivery_types"|
        "settings_deals_enter"|
        "settings_deals_import_deals_from_email"|
        "settings_deals_integrate"|
        "settings_deals_pay_forms"|
        "settings_deals_program_wizard"|
        "settings_file_storage_info"|
        "settings_personal_setting_enter"|
        "settings_personal_setting_notifications"|
        "settings_services_integration_email_communications"|
        "settings_services_integration_eneter"|
        "settings_services_integration_imap_integration"|
        "settings_services_integration_imap_integration_for_deals"|
        "settings_services_integration_newsletters_and_notifications"|
        "settings_services_integration_outcoming_email"|
        "settings_services_integration_reply_to_notifications"|
        "settings_services_integration_rts_tender_integration"|
        "settings_services_integration_telephony"|
        "settings_setting_account_security"|
        "settings_setting_backup_management"|
        "settings_setting_employees_rights"|
        "settings_setting_enter"|
        "settings_setting_interface"|
        "settings_setting_mailouts_mail_server"|
        "settings_setting_menu_items"|
        "settings_setting_product_version"|
        "settings_setting_system"|
        "settings_setting_tasks"|
        "settings_setting_transition_box"|
        "settings_staff_can_hide_menu_items"|
        "task_attaches_available"|
        "task_attaches_available_in_add_form"|
        "task_filter_export"|
        "task_tags_available"|
        "task_tags_available_in_add_form"|
        "task_todos_available"|
        "task_todos_available_in_add_form"|
        "tasks_add"|
        "tasks_delete"|
        "tasks_enter"|
        "todos_add"|
        "todos_enter"|
        "trade_filter_export";
    export module PossibleActions {
        export const act_can_block: PossibleActions = "act_can_block";
        export const act_can_fire: PossibleActions = "act_can_fire";
        export const act_can_restore_fired: PossibleActions = "act_can_restore_fired";
        export const act_can_unblock: PossibleActions = "act_can_unblock";
        export const act_create_deal: PossibleActions = "act_create_deal";
        export const act_delete: PossibleActions = "act_delete";
        export const act_edit: PossibleActions = "act_edit";
        export const act_read: PossibleActions = "act_read";
        export const addons_enter: PossibleActions = "addons_enter";
        export const assign_coordinators: PossibleActions = "assign_coordinators";
        export const blog_news_read: PossibleActions = "blog_news_read";
        export const canChangePosition: PossibleActions = "canChangePosition";
        export const canChangeStatus: PossibleActions = "canChangeStatus";
        export const canChangeTeam: PossibleActions = "canChangeTeam";
        export const can_create_application: PossibleActions = "can_create_application";
        export const can_create_widgets: PossibleActions = "can_create_widgets";
        export const can_delete_the_account: PossibleActions = "can_delete_the_account";
        export const can_edit_employees_settings: PossibleActions = "can_edit_employees_settings";
        export const can_edit_structure: PossibleActions = "can_edit_structure";
        export const can_hide_menu_items: PossibleActions = "can_hide_menu_items";
        export const can_install_application: PossibleActions = "can_install_application";
        export const can_see_productivity: PossibleActions = "can_see_productivity";
        export const can_start_feature_test_drive: PossibleActions = "can_start_feature_test_drive";
        export const can_use_telephony: PossibleActions = "can_use_telephony";
        export const certificate_enter: PossibleActions = "certificate_enter";
        export const crm_add: PossibleActions = "crm_add";
        export const crm_enter: PossibleActions = "crm_enter";
        export const crm_filter_export: PossibleActions = "crm_filter_export";
        export const crm_leadforms: PossibleActions = "crm_leadforms";
        export const crm_loyalty: PossibleActions = "crm_loyalty";
        export const crm_sending: PossibleActions = "crm_sending";
        export const crm_view_onboarding_hint: PossibleActions = "crm_view_onboarding_hint";
        export const deal_add: PossibleActions = "deal_add";
        export const deal_enter: PossibleActions = "deal_enter";
        export const discuss_add: PossibleActions = "discuss_add";
        export const discuss_enter: PossibleActions = "discuss_enter";
        export const doc_add: PossibleActions = "doc_add";
        export const doc_enter: PossibleActions = "doc_enter";
        export const employee_add: PossibleActions = "employee_add";
        export const employee_bonus: PossibleActions = "employee_bonus";
        export const employee_enter: PossibleActions = "employee_enter";
        export const employee_filter_export: PossibleActions = "employee_filter_export";
        export const employee_groups: PossibleActions = "employee_groups";
        export const employee_relation: PossibleActions = "employee_relation";
        export const employee_report: PossibleActions = "employee_report";
        export const employee_salary: PossibleActions = "employee_salary";
        export const employee_structure: PossibleActions = "employee_structure";
        export const employee_vacation: PossibleActions = "employee_vacation";
        export const feature_download_all: PossibleActions = "feature_download_all";
        export const feature_new_crm_card: PossibleActions = "feature_new_crm_card";
        export const feature_new_crm_list: PossibleActions = "feature_new_crm_list";
        export const feature_new_employee_card: PossibleActions = "feature_new_employee_card";
        export const feature_new_employee_list: PossibleActions = "feature_new_employee_list";
        export const feature_new_project_card: PossibleActions = "feature_new_project_card";
        export const feature_new_task_card: PossibleActions = "feature_new_task_card";
        export const feature_new_task_list: PossibleActions = "feature_new_task_list";
        export const feature_new_todo_card: PossibleActions = "feature_new_todo_card";
        export const feature_new_todo_list: PossibleActions = "feature_new_todo_list";
        export const feature_new_topic_list: PossibleActions = "feature_new_topic_list";
        export const feature_new_trade_card: PossibleActions = "feature_new_trade_card";
        export const feature_new_trade_list: PossibleActions = "feature_new_trade_list";
        export const fin_operation: PossibleActions = "fin_operation";
        export const fin_operation_add: PossibleActions = "fin_operation_add";
        export const fin_report: PossibleActions = "fin_report";
        export const fin_settings: PossibleActions = "fin_settings";
        export const invoice_add: PossibleActions = "invoice_add";
        export const invoice_enter: PossibleActions = "invoice_enter";
        export const issues_templates_enter: PossibleActions = "issues_templates_enter";
        export const project_admin: PossibleActions = "project_admin";
        export const project_attaches_available: PossibleActions = "project_attaches_available";
        export const project_attaches_available_in_add_form: PossibleActions = "project_attaches_available_in_add_form";
        export const project_enter: PossibleActions = "project_enter";
        export const project_tags_available: PossibleActions = "project_tags_available";
        export const project_tags_available_in_add_form: PossibleActions = "project_tags_available_in_add_form";
        export const project_todos_available: PossibleActions = "project_todos_available";
        export const project_todos_available_in_add_form: PossibleActions = "project_todos_available_in_add_form";
        export const report_add: PossibleActions = "report_add";
        export const report_enter: PossibleActions = "report_enter";
        export const report_sales_department: PossibleActions = "report_sales_department";
        export const report_sales_funnel: PossibleActions = "report_sales_funnel";
        export const settings_account_area: PossibleActions = "settings_account_area";
        export const settings_cards_advertising_ways: PossibleActions = "settings_cards_advertising_ways";
        export const settings_cards_business_processes: PossibleActions = "settings_cards_business_processes";
        export const settings_cards_contractors: PossibleActions = "settings_cards_contractors";
        export const settings_cards_contractors_activity_types: PossibleActions = "settings_cards_contractors_activity_types";
        export const settings_cards_contractors_custom_fields: PossibleActions = "settings_cards_contractors_custom_fields";
        export const settings_cards_contractors_types: PossibleActions = "settings_cards_contractors_types";
        export const settings_cards_countries: PossibleActions = "settings_cards_countries";
        export const settings_cards_currencies: PossibleActions = "settings_cards_currencies";
        export const settings_cards_discussion_sections: PossibleActions = "settings_cards_discussion_sections";
        export const settings_cards_employees: PossibleActions = "settings_cards_employees";
        export const settings_cards_enter: PossibleActions = "settings_cards_enter";
        export const settings_cards_event_categories: PossibleActions = "settings_cards_event_categories";
        export const settings_cards_events_places: PossibleActions = "settings_cards_events_places";
        export const settings_cards_events_statuses: PossibleActions = "settings_cards_events_statuses";
        export const settings_cards_invoice_templates: PossibleActions = "settings_cards_invoice_templates";
        export const settings_cards_invoice_units: PossibleActions = "settings_cards_invoice_units";
        export const settings_cards_managers_rights: PossibleActions = "settings_cards_managers_rights";
        export const settings_cards_offers: PossibleActions = "settings_cards_offers";
        export const settings_cards_offers_custom_fields: PossibleActions = "settings_cards_offers_custom_fields";
        export const settings_cards_positions: PossibleActions = "settings_cards_positions";
        export const settings_cards_projects_custom_fields: PossibleActions = "settings_cards_projects_custom_fields";
        export const settings_cards_requisites: PossibleActions = "settings_cards_requisites";
        export const settings_cards_sending: PossibleActions = "settings_cards_sending";
        export const settings_cards_tasks_and_projects: PossibleActions = "settings_cards_tasks_and_projects";
        export const settings_cards_tasks_custom_fields: PossibleActions = "settings_cards_tasks_custom_fields";
        export const settings_cards_taxes: PossibleActions = "settings_cards_taxes";
        export const settings_cards_vacation_types: PossibleActions = "settings_cards_vacation_types";
        export const settings_deals_deals_programs: PossibleActions = "settings_deals_deals_programs";
        export const settings_deals_delivery_types: PossibleActions = "settings_deals_delivery_types";
        export const settings_deals_enter: PossibleActions = "settings_deals_enter";
        export const settings_deals_import_deals_from_email: PossibleActions = "settings_deals_import_deals_from_email";
        export const settings_deals_integrate: PossibleActions = "settings_deals_integrate";
        export const settings_deals_pay_forms: PossibleActions = "settings_deals_pay_forms";
        export const settings_deals_program_wizard: PossibleActions = "settings_deals_program_wizard";
        export const settings_file_storage_info: PossibleActions = "settings_file_storage_info";
        export const settings_personal_setting_enter: PossibleActions = "settings_personal_setting_enter";
        export const settings_personal_setting_notifications: PossibleActions = "settings_personal_setting_notifications";
        export const settings_services_integration_email_communications: PossibleActions = "settings_services_integration_email_communications";
        export const settings_services_integration_eneter: PossibleActions = "settings_services_integration_eneter";
        export const settings_services_integration_imap_integration: PossibleActions = "settings_services_integration_imap_integration";
        export const settings_services_integration_imap_integration_for_deals: PossibleActions = "settings_services_integration_imap_integration_for_deals";
        export const settings_services_integration_newsletters_and_notifications: PossibleActions = "settings_services_integration_newsletters_and_notifications";
        export const settings_services_integration_outcoming_email: PossibleActions = "settings_services_integration_outcoming_email";
        export const settings_services_integration_reply_to_notifications: PossibleActions = "settings_services_integration_reply_to_notifications";
        export const settings_services_integration_rts_tender_integration: PossibleActions = "settings_services_integration_rts_tender_integration";
        export const settings_services_integration_telephony: PossibleActions = "settings_services_integration_telephony";
        export const settings_setting_account_security: PossibleActions = "settings_setting_account_security";
        export const settings_setting_backup_management: PossibleActions = "settings_setting_backup_management";
        export const settings_setting_employees_rights: PossibleActions = "settings_setting_employees_rights";
        export const settings_setting_enter: PossibleActions = "settings_setting_enter";
        export const settings_setting_interface: PossibleActions = "settings_setting_interface";
        export const settings_setting_mailouts_mail_server: PossibleActions = "settings_setting_mailouts_mail_server";
        export const settings_setting_menu_items: PossibleActions = "settings_setting_menu_items";
        export const settings_setting_product_version: PossibleActions = "settings_setting_product_version";
        export const settings_setting_system: PossibleActions = "settings_setting_system";
        export const settings_setting_tasks: PossibleActions = "settings_setting_tasks";
        export const settings_setting_transition_box: PossibleActions = "settings_setting_transition_box";
        export const settings_staff_can_hide_menu_items: PossibleActions = "settings_staff_can_hide_menu_items";
        export const task_attaches_available: PossibleActions = "task_attaches_available";
        export const task_attaches_available_in_add_form: PossibleActions = "task_attaches_available_in_add_form";
        export const task_filter_export: PossibleActions = "task_filter_export";
        export const task_tags_available: PossibleActions = "task_tags_available";
        export const task_tags_available_in_add_form: PossibleActions = "task_tags_available_in_add_form";
        export const task_todos_available: PossibleActions = "task_todos_available";
        export const task_todos_available_in_add_form: PossibleActions = "task_todos_available_in_add_form";
        export const tasks_add: PossibleActions = "tasks_add";
        export const tasks_delete: PossibleActions = "tasks_delete";
        export const tasks_enter: PossibleActions = "tasks_enter";
        export const todos_add: PossibleActions = "todos_add";
        export const todos_enter: PossibleActions = "todos_enter";
        export const trade_filter_export: PossibleActions = "trade_filter_export";
    }
    export const newObject: Employee = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const firstName = {contentType: "StringField" as "StringField", name: "firstName", isMultiple: false, isSortable: false, id: "Employee.firstName", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const lastName = {contentType: "StringField" as "StringField", name: "lastName", isMultiple: false, isSortable: false, id: "Employee.lastName", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const middleName = {contentType: "StringField" as "StringField", name: "middleName", isMultiple: false, isSortable: false, id: "Employee.middleName", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: true, id: "Employee.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const position = {contentType: "StringField" as "StringField", name: "position", isMultiple: false, isSortable: true, id: "Employee.position", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const uid = {contentType: "IntegerField" as "IntegerField", name: "uid", isMultiple: false, isSortable: false, id: "Employee.uid", defaultValue: null as IntegerField["defaultValue"]};
        export const gender = {contentType: "EnumField" as "EnumField", name: "gender", isMultiple: false, isSortable: true, id: "Employee.gender", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["female", "male"])};
        export const contactInfo = {contentType: "RefLinkField" as "RefLinkField", name: "contactInfo", isMultiple: true, isSortable: false, id: "Employee.contactInfo", refContentType: H.List(["Address", "ContactInfo"])};
        export const address = {contentType: "RefLinkField" as "RefLinkField", name: "address", isMultiple: false, isSortable: false, id: "Employee.address", refContentType: H.List(["Address"])};
        export const contactInfoCount = {contentType: "IntegerField" as "IntegerField", name: "contactInfoCount", isMultiple: false, isSortable: false, id: "Employee.contactInfoCount", defaultValue: null as IntegerField["defaultValue"]};
        export const isWorking = {contentType: "BoolField" as "BoolField", name: "isWorking", isMultiple: false, isSortable: false, id: "Employee.isWorking", defaultValue: null as BoolField["defaultValue"]};
        export const fireInProgress = {contentType: "BoolField" as "BoolField", name: "fireInProgress", isMultiple: false, isSortable: false, id: "Employee.fireInProgress", defaultValue: null as BoolField["defaultValue"]};
        export const birthday = {contentType: "DateField" as "DateField", name: "birthday", isMultiple: false, isSortable: true, id: "Employee.birthday", defaultValue: null as DateField["defaultValue"], refContentType: H.List(["DateOnly"])};
        export const inn = {contentType: "StringField" as "StringField", name: "inn", isMultiple: false, isSortable: true, id: "Employee.inn", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const age = {contentType: "IntegerField" as "IntegerField", name: "age", isMultiple: false, isSortable: false, id: "Employee.age", defaultValue: null as IntegerField["defaultValue"]};
        export const possibleActions = {contentType: "EnumField" as "EnumField", name: "possibleActions", isMultiple: true, isSortable: false, id: "Employee.possibleActions", defaultValue: null as EnumField["defaultValue"], enumValues: H.List([
            "act_can_block",
            "act_can_fire",
            "act_can_restore_fired",
            "act_can_unblock",
            "act_create_deal",
            "act_delete",
            "act_edit",
            "act_read",
            "addons_enter",
            "assign_coordinators",
            "blog_news_read",
            "canChangePosition",
            "canChangeStatus",
            "canChangeTeam",
            "can_create_application",
            "can_create_widgets",
            "can_delete_the_account",
            "can_edit_employees_settings",
            "can_edit_structure",
            "can_hide_menu_items",
            "can_install_application",
            "can_see_productivity",
            "can_start_feature_test_drive",
            "can_use_telephony",
            "certificate_enter",
            "crm_add",
            "crm_enter",
            "crm_filter_export",
            "crm_leadforms",
            "crm_loyalty",
            "crm_sending",
            "crm_view_onboarding_hint",
            "deal_add",
            "deal_enter",
            "discuss_add",
            "discuss_enter",
            "doc_add",
            "doc_enter",
            "employee_add",
            "employee_bonus",
            "employee_enter",
            "employee_filter_export",
            "employee_groups",
            "employee_relation",
            "employee_report",
            "employee_salary",
            "employee_structure",
            "employee_vacation",
            "feature_download_all",
            "feature_new_crm_card",
            "feature_new_crm_list",
            "feature_new_employee_card",
            "feature_new_employee_list",
            "feature_new_project_card",
            "feature_new_task_card",
            "feature_new_task_list",
            "feature_new_todo_card",
            "feature_new_todo_list",
            "feature_new_topic_list",
            "feature_new_trade_card",
            "feature_new_trade_list",
            "fin_operation",
            "fin_operation_add",
            "fin_report",
            "fin_settings",
            "invoice_add",
            "invoice_enter",
            "issues_templates_enter",
            "project_admin",
            "project_attaches_available",
            "project_attaches_available_in_add_form",
            "project_enter",
            "project_tags_available",
            "project_tags_available_in_add_form",
            "project_todos_available",
            "project_todos_available_in_add_form",
            "report_add",
            "report_enter",
            "report_sales_department",
            "report_sales_funnel",
            "settings_account_area",
            "settings_cards_advertising_ways",
            "settings_cards_business_processes",
            "settings_cards_contractors",
            "settings_cards_contractors_activity_types",
            "settings_cards_contractors_custom_fields",
            "settings_cards_contractors_types",
            "settings_cards_countries",
            "settings_cards_currencies",
            "settings_cards_discussion_sections",
            "settings_cards_employees",
            "settings_cards_enter",
            "settings_cards_event_categories",
            "settings_cards_events_places",
            "settings_cards_events_statuses",
            "settings_cards_invoice_templates",
            "settings_cards_invoice_units",
            "settings_cards_managers_rights",
            "settings_cards_offers",
            "settings_cards_offers_custom_fields",
            "settings_cards_positions",
            "settings_cards_projects_custom_fields",
            "settings_cards_requisites",
            "settings_cards_sending",
            "settings_cards_tasks_and_projects",
            "settings_cards_tasks_custom_fields",
            "settings_cards_taxes",
            "settings_cards_vacation_types",
            "settings_deals_deals_programs",
            "settings_deals_delivery_types",
            "settings_deals_enter",
            "settings_deals_import_deals_from_email",
            "settings_deals_integrate",
            "settings_deals_pay_forms",
            "settings_deals_program_wizard",
            "settings_file_storage_info",
            "settings_personal_setting_enter",
            "settings_personal_setting_notifications",
            "settings_services_integration_email_communications",
            "settings_services_integration_eneter",
            "settings_services_integration_imap_integration",
            "settings_services_integration_imap_integration_for_deals",
            "settings_services_integration_newsletters_and_notifications",
            "settings_services_integration_outcoming_email",
            "settings_services_integration_reply_to_notifications",
            "settings_services_integration_rts_tender_integration",
            "settings_services_integration_telephony",
            "settings_setting_account_security",
            "settings_setting_backup_management",
            "settings_setting_employees_rights",
            "settings_setting_enter",
            "settings_setting_interface",
            "settings_setting_mailouts_mail_server",
            "settings_setting_menu_items",
            "settings_setting_product_version",
            "settings_setting_system",
            "settings_setting_tasks",
            "settings_setting_transition_box",
            "settings_staff_can_hide_menu_items",
            "task_attaches_available",
            "task_attaches_available_in_add_form",
            "task_filter_export",
            "task_tags_available",
            "task_tags_available_in_add_form",
            "task_todos_available",
            "task_todos_available_in_add_form",
            "tasks_add",
            "tasks_delete",
            "tasks_enter",
            "todos_add",
            "todos_enter",
            "trade_filter_export"
        ])};
        export const settings = {contentType: "RefLinkField" as "RefLinkField", name: "settings", isMultiple: true, isSortable: false, id: "Employee.settings", refContentType: H.List(["SystemSetting", "UserSetting"])};
        export const settingsCount = {contentType: "IntegerField" as "IntegerField", name: "settingsCount", isMultiple: false, isSortable: false, id: "Employee.settingsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const dateLastReadNews = {contentType: "StringField" as "StringField", name: "dateLastReadNews", isMultiple: false, isSortable: false, id: "Employee.dateLastReadNews", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const availableTransports = {contentType: "StringField" as "StringField", name: "availableTransports", isMultiple: true, isSortable: false, id: "Employee.availableTransports", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const defaultReminders = {contentType: "RefLinkField" as "RefLinkField", name: "defaultReminders", isMultiple: true, isSortable: false, id: "Employee.defaultReminders", refContentType: H.List(["DefaultReminder"])};
        export const defaultRemindersCount = {contentType: "IntegerField" as "IntegerField", name: "defaultRemindersCount", isMultiple: false, isSortable: false, id: "Employee.defaultRemindersCount", defaultValue: null as IntegerField["defaultValue"]};
        export const nearestVacation = {contentType: "RefLinkField" as "RefLinkField", name: "nearestVacation", isMultiple: false, isSortable: false, id: "Employee.nearestVacation", refContentType: H.List(["Vacation"])};
        export const nearestVacationsCount = {contentType: "IntegerField" as "IntegerField", name: "nearestVacationsCount", isMultiple: false, isSortable: false, id: "Employee.nearestVacationsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const emailFooter = {contentType: "StringField" as "StringField", name: "emailFooter", isMultiple: false, isSortable: false, id: "Employee.emailFooter", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const isReadable = {contentType: "BoolField" as "BoolField", name: "isReadable", isMultiple: false, isSortable: false, id: "Employee.isReadable", defaultValue: null as BoolField["defaultValue"]};
        export const effectiveness = {contentType: "IntegerField" as "IntegerField", name: "effectiveness", isMultiple: false, isSortable: false, id: "Employee.effectiveness", defaultValue: null as IntegerField["defaultValue"]};
        export const description = {contentType: "StringField" as "StringField", name: "description", isMultiple: false, isSortable: true, id: "Employee.description", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const megaMail = {contentType: "StringField" as "StringField", name: "megaMail", isMultiple: false, isSortable: false, id: "Employee.megaMail", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const appearanceDay = {contentType: "DateTimeField" as "DateTimeField", name: "appearanceDay", isMultiple: false, isSortable: false, id: "Employee.appearanceDay", defaultValue: null as DateTimeField["defaultValue"]};
        export const isOnline = {contentType: "BoolField" as "BoolField", name: "isOnline", isMultiple: false, isSortable: false, id: "Employee.isOnline", defaultValue: null as BoolField["defaultValue"]};
        export const lastOnline = {contentType: "DateTimeField" as "DateTimeField", name: "lastOnline", isMultiple: false, isSortable: false, id: "Employee.lastOnline", defaultValue: null as DateTimeField["defaultValue"]};
        export const canLogin = {contentType: "BoolField" as "BoolField", name: "canLogin", isMultiple: false, isSortable: false, id: "Employee.canLogin", defaultValue: null as BoolField["defaultValue"]};
        export const fireDay = {contentType: "DateTimeField" as "DateTimeField", name: "fireDay", isMultiple: false, isSortable: false, id: "Employee.fireDay", defaultValue: null as DateTimeField["defaultValue"]};
        export const passportData = {contentType: "StringField" as "StringField", name: "passportData", isMultiple: false, isSortable: false, id: "Employee.passportData", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const lastAssignedTasksCount = {contentType: "IntegerField" as "IntegerField", name: "lastAssignedTasksCount", isMultiple: false, isSortable: false, id: "Employee.lastAssignedTasksCount", defaultValue: null as IntegerField["defaultValue"]};
        export const lastClosedTasksCount = {contentType: "IntegerField" as "IntegerField", name: "lastClosedTasksCount", isMultiple: false, isSortable: false, id: "Employee.lastClosedTasksCount", defaultValue: null as IntegerField["defaultValue"]};
        export const lastOverdueTasksCount = {contentType: "IntegerField" as "IntegerField", name: "lastOverdueTasksCount", isMultiple: false, isSortable: false, id: "Employee.lastOverdueTasksCount", defaultValue: null as IntegerField["defaultValue"]};
        export const lastOverdueClosedTasksCount = {contentType: "IntegerField" as "IntegerField", name: "lastOverdueClosedTasksCount", isMultiple: false, isSortable: false, id: "Employee.lastOverdueClosedTasksCount", defaultValue: null as IntegerField["defaultValue"]};
        export const drinking = {contentType: "IntegerField" as "IntegerField", name: "drinking", isMultiple: false, isSortable: false, id: "Employee.drinking", defaultValue: null as IntegerField["defaultValue"]};
        export const smoking = {contentType: "IntegerField" as "IntegerField", name: "smoking", isMultiple: false, isSortable: false, id: "Employee.smoking", defaultValue: null as IntegerField["defaultValue"]};
        export const department = {contentType: "RefLinkField" as "RefLinkField", name: "department", isMultiple: false, isSortable: false, id: "Employee.department", refContentType: H.List(["Department"])};
        export const status = {contentType: "RefLinkField" as "RefLinkField", name: "status", isMultiple: false, isSortable: false, id: "Employee.status", refContentType: H.List(["EmployeeStatus"])};
        export const notificationsUnreadCount = {contentType: "IntegerField" as "IntegerField", name: "notificationsUnreadCount", isMultiple: false, isSortable: false, id: "Employee.notificationsUnreadCount", defaultValue: null as IntegerField["defaultValue"]};
        export const login = {contentType: "StringField" as "StringField", name: "login", isMultiple: false, isSortable: false, id: "Employee.login", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const password = {contentType: "StringField" as "StringField", name: "password", isMultiple: false, isSortable: false, id: "Employee.password", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const behaviour = {contentType: "StringField" as "StringField", name: "behaviour", isMultiple: false, isSortable: false, id: "Employee.behaviour", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const hideMyBirthday = {contentType: "BoolField" as "BoolField", name: "hideMyBirthday", isMultiple: false, isSortable: false, id: "Employee.hideMyBirthday", defaultValue: null as BoolField["defaultValue"]};
        export const googleSyncSetting = {contentType: "RefLinkField" as "RefLinkField", name: "googleSyncSetting", isMultiple: false, isSortable: false, id: "Employee.googleSyncSetting", refContentType: H.List(["GoogleSyncSetting"])};
        export const avatar = {contentType: "RefLinkField" as "RefLinkField", name: "avatar", isMultiple: false, isSortable: false, id: "Employee.avatar", refContentType: H.List(["File"])};
        export const photo = {contentType: "RefLinkField" as "RefLinkField", name: "photo", isMultiple: false, isSortable: false, id: "Employee.photo", refContentType: H.List(["File"])};
    }
}

export function isEmployee(arg: any): arg is Employee {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Employee"
}
