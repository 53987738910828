/* tslint:disable */
import * as H from "../metadataHelper"
import {IntegerField} from "./integerField"
import {StringField} from "./stringField"

export interface Counter extends H.BaseEntity {
    count?: number; // Counter value itself
    attributes?: H.List<string>;
    id?: string; // Идентификатор
    contentType: "Counter"; // Object type
}

export module Counter {
    export const contentType: "Counter" = "Counter";
    export const endpoint = "/api/v3/counter";

    export const newObject: Counter = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const count = {contentType: "IntegerField" as "IntegerField", name: "count", isMultiple: false, isSortable: false, id: "Counter.count", defaultValue: null as IntegerField["defaultValue"]};
        export const attributes = {contentType: "StringField" as "StringField", name: "attributes", isMultiple: true, isSortable: false, id: "Counter.attributes", defaultValue: null as StringField["defaultValue"], maxLength: 0};
    }
}

export function isCounter(arg: any): arg is Counter {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Counter"
}
