import classNames from "classnames/bind"
import * as React from "react"
import {observer} from "mobx-react"
import {autobind} from "core-decorators"
import {PROGRAM_ID, MODULES_HINT} from "src/bums/common/onboarding/tutorial/tracker"
import {StickyStore} from "src/bums/common/sticky/StickyStore"
import {Tracker} from "src/bums/common/stores/Tracker"
import {CButton, CLink, Component, CSmartCard, CStyledContent, CFlex} from "src/lib/components"
import {inject} from "src/lib/utils/inject"
import {FormattedMessage} from "src/lib/utils/intl/components"

export namespace CModularHint {
   export interface Props {
       onNextClick: () => void
   }
}

const style = classNames.bind(require("./styles/CModulesHint.styl"))
const messages = require("../messages.yml")

@observer
@autobind
export class CModularHint extends Component<CModularHint.Props, {}> {

    @inject(Tracker)
    private tracker: Tracker

    private onNextClick() {
        if (this.props.onNextClick) {
            this.props.onNextClick()
        }
        this.tracker.trackEvent(PROGRAM_ID, MODULES_HINT.step, MODULES_HINT.next)
    }

    private onSettingsClick() {
        this.tracker.trackEvent(PROGRAM_ID, MODULES_HINT.step, MODULES_HINT.settingClick)
        this.props.onNextClick()
    }

    public render() {
        return <React.Fragment>
            <CSmartCard.Header>
                <FormattedMessage {...messages["modularSystem"]} />
            </CSmartCard.Header>
            <CSmartCard.Content>
                <CStyledContent className={style("paragraph")}>
                    <FormattedMessage {...messages["modularSystemDescription"]} />
                </CStyledContent>
                <CStyledContent>
                    <FormattedMessage {...messages["modularSystemSettings"]} />
                    {" "}
                    <CLink to={"/prefs/interface/"} onClick={this.onSettingsClick} target={"_blank"} forceTarget>
                        <FormattedMessage {...messages["linkTosSettings"]}/>
                    </CLink>
                </CStyledContent>
            </CSmartCard.Content>
            <CSmartCard.Footer>
                <CFlex.Divider />
                <CFlex.Item>
                    <CButton
                        name="next"
                        caption={<FormattedMessage {...messages["next"]} />}
                        onClick={this.onNextClick}
                        type={CButton.Type.FLAT_SUCCESS}
                    />
                </CFlex.Item>
            </CSmartCard.Footer>
        </React.Fragment>
    }
}

export namespace CModularHint {
   export function getContainerPosition(store: StickyStore) {
       if (store && store.minTopOffset) {
           return {top: store.minTopOffset}
       }
       return {}
   }
   export function getHintProps(store: StickyStore) {
       if (store && store.minTopOffset) {
           return {
                verticalOrientation: "bottom" as "bottom",
                position: {top: store.minTopOffset, left: 500},
                maxWidth: 420
           }
       }
       return {}
   }
}
