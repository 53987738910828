import {Component} from "src/lib/components"
import * as ReactDOM from "react-dom"
import {getScrollParent} from "src/lib/utils/scrollHelper"
import {ReactInstance} from "react"
import {computed, observe, IValueDidChange, Lambda} from "mobx"
import {observer} from "mobx-react"

interface CScrollEventProps {
    handler: () => void
    component: ReactInstance //Компонент, относительно которого будет осуществлен поиск скролящегося компонента
}

/**
 * Компонент для автонавешивания и снятия обработчика события scroll
 */
@observer
export class CScrollEvent extends Component<CScrollEventProps, {}> {

    @computed
    public get scrollParent() {
        return getScrollParent(ReactDOM.findDOMNode(this.props.component) as HTMLElement)
    }

    @computed
    public get scrollHandler() {
        return this.props.handler
    }

    private scrollParentDispose: Lambda
    private scrollHandlerDispose: Lambda

    public componentWillMount() {
        this.scrollParent.addEventListener("scroll", this.scrollHandler)

        this.scrollParentDispose = observe(this, "scrollParent", (change: IValueDidChange<HTMLElement>) => {
            change.oldValue.removeEventListener("scroll", this.props.handler)
            if (change.type === "update") {
                change.newValue.addEventListener("scroll", this.props.handler)
            }
        })
        this.scrollHandlerDispose = observe(this, "scrollHandler", (change: IValueDidChange<() => void>) => {
            this.scrollParent.removeEventListener("scroll", change.oldValue)
            if (change.type === "update") {
                this.scrollParent.addEventListener("scroll", change.newValue)
            }
        })
    }

    public componentWillUnmount() {
        this.scrollParent.removeEventListener("scroll", this.scrollHandler)
        this.scrollParentDispose()
        this.scrollHandlerDispose()
    }

    render(): JSX.Element | null {
        return null
    }
}
