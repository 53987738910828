import {Form} from "src/lib/utils/form/form"
import {FormValidator} from "src/lib/utils/form/validation"
import {ExtraField, isBoolField, isEnumField} from "src/lib/entities/api";

export function createFormWithStaticValidator<T extends {}>() {
    return class extends Form<T> {
        constructor(
            valueFactory: () => T,
            private $validator: FormValidator<T>
        ) {
            super(valueFactory)
        }

        protected get validator() {
            return this.$validator
        }
    }
}

export function prepareExtraFieldDefaultValue(extraField: ExtraField) {
    let defaultValue = null

    if (isEnumField(extraField))  {
        if (extraField.isMultiple) {
            defaultValue = extraField.defaultValue ? [extraField.defaultValue] : null
        } else {
            defaultValue = extraField.defaultValue
        }
    } else if (isBoolField(extraField)) {
        defaultValue = extraField.defaultValue
    }

    return defaultValue
}
