import {AbstractExtraFieldsStore} from "./AbstractExtraFieldsStore"
import {inject} from "src/lib/utils/inject"
import {ApiStore} from "src/lib/entities/store/ApiStore"
import {getListEndpoint} from "src/lib/entities/utils"
import {ContractorHuman, ExtraField} from "src/lib/entities/api"

export class HumanExtraFieldsStore extends AbstractExtraFieldsStore {

    constructor(@inject(ApiStore) protected $apiStore: ApiStore) {
        super()
        void this.init()
    }

    protected initializeListStore() {
        return this.$apiStore.resolveList<ExtraField>(() => ({
            endpoint: getListEndpoint(ContractorHuman.contentType, "extraFields")
        }));
    }
}
