import * as Dom from "react-dom"
import {ReactInstance} from "react"

let inScrolling = false
let stopScroll = false

let scrollChecker: number

// кол-во пикселей по умолчанию которое мы хотим отступить от верха экрана до top элемента при прокрутке к верху
const DEFAULT_TOP_OFFSET = 200
// кол-во пикселей по умолчанию которое мы хотим отступить от низа экрана до bottom элемента при прокрутке к низу
const DEFAULT_BOTTOM_OFFSET = 50

// кол-во миллисекунд которое будет длится анимация скролла
const SCROLL_DURATION = 800

export const getScrollParent = (node: HTMLElement): HTMLElement | Window => {
    if (node == null || !(node instanceof HTMLElement)) {
        return window;
    }

    let overflowY = window.getComputedStyle(node).overflowY;
    let isScrollable = overflowY !== "visible" && overflowY !== "hidden";

    if (isScrollable && node.scrollHeight > node.clientHeight) {
        return node;
    } else {
        return getScrollParent((node.parentNode as HTMLElement));
    }
}

const getScrollTop = (component: HTMLElement | Window) => {
    if (component instanceof Window) {
        if (component.scrollY) {
            return component.scrollY
        }

        return document.documentElement.scrollTop || document.body.scrollTop
    } else {
        return component.scrollTop
    }
}

const setScrollTop = (scrollTo: number, component: HTMLElement | Window) => {

    if (component.hasOwnProperty("scrollY")) {
        component.scroll(0, scrollTo)
    } else {
        if (component instanceof Window) {
            document.documentElement.scrollTop = scrollTo
        } else {
            component.scrollTop = scrollTo
        }
    }
}

/**
 * Скролит к компоненту
 * Если позиция компонента изменяется - доскроливает до нее, до тех пор пока пользователь не поскроллил сам
 *
 * @param {React.ReactInstance} component
 */
export const scrollToElementForce = (component: ReactInstance, parent: HTMLElement | Window): void => {

    stopScroll = false
    let currentDiff = 0

    scrollCheckerDispose()

    scrollChecker = setInterval(() => {
        let domCoord = getDomCoord(component)

        if (!domCoord) {
            clearInterval(scrollChecker)
            stopScroll = true

            return
        }

        let diff = getTopDiff(domCoord, parent)

        if (currentDiff !== diff && !stopScroll) {
            doScrolling(domCoord, SCROLL_DURATION, parent)
        }
    }, SCROLL_DURATION)

    const scrollHandler = () => {
        if (!inScrolling) {
            clearInterval(scrollChecker)
            stopScroll = true
            parent.removeEventListener("scroll", scrollHandler)
        }
    }

    parent.addEventListener("scroll", scrollHandler)
}

export const scrollCheckerDispose = () => {
    clearInterval(scrollChecker)
}

/**
 * Прокручивает так чтобы верхняя граница component отстояла от верхней границы parent на offset пикселов
 * Если doBottom == true то аналогично - прокручивает так чтобы нижняя граница component отстояла от нижней
 * границы parent на offset пикселов
 * @param {React.ReactInstance} component
 * @param {HTMLElement | Window} parent
 * @param {number} offset
 * @param {boolean} doBottom
 */
export const scrollToElement = (
    component: ReactInstance,
    parent?: HTMLElement | Window,
    offset: number = DEFAULT_BOTTOM_OFFSET,
    doBottom?: boolean
): void => {

    if (!component) {
        return;
    }

    const domCoord = getDomCoord(component)

    if (!parent) {
        parent = getScrollParent(Dom.findDOMNode(component) as HTMLElement)
    }

    if (!domCoord) {
        return
    }

    doScrolling(domCoord, SCROLL_DURATION, parent, offset, doBottom)
}

/**
 * Если component ниже границы окна, то прокрутить чтобы его нижняя граница шла по нижней границе окна
 * @param {React.ReactInstance} component
 * @param {number} offset
 */
export const scrollToBottomIfBelowViewport = (
    component: ReactInstance,
    offset: number = DEFAULT_BOTTOM_OFFSET
) => {
    const domCoord = getDomCoord(component)
    if (domCoord.bottom + offset > window.innerHeight) {
        scrollToElement(component, null, offset, true)
    }
}

const getDomCoord = (component: ReactInstance) => {
    if (!component) {
        return;
    }

    const commentInputContainer = Dom.findDOMNode(component)
    if (commentInputContainer instanceof Element) {
        return commentInputContainer.getBoundingClientRect()
    }
}

/**
 * Возвращает сколько нужно проскролить чтобы верхняя граница domCoord отстояла от
 * max(верхняя граница container, верхняя граница window viewport) на offset пикселов
 * @param {ClientRect} domCoord
 * @param {HTMLElement | Window} container
 * @param {number} offset
 * @returns {number}
 */
const getTopDiff = (domCoord: ClientRect, container: HTMLElement | Window, offset: number = DEFAULT_TOP_OFFSET) => {
    if (container instanceof Window) {
        return domCoord.top - offset
    } else {
        // если скроолим внутри div'а то скроллит нужно до max(верхняя граница div, верхняя граница экрана)
        const domCoordContainer = getDomCoord(container)
        return Math.min(domCoord.top - domCoordContainer.top, domCoord.top - offset)
    }
}
/**
 * Возвращает сколько нужно проскролить чтобы нижняя граница domCoord отстояла от
 * min(нижняя граница container, нижняя граница window viewport) на offset пикселов
 * @param {ClientRect} domCoord
 * @param {HTMLElement | Window} container
 * @param {number} offset
 * @returns {number}
 */
const getBottomDiff = (domCoord: ClientRect, container: HTMLElement | Window, offset: number = DEFAULT_BOTTOM_OFFSET) => {
    if (container instanceof Window) {
        return domCoord.bottom - window.innerHeight + offset
    } else {
        // если скроллим внутри div'а то скроллить нужно до min(нижняя граница div, нижняя граница экрана)
        const domCoordContainer = getDomCoord(container)
        return Math.min(domCoord.bottom - window.innerHeight, domCoordContainer.bottom - domCoord.bottom) + offset
    }
}

const doScrolling = (
    domCoord: ClientRect,
    duration: number,
    container: HTMLElement | Window,
    offset: number = DEFAULT_BOTTOM_OFFSET,
    doBottom?: boolean
) => {
    inScrolling = true

    let diff = 0
    if (doBottom) {
        diff = getBottomDiff(domCoord, container, offset)
    } else {
        diff = getTopDiff(domCoord, container, offset)
    }

    const startingY = getScrollTop(container)
    let start: number

    window.requestAnimationFrame(function step(timestamp: number) {
        if (!start) {
            start = timestamp
        }

        const time = timestamp - start

        const percent = Math.min(time / duration, 1)

        setScrollTop(startingY + diff * percent, container)

        if (time < duration) {
            window.requestAnimationFrame(step)
        } else {
            inScrolling = false
        }
    })

}
