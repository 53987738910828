import * as Api from "src/lib/entities/api"
import {List} from "src/lib/collections"

export function getTimeCreated(el: Api.File | Api.FileGroup) {
    if (!el) {
        return null
    }

    if (Api.isFile(el)) {
        return el.timeCreated
    }

    if (Api.isFileGroup(el)) {
        if (!el.children || !el.children.last()) {
            return null
        }
        return el.children.last().timeCreated
    }

    return null
}

function removeDuplicatesSmtp(mailAccounts: List<Api.Imap | Api.Smtp | Api.Megamail>) {
    const imapEmails = mailAccounts.filter(item => Api.isImap(item)).map(item => item.email)

    return mailAccounts.filterNot(item => Api.isSmtp(item) && imapEmails.includes(item.email))
}

function sortMailAccounts(mailAccounts: List<Api.Imap | Api.Smtp | Api.Megamail>) {
    return mailAccounts.sort((a, b) => (a as Api.Smtp).isDefault === (b as Api.Smtp).isDefault ? 0 : (a as Api.Smtp).isDefault ? -1 : 1)
}

export function prepareMailAccounts(mailAccounts: List<Api.Imap | Api.Smtp | Api.Megamail>) {
    if (!mailAccounts.length) {
        return List()
    }

    const noSmtpDuplicatesList = removeDuplicatesSmtp(mailAccounts)

    return sortMailAccounts(noSmtpDuplicatesList)
}

export const CHAT_FULLSCREEN_OPEN_EVENT = "fullscreen open"
export const CHAT_VIDEO_CALL_EVENT = "video call"
export const CHAT_ARCHIVE_OPEN_EVENT = "archive open"
export const CHAT_INFO_OPEN_EVENT = "info open"
export const CHAT_MESSAGES_SEARCH_OPEN_EVENT = "messages search open"
export const CHAT_WIDGET_SEARCH_EVENT = "Do Search" // начать поиск в чатах
export const NEW_CHAT_MODAL_OPEN_EVENT = "New Chat Modal Open" // подтвердить создание нового обсуждения
export const OPEN_CHAT_EVENT = "Open Chat" // событие открыть чат
export const CHAT_FOLDER_OPEN_EVENT = "Open Folder"
export const CHAT_MENU_OPEN_EVENT = "Chat Menu Click"// по клику на меню в списке чатов справа
export const CHAT_MENU_ITEM_OPEN_EVENT = "Chat Do Action" // по клику на пункт в меню
export const NEW_CHAT_EVENT = "New Chat Click" // клику на иконку создания чата
export const NEW_CHAT_CONFIRM_EVENT = "New Chat Confirm" // подтвердить создание

export const MARK_AS_READ_ACTION = "Mark As Read" // пометить как прочитанное
export const MARK_AS_UNREAD_ACTION = "Mark As Unread"  //отметить как непрочитанную
export const UNPIN_TOPIC_ACTION = "Unpin Topic" // открепить
export const PIN_TOPIC_ACTION = "Pin Topic" // закрепить
export const HIDE_TOPIC_ACTION = "Hide Topic" // скрыть тему
export const RESTORE_TOPIC_ACTION = "Restore Topic" // восстановить тему
export const NOTIFICATION_OFF_ACTION = "Notifications Off"
export const NOTIFICATION_ON_ACTION = "Notifications On"
export const CREATE_GROUP_ACTION = "Create Group"
export const CREATE_CHANNEL_ACTION = "Create Channel"
export const EDIT_GROUP_ACTION = "Edit Group"
export const EDIT_CHANNEL_ACTION = "Edit Channel"

// tslint:disable-next-line:no-cyrillic-in-string-literals
export const ARCHIVE = "Архив"
export const ALL = "all"
