import * as React from "react"
import {observer} from "mobx-react"
import {computed} from "mobx"
import {autobind} from "core-decorators"
import {inject} from "src/lib/utils/inject"
import {
    CModal,
    Component,
} from "src/lib/components"
import {Router} from "src/lib/utils/router"
import {CInviteLinkForm} from "src/bums/common/onboarding/tutorial/CInviteTeam/CInviteLinkForm"

@observer
@autobind
export class CInviteLinkModal extends Component<{}, {}> {

    @inject(Router)
    private router: Router

    @computed
    private get isOpen() {
        return this.router.location.hash === "#invite"
    }

    private clearHash() {
        window.location.hash = ""
    }

    public render() {
        if (!this.isOpen) {
            return null
        }

        return <CModal
            open={this.isOpen}
            onClose={this.clearHash}
            maxWidth={492}
            autoOverflow
        >
            <CInviteLinkForm />
        </CModal>
    }
}
