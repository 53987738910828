import * as React from "react"
import {CIcon, CButton, Component} from "src/lib/components"
import {Intl} from "src/lib/utils/intl"
import {inject} from "src/lib/utils/inject"
import {autobind} from "core-decorators"
import {action, observable} from "mobx";
import {observer} from "mobx-react";
import classNames from "classnames/bind";
import {COPY_COMMENT_EVENT, JOURNAL_EVENT_TYPE} from "src/bums/common/comment/constants"
import track from "src/lib/tracking"

const messages: any = require("./../../messages.yml")

const style = classNames.bind(require("./CCopyToClipboardButton.styl"))

const regexp = /<p>|<\/p>/

interface CCopyToClipboardButtonProps {
    contentToCopy?: string
    type?: "icon" | "button"
    onAfterCopy?: () => void
    size?: CButton.Size
    disabled?: boolean
}

/**
 * Компонент кнопки, которая по клику копирует переданный текст в буфер обмена.
 * При наличии html вставляет его вместе с plain текстом,
 * что дает возможность сохранить стили для вставки в текстовый редактор
 */

@track({eventType: JOURNAL_EVENT_TYPE, channels: ["product", "amplitude"]})
@observer
@autobind
export class CCopyToClipboardButton extends Component<CCopyToClipboardButtonProps, {}> {
    public static defaultProps = {
        size: "small"
    }

    @inject(Intl)
    private intl: Intl

    @observable
    private showSuccessIcon = false

    private copyWithStyle(event: ClipboardEvent): void {
        event.clipboardData.setData("text/plain", this.props.contentToCopy)
        if (this.props.contentToCopy) {
            event.clipboardData.setData("text/html", this.props.contentToCopy)
        }
        event.preventDefault()
        document.removeEventListener("copy", this.copyWithStyle)
    }

    @track((self: CCopyToClipboardButton) => {
        const {contentToCopy} = self.props
        return {
            event: COPY_COMMENT_EVENT,
            params: {
                method: contentToCopy.match(regexp) ? "full" : "part",
            }
        }})
    @action
    private async onClick() {
        document.addEventListener("copy", this.copyWithStyle)
        document.execCommand("Copy")
        this.showSuccessIcon = true
        setTimeout(action(() => this.showSuccessIcon = false), 1000)

        if (this.props.onAfterCopy) {
            this.props.onAfterCopy()
        }
    }

    render() {
        return this.props.type === "button"
            ? <CButton
                name="copyToClipboard"
                type={CButton.Type.MENU}
                title={this.intl.formatMessage(messages["copyToClipboard"])}
                caption={this.intl.formatMessage(messages["copyToClipboardShort"])}
                onClick={this.onClick}
                size={this.props.size}
                disabled={this.props.disabled}
            />
            : <CButton
                name="copyToClipboard"
                className={style("successIcon", {show: this.showSuccessIcon})}
                type={CButton.Type.FLAT}
                color={this.showSuccessIcon ? CIcon.Color.EMERALD : CIcon.Color.DARK}
                icon={this.showSuccessIcon ? CIcon.Type.CONFIRM : CIcon.Type.COPY}
                size={this.props.size}
                title={this.intl.formatMessage(messages["copyToClipboardShort"])}
                onClick={this.onClick}
                hideCaption={true}
                disabled={this.props.disabled}
            />
    }
}
