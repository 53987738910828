import * as React from "react"
import {CStyledContent, CLink} from "src/lib/components"
import {Tracker} from "src/bums/common/stores/Tracker"
import {bindArg} from "src/lib/utils/func"


// Переводы
import {FormattedMessage, FormattedMessageHtml} from "src/lib/utils/intl"

const messages = require("./messages.yml") as any


const buildVersionNames: {[index: string]: number} = {
    r1811: 1811,
    r1812: 1812
}



const TRACKER_PREFIX = "click_on_help_link_in_"

export const TaskHintIdsType = {
    mainInformation: "mainInformation",
    description: "description",
    actionButtons: "actionButtons",
    planned: "planned",
}


/* tslint:disable:max-line-length */
/* tslint:disable:no-cyrillic-in-string-literals */

export function taskTutorialHintsMap(tracker?: Tracker): {
    [index: string]:
        {
            title: JSX.Element,
            content: JSX.Element,
            buildVersion: number | null | "oldOnly", // в случае подсказки для всех null
        }
} {
    return {
        [TaskHintIdsType.mainInformation]: {
            "title": <FormattedMessage {...messages["mainInformationTitle"]} />,
            "content": <React.Fragment>
                <CStyledContent>
                    <div>
                        <FormattedMessageHtml {...messages["mainInformationContent"]} />
                    </div>
                    <CLink
                        to="/help/article/tasks/work/taskcard"
                        target="_blank"
                        forceTarget
                        onClick={tracker
                            ? bindArg(tracker.trackEvent, "click", TRACKER_PREFIX + TaskHintIdsType.mainInformation)
                            : void 0
                        }
                    >
                        <FormattedMessage {...messages["mainInformationLink"]} />
                    </CLink>
                </CStyledContent>
            </React.Fragment>,
            buildVersion: buildVersionNames.r1811
        },
        [TaskHintIdsType.description]: {
            "title": <FormattedMessage {...messages["descriptionTitle"]} />,
            "content": <React.Fragment>
                <CStyledContent>
                    <div>
                        <FormattedMessage {...messages["descriptionContent"]} />
                    </div>
                </CStyledContent>
            </React.Fragment>,
            buildVersion: buildVersionNames.r1811
        },
        [TaskHintIdsType.actionButtons]: {
            "title": <FormattedMessage {...messages["actionButtonsTitle"]} />,
            "content": <React.Fragment>
                <CStyledContent>
                    <div>
                        <FormattedMessage {...messages["actionButtonsContent"]} />
                    </div>
                    <CLink
                        to="/help/article/tasks/work/taskwork"
                        target="_blank"
                        forceTarget
                        onClick={tracker
                            ? bindArg(tracker.trackEvent, "click", TRACKER_PREFIX + TaskHintIdsType.actionButtons)
                            : void 0
                        }
                    >
                        <FormattedMessage {...messages["actionButtonsLink"]} />
                    </CLink>
                </CStyledContent>
            </React.Fragment>,
            buildVersion: buildVersionNames.r1811
        },
    }
}
