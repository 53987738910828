import * as React from "react";
import classNames from "classnames/bind";
import * as Collections from "src/lib/collections";
import CRadioSelect, {Option} from "src/lib/components/CRadioSelect/CRadioSelect";
import {Form} from "src/lib/utils/form/form";
import {FirstConfigureFormValue} from "src/bums/common/onboarding/utils";
import {CCustomScrollbars} from "src/lib/components/CCustomScrollbars/CCustomScrollbars";

const style = classNames.bind(require("../styles/CFirstConfigureGlobalContainer.styl"))

interface CStepOptionsProps  {
    options: Collections.List<Option>,
    form: Form<FirstConfigureFormValue>
    formField: "forWhom" | "howManyEmployees" | "chooseRole" | "chooseIndustry" | "experienceWithCRM",
    boxView?: boolean
}

export const CStepOptions: React.FunctionComponent<CStepOptionsProps> = props => {
    return props.form.renderValue(props.formField, bind => {
        const radioSelect = <CRadioSelect
            {...bind}
            options={props.options}
            value={props.form.get(props.formField)}
            optionClassName={style({"option" : !props.boxView})}
            inline={!props.boxView}
        />

        return <div className={style("checkboxesSelect", {"checkboxesContainer": props.boxView})}>
            {props.boxView
                ? <CCustomScrollbars>
                    {radioSelect}
                </CCustomScrollbars>
                : radioSelect}
        </div>
    })
}
