import * as React from "react"
import classNames from "classnames/bind"
import {autobind} from "core-decorators"
import {CPopover, CIcon, CButton, Component} from "src/lib/components"
import {observer} from "mobx-react"
import {observable, action, computed} from "mobx"
import CTheme from "src/lib/components/CTheme/CTheme"
import {Position} from "src/lib/types"

const style = classNames.bind(require("./CHint.styl"))


/**
 * Всплывающая подсказка
 */
export namespace CHint {
    export interface Props {
        // top - от верхней границы элемента вверх, bottom - от нижней границы элемента вниз
        verticalOrientation?: "top" | "bottom"
        // top - от верхней границы элемента вверх, bottom - от нижней границы элемента вниз
        horizontalOrientation?: "left" | "right" | "center"
        type?: "default" | "dark", // определяет цвет фона
        element?: JSX.Element // элемент относительно которого будет позиционироваться поповер
        noStyle?: boolean // убирает отступы и стилизацию текста
        direction?: "horizontal" | "vertical" // область вывода - "horizontal" - справа/слева, "vertical" - сверху/снизу
        open?: boolean
        position?: Position // Точная позиция поповера в координатах
        maxWidth?: number
        disableOutsideClick?: boolean
        onClose?: () => void
        onOutsideClose?: () => void
        isFromCenter?: boolean,
        zIndex?: string,
        popoverClassName?: string,
        hintClassName?: string
        cancelButton?: boolean //Нужна ли кнопка для закрытия подсказки
    }
}

@observer
@autobind
export class CHint extends Component<CHint.Props, {}> {

    public static defaultProps = {
        verticalOrientation: "top",
        type: "default",
        cancelButton: true
    }

    @observable
    private $open: boolean = false

    @action
    private closeHandler(event: any) {
        this.$open = false
        if (this.props.onClose) {
            this.props.onClose()
        }

        return event
    }

    @action
    private outsideCloseHandler(event: any) {
        this.$open = false

        if (this.props.onOutsideClose) {
            this.props.onOutsideClose()

            return event
        }

        return this.closeHandler(event)
    }

    @action
    private openHandler(event: React.MouseEvent<HTMLElement>) {
        this.$open = true
        // Хинт может быть на кликабельном элементе и его не стоит затирать
        if (this.props.element && this.props.element.props.onClick) {
            this.props.element.props.onClick(event)
        }
    }

    @computed
    private get isOpen() {
        return this.props.open == null
            ? this.$open
            : this.props.open
    }

    public render() {

        const {
            element,
            type,
            noStyle,
            position,
            maxWidth,
            horizontalOrientation,
            direction,
            isFromCenter,
            verticalOrientation,
            hintClassName,
            cancelButton
        } = this.props

        const targetElement = element
            ? React.cloneElement(
                element,
                {onClick: this.openHandler}
            )
            : <CIcon
                size={CIcon.Size.SMALL}
                type={CIcon.Type.INQUIRY}
                onClick={this.openHandler}
                className={style("icon")}
            />

        const content = <React.Fragment>
            <div className={style("CHint", hintClassName, {styled: !noStyle})}>
                {this.props.children}
            </div>
            {cancelButton && <CButton
                name="cancelHint"
                type={CButton.Type.FLAT}
                icon={CIcon.Type.CANCEL}
                size="small"
                className={style("button")}
                onClick={this.closeHandler}
            />}
        </React.Fragment>

        return <CPopover
            element={targetElement}
            onClose={!this.props.disableOutsideClick ? this.outsideCloseHandler : void 0}
            open={this.isOpen}
            autoPosition={true}
            type={type === "dark" ? "darkhint" : "hint"}
            maxWidth={maxWidth || (type === "dark" ? 424 : 320)}
            noPadding={true}
            direction={direction}
            position={position}
            horizontalOrientation={horizontalOrientation}
            verticalOrientation={verticalOrientation}
            isFromCenter={isFromCenter}
            zIndex={this.props.zIndex}
            className={this.props.popoverClassName}
        >
            {type === "dark"
                ? <CTheme>
                    <div className={style("dark")}>
                        {content}
                    </div>
                </CTheme>
                : content
            }
        </CPopover>
    }
}
