import * as React from "react"
import {connect} from "react-redux"

import * as Megaplan from "src/megaplan"
import {LoadState} from "src/lib/utils/loadState"
import {CSpinner, Component} from "src/lib/components"

interface OwnProps {
    loadState?: LoadState
}

type CInitialLoadingContainerProps = OwnProps

/**
 * Начальная загрузка всего приложения.
 */
class CInitialLoadingContainer extends Component<CInitialLoadingContainerProps, {}> {
    public render() {
        return this.props.loadState.isPending() ? <CSpinner /> : React.Children.only(this.props.children);
    }
}

function mapStateToProps(state: Megaplan.State): CInitialLoadingContainerProps {
    return {loadState: state.InitialLoading}
}

export default connect(mapStateToProps)(CInitialLoadingContainer) as typeof CInitialLoadingContainer
