import {CButton, CFlex, CModal, Component, CSmartCard, CStyledContent} from "src/lib/components"
import {ContentPosition} from "src/lib/components/CModal/CModal"
import * as React from "react"
import classNames from "classnames/bind"
import {inject} from "src/lib/utils/inject"
import {Intl} from "src/lib/utils/intl/Intl"

export namespace CExtendedConfirmModal {
    export interface Props {
        open: boolean
        header: string
        onCancel: () => void
        description?: string | JSX.Element
        content?: JSX.Element
        onConfirm?: () => void
        disabled?: boolean
        isLoading?: boolean
        confirmButtonCaption?: string
        cancelButtonCaption?: string
        additionalContent?: JSX.Element
    }
}

const libMessages = require("src/lib/messages.yml")
const style = classNames.bind(require("./CExtendedConfirmModal.styl"))

export class CExtendedConfirmModal extends Component<CExtendedConfirmModal.Props, {}> {

    @inject(Intl)
    private intl: Intl

    private renderMainContent() {
        const {
            onConfirm,
            onCancel,
            disabled,
            isLoading,
            header,
            description,
            content,
            confirmButtonCaption,
            cancelButtonCaption
        } = this.props
        return <div>
            <CSmartCard.Header className={style("cardHeader")}>
                {header}
            </CSmartCard.Header>
            <CSmartCard.Content className={style("cardContent")}>
                {description && <CStyledContent className={style("contextText")}>
                    {description}
                </CStyledContent>}
                {content}
            </CSmartCard.Content>
            <CSmartCard.Footer>
                {onConfirm && <CButton
                    name="confirm"
                    caption={confirmButtonCaption || this.intl.formatMessage(libMessages["confirm"])}
                    type={CButton.Type.SUCCESS}
                    onClick={onConfirm}
                    marginRight={CButton.Margin.SM}
                    loading={isLoading}
                    disabled={disabled}
                />}
                <CButton
                    name="cancel"
                    caption={cancelButtonCaption || this.intl.formatMessage(libMessages["cancel"])}
                    type={CButton.Type.FLAT}
                    onClick={onCancel}
                    loading={isLoading}
                />
            </CSmartCard.Footer>
        </div>
    }

    private renderAdditionalContent() {
        return this.props.additionalContent
    }

    render() {
        const {open, onCancel} = this.props
        return <CModal
            open={open}
            onClose={onCancel}
            maxWidth={724}
            contentPosition={ContentPosition.CENTER}
            animateClass="animate-fadein"
            hasCloseButton
        >
            <CSmartCard className={style("card")}>
                <CFlex>
                    {this.renderMainContent()}
                    {this.renderAdditionalContent()}
                </CFlex>
            </CSmartCard>
        </CModal>
    }
}
