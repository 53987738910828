/* tslint:disable */
import * as H from "../metadataHelper"
import {EnumField} from "./enumField"
import {StringField} from "./stringField"
import {BoolField} from "./boolField"

export interface OnboardingPollResult extends H.BaseValue {
    forWhom?: OnboardingPollResult.ForWhom;
    chooseIndustry?: string;
    chooseRole?: OnboardingPollResult.ChooseRole;
    howManyEmployees?: OnboardingPollResult.HowManyEmployees;
    experienceWithCRM?: OnboardingPollResult.ExperienceWithCRM;
    nameOfYourCompany?: string;
    pollWasCanceled?: boolean;
    comment?: string;
    isCustomIndustry?: boolean;
    isCustomNameOfYourCompany?: boolean;
    contentType: "OnboardingPollResult"; // Object type
}

export module OnboardingPollResult {
    export const contentType: "OnboardingPollResult" = "OnboardingPollResult";
    export const endpoint = "/api/v3/onboardingPollResult";
    export type ForWhom = "forCompany"|"forDepartment"|"forMyself"|"forTeam";
    export module ForWhom {
        export const forCompany: ForWhom = "forCompany";
        export const forDepartment: ForWhom = "forDepartment";
        export const forMyself: ForWhom = "forMyself";
        export const forTeam: ForWhom = "forTeam";
    }
    export type ChooseRole = "director"|"employee"|"manager";
    export module ChooseRole {
        export const director: ChooseRole = "director";
        export const employee: ChooseRole = "employee";
        export const manager: ChooseRole = "manager";
    }
    export type HowManyEmployees = "from6To15People"|"moreThan15People"|"upTo5People";
    export module HowManyEmployees {
        export const from6To15People: HowManyEmployees = "from6To15People";
        export const moreThan15People: HowManyEmployees = "moreThan15People";
        export const upTo5People: HowManyEmployees = "upTo5People";
    }
    export type ExperienceWithCRM = "introductoryOnly"|"no"|"yesIUseItNow"|"yesImplementedEarlier";
    export module ExperienceWithCRM {
        export const introductoryOnly: ExperienceWithCRM = "introductoryOnly";
        export const no: ExperienceWithCRM = "no";
        export const yesIUseItNow: ExperienceWithCRM = "yesIUseItNow";
        export const yesImplementedEarlier: ExperienceWithCRM = "yesImplementedEarlier";
    }
    export const newObject: OnboardingPollResult = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const forWhom = {contentType: "EnumField" as "EnumField", name: "forWhom", isMultiple: false, isSortable: false, id: "OnboardingPollResult.forWhom", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["forCompany", "forDepartment", "forMyself", "forTeam"])};
        export const chooseIndustry = {contentType: "StringField" as "StringField", name: "chooseIndustry", isMultiple: false, isSortable: false, id: "OnboardingPollResult.chooseIndustry", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const chooseRole = {contentType: "EnumField" as "EnumField", name: "chooseRole", isMultiple: false, isSortable: false, id: "OnboardingPollResult.chooseRole", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["director", "employee", "manager"])};
        export const howManyEmployees = {contentType: "EnumField" as "EnumField", name: "howManyEmployees", isMultiple: false, isSortable: false, id: "OnboardingPollResult.howManyEmployees", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["from6To15People", "moreThan15People", "upTo5People"])};
        export const experienceWithCRM = {contentType: "EnumField" as "EnumField", name: "experienceWithCRM", isMultiple: false, isSortable: false, id: "OnboardingPollResult.experienceWithCRM", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["introductoryOnly", "no", "yesIUseItNow", "yesImplementedEarlier"])};
        export const nameOfYourCompany = {contentType: "StringField" as "StringField", name: "nameOfYourCompany", isMultiple: false, isSortable: false, id: "OnboardingPollResult.nameOfYourCompany", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const pollWasCanceled = {contentType: "BoolField" as "BoolField", name: "pollWasCanceled", isMultiple: false, isSortable: false, id: "OnboardingPollResult.pollWasCanceled", defaultValue: null as BoolField["defaultValue"]};
        export const comment = {contentType: "StringField" as "StringField", name: "comment", isMultiple: false, isSortable: false, id: "OnboardingPollResult.comment", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const isCustomIndustry = {contentType: "BoolField" as "BoolField", name: "isCustomIndustry", isMultiple: false, isSortable: false, id: "OnboardingPollResult.isCustomIndustry", defaultValue: null as BoolField["defaultValue"]};
        export const isCustomNameOfYourCompany = {contentType: "BoolField" as "BoolField", name: "isCustomNameOfYourCompany", isMultiple: false, isSortable: false, id: "OnboardingPollResult.isCustomNameOfYourCompany", defaultValue: null as BoolField["defaultValue"]};
    }
}

export function isOnboardingPollResult(arg: any): arg is OnboardingPollResult {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "OnboardingPollResult"
}
