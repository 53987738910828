import {combineReducers} from "redux"

import {Dispatch as ApiStoreDispatch} from "src/lib/entities/apiStoreMiddleware"
import * as ReportCard from "src/bums/report/card/reducer"
import * as ReportList from "src/bums/report/list/reducer"
import * as BumsTradeFunnel from "./bums/trade/funnel/reducer"
import * as CardState from "src/lib/components/CCardContainer/reducer"
import * as BumsReportEdit from "src/bums/report/edit/reducer"
import * as InitialLoading from "src/bums/common/initialLoading/reducer"
import * as BumsFunnelPromo from "src/bums/trade/promo/reducer"
import * as ContextMenu from "src/bums/common/contextMenu/reducer"

export interface State {
    InitialLoading: InitialLoading.State
    BumsReportList: ReportList.State
    BumsReportCard: ReportCard.State
    CardState: CardState.State
    BumsTradeFunnel: BumsTradeFunnel.State
    BumsReportEdit: BumsReportEdit.State
    BumsFunnelPromo: BumsFunnelPromo.State
    ContextMenu: ContextMenu.State
}

/**
 * Какие части состояния сохранять в локальном хранилище браузера.
 * @type {Array}
 */
export const persistWhitelist: Array<string | Array<string>> = [
    ["BumsReportList", "promoClosed"]
]

export type Dispatch = ApiStoreDispatch & {
    <T>(action: (...arg: any[]) => T): T;
    <T>(action: T): T;
}

export const reducer = combineReducers<State>({
    InitialLoading: InitialLoading.reducer,
    BumsReportList: ReportList.reducer,
    BumsReportCard: ReportCard.reducer,
    CardState: CardState.reducer,
    BumsTradeFunnel: BumsTradeFunnel.reducer,
    BumsReportEdit: BumsReportEdit.reducer,
    BumsFunnelPromo: BumsFunnelPromo.reducer,
    ContextMenu: ContextMenu.reducer,
})

export {contentTypeToClassMap} from "src/ContentTypeToClassMap"
