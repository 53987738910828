import {autobind} from "core-decorators"
import {action, observable, computed} from "mobx"
import {isNumber} from "lodash"
import * as Collections from "src/lib/collections"
import {ObservableListClass} from "src/lib/collections/ListClass"
import {FetchErrors, FormError} from "src/lib/utils/form/types"
import {FormContract, CollectionChildForm} from "./types"
import {Form} from "src/lib/utils/form/form"

@autobind
export class FormCollection<T extends object, F extends FormContract<T>> implements FormContract<Collections.List<T>> {

    @observable.ref
    private $userValue: ObservableListClass<T> | void

    @observable.ref
    private $externalErrors: FetchErrors

    protected $childFormsHash: WeakMap<T, CollectionChildForm<T, F>> = new WeakMap()
    @observable protected $showErrors = false

    constructor(
        private $initialValue: () => Collections.List<T>,
        private $elementFormConstructor: (v: T) => F = v => new Form(() => v) as FormContract<T> as F
    ) {}

    @action
    public reset(): void {
        this.$userValue = null
        this.$childFormsHash = new WeakMap()
        this.$externalErrors = null
    }

    @computed
    public get initialValue() {
        return this.$initialValue()
    }

    @computed
    public get value() {
        if (!this.isDirty) {
            return this.initialValue
        }
        return this.childForms.map(child => child.form.value)
    }

    @computed
    public get isDirty() {
        return !!this.$userValue || this.childForms.some(child => child.form.isDirty)
    }

    /** метод валидации по сути абстрактный и его нужно имплементить */
    protected validate(formValue: Collections.List<T>): Collections.List<FormError> {
        return Collections.List<FormError>()
    }

    @computed
    public get isValid() {
        if (this.errors.length) {
            return false
        }
        if (this.childForms.some(child => !child.form.isValid)) {
            return false
        }
        return true
    }

    @computed
    private get errors() {
        const apiErrors = this.$externalErrors ? this.$externalErrors.own : []
        return this.validate(this.value).concat(apiErrors)
    }

    @computed
    public get ownErrors() {
        return this.$showErrors
            ? this.errors
            : Collections.List()
    }

    @action
    public setErrors(formErrors: FetchErrors) {
        this.$externalErrors = formErrors
        for (let prop in this.$externalErrors.children) {
            if (isNumber(Number(prop))) {
                this.childForms.get(Number(prop)).form.setErrors(formErrors.children[prop])
            }
        }
    }

    @action
    public removeErrors() {
        this.childForms.forEach(child => child.form.removeErrors())
        this.$externalErrors = null
    }

    @action
    public showErrors() {
        this.$showErrors = true
        this.childForms.forEach(child => child.form.showErrors())
    }

    @action
    public hideErrors() {
        this.$showErrors = true
        this.childForms.forEach(child => child.form.hideErrors())
    }

    @computed
    public get isErrorsShown() {
        return this.$showErrors
    }

    public map<U>(mapper: (child: CollectionChildForm<T, F>, index: number) => U): Collections.List<U> {
        return this.childForms.map(mapper)
    }

    public filter(mapper: (child: CollectionChildForm<T, F>, index: number) => boolean): Collections.List<CollectionChildForm<T, F>> {
        return this.childForms.filter(mapper)
    }

    public forEach(callback: (child: CollectionChildForm<T, F>, index: number) => void): void {
        return this.childForms.forEach(callback)
    }

    public get(index: number): CollectionChildForm<T, F> | void {
        return this.childForms.get(index)
    }

    @action
    public push(newValue: T) {
        this.insert(this.length, newValue)
    }

    @action
    public insert(idx: number, newValue: T) {
        if (newValue) {
            if (!this.$userValue) {
                this.$userValue = new ObservableListClass(this.initialValue)
            }
            this.$userValue.set(idx, newValue)
        }
    }

    public get length() {
        return this.childForms.length
    }

    @computed
    public get childForms(): Collections.List<CollectionChildForm<T, F>> {
        let collection: Collections.List<T>

        if (this.$userValue) {
            collection = this.$userValue
        } else {
            collection = Collections.isList(this.initialValue) ? this.initialValue : Collections.List()
        }

        return collection.reduce((result, value) => {
            if (!!value) {
                result.push(this.getOrCreateChild(value))
            }

            return result
        }, Collections.List())
    }

    private getOrCreateChild(value: T): CollectionChildForm<T, F> {
        // Либо сохраненный
        if (this.$childFormsHash.has(value)) {
            return this.$childFormsHash.get(value)
        }
        // либо создаем и сохраняем
        const form = this.$elementFormConstructor(value)
        const remove = action(() => {
            const index = this.childForms.findIndex(childForm => childForm.form === form)
            if (!this.$userValue) {
                this.$userValue = new ObservableListClass(this.initialValue)
            }
            if (index !== -1) {
                this.$userValue.remove(index)
            }
        })
        const replace = action((newValue: T) => {
            if (!newValue) {
                return
            }
            const index = this.childForms.findIndex(childForm => childForm.form === form)
            if (!this.$userValue) {
                this.$userValue = new ObservableListClass(this.initialValue)
            }
            if (index !== -1) {
                this.$userValue.set(index, newValue)
            }
        })
        const child = { form, remove, replace }
        this.$childFormsHash.set(value, child)
        return child
    }

}
