import {action, observable, computed} from "mobx"
import {autobind} from "core-decorators"
import {Form} from "src/lib/utils/form/form"
import {getFetchError} from "src/lib/utils/form/types"
import {FormValidator} from "src/lib/utils/form/validation"
import {inject} from "src/lib/utils/inject"
import {BaseEntity} from "src/lib/entities/types"
import {ApiStore} from "src/lib/entities/store/ApiStore"
import {getListEndpoint} from "src/lib/entities/utils"

function isBaseOn(arg: BaseEntity): arg is BaseEntity & {baseOn: BaseEntity} {
    return arg && "baseOn" in arg
}

@autobind
export class AbstractModalForm<T extends BaseEntity> extends Form<T> {

    @observable
    public isPending: boolean

    @observable
    public active: boolean = false

    @observable.ref
    protected fixedFields: (keyof T)[] = []

    protected customSubmitHandler: (entity: T) => void

    constructor(
        valueFactory: () => T,
        @inject(ApiStore) protected apiStore: ApiStore,
        protected $validator?: FormValidator<T>
    ) {
        super(valueFactory)
    }

    @action
    public toggleActive(active: boolean) {
        this.active = active
    }

    @action
    public changePendingState(value: boolean) {
        this.isPending = value
    }

    @action
    public setFixedFields(fields: (keyof T)[]) {
        this.fixedFields = fields
    }

    public setCustomSubmitHandler(handler: (entity: T) => void) {
        this.customSubmitHandler = handler
    }

    @computed
    public get isErrorShown() {
        return this.$showErrors
    }

    public get validator() {
        return this.$validator
    }

    public isRequired(field: keyof T) {
        return this.validator.has(field)
    }

    public isFixed(field: keyof T) {
        return this.fixedFields.includes(field)
    }

    protected get entityForSave() {
        if (this.get("id")) {
            const entity = Object.assign(this.entityLink, this.changedValues)
            return this.getValueForSave(entity)
        } else {
            return this.getValueForSave(this.value)
        }
    }

    public getValueForSave(value: Partial<T>) {
        const valueCopy = { ...value as {} } as Partial<T>
        Object.keys(value).forEach((prop: keyof T) => {
            if (this.$childForms.has(prop)) {
                const childForm = this.$childForms.get(prop).form
                if (childForm && "getValueForSave" in childForm) {
                    valueCopy[prop] = (childForm as any).getValueForSave(value[prop])
                }
            }
        })

        return valueCopy
    }

    @action
    public async save(onSubmit?: (savedValue?: T) => void) {
        if (!this.isValid) {
            this.showErrors()
            return
        }
        this.changePendingState(true)
        const value = this.entityForSave as T
        try {
            if (this.customSubmitHandler) {
                await this.customSubmitHandler(value)
                if (this.initialValue.id) {
                    this.reset()
                }
            } else {
                let entity: BaseEntity

                if (isBaseOn(value)) {
                    const response = await this.apiStore.fetchEntities(getListEndpoint(value.baseOn, "linkWith"), {
                        method: "POST",
                        bodyEntity: Object.assign({}, value, {baseOn: void 0})
                    })
                    entity = response.value.data[0]
                } else {
                    entity = await this.apiStore.update(value)
                }

                if (this.initialValue.id) {
                    this.reset()
                }
                if (onSubmit) {
                    onSubmit(entity as T)
                }
            }
        } catch (e) {
            this.setErrors(getFetchError(e))
            this.showErrors()
        } finally {
            this.changePendingState(false)
        }
    }

    @action
    public set<K extends keyof T>(prop: K, value: T[K]) {
        if (this.fixedFields.includes(prop)) {
            return
        }
        super.set(prop, value)
    }

    @computed
    public get entityLink() {
        return {
            id: this.get("id"),
            contentType: this.get("contentType")
        }
    }
}
