import {Action} from "redux-actions"
import * as Api from "src/lib/entities/api"
import * as WebApi from "src/lib/entities/apiWeb"
import * as Megaplan from "src/megaplan"
import {ApiErrorResponse} from "src/lib/entities/api"

export const FUNNEL_PROMO_CLOSED = "bums/trade/promo/action/FUNNEL_PROMO_CLOSED"
export const FUNNEL_PROMO_CLOSE_FAILED = "bums/trade/promo/action/FUNNEL_PROMO_CLOSE_FAILED"

export type CloseFunnelPromo = Action<void>
export type FunnelPromoCloseFailed = Action<{error: ApiErrorResponse}>

export function funnelPromoClosed(): CloseFunnelPromo {
    return {
        type: FUNNEL_PROMO_CLOSED,
    }
}

export function funnelPromoCloseFailed(error: ApiErrorResponse): FunnelPromoCloseFailed {
    return {
        type: FUNNEL_PROMO_CLOSE_FAILED,
        payload: {
            error
        }
    }
}

export function closeFunnelPromo() {
    return (dispatch: Megaplan.Dispatch) => {
        const userSetting = {
            id: "hideFunnelPromo",
            contentType: Api.UserSetting.contentType,
            value: 1
        } as Api.UserSetting<number>
        return dispatch(WebApi.update(userSetting, Api.UserSetting.endpoint)).then(() => {
            return dispatch(funnelPromoClosed())
        }).catch((error: ApiErrorResponse) => {
            return dispatch(funnelPromoCloseFailed(error))
        })
    }
}
