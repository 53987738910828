import {Form} from "src/lib/utils/form/form";
import * as Api from "src/lib/entities/api"
import {FormValidator} from "src/lib/utils/form/validation";
import {computed} from "mobx";

export class EmailTemplateForm extends Form<Api.CommentTemplate> {

    @computed
    protected get validator() {
        const validationMap = new FormValidator<Api.CommentTemplate>()

        validationMap.set("name", FormValidator.nonEmptyFieldValidator(""))
        validationMap.set("content", FormValidator.nonEmptyFieldValidator(""))

        return validationMap
    }
}
