import {PlainRoute} from "react-router"

export const settingsIntegrationsRoutes: PlainRoute[] = [
    {
        path: "/settings/integrations(/:section)",
        async getComponent() {
            return (await import("./containers/CPageContainer")).CPageContainer
        },
    },
    {
        path: "/BumsSettings/Wazzup/*",
        onEnter(nextState, replace) {
            replace({pathname: "/settings/integrations/messengers/#whatsapp"});
        }
    },
    {
        path: "/BumsSettings/Telegram/*",
        onEnter(nextState, replace) {
            replace({pathname: "/settings/integrations/messengers/#telegram"});
        },
    },
    {
        path: "/BumsSettings/Instagram/*",
        onEnter(nextState, replace) {
            replace({pathname: "/settings/integrations/messengers/#instagram"});
        },
    },
    {
        path: "wazzup/connectRequest/",
        async getComponent() {
            return (await import("./containers/CPageContainer")).CPageContainer

        },
    },
    {
        path: "/settings/integrations/mail/",
        async getComponent() {
            return (await import("./mail/CMailList")).CMailList
        },
    }
]
