import {OnBoardingProgramStore} from "src/bums/common/onboarding/stores/OnBoardingProgramStore"
import {inject} from "src/lib/utils/inject"
import * as Api from "src/lib/entities/api"

export class OnBoardingFactory {

    private storeCache: {[programId: string]: OnBoardingProgramStore} = {}

    constructor(@inject(Api.Store) private apiStore: Api.Store) {}

    public getOrCreateStore(id: string) {
        if (!this.storeCache[id]) {
            this.storeCache[id] = new OnBoardingProgramStore(this.apiStore, id)
        }
        return this.storeCache[id]
    }

    public get hasActiveProgram() {
        let isLoadingOrActive = false
        for (let programId in this.storeCache) {
            if (this.storeCache[programId]) {
                isLoadingOrActive = isLoadingOrActive || (this.storeCache[programId].isLoading && !!this.storeCache[programId].currentStep)
            }
        }
        return isLoadingOrActive
    }

}
