import {Component} from "src/lib/components"
import {observer} from "mobx-react"
import * as PropTypes from "prop-types"
import {AdaptiveObservable, AdaptiveType} from "./AdaptiveObservable"
import {CAdaptProvider} from "src/lib/components/CAdapt/CAdaptProvider"

/**
 * Позволяет отрендерить контент в зависимости от того, сходится ли текущий режим адаптивности с выбранным
 */
@observer
export class CAdaptSelector extends Component<CAdaptSelector.Props, {}> {

    public adaptive = new AdaptiveObservable()

    public static contextTypes = {
        [CAdaptProvider.contextTypeKey]: PropTypes.object,
    }

    private get currentContextAdaptiveType() {
        return this.context[CAdaptProvider.contextTypeKey] ? this.context[CAdaptProvider.contextTypeKey].get() : null
    }

    private get currentAdaptiveType() {
        return this.currentContextAdaptiveType || this.adaptive.type
    }

    public render() {
        return this.props.renderChildren(this.currentAdaptiveType === this.props.type)
    }
}

export namespace CAdaptSelector {
    export interface Props {
        type: AdaptiveType,
        renderChildren: (v: boolean) => JSX.Element
    }
}
