import {computed} from "mobx"
import {TransportConfigOptions} from "src/bums/common/comment/transports/types"
import * as Collections from "src/lib/collections"
import * as Api from "src/lib/entities/api"
import {Form} from "src/lib/utils/form/form"

export abstract class AbstractTransport<T, F extends Api.BaseEntity> {

    protected constructor(
        private $context: () => TransportConfigOptions
    ) {
    }

    @computed
    public get contextEntity() {
        return this.$context().entity
    }

    @computed
    public get transportForm() {
        return this.$context().form.transportForm as Form<F>
    }

    @computed
    public get commentForm() {
        return this.$context().form
    }

    @computed
    protected get context() {
        return this.$context()
    }

    public abstract get availableTransports(): Collections.List<T>

    public abstract async initForm(): Promise<void>

    public abstract dispose(): void

    public resetForm() {
        this.transportForm.reset()
    }

    public get isFormFilled() {
        return this.transportForm.isDirty
    }

}


