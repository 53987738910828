import {Component} from "src/lib/components"
import * as React from "react"
import bowser from "bowser"
import classNames from "classnames/bind"
import {Size} from "src/lib/types"

let style = classNames.bind(require("./CRoundCrop.styl"));

interface CRoundCropProps {
    className?: string | string[]
    size?: Size
}

/**
 *  Элемент, создающий скругленную маску вокруг любого контейнера
 */
export default class CRoundCrop extends Component<CRoundCropProps, {}> {
    public static Size = Size;

    public static defaultProps = {
        size: Size.BIG
    }

    public render() {
        const {
                className,
                size
            } = this.props;

        // отключаем для всех браузеров на iOS (потому что они почти все на битом WebKit)
        // отключаем на десктопном Safari 10.0.x
        const disableClipPath = bowser.ios || (bowser.safari && /^10\.0.*$/.test(String(bowser.version)))

        const addClassName = style(
            "CRoundCrop",
            {
                [Size[size]]: Boolean(Size[size]),
                "clipped": !disableClipPath
            },
            className
        );

        return (
            <div className={addClassName}>
                {this.props.children}
            </div>
        )

    }
}
