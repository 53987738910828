import * as ReduxActions from "redux-actions"

import {CARD_SUBJECT} from "../constants"
import {CardMeta} from "../types"

export const RESET_CARD = "bums/task/action/RESET_CARD"

type ResetCardAction = ReduxActions.Action<{}> & CardMeta

export function resetCard(cardId: string): ResetCardAction {
    return {
        type: RESET_CARD,
        meta: {
            cardId,
            subject: CARD_SUBJECT
        }
    }
}

export function isRefreshCard(action: any): action is ResetCardAction {
    return "object" === typeof action && RESET_CARD === action.type
}
