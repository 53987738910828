import * as React from "react"
import {observer} from "mobx-react"
import classNames from "classnames/bind"
import {fieldRowContainerDisplayType} from "src/lib/types"
import {CFieldRow, Component} from "src/lib/components"

const style: any = classNames.bind(require("./CFieldRowContainer.styl"))

interface CFieldRowContainerProps {
    className?: string
    title?: string | JSX.Element
    type?: fieldRowContainerDisplayType
    isCollapsed?: boolean
    marginBottom?: boolean
    subGroup?: boolean// строки подгруппы
}

interface FieldRowBlock {
    parentType?: fieldRowContainerDisplayType
}


@observer
export class COneStringTitleBlock extends Component<CFieldRowContainerProps, {}> {
    render() {
        return <div className={style("header")}>
            <div className={style("headerWrapper")}>
                {this.props.children}
            </div>
        </div>
    }
}

/**
 * Контейнер для набора CFieldRow
 */
@observer
export class CFieldRowContainer extends Component<CFieldRowContainerProps, {}> {

    public static OneStringTitleBlock = COneStringTitleBlock

    public static defaultProps = {
        type: "table",
        isCollapsed: false,
        marginBottom: true
    }

    private static isFieldRowBlock(arg: any): arg is FieldRowBlock {
        return Boolean(arg) && typeof arg === "object" && arg.type && arg.type === CFieldRow
    }

    public renderTitle() {

        if (!this.props.title) {
            return
        }

        return <div
            className={style("title", `${this.props.type}Title`)}
        >
            {this.props.title}
        </div>
    }

    public renderChildren() {
        return React.Children.map(this.props.children, (child: React.ReactChild) => {
            if (React.isValidElement(child) && CFieldRowContainer.isFieldRowBlock(child)) {

                return React.cloneElement(
                    child as React.ReactElement<{ subGroup?: boolean; }>,
                    {
                        subGroup: this.props.subGroup
                    }
                );
            } else {
                return child
            }
        })
    }

    public render() {
        const {type, title, marginBottom} = this.props

        const allClassName = style(this.props.type, {"marginBottom": marginBottom})

        return type === "subTable" && !title
            ?   this.props.isCollapsed
                ? null
                : <div className={allClassName}>{this.renderChildren()}</div>
            : <div className={style(this.props.className)}>
                {this.renderTitle()}
                {this.props.isCollapsed
                    ? null
                    : <div className={allClassName}>{this.renderChildren()}</div>
                    }
            </div>
    }
}
