import {computed} from "mobx"
import {AbstractContractorTypesStore} from "./AbstractContractorTypesStore"
import {ContractorHuman, ContractorType} from "src/lib/entities/api"
import {getListEndpoint} from "src/lib/entities/utils"

export class ContractorHumanTypesStore extends AbstractContractorTypesStore {

    protected get $endpoint() {
        return getListEndpoint(ContractorHuman.contentType, "types")
    }

    @computed
    public get typeContact() {
        return this.loadedItems.find((item) => item.type === ContractorType.Type.contact)
    }
}
