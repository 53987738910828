import {autobind} from "core-decorators"
import {action, computed, observable} from "mobx"

type RatingStatus = "vote" | "feedback" | "done"

@autobind
export class RatingStore {

    constructor(
        private tracker: {trackEvent: (event: string, key: string, params: string) => void},
        private trackOptions: {type: string, tag: string, userIsDirector: boolean, userPosition: string}
    ) {}

    @observable
    private $status: RatingStatus = "vote"

    @observable
    private $rating: number

    @observable
    private $feedbackMessage: string

    @observable
    private $isTrackSend: boolean

    @computed
    public get status() {
        return this.$status
    }

    @computed
    public get rating() {
        return this.$rating
    }

    @computed
    public get isVoted() {
        return !!this.$rating
    }

    @computed
    public get feedbackMessage() {
        return this.$feedbackMessage
    }

    @computed
    public get isFeedbackFilled() {
        return !!this.$feedbackMessage && !!this.$feedbackMessage.trim()
    }

    @computed
    public get isPositive() {
        return this.isVoted && this.$rating > 3
    }

    @action
    public onFeedbackMessageChange(message: string) {
        this.$feedbackMessage = message
    }

    @action
    public onVote(rating: number, toStatus: RatingStatus) {
        this.$rating = rating
        this.$status = toStatus
    }

    @action
    public returnToVote() {
        this.$status = "vote"
    }

    @action
    public sendFeedback() {
        this.sendTrackData()
        this.$status = "done"
    }

    @action
    public sendTrackData() {
        this.tracker.trackEvent(
            "rating",
            this.trackOptions.type,
            JSON.stringify({
                rating: this.$rating,
                feedback: this.$feedbackMessage,
                tag: this.trackOptions.tag,
                is_director: this.trackOptions.userIsDirector,
                position: this.trackOptions.userPosition
            })
        )
        this.$isTrackSend = true
    }

    @computed
    public get isTrackSend() {
        return this.$isTrackSend
    }

    @action
    public settleData(data: {rating: RatingStore["$rating"], feedback: RatingStore["$feedbackMessage"], status: RatingStore["$status"]}) {
        this.$rating = data.rating || 0
        this.$feedbackMessage = data.feedback || ""
        this.$status = data.status || "vote"
    }
}
