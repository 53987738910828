import {action, computed, observable, runInAction} from "mobx"
import * as Api from "src/lib/entities/api"
import {inject} from "src/lib/utils/inject"
import {getListEndpoint, ListStore} from "src/lib/entities/api"
import {autobind} from "core-decorators"
import {INVITED_EMPLOYEES_FIRST_LIMIT, InviteLinkFormValue} from "src/bums/employee/utils"

@autobind
export class InviteLinkStore {

    @observable
    private $inviteLink: Api.InviteLink

    @observable
    public isLoading: boolean = false

    @observable.ref
    public invitedEmployees: ListStore<Api.Employee>

    constructor(
        @inject(Api.Store) private apiStore: Api.Store
    ) {
        this.invitedEmployees = this.apiStore.resolveList(() => {
            if (!this.inviteLink) {
                return
            }
            return {
                endpoint: `${Api.InviteLink.endpoint}/${this.inviteLink.id}/employees`,
                options: {
                    limit: INVITED_EMPLOYEES_FIRST_LIMIT,
                    fields: [{
                        employees: ["canLogin"]
                    }]
                }
            }
        })
    }

    @computed
    public get inviteLink() {
        return this.$inviteLink
    }

    @computed
    public get isEnabled() {
        return this.$inviteLink && this.$inviteLink.enabled
    }

    @action
    public async tryGetInviteLink() {
        this.isLoading = true

        let currentLink
        try {
            const response = await this.apiStore.fetchEntities<Api.InviteLink>(
                Api.InviteLink.endpoint,
                {
                    queryParams: {
                        fields: ["employeesCount"]
                    }
                }
            )
            if (!response.value.data.length) {
                //сгенерировать новую ссылку
                currentLink = await this.apiStore.update({
                    ...Api.InviteLink.newObject
                })
            } else {
                currentLink = response.value.data[0]
            }
            this.setInviteLink(currentLink)
        } catch (e) {
            throw e
        } finally {
            runInAction(() => this.isLoading = false)
        }
    }

    @action
    public async refreshUuid() {
        if (!this.inviteLink) {
            return
        }

        await this.apiStore.fetchEntities(
            getListEndpoint(this.inviteLink, "refreshUuid"),
            {
                method: "POST"
            }
        )
    }

    @action
    private setInviteLink(value: Api.InviteLink) {
        this.$inviteLink = value
    }

    @action
    public async switchEnabledLink(enabled: boolean) {
        if (!this.inviteLink) {
            return
        }
        await this.apiStore.update({...this.inviteLink, enabled: enabled})
    }

    public async employeeAction(employee: Api.Employee, _action: "block" | "unblock") {
        const response = await this.apiStore.fetch<Api.Employee>(
            `${Api.Employee.endpoint}/${employee.id}/${_action}`,
            {
                method: "POST"
            })
        await this.apiStore.updateEntitiesFromJson(response.value.data)
    }

    public async tryCreateEmployeeByInviteLink(formValue: InviteLinkFormValue, uuid: string) {
        return this.apiStore.fetch(Api.InviteLinkRegistration.endpoint,
            {
                method: "POST",
                bodyEntity: {
                    uuid: uuid,
                    ...formValue
                }
            })
    }

}
