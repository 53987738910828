import {Component} from "src/lib/components"
import * as React from "react"
import * as Api from "src/lib/entities/api"
import {bindArg} from "src/lib/utils/func"
import {CIntegrationWidgetPlaceholderContainer} from "src/bums/common/integrationWidget/CIntegrationWidgetPlaceholderContainer"
import * as Url from "src/lib/utils/url"
import {parsePhoneNumberFromString} from "src/lib/utils/phonenumber"

function isModifiedEvent(event: React.MouseEvent<HTMLElement>) {
    return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)
}

interface CContactInfoProps {
    to: Api.ContactInfo | Api.Address
    children: React.ReactElement<{
        onClick?: (...args: any[]) => void
        content?: React.ReactChild,
        title?: string
        className?: string
    }>
    onIntegrationWidgetMouseDown?: (...args: any[]) => void
}

/**
 * элемент для обработки сущности контактной информации
 */
export default class CContactInfo extends Component<CContactInfoProps, {}> {

    private handleClick = (handler: React.MouseEventHandler<HTMLElement>, event: React.MouseEvent<HTMLElement>) => {
        if (handler) {
            handler(event)
        }
        event.stopPropagation()

        // Если нажали не левой кнокой
        // или были нажаты дополнительный функциональные кнопки на клавиатуре
        // или отменен
        // ничего не делаем.
        if (event.button !== 0 || isModifiedEvent(event) || event.defaultPrevented === true) {
            return
        }

        //почта превентится в своем плагине
        if (this.props.to.type === Api.ContactInfo.Type.phone) {
            event.preventDefault()
        }
    }

    public render() {
        const {to} = this.props
        let {onClick: handler, content, title, className} = React.Children.only(this.props.children).props
        let entityName: string
        let url: string
        let phoneNumber: string
        let customOptions = {}

        if (to.type && to.value) {

            entityName = to.value
            switch (to.type) {
                case Api.ContactInfo.Type.email:
                    url = `mailto:${to.value}`
                    customOptions = {
                        target: "_self",
                    }
                    break;

                case Api.ContactInfo.Type.link:
                    url = to.value
                    url = url.indexOf("http") === 0 ? url : "http://" + url

                    customOptions = {
                        target: Url.hasInternalLink(url) ? "_self" : "_blank"
                    }

                    break ;

                case Api.ContactInfo.Type.skype:
                    //переход в чат с to.value
                    url = `skype:${to.value}?chat`
                    break ;

                case Api.ContactInfo.Type.phone:
                    phoneNumber = to.value
                    const phone = parsePhoneNumberFromString(phoneNumber)
                    url = null
                    customOptions = {
                        "data-phone": phone?.isValid() ? phone.number as string : to.value
                    }
                    className = className + " js-call-button"
                    entityName = phoneNumber
                    break;

                default:
                    break;
            }

            let childProps: {
                href?: string,
                onClick?: React.MouseEventHandler<HTMLElement>,
                children: React.ReactChild
                title: string
                className?: string
                dataPhone?: string
                target?: "_blank"
            } = {
                children: content || entityName,
                title : title || entityName,
                className,
                ...customOptions,
            }

            childProps.onClick = bindArg(this.handleClick, handler)

            if (url) {
                childProps.href = url
            }

             return <React.Fragment>
                 {React.cloneElement(
                     this.props.children as React.ReactElement<any>,
                     childProps
                 )}
                 {phoneNumber && <CIntegrationWidgetPlaceholderContainer
                    isInline={true}
                    isCard={false}
                    placeholder={Api.IntegrationWidget.Placeholder.common_phone_number}
                    onMouseDown={this.props.onIntegrationWidgetMouseDown}
                />}
             </React.Fragment>
        }

        return null
    }
}
