/* tslint:disable */
import * as H from "../metadataHelper"


export interface TodoRejectInvitationActionRequest extends H.BaseValue {
    contentType: "TodoRejectInvitationActionRequest"; // Object type
}

export module TodoRejectInvitationActionRequest {
    export const contentType: "TodoRejectInvitationActionRequest" = "TodoRejectInvitationActionRequest";
    export const endpoint = "/api/v3/todoRejectInvitationActionRequest";

    export const newObject: TodoRejectInvitationActionRequest = {contentType}
    Object.freeze(newObject)

    export module fields {}
}

export function isTodoRejectInvitationActionRequest(arg: any): arg is TodoRejectInvitationActionRequest {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "TodoRejectInvitationActionRequest"
}
