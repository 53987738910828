/* tslint:disable */
import * as H from "../metadataHelper"
import {File} from "./file"
import {StringField} from "./stringField"
import {IntegerField} from "./integerField"

export interface FileGroup extends H.BaseEntity {
    name?: string; // Название
    children?: H.List<File>; // Массив файлов
    childrenCount?: number; // Количество файлов в группе
    id?: string; // Идентификатор
    contentType: "FileGroup"; // Object type
}

export module FileGroup {
    export const contentType: "FileGroup" = "FileGroup";
    export const endpoint = "/api/v3/fileGroup";

    export const newObject: FileGroup = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "FileGroup.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const children = {contentType: "RefLinkField" as "RefLinkField", name: "children", isMultiple: true, isSortable: false, id: "FileGroup.children", refContentType: H.List(["File"])};
        export const childrenCount = {contentType: "IntegerField" as "IntegerField", name: "childrenCount", isMultiple: false, isSortable: false, id: "FileGroup.childrenCount", defaultValue: null as IntegerField["defaultValue"]};
    }
}

export function isFileGroup(arg: any): arg is FileGroup {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "FileGroup"
}
