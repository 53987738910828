/* tslint:disable */
import * as H from "../metadataHelper"
import {Topic} from "./topic"
import {StringField} from "./stringField"
import {IntegerField} from "./integerField"

export interface TopicFolder extends H.BaseEntity {
    name?: string; // Название папки
    topics?: H.List<Topic>; // Первые 10 обсуждений из папки
    topicsCount?: number; // Количество обсуждений в папке
    id?: string; // Идентификатор
    contentType: "TopicFolder"; // Object type
}

export module TopicFolder {
    export const contentType: "TopicFolder" = "TopicFolder";
    export const endpoint = "/api/v3/topicFolder";

    export const newObject: TopicFolder = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "TopicFolder.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const topics = {contentType: "RefLinkField" as "RefLinkField", name: "topics", isMultiple: true, isSortable: false, id: "TopicFolder.topics", refContentType: H.List(["Topic"])};
        export const topicsCount = {contentType: "IntegerField" as "IntegerField", name: "topicsCount", isMultiple: false, isSortable: false, id: "TopicFolder.topicsCount", defaultValue: null as IntegerField["defaultValue"]};
    }
}

export function isTopicFolder(arg: any): arg is TopicFolder {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "TopicFolder"
}
