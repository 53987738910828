import * as Collections from "../collections"
import {LoadState} from "../utils/loadState"
import {BaseEntity, BaseValue} from "./base"
import {isTaskFilter, TaskFilter} from "./bums/taskFilter"
import {CrmFilter, isCrmFilter} from "./bums/crmFilter"
import {isTradeFilter} from "./bums/tradeFilter"
import {isEmployeeFilter} from "./bums/employeeFilter"

export * from "./base"

export const UNKNOWN_ID_PREFIX = "###unknown_id_"
export type Id = string
export type LinksList = Collections.List<BaseEntity>
export type ListDirection = "Next" | "Prev"

/**
 * Хранилище ссылок на несохранённые сущности.
 * Зранятся имена списков, в которых присутствуют ссылки на несохранённые модели.
 * Immutable.Map<contentType: string, Immutable.Map<entityId: Id, Immutable.List<listName: string>>>
 */
export type UnsavedEntitiesReferencesMap = Collections.Map<string, Collections.Map<Id, Collections.List<string>>>



export function isNamedEntity<T extends BaseEntity>(entity: T): entity is T & {name: string} {
    return "name" in entity
}

export type FilterEntity = TaskFilter | CrmFilter

export function isFilterEntity(entity: any): entity is FilterEntity {
    return !!entity && (isTaskFilter(entity) || isCrmFilter(entity) || isTradeFilter(entity) || isEmployeeFilter(entity))
}

export function isTemplatedEntity<T extends BaseEntity>(entity: T): entity is T & {isTemplate: boolean} {
    return "isTemplate" in entity
}

export interface NormalizedList {
    loadStateNext: LoadState
    loadStatePrev: LoadState
    hasMoreNext?: boolean
    hasMorePrev?: boolean
    loadedWithOptions?: any   // с какими опциями этот список был загружен.
                              // если придёт запрос на загрузку с другими опциями, то список перезагружается.
    totalItemsCount?: number
    items: LinksList
}

export const emptyList: NormalizedList = {
    loadStateNext: LoadState.None(),
    loadStatePrev: LoadState.None(),
    items: Collections.List<BaseEntity>(),
    loadedWithOptions: {},
}

export interface Money extends BaseValue {
    value: number
    currency: string
    rate: number
}

export interface HasMore {
    Prev?: boolean
    Next?: boolean
}

export interface RequestOptions {
    [optionName: string]: RequestOptions | {} | void
}



export interface ContentTypeMetadata {
    newObject: {}
}
