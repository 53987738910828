import {action, computed, observable} from "mobx"
import {autobind} from "core-decorators"
import * as Api from "src/lib/entities/api"
import {inject} from "src/lib/utils/inject"
import {UserSettingStore} from "src/bums/common/stores/UserSettingStore"
import {DocumentVisibilityStore} from "src/bums/common/stores/DocumentVisibilityStore"
import {BubbleType, countersIds, endPoints, informerShowReadNotifications, informerSoundVolume, listNames} from "./types"
import {FaviconStore} from "src/bums/common/stores/FaviconStore"
import {Ecomet} from "src/lib/services/Ecomet"
import {Tracker} from "src/bums/common/stores/Tracker"

const OPENED_BUBBLE_SESSION_STORAGE_KEY = "openedBubble"

@autobind
export class InformerStore {

    public countersList: Api.ListStore<Api.Counter>

    @observable
    public openedBubble: BubbleType = null

    @observable
    public serverError: boolean = false

    @observable
    public allNotificationBeingDeleted = false

    public soundEnableSettingStore: UserSettingStore<boolean>

    public ignorReadNotificationsSettingStore: UserSettingStore<boolean>

    private ignoreNotificationSubjects = new Set<Api.BaseEntity>()

    constructor(
        @inject(Api.Store) private apiStore: Api.Store,
        @inject(DocumentVisibilityStore) public documentVisibilityStore: DocumentVisibilityStore,
        @inject(FaviconStore) public faviconStore: FaviconStore,
        @inject(Ecomet) public ecomet: Ecomet,
        @inject(Api.UserStore) public userStore: Api.UserStore,
        @inject(Tracker) public tracker: Tracker,
    ) {
        this.soundEnableSettingStore = new UserSettingStore<boolean>(apiStore, () => informerSoundVolume)
        this.ignorReadNotificationsSettingStore = new UserSettingStore<boolean>(apiStore, () => informerShowReadNotifications, () => false)

        this.countersList = this.apiStore.resolveList(() => ({
            endpoint: "/api/v3/counter"
        }))
    }

    @computed
    public get chatCount() {
        return this.countersList.originalItems
            .filter(item => item.id === countersIds[BubbleType.Chat])
            .reduce((sum, item) => {
                sum += item.count
                return sum
            }, 0)
    }

    @computed
    public get isCompleted() {
        return this.soundEnableSettingStore.get().state === "fulfilled"
    }

    @computed
    public get isSoundEnable() {
        const state = this.soundEnableSettingStore.get()
        return state.state !== "fulfilled" ? false : (state.value === null || !!state.value)
    }

    public setSoundEnabled(value: boolean) {
        void this.soundEnableSettingStore.set(value)
    }

    @computed
    public get isReadNotifications() {
        const state = this.ignorReadNotificationsSettingStore.get()
        return state.state !== "fulfilled" ? false : (state.value === null || !!state.value)
    }

    public setReadNotifications(value: boolean) {
        void this.ignorReadNotificationsSettingStore.set(value)
    }

    @action
    public setOpenedBubble(bubble: BubbleType) {
        this.openedBubble = bubble

        if (window && window.sessionStorage) {
            window.sessionStorage.setItem(OPENED_BUBBLE_SESSION_STORAGE_KEY, String(bubble))
        }
    }

    @action
    public tryRestoreOpenedBubbleBySessionStore() {
        if (
            window
            && window.sessionStorage
            && !window.bt_mode
        ) {
            const savedValue = window.sessionStorage.getItem(OPENED_BUBBLE_SESSION_STORAGE_KEY)
            if (null === savedValue) {
                return
            }

            this.setOpenedBubble(Number(savedValue))
        }
    }

    public isOpenedBubble(bubble: BubbleType) {
        return bubble === this.openedBubble
    }

    @action
    public setServerError(value = true) {
        this.serverError = value
    }

    @action
    public setAllNotificationBeingDeleted(value = true) {
        this.allNotificationBeingDeleted = value
    }

    //---------------------------------------------------------------------
    // Some other helpers

    public reloadCounters() {
        void this.countersList.reloadList()
    }

    public getCounterId(bubble: BubbleType) {
        return countersIds[bubble]
    }

    public getListName(bubble: BubbleType): string  {
        return listNames[bubble]
    }

    public getEndPoint(bubble: BubbleType): string {
        return endPoints[bubble]
    }

    public async addNewNotifications(bubbles: BubbleType[], notifications: Api.Notification[]) {
        notifications = await this.apiStore.updateEntitiesFromJson(notifications)

        for (const bubble of bubbles) {
            const listName = this.getListName(bubble)
            if (this.apiStore.getLists().has(listName)) {
                await this.apiStore.listPrependEntities(listName, notifications)

                const ignoredNotifications = this.documentVisibilityStore.visible
                    ? notifications.filter(notification => {
                        const subject = Api.isComment(notification.subject) ? notification.subject.subject : notification.subject
                        return this.ignoreNotificationSubjects.has(this.apiStore.getEntity(subject))
                    })
                    : []

                this.apiStore.listRemoveEntities(listName, ignoredNotifications)
            }
        }
    }

    public canIgnoreEntityNotification(subject: Api.BaseEntity) {
        return this.documentVisibilityStore.visible && this.ignoreNotificationSubjects.has(this.apiStore.getEntity(subject))
    }

    public ignoreAddNewNotificationsWithSubject(subject: Api.BaseEntity) {
        this.ignoreNotificationSubjects.add(subject)
    }

    public unignoreAddNewNotificationsWithSubject(subject: Api.BaseEntity) {
        this.ignoreNotificationSubjects.delete(subject)
    }

    @action
    public clearNotificationBySubject(subject: Api.BaseEntity) {
        if (this.apiStore.getEntities().has(Api.Notification.contentType)) {
            const notifications = Array.from(this.apiStore.getEntities().get(Api.Notification.contentType).values())
                .filter((notification: Api.Notification) => notification.subject && Api.isEntityEquals(notification.subject, subject))
            this.apiStore.deleteEntityFromAllLists(notifications)
        }
    }

    @action
    public clearApprovalsBySubject(subject: Api.BaseEntity) {
        if (this.apiStore.getEntities().has(Api.Approval.contentType)) {
            const approvals = Array.from(this.apiStore.getEntities().get(Api.Approval.contentType).values())
                .filter((approval: Api.Approval) => approval.entity && Api.isEntityEquals(approval.entity, subject))
            this.apiStore.deleteEntityFromAllLists(approvals)
        }
    }

    @action
    public async clearAllNotifications(bubble: BubbleType) {
        if (this.apiStore.getEntities().has(Api.Notification.contentType)) {
            const notifications = Array.from(this.apiStore.getEntities().get(Api.Notification.contentType).values())
            this.apiStore.deleteEntityFromAllLists(notifications)
            this.apiStore.getEntities().delete(Api.Notification.contentType)
        }

        this.setAllNotificationBeingDeleted(true)

        try {
            await this.apiStore.fetch("/api/v3/notification/all", {method: "DELETE"})
            await this.apiStore.fetchList(this.getListName(bubble), this.getEndPoint(bubble))
            this.reloadCounters()
            this.setOpenedBubble(null)
        } catch (e) {
            if (e instanceof Api.FetchError) {
                this.setServerError(true)
            }
            throw e
        } finally {
            this.setAllNotificationBeingDeleted(false)
        }
    }
}
