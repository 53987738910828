import * as React from "react"
import {computed, IReactionDisposer, when} from "mobx"
import {observer} from "mobx-react"
import {OnBoardingFactory} from "src/bums/common/onboarding/stores/OnBoardingFactory"
import {OnBoardingProgramStore} from "src/bums/common/onboarding/stores/OnBoardingProgramStore"
import {Component} from "src/lib/components"
import {inject} from "src/lib/utils/inject"
import {FeatureStore} from "src/bums/common/stores/FeatureStore"
import {BlockedPopupContentStore} from "src/bums/common/stores/BlockedPopupContentStore"
import {ProductQuestStore} from "src/bums/common/onboarding/CProductQuests/stores/ProductQuestStore";
import {InformerStore} from "src/bums/common/informerPanel/informerStore";
import {BubbleType} from "src/bums/common/informerPanel/types";
import {ProductQuest} from "src/lib/entities/bums";
import {PROGRAM_ID} from "src/bums/common/onboarding/tutorial/tracker";

declare var window: any

export namespace COnBoardingGlobalContainer {
   export interface Props {
       programId: string
       mapStepToComponent: COnBoardingGlobalContainer.StepMapper
   }
   export type StepMapper = (store: OnBoardingProgramStore) => JSX.Element

}

function create(programId: string, mapStepToComponent: COnBoardingGlobalContainer.StepMapper) {
    return () => <COnBoardingGlobalContainer {...{programId, mapStepToComponent}}/>
}

@observer
export class COnBoardingGlobalContainer extends Component<COnBoardingGlobalContainer.Props, {}> {

    public static create = create

    @inject(FeatureStore)
    private featureStore: FeatureStore

    @inject(OnBoardingFactory)
    private onBoardingFactory: OnBoardingFactory

    @inject(BlockedPopupContentStore)
    private blockedPopupContentStore: BlockedPopupContentStore

    @inject(ProductQuestStore)
    private productQuestStore: ProductQuestStore

    @inject(InformerStore)
    private informerStore: InformerStore

    private openProductQuestReactionDisposer: IReactionDisposer

    public componentDidMount() {
        this.openProductQuestReactionDisposer = this.featureStore.isAvailable("bums.common.product_quest_feature")
            ? when(
                () => this.tutorialOnboardingStore.currentStep === "openProductQuests",
                () => {
                    this.informerStore.setOpenedBubble(BubbleType.ProductQuest)
                    this.productQuestStore.setActiveProductQuest(this.productQuestStore.getQuestById(ProductQuest.Id.initial))
                    this.tutorialOnboardingStore.nextStep()
                }
            )
            : null
    }

    public componentWillUnmount() {
        if (this.openProductQuestReactionDisposer) {
            this.openProductQuestReactionDisposer()
        }
    }

    @computed
    private get tutorialOnboardingStore() {
        return this.onBoardingFactory.getOrCreateStore(PROGRAM_ID)
    }

    @computed
    private get store() {
        return this.onBoardingFactory.getOrCreateStore(this.props.programId)
    }

    public render() {
        if (!this.featureStore.isAvailable("bums.common.on_boarding_tutorial") || window.bt_mode) {
            this.store.cancel()
            return null
        }
        if (this.store.currentStep) {
            this.blockedPopupContentStore.blockContent()
        }
        return this.props.mapStepToComponent(this.store)
    }
}
