import {OnboardingPollResult} from "src/lib/entities/bums"
import {OnboardingPollStore, StepId} from "src/bums/common/onboarding/firstConfigure/store/OnboardingPollStore"
import {TrackingParams} from "src/lib/tracking";

export const ROBOT_SETTING_ID = "mobile_robot_setting"

export interface RobotSetting {
    module: string
}

export const MP_CLIENT_STATISTIC_SEND_INTERVIEW_URL = "https://account.megaplan.ru/PromoSiteApiV01/Account/sendInterview.api?"

export const TASKS_PROJECTS_ROBOT_ANSWER_ID = "tasks_projects"

export interface FirstConfigureFormValue {
    forWhom: OnboardingPollResult.ForWhom,
    chooseIndustry: keyof Industries | string,
    chooseRole: OnboardingPollResult.ChooseRole,
    howManyEmployees: OnboardingPollResult.HowManyEmployees,
    nameOfYourCompany: string
    experienceWithCRM: OnboardingPollResult.ExperienceWithCRM
}

const SETTING_SCOPE = "onboarding";
export const COMPLETED_SYSTEM_SETTING_ID = `${SETTING_SCOPE}/onboardingFirstConfigureCompleted`
export const POLL_RESULT_SYSTEM_SETTING_ID = `${SETTING_SCOPE}/pollResult`

export const TRACKER_KEY = "onboarding_first_configure"

export enum Industries {
    production = "production",
    it = "it",
    construction = "construction",
    wholesale = "wholesale",
    retail = "retail",
    marketing = "marketing",
    businessServices = "businessServices",
    populationServices = "populationServices",
    stateCompanies = "stateCompanies"
}

export const ONBOARDING_POLL_EVENT_TYPE = "Onboarding poll"
export const START_POLL_EVENT = "Start Poll"
export const NEXT_QUESTION_EVENT = "Next Question"
export const PREVIOUS_QUESTION_EVENT = "Previous Question"
export const FINISH_POLL_EVENT = "Finish Poll"
export const SKIP_POLL_EVENT = "Skip Poll"

export const PollMessagesKeyMap = new Map<StepId, {questionMessageKey: string, answerKey: keyof FirstConfigureFormValue}>([
    [StepId.ForWhom, {questionMessageKey: "howDoYouPlanToUseMegaplan", answerKey: "forWhom"}],
    [StepId.Industry, {questionMessageKey: "whatIndustry", answerKey: "chooseIndustry"}],
    [StepId.YourRole, {questionMessageKey: "chooseYourRole", answerKey: "chooseRole"}],
    [StepId.HowManyEmployees, {questionMessageKey: "howManyEmployees", answerKey: "howManyEmployees"}],
    [StepId.NameOfYourCompany, {questionMessageKey: "whatIsTheNameOfYourCompany", answerKey: "nameOfYourCompany"}]
])

export function prepareDataForPollTracking(args: IArguments): TrackingParams {
    const store: OnboardingPollStore = args[0]
    const stepBack = args[1] ? args[1] : false
    if (stepBack) {
        if (store.isFirstStep) {
            return {
                event: SKIP_POLL_EVENT,
                params: {},
                channels: ["product", "amplitude", "carrotquest", "track"],
            }
        } else {
            return {
                event: PREVIOUS_QUESTION_EVENT,
                params: {question: store.getCurrentQuestionText()},
                channels: ["product", "amplitude", "track"]
            }
        }
    } else if (store.isFirstStep) {
        return {
            event: START_POLL_EVENT,
            params: {},
            channels: ["product", "amplitude", "track"],
        }
    } else {
        const question = store.getCurrentQuestionText()
        const answer = store.currentAnswerText
        return {
            event: NEXT_QUESTION_EVENT,
            params: {question, answer},
            channels: ["product", "amplitude", "track"],
        }
    }
}

export function prepareDataForCompletePollTracking(args: IArguments): TrackingParams | false {
    const store: OnboardingPollStore = args[0]
    const result: string[] = []
    PollMessagesKeyMap.forEach((value, key, map) => {
        const question = store.getCurrentQuestionText(key)
        const answer = store.form.get(value.answerKey)
        const answerTranslation = answer ? store.getAnswerTranslation(value.answerKey, answer) : ""
        if (question && answerTranslation) {
            result.push(`${question}: ${answerTranslation}`)
        }
    })
    return result
        ? {
            event: FINISH_POLL_EVENT,
            params: {answers: result},
            channels: ["product", "amplitude", "carrotquest", "track"],
        }
        : false
}
