import {
    Group,
} from "src/lib/entities/bums"
import {ApiStore} from "src/lib/entities/store/ApiStore"
import {AbstractModalForm} from "src/bums/common/modalForm/AbstractModalForm"

export class GroupForm extends AbstractModalForm<Group> {
    constructor(valueProducer: () => Group, apiStore: ApiStore) {
        super(valueProducer, apiStore)
    }
}
