import {autobind} from "core-decorators";
import {UserSettingStore} from "src/bums/common/stores/UserSettingStore";
import {inject} from "src/lib/utils/inject";
import {ApiStore} from "src/lib/entities/store/ApiStore";
import {action, computed} from "mobx";
import {PromoComponent} from "src/bums/common/promo/types";
import {CRatingContainer} from "src/bums/common/promo/CRating/CRatingContainer";
import {fromPromise} from "src/lib/utils/fromPromise";
import * as Api from "src/lib/entities/api"

declare let window: any

const CARROT_QUEST_PROMO_FACTORY = "carrot_quest_promo_factory"

type ComponentId = "regularRating"

type PromoComponentDataFactory = {
    id: string,
    component: ComponentId,
    feature?: Api.Feature.Name,
    params?: {},
}

interface CqPromoFactory {
    data: PromoComponentDataFactory[]
}

@autobind
export class CarrotQuestPromoDataFactory {
    private componentMap = new Map<ComponentId, () => PromoComponent>([
        ["regularRating", () => CRatingContainer.injectProps({ratingType: "regularRating"})],
    ])

    private store: UserSettingStore<CqPromoFactory>

    constructor(
        @inject(ApiStore) private apiStore: ApiStore
    ) {
        this.store = new UserSettingStore<CqPromoFactory>(
            this.apiStore,
            () => CARROT_QUEST_PROMO_FACTORY,
            () => null
        )

        if (window) {
            window.carrotQuestPromoDataFactory = this
        }
    }

    @computed
    public get isComplited() {
        return this.store.get().state === "fulfilled"
    }

    @computed
    public get promoData() {
        return fromPromise.map(
            this.store.get(),
            factoryData => factoryData && factoryData.data && factoryData.data.reduce(
                (result, item) => {
                    if (this.componentMap.has(item.component)) {
                        result.push({
                            id: `promo.carrot_quest.${item.id}`,
                            feature: item.feature,
                            component:  this.componentMap.get(item.component)(),
                            params: item.params
                        })
                    } else {
                        console.error(`Can't resolve component ${item.component}`)
                    }

                    return result
                },
                []
            ) || []
        )
    }

    @action
    public setFactoryData(factoryData: PromoComponentDataFactory[]) {
        void this.store.set({data: factoryData})
    }
}
