import * as React from "react"
import classNames from "classnames/bind"
import {autobind} from "core-decorators"
import {observer} from "mobx-react"
import {computed, action} from "mobx"
import {noop, debounce} from "lodash"
import * as Api from "src/lib/entities/api"
import {inject} from "src/lib/utils/inject"
import {bindArg, makeRefHandler} from "src/lib/utils/func"
import {Intl} from "src/lib/utils/intl"
import {CFilterGroup} from "./CFilterGroup"
import {AutocompleteFilterStore} from "../AutocompleteFilterStore"
import {AllowedFilter} from "src/bums/common/filter/FilterStore"
import {Component, CCustomScrollbars, CTheme} from "src/lib/components"

const style: any = classNames.bind(require("../CAutocomplete.styl"))
const messages: any = require("../messages.yml")

const ignoredBaseFilters: string[] = [Api.Group.contentType]

export namespace CFilterList {
   export interface Props {
       store: AutocompleteFilterStore<Api.BaseEntity>
       loadAndSelectEmployees?: (group: Api.Group | Api.Department | Api.Position) => void
       selectAllFiltersByDefault?: boolean
   }

   export interface EmployeeProps extends Props {
       canSelectGroupFilter: boolean
   }

   export interface GroupProps extends Props {
       contentType: AllowedFilter["contentType"]
   }
}

@observer
@autobind
class CEmployeeFilterList extends Component<CFilterList.EmployeeProps, {}> {

    @inject(Intl)
    private intl: Intl

    private clickedOnce = false

    private delayedClick = debounce((entity: Api.BaseEntity) => {
        this.props.store.changeFilter(entity)
        this.clickedOnce = false
    }, 400)

    @action
    private selectAndExpandGroup() {
        const {store, canSelectGroupFilter} = this.props

        store.filterGroupExpand(Api.Group.contentType)

        if (canSelectGroupFilter) {
            store.changeFilter(Api.Group.contentType)
        }
    }

    private handleClick(entity: Api.BaseEntity) {
        const {store} = this.props
        if (!this.props.loadAndSelectEmployees) {
            store.changeFilter(entity)
            return
        }

        if (this.clickedOnce) {
            this.delayedClick.cancel()
            this.clickedOnce = false
            this.props.loadAndSelectEmployees(entity as Api.Group | Api.Department)
        } else {
            this.delayedClick(entity)
            this.clickedOnce = true
        }
    }

    private renderGroupItems<T extends Api.BaseEntity>(items: T[]) {
        const {store} = this.props
        return items.map((entity: T) => <CFilterGroup.Item
            key={Api.getGID(entity)}
            title={Api.getEntityName(entity)}
            selected={store.hasFilterSelected(entity)}
            onSelect={bindArg(this.handleClick, entity)}
        />)
    }

    public render() {
        const {store} = this.props

        return <div>
            {store.groupedFilters.has(Api.Department.contentType) &&
                <CFilterGroup
                    title={this.intl.formatMessage(messages["contentType"], {type: Api.Department.contentType})}
                    onExpand={bindArg(store.filterGroupExpand, Api.Department.contentType)}
                    expanded={store.hasFilterGroupExpand(Api.Department.contentType)}
                >
                    {this.renderGroupItems(store.groupedFilters.get(Api.Department.contentType))}
                </CFilterGroup>
            }
            {store.groupedFilters.has(Api.Group.contentType) &&
                <CFilterGroup
                    title={this.intl.formatMessage(messages["contentType"], {type: Api.Group.contentType})}
                    onExpand={this.selectAndExpandGroup}
                    expanded={store.hasFilterGroupExpand(Api.Group.contentType)}
                    selected={store.hasFilterSelected(Api.Group.contentType)}
                >
                    {this.renderGroupItems(store.groupedFilters.get(Api.Group.contentType))}
                </CFilterGroup>
            }
            {store.groupedFilters.has(Api.Position.contentType) &&
                <CFilterGroup
                    title={this.intl.formatMessage(messages["contentType"], {type: Api.Position.contentType})}
                    onExpand={bindArg(store.filterGroupExpand, Api.Position.contentType)}
                    expanded={store.hasFilterGroupExpand(Api.Position.contentType)}
                >
                    {this.renderGroupItems(store.groupedFilters.get(Api.Position.contentType))}
                </CFilterGroup>
            }
        </div>
    }
}


@observer
@autobind
class CGroupFilterList extends Component<CFilterList.GroupProps, {}> {

    @inject(Intl)
    private intl: Intl

    @computed
    private get groups() {
        const {store, contentType} = this.props

        if (!store.groupedFilters.has(contentType)) {
            return new Map<string | Api.FilterGroup, AllowedFilter[]>()
        }

        const systemFilters: AllowedFilter[] = []
        const groupedFilters = new Map<string | Api.FilterGroup, AllowedFilter[]>()
        const unallocatedFilters: AllowedFilter[] = []

        store.groupedFilters.get(contentType).forEach((filter: AllowedFilter) => {
            if (isNaN(Number(filter.id))) {
                systemFilters.push(filter)
            } else if (filter.group) {
                const group = filter.group
                if (groupedFilters.has(group)) {
                    groupedFilters.get(group).push(filter)
                } else {
                    groupedFilters.set(group, [filter])
                }
            } else {
                unallocatedFilters.push(filter)
            }
        })

        return new Map<string | Api.FilterGroup, AllowedFilter[]>([
            [this.intl.formatMessage(messages["system"]), systemFilters],
            ...Array.from(groupedFilters.entries()),
            [this.intl.formatMessage(messages["unallocated"]), unallocatedFilters]
        ])
    }

    public render() {
        const {store} = this.props

        if (!store.groupedFilters.has(this.props.contentType)) {
            return null
        }

        return <div>
            {Array.from(this.groups.entries()).map(item => {
                const [group, filters] = item

                if (filters.length === 0) {
                    return null
                }

                const title = Api.isFilterGroup(group) ? group.name : group
                const gid = Api.isFilterGroup(group) ? Api.getGID(group) : group

                return <CFilterGroup
                    key={gid}
                    title={title}
                    onExpand={bindArg(store.filterGroupExpand, gid)}
                    expanded={store.hasFilterGroupExpand(gid)}
                >
                    {filters.map(entity => <CFilterGroup.Item
                        key={Api.getGID(entity)}
                        title={Api.getValueName(entity, this.intl, false)}
                        selected={store.hasFilterSelected(entity)}
                        onSelect={bindArg(store.changeFilter, entity)}
                    />)}
                </CFilterGroup>
            })}
        </div>
    }
}


@observer
@autobind
export class CFilterList extends Component<CFilterList.Props, {}> {

    public static defaultProps = {
        loadAndSelectEmployees: noop
    }

    @inject(Intl)
    private intl: Intl

    public scrollNode: CCustomScrollbars

    private mutationObserver: MutationObserver

    @computed
    private get filters() {
        const {store} = this.props
        const result: {title: string, bind: void|string}[] = []
        if (store.baseFilters.length > 1) {
            result.push({
                title: this.intl.formatMessage(messages["allFilters"]),
                bind: null
            })
        }

        return result.concat(
            store.baseFilters.filter(contentType => !ignoredBaseFilters.includes(contentType as string)).map((contentType) => ({
                title: this.intl.formatMessage(messages["contentType"], {type: contentType}),
                bind: contentType as string
            }))
        )
    }

    @computed
    private get canSelectGroupFilter() {
        return this.props.store.baseFilters.includes(Api.Group.contentType)
    }

    componentDidMount() {
        if (!this.scrollNode) {
            return
        }

        // Хак для реагирования кастомного скролла на изменения контента компонента
        this.mutationObserver = new MutationObserver(debounce(this.updateScroll, 100))
        this.mutationObserver.observe(this.scrollNode.scrollView, {childList: true, subtree: true})

        if (this.props.selectAllFiltersByDefault) {
            this.props.store.changeFilter(null)
        }
    }

    componentWillUnmount() {
        if (this.mutationObserver) {
            this.mutationObserver.disconnect()
        }
    }

    private updateScroll() {
        if (!this.scrollNode || !this.scrollNode.scrollbarsInstance) {
            return
        }
        const instance = this.scrollNode.scrollbarsInstance
        instance.handleWindowResize()
    }

    public render() {
        const {store} = this.props

        if (store.filters.length === 0) {
            return null
        }

        const customScrollProps = {
            className: style("filterContainer"),
            autoHeight: true,
            autoHeightMax: 300,
            autoHeightMin: 300,
            style: {
                width: 180,
                maxWidth: 180,
                minWidth: 180,
            }
        }

        return <CTheme>
            <CCustomScrollbars customScrollProps={customScrollProps} ref={makeRefHandler(this, "scrollNode")}>
                {this.filters.map(({title, bind}, index) => {
                    return <CFilterGroup.Item
                        key={index}
                        title={title}
                        selected={store.hasFilterSelected(bind)}
                        onSelect={bindArg(store.changeFilter, bind)}
                    />
                })}
                <CEmployeeFilterList
                    store={store}
                    canSelectGroupFilter={this.canSelectGroupFilter}
                    loadAndSelectEmployees={this.props.loadAndSelectEmployees}
                />
                <CGroupFilterList store={store} contentType={Api.CrmFilter.contentType} />
                <CGroupFilterList store={store} contentType={Api.TaskFilter.contentType} />
            </CCustomScrollbars>
        </CTheme>
    }
}
