import * as React from "react"
import {observer} from "mobx-react"
import {action, computed, observable} from "mobx"
import classNames from "classnames/bind"
import {CButton, CEllipsis, CIcon, CIconSize, Component} from "src/lib/components"

const style: any = classNames.bind(require("./CCheckbox.styl"))

export type CCheckboxType = "default" | "success" | "success_button" | "failed"

export namespace CCheckbox {
    export interface Props {
        checked?: boolean
        defaultChecked?: boolean
        caption?: string | JSX.Element
        description?: string
        type?: CCheckboxType
        onChange?: (checked: boolean, event?: React.FormEvent<{}> | React.MouseEvent<{}>) => void
        className?: string
        radioType?: boolean
        disabled?: boolean
        touchRipple?: boolean,
        size?: CIconSize
        maxWidth?: number | string
        labelBeforeBox?: boolean // Определяет порядок [label][box] вместо [box][label]
        noPaddingLeft?: boolean
    }
}

@observer
export class CCheckbox extends Component<CCheckbox.Props, {}> {

    public static defaultProps = {
        type: "default",
        onChange: () => {return},
        defaultChecked: false,
        radioType: false,
    }

    @observable
    private checkedValue: boolean

    @computed
    private get checked() {
        return this.props.checked === void 0
            ? this.checkedValue === void 0 ? this.props.defaultChecked : this.checkedValue
            : this.props.checked
    }

    @action
    private labelOnClick = (event: React.FormEvent<{}> | React.MouseEvent<{}>) => {
        // позволяет выделить текст
        if (window.getSelection() && window.getSelection().toString() && !(event.target as HTMLElement).closest(`.${style("iconBox")}`)) {
            event.preventDefault()
        } else {
            this.toggleChecked(event)
        }
    }

    @action
    private toggleChecked = (event: React.FormEvent<{}> | React.MouseEvent<{}>) => {
        if (this.props.disabled) {
            return
        }

        event.preventDefault()
        this.props.onChange(this.checkedValue = !this.checked, event)
    }

    private setIconType(checked: boolean) {
        if (this.props.type === "failed") {
            return checked ? CIcon.Type.TODO_FAILED : CIcon.Type.CHECKBOX_BLANK
        }
        if (this.props.radioType) {
            return checked ? CIcon.Type.RADIO_BUTTON_CHECKED : CIcon.Type.RADIO_BUTTON_UNCHECKED
        } else {
            return checked ? CIcon.Type.CHECKBOX : CIcon.Type.CHECKBOX_BLANK
        }
    }

    private renderLabel() {
        if (this.props.caption) {
            return <span className={style("caption", this.props.labelBeforeBox ? "rightPadding" : "leftPadding")}>
                {typeof this.props.caption === "string" ?  <CEllipsis>{this.props.caption}</CEllipsis> : this.props.caption}
            </span>
        }
        return null
    }

    public render() {
        const {type, disabled, size, maxWidth} = this.props

        const className = style("wrapper", this.props.className, {disabled: this.props.disabled})

        return (type === "success_button")
            ? <CButton
                name="toggleChecked"
                className={className}
                onClick={this.toggleChecked}
                title={this.props.description}
                caption={this.props.caption}
                iconRight={this.setIconType(this.checked)}
                type={CButton.Type.SETTING}
                touchRipple={this.props.touchRipple}
                disabled={disabled}
                maxWidth={maxWidth}
                colorRight={(disabled || !this.checked) ? CIcon.Color.DEFAULT : CIcon.Color.SUCCESS}
            >
                <input
                    type={(this.props.radioType) ? "radio" : "checkbox"}
                    checked={this.checked}
                    onChange={this.toggleChecked}
                    className={style("input")}
                />
            </CButton>
            : <label className={className} onClick={this.labelOnClick} title={this.props.description}>
                <div className={style("labelWrapper", {small: size === CIconSize.SMALL})}>
                    <input
                        type={(this.props.radioType) ? "radio" : "checkbox"}
                        checked={this.checked}
                        onChange={this.toggleChecked}
                        className={style("input")}
                    />
                    {this.props.labelBeforeBox && this.renderLabel()}
                    <div className={style("iconBox", {small: size === CIconSize.SMALL, noPaddingLeft: this.props.noPaddingLeft})}>
                        <CIcon
                            type={this.setIconType(this.checked)}
                            color={type === "success" && this.checked
                                ? CIcon.Color.SUCCESS
                                : type === "failed"
                                    ? CIcon.Color.RED
                                    : CIcon.Color.DEFAULT}
                            size={size}
                            className={style("icon", type, {
                                disabled: this.props.disabled,
                                checked: this.checked,
                            })}
                        />
                    </div>
                    {!this.props.labelBeforeBox && this.renderLabel()}
                </div>
            </label>
    }
}
