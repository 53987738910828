import {autobind} from "core-decorators"
import {ChatsPageStore} from "src/bums/common/chat/stores/ChatsPageStore"
import * as Api from "src/lib/entities/api"
import {Router} from "src/lib/utils/router"
import {inject} from "src/lib/utils/inject"
import {TopicsStore} from "src/bums/common/stores/TopicsStore"
import {InformerStore} from "src/bums/common/informerPanel/informerStore"
import {BubbleType} from "src/bums/common/informerPanel/types"

@autobind
export class ChatWidgetStore extends ChatsPageStore {

    constructor(
        @inject(Api.Store) apiStore: Api.Store,
        @inject(Router) router: Router,
        @inject(TopicsStore) topicsStore: TopicsStore,
        @inject(InformerStore) private informerStore: InformerStore,
    ) {
        super(apiStore, router, topicsStore, false)
    }

    public async topicOpen(entity: Api.Topic | Api.Employee | Api.Contractor, forceFullScreen = false) {

        if (forceFullScreen !== true) {
            this.informerStore.setOpenedBubble(BubbleType.Chat)
        }

        return super.topicOpen(entity, forceFullScreen)
    }
}
