import {Component} from "src/lib/components"
import * as React from "react"
import classNames from "classnames/bind"

const style = classNames.bind(require("./CPlaceholder.styl"));

/**
 * Placeholder
 */
export class CPlaceholder extends Component<{}, {}> {
    public render() {
        return <span className={style("CPlaceholder")}>
            {this.props.children}
        </span>
    }
}
