export interface InviteLinkFormValue {
    firstName: string
    lastName: string
    email: string
    password: string
    photoRawBase64?: string
}

export const INVITE_EMPLOYEE_LINK = "/inviteForm/"

export const PASSWORD_MIN_LENGTH = 6

export const INVITED_EMPLOYEES_FIRST_LIMIT = 8
export const INVITED_EMPLOYEES_LIMIT = 5

/**
 * Tracking
 */
export const EMPLOYEE_EVENT_TYPE = "Employee"
export const OPEN_EMPLOYEE_FORM_EVENT = "Open Employee form"
export const UNFIRE_SUBMIT = "Unfire Submit"
export const EVENT_TYPE_CHAT_WIDGET = "Chat Widget"
export const EVENT_TYPE_CHATS = "Chats"
export const EVENT_CARD_TYPE = "Employee Card"
export const EVENT_CARD_EDIT_TYPE = "Employee Card Edit"
export const EVENT_OPEN_CHAT = "Open Chat"
export const EVENT_LIST_OPEN = "Action List Open"
export const EVENT_EDIT = "Edit Click"
export const EVENT_FIRE = "Fire Click"
export const EVENT_CANCEL = "Cancel"
export const EVENT_SUBMIT = "Submit"
export const EVENT_BLOCK = "Block Click"
export const EVENT_GETBACK = "Unfire Click"
export const EVENT_UNBLOCK = "Unblock Clik"
export const METHOD_NAME_EMPLOYEE_CARD = "employeeCard"
export const COPY_CONTACT = "Copy Contact"
export const EXPAND_TEAM = "Expand Team"
export const METOD_FROM_EMPLOYEE_CARD = "fromEmployeeCard"
export const EVENT_TYPE_ACTIVITY_DESKTOP_WIDGET = "ActivityDesktopWidget"
export const EVENT_CHOOSE_PERIOD = "choosePeriod"
export const EVENT_EVENT_TYPE = "Event"
export const EVENT_OPEN_EVENT_FORM = "Open Event Form"
export const EFFECTIVENESS_WIDGET = "Effectiveness Widget"
export const CARD_RELATIONS = "Card Relations"

/**
 * Settings
 */
export const SWITCH_EMPLOYEE_CARD_SETTING_NAME = "bums.employee.new.card"
