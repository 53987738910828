import {Component, CCopyToClipboardButton} from "src/lib/components"
import * as React from "react"
import * as Api from "src/lib/entities/api"
import {observer} from "mobx-react"
import {computed} from "mobx";
import {CUser, ViewTypeContactInfo} from "./CUser"
import {FormattedMessage} from "src/lib/utils/intl"
import * as infoHelper from "src/lib/utils/info"
import classNames from "classnames/bind"
import CFieldRow from "src/lib/components/CFieldRow/CFieldRow";

const style: any = classNames.bind(require("./CUser.styl"))
const messages: any = require("./messages.yml")

export module CUserContactList {
    export type Props = {
        entity: Api.ContractorHuman | Api.Employee
        contactRenderer?: (item: Api.ContactInfo, type?: CUserContactList.ContactType, isFirst?: boolean) => JSX.Element
        viewTypeContactInfo?: ViewTypeContactInfo
        customContactInfo?: Array<Api.ContactInfo>
        className?: string | string[]
        showFirst?: boolean
    }
    export type ContactType = "phone" | "email"
}

@observer
export class CUserContactList extends Component<CUserContactList.Props, {}> {

    private renderItem = (item: Api.ContactInfo): JSX.Element => {
        const {contactRenderer} = this.props
        if (contactRenderer) {
            return contactRenderer(item as Api.ContactInfo)
        }
        return <div>
            {item.value}
        </div>
    }

    @computed
    private get emails() {
        let emailGroup: Api.ContactInfo[] = []
        if (this.props.viewTypeContactInfo === CUser.ViewTypeContactInfo.FULL) {
            (this.props.customContactInfo || infoHelper.uniqueContacts(this.props.entity.contactInfo))
                .forEach(item => {
                    if (infoHelper.isEmail(item)) {
                        emailGroup.push(item)
                    }
                })
        } else {
            const emailInfo = infoHelper.findFirstInfo(this.props.entity.contactInfo, Api.ContactInfo.Type.email) as Api.ContactInfo
            if (emailInfo) {
                emailGroup.push(emailInfo)
            }
        }
        return emailGroup.map((el, index) => {
            const renderedEl = this.renderItem(el)
            return <CFieldRow
                customActions={<CCopyToClipboardButton contentToCopy={el.value}/>}
                key={"email" + index}
                size="small"
                width="auto"
                align="middle"
            >
                    {renderedEl}
            </CFieldRow>
        })
    }

    @computed
    private get phones() {
        let phoneGroup: Api.ContactInfo[] = []
        if (this.props.viewTypeContactInfo === CUser.ViewTypeContactInfo.FULL) {
            (this.props.customContactInfo || infoHelper.uniqueContacts(this.props.entity.contactInfo))
                .forEach(item => {
                    if (infoHelper.isPhone(item)) {
                        phoneGroup.push(item)
                    }
                })
        } else {
            const phone = infoHelper.findFirstPhone(this.props.entity.contactInfo)
            if (phone) {
                phoneGroup.push(phone)
            }
        }
        return phoneGroup.map((el, index) => {
            const renderedEl = this.renderItem(el)
            return <CFieldRow
                    customActions={<CCopyToClipboardButton contentToCopy={el.value}/>}
                    key={"phone" + index}
                    size="small"
                    width="auto"
                    align="middle"
                >
                    {renderedEl}
                </CFieldRow>
        })
    }

    render() {
        const {entity, viewTypeContactInfo, className, children} = this.props

        const shortInfoMessage = CUser.ViewTypeContactInfo.SHORT === viewTypeContactInfo
            ? "shortInfoOnly"
            :  CUser.ViewTypeContactInfo.NO_ACCESS === viewTypeContactInfo && Api.isContractor(entity)
                ? "noAccessToClient"
                : null

        if (shortInfoMessage) {
            return <div className={style("blockText")}>
                <FormattedMessage {...messages[shortInfoMessage]}/>
            </div>
        }

        if ((Api.isEmployee(entity) || Api.isContractor(entity)) && entity.contactInfo) {
            const contactInfo = {
                className: style("contactInfo", `contactInfo_${className}`)
            }

            return <React.Fragment>
                <div {...contactInfo}>
                    {this.phones}
                    {this.emails}
                </div>
                {children}
            </React.Fragment>
        }

        return null
    }
}
