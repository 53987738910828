import React, {useEffect} from "react"
import {observer} from "mobx-react-lite"
import classNames from "classnames/bind"
import {motion, usePresence, useAnimation}  from "framer-motion"
import {simpleScale} from "src/bums/common/chat/constants"

const style = classNames.bind(require("./COnlineStatus.styl"))

export namespace COnlineStatus {
    export interface Props {
        type: "web" | "mobile"
    }
}

export const COnlineStatus = observer<COnlineStatus.Props>(({ type }) => {
    const [isPresent, safeToRemove] = usePresence()
    const animation = useAnimation()

    useEffect(() => {
        void animation.start("visible")

        if (!isPresent) {
            void animation.start("hidden").then(safeToRemove)
        }
    }, [isPresent])

    if (type === "web") {
        return (
            <motion.div
                key="webOnline"
                className={style("online")}
                initial="hidden"
                animate={animation}
                variants={simpleScale}
            />
        )
    }

    if (type === "mobile") {
        return (
            <motion.div
                key="mobileOnline"
                className={style("svgContainer")}
                initial="hidden"
                animate={animation}
                variants={simpleScale}
            >
                <svg width={14} height={16} viewBox={"0 0 14 16"}>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.114.5C4.118.5 2.5 2.143 2.5 4.17v7.66c0 2.027 1.618 3.67 3.614 3.67h3.772c1.996 0 3.614-1.643 3.614-3.67V4.17C13.5 2.143 11.882.5 9.886.5H6.114z"
                        fill="currentColor"
                        className={style("mobileBorder")}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.757 4.17c0-.22.173-.376.357-.376h3.772c.184 0 .357.155.357.376v6.234H5.857a1 1 0 00-.1.005V4.17zm.357-2.176c-1.204 0-2.157.987-2.157 2.176v7.66c0 1.189.953 2.176 2.157 2.176h3.772c1.204 0 2.157-.987 2.157-2.176V4.17c0-1.189-.953-2.176-2.157-2.176H6.114z"
                        fill="#08BF32"
                    />
                </svg>
            </motion.div>
        )
    }

    return null
})
