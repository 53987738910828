/* tslint:disable */
import * as H from "../metadataHelper"
import {Employee} from "./employee"
import {Task} from "./task"
import {Project} from "./project"
import {Reminder} from "./reminder"
import {StringField} from "./stringField"
import {BoolField} from "./boolField"
import {EnumField} from "./enumField"
import {DateTimeField} from "./dateTimeField"

export interface Milestone extends H.BaseEntity {
    name?: string; // Название
    description?: string; // Описание вехи
    completed?: boolean; // Признак завершенности вехи
    owner?: Employee; // Создатель вехи
    responsible?: Employee; // Ответственный за веху (поле Reporter в модели)
    type?: Milestone.Type; // Тип вехи
    date?: Date; // Дата и время вехи
    task?: Task; // Задача
    project?: Project; // Проект
    reminder?: Reminder; // Напоминание
    possibleActions?: H.List<Milestone.PossibleActions>; // Возможные действия
    id?: string; // Идентификатор
    contentType: "Milestone"; // Object type
}

export module Milestone {
    export const contentType: "Milestone" = "Milestone";
    export const endpoint = "/api/v3/milestone";
    export type Type = "note"|"reminder"|"report";
    export module Type {
        export const note: Type = "note";
        export const reminder: Type = "reminder";
        export const report: Type = "report";
    }
    export type PossibleActions = "act_milestone_edit";
    export module PossibleActions {
        export const act_milestone_edit: PossibleActions = "act_milestone_edit";
    }
    export const newObject: Milestone = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "Milestone.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const description = {contentType: "StringField" as "StringField", name: "description", isMultiple: false, isSortable: false, id: "Milestone.description", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const completed = {contentType: "BoolField" as "BoolField", name: "completed", isMultiple: false, isSortable: false, id: "Milestone.completed", defaultValue: null as BoolField["defaultValue"]};
        export const owner = {contentType: "RefLinkField" as "RefLinkField", name: "owner", isMultiple: false, isSortable: false, id: "Milestone.owner", refContentType: H.List(["Employee"])};
        export const responsible = {contentType: "RefLinkField" as "RefLinkField", name: "responsible", isMultiple: false, isSortable: false, id: "Milestone.responsible", refContentType: H.List(["Employee"])};
        export const type = {contentType: "EnumField" as "EnumField", name: "type", isMultiple: false, isSortable: false, id: "Milestone.type", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["note", "reminder", "report"])};
        export const date = {contentType: "DateTimeField" as "DateTimeField", name: "date", isMultiple: false, isSortable: false, id: "Milestone.date", defaultValue: null as DateTimeField["defaultValue"]};
        export const task = {contentType: "RefLinkField" as "RefLinkField", name: "task", isMultiple: false, isSortable: false, id: "Milestone.task", refContentType: H.List(["Task"])};
        export const project = {contentType: "RefLinkField" as "RefLinkField", name: "project", isMultiple: false, isSortable: false, id: "Milestone.project", refContentType: H.List(["Project"])};
        export const reminder = {contentType: "RefLinkField" as "RefLinkField", name: "reminder", isMultiple: false, isSortable: false, id: "Milestone.reminder", refContentType: H.List(["Reminder"])};
        export const possibleActions = {contentType: "EnumField" as "EnumField", name: "possibleActions", isMultiple: true, isSortable: false, id: "Milestone.possibleActions", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["act_milestone_edit"])};
    }
}

export function isMilestone(arg: any): arg is Milestone {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Milestone"
}
