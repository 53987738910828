/* tslint:disable */
import * as H from "../metadataHelper"
import {StringField} from "./stringField"
import {EnumField} from "./enumField"

export interface TodoStatus extends H.BaseEntity {
    name?: string; // Имя статуса
    masterType?: TodoStatus.MasterType; // Тип статуса
    id?: string; // Идентификатор
    contentType: "TodoStatus"; // Object type
}

export module TodoStatus {
    export const contentType: "TodoStatus" = "TodoStatus";
    export const endpoint = "/api/v3/todoStatus";
    export type MasterType = "cancelled"|"fail"|"finish_without_result"|"finished"|"scheduled"|"success";
    export module MasterType {
        export const cancelled: MasterType = "cancelled";
        export const fail: MasterType = "fail";
        export const finish_without_result: MasterType = "finish_without_result";
        export const finished: MasterType = "finished";
        export const scheduled: MasterType = "scheduled";
        export const success: MasterType = "success";
    }
    export const newObject: TodoStatus = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "TodoStatus.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const masterType = {contentType: "EnumField" as "EnumField", name: "masterType", isMultiple: false, isSortable: false, id: "TodoStatus.masterType", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["cancelled", "fail", "finish_without_result", "finished", "scheduled", "success"])};
    }
}

export function isTodoStatus(arg: any): arg is TodoStatus {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "TodoStatus"
}
