import {autobind} from "core-decorators"
import {computed} from "mobx"
import * as Api from "src/lib/entities/api"
import {LockStore} from "src/lib/utils/LockStore"
import {AbstractMultiSubscription} from "src/lib/services/utils"
import {SocketTransport, BaseListener} from "src/lib/services/types"
import {ChatCommentData} from "src/bums/common/subscriptions/SaasStatSubscription";


type LastCommentReadData = {id: number, wasRead: boolean}
type ChatTopicData = {entities: Api.Topic[], counters?: Api.Counter[]}

@autobind
export class MessageSubscription extends AbstractMultiSubscription<ChatTopicData | ChatCommentData> {

    constructor(
        transport: SocketTransport,
        private $apiStore: Api.Store,
        private $userStore: Api.UserStore,
        private $lockStore: LockStore,
        private $listStore: () => Api.ListStore<Api.Topic>
    ) {
        super(transport)
    }

    private get listStore() {
        return this.$listStore()
    }

    @computed
    protected get eventHandleMap() {
        if (!this.$userStore.uid) {
            return void 0
        }

        const uid = this.$userStore.uid

        return new Map<string, BaseListener<ChatTopicData | ChatCommentData | LastCommentReadData>>([
            [`bums.chat.topic.read:u${uid}`, this.topicReadListener],
            [`bums.chat.topic.remove:u${uid}`, this.topicRemoveListener],
            [`bums.chat.topic.new:u${uid}`, this.topicNewListener],
            [`bums.chat.comment.new:u${uid}`, this.commentNewListener],
            [`bums.chat.last_comment.read:u${uid}`, this.topicLastCommentReadListener]],
        )
    }

    private topicLastCommentReadListener(data: LastCommentReadData) {
        void this.$apiStore.updateEntitiesFromJson({
            id: data.id.toString(),
            contentType: Api.Comment.contentType,
            wasRead: data.wasRead,
        })
    }

    protected commentNewListener(data: ChatCommentData) {
        const {entities} = data

        if (entities.length > 0 && this.listStore) {

            const subjects = entities
                .map(entity => this.$apiStore.getEntity(entity.subject))
                .filter(entity => {
                    const attachedAll = !entity.isAttachedForever && !entity.attachedTill
                    return attachedAll && !entity.isHidden
                })

            void this.listStore.prependEntities(subjects)
        }
    }

    protected topicReadListener(data: ChatTopicData) {
        void this.$apiStore.updateEntitiesFromJson(data.entities)
        if (data.counters) {
            void this.$apiStore.updateEntitiesFromJson(data.counters)
        }
    }

    protected topicRemoveListener(data: ChatTopicData) {
        if (data.counters) {
            void this.$apiStore.updateEntitiesFromJson(data.counters)
        }
        this.listStore?.removeEntities(data.entities)
    }

    protected async topicNewListener(data: ChatTopicData) {
        if (data.counters) {
            await this.$apiStore.updateEntitiesFromJson(data.counters)
        }
        if (!this.listStore) {
            return
        }

        const topic = await this.$apiStore.fetchFullEntity(data.entities[0])
        await this.$apiStore.updateEntitiesFromJson(data.entities)

        const release = await this.$lockStore.get(this.listStore.hash).shared()

        try {
            await this.listStore.prependEntities([topic])
        } finally {
            release()
        }
    }
}
