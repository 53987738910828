import {observer} from "mobx-react";
import {autobind} from "core-decorators";
import {Component} from "src/lib/components/Component/Component";
import {CButton, CModal, CSmartCard, CStyledContent} from "src/lib/components";
import {Intl} from "src/lib/utils/intl/Intl";
import {inject} from "src/lib/utils/inject"
import CFieldRow from "src/lib/components/CFieldRow/CFieldRow";
import * as React from "react";
import {ZLevel} from "src/lib/components/CModal/CModal";

export namespace CTestDriveAvailableModal {
    export interface Props {
        open: boolean,
        message: string,
        onClose: () => void,
        onConfirm: () => void,
    }
}

const messages: any = require("./messages.yml")

@observer
@autobind
export class CTestDriveAvailableModal extends Component<CTestDriveAvailableModal.Props, {}> {

    @inject(Intl)
    private intl: Intl

    public render() {
        return <CModal open={this.props.open} onClose={this.props.onClose} maxWidth={424} zLevel={ZLevel.LEVEL_3}>
            <CSmartCard>
                <CSmartCard.Header />
                <CSmartCard.Content>
                    <CStyledContent>
                        <span dangerouslySetInnerHTML={{__html: this.props.message}} />
                    </CStyledContent>
                </CSmartCard.Content>
                <CSmartCard.Footer>
                    <CFieldRow width="auto" displayType="inlineItemSmMargin">
                        <CButton
                            name="acceptStatusChange"
                            onClick={this.props.onConfirm}
                            type={CButton.Type.SUCCESS}
                            caption={this.intl.formatMessage(messages["tryInTestDrive"])}/>
                        <CButton
                            name="cancelStatusChange"
                            onClick={this.props.onClose}
                            type={CButton.Type.FLAT}
                            caption={this.intl.formatMessage(messages["notInteresting"])}/>
                    </CFieldRow>
                </CSmartCard.Footer>
            </CSmartCard>
        </CModal>

    }

}
