import * as React from "react"
import {computed} from "mobx"
import {observer} from "mobx-react"
import {Router, PlainRoute, applyRouterMiddleware} from "react-router"
import {History} from "history"
import {useScroll} from "react-router-scroll"
import {inject} from "src/lib/utils/inject"
import {AccountInfoStore} from "src/lib/entities/store/AccountInfoStore"
import {Component} from "src/lib/components/Component/Component"
import {menuItemRoutes} from "src/bums/common/menuitem/routes"
import {commonRoutes} from "src/bums/common/routes"
import {taskRoutes} from "src/bums/task/routes"
import {crmRoutes} from "src/bums/crm/routes"
import {tradeRoutes} from "src/bums/trade/routes"
import {helpRoutes} from "src/bums/help/routes"
import {employeeRoutes} from "src/bums/employee/routes"
import {accountRoutes} from "src/bums/account/routes"
import {applicationRoutes} from "src/bums/application/routes"
import {upgradeRoutes} from "src/bums/upgrade/routes"
import {internalRoutes} from "src/bums/internal/routes"
import {todoRoutes} from "src/bums/todo/routes"
import {settingsIntegrationsRoutes} from "src/bums/settings/integrations/routes"
import {adminSettingsRoutes} from "src/bums/settings/cards/routes"
import {discussRoutes} from "src/bums/discuss/routes"

declare const window: Window & {internal_help_show: boolean, megaplan_iframe_spa: boolean}

const lastRoutes: PlainRoute[] = [
    // Последний обработчик урлов. Отображает лоадер и перезагружает страницу.
    {
        path: "*",
        // cb не исполбзуется внутри функции, но он нужен чтобы сообщить роутеру что хук асинхронный.
        onEnter: (nextState, replace, cb) => {
            //поставим ка обновление только если не одинаковые urls хотя один разок можно попробовать,
            // но надо сохранять state, также можно было бы и ретурнить тут Cempty c ошибкой
            if (document.referrer !== window.location.href) {
                window.location.reload()
            } else if (!window.location.hash) { //сделано для одной перезагрузки, добавив хеш
                window.location.hash = "#rel";
                window.location.reload();
            }
        }
    }
];

interface MegaplanRouterProps {
    history: History;
}

@observer
export default class MegaplanRouter extends Component<MegaplanRouterProps, {}> {

    @inject(AccountInfoStore)
    private accountInfoStore: AccountInfoStore

    @computed
    private get routes() {
        if (!this.accountInfoStore.isLoaded) {
            return  []
        }

        return [
            ...commonRoutes,
            ...this.checkByModule("task", taskRoutes),
            ...this.checkByModule("crm", crmRoutes),
            ...this.checkByModule("trade", tradeRoutes),
            ...accountRoutes,
            ...applicationRoutes,
            ...helpRoutes,
            ...employeeRoutes,
            ...todoRoutes,
            ...discussRoutes,
            ...upgradeRoutes,
            ...settingsIntegrationsRoutes,
            ...adminSettingsRoutes
        ] as PlainRoute[]
    }

    private checkByModule(moduleId: string, routes: PlainRoute[]) {
        return this.accountInfoStore.moduleAvailableInPaidVersionOnly(moduleId)
            ? []
            : routes
    }

    public render() {
        if (!this.accountInfoStore.isLoaded) {
            return null
        }

        let finalRoutes = this.accountInfoStore.licenseExpired
            ? accountRoutes
            : this.routes
        if (process.env.NODE_ENV === "development" && window.internal_help_show) {
            finalRoutes = [...internalRoutes, ...finalRoutes]
        }
        if (window.megaplan_iframe_spa) {
            finalRoutes = [...finalRoutes, ...menuItemRoutes]
        }

        finalRoutes = [...finalRoutes, ...lastRoutes]

        return <Router
            history={this.props.history}
            routes={finalRoutes}
            render={applyRouterMiddleware(useScroll())}
        />
    }
}
