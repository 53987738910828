import * as ReduxActions from "redux-actions"

const ADD_CARD = "src/lib/CCardContainer/action/ADD_CARD"

type AddCardAction = ReduxActions.Action<string>

export function addCard(cardId: string): AddCardAction {
    return {
        type: ADD_CARD,
        payload: cardId
    }
}

export function isAddCardAction(action: any): action is AddCardAction {
    return "object" === typeof action && ADD_CARD === action.type
}
