import {Component, CAdapt} from "src/lib/components"
import * as React from "react"
import * as ReactDOM from "react-dom"
import DOM from "react-dom-factories"
import {noop, debounce, isEqual} from "lodash"
import {CSSTransitionGroup} from "react-transition-group"
import {makeRefHandler} from "src/lib/utils/func"
import {convertClientRectToPageRect} from "src/lib/utils/window"
import {observer} from "mobx-react"
import {Position} from "src/lib/types"

import classNames from "classnames/bind"
import {CStopMouseWheelContainer} from "src/lib/components/CStopMouseWheelContainer/CStopMouseWheelContainer"
import {observable, action} from "mobx";

const style: any = classNames.bind(require("./CPopover.styl"))

//z-index заданный в стилях
const minZIndex = 20000

const TOOLTIP_TRIANGLE_HEIGHT = 15
const TOOLTIP_TRIANGLE_FROM_EDGE_TO_CENTER = 24

const HINT_OFFSET: {[index: string]: number} = {
    left: TOOLTIP_TRIANGLE_HEIGHT,
    top: -TOOLTIP_TRIANGLE_HEIGHT,
    right: TOOLTIP_TRIANGLE_HEIGHT,
    bottom: -TOOLTIP_TRIANGLE_HEIGHT,
}

const HINT_OFFSET_CENTER: {[index: string]: number} = {
    left: TOOLTIP_TRIANGLE_FROM_EDGE_TO_CENTER,
    top: -TOOLTIP_TRIANGLE_HEIGHT,
    right: TOOLTIP_TRIANGLE_FROM_EDGE_TO_CENTER,
    bottom: - 2 * TOOLTIP_TRIANGLE_HEIGHT,
}

const HINT_OFFSET_HORIZONTAL: {[index: string]: number} = {
    left: -TOOLTIP_TRIANGLE_HEIGHT,
    top: 0,
    right: -TOOLTIP_TRIANGLE_HEIGHT,
    bottom: 0,
}

const HINT_OFFSET_HORIZONTAL_CENTER: {[index: string]: number} = {
    left: -TOOLTIP_TRIANGLE_HEIGHT,
    top: TOOLTIP_TRIANGLE_FROM_EDGE_TO_CENTER,
    right: -TOOLTIP_TRIANGLE_HEIGHT,
    bottom: TOOLTIP_TRIANGLE_FROM_EDGE_TO_CENTER,
}

export interface CPopoverProps {
    element?: JSX.Element // элемент относительно которого будет позиционироваться поповер
    position?: Position // Точная позиция поповера в координатах
    open?: boolean // состояние открытости поповера
    triggerCloseOnEsc?: boolean // вызывать ли событие onClose на нажатие клавиши ESC
    onClose?: (event?: React.MouseEvent<HTMLElement>) => void // событие сигнализируещее, что произошел click вне поповера
    className?: string
    // top - от верхней границы элемента вверх, bottom - от нижней границы элемента вниз
    verticalOrientation?: "top" | "bottom"
    // left - от левой границы элемента, right - от правой границы элемента
    horizontalOrientation?: "left" | "right" | "center"
    noPadding?: boolean // Отображение попвера без внутренних отступов
    autoPosition?: boolean // Включение автоматического позиционирования поповера, учитывает края области окна браузера
    autoCorrelation?: boolean // Включение автоматического позиционирования поповера, учитывает края области окна браузера
    animateClass?: "animateFade" | "animate-fadein" | "animate-zoomin" | "animate-little-cloud"
    inlineRender?: boolean // рендер поповера в том месте, где он создан, а не в body
    inlineClassName?: string
    takeIntoElementWhenClose?: boolean // игнорировать click на element для события onClose
    minWidth?: number // минимальная ширина
    maxWidth?: number // максимальная ширина
    zIndex?: string // z-index всего поповера
    type?: "default" | "hint" | "darkhint"
    direction?: "horizontal" | "vertical" // область вывода - "horizontal" - справа/слева, "vertical" - сверху/снизу
    isFromCenter?: boolean
}

interface CPopoverState {
    position?: Position
    zIndex?: string
}

@observer
export default class CPopover extends Component<CPopoverProps, CPopoverState> {

    public node: HTMLDivElement
    public element: HTMLElement
    public content: React.ReactInstance

    @observable
    horizontalOrientation: "left" | "right" | "center"

    @observable
    verticalOrientation?: "top" | "bottom"

    private debouncePositionCalc: () => void

    public state = {} as CPopoverState

    public static defaultProps = {
        open: false,
        onClose: noop,
        horizontalOrientation: "left",
        verticalOrientation: "bottom",
        triggerCloseOnEsc: true,
        noPadding: false,
        inlineRender: false,
        autoPosition: true,
        autoCorrelation: true,
        type: "default",
        direction: "vertical"
    }

    public constructor(props: CPopoverProps) {
        super(props)
        this.debouncePositionCalc = debounce(() => this.recalculateState(), 200)
    }

    public componentDidMount() {
        if (this.props.open) {
            this.initListeners()
        }

        if (this.props.inlineRender) {
            return
        }

        this.node = document.createElement("div")
        this.node.className = style("_sc", "popoverNode")
        document.body.appendChild(this.node)

        this.renderPopover()
    }

    public componentWillReceiveProps(nextProps: CPopoverProps) {
        if (nextProps.open) {
            this.recalculateState(nextProps)
        }
    }

    public componentDidUpdate(prevProps: CPopoverProps) {
        if (this.props.open && !prevProps.open) {
            this.initListeners()
        } else if (!this.props.open) {
            this.removeListeners()
        }
        if (!this.props.inlineRender) {
            this.renderPopover()
        }
        if (this.props.open) {
            this.debouncePositionCalc()
        }
    }

    public componentWillUnmount() {
        this.removeListeners()
        if (!this.props.inlineRender && this.node) {
            ReactDOM.unmountComponentAtNode(this.node)
            if (this.node.parentNode) {
              this.node.parentNode.removeChild(this.node);
            }
            this.node = null
        }
    }

    public getElement = () => ReactDOM.findDOMNode(this.element) as HTMLElement

    private outerClickHandler = (event: MouseEvent) => {
        if (!this.props.open) {
            return
        }

        const target = event.target as HTMLElement
        const content = ReactDOM.findDOMNode(this.content) as HTMLElement
        const element = ReactDOM.findDOMNode(this.element) as HTMLElement

        if (this.props.takeIntoElementWhenClose && element && element.contains(target)) {
            return
        } else if (
            (this.props.inlineRender && this.content && !content.contains(target)) ||
            (this.node && !this.node.contains(target))
        ) {
            // обработка ситуации "поповер в поповере"
            const targetPopover = target.closest(".popoverNode") as HTMLElement
            // если таргет не в поповере - обычное поведение
            if (!targetPopover) {
                this.props.onClose(event as any)
                return
            }
            // Поповеры создаются в Dom друг за другом. Этот нюанс помогает определить вложенность.
            // Достаточно сравнить их порядковый номер
            const popoverNodes = Array.prototype.slice.call(document.body.getElementsByClassName("popoverNode")) as HTMLElement[]
            const currentIndex = popoverNodes.indexOf(this.node)
            const targetIndex = popoverNodes.indexOf(targetPopover)
            if (targetIndex < currentIndex) {
                this.props.onClose(event as any)
            }
        }
    }

    private keydownHandler = (event: KeyboardEvent) => {
        const element = ReactDOM.findDOMNode(this.element) as HTMLElement

        if (this.props.triggerCloseOnEsc && this.props.open && event.keyCode === 27 && (
            ["INPUT", "TEXTAREA"].indexOf((event.target as HTMLElement).nodeName) === -1 ||
            (this.element && element.contains(event.target as HTMLElement))
        )) {
            this.props.onClose()
        }
    }

    private initListeners() {
        document.addEventListener("mousedown", this.outerClickHandler, true)
        document.addEventListener("keydown", this.keydownHandler, true)
        if (this.props.autoPosition) {
            document.addEventListener("scroll", this.debouncePositionCalc, true)
            window.addEventListener("resize", this.debouncePositionCalc, true)
        }
    }

    private removeListeners() {
        document.removeEventListener("mousedown", this.outerClickHandler, true)
        document.removeEventListener("keydown", this.keydownHandler, true)
        if (this.props.autoPosition) {
            document.removeEventListener("scroll", this.debouncePositionCalc, true)
            window.removeEventListener("resize", this.debouncePositionCalc, true)
        }
    }

    private renderPopover() {
        ReactDOM.unstable_renderSubtreeIntoContainer(this, this.renderPopoverContent(), this.node)
    }

    private recalculateState = (props?: CPopoverProps) => {
        const actualProps = props || this.props

        if (!actualProps.open || !this.content || !(this.element || actualProps.position)) {
            return
        }

        const position = this.calculatePosition(actualProps)
        let zIndex = this.props.zIndex ? this.props.zIndex : this.state.zIndex

        if (!zIndex && this.element) {
            const elementNode = ReactDOM.findDOMNode(this.element)
            if (elementNode instanceof Element) {
                const nestedNode = elementNode.closest("._sc")
                if (nestedNode instanceof HTMLElement) {
                    const nodeZIndex = nestedNode.style.zIndex
                    if (nodeZIndex && nodeZIndex !== "auto") {
                        zIndex = String(parseInt(nodeZIndex, 10) + 10 + minZIndex)
                    }
                }
            }
        }

        const newState = {position, zIndex}

        if (!isEqual(this.state, newState)) {
            this.setState(newState)
        }
    }


    @action
    private setOrientation(verticalOrientation: "top" | "bottom", horizontalOrientation: "left" | "right" | "center") {
        this.verticalOrientation = verticalOrientation
        this.horizontalOrientation = horizontalOrientation
    }

    private calculatePosition = (props: CPopoverProps) => {
        let propsPosition: ClientRect = null
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop

        if (this.props.position) {
            if (!this.props.autoPosition) {
                return this.props.position
            }
            propsPosition = Object.assign({
                left: 0,
                bottom: 0,
                right: 0,
                width: 0,
                height: 0
            }, this.props.position, {top: this.props.position.top - scrollTop})
        }

        let {verticalOrientation, horizontalOrientation} = this.props
        let position: Position = {}

        // учет нижней панели
        const correlationBottomWithMenu = 40

        const element = this.props.position ? null : ReactDOM.findDOMNode(this.element) as HTMLElement
        const contentNode = ReactDOM.findDOMNode(this.content)
        if (!(contentNode instanceof Element)) {
            return position
        }
        const offset = convertClientRectToPageRect(this.props.position ? propsPosition : element.getBoundingClientRect())

        const isHorizontal = this.props.direction === "horizontal"

        if (this.props.autoPosition) {
            const scrollLeft = document.body.scrollLeft
            const hasTopCondition = () => (offset.top - contentNode.clientHeight - offset.scrollTop) >= 0
            const hasBottomCondition = () => window.innerHeight - contentNode.clientHeight - offset.height -
                offset.top - correlationBottomWithMenu + offset.scrollTop >= 0
            const hasLeftCondition = () => (window.innerWidth + scrollLeft - contentNode.clientWidth - offset.left) >= 0
            const hasRightCondition = () => (offset.left - contentNode.clientWidth) >= 0

            if (verticalOrientation === "top" && !hasTopCondition() && hasBottomCondition()) {
                verticalOrientation = "bottom"
            } else if (verticalOrientation === "bottom" && !hasBottomCondition() && hasTopCondition()) {
                verticalOrientation = "top"
            }

            if (horizontalOrientation === "left" && !hasLeftCondition() && hasRightCondition()) {
                horizontalOrientation = "right"
            } else if (horizontalOrientation === "right" && !hasRightCondition() && hasLeftCondition()) {
                horizontalOrientation = "left"
            }
        }

        const {isFromCenter} = this.props


        const offsetHeight = element && (element.offsetHeight == null ? element.clientHeight : element.offsetHeight)
        const offsetWidth = element && (element.offsetWidth == null ? element.clientWidth : element.offsetWidth)

        const elementOffsetHeight = offsetHeight || 0
        const elementOffsetWidth = offsetWidth || 0

        const correctFromCenterHeight = isFromCenter && offsetHeight ? offsetHeight / 2 : 0
        const correctFromCenterWidth = isFromCenter && offsetWidth ? offsetWidth / 2 : 0

        if (verticalOrientation === "top") {
            position["bottom"] = isHorizontal
                ? offset.bottom - elementOffsetHeight + correctFromCenterHeight
                : offset.bottom - correctFromCenterHeight
        } else {
            position["top"] = isHorizontal
                ? offset.top + correctFromCenterHeight
                : offset.top + elementOffsetHeight

            const content = contentNode.getBoundingClientRect()

            if (
                this.props.autoCorrelation &&
                this.state.position &&              // если нет состояния - значит вычисляем впервые
                this.state.position.top &&
                this.state.position.top < position["top"]       // случился скрол вниз, контент прокрутился выше изначального положения
            ) {
                if (content.bottom + 1 >= window.innerHeight) {      // если не видим нижнюю границу, то не изменяем координаты -
                    position["top"] = this.state.position.top   // пусть проскролится

                } else if ( // если нижнюю границу видно, но сам попап на экране не вмещается
                    content.bottom < window.innerHeight
                    && content.height >= window.innerHeight
                ) {
                    position["top"] = window.innerHeight + scrollTop     // зафиксируем нижнюю границу
                }
            }
        }

        if (horizontalOrientation === "left") {
            position["left"] = isHorizontal
                ? offset.left + elementOffsetWidth
                : offset.left + correctFromCenterWidth
        } else if (horizontalOrientation === "right") {
            position["right"] = isHorizontal
                ? offset.right
                : offset.right - elementOffsetWidth + correctFromCenterWidth
        } else {
            position["left"] = offset.left - (Math.floor(contentNode.clientWidth / 2) - Math.floor(element.clientWidth / 2))
        }

        if (this.props.inlineRender) {
            const correlation: {[index: string]: number} = {
                top: 0,
                left: 0,
                bottom: offset.height,
                right: offset.width
            }

            Object.keys(position).forEach((key: string) => {
                position[key] = position[key] - offset[key] + correlation[key]
            })
        }

        if (this.props.type === "hint" || this.props.type === "darkhint") {
            const correctOffset = isHorizontal
                ? isFromCenter
                    ? HINT_OFFSET_HORIZONTAL_CENTER
                    : HINT_OFFSET_HORIZONTAL
                : isFromCenter
                    ? HINT_OFFSET_CENTER
                    : HINT_OFFSET


            Object.keys(position).forEach((key: string) => {
                position[key] = position[key] - (correctOffset[key] as number)
            })
        }

        this.setOrientation(verticalOrientation, horizontalOrientation)

        return position
    }

    private refContent = (ref: React.ReactInstance) => {
        this.content = ref
        if (ref) {
            this.recalculateState()
        }
    }

    private onBlockClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
    }

    private renderPopoverContent() {
        const {open, className, noPadding, type, direction} = this.props

        const popoverStyle = Object.assign({}, this.state.position, {
                maxWidth: this.props.maxWidth || "auto",
                minWidth: this.props.minWidth || 0,
                zIndex: this.state.zIndex || minZIndex,
            })

        const content = open ?
            <div
                className={style(
                    className,
                    "popover",
                    "open",
                    {popoverNoPadding: !!noPadding},
                    type,
                    direction,
                    this.verticalOrientation,
                    this.horizontalOrientation
                )}
                style={popoverStyle}
                ref={this.refContent}
                onContextMenu={this.onBlockClick}
            >
                <CStopMouseWheelContainer>{this.props.children}</CStopMouseWheelContainer>
            </div>
            : DOM.noscript()

        return <CAdapt>
            {this.props.animateClass
                ? <CSSTransitionGroup
                    transitionName={this.props.animateClass}
                    transitionEnter={false}
                    transitionLeave={false}
                    transitionAppear={true}
                    transitionAppearTimeout={500}
                >
                    {content}
                </CSSTransitionGroup>
                : content}
            </CAdapt>
    }

    public render() {
        if (!this.props.element) {
            return null
        }

        const element = React.cloneElement(this.props.element, {ref: makeRefHandler(this, "element", (this.props.element as any).ref)})

        if (this.props.inlineRender) {
            return <div className={style("wrapper", this.props.inlineClassName)}>
                {element}
                {this.renderPopoverContent()}
            </div>
        } else {
            return element
        }
    }
}
