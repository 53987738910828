import {Component} from "src/lib/components"
import * as React from "react"
import {observer} from "mobx-react"
import {alignItem} from "src/lib/types"
import classNames from "classnames/bind"

const style = classNames.bind(require("./CFieldRowItem.styl"))

interface CFieldRowItemProps {
    className?: any,
    align?: alignItem
}

/**
 *  Дочерний элемент fieldRow
 */
@observer
export default class CFieldRowItem extends Component<CFieldRowItemProps, {}> {
    public static defaultProps = {
        align: "top"
    }

    public render() {
        return <div className={style("CFieldRowItem", this.props && this.props.className, this.props.align)}>
            {this.props.children}
        </div>
    }
}
