import * as React from "react"
import {CCheckbox, Component} from "src/lib/components"
import classNames from "classnames/bind"
import {bindArg} from "src/lib/utils/func"
import * as Collections from "src/lib/collections"
import CFieldRow from "src/lib/components/CFieldRow/CFieldRow";

const style: any = classNames.bind(require("./CRadioSelect.styl"))

export interface Option {
    key: string | number
    value: string | JSX.Element
}

interface CRadioSelectProps {
    value?: string | number
    options: Collections.List<Option>
    onChange?: (key?: string | number) => void
    disabled?: boolean,
    inline?: boolean
    optionClassName?: string    // css класс, котрый будет применен к каждому из option
}

/**
 * Выбиратор значений на основе списка radio-чекбоксов
 */
export default class CRadioSelect extends Component<CRadioSelectProps, {}> {

    public static defaultProps = {
        inline: false,
    }

    private toggleOption = (option: Option) => {
        if (option.key === this.props.value) {
            return
        }
        this.props.onChange(option.key)
    }

    private renderOption = (option: Option) => {
        return (
            <div
                key={option.key}
                className={style("option", this.props.optionClassName)}
                onClick={bindArg(this.toggleOption, option)}
            >
                <CCheckbox
                    className={style("checkbox")}
                    type={"success"}
                    radioType={true}
                    checked={this.props.value === option.key}
                    disabled={this.props.disabled}
                />
                <div className={style("caption")}>
                    {option.value}
                </div>
            </div>
        )
    }

    public render() {
        const options = this.props.options.map(this.renderOption)

        return this.props.inline
            ? <CFieldRow displayType="inlineItemMargin" width="auto">
                {options}
            </CFieldRow>

            : <div className={style("wrapper")}>
                {options}
            </div>

    }
}
