import * as React from "react"
import classNames from "classnames/bind"
import {ContentBlock, ContentState, CharacterMetadata, Entity} from "draft-js"
import {Intl} from "src/lib/utils/intl"
import {inject} from "src/lib/utils/inject"
import {makeRefHandler} from "src/lib/utils/func"
import {CPopover, CInput, CIcon, CEllipsis, Component} from "src/lib/components"
import {EditorState} from "draft-js"
import {replaceEntity} from "src/lib/utils/draft"
import {submitHandlers} from "src/lib/components/CEditor/CEditor"
import {noop} from "lodash"

const style: any = classNames.bind(require("./LinkEntity.styl"))
const messages: any = require("../messages.yml")


interface OwnProps {
    editorState: EditorState
    onChange: (editorState: EditorState) => void
    changeOuterToolbarState: (state: boolean) => void
    submitHandlers: submitHandlers
}

interface EntityProps {
    entityKey: string
    decoratedText: string
    children: React.ReactNode
}

type CEntityLinkProps = OwnProps & EntityProps

interface CEntityLinkState {
    open?: boolean
    view?: "edit" | "view"
    inputValue?: string
}

const NO_CONFIRM_LINK = "NO-CONFIRM-LINK"
const LINK = "LINK"

function getEntity(props: CEntityLinkProps) {
    return props.editorState.getCurrentContent().getEntity(props.entityKey)
}

class CEntityLink extends Component<CEntityLinkProps, CEntityLinkState> {

    @inject(Intl)
    private intl: Intl

    public input: CInput

    public constructor(props: CEntityLinkProps) {
        super(props)
        const hasOpen = props.entityKey === null ? false : getEntity(props).getType() === NO_CONFIRM_LINK
        this.state = {
            open: hasOpen,
            view: hasOpen ? "edit" : "view",
            inputValue: ""
        }
    }

    public componentDidMount() {
        if (this.state.open) {
            setTimeout(() => this.input.focus())
        }
    }

    public componentDidUpdate(prevProps: CEntityLinkProps, prevState: CEntityLinkState) {
        if (prevState.view === "view" && this.state.view === "edit") {
            setTimeout(() => this.input.focus())
        }
    }

    private resetState = () => this.setState({open: false, inputValue: "", view: "view"})

    private viewHandler = (event: React.MouseEvent<Element>) => {
        if (event) {
            event.preventDefault()
        }
        this.setState({view: "edit"})
    }

    private saveHandler = (event: React.MouseEvent<Element>) => {
        if (event) {
            event.preventDefault()
        }
        const {inputValue} = this.state

        if (inputValue === "") {
            this.props.onChange(replaceEntity(this.props.editorState, this.props.entityKey, null))
            return
        }

        const entity = getEntity(this.props)
        if (entity.getType() === NO_CONFIRM_LINK) {
            this.props.onChange(
                replaceEntity(this.props.editorState, this.props.entityKey, Entity.create(LINK, "IMMUTABLE", {url: inputValue}))
            )
        } else {
            Entity.replaceData(this.props.entityKey, {
                url: this.state.inputValue
            })
        }
        this.props.changeOuterToolbarState(false)
        this.resetState()
    }

    private trashHandler = (event: React.MouseEvent<Element>) => {
        if (event) {
            event.preventDefault()
        }
        this.props.onChange(replaceEntity(this.props.editorState, this.props.entityKey, null))
    }

    private closeHandler = (event?: MouseEvent | React.MouseEvent<Element>) => {
        if (event) {
            event.preventDefault()
        }
        const entity = getEntity(this.props)
        if (entity.getType() === NO_CONFIRM_LINK) {
            this.props.onChange(replaceEntity(this.props.editorState, this.props.entityKey, null))
        } else {
            this.resetState()
        }
        this.props.changeOuterToolbarState(false)
    }

    private changeHandler = (inputValue: string) => {
        this.setState({inputValue})
    }

    private openHandler = (event: React.MouseEvent<HTMLElement>) => {
        if (this.state.open) {
            return
        }
        event.preventDefault()
        const {url} = getEntity(this.props).getData()
        this.props.changeOuterToolbarState(true)
        this.setState({
            open: true,
            inputValue: url
        })
    }

    private renderContent() {
        const entity = getEntity(this.props)
        const {url} = entity.getData()

        let buttons: JSX.Element[]
        let input: JSX.Element

        if (this.state.view === "view") {
            input = <div className={style("linkWrapper")}>
                <CEllipsis>
                    <a href={url} target="_blank" className={style("link")}>{url}</a>
                </CEllipsis>
            </div>

            buttons = [
                <CIcon key="change" type={CIcon.Type.EDIT} onMouseDown={this.viewHandler} className={style("button")}/>,
                <CIcon key="trash" type={CIcon.Type.DELETE} onMouseDown={this.trashHandler} className={style("button")}/>
            ]
        } else {
            if (this.props.submitHandlers) {
                this.props.submitHandlers.blockSubmit()
            }
            input = <CEntityLinkInput
                component={<CInput
                    name="insertLink"
                    value={this.state.inputValue}
                    onChange={this.changeHandler}
                    className={style("input")}
                    placeholder={this.intl.formatMessage(messages["insertLink"])}
                    ref={makeRefHandler(this, "input")}
                />}
                submitHandlers={this.props.submitHandlers}
            />

            buttons = [
                <CIcon key="cancel" type={CIcon.Type.CANCEL} onMouseDown={this.closeHandler} className={style("button")}/>,
                <CIcon
                    key="confirm"
                    type={CIcon.Type.CONFIRM_CIRCLE}
                    onMouseDown={this.saveHandler}
                    className={style("button", "confirm")}
                />
            ]
        }

        return <div className={style("wrapper")}>
            {input}
            <div className={style("buttonWrapper")} >
                {buttons}
            </div>
        </div>
    }

    public render() {
        const {entityKey} = this.props

        if (entityKey === null) {
            return <a href={this.props.decoratedText} className={style("link")}>{this.props.children}</a>
        }

        const entity = getEntity(this.props)
        const {url} = entity.getData()
        let link: JSX.Element

        if (entity.getType() === NO_CONFIRM_LINK) {
            link = <span className={style("selected")}>{this.props.children}</span>
        } else {
            link = <a href={url} className={style("link", {selected: this.state.open})} onClick={this.openHandler} >
                {this.props.children}
            </a>
        }

        return <CPopover
            element={link}
            open={this.state.open}
            onClose={this.closeHandler}
            verticalOrientation="top"
            horizontalOrientation="left"
            className={style("popover", this.state.view)} >
            {this.renderContent()}
        </CPopover>
    }
}

export default function (propsMapper: () => OwnProps) {
    return {
        strategy: (contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState) => {
            contentBlock.findEntityRanges(
                (character: CharacterMetadata) => {
                    const entityKey = character.getEntity()
                    return entityKey && [NO_CONFIRM_LINK, LINK].includes(contentState.getEntity(entityKey).getType())
                },
                callback
            )
        },
        component: function(props: EntityProps) {
            return <CEntityLink {...props} {...propsMapper()}>
                {props.children}
            </CEntityLink>
        }
    }
}

interface CEntityLinkInputProps {
    component: JSX.Element
    submitHandlers: submitHandlers
}

class CEntityLinkInput extends Component<CEntityLinkInputProps, {}> {
    static defaultProps: any = {
        submitHandlers: {
            blockSubmit: noop,
            unblockSubmit: noop
        }
    }

    public componentWillMount() {
        this.props.submitHandlers.blockSubmit()
    }

    public componentWillUnmount() {
        this.props.submitHandlers.unblockSubmit()
    }

    render() {
        return this.props.component
    }
}

