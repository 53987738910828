/* tslint:disable */
import * as H from "../metadataHelper"
import {StringField} from "./stringField"
import {DateTimeField} from "./dateTimeField"
import {IntegerField} from "./integerField"

export interface Approval extends H.BaseEntity {
    type?: string;
    time?: Date;
    deadline?: Date;
    entity?: H.BaseEntity;
    status?: string;
    owner?: H.BaseEntity;
    accept?: string;
    acceptAction?: string;
    reject?: string;
    rejectAction?: string;
    sort?: number;
    id?: string; // Идентификатор
    contentType: "Approval"; // Object type
}

export module Approval {
    export const contentType: "Approval" = "Approval";
    export const endpoint = "/api/v3/approval";

    export const newObject: Approval = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const type = {contentType: "StringField" as "StringField", name: "type", isMultiple: false, isSortable: false, id: "Approval.type", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const time = {contentType: "DateTimeField" as "DateTimeField", name: "time", isMultiple: false, isSortable: false, id: "Approval.time", defaultValue: null as DateTimeField["defaultValue"]};
        export const deadline = {contentType: "DateTimeField" as "DateTimeField", name: "deadline", isMultiple: false, isSortable: false, id: "Approval.deadline", defaultValue: null as DateTimeField["defaultValue"]};
        export const entity = {contentType: "RefLinkField" as "RefLinkField", name: "entity", isMultiple: false, isSortable: false, id: "Approval.entity", refContentType: H.List(["BaseEntity"])};
        export const status = {contentType: "StringField" as "StringField", name: "status", isMultiple: false, isSortable: false, id: "Approval.status", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const owner = {contentType: "RefLinkField" as "RefLinkField", name: "owner", isMultiple: false, isSortable: false, id: "Approval.owner", refContentType: H.List(["BaseEntity"])};
        export const accept = {contentType: "StringField" as "StringField", name: "accept", isMultiple: false, isSortable: false, id: "Approval.accept", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const acceptAction = {contentType: "StringField" as "StringField", name: "acceptAction", isMultiple: false, isSortable: false, id: "Approval.acceptAction", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const reject = {contentType: "StringField" as "StringField", name: "reject", isMultiple: false, isSortable: false, id: "Approval.reject", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const rejectAction = {contentType: "StringField" as "StringField", name: "rejectAction", isMultiple: false, isSortable: false, id: "Approval.rejectAction", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const sort = {contentType: "IntegerField" as "IntegerField", name: "sort", isMultiple: false, isSortable: false, id: "Approval.sort", defaultValue: null as IntegerField["defaultValue"]};
    }
}

export function isApproval(arg: any): arg is Approval {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Approval"
}
