import {autobind} from "core-decorators"
import * as React from "react"
import classNames from "classnames/bind"
import * as ReactDOM from "react-dom"
import {pick} from "lodash"
import TextareaAutosize from "react-textarea-autosize"
import InputMask from "react-input-mask"
import {makeRefHandler} from "src/lib/utils/func"
import {formFieldStatus, sizeType} from "src/lib/types"
import {CFormFieldBorderBottom, Component} from "src/lib/components"
import {observer} from "mobx-react"
import {observable, action, computed} from "mobx"

const style = classNames.bind(require("./CInput.styl"))

type EventTarget = HTMLInputElement | HTMLTextAreaElement
type FormEvent = React.FormEvent<EventTarget>
type KeyboardEvent = React.KeyboardEvent<EventTarget>
type KeyboardEventHandler = React.KeyboardEventHandler<EventTarget>
type FocusEvent = React.FocusEvent<EventTarget>

export namespace CInput {
    export interface Props {
        id?: string
        type?: string
        status?: formFieldStatus
        placeholder?: string
        name: string            // На этот параметр завязаны автотесты
        value?: string
        className?: string
        multiline?: boolean
        onChange?: (value?: string, event?: FormEvent) => void
        onEnterPress?: (value?: string, event?: KeyboardEvent) => void
        onCtrlEnter?: (value?: string, event?: KeyboardEvent) => void
        onShiftEnter?: (value?: string, event?: KeyboardEvent) => void
        onEscapePress?: (value?: string, event?: KeyboardEvent) => void
        onKeyDown?: KeyboardEventHandler
        onKeyUp?: KeyboardEventHandler
        minRows?: number
        maxRows?: number
        resizable?: boolean
        onFocus?: (event?: FocusEvent) => void
        onBlur?: (value?: string, event?: FormEvent) => void
        onClick?: () => void
        // mask options
        mask?: string
        maskChar?: string
        formatChars?: Object
        alwaysShowMask? : boolean
        disabled?: boolean
        autofocus?: boolean
        autofocusEnd?: boolean
        size?: sizeType
        //Ширина текстового поля, вариант "auto" не работает для поля с маской
        sizeInput?: number | "auto" | "inherit"
        //Нижнее значение для ввода числа или даты
        min?: number
        //Верхнее значение для ввода числа или даты
        max?: number
        autoComplete?: "on" | "off" | "new-password"
        showBottomBorder?: boolean
    }
}

/**
 * Текстовый элемент формы
 */
@autobind
@observer
export class CInput extends Component<CInput.Props, {}> {

    public static Status: formFieldStatus

    @observable
    private $focused = false

    public static defaultProps = {
        type: "text",
        status: "normal",
        size: "medium",
        autofocus: false,
        autofocusEnd: false,
        autoComplete: "off",
        showBottomBorder: true
    }

    public inputRef: TextareaAutosize|HTMLInputElement|HTMLTextAreaElement
    private caretPositionToSet: number

    componentDidUpdate() {
        if (this.caretPositionToSet !== void 0) {
            this.setCaretPosition(this.caretPositionToSet)
            this.caretPositionToSet = void 0
            // TODO надо еще как-то двигать скролл за курсором
        }
    }

    public componentDidMount() {
        if (this.props.autofocus) {
            this.focus(this.props.autofocusEnd)
        }
    }

    public componentWillReceiveProps(nextProps: CInput.Props) {
        if (nextProps.autofocus && !this.props.autofocus) {
            this.focus()
        }
    }

    @computed
    public get focused() {
        return this.$focused
    }

    private changeHandler = (event: FormEvent) => {
        if (this.props.onChange) {
            this.props.onChange(event.currentTarget.value, event)
        }
    }

    @action
    private blurHandler = (event: FormEvent) => {
        if (this.props.onBlur) {
            this.props.onBlur(event.currentTarget.value, event)
        }
        this.$focused = false
    }

    private keyDownHandler = (event: KeyboardEvent) => {
        if (event.key === "Enter") {

            if (event.shiftKey && this.props.onShiftEnter) {
                //console.log(`keyDown + onShiftEnter: ` + event.key + ` ` + event.shiftKey)
                this.props.onShiftEnter(event.currentTarget.value, event)
                // Без preventDefault вставится лишняя строка
                event.preventDefault()
                return
            }

            //ctrl+enter нету в keypress
            if ((event.ctrlKey || event.metaKey) && this.props.onCtrlEnter) {
                //console.log(`keyDown + onCtrlEnter: ` + event.key + ` ` + event.ctrlKey)
                this.props.onCtrlEnter(event.currentTarget.value, event)
                event.preventDefault()
                return
            }

            if (this.props.onEnterPress) {
                //console.log(`keyDown + onEnterPress: ` + event.key)
                this.props.onEnterPress(event.currentTarget.value, event)
                // Без preventDefault вставится лишняя строка
                event.preventDefault()
                return
            }
        }

        if (this.props.onEscapePress && event.key === "Escape") {
            this.props.onEscapePress(event.currentTarget.value, event)
            event.preventDefault()
            return
        }

        if (this.props.onKeyDown) {
            this.props.onKeyDown(event)
        }
    }

    /**
     * Вставляет новую строку на текущем расположении курсора.
     * Event нужен, чтобы корректно дернуть коллбэк onChange
     * @param event
     */
    public insertNewLine(event: KeyboardEvent) {
        if (!this.props.multiline) {
            return
        }
        let pos = this.getCaretPosition()
        let value = event.currentTarget.value
        this.props.onChange(value.slice(0, pos) + "\n" + value.slice(pos), event)
        this.caretPositionToSet = pos + 1
        return
    }

    /**
     * Возвращает текущю позицию каретки.
     * @returns {number}
     */
    public getCaretPosition() {
        let el = ReactDOM.findDOMNode(this.inputRef) as EventTarget
        return el.selectionStart
    }

    /**
     * Устанавливает каретку на определенное место
     * @param position
     */
    @action
    public setCaretPosition(position: number) {
        let el = ReactDOM.findDOMNode(this.inputRef) as EventTarget
        el.setSelectionRange(position, position)
        this.$focused = true
    }

    @action
    public focus(focusEnd = false) {
        const input = ReactDOM.findDOMNode(this.inputRef) as EventTarget
        if (input === document.activeElement) {
            return
        }
        input.focus()
        if (focusEnd) {
            const value = input.value
            input.value = ""
            input.value = value
        }
        this.$focused = true
    }

    @action
    public blur() {
        const input = ReactDOM.findDOMNode(this.inputRef) as EventTarget
        if (input && input === document.activeElement) {
            input.blur()
        }
        this.$focused = false
    }

    @action
    private focusHandler = () => {
        this.$focused = true
        if (this.props.onFocus) {
            this.props.onFocus()
        }
    }

    public getValue() {
        return (ReactDOM.findDOMNode(this.inputRef) as EventTarget).value
    }

    public render() {
        let status = this.props.status
        if (this.props.disabled && !this.props.status) {
            status = "disabled"
        }

        const isSizeAuto = this.props.sizeInput === "auto"

        const commonProps = {
            id: this.props.id,
            className: style(
                "textField",
                status,
                this.props.size,
                {
                    textFieldArea: this.props.multiline,
                    clear: !this.props.showBottomBorder,
                    sizeInputAuto: isSizeAuto
                },
            ),
            value: this.props.value == null ? "" : this.props.value,
            name: this.props.name == null ? "" : this.props.name,
            placeholder: this.props.placeholder,
            onChange: this.changeHandler,
            onFocus: this.focusHandler,
            onKeyDown: this.keyDownHandler,
            onBlur: this.blurHandler,
            onClick: this.props.onClick,
            disabled: this.props.disabled,
            autoComplete: this.props.autoComplete,
            ref: makeRefHandler(this, "inputRef"),
            key: "textField"
        }

        const textField = this.props.multiline
            ? isSizeAuto
                ? <textarea
                    {...commonProps}
                    {...pick(this.props, ["minRows", "maxRows", "onKeyUp"])}
                />
                : <TextareaAutosize
                    {...commonProps}
                    {...pick(this.props, ["minRows", "maxRows", "onKeyUp"])}
                />
            : <input
                {...commonProps}
                {...pick(this.props, ["type", "onKeyUp", "min", "max"])}
                size={!isSizeAuto ? this.props.sizeInput as number : void 0}
            />

        const input = isSizeAuto
            ?  [<div
                    className={style(
                        "hiddenBlock",
                        this.props.size,
                        {
                            minWidth: !(this.props.value && this.props.value.length !== 0
                                || this.props.placeholder && this.props.placeholder.length !== 0),
                            placeholder: !this.props.value && this.props.placeholder && this.props.placeholder.length !== 0,
                            textAreaBlock: this.props.multiline
                        }
                        )}
                    key="hiddenBlock"
                >
                    {this.props.value ? this.props.value : this.props.placeholder}
                </div>,
                textField]
            : textField

        return <div className={style(
                "CInput",
                this.props.className,
                this.props.size,
                {clear: !this.props.showBottomBorder, sizeCInputAuto: isSizeAuto}
            )}
        >
            <div className={style({"box" : this.props.showBottomBorder, sizeBoxAuto: isSizeAuto}, "inputWidth")}>
                {this.props.mask
                    ? <InputMask
                        {...commonProps}
                        {...pick(this.props, ["mask", "maskChar", "formatChars", "alwaysShowMask"])}
                    />
                    : input
                }
            </div>
            {this.props.showBottomBorder &&
                <CFormFieldBorderBottom status={this.focused ? "focus" : this.props.status || "normal"} />
            }
        </div>


    }
}
