export enum CIconSize {
    XSMALL,
    SMALL,
    MEDIUM,
    LARGE
}

export enum CIconColor {
    DARK,
    DARK_BLUE,
    FAVORITE,
    LIGHT,
    GREY,
    RED,
    SILVER,
    EGGPLANT,
    BLACK,
    OREGON,
    OLIVE,
    LAUREL,
    CUSTOM_GREEN,
    YELLOW,
    GREEN,
    KIMBERLY,
    ROUGE,
    PISTACHIO,
    MAROON,
    NAVY,
    LIPSTICK,
    INDIGO,
    ORANGE,
    VERMILION,
    SUCCESS,
    DEFAULT,
    SECONDARY,
    EMERALD,
    FROLY,
    ALTO,
    LINK,
    WHATSAPP,
    TELEGRAM,
    FILE_UNKNOWN,
    FILE_DOCUMENT,
    FILE_EXCEL,
    FILE_PPOINT,
    FILE_IMAGE,
    FILE_VIDEO,
    FILE_ARCHIVE,
    FILE_MUSIC,
    FILE_GIF,
    FILE_PDF,
    FILE_PSD,
    FILE_AI,
    FILE_SKETCH,
    LIGHT_STEEL,
    LIGHT_NIGHT,
    WHITE,
    INSTAGRAM,
    TASK,
    TOPIC,
    CONTRACTORHUMAN,
    CONTRACTORCOMPANY,
    TODO,
    DEAL
}
