import {
    ContentType, ContractorActivityType,
    ContractorCompany,
    ContractorHuman,
    ContractorStatus,
    ContractorType,
    DeliveryType,
    Employee,
    ExtraField, isContractor,
    isDeal, isProject,
    isRefLinkField, isTask,
    RefLinkField,
    Unit
} from "src/lib/entities/api"
import {BaseEntity} from "src/lib/entities/types"
import {intersection} from "lodash"

const notAutocompleteTypes = [
    ContractorStatus.contentType,
    ContractorType.contentType,
    DeliveryType.contentType,
    Unit.contentType,
    ContractorActivityType.contentType
]

export function isAnyTypeUserField(extraField: ExtraField) {
    return isRefLinkField(extraField)
        && extraField.refContentType.includes(Employee.contentType)
        && extraField.refContentType.includes(ContractorHuman.contentType)
        && extraField.refContentType.includes(ContractorCompany.contentType)
}

export function isDealUserField(extraField: ExtraField, contextEntity: BaseEntity): extraField is RefLinkField {
    return isDeal(contextEntity) && isAnyTypeUserField(extraField)

}

export function isContractorField(extraField: ExtraField): extraField is RefLinkField {
    return isRefLinkField(extraField) && (
        (
            extraField.refContentType.length === 2
                && extraField.refContentType.includes(ContractorHuman.contentType)
                && extraField.refContentType.includes(ContractorCompany.contentType)
        )
            ||
        (
            extraField.refContentType.length === 1 && (isContractorCompanyField(extraField) || isContractorHumanField(extraField))
        )
    )
}

export function isContractorCompanyField(extraField: ExtraField): extraField is RefLinkField {
    return isRefLinkField(extraField)
        && extraField.refContentType.length === 1 && extraField.refContentType.includes(ContractorCompany.contentType)
}

export function isContractorHumanField(extraField: ExtraField): extraField is RefLinkField {
    return isRefLinkField(extraField)
        && extraField.refContentType.length === 1 && extraField.refContentType.includes(ContractorHuman.contentType)
}

export function isUserViewField(extraField: ExtraField, contextEntity: BaseEntity): extraField is RefLinkField {
    return isRefLinkField(extraField)
        && (
            isDeal(contextEntity)
            || isTask(contextEntity)
            || isProject(contextEntity)
            || isContractor(contextEntity)
        )
        && (
            extraField.refContentType.includes(Employee.contentType)
            || extraField.refContentType.includes(ContractorHuman.contentType)
            || extraField.refContentType.includes(ContractorCompany.contentType)
        )
}

export function isSupportAutocompleteContentTypes(contentTypes: ContentType[]) {
    return intersection(notAutocompleteTypes, contentTypes).length === 0
}
