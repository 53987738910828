import * as React from "react"
import * as Api from "src/lib/entities/api"
import {ModalFormStore} from "src/bums/common/modalForm/ModalFormStore"

// Спец-клавиша, "-" - без спец. клавиш
export type ModifierKey = "alt" | "ctrl" | "meta" | "meta|ctrl" | "-" | "alt+shift"


// Just add required key from the end of this file
export type MainKey = "q" | "w" | "e" | "r" | "t" | "y" | "u" | "i" | "o" | "p" | "[" | "]"
    | "a" | "s" | "d" | "f" | "g" | "h" | "j" | "k" | "l" | ";" | "'" | "|"
    | "z" | "x" | "c" | "v" | "b" | "n" | "m" | "<" | ">" | "?"
    | "~" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "0" | "-" | "="
    | "num0" | "num1" | "num2" | "num3" | "num4" | "num5" | "num6" | "num7" | "num8" | "num9" | "num+" | "num-" | "num*" | "num/"
    | "f1" | "f2" | "f3" | "f4" | "f5" | "f6" | "f7" | "f8" | "f9" | "f10" | "f11" | "f12" | "escape"


export interface HandlerBag {
    handler: (e: React.KeyboardEvent<any>) => void
    modifierKey: ModifierKey
    mainKey: MainKey
    allowInInput: boolean
}

export type KeyMapItem = [ModifierKey, MainKey, (e: React.KeyboardEvent<any>) => void, boolean] |
                         [ModifierKey, MainKey, (e: React.KeyboardEvent<any>) => void]

function isKeyMapItem(a: any): a is KeyMapItem {
    return Array.isArray(a) && (a.length === 3 || a.length === 4) && !Array.isArray(a[0])
}

export interface UrlStruct {
    features: Api.Feature.Name[]
    openFormHandler: {buttonId?: string, handler: (modalFormStore: ModalFormStore) => void}
    redirectHandler: {buttonId?: string, handler: () => void}
    hotKey: MainKey
}

const dealUrlStruct: UrlStruct = {
    features: ["bums.trade_card_component"],
    openFormHandler: {
        buttonId: "deal_add",
        handler: (modalFormStore: ModalFormStore) => {
            return modalFormStore.open("deal", {
                presets: [{contentType: Api.Deal.contentType, state: void 0, program: void 0}],
                type: "shortcut"
            })
        }
    },
    redirectHandler: {
        handler: () => {
            const path = "/deals/list/"
            if (window.location.pathname.includes(path)) {
                return null
            }
            return window.location.assign(path)
        }
    },
    hotKey: "d"
}

export const hotKeyHandlersMap = new Map<string, UrlStruct>([
    ["task", {
        features: ["bums.task_card_component"],
        openFormHandler: {
            buttonId: "task_add",
            handler: (modalFormStore: ModalFormStore) => {
                return modalFormStore.open("taskProject", {type: "shortcut"})
            }
        },
        redirectHandler: {
            buttonId: "task_my",
            handler: () => {
                const path = "/task/filter/"
                if (window.location.pathname.includes(path)) {
                    return null
                }
                return window.location.assign(path)
            }
        },
        hotKey: "t",
    }],
    ["crm", {
        features: ["bums.company_card_component"],
        openFormHandler: {
            buttonId: "crm_add",
            handler: (modalFormStore: ModalFormStore) => {
                return modalFormStore.open("contractor", {type: "shortcut"})
            }
        },
        redirectHandler: {
            buttonId: "crm_list",
            handler: () => {
                if (window.location.pathname.includes("/crm/filter/")) {
                    return null
                }
                return window.location.assign("/crm/")
            }
        },
        hotKey: "c"
    }],
    ["deal", dealUrlStruct],
    ["bpm", dealUrlStruct],
    ["employee", {
        features: [],
        openFormHandler: {
            buttonId: "employee_add",
            handler: (modalFormStore: ModalFormStore) => {
                return modalFormStore.open("employee", {type: "shortcut"})
            }
        },
        redirectHandler: {
            buttonId: "employee_list",
            handler: () => {
                const path = "/staff/list/"
                if (window.location.pathname.includes(path)) {
                    return null
                }
                return window.location.assign(path)
            }
        },
        hotKey: "e"
    }]
])

export class HotKeyManager {

    private handlersBin: {[key1 in ModifierKey]?: {[key2 in MainKey]?: Array<HandlerBag>}} = {}


    constructor () {
        window.addEventListener("keydown", this.onKeyDownHandler as any)
    }

    public addKeyHandler(item: KeyMapItem[] | KeyMapItem, stack: boolean): HandlerBag[] {

        const items: KeyMapItem[] = isKeyMapItem(item)
            ? [item]
            : item

        const result: HandlerBag[] = [];

        for (let keyMapItem of items) {
            result.push(this.createHandlerBag(keyMapItem, stack))
        }
        return result
    }

    private createHandlerBag(keyMapItem: KeyMapItem, stack: boolean): HandlerBag {
        const modifierKey: ModifierKey = keyMapItem[0] as ModifierKey
        const mainKey: MainKey = keyMapItem[1] as MainKey
        if (!this.handlersBin[modifierKey]) {
            this.handlersBin[modifierKey] = {}
        }

        if (this.handlersBin[modifierKey][mainKey]) {
            if (!stack) {
                console.error(`Duplicate hot key detected: ${modifierKey}-${mainKey}`)
            }
        } else {
            this.handlersBin[modifierKey][mainKey] = []
        }

        const handlerBag: HandlerBag = {
            handler: keyMapItem[2] as (e: React.KeyboardEvent<any>) => void,
            allowInInput: (keyMapItem[3] as boolean),
            modifierKey: modifierKey,
            mainKey: mainKey
        }
        this.handlersBin[keyMapItem[0]][mainKey].unshift(handlerBag)

        return handlerBag
    }

    public removeKeyHandler(items: HandlerBag[]) {
        for (let handler of items) {
            if (this.handlersBin[handler.modifierKey]) {
                if (this.handlersBin[handler.modifierKey][handler.mainKey]) {
                    const index = this.handlersBin[handler.modifierKey][handler.mainKey].indexOf(handler)
                    if (index > -1) {
                        this.handlersBin[handler.modifierKey][handler.mainKey].splice(index, 1)
                    }
                }
            }
        }
    }

    private onKeyDownHandler = (e: React.KeyboardEvent<any>) => {
        const keyBlock = this.getKeyBlock(e);
        const isInput = (e.target as Element).tagName in ["INPUT", "TEXTAREA"] || (e.target as Element).getAttribute("contentEditable")
        const key = this.getCharByKeyCode(e.keyCode)
        if (keyBlock) {
            const keyMapItem = keyBlock[key] && keyBlock[key][0]
            if (keyMapItem && (keyMapItem.allowInInput || !isInput)) {
                keyMapItem.handler(e)
            }
        }
    }

    private getKeyBlock(e: React.KeyboardEvent<any>): {[key: string]: Array<HandlerBag>} {
        if (e.altKey && e.shiftKey) {
            return this.handlersBin["alt+shift"]
        } else if (e.altKey) {
            return this.handlersBin["alt"]
        } else if (e.ctrlKey) {
            return Object.assign({}, this.handlersBin["ctrl"], this.handlersBin["meta|ctrl"])
        } else if (e.metaKey) {
            return Object.assign({}, this.handlersBin["meta"], this.handlersBin["meta|ctrl"])
        }
        return this.handlersBin["-"]
    }

    private getCharByKeyCode(keyCode: number): string {
        const keyboardMap = [
            "", // [0]
            "", // [1]
            "", // [2]
            "cancel", // [3]
            "", // [4]
            "", // [5]
            "help", // [6]
            "", // [7]
            "back_space", // [8]
            "tab", // [9]
            "", // [10]
            "", // [11]
            "clear", // [12]
            "enter", // [13]
            "enter_special", // [14]
            "", // [15]
            "shift", // [16]
            "control", // [17]
            "alt", // [18]
            "pause", // [19]
            "caps_lock", // [20]
            "kana", // [21]
            "eisu", // [22]
            "junja", // [23]
            "final", // [24]
            "hanja", // [25]
            "", // [26]
            "escape", // [27]
            "convert", // [28]
            "nonconvert", // [29]
            "accept", // [30]
            "modechange", // [31]
            "space", // [32]
            "page_up", // [33]
            "page_down", // [34]
            "end", // [35]
            "home", // [36]
            "left", // [37]
            "up", // [38]
            "right", // [39]
            "down", // [40]
            "select", // [41]
            "print", // [42]
            "execute", // [43]
            "printscreen", // [44]
            "insert", // [45]
            "delete", // [46]
            "", // [47]
            "0", // [48]
            "1", // [49]
            "2", // [50]
            "3", // [51]
            "4", // [52]
            "5", // [53]
            "6", // [54]
            "7", // [55]
            "8", // [56]
            "9", // [57]
            "colon", // [58]
            "semicolon", // [59]
            "less_than", // [60]
            "equals", // [61]
            "greater_than", // [62]
            "question_mark", // [63]
            "at", // [64]
            "a", // [65]
            "b", // [66]
            "c", // [67]
            "d", // [68]
            "e", // [69]
            "f", // [70]
            "g", // [71]
            "h", // [72]
            "i", // [73]
            "j", // [74]
            "k", // [75]
            "l", // [76]
            "m", // [77]
            "n", // [78]
            "o", // [79]
            "p", // [80]
            "q", // [81]
            "r", // [82]
            "s", // [83]
            "t", // [84]
            "u", // [85]
            "v", // [86]
            "w", // [87]
            "x", // [88]
            "y", // [89]
            "z", // [90]
            "os_key", // [91] windows key (windows) or command key (mac)
            "", // [92]
            "context_menu", // [93]
            "", // [94]
            "sleep", // [95]
            "num0", // [96]
            "num1", // [97]
            "num2", // [98]
            "num3", // [99]
            "num4", // [100]
            "num5", // [101]
            "num6", // [102]
            "num7", // [103]
            "num8", // [104]
            "num9", // [105]
            "num*", // [106]
            "num+", // [107]
            "num/", // [108]
            "num-", // [109]
            "num.", // [110]
            "num/", // [111]
            "f1", // [112]
            "f2", // [113]
            "f3", // [114]
            "f4", // [115]
            "f5", // [116]
            "f6", // [117]
            "f7", // [118]
            "f8", // [119]
            "f9", // [120]
            "f10", // [121]
            "f11", // [122]
            "f12", // [123]
            "f13", // [124]
            "f14", // [125]
            "f15", // [126]
            "f16", // [127]
            "f17", // [128]
            "f18", // [129]
            "f19", // [130]
            "f20", // [131]
            "f21", // [132]
            "f22", // [133]
            "f23", // [134]
            "f24", // [135]
            "", // [136]
            "", // [137]
            "", // [138]
            "", // [139]
            "", // [140]
            "", // [141]
            "", // [142]
            "", // [143]
            "num_lock", // [144]
            "scroll_lock", // [145]
            "win_oem_fj_jisho", // [146]
            "win_oem_fj_masshou", // [147]
            "win_oem_fj_touroku", // [148]
            "win_oem_fj_loya", // [149]
            "win_oem_fj_roya", // [150]
            "", // [151]
            "", // [152]
            "", // [153]
            "", // [154]
            "", // [155]
            "", // [156]
            "", // [157]
            "", // [158]
            "", // [159]
            "circumflex", // [160]
            "exclamation", // [161]
            "double_quote", // [162]
            "hash", // [163]
            "dollar", // [164]
            "percent", // [165]
            "ampersand", // [166]
            "underscore", // [167]
            "open_paren", // [168]
            "close_paren", // [169]
            "asterisk", // [170]
            "plus", // [171]
            "pipe", // [172]
            "hyphen_minus", // [173]
            "open_curly_bracket", // [174]
            "close_curly_bracket", // [175]
            "tilde", // [176]
            "", // [177]
            "", // [178]
            "", // [179]
            "", // [180]
            "volume_mute", // [181]
            "volume_down", // [182]
            "volume_up", // [183]
            "", // [184]
            "", // [185]
            "semicolon", // [186]
            "equals", // [187]
            "comma", // [188]
            "minus", // [189]
            "period", // [190]
            "slash", // [191]
            "back_quote", // [192]
            "", // [193]
            "", // [194]
            "", // [195]
            "", // [196]
            "", // [197]
            "", // [198]
            "", // [199]
            "", // [200]
            "", // [201]
            "", // [202]
            "", // [203]
            "", // [204]
            "", // [205]
            "", // [206]
            "", // [207]
            "", // [208]
            "", // [209]
            "", // [210]
            "", // [211]
            "", // [212]
            "", // [213]
            "", // [214]
            "", // [215]
            "", // [216]
            "", // [217]
            "", // [218]
            "open_bracket", // [219]
            "back_slash", // [220]
            "close_bracket", // [221]
            "quote", // [222]
            "", // [223]
            "meta", // [224]
            "altgr", // [225]
            "", // [226]
            "win_ico_help", // [227]
            "win_ico_00", // [228]
            "", // [229]
            "win_ico_clear", // [230]
            "", // [231]
            "", // [232]
            "win_oem_reset", // [233]
            "win_oem_jump", // [234]
            "win_oem_pa1", // [235]
            "win_oem_pa2", // [236]
            "win_oem_pa3", // [237]
            "win_oem_wsctrl", // [238]
            "win_oem_cusel", // [239]
            "win_oem_attn", // [240]
            "win_oem_finish", // [241]
            "win_oem_copy", // [242]
            "win_oem_auto", // [243]
            "win_oem_enlw", // [244]
            "win_oem_backtab", // [245]
            "attn", // [246]
            "crsel", // [247]
            "exsel", // [248]
            "ereof", // [249]
            "play", // [250]
            "zoom", // [251]
            "", // [252]
            "pa1", // [253]
            "win_oem_clear", // [254]
            "" // [255]
        ]

        return keyboardMap[keyCode]
    }


}

