import * as DateHelper from "src/lib/utils/date"
import {Globalize} from "globalize"
const MessageFormat: any = require("messageformat")
import {
    CurrencyFormatter,
    NumberFormatter,
    DateFormatter,
    RelativeTimeFormatter,
    UnitFormatter
} from "./formatters"

import {spaceExp} from "src/lib/utils/text"
import {IntlReplacer} from "src/lib/utils/intl/IntlReplacer"

export interface NameAliasesData {
    me?: string[],
    normalizedLetters?: string[],
    names?: Array<Array<string>>
}

export const PERSONAL_NAME_ORDER = ["lastName", "firstName", "middleName"]

export type MessageDescriptor = {id: string, defaultMessage: string}

export class Intl {
    private _currency: CurrencyFormatter
    private _date: DateFormatter
    private _unit: UnitFormatter
    private _number: NumberFormatter
    private _relativeTime: RelativeTimeFormatter
    private _globalReplacement: void | {
        search: RegExp,
        replacement: string
    }
    private _intlReplacer: IntlReplacer

    constructor(
        public locale: string,
        private g: Globalize,
        private _nameAliasesData: NameAliasesData,
    ) {
    }

    public setGlobalReplacement(search: RegExp, replacement: string) {
        this._globalReplacement = {search, replacement}
    }

    public setIntlReplacer(replacer: IntlReplacer) {
        this._intlReplacer = replacer
    }

    public get currency() {
        return this._currency || (this._currency = new CurrencyFormatter(this.g))
    }

    public get date() {
        return this._date || (this._date = new DateFormatter(this.g))
    }

    public get relativeTime() {
        return this._relativeTime || (this._relativeTime = new RelativeTimeFormatter(this.g))
    }

    public get unit() {
        return this._unit || (this._unit = new UnitFormatter(this.g))
    }

    public get number() {
        return this._number || (this._number = new NumberFormatter(this.g))
    }

    public messageFormatter(descriptor: void | MessageDescriptor): Globalize.MessageFormatter {
        if (!descriptor) {
            const error = new Error("Unknown message received for translation")
            if (process.env.NODE_ENV === "development") {
                throw error;
            } else {
                console.error(error)
                descriptor = {
                    id: "???",
                    defaultMessage: "???"
                }
            }
        }

        if (this._intlReplacer?.check(descriptor.id)) {
            descriptor = this._intlReplacer.getReplacingDescriptor(descriptor.id)
        }

        const formatter = this.g.messageFormatter && this.g.messageFormatter(descriptor.id) ||
            new MessageFormat(this.locale).compile(descriptor.defaultMessage)
        if (this._globalReplacement) {
            const _globalReplacement = this._globalReplacement
            return values => formatter(values).replace(
                _globalReplacement.search,
                _globalReplacement.replacement
            )
        } else {
            return formatter
        }
    }

    public formatMessage = (descriptor: {id: string, defaultMessage: string}, variables?: {}): string => {
        try {
            return this.messageFormatter(descriptor)(variables)
        } catch (e) {
            if (descriptor) {
                throw new Error(`${e} (${descriptor.id})`)
            }
            throw e
        }
    }

    public getMeAliases(): string[] {
        return this._nameAliasesData.me || []
    }

    public getNormalizationLetters(): string[] {
        return this._nameAliasesData.normalizedLetters || []
    }

    public getNameAliases(): Array<Array<string>> {
        return this._nameAliasesData.names || []
    }

    /**
     * Возвращает порядок следования полей с ФИО для локалей
     * @returns string[]
     */
    public getPersonalNameOrder() {
        return PERSONAL_NAME_ORDER
    }

    public formatMoney(value: number, currency: string) {
        const formatter = this.currency.symbol(currency)
        const symbol = this.currencySymbol(currency)
        const money = value % 1 ? this.number.money()(value) : formatter(value).replace(symbol, "")
        return money.trim() + "\xa0" + symbol
    }

    public currencySymbol(abbr: string, spaceBefore?: boolean) {
        const formatter = this.currency.symbol(abbr)
        return (spaceBefore ? "\xa0" : "")
            + formatter(0).replace(this.number.decimal()(0), "").replace(spaceExp, "")
    }

    public percentSymbol(spaceBefore?: boolean) {
        return (spaceBefore ? "\xa0" : "") + "\x25"
    }

    public formatPercent(value: number) {
        return value.toString().trim() + this.percentSymbol(true)
    }
}

/**
 * Конвертирует количество байт в читабельный вид
 */
export const digitalSizeHr = (intl: Intl) => (value: number) => {
    const i = Math.floor(Math.log(value) / Math.log(1024))
    if (!value) {
        return intl.unit.byteShort()(0)
    }
    return [
        intl.unit.byteShort,
        intl.unit.kilobyteShort,
        intl.unit.megabyteShort,
        intl.unit.gigabyteShort,
        intl.unit.terabyteShort,
    ][i]()(Number((value / Math.pow(1024, i)).toFixed(2)))
}

export type DateFormatterFunction = (value: Date) => string
/**
 * В качестве формата в smartDateHr можно передавать либо формат из DateFormatter.KnownFormat, либо коллбэк DateFormatterFunction
 * Лучше этого не делать напрямую, а использовать готовые высокоуровневые функции из dateHelper.ts
 */
export type SmartDateHrFormat = DateFormatterFunction | string
export interface SmartDateHrOptions {
    // Что показывать, когда дата попадает в относительно близкий период
    // Срабатывают в указанном здесь порядке (т.е. формат tomorrow сработает раньше sameWeek, если переданная дата - завтра).
    sameMinute?: SmartDateHrFormat
    sameHour?: SmartDateHrFormat
    yesterday?: SmartDateHrFormat
    today?: SmartDateHrFormat
    tomorrow?: SmartDateHrFormat
    sameWeek?: SmartDateHrFormat
    sameYear?: SmartDateHrFormat
    other?: SmartDateHrFormat
}
const isDateFormatterFunction = (a: any): a is DateFormatterFunction => {
    return typeof a === "function"
}
const isString = (a: any): a is string => {
    return typeof a === "string"
}
const toSmartDateHrFormat = (value: Date, format: SmartDateHrFormat) => {
    // Если передали коллбэк, то просто вызываем его
    if (isDateFormatterFunction(format)) {
        return format(value)
    }
    // Если строка, то просто выводим строку
    if (isString(format)) {
        return format
    }
    throw new Error(`Unexpected SmartDateHrFormat: "${format}"`)
}

/**
 * Умное отображение дат, в зависимости от попадания даты в какой-то временной промежуток относительно текущей.
 * В качестве формата даты может принимать коллбек.
 * @param value
 * @param options
 */
export const smartDateHr = (value: Date, options: SmartDateHrOptions = {}) => {
    // Сначала пытаемся отыскать подходящий формат из переданных
    if (options.sameMinute && DateHelper.isSameMinute(value)) {
        return toSmartDateHrFormat(value, options.sameMinute)
    }

    if (options.sameHour && DateHelper.isSameHour(value)) {
        return toSmartDateHrFormat(value, options.sameHour)
    }

    if (options.yesterday && DateHelper.isYesterday(value)) {
        return toSmartDateHrFormat(value, options.yesterday)
    }

    if (options.today && DateHelper.isToday(value)) {
        return toSmartDateHrFormat(value, options.today)
    }

    if (options.tomorrow && DateHelper.isTomorrow(value)) {
        return toSmartDateHrFormat(value, options.tomorrow)
    }

    if (options.sameWeek && DateHelper.isSameWeek(value)) {
        return toSmartDateHrFormat(value, options.sameWeek)
    }

    if (options.sameYear && DateHelper.isThisYear(value)) {
        return toSmartDateHrFormat(value, options.sameYear)
    }

    if (options.other) {
        return toSmartDateHrFormat(value, options.other)
    }
    console.error(`Failed to find smartDateHr format for date: "${value}"`)
    return value.toString()
}

/**
 * Форматирует период времени в секундах в строку вида "1 д 2 ч",
 * Всегда возвращает две старших единицы измерения, причем исключает вывод после двух пропущенных единиц.
 * Т.е. не будет "2 нед 3с", а "1 мес 3 нед" - пожалуйста
 * TODO написать тесты
 * @param form
 * @param unitsFormatter
 */

export const timePeriodHr = (form: "Short" | "Long", unitsFormatter: "default" | "working" | "hoursMinutes") =>
    (intl: Intl) => (seconds: number, maxParts = 2): string => {
        interface UnitDescriptor {
            funcName: UnitFormatter.KnownFormat
            divider: number
        }
        const timeFormatterUnits: {[formatterName: string]: UnitDescriptor[]} = {
            default: [
                {
                    funcName: "year" + form as UnitFormatter.KnownFormat,
                    divider: 86400 * 365
                },
                {
                    funcName: "month" + form as UnitFormatter.KnownFormat,
                    divider: 86400 * 30
                },
                {
                    funcName: "week" + form as UnitFormatter.KnownFormat,
                    divider: 86400 * 7
                },
                {
                    funcName: "day" + form as UnitFormatter.KnownFormat,
                    divider: 86400
                },
                {
                    funcName: "hour" + form as UnitFormatter.KnownFormat,
                    divider: 3600
                },
                {
                    funcName: "minute" + form as UnitFormatter.KnownFormat,
                    divider: 60
                },
                {
                    funcName: "second" + form as UnitFormatter.KnownFormat,
                    divider: 1
                },
            ],
            working: [
                {
                    funcName: "year" + form as UnitFormatter.KnownFormat,
                    divider: DateHelper.getWorkSecondsByUnit(DateHelper.Units.YEARS)
                },
                {
                    funcName: "month" + form as UnitFormatter.KnownFormat,
                    divider: DateHelper.getWorkSecondsByUnit(DateHelper.Units.MONTHS)
                },
                {
                    funcName: "week" + form as UnitFormatter.KnownFormat,
                    divider: DateHelper.getWorkSecondsByUnit(DateHelper.Units.WEEKS)
                },
                {
                    funcName: "day" + form as UnitFormatter.KnownFormat,
                    divider: DateHelper.getWorkSecondsByUnit(DateHelper.Units.DAYS)
                },
                {
                    funcName: "hour" + form as UnitFormatter.KnownFormat,
                    divider: DateHelper.getWorkSecondsByUnit(DateHelper.Units.HOURS)
                },
                {
                    funcName: "minute" + form as UnitFormatter.KnownFormat,
                    divider: DateHelper.getWorkSecondsByUnit(DateHelper.Units.MINUTES)
                },
                {
                    funcName: "second" + form as UnitFormatter.KnownFormat,
                    divider: DateHelper.getWorkSecondsByUnit(DateHelper.Units.SECONDS)
                },
            ],
            hoursMinutes: [
                {
                    funcName: "hour" + form as UnitFormatter.KnownFormat,
                    divider: 3600
                },
                {
                    funcName: "minute" + form as UnitFormatter.KnownFormat,
                    divider: 60
                },
            ]
        }

        const units = timeFormatterUnits[unitsFormatter]
        let residueSeconds = seconds

        return units.filter(unit => seconds >= unit.divider).map(unit => {
            const part = Math.floor(residueSeconds / unit.divider)
            residueSeconds %= unit.divider
            if (part) {
                return intl.unit.getByName(unit.funcName)(part)
            }
            return null
        }).filter((v: any) => v !== null).slice(0, maxParts).join(" ") // TODO Detect rtl and reverse array
    }


/**
 * Форматирует период времени в секундах в строку вида "1 д 2 ч",
 * отбрасывает минуты при количестве дней > 0
 * @param locale
 */
export const timePeriodShortHr = timePeriodHr("Short", "default")

/**
 * Форматирует период времени в секундах в строку вида "1 день 2 часа"
 * @param locale
 */
export const timePeriodLongHr = timePeriodHr("Long", "default")

/**
 * Форматирует период времени в секундах в строку вида "1 д 2 ч",
 * использует псевдо рабочий календать (8 часов в день, 5 дней в неделе и т.д.),
 * отбрасывает минуты при количестве дней > 0
 * @param locale
 */
export const workingTimePeriodShortHr = timePeriodHr("Short", "working")

/**
 * Форматирует период времени в секундах в строку вида "1 день 2 часа"
 * использует псевдо рабочий календать (8 часов в день, 5 дней в неделе и т.д.),
 * @param locale
 */
export const workingTimePeriodLongHr = timePeriodHr("Long", "working")

/**
 * Форматирует период времени в секундах в строку вида "1 час 2 минуты"
 * Только часы и минуты
 * @param locale
 */
export const hoursMinutesPeriodLongHr = timePeriodHr("Long", "hoursMinutes")
export const hoursMinutesPeriodShortHr = timePeriodHr("Short", "hoursMinutes")


export const shortNumber = (number: number | void, thousands?: boolean) => {
    if (number || number === 0) {
        return number < 1000 ? number : Math.floor(number / (thousands ? 1000 : 100)) / (thousands ? 1 : 10) + "k"
    }
}



