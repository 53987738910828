import {Intl} from "src/lib/utils/intl"
import * as Api from "src/lib/entities/api"
import * as Collections from "src/lib/collections"
import * as dateFunctions from "src/lib/utils/date"
import * as DateHelper from "src/lib/utils/intl/dateHelper"

const messages: any = require("./messages.yml")

/**
 * Проверяет, попадает ли конкретная дата в расписание.
 * @todo доделать
 * @deprecated не работает
 * @param day
 * @param schedule
 * @returns {boolean}
 */
export const dateIsInSchedule = (day: Date, schedule: Api.Schedule) => {
    // TODO функция не дописана и не протестирована!!
    if (1) {
        return false
    }
    const startDate = Api.isDateOnly(schedule.startDate) ? DateHelper.DateOnlyToDate(schedule.startDate) : schedule.startDate

    const endDate = DateHelper.DateOnlyToDate(schedule.endDate)


    // Неполное расписание
    if (!startDate || !schedule.interval || !schedule.each) {
        return false
    }

    // Дата до начала расписания
    if (startDate > day) {
        return false
    }

    // Дата за гранью окончания расписания
    if (endDate && day > endDate) {
        return false
    }

    // Не тот день недели
    if (schedule.weekDays && schedule.weekDays.indexOf(day.getDay()) === -1) {
        return false
    }

    // Не то число месяца
    if (schedule.day && schedule.day !== day.getDate()) {
        return false
    }

    // Не тот месяц
    if (schedule.month && schedule.month !== day.getMonth()) {
        return false
    }

    // Только по будням, а нынче - выходной.
    if (schedule.workDaysOnly && [6, 0].indexOf(day.getDay()) !== -1) {
        return false
    }

    // TODO проверить номер недели в месяце.
    // Остается проверить количество прошедших повторений, и each, в остальном всё ок.
    let repeatedBefore = 0

    let daysPassed = dateFunctions.diff(startDate, day)
    switch (schedule.interval) {
        case Api.Schedule.Interval.day:
            if (schedule.workDaysOnly) {
                // Если дни только рабочие, то надо вычесть все выходные
                daysPassed = daysPassed - (Math.floor(daysPassed / 7) * 2)

                // Удаляем предыдущие выходные (хз, что это значит)
                if (startDate.getDay() - day.getDay() > 1) {
                    daysPassed = daysPassed - 2
                }
                // Если начали в воскресенье, но закончили до субботы - вычитаем начальный день
                if (startDate.getDay() === 0 && day.getDay() !== 6) {
                    daysPassed = daysPassed - 1
                }
                // Если начали закончили в субботу, и начали до воскресенья - вычитаем конец.
                if (startDate.getDay() !== 0 && day.getDay() === 6) {
                    daysPassed = daysPassed - 1
                }
            }

            // Каждые n-дней
            if ((daysPassed - 1) % schedule.each !== 0) {
                // Если остаток от этого деления !=0, значит мы не попали в each дней.
                return false
            }

            repeatedBefore = daysPassed / schedule.each
            break
        case Api.Schedule.Interval.week:
            if (!schedule.weekDays.length) {
                // Невалидное расписание. Каждые n недель хз в какие дни
                return false
            }

            // Считаем количество совпадений в расписании для целых недель.
            let weeksPassed = Math.floor(daysPassed / 7)
            repeatedBefore = Math.floor(weeksPassed / schedule.each) * schedule.weekDays.length

            // Для первой недели докидываем остаток, только при each=1 и неполной первой неделе.
            if (startDate.getDay() > 1 && schedule.each === 1) {
                schedule.weekDays.forEach((weekDay) => {
                    if (startDate.getDay() <= weekDay) {
                        repeatedBefore++
                    }
                })
            }

            // Остаток с последней недели может попасть только если
            // TODO implement


            break
        case Api.Schedule.Interval.month:
            // TODO implement
            break
        case Api.Schedule.Interval.year:
            // TODO implement
            break
    }

    // Уже выполнили все повторения
    if (schedule.repetitions && repeatedBefore >= schedule.repetitions) {
        return false
    }

    return true
}

/**
 * Форматирует Api.Schedule.weekDay в человеко-читабельный формат
 * @param intl
 * @param weekDays
 * @returns {string}
 */
export const formatWeekDays = (intl: Intl, weekDays: Collections.List<number>): string => {
    // TODO может вернем с бэкенда массивчик лучше?
    const weekDaysTranslated = weekDays.map((weekDay: number) =>
        intl.formatMessage(messages["weekDayShort"], {
            weekDay: weekDay
        })
    )

    if (weekDaysTranslated.length === 1) {
        return weekDaysTranslated.first()
    } else {
        let lastWeekDay = weekDaysTranslated.last()
        let result = weekDaysTranslated.slice(0, -1).join(", ")
        result += ` ${intl.formatMessage(messages["and"])} ${lastWeekDay}`
        return result
    }
}

export const getScheduleHowText = (intl: Intl, schedule: Api.Schedule) => {
    switch (schedule.interval) {
        case Api.Schedule.Interval.day:
            // Каждые # дней
            if (schedule.workDaysOnly) {
                // рабочих
                return intl.formatMessage(messages["repeatEveryWorkDay"], {count: schedule.each})
            }

            // любых
            return intl.formatMessage(messages["repeatEveryDay"], {count: schedule.each})
        case Api.Schedule.Interval.week:
            // Каждые # недель по вт, ср и пт
            return intl.formatMessage(messages["repeatEveryWeek"], {
                count: schedule.each,
                weekDays: formatWeekDays(intl, schedule.weekDays)
            })
        case Api.Schedule.Interval.month:
            // Каждые # месяцев
            if (schedule.weekDays && schedule.weekDays.length && schedule.week) {
                // 1/2/3/4/последний вторник
                return intl.formatMessage(messages["repeatEveryMonthOnWeekDay"], {
                    count: schedule.each,
                    weekDays: intl.formatMessage(messages["repeatNthWeekDay"], {
                        count: schedule.week,
                        weekDay: schedule.weekDays.first()
                    })
                })
            }

            // # числа
            return intl.formatMessage(messages["repeatEveryMonthOnDay"], {
                count: schedule.each,
                day: intl.formatMessage(messages["repeatNthDay"], {count: schedule.day || 1})
            })
        case Api.Schedule.Interval.year:
            // Каждые # месяцев по вт
            if (schedule.weekDays && schedule.weekDays.length && schedule.week) {
                const weekDays = intl.formatMessage(messages["repeatNthWeekDay"], {
                    count: schedule.week,
                    weekDay: schedule.weekDays.first()
                })

                // 1/2/3/4/последний вторник февраля
                return intl.formatMessage(messages["repeatEveryYearOnWeekDayAndMonth"], {
                    count: schedule.each,
                    weekDays: weekDays,
                    month: DateHelper.getMonthLong(intl, DateHelper.getDateByMonthNumber(schedule.month))
                })
            }

            // # числа
            return intl.formatMessage(messages["repeatEveryYearOnDate"], {
                count: schedule.each,
                dateAndMonth: DateHelper.getDayAndMonthFromDate(intl, DateHelper.getDateByDayAndMonth(schedule.day, schedule.month)),
            })
        default:
            throw Error("unhandled interval for schedule: " + schedule.interval)
    }
}

export const getScheduleStartText = (intl: Intl, schedule: Api.Schedule) => {
    const startDate = schedule.startDate
    return DateHelper.getFormattedDateFromDatePicker(intl, startDate)
}


export const getScheduleEndText = (intl: Intl, schedule: Api.Schedule) => {
    if (schedule.endDate) {
        return intl.formatMessage(messages["until"]) + " " + DateHelper.getCasualDate(intl, DateHelper.DateOnlyToDate(schedule.endDate))
    }

    if (schedule.repetitions) {
        return intl.formatMessage(messages["nTimes"], {count: schedule.repetitions})
    }

    return ""
}

/**
 * Возвращает текстовое представление расписания
 * @param intl
 * @param schedule
 * @returns {string}
 */
export const getScheduleText = (intl: Intl, schedule: Api.Schedule) => {
    return getScheduleHowText(intl, schedule) + " " + getScheduleEndText(intl, schedule)
}
