declare const window: Window & {
    informer_limit_15: boolean,
}

export enum BubbleType {
    UnitedNotifications,
    Approvals,
    Burnings,
    Notifications,
    Chat,
    Favorites,
    Feedback,
    ProductQuest
}

export enum CounterTypes {
    Notifications,
    Approvals,
    Burnings,
    Favorites
}

export interface BubbleSize {
    width: number
    height: number
}

export const listNames: {[type: number]: string} = {
    [BubbleType.UnitedNotifications]: "informerUnitedNotifications",
    [BubbleType.Approvals]: "informerApprovals",
    [BubbleType.Burnings]: "informerBurnings",
    [BubbleType.Notifications]: "informerNotifications",
    [BubbleType.Favorites]: "informerFavorites",
    [BubbleType.Chat]: "chatTopics"
}

export const endPoints: {[type: number]: string} = {
    [BubbleType.UnitedNotifications]: "/api/v3/notification/united",
    [BubbleType.Approvals]: "/api/v3/approval",
    [BubbleType.Burnings]: "/api/v3/burning",
    [BubbleType.Notifications]: "/api/v3/notification",
    [BubbleType.Favorites]: "/api/v3/favorite",
    [BubbleType.Chat]: "/api/v3/topic"
}

export const countersIds: {[type: number]: string} = {
    [BubbleType.Approvals]: "approval",
    [BubbleType.Burnings]: "burnings",
    [BubbleType.Notifications]: "notifications",
    [BubbleType.Favorites]: "favourites",
    [BubbleType.Chat]: "chat"
}

export const informerBubbleWidth = "informerBubbleWidth"
export const informerBubbleHeight = "informerBubbleHeight"
export const informerSoundVolume = "setting.sound.notification"
export const informerShowReadNotifications = "setting.ignor.read.notifications"
export const chatSoundDisable = "setting.chat.global_sound.disable"
export const sendOnCtrlEnterDisable = "setting.chat.send_on_ctrl_enter.disable"
export const videochatHintShown = "setting.chat.videochat_hint_shown"
export const mailGroupingEnable = "setting.mail.grouping.enable"

export const approvalFetchName = "approvalFetch"

export const notificationLoadLimit = window.informer_limit_15 ? 15 : void 0;
