export type SelectionPosition = ClientRect & {scrollTop: number, [index: string]: number}

export function convertClientRectToPageRect(clientRect: ClientRect): SelectionPosition {
    const body = document.body
    const docElem = document.documentElement

    const scrollTop = window.pageYOffset || docElem.scrollTop || body.scrollTop
    const scrollLeft = window.pageXOffset || docElem.scrollLeft || body.scrollLeft

    const clientTop = docElem.clientTop || body.clientTop || 0
    const clientLeft = docElem.clientLeft || body.clientLeft || 0
    const clientWidth = docElem.clientWidth || body.clientWidth || 0
    const clientHeight = docElem.clientHeight || body.clientHeight || 0

    const top  = clientRect.top + scrollTop - clientTop
    const left = clientRect.left + scrollLeft - clientLeft
    const right = clientWidth - left
    const bottom = clientHeight - top

    return Object.assign({}, clientRect, {
            top: Math.round(top),
            left: Math.round(left),
            right: Math.round(right),
            bottom: Math.round(bottom),
            scrollTop: scrollTop,
            height: clientRect.height,
            width: clientRect.width
        }
    )
}

export function getAndNormalizeBoundClientRect(node: HTMLElement): ClientRect {
    const clientRect = node.getBoundingClientRect();
    return {
        bottom: Number(clientRect.bottom.toFixed()),
        height: Number(clientRect.height.toFixed()),
        left: Number(clientRect.left.toFixed()),
        right: Number(clientRect.right.toFixed()),
        top: Number(clientRect.top.toFixed()),
        width: Number(clientRect.width.toFixed()),
    }
}


export function getSelection(): Selection {
    // без этого хака не пашет document.selection, не компилируется
    const doc = document as any

    if (window.getSelection) {
        return window.getSelection();
    } else if (document.getSelection) {
        return document.getSelection();
    }

    return doc.selection
}

export function getHtmlFromSelection(selection: Selection) {
    let html = ""
    if (selection.rangeCount) {
        const container = document.createElement("div")
        for (let i = 0; i < selection.rangeCount; i++) {
            container.appendChild(selection.getRangeAt(i).cloneContents());
        }
        html = container.innerHTML
    }
    return html
}

/**
 * Возвращает выбранный на данный момент текст
 * @return string
 */
export function getSelectedText() {
    const selection = getSelection()
    if (!selection || selection.isCollapsed) {
        return ""
    }

    let result = selection.toString()
    if (result) {
        return result
    }

    // Хак, чтобы обойти ошибку компиляции
    const slctn = selection as any
    if (!slctn.createRange) {
        return ""
    }
    return slctn.createRange().text as string
}

/**
 * Возвращает координаты и размеры текущего выделения на экране
 * @returns {SelectionPosition}
 */
export function getSelectionPosition(): SelectionPosition {
    const selection = getSelection()
    return convertClientRectToPageRect(selection.getRangeAt(0).getBoundingClientRect())
}

/**
 * Убирает текущие выделения с монитора
 */
export function clearSelection() {
    const selection = getSelection()
    if (selection.empty) {
        selection.empty()
    } else if (selection.removeAllRanges) {
        selection.removeAllRanges()
    }
}

export function scrollToNodeInContainerIfNeeded(container: HTMLElement, node: HTMLElement) {
    if (!node || !container) {
        return
    }
    const contRect = container.getBoundingClientRect()
    const nodeRect = node.getBoundingClientRect()
    const bottomDelta = container.clientHeight + contRect.top - nodeRect.top
    const topDelta = bottomDelta - container.clientHeight
    if (topDelta > 0) {
        container.scrollTop = container.scrollTop - topDelta
    } else if (bottomDelta < node.clientHeight) {
        container.scrollTop = container.scrollTop + node.clientHeight - bottomDelta
    }
}

export function getScrollContainer(node: HTMLElement) {
    let container: HTMLElement | null = node.parentElement
    if (container === null) {
        return null
    }
    do {
        const style = window.getComputedStyle(container)
        if (style.overflow === "auto" || style.overflowY === "auto" || style.overflow === "scroll" || style.overflowY === "scroll") {
            return container
        } else {
            container = container.parentElement
            if (container === null) {
                return null
            }
        }
    } while (container.tagName !== "HTML")
    return container
}
