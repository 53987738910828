import {autobind} from "core-decorators"
import {computed, observable, action} from "mobx"
import {inject} from "src/lib/utils/inject"
import {MainMenuStore} from "src/bums/common/CMainMenu/MainMenuStore"
import {Sticky, StickyPosition, StickyBehavior, StickyOrientation} from "./types"

function randomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min)) + min
}

@autobind
export class StickyStore {

    @observable
    public smartCards = 0

    public stickyComponents = observable.map<string, Sticky>()

    constructor(@inject(MainMenuStore) private mainMenuStore: MainMenuStore) {}

    @computed
    private get stickyComponentsEntries() {
        return Array.from(this.stickyComponents.entries())
    }

    @computed
    public get isAllSmartDummyTop() {
        return this.stickyComponentsEntries.filter(([, sticky]) => {
            return sticky.position.behavior === StickyBehavior.SMART && sticky.position.orientation === StickyOrientation.TOP
        }).length === this.smartCards && this.smartCards > 1
    }

    @computed
    public get minTopOffset() {
        const firstElement = this.stickyComponentsEntries.sort((a, b) => a[1].level - b[1].level)

        if (firstElement.length > 0) {
            return firstElement[0][1].position.height
        }

        return this.mainMenuStore.potentialHeight
    }

    @action
    public addSmartCard () {
        this.smartCards++
    }

    @action
    public removeSmartCard () {
        this.smartCards--
    }

    @action
    public setStickyComponent(position: StickyPosition, fixLevel: number): string {
        let level = fixLevel
        if (fixLevel === void 0) {
            const stickyMap = new Map(
                this.stickyComponentsEntries.filter(([, _sticky]) => {
                    return this.compareStickyComponents(_sticky.position, position)
                })
            )

            let maxLevelStickyId: string

            stickyMap.forEach((_sticky, index) => {
                if (maxLevelStickyId && stickyMap.has(maxLevelStickyId)) {
                    if (stickyMap.get(maxLevelStickyId).level < _sticky.level) {
                        maxLevelStickyId = index
                    }
                } else {
                    maxLevelStickyId = index
                }
            })

            level = stickyMap.has(maxLevelStickyId) ? stickyMap.get(maxLevelStickyId).level + 1 : 0
        }

        const id = String(randomInt(0, 10000000))
        this.stickyComponents.set(id, {position, level})
        return id
    }

    @action
    public updateStickyComponent(position: StickyPosition, id: string) {
        if (this.stickyComponents.has(id)) {
            const sticky = this.stickyComponents.get(id)
            this.stickyComponents.set(id, Object.assign({}, sticky, {position, level: sticky.level}))
        }
    }

    @action
    public deleteStickyComponent(start: string) {
        this.stickyComponents.delete(start)
    }

    @action
    public clearComponents() {
        this.stickyComponents.clear()
    }

    public fullStickyHeight(stickyId: string, stickyPosition: StickyPosition) {
        let top = 0

        if (typeof stickyId === "string") {
            let level: number

            if (stickyId !== "-1") {
                level = this.stickyComponents.has(stickyId) && this.stickyComponents.get(stickyId).level
            }

            const filteredComponents = this.stickyComponentsEntries.filter(([, sticky]) => this.compareStickyComponents(
                sticky.position,
                stickyPosition ? stickyPosition : stickyId
            )).sort((a, b) => a[1].level - b[1].level)

            filteredComponents.forEach(([index, sticky]) => {
                if (
                    sticky.level < level
                    || stickyId === "-1"
                    || sticky.position.priority < stickyPosition.priority
                    || (
                        stickyPosition.behavior === StickyBehavior.SMART
                        && sticky.position.priority <= stickyPosition.priority
                        && stickyId !== index
                    )
                ) {
                    top += sticky.position.height
                }
            })

        } else {
            this.stickyComponents.forEach((sticky, index) => {
                top += sticky.position.height
            })
        }

        return top
    }

    public compareStickyComponents(compareSticky: StickyPosition, stickyPosition: string | StickyPosition) {
        let position: StickyPosition;
        if (stickyPosition && compareSticky) {
            if (typeof stickyPosition === "string") {
                position = this.stickyComponents.get(stickyPosition).position;
            } else {
                position = stickyPosition
            }
            return (
                !(
                    position.left < compareSticky.left
                    && position.left + position.width < compareSticky.left
                    || position.left > compareSticky.left + compareSticky.width
                )
                && position.priority >= compareSticky.priority
                && compareSticky.behavior !== StickyBehavior.SMART
                && compareSticky.orientation !== StickyOrientation.NONE
                && compareSticky.orientation !== StickyOrientation.MIDDLE
            )
        } else {
            return false
        }
    }

}
