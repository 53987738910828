import * as React from "react"
import classNames from "classnames/bind"
import {autobind} from "core-decorators"
import {CSSTransitionGroup} from "react-transition-group"
import {observer} from "mobx-react"
import {observable, action} from "mobx"
import * as Api from "src/lib/entities/api"
import {inject} from "src/lib/utils/inject"
import {UserSettingStore} from "src/bums/common/stores/UserSettingStore"
import {CLayoutStore} from "src/bums/common/userInterface/context"
import {CCardInnerWrapper} from "src/lib/components/CPageLayout/CPageLayout"
import {CPopoverMenu, CButton, CIconType, CSpinner, CFieldRow, Component, CAdapt} from "src/lib/components"

const style: any = classNames.bind(require("./CSmartCard.styl"))
const MENUCONTROL_MAX_HEIGHT = 500

interface CHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
    className?: string
    active?: boolean
    children?: React.ReactNode
    onToggle?: () => void
    noPadding?: boolean
    align?: "left" | "right"
    onContextMenu?: (event?: React.MouseEvent<HTMLElement>) => void
}

class CRow extends Component<{}, {}> {
    public render() {
        return <div className={style("row")}>
            {this.props.children}
        </div>
    }
}

class CTitle extends Component<{}, {}> {
    public render() {
        return <div className={style("title")}>
            {this.props.children}
        </div>
    }
}

const CHeader = observer((props: CHeaderProps) => {
    const {align, className, active, noPadding, onToggle, onContextMenu, children, ...divProps} = props
    return <div
        {...divProps}
        className={style(className, "header", align, {active, noPadding, cursorPointer: !!onToggle || !!onContextMenu})}
        onClick={onToggle}
        onContextMenu={onContextMenu}
    >
        {children}
    </div>
})

interface CContentProps {
    className?: string
    active?: boolean
    onClick?: React.MouseEventHandler<HTMLElement>
    dataName?: string
}

@observer
class CContent extends Component<CContentProps, {}> {
    render() {
        return <div
            className={style("content", this.props.className)}
            data-name={this.props.dataName ? this.props.dataName : "smart-card-content"}
            onClick={this.props.onClick}
        >
            {this.props.children}
        </div>
    }
}

interface CFooterProps {
    className?: string
    noPadding?: boolean
    align?: "left" | "right"
    children?: React.ReactNode
}

const CFooter = observer((props: CFooterProps) => {
    return <div className={style("footer", props.align, props.className, {noPadding: props.noPadding})}>
        {props.children}
    </div>
})

const CControlWrapper = observer((props: {children?: React.ReactNode, className?: string}) => {
    return <div className={style("controls", props.className)}>
            <CFieldRow width="auto" displayType="inlineItemSmMargin">
                {props.children}
            </CFieldRow>
        </div>

})

interface CMenuControlProps extends CPopoverMenu.Props {
    className?: string
    popoverClassName?: string
    title?: string
}

@observer
class CMenuControl extends Component<CMenuControlProps, {}> {
    public render() {
        return <CPopoverMenu
            closeOnItemClick={true}
            title={this.props.title}
            onHintRequest={this.props.onHintRequest}
            onHintHide={this.props.onHintHide}
            onOpen={this.props.onOpen}
            maxHeight={MENUCONTROL_MAX_HEIGHT}
        >
            {this.props.children}
        </CPopoverMenu>
    }
}

interface CollapserRenderCallback {
    (isCollapsed: boolean, toggle: (event?: React.MouseEvent<HTMLElement>) => void): JSX.Element | JSX.Element[]
}

interface CTogglerProps {
    id?: string,
    renderCallback: CollapserRenderCallback
    collapsed?: boolean
}

@observer
class CToggler extends Component<CTogglerProps, {}> {

    @inject(Api.Store)
    private apiStore: Api.Store

    @observable
    private collapsed = true

    private userSettingStore: UserSettingStore<boolean>

    @action
    componentWillMount() {
        if (this.props.id) {
            this.userSettingStore = new UserSettingStore<boolean>(
                this.apiStore,
                () => this.props.id,
                () => this.props.collapsed
            )
        } else {
            this.collapsed = this.props.collapsed
        }
    }

    @action
    private collapsedToogle = () => this.collapsed = !this.collapsed

    private toggleHandler = (event?: React.MouseEvent<HTMLElement>) => {
        if (event && (event.target as HTMLElement).closest(`.${style("controls")}`)) {
            return
        }
        if (this.props.id) {
            const userSetting = this.userSettingStore.get()
            if (userSetting.state === "fulfilled") {
                void this.userSettingStore.set(!userSetting.value)
            }
        } else {
            this.collapsedToogle()
        }
    }

    public render() {
        if (this.props.id) {
            const userSetting = this.userSettingStore.get()
            if (userSetting.state === "fulfilled") {
                return this.props.renderCallback(userSetting.value, this.toggleHandler)
            } else {
                return <CSpinner />
            }
        }

        return this.props.renderCallback(this.collapsed, this.toggleHandler)
    }
}

interface CLayoutTogglerProps {
    viewStore: CLayoutStore.Stores["viewStore"]
    renderCallback: CollapserRenderCallback
}

@observer
@autobind
class CLayoutToggler extends Component<CLayoutTogglerProps, {}> {

    private toggleHandler() {
        this.props.viewStore.setCollapsed(!this.props.viewStore.isCollapsed)
    }

    public render() {
        return this.props.renderCallback(this.props.viewStore.isCollapsed, this.toggleHandler)
    }
}


interface CCollapserProps {
    icon: CIconType,
    caption: string | JSX.Element,
    onToggle: () => void
    content?: JSX.Element
    maxWidth?: number | string
}

const CCollapser = observer((props: CCollapserProps) => {
    return <CSmartCard>
        <CSmartCard.Header noPadding={true}>
            <CButton
                name="collapseCard"
                type={CButton.Type.CARD}
                icon={props.icon}
                caption={props.caption}
                maxWidth={props.maxWidth}
                onClick={props.onToggle}
            >
                {props.content}
            </CButton>
        </CSmartCard.Header>
    </CSmartCard>
})

interface CLayoutWrapperProps {
    collapsed?: boolean
    isMainCard?: boolean
    title?: string
    className?: string
}

class CLayoutWrapper extends Component<CLayoutWrapperProps, {}> {
    public render() {
        return <CCardInnerWrapper collapsed={this.props.collapsed} className={this.props.className}>
            {this.props.children}
        </CCardInnerWrapper>
    }
}

export interface CMainCardHeaderProps {
    className?: string
    onContextMenu?: (event?: React.MouseEvent<HTMLElement>) => void
}

class CMainCardHeader extends Component<CMainCardHeaderProps, {}> {
    public render() {
        return <CSmartCard.Header
            className={style("CMainCardHeader", this.props.className)}
            onContextMenu={this.props.onContextMenu}
        >
            {this.props.children}
        </CSmartCard.Header>
    }
}

interface CHeaderSectionProps {
    maxWidth?: number | string
}

class CHeaderSection extends Component<CHeaderSectionProps, {}> {
    public static defaultProps = {
        maxWidth: "auto"
    }

    public render() {
        return <div
            className={style("CHeaderSection")}
            style={{
                maxWidth: this.props.maxWidth,
            }}
        >
            {this.props.children}
        </div>
    }
}

/**
 * "расталкивает" соседние секции
 */

class CHeaderSectionDivider extends Component<{}, {}> {
    public render() {
        return <div className={style("CHeaderSectionDivider")}/>
    }
}

interface CSmartCardProps {
    className?: string
    animateClass?: string
    active?: boolean
    animated?: boolean
    noMargin?: boolean
    inLayout?: boolean
    fullWidth?: boolean
}

@observer
export class CSmartCard extends Component<CSmartCardProps, {}> {
    static Header = CHeader
    static MainCardHeader = CMainCardHeader
    static HeaderSection = CHeaderSection
    static HeaderSectionDivider = CHeaderSectionDivider
    static LayoutWrapper = CLayoutWrapper
    static Title = CTitle
    static Footer = CFooter
    static Content = CContent
    static Row = CRow
    static ControlWrapper = CControlWrapper
    static MenuControl = CMenuControl
    static Collapser = CCollapser
    static cardToggler = (renderCallback: CollapserRenderCallback) => {
        return <CLayoutStore>
            {stores => {
                if (stores && stores.viewStore) {
                    return <CLayoutToggler viewStore={stores.viewStore} renderCallback={renderCallback} />
                }
                return CSmartCard.noSettingToggler(true, renderCallback)
            }}
        </CLayoutStore>
    }
    static toggler = (id: string, renderCallback: CollapserRenderCallback, collapsedByDefault = false) => {
        return <CToggler
            key={`${id}_card_collapsed`}
            id={`${id}_card_collapsed`}
            renderCallback={renderCallback}
            collapsed={collapsedByDefault}
        />
    }
    static noSettingToggler = (collapsed: boolean, renderCallback: CollapserRenderCallback) => {
        return <CToggler renderCallback={renderCallback} collapsed={collapsed} />
    }

    static defaultProps: CSmartCardProps = {
        animateClass: "animate-fadein",
        animated: true,
        noMargin: false,
        fullWidth: false,
    }

    private adaptive = new CAdapt.Observable()

    public render() {
        const fullWidth = {fullWidth: this.props.fullWidth}
        return this.props.animated
            ? <CSSTransitionGroup
                transitionName={this.props.animateClass}
                transitionEnter={false}
                transitionLeave={false}
                // При выводе на печать анимация не воспроизводится и компонент для печать отображается прозрачным.
                // Определяем если страница в режиме печати то анимация не появится
                transitionAppear={!this.adaptive.getIsPrint}
                transitionAppearTimeout={500}
            >
                <div className={style("card", fullWidth, {noMargin: this.props.noMargin}, this.props.className)}>
                    {this.props.children}
                </div>
            </CSSTransitionGroup>
            : <div className={style("card", fullWidth, this.props.className)}>
                {this.props.children}
            </div>
    }
}
