import * as React from "react"
import classNames from "classnames/bind"
import {autobind} from "core-decorators"
import {CButton, CCheckbox, Component} from "src/lib/components"
import {alignItem, justifyItem, sizeType} from "src/lib/types"

/**
 * выстраивает дочерние элементы в строку или в столбик - в зависимости от настроек
 */

@observer
@autobind
export class CFlex extends Component<CFlex.Props, {}> {
    public static defaultProps = {
        direction: "row",
        width: "full",
        bottomMargin: false,
        lastDetect: true,

    }

    /*
    * если компонет имеет прозрачную подложку добавляем обертку для изменеия выравнивания первых и последних элементов в строке
     */

    private isTransparentElement(element: React.ReactElement<any>) {
        return element && ((
                (element.type as React.ComponentClass<any>) === CButton
                && (element.props.type === CButton.Type.FLAT
                    || element.props.type === CButton.Type.FLAT_WARNING
                    || element.props.type === CButton.Type.FLAT_SUCCESS
                )
            )
            || transparentElements.indexOf(element.type as React.ComponentClass<any>) !== -1)
    }

    public renderChildren(children: React.ReactNode): React.ReactNode {

        const {margin, width, direction} = this.props

        return React.Children.map(children, (child: JSX.Element, index: number) => {
            //если элемент уже завернут в Item - просто передаем в него нужные свойства
            if (!child) {
                return null
            }

            if ((child.type as React.ComponentClass<any>) === CItem) {
                return React.cloneElement(
                    child,
                    {
                        margin: margin,
                        width: width,
                        direction: direction,
                        key: `item-${index}`,
                        ...child.props,
                    }
                )
            }
            //если элемент CDivider - ничего с ним не делаем
            if ((child.type as React.ComponentClass<any>) === CDivider) {
                return child
            }

            // во всех остальных случаях оборачиваем в CItem
            return <CItem
                margin={margin}
                width={width}
                direction={direction}
                key={`item-${index}`}
                leftShift={this.isTransparentElement(child) && index === 0}
                rightShift={this.isTransparentElement(child) && index === React.Children.count(children) - 1}
            >
                {child}
            </CItem>
        })
    }

    public render() {
        const {direction, align, justify, size, fullHeight, bottomMargin, wrap} = this.props


        return <div
            className={style(
                "CFlex",
                direction,
                {fullHeight: fullHeight},
                align ? `align_${align}` : void 0,
                justify ? `justify_${justify}` : void 0,
                wrap ? "wrap" : void 0,
                size,
                {marginBottom: bottomMargin},
                this.props.className
            )}
            style={this.props.style}
            onClick={this.props.onClick}
        >
            {this.renderChildren(this.props.children)}
        </div>
    }
}

import {observer} from "mobx-react"

const style = classNames.bind(require("./CFlex.styl"));

//элемнты с прозрачной подложкой (им требуется отрицательный отступ, если они находятся в начале или конце строки)
const transparentElements:  React.ComponentClass<any>[] = [
    CCheckbox
]

export namespace CFlex {
    export interface Props {
        direction?: "row" | "column" // напрвление выравнивания
        margin?: "none" | "small" | "medium" // отступ межде элементами
        width?: "auto" | "full" // растягивание по ширине
        fullHeight?: boolean // растягивание по высоте
        bottomMargin?: boolean //нижний отступ под блоком
        lastDetect?: boolean // отрицательный отступ для последнего блока
        size?: sizeType // минимальная высота строки, если не указан рассчитывается по самому высокому элемнту
        align?: alignItem // выравнивание элементов
        justify?: justifyItem // выравнивание элементов
        wrap?: boolean // перенос элементов
        className?: string | string[]
        onClick?: React.MouseEventHandler<HTMLDivElement>
        style?: React.CSSProperties
    }

    export interface Item {
        alignSelf?: alignItem // выравнивание отдельного элемента
        className?: string | string[] | {[index: string] : boolean}
        width?: "auto" | "full"
        margin?: "none" | "small" | "medium" // отступ межде элементами
        fullStretch?: boolean //приоритетная ширина блока
        leftShift?: boolean  // отрицательный сдвиг влево
        rightShift?: boolean  // отрицательный сдвиг вправо
        noShrink?: boolean  // несжимаемый элемент
        noGrow?: boolean // нерастущий элемент
        direction?: "row" | "column" // напрвление выравнивания - лучше задавать в родителе
    }
}


class CDivider extends Component<{}, {}> {
    render() {
        return <div className={style("divider")}/>
    }
}

@observer
class CItem extends Component<CFlex.Item, {}> {

    render() {
        const {children, margin, alignSelf, width, fullStretch, rightShift, leftShift, noShrink, direction, noGrow} = this.props

        const itemClassName = style(
            "Item",
            {
                [`Itemalig_${alignSelf}`]: Boolean(alignSelf),
                [`Item${margin}`]: margin !== "none",
                [`Item${width}`]: Boolean(width),
                [`Item${direction}`]: Boolean(direction),
                fullStretch: fullStretch,
                leftShift: leftShift,
                rightShift: rightShift,
                noShrink: noShrink,
                noGrow: noGrow,
            },
        )

        const childrenWrapper = <div
                className={`${itemClassName} ${style(this.props.className)}`}
            >
                {this.props.children}
            </div>

        if (React.Children.count(children) === 1) {
            return React.Children.map(children, (child: JSX.Element, index: number) => {
                if (React.isValidElement<{className?: string}>(child) && child.type !== CFlex) {
                    return React.cloneElement(
                        child,
                        {className: `${itemClassName} ${style(child.props.className)}`}
                    )
                } else {
                    return childrenWrapper
                }
            })
        }

        return childrenWrapper
    }
}

export namespace CFlex {
    export const Divider = CDivider
    export const Item = CItem
}
