/* tslint:disable */
import * as H from "../metadataHelper"
import {StringField} from "./stringField"
import {IntegerField} from "./integerField"

export interface Group extends H.BaseEntity {
    name?: string; // Название группы
    children?: H.List<H.BaseEntity>; // Сущности группы
    childrenCount?: number; // Количество сущностей в группе
    id?: string; // Идентификатор
    contentType: "Group"; // Object type
}

export module Group {
    export const contentType: "Group" = "Group";
    export const endpoint = "/api/v3/group";

    export const newObject: Group = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "Group.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const children = {contentType: "RefLinkField" as "RefLinkField", name: "children", isMultiple: true, isSortable: false, id: "Group.children", refContentType: H.List(["BaseEntity"])};
        export const childrenCount = {contentType: "IntegerField" as "IntegerField", name: "childrenCount", isMultiple: false, isSortable: false, id: "Group.childrenCount", defaultValue: null as IntegerField["defaultValue"]};
    }
}

export function isGroup(arg: any): arg is Group {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Group"
}
