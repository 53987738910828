/* tslint:disable */
import * as H from "../metadataHelper"
import {MailServerConfig} from "./mailServerConfig"
import {Program} from "./program"
import {ProgramState} from "./programState"
import {ContractorCompany} from "./contractorCompany"
import {ContractorHuman} from "./contractorHuman"
import {Employee} from "./employee"
import {Group} from "./group"
import {StringField} from "./stringField"
import {EnumField} from "./enumField"
import {BoolField} from "./boolField"
import {IntegerField} from "./integerField"

export interface Imap extends H.BaseEntity {
    imapConfig?: MailServerConfig;
    smtpConfig?: MailServerConfig;
    email?: string; // E-mail адрес
    footer?: string;
    incomingEmailMode?: Imap.IncomingEmailMode;
    receivePrivateEmail?: boolean;
    program?: Program;
    programState?: ProgramState;
    isDefault?: boolean; // Используется по умолчанию
    imapStatus?: Imap.ImapStatus;
    smtpStatus?: Imap.SmtpStatus;
    visibleFor?: H.List<ContractorCompany | ContractorHuman | Employee | Group>; // Кому доступно
    visibleForCount?: number;
    expandedVisibleForCount?: number; // разворачивает группы для подсчета участников
    id?: string; // Идентификатор
    contentType: "Imap"; // Object type
}

export module Imap {
    export const contentType: "Imap" = "Imap";
    export const endpoint = "/api/v3/imap";
    export type IncomingEmailMode = "deal"|"email";
    export module IncomingEmailMode {
        export const deal: IncomingEmailMode = "deal";
        export const email: IncomingEmailMode = "email";
    }
    export type ImapStatus = "disabled"|"enabled"|"error"|"unknown";
    export module ImapStatus {
        export const disabled: ImapStatus = "disabled";
        export const enabled: ImapStatus = "enabled";
        export const error: ImapStatus = "error";
        export const unknown: ImapStatus = "unknown";
    }
    export type SmtpStatus = "disabled"|"enabled"|"error"|"unknown";
    export module SmtpStatus {
        export const disabled: SmtpStatus = "disabled";
        export const enabled: SmtpStatus = "enabled";
        export const error: SmtpStatus = "error";
        export const unknown: SmtpStatus = "unknown";
    }
    export const newObject: Imap = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const imapConfig = {contentType: "RefLinkField" as "RefLinkField", name: "imapConfig", isMultiple: false, isSortable: false, id: "Imap.imapConfig", refContentType: H.List(["MailServerConfig"])};
        export const smtpConfig = {contentType: "RefLinkField" as "RefLinkField", name: "smtpConfig", isMultiple: false, isSortable: false, id: "Imap.smtpConfig", refContentType: H.List(["MailServerConfig"])};
        export const email = {contentType: "StringField" as "StringField", name: "email", isMultiple: false, isSortable: false, id: "Imap.email", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const footer = {contentType: "StringField" as "StringField", name: "footer", isMultiple: false, isSortable: false, id: "Imap.footer", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const incomingEmailMode = {contentType: "EnumField" as "EnumField", name: "incomingEmailMode", isMultiple: false, isSortable: false, id: "Imap.incomingEmailMode", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["deal", "email"])};
        export const receivePrivateEmail = {contentType: "BoolField" as "BoolField", name: "receivePrivateEmail", isMultiple: false, isSortable: false, id: "Imap.receivePrivateEmail", defaultValue: null as BoolField["defaultValue"]};
        export const program = {contentType: "RefLinkField" as "RefLinkField", name: "program", isMultiple: false, isSortable: false, id: "Imap.program", refContentType: H.List(["Program"])};
        export const programState = {contentType: "RefLinkField" as "RefLinkField", name: "programState", isMultiple: false, isSortable: false, id: "Imap.programState", refContentType: H.List(["ProgramState"])};
        export const isDefault = {contentType: "BoolField" as "BoolField", name: "isDefault", isMultiple: false, isSortable: false, id: "Imap.isDefault", defaultValue: null as BoolField["defaultValue"]};
        export const imapStatus = {contentType: "EnumField" as "EnumField", name: "imapStatus", isMultiple: false, isSortable: false, id: "Imap.imapStatus", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["disabled", "enabled", "error", "unknown"])};
        export const smtpStatus = {contentType: "EnumField" as "EnumField", name: "smtpStatus", isMultiple: false, isSortable: false, id: "Imap.smtpStatus", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["disabled", "enabled", "error", "unknown"])};
        export const visibleFor = {contentType: "RefLinkField" as "RefLinkField", name: "visibleFor", isMultiple: true, isSortable: false, id: "Imap.visibleFor", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee", "Group"])};
        export const visibleForCount = {contentType: "IntegerField" as "IntegerField", name: "visibleForCount", isMultiple: false, isSortable: false, id: "Imap.visibleForCount", defaultValue: null as IntegerField["defaultValue"]};
        export const expandedVisibleForCount = {contentType: "IntegerField" as "IntegerField", name: "expandedVisibleForCount", isMultiple: false, isSortable: false, id: "Imap.expandedVisibleForCount", defaultValue: null as IntegerField["defaultValue"]};
    }
}

export function isImap(arg: any): arg is Imap {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Imap"
}
