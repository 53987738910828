import {autobind} from "core-decorators"
import {computed} from "mobx"
import * as Collections from "src/lib/collections"
import {Form} from "src/lib/utils/form/form"
import {FormCollection} from "src/lib/utils/form/formCollection"
import {FormValidator, isEmpty} from "src/lib/utils/form/validation"
import {Intl} from "src/lib/utils/intl/Intl"
import {Address, AddressType, ContactInfo, isAddress, isContactInfo} from "src/lib/entities/api"

const libMessages: any = require("src/lib/messages.yml")

const contactInfoTypeToContractorExtraFieldsNameMap = new Map<ContactInfo.Type, string>([
    [ContactInfo.Type.phone, "phones"],
    [ContactInfo.Type.email, "email"],
    [ContactInfo.Type.icq, "icq"],
    [ContactInfo.Type.jabber, "jabber"],
    [ContactInfo.Type.link, "links"],
    [ContactInfo.Type.skype, "skype"],
    [ContactInfo.Type.telegram, "telegram"],
    [ContactInfo.Type.viber, "viber"],
    [ContactInfo.Type.whatsapp, "whatsapp"],
    [ContactInfo.Type.instagram, "instagram"],
])

@autobind
export class ContactInfoForm extends Form<ContactInfo> {

    constructor(
        valueFactory: () => ContactInfo,
        private intl: Intl,
        private $isRequired: boolean = false
    ) {
        super(valueFactory)
    }

    public isRequired(field?: string) {
        return this.$isRequired
    }

    @computed
    protected get validator() {
        return this.$isRequired
            ? contactInfoValidationFactory<ContactInfo>(this.intl)
            : null
    }

}

@autobind
export class AddressForm extends Form<Address> {

    constructor(
        valueFactory: () => Address,
        private intl: Intl,
        private $isRequired: boolean = false
    ) {
        super(valueFactory)
    }

    public isRequired(field?: string) {
        return this.$isRequired
    }

    @computed
    protected get validator() {
        return this.$isRequired
            ? contactInfoValidationFactory<Address>(this.intl)
            : null
    }
}

function contactInfoValidationFactory<T extends ContactInfo | Address>(intl: Intl) {
    const validator = new FormValidator<T>()
    validator.set("value", FormValidator.nonEmptyFieldValidator(intl.formatMessage(libMessages["emptyFieldError"])))
    validator.set("type", FormValidator.nonEmptyFieldValidator(intl.formatMessage(libMessages["emptyFieldError"])))
    return validator
}

@autobind
export class ContactInfoFormCollection extends FormCollection<ContactInfo | Address, ContactInfoForm | AddressForm> {
    constructor(
        valueFactory: () => Collections.List<ContactInfo | Address>,
        public requiredContactInfoTypes: string[] = [],
        intl: Intl
    ) {
        super(
            valueFactory,
            contact => {
                if (isContactInfo(contact)) {
                    const isRequired = requiredContactInfoTypes.includes(contact.type)
                    return new ContactInfoForm(() => contact, intl, isRequired)
                } else if (isAddress(contact)) {
                    const isRequired = requiredContactInfoTypes.includes("locations")
                    return new AddressForm(() => contact, intl, isRequired)
                }
            }
        )
    }

    @computed
    public get isValid() {
        return this.requiredContactInfoTypes.every((type: string) => {
            const formsList = this.getFormsByType(type as any)
            //если в коллекции несколько форм одного типа, достаточно, чтобы была валидна хотя бы одна
            return formsList.some(child => child.form.isValid)
        })
    }

    public getFormsByType(type: ContactInfo.Type | "locations") {
        return isAddressField(type)
            ? this.filter(child => isAddress(child.form.value))
            : this.filter(child => (child.form as ContactInfoForm).get("type") === type )
    }

    public canAddNewContactType(type: ContactInfo.Type | "locations") {
        return this.getFormsByType(type).every(child => !isEmpty((child.form as ContactInfoForm).get("value")))
    }

    public addNewContact(type: ContactInfo.Type | "locations") {
        if (isAddressField(type)) {
            this.push({...Address.newObject})
        } else if (ContactInfo.fields.type.enumValues.concat(["site", "link"]).includes(type)) {
            this.push({...ContactInfo.newObject, type: type as ContactInfo.Type})
        }
    }

    public addNewAddress(type: AddressType) {
        this.push({...Address.newObject, type: type})
    }

}

export function isContactInfoField(fieldName: string): boolean {
    return !!fieldName && Array.from(contactInfoTypeToContractorExtraFieldsNameMap.values()).includes(fieldName)
}

export function getContactInfoType(fieldName: string): ContactInfo.Type {
    for (let [key, value] of contactInfoTypeToContractorExtraFieldsNameMap) {
        if (value === fieldName) {
            return key
        }
    }
}

export function getContractorExtraFieldName(contactInfoType: ContactInfo.Type | "locations") {
    return contactInfoType === "locations" ? contactInfoType : contactInfoTypeToContractorExtraFieldsNameMap.get(contactInfoType)
}

export function isAddressField(fieldName: string): boolean {
    return !!fieldName && ["locations"].includes(fieldName)
}
