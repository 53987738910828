import {autobind} from "core-decorators"
import {AbstractTransport} from "src/bums/common/comment/transports/AbstractTransport"
import {FeatureStore} from "src/bums/common/stores/FeatureStore"
import * as Api from "src/lib/entities/api"
import * as Collections from "src/lib/collections"
import {action, computed} from "mobx"
import {ListStore} from "src/lib/entities/store/ListStore"
import {stripTags} from "src/lib/utils/text"
import {scrollToBottomIfBelowViewport} from "src/lib/utils/scrollHelper"
import {KnownEmailTransports, TransportConfigOptions, TransportEntities} from "src/bums/common/comment/transports/types"
import {EmailTemplateStore} from "src/bums/common/emailTemplate/stores/EmailTemplateStore";

export const EmailRegExp = /([a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+.[a-zA-Z0-9._-]+)/i

/**
 * С помощью этого стора CSendMailForm проставляет начальные данные
 * и заполняет поле transportConfigs формы коммента
 */
@autobind
export class EmailTransport extends AbstractTransport<KnownEmailTransports, Api.EmailTransportConfig> {

    private toEmails: ListStore<Api.ContactInfo>

    private $emailTemplateStore: EmailTemplateStore

    constructor(
        private apiStore: Api.Store,
        private userStore: Api.UserStore,
        private transportListStore: Api.ListStore<TransportEntities>,
        private featureStore: FeatureStore,
        context: () => TransportConfigOptions
    ) {
        super(context)
        this.toEmails = this.apiStore.resolveList<Api.ContactInfo>(() => ({
            endpoint: Api.getListEndpoint(this.contextEntity, "commentTransportEmailTo"),
        }))

        this.$emailTemplateStore = new EmailTemplateStore(
            this.apiStore,
            () => this
        )
    }

    @computed
    private get formRef() {
        return this.context.formRef
    }

    @computed
    public get isContactInfoListReady() {
        return this.toEmails.hasMoreNext
    }

    @computed
    public get contactInfoList() {
        return this.toEmails.originalItems
    }

    @computed
    public get emailTemplateStore() {
        return this.$emailTemplateStore
    }

    public get availableTransports() {
        return this.transportListStore.originalItems
            .filter(item => Api.isSmtp(item) || Api.isMegamail(item) || Api.isImap(item)) as Collections.List<KnownEmailTransports>
    }

    private get defaultFrom() {
        return this.availableTransports.find(transport => Api.isSmtp(transport) && transport.isDefault)
            || this.availableTransports.find(transport => Api.isImap(transport) && transport.isDefault)
            || this.availableTransports.find(transport => Api.isMegamail(transport) && !transport.isPersonal)
            || this.availableTransports.first()
    }

    private get defaultTo() {
        let selectedContactInfo = this.toEmails.originalItems.find((toEmail: Api.ContactInfo ) => toEmail.isMain === true)

        if (!selectedContactInfo) {
            selectedContactInfo = this.toEmails.originalItems.first()
        }

        if (selectedContactInfo) {
            return Collections.List<string>([selectedContactInfo.value])
        } else {
            return Collections.List<string>()
        }
    }

    private get defaultSubject() {
        if (Api.isDeal(this.contextEntity)) {
            return "Re: " + this.contextEntity.number + " " + (this.contextEntity.name || "")
        } else if (Api.isNamedEntity(this.contextEntity) && this.contextEntity.name) {
            return "Re: " + this.contextEntity.name
        }
        return ""
    }

    @action
    public async initForm() {
        await this.toEmails.whenComplete()
        const {user} = this.userStore

        this.transportForm.set("to", this.defaultTo)
        this.transportForm.set("from", this.defaultFrom)
        this.transportForm.set("subject", this.defaultSubject)
        this.transportForm.set("sign", Api.isEmployee(user) ? user.emailFooter : "")
    }

    public async initAnswerForm(message: Api.Message) {
        const {user} = this.userStore
        let toEmail = ""
        const messageField = message.isOutgoing ? message.to : Collections.List(message.from)
        if (messageField) {
            const match = messageField.first().email.match(EmailRegExp)
            toEmail = match ? match[0] : ""
        }
        this.transportForm.set("to", Collections.List(toEmail))
        this.transportForm.set("from", this.defaultFrom)
        this.transportForm.set("subject", message.subject)
        this.transportForm.set("sign", Api.isEmployee(user) ? user.emailFooter : "")
        this.transportForm.set("referenceId", message.messageId)

        if (this.featureStore.isAvailable("bums.common.common.email_message_quoting")) {
            this.transportForm.set("originalMessage", message)
        } else {
            this.setQuote(message.text)
        }

        this.scrollToForm()
    }

    public setQuote(text: string) {
        if (!this.formRef || !this.formRef.editor) {
            return
        }
        this.formRef.editor.clearContent()
        this.formRef.editor.insertTextInCurrentPosition("\n")
        this.formRef.insertBlockquote(stripTags(text))
        this.formRef.editor.moveSelectionToStart()
    }

    private scrollToForm() {
        if (!this.formRef) {
            return
        }
        scrollToBottomIfBelowViewport(this.formRef)
    }

    @action
    public dispose() {
        if (this.toEmails) {
            this.toEmails.dispose()
        }
    }
}

export namespace EmailTransport {
    export const Type = "email"
    export function isAvailable(transports: Api.ListStore<TransportEntities>, feature?: FeatureStore) {
        return feature.isAvailable("bums.common.common.duplicate_comment_in_email_feature")
            && !!transports.originalItems.filter(item => Api.isSmtp(item) || Api.isMegamail(item) || Api.isImap(item)).length
    }
}

