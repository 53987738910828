
/**
 * LibreOffice Writer: исправляет списки <li/>... в <li>...</li>
 * TODO Доработать: Списки содержат лишние переносы строк
 * @param pastedHtml
 * @returns {string}
 */
const convertLibreHtml = function(pastedHtml: string): string {

    if (!pastedHtml.length) {
        return pastedHtml
    }

    const listItemFixer = /(<li\/>)(\s*\S*[.\s\S]*?)(<\/ul>|<\/ol>)/g

    while (listItemFixer.test(pastedHtml)) {
        pastedHtml = pastedHtml.replace(listItemFixer, "<li>$2</li>$3")
    }

    return pastedHtml
}

export default convertLibreHtml
