import {observable, action, computed} from "mobx"
import {autobind} from "core-decorators"
import * as Api from "src/lib/entities/api"
import {AbstractModalForm} from "./AbstractModalForm"
import {FormType, MultiFormType, BaseOptions, Options, FormInstance} from "./types"
import {EventEmitter} from "events"

@autobind
export class ModalFormStore {

    @observable
    protected $type: FormType

    @observable.ref
    protected $options: BaseOptions<Api.BaseEntity[]>

    @observable.ref
    protected $forms: FormInstance[]

    protected presetMap = new Map<FormType, Api.BaseEntity[]>()

    @observable
    public formToRestore: { type: FormType, activeFormType: Options<MultiFormType>["activeForm"], isTemplate: boolean }

    private eventEmmiter = new EventEmitter()

    @action
    private addFormToRestore() {
        this.formToRestore = {
            type: this.$type,
            activeFormType: this.activeForm
                ? this.activeForm.value.contentType?.toLowerCase() as Options<MultiFormType>["activeForm"]
                : void 0,
            isTemplate: this.forms.some((form: any) => form.value ? form.value.isTemplate : false),
        }
    }

    @action
    public clearFormToRestore() {
        this.formToRestore = null
    }

    @action
    public open<T extends FormType>(type: T, options?: Options<T>) {
        this.$type = type
        this.$options = options || {}

        const presets = this.$options.presets || []

        if (this.presetMap.has(type)) {
            this.presetMap.set(type, this.presetMap.get(type).map((preset, index) => ({...preset, ...(presets[index] || {})})))
        } else {
            this.presetMap.set(type, presets)
        }
    }

    @action
    public async resolveForms(forms: Promise<FormInstance[]>) {
        this.$forms = void 0
        this.setForms(await forms)
    }

    @action
    protected setForms(forms: FormInstance[]) {
        this.$forms = forms
    }

    @computed
    public get forms() {
        return this.$forms
    }

    @computed
    public get type() {
        return this.$type
    }

    @computed
    public get isComplete() {
        return this.$type && this.$forms && this.$forms.length > 0
            && (this.$forms.filter(form => !!("form" in form ? (form as {form: AbstractModalForm<Api.BaseEntity>}).form : form))).length > 0
    }

    @computed
    private get activeForm() {
        return this.forms.map(formInstance => {
            return this.getAbstractForm(formInstance)
        }).find((form: AbstractModalForm<Api.BaseEntity>) => {
            return form.active || this.forms.length === 1
        }) as AbstractModalForm<Api.BaseEntity>
    }

    private getAbstractForm(formInstance: FormInstance) {
        if ("form" in formInstance && "function" !== typeof (formInstance as { form: AbstractModalForm<Api.BaseEntity> }).form) {
            return (formInstance as { form: AbstractModalForm<Api.BaseEntity> }).form
        }
        return formInstance
    }

    public getOptions<T extends FormType>(): Options<T> {
        return this.$options as Options<T>
    }

    public getPresets<T extends FormType>(): Options<T>["presets"] {

        if (this.$type && this.presetMap.has(this.$type)) {
            return this.presetMap.get(this.$type) as Options<T>["presets"]
        }

        return [] as any as Options<T>["presets"]
    }

    public savePreset() {
        if (this.$type && this.presetMap.has(this.$type) && this.isComplete) {
            const forms = this.forms
            const presets = this.presetMap.get(this.$type)

            this.presetMap.set(
                this.$type,
                forms.map((form, index) => {
                    const preset = presets[index]

                    form = this.getAbstractForm(form)

                    return {
                        ...preset,
                        ...(form as AbstractModalForm<Api.BaseEntity>).value,
                    }
                })
            )
            this.addFormToRestore()
        }
    }

    public getFixedFields() {
        if (this.$options.withoutFixPreset) {
            return []
        }

        if (this.$options.fixedFields && this.$options.fixedFields.length > 0) {
            return this.$options.fixedFields
        }

        return []
    }

    @action
    public closeForm(confirmed = false) {
        if (confirmed && this.eventEmmiter.listenerCount("submitForm")) {
            if (this.activeForm) {
                this.eventEmmiter.emit("submitForm", this.activeForm.get("contentType"))
            }
        }
        const confirmHandler = this.$options
            ? this.$options.confirmHandler
            : void 0
        const cancelHandler = this.$options
            ? this.$options.cancelHandler
            : void 0

        if (confirmed && confirmHandler) {
            confirmHandler()
        }

        if (!confirmed && cancelHandler) {
            cancelHandler()
        }

        this.$type = void 0
        this.$options = void 0
    }

    public clearPreset(formType: FormType = this.$type) {
        if (formType && this.presetMap.has(formType)) {
            this.presetMap.delete(formType)
            this.clearFormToRestore()
        }
    }

    public subscribeToCloseForm(callback: (formContentType: string) => void) {
        this.eventEmmiter.addListener("submitForm", callback)
    }

    public unsubscribeToCloseForm(callback: (formContentType: string) => void) {
        this.eventEmmiter.removeListener("submitForm", callback)
    }
}


