import * as React from "react"
import {autobind} from "core-decorators"
import {CLink, Component, CSmartCard, CSpinner} from "src/lib/components"
import * as Api from "src/lib/entities/api"
import {action, observable} from "mobx";
import {observer} from "mobx-react";
import {inject} from "src/lib/utils/inject";
import {bindArg} from "src/lib/utils/func";
import classNames from "classnames/bind";
import {FormattedCurrency, Intl} from "src/lib/utils/intl";

const style = classNames.bind(require("./theme/CPacketBuyButton.styl"))

const messages: any = require("./messages.yml")


@observer
@autobind
export class CPacketBuyButton extends Component<CPacketBuyButton.Props, {}> {
    @inject(Api.Store)
    private apiStore: Api.Store

    @inject(Intl)
    private intl: Intl

    @observable
    private buyProcess: boolean = false

    private entityStore: Api.EntityStore<Api.ObjectPacket>

    constructor(props: CPacketBuyButton.Props, context: {}) {
        super(props, context)

        this.entityStore = new Api.EntityStore<Api.ObjectPacket>(this.apiStore, () => ({
            contentType: Api.ObjectPacket.contentType,
            id: this.props.editionExceed.id
        }))
    }

    @action
    private createOrder(objectPacket: Api.ObjectPacket) {
        this.buyProcess = true
        return this.apiStore.fetch(Api.ObjectPacket.endpoint + "/createOrder/", {
            method: "POST",
            bodyEntity: objectPacket
        })
            .catch(er => {
                if (er instanceof Api.FetchError) {
                    throw er
                }
            })
            .then((result: any) => {
                window.location.href = result.value.data;
            })
            .finally(() => {
                this.buyProcess = false
            })
    }

    public render() {
        const packetStore = this.entityStore.get()
        if (packetStore.state !== "fulfilled" || this.buyProcess) {
            return <CSpinner/>
        }
        const objectPacket = packetStore.value

        if (!objectPacket.buyPossible) {
            return null
        }

        return <CSmartCard>
            <CLink onClick={bindArg(this.createOrder, objectPacket)}>
                    <span className={style("buyLinkText")}>
                        {this.intl.formatMessage(messages["addMore"], {count: objectPacket.size})}&nbsp;
                        <FormattedCurrency value={objectPacket.price.value} currency={objectPacket.price.currency}/>.
                    </span>
            </CLink>
        </CSmartCard>
    }
}

namespace CPacketBuyButton {
    export type Props = {
        editionExceed: Api.EditionExceed
    }
}

