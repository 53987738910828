import {CRatingContainer} from "src/bums/common/promo/CRating/CRatingContainer"
import {PromoData} from "src/bums/common/promo/types"
import * as DateHelper from "src/lib/utils/date"

export type RatingParams = {
    ratingTitle?: string,
    ratingExplanation?: string,
    feedbackTitle?: string,
    feedbackExplanation: string,
    thanksForAnswerTitle: string,
    thanksForAnswerExplanation: string,
    skipFeedback?: boolean  //пропустить второй экран с фидбеком
}

export const createSeptemberPromoSettings = (): PromoData<RatingParams> => {
    return {
        id: "promo.common.promo_rating_sept2019",
        feature: "bums.common.promo.promo_rating_sept2019",
        component: CRatingContainer.injectProps({ratingTag: "v1", ratingType: "regularRating"}),
        userCondition: (userStore, featureStore) => {
            if (featureStore.isAvailable("bums.common.promo.on_boarding_rating")) {
                return false
            }
            const now = DateHelper.getNow().getTime()
            const today10am = DateHelper.getToday().addHours(10).getTime()
            const today17pm = DateHelper.getToday().addHours(17).getTime()
            return today10am < now && now < today17pm
        },
        store: null
    }
}
