import * as ReduxActions from "redux-actions"

const REMOVE_CARD = "src/lib/CCardContainer/action/REMOVE_CARD"

type RemoveCardAction = ReduxActions.Action<string>

export function removeCard(cardId: string): RemoveCardAction {
    return {
        type: REMOVE_CARD,
        payload: cardId
    }
}

export function isRemoveCardAction(action: any): action is RemoveCardAction {
    return "object" === typeof action && REMOVE_CARD === action.type
}

