import * as React from "react"
import * as ReactDOM from "react-dom"
import {observer} from "mobx-react"
import {autobind} from "core-decorators"
import {observable, action, computed} from "mobx"
import {noop} from "lodash"
import classNames from "classnames/bind"
import * as Collections from "src/lib/collections"
import * as Api from "src/lib/entities/api"
import {Intl} from "src/lib/utils/intl"
import {inject} from "src/lib/utils/inject"
import {bindArg, makeRefHandler} from "src/lib/utils/func"
import {KeyedValue, FilterOptions, formFieldStatus, sizeType} from "../../types"
import {
    CDropDown,
    CSelectOption,
    CPopover,
    CInput,
    CIcon,
    CEmpty,
    CFormFieldBorderBottom,
    CPlaceholder,
    CChip,
    CLink,
    CDisguisedValue,
    Component
} from "src/lib/components"
import {generateUrlByEntity, transitionToPage} from "src/lib/utils/url"
import {SelectionStore} from "./SelectionStore"

const messages: any = require("../../messages.yml")

const style: any = classNames.bind(require("./CSmartSelect.styl"))

const MAX_OPTIONS_WIDTH = 300

function compareKeys(key: any, key2: any) {
    return key === key2 || (Api.isBaseEntity(key) && Api.isBaseEntity(key2) && Api.isEntityEquals(key, key2))
}

export namespace CSmartSelect {
    export interface CommonProps<V, V2> {
        key?: string
        fieldName: string
        items?: Collections.List<V | KeyedValue<V, V2>>
        onClose?: () => void
        onCloseCondition?: (event?: React.MouseEvent<Element>) => boolean
        onBlur?: () => void
        multi?: boolean
        disabled?: boolean
        active?: boolean
        loading?: boolean
        showLoaderIfEmptyList?: boolean
        showElementIfFocused?: boolean
        // показывает крестик очистки для в конце поля
        // по клику на крестик вызывается props.onChange(null)
        removable?: boolean
        autofocus?: boolean
        minWidth?: number | string
        maxWidth?: string
        placeholder?: string | JSX.Element
        status?: formFieldStatus
        className?: string
        emptyListPlaceholder?: string | JSX.Element
        dropDownClassName?: string
        appendDropDownElement?: JSX.Element
        //элемент для отображения в начале листа
        prependDropDownElement?: JSX.Element
        theme?: "text" | "chip"
        size?: sizeType
        typeListOptions?: "default" | "inline"
        inputSwitch?: boolean
        //прятать слишком длинный текст в options
        optionsEllipsis?: boolean,
        //ужимается по ширине родительского блока, независимо от контента
        inheritParentElementWidth?: boolean
        showDropDownStatus?: boolean
        showBottomBorder?: boolean
        onFocus?: () => void
        customOptionComponent?: React.ComponentClass<CSelectOption.Props<V2>> | React.StatelessComponent<CSelectOption.Props<V2>>
        customChipContentComponent?: React.ComponentClass<{ entity: V2, fieldName?: string }>
        | React.StatelessComponent<{ entity: V2, fieldName?: string }>
        wrappingLink?: boolean
        onChangeSearchText?: (value: string, operation: "change" | "clear") => void
        onKeyDown?: React.KeyboardEventHandler<EventTarget>
        searchText?: string
        filters?: ((list: Collections.List<KeyedValue<V, V2>>, options?: FilterOptions) => Collections.List<KeyedValue<V, V2>>)[]
        onMouseDown?: () => void
        errors?: {} // Заглушка для обхода TS2345
        width?: number
        display?: "block" | "inlineBlock"
        //текст поле инпута поиска
        textAfter?: string
        //Ширина текстового поля, вариант "auto" - по ширине текста или плейсхолдера, если текст не введен
        sizeInput?: number | "auto" | "inherit"
        //неразрывный текст в поиске и выбранном значении
        nowrap?: boolean
        //Установить ширину инпута 100%
        setInputMaxWidth?: boolean
        wrapperClassName?: string
        additionalDropDownProps?: Pick<CDropDown.Props<V2>, "initialHoverIndex" | "initialScrollValue">
    }

    export interface SingleProps<V, V2> extends CommonProps<V, V2> {
        value?: V | KeyedValue<V, V2> | void
        onChange?: (value?: V) => void
        multi?: false
    }

    export interface MultiProps<V, V2> extends CommonProps<V, V2> {
        value?: Collections.List<V | KeyedValue<V, V2>> | void
        onChange?: (value?: Collections.List<V>) => void
        multi?: true,
        maxValues?: number
    }

    export type Props<V, V2> = (SingleProps<V, V2> | MultiProps<V, V2>)
}

@observer
class CNotFound extends Component<{height?: number}, {}> {
    public render() {
        return <div className={style("notValue")}>
            <div className={style("notValueItem")}><CEmpty type="search" /></div>
        </div>
    }
}

@observer
class CEmptyLoading extends Component<{}, {}> {
    public render() {
        return <div className={style("notValue", "loader")}>
            <div className={style("notValueItem")}>
                <CDisguisedValue />
            </div>
        </div>
    }
}


@observer
@autobind
export class CSmartSelect<V, V2> extends Component<CSmartSelect.Props<V, V2>, {}> {

    public static defaultProps: CSmartSelect.Props<any, any> = {
        active: true,
        fieldName: "smartSelectField",
        showDropDownStatus: true,
        showBottomBorder: true,
        theme: "text",
        onChange: noop,
        onClose: noop,
        onFocus: noop,
        onBlur: noop,
        onMouseDown: noop,
        onChangeSearchText: noop,
        onCloseCondition: () => true,
        showLoaderIfEmptyList: true,
        showElementIfFocused: true,
        optionsEllipsis: true,
        wrappingLink: true,
        filters: [],
        typeListOptions: "default",
        display: "block",
        maxWidth: "50vw"
    }

    @inject(Intl)
    private intl: Intl

    @inject(Api.UserStore)
    private userStore: Api.UserStore

    public input: CInput

    public element: HTMLElement

    @observable
    private focused = false

    @observable
    private width = 0

    @observable
    private searchText = ""

    private canBlur = false

    private selectionStore: SelectionStore<V, V2>

    public componentWillMount() {
        this.selectionStore = new SelectionStore<V, V2>(
            this.userStore,
            this.intl,
            () => {
                let value = "string" === typeof this.props.value && this.props.value === "" ? null : this.props.value
                if (this.props.multi && Collections.isList(value) && this.props.maxValues !== void 0) {
                    value = value.slice(0, this.props.maxValues)
                }

                return {
                    value,
                    items: this.props.items,
                    searchText: this.props.searchText === void 0 ? this.searchText : this.props.searchText,
                    lifeFiltration: this.props.loading !== false
                }
            }
        )

        if (this.props.autofocus) {
            this.focus()
        }
    }

    public componentDidMount() {
        this.calculateWidth()
    }

    public componentDidUpdate(prev: CSmartSelect.Props<V, V2>) {
        if (this.props.autofocus && this.props.active) {

            if (!prev.active) {
                this.focus()
            }

            if (this.focused && this.input) {
                this.input.focus(true)
            }
        }

        this.calculateWidth()
    }

    @computed
    private get currentTheme() {
        return this.props.theme === "text" && this.props.multi ? "chip" : this.props.theme
    }

    @computed
    private get hasShowInput() {
        return this.props.multi
            || this.currentTheme === "text"
            || (this.currentTheme === "chip" && this.focused)
    }

    @computed
    private get hasShowPlaceholder() {
        const isEmptyText = this.inputText.length === 0
        return this.currentTheme === "text"
            ? isEmptyText
            : isEmptyText && this.selectionStore.values.length === 0
    }

    @computed
    private get inputText() {
        if (this.selectionStore.hasUserChanges || this.currentTheme === "chip") {
            return this.selectionStore.searchText
        }

        return String(this.selectionStore.values.length !== 0 ? Api.getValueName(this.selectionStore.values.get(0).value, this.intl) : "")
    }


    @computed
    private get filteredItems() {
        const filterOptions = {
            filter: this.selectionStore.searchText,
            focused: this.focused,
        }

        const items = this.props.multi
            ?  this.selectionStore.items.filter(item => !this.selectionStore.values.find(value => compareKeys(item.key, value.key)))
            :  this.selectionStore.items

        return this.props.filters.filter(filter => !!filter).reduce((list, filter) => filter(list, filterOptions), items)
    }

    @computed
    private get hasRemovable() {
        if (this.currentTheme === "chip" && this.props.active) {
            return this.props.removable || (!this.props.removable && this.selectionStore.values.length > 1)
        }
        return false
    }

    @computed
    private get sizeInput() {
        if (this.currentTheme === "chip" && this.selectionStore.values.length > 0) {
            return this.inputText.length + 1
        }
        return this.props.sizeInput
    }

    @computed
    private get isStorageIsFull() {
        return this.props.multi && this.props.maxValues !== void 0
            && this.selectionStore.values.length + 1 > this.props.maxValues
    }

    @action
    public focus() {
        this.focused = true
        this.props.onFocus()
    }

    @action
    public blur() {
        this.focused = false
        if (this.props.onBlur) {
            this.props.onBlur()
        }
    }

    public clear() {
        this.changeSearchTextHandler("clear", "")
    }

    private onBlur() {
        if (this.canBlur) {
            this.canBlur = false
            this.blur()
        }
    }

    private calculateWidth() {
        window.requestAnimationFrame(action(() => {
            if (!this.element) {
                return
            }
            const element = ReactDOM.findDOMNode(this.element) as HTMLElement
            if (!element) {
                return
            }
            this.width = element.offsetWidth
        }))
    }

    private keyDown(e: React.KeyboardEvent<HTMLElement>) {
        if (e.keyCode === 9) {
            this.canBlur = true
        }
    }

    private focusHandler(event?: React.MouseEvent<HTMLElement>) {
        this.calculateWidth()

        if (event && !this.focused && this.props.active && !this.props.disabled) {
            event.preventDefault()
            event.stopPropagation()
            this.focus()
        }
        if (this.props.active) {
            setTimeout(() => {
                if (this.input) {
                    this.input.focus(true)
                }
            })
        }
    }

    private closeDropdownHandler(event: React.MouseEvent<Element>) {
        if (this.focused) {
            event.stopPropagation()
            this.closeHandler(event)
        }
    }

    private closeHandler(event?: React.MouseEvent<Element>) {
        if (event && !this.props.onCloseCondition(event)) {
            this.focusHandler()
            return
        }
        this.clear()
        this.blur()
        this.props.onClose()
    }

    private changeHandler(values: Collections.List<KeyedValue<any, any>>, event?: React.MouseEvent<HTMLElement>) {
        const {multi} = this.props
        const result = values.map(item => item.key)
        this.clear()

        if (event) {
            event.preventDefault()
            event.stopPropagation()
        }

        if (multi) {
            (this.props.onChange as (value?: Collections.List<V>, event?: React.MouseEvent<HTMLElement>) => void)(result, event)
        } else {
            (this.props.onChange as (value?: V) => void)(result.last())
        }
    }

    @action
    private changeSearchTextHandler(type: "change" | "clear", value: string) {
        if (this.props.searchText === void 0) {
            this.searchText = value
        }

        this.props.onChangeSearchText(value, type)

        this.selectionStore.changeUserChanges(type === "change")
    }

    private addHandler(index: number) {
        const item = this.filteredItems.get(index)

        if (this.isStorageIsFull) {
            if (!this.props.multi) {
                return this.closeHandler()
            } else {
                this.changeHandler(this.selectionStore.values.slice(0, this.selectionStore.values.length - 1).push(item))
                setTimeout(this.closeHandler)

                return
            }
        }

        if (item) {
            this.changeHandler(this.selectionStore.values.slice().push(item))
            if (!this.props.multi || this.isStorageIsFull) {
                setTimeout(this.closeHandler)
            } else {
                this.focusHandler()
            }
        }
    }

    private removeHandler(index: number, event: React.MouseEvent<HTMLElement>) {
        if (event) {
            event.preventDefault()
            event.stopPropagation()
        }

        this.changeHandler(this.selectionStore.values.filter((item, valueIndex) => index !== valueIndex))
        this.focusHandler()
    }

    private linkClickHandler(value: V2, event: React.MouseEvent<HTMLElement>) {
        if (Api.isBaseEntity(value)) {
            event.preventDefault()
            event.stopPropagation()
            transitionToPage(generateUrlByEntity(value), true)
        }
    }

    private iconSize() {
        if (!this.props.size) {
            return CIcon.Size.SMALL
        }

        switch (this.props.size) {
            case "large":
                return CIcon.Size.MEDIUM
            default:
                return CIcon.Size.SMALL
        }

    }

    private renderIcon() {

        if (this.props.showDropDownStatus && (this.currentTheme === "text")
            && (!this.focused || (this.selectionStore.values.length === 0 || !this.props.removable))
        ) {
            return <CIcon
                type={this.focused ? CIcon.Type.ARROW_DROP_UP : CIcon.Type.ARROW_DROP_DOWN}
                size={this.iconSize()}
                className={style("status", this.props.size)}
                onClick={this.closeDropdownHandler}
            />
        }

        if (this.currentTheme === "chip" && !this.props.multi) {
            return null
        }

        if (!this.props.disabled && this.props.removable && this.props.active &&
            (
                (this.currentTheme === "chip" && this.selectionStore.values.length > 1) ||
                ((this.currentTheme === "text")
                    && this.selectionStore.values.length === 1
                    && this.selectionStore.values.get(0).key !== null
                )
            )
        ) {
            return <CIcon
                size={this.iconSize()}
                type={CIcon.Type.CANCEL}
                className={style("status", this.props.size)}
                onClick={bindArg(this.changeHandler, Collections.List())}
            />
        }

        return null
    }

    renderInput() {
        const {inputSwitch, size, textAfter, disabled} = this.props

        const inputClassName = style(
            "input",
            this.currentTheme,
            {
                empty: this.inputText.length === 0,
                available: this.currentTheme === "text" || this.focused,
                inputSwitch,
                textAfter,
                inputWidth: this.props.setInputMaxWidth && this.inputText.length
            },
            size
        )

        return <CInput
            key="input"
            name="autocompleteSearch"
            value={this.inputText}
            onChange={bindArg(this.changeSearchTextHandler, "change")}
            onFocus={this.focus}
            onBlur={this.onBlur}
            className={inputClassName}
            showBottomBorder={false}
            size={this.props.size}
            autofocus={this.focused}
            autofocusEnd={true}
            ref={makeRefHandler(this, "input")}
            sizeInput={this.sizeInput}
            disabled={disabled}
            onKeyDown={this.props.onKeyDown}
        />
    }

    private renderInputBlock() {
        const {
            multi,
            disabled,
            active,
            showBottomBorder,
            inputSwitch,
            customChipContentComponent,
            inheritParentElementWidth,
            size,
            fieldName
        } = this.props

        const className = style("wrapper", {
                disabled,
                active,
                multi,
                focused: this.focused,
                inputSwitch,
                inheritParentElementWidth,
                notRemovable: this.renderIcon() === null
            },
            size,
            this.props.wrapperClassName
        )

        return <div
            data-autocomplete-input={fieldName}
            className={style("box", {inlineBlock: this.props.display === "inlineBlock"}, this.props.size, this.props.className  )}>
            <div ref={makeRefHandler(this, "element")}>
                <CChip.Box
                    className={className}
                    onClick={this.focusHandler}
                    onKeyDown={this.keyDown}
                    onMouseDown={this.props.onMouseDown}
                    size={size}
                >
                    {(multi || (this.currentTheme === "chip" && !this.focused)) && this.selectionStore.values.map((item, index) => {
                        const value = item.value
                        const url = Api.isBaseEntity(value) ? generateUrlByEntity(value) : void 0
                        const valueName = Api.isVariable(value)
                            ? (value.name === "currentUser" ? this.intl.formatMessage(messages["currentUser"]) : "")
                            : Api.getValueName(value, this.intl)
                        const content = !this.focused && this.props.wrappingLink && url
                            ? <CLink
                                to={url}
                                target="_blank"
                                type="secondary"
                                onClick={bindArg(this.linkClickHandler, value)}
                            >
                                {valueName}
                            </CLink>
                            : valueName

                        return <CChip
                            key={`selected-${index}`}
                            content={customChipContentComponent
                                ? React.createElement(customChipContentComponent, {entity: value, fieldName})
                                : content
                            }
                            onRemove={bindArg(this.removeHandler, index)}
                            removable={item.key === null && this.selectionStore.values.length === 1 ? false : this.hasRemovable}
                            noEllipsis={!this.props.optionsEllipsis || !!customChipContentComponent}
                            type={Api.isGroup(item.value) ? "group" : "default"}
                        />
                    })}
                    {this.hasShowInput &&
                        (this.currentTheme === "text"
                            ? <div
                                    className={style(
                                        "inputWrapper",
                                        this.props.size,
                                        {
                                            inheritWidth: this.inputText.length !== 0 && this.props.sizeInput === "inherit",
                                            empty: this.inputText.length === 0,
                                        })}
                                >
                                <div className={style("inputWrapperText", {nowrap: this.props.nowrap}, this.props.size)}>
                                    {this.inputText}
                                </div>
                                {this.renderInput()}
                            </div>
                            : this.renderInput()
                        )
                    }
                    {this.hasShowPlaceholder &&
                        <div className={style("placeholder", this.props.size)}>
                            <CPlaceholder>
                                {this.props.placeholder === void 0
                                    ? this.intl.formatMessage(messages["enumPlaceholder"])
                                    : this.props.placeholder
                                }
                            </CPlaceholder>
                        </div>
                    }
                    {this.props.textAfter &&
                        <div className={style("textAfter", this.props.size)}>
                            {this.props.textAfter}
                        </div>
                    }
                    {this.renderIcon()}
                </CChip.Box>
            </div>
            {active && !inputSwitch && showBottomBorder &&
                <CFormFieldBorderBottom status={this.focused ? "focus" : this.props.status || "normal"}/>
            }
        </div>
    }

    renderOptions() {
        const placeholder = this.renderPlaceholder()

        return CDropDown.create<V2>({
            items: this.filteredItems.map(item => item.value),
            onSelect: this.addHandler,
            className: style(this.props.dropDownClassName, "dropDown"),
            appendElement: placeholder ? placeholder : this.props.appendDropDownElement,
            prependElement: this.props.prependDropDownElement,
            customComponent: this.props.customOptionComponent,
            fieldName: this.props.fieldName,
            width: this.props.width,
            ...this.props.additionalDropDownProps
        })
    }

    renderPlaceholder() {
        const {emptyListPlaceholder} = this.props

        return this.filteredItems.length === 0 && emptyListPlaceholder && (React.isValidElement(emptyListPlaceholder)
            ? emptyListPlaceholder
            : <div className={style("emptyPlaceholder", this.props.size)}>
                <CPlaceholder>{emptyListPlaceholder}</CPlaceholder>
            </div>
        )
    }

    public render() {
        const {disabled} = this.props
        const input = this.renderInputBlock()

        if (disabled) {
            return input
        }

        return this.props.typeListOptions === "default"
            ? <CPopover
                element={input}
                open={this.focused}
                onClose={this.closeHandler}
                className={style("popover")}
                takeIntoElementWhenClose={true}
                horizontalOrientation="left"
            >
                <div className={style("container")}>
                    <div className={style("listContainer", {emptyContainer: this.filteredItems.length === 0})} style={{
                        maxWidth: MAX_OPTIONS_WIDTH,
                        minWidth: this.width < MAX_OPTIONS_WIDTH ? this.width : 0
                    }}>
                        {this.renderOptions()}
                    </div>
                    {this.props.children}
                </div>
            </CPopover>
            : <div>
                <div className={style("inputBox")}>{input}</div>
                {this.renderOptions()}
                {this.props.children}
            </div>
    }
}

interface CSmartSelectRef {
    ref?: React.Ref<CSmartSelect<any, any>>
}

export namespace CSmartSelect {
    export const NotFound = CNotFound
    export const EmptyLoading = CEmptyLoading

    export function single<V>(props: SingleProps<V, V> & CSmartSelectRef, ...children: React.ReactNode[]): JSX.Element
    export function single<V, V2>(props: SingleProps<V, V2> & CSmartSelectRef, ...children: React.ReactNode[]): JSX.Element
    export function single(props: SingleProps<any, any> & CSmartSelectRef, ...children: React.ReactNode[]) {
        return React.createElement(CSmartSelect, {...props, multi: false}, ...children)
    }

    export function multi<V>(props: MultiProps<V, V> & CSmartSelectRef, ...children: React.ReactNode[]): JSX.Element
    export function multi<V, V2>(props: MultiProps<V, V2> & CSmartSelectRef, ...children: React.ReactNode[]): JSX.Element
    export function multi(props: MultiProps<any, any> & CSmartSelectRef, ...children: React.ReactNode[]) {
        return React.createElement(CSmartSelect, {...props, multi: true}, ...children)
    }
}
