/**
 * Данный модуль предназначен для работы с апи Мегаплана.
 * Содержит в себе необходимые action creators, для различной работы с сущностями.
 */
import {
    BaseEntity,
    isBaseEntity
} from "./types"
import {isList, List} from "src/lib/collections"
import {
    FieldSetting,
    Task,
    Project,
    TaskRights,
    ProjectRights,
    ContractorCompany,
    ContractorHuman,
    isContractorHuman,
    EndpointMetadata, Endpoints, Employee, isTask, isProject, StringField, IntegerField, FloatField,
    BigIntField, DateField, DateTimeField, DurationField, EnumField, ExternalSourceField, BoolField, RefLinkField,
    MoneyField, RatingField, Program, PluralName
} from "src/lib/entities/bums"
import * as AdditionalMetadata from "./additionalMetadata"
import {TaskPossibleActions, ProjectPossibleActions} from "./compatibilityTypes"

export {ApiStore as Store, FETCH_FULL_NAME} from "./store/ApiStore"
export * from "src/lib/entities/bums"
export {
    GetEntityFunction,
    GetListFunction,
    getLink,
    getEntityFetchState,
    getGID,
    getListName,
    getFetchNameInSet,
    getEntityFetchStateInSet,
    isIdUnknown,
    isEntityEquals,
    getEntityTypeEndpoint,
    getListEndpoint,
    getEntityName,
    getValueName,
    isImage,
} from "./utils"
export {
    BaseEntity,
    isBaseEntity,
    isBaseValue,
    isNamedEntity,
    isFilterEntity,
    isTemplatedEntity,
    emptyList,
    NormalizedList,
    LinksList,
    ListDirection,
    Id,
    HasMore,
    DateInterval,
    RequestOptions,
    ApiResponse,
    ApiErrorResponse,
    ApiErrors,
    isApiErrorResponse,
    isApiResponse,
    isFailedDependencyResponse,
    FilterEntity,
    isApiError,
} from "./types"
export {FetchError, isFetchError} from "src/lib/utils/fetch"
export {UserStore} from "./store/UserStore"
export {ListStore} from "./store/ListStore"
export {EntitiesList} from "./store/EntitiesList"
export {EntityStore} from "./store/EntityStore"

function isFillRightsEntity(entity: Task | Project) {
    return "rights" in entity && !!entity["rights"] && !entity["possibleActions"]
}

export function hasRights<T extends BaseEntity & {
    possibleActions?: List<string>,
    availableActions?: List<string>
}>(entity: T, right: string): boolean {
    if (isTask(entity) && isFillRightsEntity(entity)) {
        if (right in entity.rights) {
            return !!entity.rights[right as keyof TaskRights]
        } else if (!!TaskPossibleActions[right] && TaskPossibleActions[right] in entity.rights) {
            return !!entity.rights[TaskPossibleActions[right] as keyof TaskRights]
        }

        return false
    } else if (isProject(entity) && isFillRightsEntity(entity)) {
        if (right in entity.rights) {
            return !!entity.rights[right as keyof ProjectRights]
        } else if (!!ProjectPossibleActions[right] && ProjectPossibleActions[right] in entity.rights) {
            return !!entity.rights[ProjectPossibleActions[right] as keyof ProjectRights]
        }

        return false
    }

    return !!((entity.possibleActions && entity.possibleActions.includes(right)) ||
        (entity.availableActions && entity.availableActions.includes(right)))
}

export function getFieldSettingValue(field: AdditionalMetadata.ExtraField, settingName: string, defaultValue?: boolean) {
    let result = defaultValue
    let setting: FieldSetting | void
    if (isList(field.settings) && (setting = field.settings.find((s) => s.name === settingName))) {
        result = setting.enabled
    }
    return result
}

const queryStringExp = /(\?.+|\/)$/

export function isExtraField(arg: any): arg is AdditionalMetadata.ExtraField {
    return !!arg && isBaseEntity(arg) && [
        StringField.contentType,
        IntegerField.contentType,
        FloatField.contentType,
        BigIntField.contentType,
        DateField.contentType,
        DateTimeField.contentType,
        DurationField.contentType,
        EnumField.contentType,
        ExternalSourceField.contentType,
        BoolField.contentType,
        RefLinkField.contentType,
        RatingField.contentType,
        MoneyField.contentType,
    ].includes((arg as AdditionalMetadata.ExtraField).contentType)
}

export type User = (Employee | ContractorHuman) & {
    possibleActions?: List<Employee.PossibleActions | ContractorHuman.PossibleActions>
}

export function getEndpointMetadata(endpoint: string): EndpointMetadata | void {
    endpoint = endpoint.replace("/api/v3", "").replace(queryStringExp, "")
    if (endpoint in Endpoints) {
        return Endpoints[endpoint]
    }

    for (let endpointName of Object.keys(Endpoints)) {
        const endpointMetadata = Endpoints[endpointName]
        if ("regExp" in endpointMetadata && endpointMetadata.regExp.test(endpoint)) {
            return Endpoints[endpointName]
        }
    }

    return void 0
}

export function getEndpointTypesByMethod(endpoint: string) {
    const metadata = getEndpointMetadata(endpoint)

    if (!metadata) {
        return void 0
    }

    return Array.isArray(metadata) ? metadata : metadata.types
}

export function getContractorPossibleActions(entity: AdditionalMetadata.Contractor) {
    return isContractorHuman(entity) ? ContractorHuman.PossibleActions : ContractorCompany.PossibleActions
}

export function getProgramPluralName(program: Program, pluralType: PluralName.Type, byDefault: string, lowerCase?: boolean) {

    if (!program || !program.isBpm || !program.bmpSettings || !isList(program.bmpSettings.pluralNames)) {
        return byDefault
    }

    const plural = program.bmpSettings.pluralNames.find(name => name.type === pluralType)
    if (plural && plural.name.trim() !== "") {
        return lowerCase ? plural.name.toLowerCase() : plural.name
    } else {
        return byDefault
    }
}
