import {Component} from "src/lib/components"
import * as React from "react"
import classNames from "classnames/bind"

const style = classNames.bind(require("./CEmpty.styl"));

// Переводы
import {FormattedMessage} from "src/lib/utils/intl"
import {MessageDescriptor} from "src/lib/utils/intl/Intl"

const commonMessages = require("src/lib/messages.yml")

export type CEmptyType = "informer_deals"
    | "informer_notice"
    | "informer_reminder"
    | "journal"
    | "chat"
    | "link"
    | "repeat"
    | "search"
    | "tags"
    | "tags_edit"
    | "todo"
    | "access_denied"
    | "reportWidget"
    | "unavailableWidget"
    | "select"
    | "bar_chart"
    | "pie_chart"
    | "event_available"

const emptyTypeTextMap: {[type: string]: string} = {
    "informer_deals" : "emptyInformerDeals",
    "informer_notice": "emptyInformerNotice",
    "informer_reminder": "emptyInformerReminder",
    "journal": "emptyJournal",
    "chat": "emptyChat",
    "link": "emptyLink",
    "repeat": "emptyRepeat",
    "search": "emptySearch",
    "tags": "emptyTags",
    "tags_edit": "emptyTagsEdit",
    "todo": "emptyTodo",
    "access_denied": "accessDenied",
    "reportWidget": "noDataToDisplay",
    "select": "emptySelectWidget",
    "unavailableWidget": "serverErrorTitle",
    "bar_chart": "noDataToDisplay",
    "pie_chart": "noDataToDisplay",
    "event_available": "noEventsAvailable",
}

export interface CEmptyProps {
    type: CEmptyType
    descriptor?: MessageDescriptor
    className?: string
}

/**
 * заглушка для пустых списков
 */
export default class CEmpty extends Component<CEmptyProps, {}> {

    public render() {
        const {type, descriptor = commonMessages[emptyTypeTextMap[type]], className} = this.props

        return <div className={style("empty", className)}>
            <span className={style("emptyImg", type)}/>
            <span className={style("emptyText")}>
                {<FormattedMessage {...descriptor} />}
            </span>
        </div>
    }
}
