import * as ReduxActions from "redux-actions"
import {CARD_SUBJECT} from "../constants"
import {CardMeta} from "../types"

export const UPDATE_UNSAVED_VALUE = "bums/task/action/UPDATE_UNSAVED_VALUE"

type UpdateUnsavedValueAction = ReduxActions.Action<{
    field: string
    value: any
}> & CardMeta

export function updateUnsavedValue(cardId: string, field: string, value: any): UpdateUnsavedValueAction {
    return {
        type: UPDATE_UNSAVED_VALUE,
        payload: {
            field,
            value
        },
        meta: {
            cardId,
            subject: CARD_SUBJECT
        }
    }
}

export function isUpdateUnsavedValueAction(action: any): action is UpdateUnsavedValueAction {
    return "object" === typeof action && UPDATE_UNSAVED_VALUE === action.type
}
