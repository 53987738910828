export enum StickyOrientation {
    NONE,
    BOTTOM,
    TOP,
    MIDDLE
}

export enum ScrollDirection {
    DOWN,
    UP,
    NONE
}

export enum PositionRelatively {
    SELF,
    PARENT,
    MIX
}

export enum StickyBehavior {
    AFTER,
    AFTER_ALL,
    SMART,
    INSIDE
}

export interface StickyPosition {
    height?: number
    width?: number
    left?: number
    priority: number
    behavior: StickyBehavior
    orientation: StickyOrientation
}

export interface Sticky {
    position: StickyPosition
    level: number
}
