import * as ReduxActions from "redux-actions"
import {CARD_SUBJECT} from "../constants"
import {CardMeta} from "../types"

export const CLEAR_UNSAVED_VALUES = "bums/task/action/CLEAR_UNSAVED_VALUES"

type ClearUnsavedValuesAction = ReduxActions.Action<{}> & CardMeta

export function clearUnsavedValues(cardId: string): ClearUnsavedValuesAction {
    return {
        type: CLEAR_UNSAVED_VALUES,
        meta: {
            cardId,
            subject: CARD_SUBJECT
        }
    }
}

export function isClearUnsavedValues(action: any): action is ClearUnsavedValuesAction {
    return "object" === typeof action && CLEAR_UNSAVED_VALUES === action.type
}
