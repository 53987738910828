import {handleActions} from "redux-actions"

import * as Actions from "./actions"

export interface State {
    promoClosed: boolean
}

export const initialState: State = {
    promoClosed: false,
}

const promoClosed = (state: State) => Object.assign({}, state, {promoClosed: true})

export const reducer = handleActions({
    [Actions.FUNNEL_PROMO_CLOSED]: promoClosed,
    [Actions.FUNNEL_PROMO_CLOSE_FAILED]: promoClosed,
}, initialState)
