import * as React from "react"
import classNames from "classnames/bind"
import {observer} from "mobx-react"
import {noop, isEqual, throttle} from "lodash"
import {autobind} from "core-decorators"
import {action, computed, observable} from "mobx"
import * as PropTypes from "prop-types"
import {inject} from "src/lib/utils/inject"
import {getAndNormalizeBoundClientRect} from "src/lib/utils/window"
import {bindArg, makeRefHandler} from "src/lib/utils/func"
import {CWindowEvent, Component, CAdapt} from "src/lib/components"
import {CScrollEvent} from "src/lib/components/CHotKey/CScrollEvent"
import {StickyStore} from "./StickyStore"
import {StickyOrientation, PositionRelatively, ScrollDirection, Sticky, StickyBehavior, StickyPosition} from "./types"
import {INFORMER_PANEL_HEIGHT} from "src/bums/common/constants"

export * from "./types"

const style = classNames.bind(require("./CSticky.styl"))

/**
 * Компонент закрепляющий блок
 */

/*
 Компонент для закрепления блоков
 Пример использования:

 import {CSticky} from "src/lib/components"

 render() {
     return (
         <CSticky>
            {content}
         </CSticky>
     )
 }

 В приведённом выше примере content будет закреплен в зависимости от передаваемых пропсов. По умолчанию задается

 stickyBehavior: StickyBehavior.AFTER
 positionRelatively: PositionRelatively.SELF
 priority: 0

 что означает, что content будет закреплен после того, как другие объекты, обернутые в CSticky пересекут верхнюю границу
 данного компонента, при этом будет иметь наивысший приоритет (0) над другими компонентами, и не будет учитывать тех, которые
 имею приоритет ниже (0+).

 Реализовано 3 варианта поведения:
     SMART - Компонент в данном состоянии старается постоянно находиться на экране пользователя,
     учитывая другие компоненты CSticky сверху и отступ снизу 38px
     INSIDE - Компонент будет прикрепляться сверху внутри родителя, используется с Position PARENT
     AFTER - Компонент прикрепляется после того, как до конца компонента, относительно которого прикрепляется позиционируемый
     компонент, остается высота данного компонента
     AFTER_ALL - Компонент прикрепляется сразу после окончания компонента, относительного которого прикрепляется

 Реализовано 3 варианта позиционирования относительно:
     SELF - самого себя и не скрывается ДО закрепления, оставляя после себя dummy div после
     PARENT - родителя и скрывается, скрывается ДО закрепления
     MIX - позиционирование относительно родителя, но не скрывается ДО закрепления, оставляя после себя dummy div после

 Реализовано 3 позиции для компонентов внутри данного элемента:
    TOP - сверху, учитывая все другие компоненты CSticky по приоритету (нижний приоритет не учитывает высший) и level (порядок закрепления)
    BOTTOM - снизу, учитывая 38px
    MIDDLE - контент без закрепления с отступом сверху в зависимости от проскролленного расстояния
 */

export interface CStickyProps {
    onSticked? : () => void
    onStickyStateChange?: (state: StickyOrientation) => void
    className?: string
    // добавляется при переходе в фиксированное состояние
    fixClassName?: string
    parentClassName?: string
    style?: React.CSSProperties
    positionRelatively?: PositionRelatively // Позиционирование относительно себя или родителя
    // self - позиционируется относительно своего контейнера, при перемещении создается dummy объект на месте,
    // где был перемещаемый объект
    // parent - позиционируется относительно родителя, исчезает при отсутствии прикрепления
    // mix - позиционируется относительно родителя, но ведет себя как self
    stickyBehavior?: StickyBehavior // Закрепление после или внутри элемента, относительно которого происходит позицонирование
    isCard?: boolean // Флаг для отступов карточки
    adaptiveType?: CAdapt.Type // Закреплять на всех видах экранов либо только на выбранном
    priority?: number // Приоритет объекта над остальными. 0 - максимальный. Компоненты высокого приоритета НЕ учитывают
    //компоненты низкого приоритета
    level?: number
    ignoreStickyStore?: boolean
}

declare global {
    interface Window {
        MozMutationObserver: any
        WebKitMutationObserver: any
        MutationObserver: any;
    }
}


@observer
@autobind
export class CSticky extends Component<CStickyProps, {}> {

    public static StickyBehavior = StickyBehavior
    public static PositionRelatively = PositionRelatively

    public static defaultProps = {
        onSticked: noop,
        onStickyStateChange: noop,
        stickyBehavior: StickyBehavior.AFTER,
        positionRelatively: PositionRelatively.SELF,
        priority: 0,
    }

    public static contextTypes = {
        [CAdapt.Provider.contextTypeKey]: PropTypes.object
    }

    @inject(StickyStore)
    private stickyStore: StickyStore

    private adaptive = new CAdapt.Observable()

    @observable.ref
    public stickyComponent: HTMLElement

    @observable.ref
    public mainStickyComponent: HTMLElement

    @observable.ref
    public scrollEvent: CScrollEvent

    @observable
    public position: StickyPosition

    @observable
    private stickyId = "-1"

    private observer: MutationObserver

    private lastScrollTop = 0

    @observable
    private dummyHeight = 0

    @observable
    private sticky: StickyOrientation = StickyOrientation.NONE

    private throttleRecalculatePosition = throttle(this.recalculatePosition, 50)

    @computed
    private get currentContextAdaptiveType() {
        return this.context[CAdapt.Provider.contextTypeKey] ? this.context[CAdapt.Provider.contextTypeKey].get() : null
    }

    @computed
    private get currentAdaptiveType() {
        return this.currentContextAdaptiveType || this.adaptive.type
    }

    @action
    componentWillMount() {
        this.position = {
            behavior: this.props.stickyBehavior,
            priority: this.props.priority,
            orientation: this.sticky
        }

        if (this.props.stickyBehavior === StickyBehavior.SMART) {
            this.stickyStore.addSmartCard()
        }
    }

    @computed
    private get container() {
        return this.props.positionRelatively === PositionRelatively.SELF
            ? this.mainStickyComponent
            : this.mainStickyComponent && (this.mainStickyComponent.parentNode as HTMLElement)
    }

    componentDidMount() {
        this.props.onSticked()
        this.props.onStickyStateChange(this.sticky)
        this.recalculatePosition()

        if (this.props.stickyBehavior === StickyBehavior.SMART) {
            if (this.container) {
                this.observer = new MutationObserver(bindArg(this.throttleRecalculatePosition, true))
                this.observer.observe(this.container, {
                    childList: true,
                    characterData: true,
                    subtree: true
                });
            }
        }
    }


    componentWillUnmount() {
        if (this.props.stickyBehavior === StickyBehavior.SMART) {
            this.stickyStore.removeSmartCard()
        }
        if (this.observer) {
            this.observer.disconnect()
        }
        if (this.stickyId !== "-1") {
            this.stickyStore.deleteStickyComponent(this.stickyId)
        }
    }

    componentDidUpdate() {
        this.throttleRecalculatePosition()
        this.props.onSticked()
        this.props.onStickyStateChange(this.sticky)
    }

    @action
    recalculatePosition(noneDirection = false) {
        if (this.stickyComponent && this.mainStickyComponent) {
            const stickyRect = this.props.positionRelatively === PositionRelatively.SELF
                ? getAndNormalizeBoundClientRect(this.mainStickyComponent)
                : getAndNormalizeBoundClientRect(this.stickyComponent)

            const height = stickyRect.height;

            if (height) {
                const width = stickyRect.width;
                const left = stickyRect.left;

                const newPosition = {
                    height: height,
                    width: width,
                    left: left,
                    behavior: this.props.stickyBehavior,
                    priority: this.props.priority,
                    orientation: this.sticky
                }

                if (this.stickyId !== "-1") {
                    try {
                        if (this.stickyStore.stickyComponents.has(this.stickyId)) {
                            const postion = this.stickyStore.stickyComponents.get(this.stickyId).position
                            if (
                                postion.height !== newPosition.height ||
                                postion.width !== newPosition.width  ||
                                postion.left !== newPosition.left
                            ) {
                                this.stickyStore.updateStickyComponent(this.position, this.stickyId)
                            }
                        }
                    } catch (e) {
                        this.stickyId = "-1"
                    }
                }

                if (!isEqual(this.position, newPosition)) {
                    Object.assign(this.position, newPosition)
                }

                this.recomputeToggle(noneDirection)
            }
        }
    }

    private get scrollTop() {
        const container = this.scrollEvent.scrollParent
        if (container instanceof Window) {
            return (window.pageYOffset !== undefined) ?
                window.pageYOffset : (document.documentElement || document.body).scrollTop
        } else {
            return container.scrollTop
        }
    }

    private get scrollHeight() {
        const container = this.scrollEvent.scrollParent
        if (container instanceof Window) {
            return document.body.clientHeight
        } else {
            return container.clientHeight
        }
    }

    private get scrollTopPosition() {
        const container = this.scrollEvent.scrollParent
        if (container instanceof Window) {
            return 0
        } else {
            return getAndNormalizeBoundClientRect(container).top
        }
    }

    private scrollBy(x: number, y: number) {
        const container = this.scrollEvent.scrollParent
        if (container instanceof Window) {
            window.scrollBy(x, y);
        } else {
            container.scrollBy(x, y)
        }
    }

    @action
    private recomputeToggle(noneDirection = false) {
        if (this.container) {
            let newSticky: StickyOrientation = this.sticky
            const containerRect = getAndNormalizeBoundClientRect(this.container)
            const fullStickyHeight = this.stickyStore.fullStickyHeight(this.stickyId, this.position)

            let scrollDirection: ScrollDirection

            if (this.scrollTop > this.lastScrollTop) {
                scrollDirection = ScrollDirection.DOWN
            } else if (this.scrollTop < this.lastScrollTop) {
                scrollDirection = ScrollDirection.UP
            } else if (noneDirection) {
                scrollDirection = ScrollDirection.NONE
            } else {
                return
            }

            this.lastScrollTop = this.scrollTop
            let dummyHeight = 0

            // Проверка на выбранный экран
            if ((!this.props.adaptiveType || this.currentAdaptiveType === this.props.adaptiveType)) {
                const top = containerRect.top
                const height = containerRect.height

                switch (this.props.stickyBehavior) {
                    case StickyBehavior.INSIDE:
                        if (top - fullStickyHeight < this.scrollTopPosition &&
                            -top + fullStickyHeight + this.scrollTopPosition <
                            height - this.position.height) {

                            newSticky = StickyOrientation.TOP
                        } else {
                            newSticky = StickyOrientation.NONE
                        }
                        break;
                    case StickyBehavior.SMART:
                        const stickyRect = getAndNormalizeBoundClientRect(this.stickyComponent)

                        if (
                            (
                                -top + fullStickyHeight + this.scrollTopPosition < this.dummyHeight
                                || this.sticky === StickyOrientation.TOP
                            )
                            && -top + fullStickyHeight > this.scrollTopPosition
                            && scrollDirection === ScrollDirection.UP
                        ) {
                            if (this.stickyStore.isAllSmartDummyTop) {
                                this.scrollBy(0, top - fullStickyHeight)
                            }

                            newSticky = StickyOrientation.TOP
                        } else if (
                            stickyRect.height <= this.scrollHeight - INFORMER_PANEL_HEIGHT - fullStickyHeight
                            && -top + fullStickyHeight > 0
                        ) {
                            newSticky = StickyOrientation.TOP
                        } else if (
                            (height < -top + this.scrollHeight - INFORMER_PANEL_HEIGHT - this.scrollTopPosition)
                            && this.sticky !== StickyOrientation.TOP && -top + fullStickyHeight > 0
                            && scrollDirection === ScrollDirection.DOWN
                        ) {
                            newSticky = StickyOrientation.BOTTOM
                        } else if (-top + fullStickyHeight > this.scrollTopPosition) {
                            const stickyHeight = this.props.positionRelatively === PositionRelatively.SELF
                                ? stickyRect.height
                                : this.position.height
                            if (this.sticky === StickyOrientation.BOTTOM) {
                                dummyHeight = -top + this.scrollHeight - INFORMER_PANEL_HEIGHT - stickyHeight
                            } else if (this.sticky === StickyOrientation.TOP) {
                                dummyHeight = -top + fullStickyHeight
                            }

                            if (dummyHeight <= 0) {
                                dummyHeight = -containerRect.top
                            }

                            newSticky = StickyOrientation.MIDDLE
                        } else {
                            newSticky = StickyOrientation.NONE
                        }

                        if (dummyHeight < 0) {
                            dummyHeight = 0
                        }

                        break;
                    case StickyBehavior.AFTER_ALL:
                        if (-top + fullStickyHeight + this.scrollTopPosition > containerRect.height) {
                            newSticky = StickyOrientation.TOP
                        } else {
                            newSticky = StickyOrientation.NONE
                        }
                        break;
                    default:
                        if (-top + fullStickyHeight + this.scrollTopPosition > height - this.position.height) {
                            newSticky = StickyOrientation.TOP
                        } else {
                            newSticky = StickyOrientation.NONE
                        }
                        break;
                }
            } else {
                newSticky = StickyOrientation.NONE
            }

            if (this.sticky !== newSticky) {
                this.position.orientation = newSticky

                if (this.stickyId === "-1" && newSticky !== StickyOrientation.NONE) {
                    if (this.position.height && (newSticky === StickyOrientation.TOP || newSticky === StickyOrientation.BOTTOM)) {
                        this.stickyId = this.stickyStore.setStickyComponent(this.position, this.props.level)
                    }
                } else {
                    if (this.stickyId !== "-1") {
                        this.stickyStore.deleteStickyComponent(this.stickyId);
                        this.stickyId = "-1"
                    }
                }

                if (newSticky === StickyOrientation.MIDDLE && dummyHeight !== 0 || newSticky !== StickyOrientation.MIDDLE) {
                    this.sticky = newSticky
                    this.dummyHeight = dummyHeight
                }
            }
        }
    }

    @computed
    private get isClone() {
        return this.props.positionRelatively === PositionRelatively.SELF || this.props.positionRelatively === PositionRelatively.MIX
    }

    @computed
    private get isSticked() {
        return this.sticky !== StickyOrientation.NONE
    }

    @computed
    private get styles() {
        const stickyStyle = Object.assign({}, this.props.style)
        const dummyStyle = Object.assign({}, this.props.style)

        const sticky: Sticky = this.stickyStore.stickyComponents.get(this.stickyId)

        switch (this.sticky) {
            case StickyOrientation.TOP:

            const fullStickyHeight = this.props.ignoreStickyStore ? 0 : this.stickyStore.fullStickyHeight(this.stickyId, this.position)

                const top = fullStickyHeight + this.scrollTopPosition

                Object.assign(stickyStyle, {
                    top: top,
                    position: "fixed"
                })

                if (this.isClone) {
                    Object.assign(stickyStyle, {
                        width: sticky ? sticky.position.width : this.position.width,
                        left: sticky ? sticky.position.left : this.position.left
                    })
                }

                if (stickyStyle.zIndex === void 0) {
                    stickyStyle.zIndex = 20
                }

                const dummyHeight = this.dummyHeight || this.position.height

                if (dummyStyle.height === void 0) {
                    dummyStyle.height = dummyHeight
                }

                break;
            case StickyOrientation.MIDDLE:
                if (dummyStyle.height === void 0) {
                    dummyStyle.height = this.dummyHeight
                }

                break;
            case StickyOrientation.BOTTOM:
                if (dummyStyle.height === void 0) {
                    dummyStyle.height = this.dummyHeight ? this.dummyHeight : sticky ? sticky.position.height : this.position.height
                }

                Object.assign(stickyStyle, {
                    bottom: INFORMER_PANEL_HEIGHT,
                    position: "fixed"
                })

                if (stickyStyle.zIndex === void 0) {
                    stickyStyle.zIndex = 20
                }

                if (this.isClone) {
                    Object.assign(stickyStyle, {
                        width: sticky ? sticky.position.width : this.position.width,
                        left: sticky ? sticky.position.left : this.position.left
                    })
                }

                break;
            default:
                if (!this.isClone) {
                    Object.assign(stickyStyle, {visibility: "hidden"})
                }
                break;
        }

        return {
            stickyStyle,
            dummyStyle
        }
    }

    public render() {
        const {stickyStyle, dummyStyle} = this.styles
        return <div
            className={style({cardParent: this.props.isCard}, this.props.parentClassName)}
            ref={makeRefHandler(this, "mainStickyComponent")}
        >
            <CWindowEvent event="resize" handler={bindArg(this.throttleRecalculatePosition, false)}/>
            <CScrollEvent
                ref={makeRefHandler(this, "scrollEvent")}
                component={this.stickyComponent}
                handler={bindArg(this.throttleRecalculatePosition, false)}
            />
            {this.isClone && <div style={dummyStyle} className={this.props.className}/>}
            <div
                ref={makeRefHandler(this, "stickyComponent")}
                style={stickyStyle}
                className={style(
                    "sticky",
                    {
                        on: this.isSticked || this.isClone,
                        card: this.props.isCard,
                        [this.props.className]: !!this.props.className,
                        [this.props.fixClassName]: this.props.fixClassName && this.isSticked
                    }
                )}>
                    {this.props.children}
            </div>
        </div>
    }
}
