import {CButton, CIconType, Component} from "src/lib/components"
import * as React from "react"
import * as ReactDOM from "react-dom"
import classNames from "classnames/bind"
import {makeRefHandler} from "src/lib/utils/func"
import {CSSTransitionGroup} from "react-transition-group"
import {CHotKey} from "../CHotKey/CHotKey"
import {observer} from "mobx-react";
import DOM from "react-dom-factories"

const style: any = classNames.bind(require("./CModal.styl"))

interface CModalProps {
    open?: boolean
    onClose?: () => void
    contentPosition?: ContentPosition
    inlineRender?: boolean
    animateClass?: string
    closeOnEsc?: boolean
    autoOverflow?: boolean
    maxWidth?: number
    className?: string
    noShadow?: boolean
    zLevel?: ZLevel
    disableCloseOnOutsideClick?: boolean
    hasCloseButton?: boolean
}

export enum ZLevel {
    LEVEL_1,
    LEVEL_2,
    LEVEL_3,
}

const levels = {
    [ZLevel.LEVEL_1]: "level1",
    [ZLevel.LEVEL_2]: "level2",
    [ZLevel.LEVEL_3]: "level3",
}

export enum ContentPosition {
    CENTER,
    CENTERLEFT,
    TOPCENTER,
    RIGHT
}

const contentPositionMap = {
    [ContentPosition.CENTER]: "center",
    [ContentPosition.CENTERLEFT]: "center-left",
    [ContentPosition.TOPCENTER]: "top-center",
    [ContentPosition.RIGHT]: "right",
}


/*TODO: переписать CModal на CPopover*/
@observer
export class CModal extends Component<CModalProps, {}> {

    public static Position = ContentPosition

    public modalElement: HTMLDivElement
    public container: React.ReactInstance

    public static defaultProps = {
        open: false,
        inlineRender: false,
        contentPosition: ContentPosition.CENTER,
        zLevel: ZLevel.LEVEL_1
    }

    public componentDidMount() {
        if (!this.props.inlineRender) {
            this.modalElement = document.createElement("div")
            this.modalElement.className = style("_sc", "modal")
            this.modalElement.style.zIndex = "1000"
            document.body.appendChild(this.modalElement)
            this.renderModal()
        }
    }

    public componentDidUpdate() {
        if (!this.props.inlineRender) {
            this.renderModal()
        }
    }

    public componentWillUnmount() {
        if (!this.props.inlineRender) {
            ReactDOM.unmountComponentAtNode(this.modalElement);
            document.body.classList.remove(style("no-scroll"))
            if (this.modalElement.parentNode) {
              this.modalElement.parentNode.removeChild(this.modalElement);
            }
            this.modalElement = null
        }
    }


    private toggleClass(element: Element, className: string, value: boolean) {
        if (value) {
            element.classList.add(className)
        } else {
            element.classList.remove(className)
        }
    }

    private renderModal(): void {
        ReactDOM.unstable_renderSubtreeIntoContainer(this, this.renderModalComponent(), this.modalElement)
        this.toggleClass(document.body, style("no-scroll"), this.props.open)
    }

    private onClick = (event: React.MouseEvent<HTMLElement>): void => {
        if (this.props.disableCloseOnOutsideClick) {
            return
        }
        const target = event.target as HTMLElement
        const container = ReactDOM.findDOMNode(this.container) as HTMLElement
        if (target === container && this.props.onClose) {
            this.props.onClose()
        }
    }

    private renderHotKey() {
        if (this.props.closeOnEsc && this.props.onClose) {
            return <CHotKey keyMap={["-", "escape", this.props.onClose]} stack={true}/>
        }
        return null
    }

    private renderContent(): JSX.Element {
        const className = style(
            "content-container",
            contentPositionMap[this.props.contentPosition],
            {autoOverflow: this.props.autoOverflow},
            levels[this.props.zLevel]
        )

        return <div
            className={className}
            onClick={this.onClick}
            ref={makeRefHandler(this, "container")}
            data-name="cmodal"
        >
            <div
                className={style(
                    "wrapper",
                    {
                        "noShadow" : this.props.noShadow,
                        "wrapperHasCloser": this.props.hasCloseButton
                    },
                    this.props.className)}
                style={{width: `${this.props.maxWidth}px`}}
            >
                {this.renderHotKey()}
                {this.props.children}
                {this.props.hasCloseButton
                    ? <CButton
                        name="closeModalBtn"
                        icon={CIconType.CANCEL}
                        type={CButton.Type.FLAT}
                        onClick={this.props.onClose}
                        className={style("closer")}
                    />
                    : null
                }
            </div>
        </div>
    }

    private renderModalComponent(): JSX.Element {

        if (this.props.animateClass) {
            return <CSSTransitionGroup
                    transitionName={this.props.animateClass}
                    transitionEnterTimeout={1000}
                    transitionLeaveTimeout={1000}
                >
                    {this.props.open ? this.renderContent() : null}
                </CSSTransitionGroup>
        }

        if (!this.props.open) {
            return DOM.noscript()
        }

        return this.renderContent()
    }

    public render() {
        if (this.props.inlineRender) {
            return this.props.open
                ? <div
                    className={style("_sc", "inline", "modal")}
                    style={{zIndex: 1000}}
                >
                    {this.renderHotKey()}
                    {this.renderModalComponent()}
                </div>
                : null
        }
        return null
    }
}
