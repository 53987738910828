import * as ReduxActions from "redux-actions"
import {CARD_SUBJECT} from "../constants"
import {CardMeta} from "../types"

export const SHOW_POPUP = "src/lib/CCardContainer/action/SHOW_POPUP"

type ShowPopupAction = ReduxActions.Action<boolean> & CardMeta

export function showPopup(cardId: string, show: boolean): ShowPopupAction {
    return {
        type: SHOW_POPUP,
        payload: show,
        meta: {
            cardId,
            subject: CARD_SUBJECT
        }
    }
}

export function isShowPopupAction(action: any): action is ShowPopupAction {
    return "object" === typeof action && SHOW_POPUP === action.type
}
