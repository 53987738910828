/* tslint:disable */
import * as H from "../metadataHelper"
import {TodoStatus} from "./todoStatus"
import {File} from "./file"
import {StringField} from "./stringField"
import {BoolField} from "./boolField"

export interface TodoFinishActionRequest extends H.BaseValue {
    status?: TodoStatus;
    resultText?: string;
    resultAttaches?: H.List<File>;
    notifyContractors?: boolean;
    contentType: "TodoFinishActionRequest"; // Object type
}

export module TodoFinishActionRequest {
    export const contentType: "TodoFinishActionRequest" = "TodoFinishActionRequest";
    export const endpoint = "/api/v3/todoFinishActionRequest";

    export const newObject: TodoFinishActionRequest = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const status = {contentType: "RefLinkField" as "RefLinkField", name: "status", isMultiple: false, isSortable: false, id: "TodoFinishActionRequest.status", refContentType: H.List(["TodoStatus"])};
        export const resultText = {contentType: "StringField" as "StringField", name: "resultText", isMultiple: false, isSortable: false, id: "TodoFinishActionRequest.resultText", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const resultAttaches = {contentType: "RefLinkField" as "RefLinkField", name: "resultAttaches", isMultiple: true, isSortable: false, id: "TodoFinishActionRequest.resultAttaches", refContentType: H.List(["File"])};
        export const notifyContractors = {contentType: "BoolField" as "BoolField", name: "notifyContractors", isMultiple: false, isSortable: false, id: "TodoFinishActionRequest.notifyContractors", defaultValue: null as BoolField["defaultValue"]};
    }
}

export function isTodoFinishActionRequest(arg: any): arg is TodoFinishActionRequest {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "TodoFinishActionRequest"
}
