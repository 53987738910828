import {autobind} from "core-decorators"
import {computed, action, observable} from "mobx"
import {arrayMove} from "react-sortable-hoc"
import * as Api from "src/lib/entities/api"
import {MainMenuStore} from "./MainMenuStore"

type TransformAxis = {x: number, y: number}

@autobind
export class MainMenuStateStore {

    @observable.ref
    private $hoverItem: Api.MenuItem = null

    @observable.ref
    private $dragItem: Api.MenuItem = null

    @observable
    public dragTransform: TransformAxis = {x: 0, y: 0}

    @observable
    public isHoverContainer = false

    @observable
    private $editMode = false

    constructor(private $mainMenuStore: MainMenuStore) {}

    @computed
    public get isHovered() {
        return !this.$dragItem && !!this.$hoverItem
    }

    @computed
    public get isDragged() {
        return !!this.$dragItem
    }

    @computed
    public get currentColor() {
        if (!this.isDragged && this.$hoverItem) {
            return this.$hoverItem.color
        }

        return this.$mainMenuStore.currentColor
    }

    @computed
    public get editMode() {
        return this.$editMode
    }

    @computed
    public get mainMenuStore() {
        return this.$mainMenuStore
    }

    @computed
    public get dragItem() {
        return this.$dragItem
    }

    @action
    public toggleEditMode(value: boolean) {
        this.$editMode = value
    }

    @action
    public changeHoverItem(item: Api.MenuItem | null) {
        this.$hoverItem = item
    }

    @action
    public toggleHoverContainer() {
        this.isHoverContainer = !this.isHoverContainer
    }

    @action
    public startDrag(index: number, transform: TransformAxis) {
        this.$dragItem = this.$mainMenuStore.allMainItems[index]
        this.changeDragTransform(transform)
    }

    @action
    public changeDragTransform(transform: TransformAxis) {
        this.dragTransform = transform
    }

    @action
    public stopDrag(fromIndex: number, toIndex: number) {
        void this.$mainMenuStore.sortOrderUserSetting.set(
            arrayMove(
                this.$mainMenuStore.allMainItems.map(entity => entity.id),
                fromIndex,
                toIndex
            )
        )
        this.$dragItem = null
        this.changeHoverItem(null)
        this.dragTransform = {x: 0, y: 0}
    }

    public checkItemIsHover(entity: Api.MenuItem) {
        return !this.$dragItem && this.$hoverItem === entity
    }

    public checkItemIsDragged(entity: Api.MenuItem) {
        return entity === this.$dragItem
    }

    public checkItemIsActive(entity: Api.MenuItem) {
        return this.$mainMenuStore.checkItemIsActive(entity)
    }

    public checkSubItemIsActive(entity: Api.MenuItem) {
        return this.$mainMenuStore.checkSubItemIsActive(entity)
    }
}
