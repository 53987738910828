import {action, computed, observable, ObservableSet} from "mobx";
import {
    FirstConfigureFormValue,
    Industries,
    POLL_RESULT_SYSTEM_SETTING_ID,
    PollMessagesKeyMap
} from "src/bums/common/onboarding/utils";
import {inject} from "src/lib/utils/inject"
import {FeatureStore} from "src/bums/common/stores/FeatureStore";
import {autobind} from "core-decorators";
import {Form} from "src/lib/utils/form/form";
import {Intl} from "src/lib/utils/intl"
import {OnboardingPollStepsFactory} from "src/bums/common/onboarding/firstConfigure/store/OnboardingPollStepsFactory";
import {ApiStore} from "src/lib/entities/store/ApiStore";
import * as Api from "src/lib/entities/api"
import {UserStore} from "src/lib/entities/api"

const messages = require("../messages.yml")

export enum StepId {
    Greeting,
    ForWhom,
    Industry,
    NameOfYourCompany,
    YourRole,
    HowManyEmployees,
    ExperienceWithCRM,
    NameAndPhone,
    NamePhoneAndCompany
}

export interface StepConfig {
    nextStepFactory: () => StepId | null
    skipPollFactory?: () => StepId | null
    onEnterToStep?: () => void
    canStepNext?: () => boolean
    customButtons?: boolean
    title?: () => string
}

@autobind
export class OnboardingPollStore {
    private $form = new Form<FirstConfigureFormValue>(() => (
        {
            howManyEmployees: null,
            chooseRole: null,
            chooseIndustry: null,
            forWhom: null,
            experienceWithCRM: null,
            nameOfYourCompany: null,
        }
    ))

    @observable
    private canCancelSteps: StepId[] = [StepId.Greeting]

    @observable
    private $step: StepId

    @observable
    private stepStack: number[] = []

    private stepsFactory: OnboardingPollStepsFactory

    constructor(
        @inject(ApiStore) private apiStore: ApiStore,
        @inject(FeatureStore) private featureStore: FeatureStore,
        @inject(Intl) private intl: Intl,
        @inject(UserStore) private userStore: UserStore,
    ) {
        this.setFirstStep()
        this.stepsFactory = new OnboardingPollStepsFactory(this, this.featureStore, intl, this.userStore)
    }

    @computed
    public get form() {
        return this.$form
    }

    @computed
    public get stepsMap() {
        return this.stepsFactory.stepsMap
    }

    @action
    private setFirstStep() {
        const isRegistrationWithEmail = this.featureStore.isAvailable("bums.common.registration_with_email") && !this.userStore.phone
        const isPhoneAtTheEnd = this.featureStore.isAvailable("bums.common.onboarding_poll_phone_end")
        if (isRegistrationWithEmail && !isPhoneAtTheEnd) {
            this.$step = StepId.NameAndPhone
            this.setCanCancelSteps([StepId.ForWhom])
        } else {
            this.$step = StepId.Greeting
        }
    }

    @action
    public takeStep(stepBack: boolean = false) {
        // Проверяем, в каких случаях нужно пропустить тот или иной вопрос
        if (stepBack) {
            this.$step = this.previousStep
            this.stepStack.pop()
        } else {
            this.stepStack.push(this.$step)
            this.$step = this.currentPollPoint.nextStepFactory()
        }

        if (this.currentPollPoint.onEnterToStep) {
            this.currentPollPoint.onEnterToStep()
        }
    }

    @action
    public takeSpecificStep(stepId: StepId) {
        this.$step = stepId
        this.currentPollPoint.onEnterToStep?.()
    }

    @computed
    public get currentPollPoint() {
        return this.stepsMap.get(this.$step)
    }

    @computed
    public get stepTitle(): string {
        return this?.currentPollPoint?.title?.() || ""
    }

    @computed
    private get previousStep() {
        return this.stepStack.length
            ? this.stepStack[this.stepStack.length - 1]
            : StepId.Greeting
    }

    @computed
    public get step() {
        return this.$step
    }

    @computed
    public get canCancel() {
        return this.canCancelSteps.indexOf(this.$step) > -1
    }

    @action
    public setCanCancelSteps(steps: StepId[]) {
        return this.canCancelSteps = steps
    }

    @computed
    public get isFirstStep() {
        return StepId.Greeting === this.step || StepId.NameAndPhone === this.step
    }

    @computed
    public get isRequiredQuestion() {
        return StepId.NameAndPhone === this.step || StepId.NamePhoneAndCompany === this.step
    }

    @computed
    public get isLastStep() {
        return !this.currentPollPoint.nextStepFactory()
    }

    @computed
    public get answers() {
        let result = ""
        for (let key in this.form.value) {
            const answer = this.form.get(key as keyof FirstConfigureFormValue)
            result += this.getAnswerTranslation(key as keyof FirstConfigureFormValue, answer)
        }

        return result
    }

    @computed
    public get chooseIndustryAnswer() {
        const answer = this.form.get("chooseIndustry")

        return this.isCustomIndustryAnswer
            ? answer || ""
            : this.intl.formatMessage(messages[answer])
    }

    @computed
    public get isCustomIndustryAnswer() {
        const answer = this.form.get("chooseIndustry")

        return !(answer in Industries)
    }

    @computed
    public get isCustomNameOfYourCompanyAnswer() {
        const answer = this.form.get("nameOfYourCompany")

        return !!answer && answer !== this.intl.formatMessage(messages.myCompanyOOO)
    }

    @computed
    public get canStepNext() {
        return !this.currentPollPoint.canStepNext
            || this.currentPollPoint.canStepNext()
    }

    public getCurrentQuestionText(stepId?: StepId) {
        const stepMessages = PollMessagesKeyMap.get(stepId || this.step)
        return stepMessages ? this.intl.formatMessage(messages[stepMessages.questionMessageKey]) : ""
    }

    @computed
    public get currentAnswerText() {
        const stepMessages = PollMessagesKeyMap.get(this.step)
        const answer = stepMessages ? this.form.get(stepMessages.answerKey) : ""
        return answer ? this.getAnswerTranslation(stepMessages.answerKey, answer) : ""
    }

    public getAnswerTranslation(key: keyof FirstConfigureFormValue, answer: string | Set<string>) {
        let result = ""
        if (typeof answer === "string") {
            if ("chooseIndustry" === key) {
                result =  `${this.chooseIndustryAnswer};`
            } else if ("nameOfYourCompany" === key) {
                result =  `${answer};`
            } else {
                result = `${this.intl.formatMessage(messages[answer])};`
            }
        } else if ((answer instanceof Set) || (answer as any instanceof ObservableSet)) {
            (answer as Set<string>).forEach((answerInSet: string) => {
                result += `${this.intl.formatMessage(messages[answerInSet])};`
            })
        }
        return result
    }

    @action
    public savePollResults(pollWasCanceled: boolean, comment: string) {
        // сохраняем результаты опроса на бекенде
        const pollResult = {
            ...Api.OnboardingPollResult.newObject,
            pollWasCanceled,
            comment,
            isCustomIndustry: this.isCustomIndustryAnswer,
            isCustomNameOfYourCompany: this.isCustomNameOfYourCompanyAnswer
        }
        for (let key in this.form.value) {
            const answer = this.form.get(key as keyof FirstConfigureFormValue)
            if (null != answer) {
                (pollResult as any)[key] = answer
            }
        }
        this.apiStore.update<Api.SystemSetting<Api.OnboardingPollResult>>({
            ...Api.SystemSetting.newObject,
            id: POLL_RESULT_SYSTEM_SETTING_ID,
            value: pollResult
        });
    }
}
