import * as React from "react"
import {observer} from "mobx-react"
import classNames from "classnames/bind"
import {autobind} from "core-decorators"
import {CButton, CHint, CIcon, CInput, Component} from "src/lib/components"
import {inject} from "src/lib/utils/inject"
import {Intl} from "src/lib/utils/intl"
import {Form} from "src/lib/utils/form/form";
import {formValidationErrorType, FormValidator} from "src/lib/utils/form/validation";
import {action, observable} from "mobx";
import CFieldRow from "src/lib/components/CFieldRow/CFieldRow";
import {UserStore} from "src/lib/entities/api"
import {ApiStore} from "src/lib/entities/store/ApiStore"
import * as Collections from "src/lib/collections";
import {OwnError} from "src/lib/utils/formError";
import {iso639ToCountyCode, parsePhoneNumberFromString} from "src/lib/utils/phonenumber"
import {OnboardingPollStore, StepId} from "src/bums/common/onboarding/firstConfigure/store/OnboardingPollStore";
import {Tracker} from "src/bums/common/stores/Tracker";
import {ContactInfo, Employee} from "src/lib/entities/bums"
import {makeRefHandler} from "src/lib/utils/func";
import {createFetchErrors} from "src/lib/utils/form/types";

const style = classNames.bind(require("./styles/CRegistrationWithEmail.styl"))
const onboardingMessages = require("../firstConfigure/messages.yml")
const libMessages = require("src/lib/messages.yml")

export interface RegistrationWithEmailFormValue {
    firstName: string
    lastName: string
    phone: string
    smsCode: string
}

export namespace CRegistrationWithEmail {
    export interface Props {
        changeStepHandler: () => void
        askCompanyName?: boolean
    }
}

@autobind
class RegistrationForm extends Form<RegistrationWithEmailFormValue> {

    constructor(
        valueFactory: () => RegistrationWithEmailFormValue,
        private intl: Intl,
    ) {
        super(valueFactory)
    }

    protected get validator() {
        const validationMap = new FormValidator<RegistrationWithEmailFormValue>()
        validationMap.set("firstName", FormValidator.nonEmptyFieldValidator(this.intl.formatMessage(libMessages["emptyFieldError"])))
        validationMap.set("lastName", FormValidator.nonEmptyFieldValidator(this.intl.formatMessage(libMessages["emptyFieldError"])))
        validationMap.set("phone", FormValidator.nonEmptyFieldValidator(this.intl.formatMessage(libMessages["emptyFieldError"])))
        validationMap.set("phone", this.phoneValidator)
        return validationMap
    }

    private phoneValidator(value: string) {
        const phone = parsePhoneNumberFromString(value, iso639ToCountyCode(this.intl.locale))
        return phone?.isValid()
            ? void 0
            : { type: "error", message: this.intl.formatMessage(libMessages["inValidPhone"]) }
    }

    public getValueForSave() {
        return {...this.value, phone: parsePhoneNumberFromString(this.value.phone, iso639ToCountyCode(this.intl.locale)).number as string}
    }
}

@observer
@autobind
export class CRegistrationWithEmail extends Component<CRegistrationWithEmail.Props, {}> {

    public smsInputNode: CInput

    @inject(Intl)
    private intl: Intl

    @inject(UserStore)
    private currentUser: UserStore

    @inject(ApiStore)
    private apiStore: ApiStore

    @inject(OnboardingPollStore)
    private onboardingPollStore: OnboardingPollStore

    @inject(Tracker)
    private tracker: Tracker

    private form: RegistrationForm

    private get phonePlaceholder() {
        return `+${window?.sdf.settings.get("locale.default_country_code") ?? ""}`
    }

    constructor(props: CRegistrationWithEmail.Props, context: {}) {
        super(props, context)
        this.form = new RegistrationForm(
            () => {
                return {
                    firstName: "",
                    lastName: "",
                    phone: "",
                    smsCode: "",
                }
            },
            this.intl,
        )
    }

    @observable
    private saveEmployeeDataErrors: Collections.List<OwnError>

    @observable
    private isLoading: boolean

    @observable
    private nameValue: string = ""

    @observable
    private smsState: string = "hidden"

    @action
    private changeLoadingState(value: boolean) {
        this.isLoading = value
    }

    @action
    private showSms() {
        this.smsState = "shown"
        setTimeout(() => {
            if (this.smsInputNode) {
                this.smsInputNode.focus()
            }
        }, 0)
    }

    @action
    private hideSms() {
        this.smsState = "hidden"
    }

    @action
    private setSaveEmployeeDataErrors(errors?: Collections.List<OwnError>) {
        let errorsList = Collections.List([])
        if (errors) {
            errors.forEach((e) => errorsList.push(e))
        }
        this.saveEmployeeDataErrors = errorsList
    }

    @action
    private submitForm() {
        if (!this.form.isValid || this.isLoading) {
            return
        }
        void this.processForm(this.form.value)
    }

    /**
     * Сохраняет данные в аккаунте
     * @param formValues
     */
    private saveLocalAccountInfo(formValues: RegistrationWithEmailFormValue) {
        return this.apiStore.update(
            {
                contentType: Employee.contentType,
                id: this.currentUser.user.id,
                firstName: formValues.firstName,
                lastName: formValues.lastName,
                contactInfo: Collections.List([
                    {
                        contentType: ContactInfo.contentType,
                        type: ContactInfo.Type.email,
                        value: this.currentUser.email.value,
                        isMain: true
                    },
                    {
                        contentType: ContactInfo.contentType,
                        type: ContactInfo.Type.phone,
                        value: formValues.phone,
                        isMain: true
                    }
                ])
            },
        )
    }
    private async processPhone() {
        if (this.smsState === "hidden") {
            this.form.set("phone", parsePhoneNumberFromString(this.form.value.phone, iso639ToCountyCode(this.intl.locale)).number as string)
            const abResult = await this.apiStore.fetch("/api/v3/account/abCheckPhone/" + this.form.value.phone)
            const abCheck = abResult.value.data
            if (abCheck) {
                this.showSms()
                return false
            }
        } else {
            let result: any = await this.apiStore.fetch("/api/v3/account/checkCode/" +
                this.form.value.phone + "/" +
                this.form.value.smsCode)
            const data = result.value.data
            if (data.status !== "ok") {
                this.form.setErrors(createFetchErrors({message: data.error, type: formValidationErrorType}, "smsCode"))
                this.form.showErrors()
                return false
            }
        }
        return true
    }
    private async processForm(formValues: RegistrationWithEmailFormValue) {
        this.form.removeErrors()
        //очистим ошибки если они есть
        this.setSaveEmployeeDataErrors()
        this.changeLoadingState(true)
        try {
            if (!await this.processPhone()) {
                this.changeLoadingState(false)
                return
            }
            await this.saveLocalAccountInfo(this.form.getValueForSave())
            await this.currentUser.initialize()
            this.apiStore.fetch("/api/v3/account/asyncUpdatePrimaryUserInfo")
            if (this.onboardingPollStore) {
                this.onboardingPollStore.setCanCancelSteps([StepId.ForWhom])
            }
            this.props.changeStepHandler()
            this.sendAnalyticGoal("EmailRegUserInfoFilled")
        } catch (e) {
            this.changeLoadingState(false)
            this.setSaveEmployeeDataErrors(e.response.value.meta.errors)
        }
    }
    private sendAnalyticGoal(goal: string) {
        this.tracker.yaTrack(goal)
        this.tracker.gaTrack(goal)
    }

    @action
    private onNameChange(value: string) {
        this.nameValue = value
        const [firstName, lastName] = value.split(" ")
        this.form.set("lastName", lastName)
        this.form.set("firstName", firstName)
    }

    @action
    private onPhoneChange(value: string) {
        this.form.set("phone", value)
        this.form.set("smsCode", "")
        this.hideSms()
    }

    private renderForm() {
        return <React.Fragment>
            <div className={style("infoContainer")}>
                {this.form.renderValue("firstName", bind => {
                    return <CFieldRow
                        required
                        key="firstName"
                        caption={this.intl.formatMessage(libMessages["firstName"])}
                        errors={bind.errors}
                        bottomMargin
                    >
                        <CInput {...bind} name="firstName" />
                    </CFieldRow>
                })}
                {this.form.renderValue("lastName", bind => {
                    return <CFieldRow
                        required
                        key="lastName"
                        caption={this.intl.formatMessage(libMessages["lastName"])}
                        errors={bind.errors}
                        bottomMargin
                    >
                        <CInput {...bind} name="lastName" />
                    </CFieldRow>
                })}
                <div className={style("phoneRow")}>
                    {this.form.renderValue("phone", bind => {
                        return <CFieldRow
                            required
                            key="phone"
                            caption={this.intl.formatMessage(libMessages["phone"])}
                            errors={bind.errors}
                            bottomMargin
                        >
                            <CInput {...bind} onChange={this.onPhoneChange} name="phone" placeholder={this.phonePlaceholder} />
                        </CFieldRow>
                    })}
                    <div className={style("smsField", this.smsState === "shown" ? "shown" : "")}>
                        {this.form.renderValue("smsCode", bind => {
                            return <CFieldRow
                                required
                                key="smsCode"
                                errors={bind.errors}
                                bottomMargin
                            >
                                <CInput
                                    {...bind} name="smsCode"
                                    autofocus
                                    placeholder={this.intl.formatMessage(onboardingMessages["smsCode"])}
                                    ref={makeRefHandler(this, "smsInputNode")}
                                />
                            </CFieldRow>
                        })}
                    </div>
                </div>
                {this.saveEmployeeDataErrors && <CFieldRow.Errors errors={this.saveEmployeeDataErrors}/>}
            </div>
            <CButton
                name="continue"
                caption={this.intl.formatMessage(onboardingMessages["continue"])}
                type={CButton.Type.SUCCESS}
                onClick={this.submitForm}
                onDisabledClick={this.form.showErrors}
                disabled={!this.form.isValid}
                loading={this.isLoading}
                iconRight={
                    <CIcon
                        type={CIcon.Type.ARROW_FORWARD}
                        size={CIcon.Size.SMALL}
                        color={CIcon.Color.LIGHT}
                        className={style("nextStepRightIcon")}
                    />
                }
            />
        </React.Fragment>
    }

    private renderExtendedForm() {
        return <React.Fragment>
            <div className={style("infoContainer", "wideContainer")}>
                <CFieldRow
                    required
                    key="name"
                    caption={this.intl.formatMessage(libMessages["lastName"]) + " " + this.intl.formatMessage(libMessages["firstName"])}
                    bottomMargin
                >
                    <CInput
                        value={this.nameValue}
                        onChange={this.onNameChange}
                        name="name"
                        placeholder={this.intl.formatMessage(onboardingMessages["namePlaceholder"])}
                    />
                </CFieldRow>
                {this.form.renderValue("phone", bind => {
                    return <CFieldRow
                        required
                        key="phone"
                        caption={this.intl.formatMessage(libMessages["phone"])}
                        errors={bind.errors}
                        bottomMargin
                    >
                        <CInput {...bind} name="phone" placeholder={this.phonePlaceholder} />
                    </CFieldRow>
                })}
                {this.onboardingPollStore.form.renderValue("nameOfYourCompany", bind => {
                    return <CFieldRow
                        required
                        key="nameOfYourCompany"
                        caption={this.intl.formatMessage(onboardingMessages["companyName"])}
                        errors={bind.errors}
                        bottomMargin
                        captionNowrap
                    >
                        <CFieldRow.StretchingElement>
                            <CInput
                                {...bind}
                                name="nameOfYourCompany"
                                placeholder={this.intl.formatMessage(onboardingMessages["myCompanyOOO"])}
                            />
                        </CFieldRow.StretchingElement>
                        <CHint
                            element={
                                <CIcon
                                    size={CIcon.Size.MEDIUM}
                                    type={CIcon.Type.INQUIRY}
                                />
                            }
                            direction="horizontal"
                            horizontalOrientation="left"
                        >
                            {this.intl.formatMessage(onboardingMessages.theNameWillAppearInYourCompanyHint)}
                        </CHint>
                    </CFieldRow>
                })}
                {this.saveEmployeeDataErrors && <CFieldRow.Errors errors={this.saveEmployeeDataErrors}/>}
            </div>
            <CButton
                name="complete"
                caption={this.intl.formatMessage(onboardingMessages["complete"])}
                type={CButton.Type.SUCCESS}
                onClick={this.submitForm}
                onDisabledClick={this.form.showErrors}
                disabled={!this.form.isValid && !this.onboardingPollStore.form.get("nameOfYourCompany")}
                loading={this.isLoading}
                iconRight={
                    <CIcon
                        type={CIcon.Type.ARROW_FORWARD}
                        size={CIcon.Size.SMALL}
                        className={style("nextStepRightIcon")}
                    />
                }
            />
        </React.Fragment>
    }

    public render() {
        if (this.props.askCompanyName) {
            return this.renderExtendedForm()
        }
        return this.renderForm()
    }
}
