import {EditorState} from "draft-js"
import {observable, action} from "mobx"
import {insertBlock, hasPlainBlock} from "src/lib/utils/draft"

export default class EditorStore {

    @observable
    private stateMap = observable.map<string, EditorState>()

    @action
    public update(id: string, editorState: EditorState) {
        this.stateMap.set(id, editorState)
    }

    @action
    public remove(id: string) {
        this.stateMap.delete(id)
    }

    public get(id: string) {
        if (!this.stateMap.has(id)) {
            throw new Error(`editorState with id = ${id} not exists`)
        }
        return this.stateMap.get(id)
    }

    @action
    public insertBlock(id: string, type: string, text: string) {
        if (!this.stateMap.has(id)) {
            throw new Error(`editorState with id = ${id} not exists`)
        }

        const originalEditorState = this.stateMap.get(id)
        let editorState = insertBlock(originalEditorState, type, text)
        const currentSelection = editorState.getSelection()

        if (!hasPlainBlock(editorState.getCurrentContent().getBlockForKey(currentSelection.getEndKey()))) {
            editorState = insertBlock(editorState, "unstyled", "")
            editorState = EditorState.push(originalEditorState, editorState.getCurrentContent(), "insert-fragment")
        }
        this.update(id, editorState)
    }
}
