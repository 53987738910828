/* tslint:disable */
import * as H from "../metadataHelper"
import {Todo} from "./todo"
import {EnumField} from "./enumField"

export interface TodoDeleteRepeatableActionRequest extends H.BaseValue {
    todo?: Todo;
    deleteMode?: TodoDeleteRepeatableActionRequest.DeleteMode;
    contentType: "TodoDeleteRepeatableActionRequest"; // Object type
}

export module TodoDeleteRepeatableActionRequest {
    export const contentType: "TodoDeleteRepeatableActionRequest" = "TodoDeleteRepeatableActionRequest";
    export const endpoint = "/api/v3/todoDeleteRepeatableActionRequest";
    export type DeleteMode = "changeCurrent"|"changeSequent"|"changeTemplate";
    export module DeleteMode {
        export const changeCurrent: DeleteMode = "changeCurrent";
        export const changeSequent: DeleteMode = "changeSequent";
        export const changeTemplate: DeleteMode = "changeTemplate";
    }
    export const newObject: TodoDeleteRepeatableActionRequest = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const todo = {contentType: "RefLinkField" as "RefLinkField", name: "todo", isMultiple: false, isSortable: false, id: "TodoDeleteRepeatableActionRequest.todo", refContentType: H.List(["Todo"])};
        export const deleteMode = {contentType: "EnumField" as "EnumField", name: "deleteMode", isMultiple: false, isSortable: false, id: "TodoDeleteRepeatableActionRequest.deleteMode", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["changeCurrent", "changeSequent", "changeTemplate"])};
    }
}

export function isTodoDeleteRepeatableActionRequest(arg: any): arg is TodoDeleteRepeatableActionRequest {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "TodoDeleteRepeatableActionRequest"
}
