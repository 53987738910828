import * as React from "react"
import {observer} from "mobx-react"
import {autobind} from "core-decorators"
import classNames from "classnames/bind"
import {CHeaderCaption, Component} from "src/lib/components"

export namespace CRatingBlock {
   export interface Props {
       image?: JSX.Element,
       title?: string | JSX.Element,
       explanation?: string | JSX.Element
   }
}

const style = classNames.bind(require("./CRatingBlock.styl"))

@observer
@autobind
export class CRatingBlock extends Component<CRatingBlock.Props, {}> {

    public render() {
        const {image, title, explanation} = this.props

        return <React.Fragment>
            {image}
            {title ? <CHeaderCaption>{title}</CHeaderCaption> : null}
            {explanation ? <div className={style("mutedText")}>{explanation}</div> : null}
        </React.Fragment>
    }
}
