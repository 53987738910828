import * as React from "react"
import {observer} from "mobx-react"
import {computed} from "mobx"
import * as Api from "src/lib/entities/api"
import {Intl} from "src/lib/utils/intl"
import {inject} from "src/lib/utils/inject"
import classNames from "classnames/bind"
import {CEllipsis, Component} from "src/lib/components"
import {getVacationTitle} from "./utils"

const style: any = classNames.bind(require("./CUserVacationCaption.styl"))

interface CUserVacationCaptionProps {
    position?: string | JSX.Element | JSX.Element[]
    vacation?: Api.Vacation
    isFullView?: boolean
    showOnlyVacation?: boolean
    showPeriod?: boolean
}

@observer
export class CUserVacationCaption extends Component<CUserVacationCaptionProps, {}> {

    @inject(Intl)
    private intl: Intl

    @computed
    private get vacationTitle() {
        return getVacationTitle(this.props.vacation, this.intl, !!this.props.showPeriod)
    }

    public render() {
        const {position, vacation, isFullView, showOnlyVacation} = this.props

        if (showOnlyVacation) {
            return this.vacationTitle && isFullView
                ? <div className={style("vacationRow")}>{this.vacationTitle}</div>
                : null
        } else {
            return <div className={style("subheader", {vacation: !!this.vacationTitle && !isFullView})}>
                <CEllipsis>
                    {
                        isFullView || !vacation || !this.vacationTitle
                            ? position
                            : this.vacationTitle
                    }
                </CEllipsis>
            </div>
        }
    }
}
