import * as React from "react"
import classNames from "classnames/bind"
import * as Api from "src/lib/entities/api"
import {computed} from "mobx"
import {AnimatePresence} from "framer-motion"
import {CIcon, CIconType, CLogo, Component, CRoundCrop} from "src/lib/components"
import {Size} from "src/lib/types"
import {observer} from "mobx-react"
import {inject} from "src/lib/utils/inject"
import {Intl} from "src/lib/utils/intl/Intl"
import {getRandomAvatarColor, getRandomAvatarMessageColor, getMessageInitials} from "src/lib/utils/chat"
import {UserOnlineStore} from "src/lib/entities/store/online/UserOnlineStore"
import {isEmployee} from "src/lib/entities/api"
import {COnlineStatus} from "./COnlineStatus"

const style = classNames.bind(require("./CAvatar.styl"))
const messagesLib: any = require("src/lib/messages.yml")
const messagesEmployee: any = require("src/bums/employee/messages.yml")

interface CAvatarProps extends React.Props<CAvatar> {
    entity?: Api.Employee | Api.Group | Api.Contractor | Api.Variable | Api.Payer | string | Api.InvitedEmployee | Api.Topic | Api.Message
    className?: string
    title?: string
    size?: Size
    status?: "positive" | "negative" | "neutral"
    cropped?: boolean
    onClick?: () => void
    showIsOnline?: boolean  // показывать отметку онлайн
    showPin?: boolean
}

export const SizeVariableMap = {
    [Size.SMALL]: 30,
    [Size.MEDIUM]: 36,
    [Size.BIG]: 48,
    [Size.XLARGE]: 72,
}

export const sizeExpr = new RegExp("/{width}x{height}/", "g")

const contentTypeToIconType: {[type: string]: CIconType} = {
    [Api.Employee.contentType]: CIcon.Type.PERSON,
    [Api.ContractorCompany.contentType]: CIcon.Type.DOMAIN_BLACK,
    [Api.ContractorHuman.contentType]: CIcon.Type.PERSON,
    [Api.Variable.contentType]: CIcon.Type.PERSON
}

/**
 * компонент отображения аватара сущности
 */
@observer
export default class CAvatar extends Component<CAvatarProps, {}> {

    @inject(Intl)
    private intl: Intl
    @inject(UserOnlineStore)
    private userOnlineStore: UserOnlineStore

    public static SizeType = Size

    public static defaultProps = {
        size: Size.SMALL,
        cropped: true,
        showIsOnline: true,
    }

    @computed
    private get title() {
        const {entity, title} = this.props

        if (!isEmployee(entity)) {
            return null
        }
        // исключаем системного пользователя
        if ((!entity.canLogin || !entity.isWorking) && entity.firstName !== this.intl.formatMessage(messagesLib["megaplan"])) {
            return !entity.isWorking
                ? this.intl.formatMessage(messagesEmployee["notWorking"])
                : this.intl.formatMessage(messagesEmployee["blocked"])
        }

        return title
    }

    private renderWorkingStatus() {
        const {entity} = this.props

        if (!isEmployee(entity)) {
            return null
        }

        // исключаем системного пользователя
        if ((!entity.canLogin || !entity.isWorking) && entity.firstName !== this.intl.formatMessage(messagesLib["megaplan"])) {
            return <div className={style("workStatus")}>
                <CIcon
                    type={!entity.isWorking ? CIcon.Type.FIRED : CIcon.Type.BLOCK}
                    color={CIcon.Color.RED}
                    className={style("workStatusIcon")}
                />
            </div>
        }

        return null
    }

    private renderStatus() {
        return this.props.status
            ?   <div className={style(this.props.status, "status")}/>
            : null
    }

    private renderPin() {
        if (this.props.showPin) {
            return <div className={style("pinContainer")}>
                <div className={style("pinGradient")}/>
                <CIcon
                    type={CIconType.FLASH}
                    size={CIcon.Size.MEDIUM}
                    color={CIcon.Color.WHITE}
                    className={style("pinIcon")}
                />
            </div>
        }

        return null
    }

    private renderIsOnline() {
        if (this.props.showIsOnline && Api.isEmployee(this.props.entity)) {
            const onlineStatus = this.userOnlineStore.getStatus(this.props.entity)

            return <AnimatePresence exitBeforeEnter initial={false}>
                {onlineStatus && (
                    <div className={style("online")}>
                        <COnlineStatus type={onlineStatus} />
                    </div>
                )}
            </AnimatePresence>
        }
        return null
    }

    private renderEmptyGroup() {
        return <div className={style("emptyAvatar", "empty")}>
            <CIcon
                className={style("icon")}
                type={CIcon.Type.GROUP}
                color={CIcon.Color.LIGHT}
                size={this.props.size === Size.SMALL ? CIcon.Size.SMALL : CIcon.Size.MEDIUM}
            />
        </div>
    }

    private renderEmptyContractor() {
        const type = (this.props.entity as Api.Contractor).contentType

        return <div className={style("emptyAvatar", type)}>
            <CIcon
                className={style("icon")}
                type={contentTypeToIconType[type]}
                color={CIcon.Color.LIGHT}
                size={this.props.size === Size.SMALL ? CIcon.Size.SMALL : CIcon.Size.MEDIUM}
            />
        </div>
    }

    private renderCurrentUser() {
        return <div className={style("emptyAvatar", "empty")}>
            <CIcon
                className={style("icon")}
                type={CIcon.Type.PERSON}
                color={CIcon.Color.LIGHT}
                size={this.props.size === Size.SMALL ? CIcon.Size.SMALL : CIcon.Size.MEDIUM}
            />
        </div>
    }

    private renderEmptyEmployee() {
        const entity = this.props.entity as Api.Employee
        const initials = !!entity.firstName && !!entity.lastName
            ?  entity.lastName.charAt(0) + entity.firstName.charAt(0)
            : <CIcon
                className={style("icon")}
                type={contentTypeToIconType[entity.contentType]}
                color={CIcon.Color.LIGHT}
                size={this.props.size === Size.SMALL ? CIcon.Size.SMALL : CIcon.Size.MEDIUM}
            />

        return <div className={style("emptyAvatar", entity.contentType)} style={{ backgroundColor: getRandomAvatarColor(entity) }}>
            <div className={style("gradientBackground")}>
                {initials}
            </div>
        </div>
    }

    private renderEmptyPayer() {
        return <div className={style("emptyAvatar", "ContractorCompany")}>
            <CIcon
                className={style("icon")}
                type={CIcon.Type.CREDIT_CARD}
                color={CIcon.Color.LIGHT}
                size={this.props.size === Size.SMALL ? CIcon.Size.SMALL : CIcon.Size.MEDIUM}
            />
        </div>
    }

    private renderEmptyTopic() {
        const entity = this.props.entity as Api.Topic
        const initials = entity.name ? entity.name.charAt(0).toUpperCase() : null

        return <div className={style("emptyAvatar")} style={{ backgroundColor: getRandomAvatarColor(entity) }} >
            <div className={style("gradientBackground")}>
                {initials}
            </div>
        </div>
    }

    private renderEmptyMessage() {
        const entity = this.props.entity as Api.Message
        const initials = getMessageInitials(entity)

        return <div className={style("emptyAvatar")} style={{ backgroundColor: getRandomAvatarMessageColor(entity) }} >
            <div className={style("gradientBackground")}>
                {initials}
            </div>
        </div>
    }

    private renderPic() {
        const {
            entity,
            children
            } = this.props

        const actualSize = "/100x100/"

        if (children) {
            return children
        }

        if (Api.isInvitedEmployee(entity)) {
            return <div className={style("emptyAvatar", entity.contentType)}>
                {entity.mail && entity.mail.charAt(0)}
            </div>
        }

        if (Api.isVariable(entity)) {
            return entity.name === "currentUser" ? this.renderCurrentUser() : null
        }

        if (Api.isGroup(entity)) {
            return this.renderEmptyGroup()
        }

        if (Api.isPayer(entity)) {
            return this.renderEmptyPayer()
        }

        if (Api.isMessage(entity)) {
            return this.renderEmptyMessage()
        }

        let srcPic: string = Api.isBaseEntity(entity) ? entity && entity.avatar && entity.avatar.thumbnail : entity

        if (!srcPic) {
            if (Api.isEmployee(entity)) {
                return this.renderEmptyEmployee()
            } else if (Api.isContractor(entity)) {
                return this.renderEmptyContractor()
            } else if (Api.isTopic(entity)) {
                return this.renderEmptyTopic()
            }

            return <div className={style("emptyAvatar", "empty")} >
                <div className={style("logo")}>
                    <CLogo/>
                </div>
            </div>
        }

        return <div className={style("picture")} style={{backgroundImage: `url(${srcPic.replace(sizeExpr, actualSize)})`}} />
    }

    public render() {
        const {
                size,
                className,
                cropped
            } = this.props

        const {title} = this

        const pictureSize = Size[size]

        return <div
                className={style("picture-wrapper", pictureSize.toLowerCase(), className)}
                title={title ? title : ""}
                onClick={this.props.onClick}
            >
            {cropped
                ? <CRoundCrop size={size}>
                    {this.renderPic()}
                </CRoundCrop>
                : this.renderPic()
            }
            {this.renderStatus()}
            {this.renderIsOnline()}
            {this.renderPin()}
            {this.renderWorkingStatus()}
        </div>
    }
}
