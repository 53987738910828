import * as React from "react"
import classNames from "classnames/bind"
import {observer} from "mobx-react"
import {autobind} from "core-decorators"
import * as Api from "src/lib/entities/api"
import {inject} from "src/lib/utils/inject"
import {FormattedCurrency} from "src/lib/utils/intl"
import {CurrencyStore} from "src/bums/common/stores/CurrencyStore"
import {CBadge, CEllipsis, Component} from "src/lib/components"
import {sizeType} from "src/lib/types";

const style: any = classNames.bind(require("./CPrice.styl"))

export namespace CPrice {
   export interface Props {
       value: Api.Money
       baseCurrency?: string
       valueFormatter?: (value: string) => JSX.Element
       single?: boolean
       size?: sizeType
       align?: "left" | "right"
   }
}

@observer
@autobind
export class CPrice extends Component<CPrice.Props, {}> {

    @inject(CurrencyStore)
    private currencyStore: CurrencyStore

    public static defaultProps = {
        align: "left"
    }

    public render() {
        if (!this.currencyStore.isCompleted) {
            return null
        }

        const {value, valueFormatter, single, baseCurrency} = this.props
        const baseCurrencyCode = baseCurrency || this.currencyStore.baseCurrency?.abbreviation

        const currentCurrency = value.currency || baseCurrencyCode
        const originalValue = <FormattedCurrency value={value.value} currency={currentCurrency} valueFormatter={valueFormatter} />

        if (currentCurrency === baseCurrencyCode) {
            return <div className={style("wrapper", this.props.size)}>
                {originalValue}
            </div>
        }

        if (single) {
            return originalValue
        }

        return <CBadge
            title={<CEllipsis>{originalValue}</CEllipsis>}
            subTitle={<FormattedCurrency value={value.valueInMain} currency={baseCurrencyCode} valueFormatter={valueFormatter} />}
            align={this.props.align}
            titleClassName={style("title")}
            className={style("wrapper")}
            size={this.props.size}
            noPadding
        />
    }
}
