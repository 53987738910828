/* tslint:disable */
import * as H from "../metadataHelper"
import {StringField} from "./stringField"

export interface ContractorActivityType extends H.BaseEntity {
    name?: string; // Название
    id?: string; // Идентификатор
    contentType: "ContractorActivityType"; // Object type
}

export module ContractorActivityType {
    export const contentType: "ContractorActivityType" = "ContractorActivityType";
    export const endpoint = "/api/v3/contractorActivityType";

    export const newObject: ContractorActivityType = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "ContractorActivityType.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
    }
}

export function isContractorActivityType(arg: any): arg is ContractorActivityType {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "ContractorActivityType"
}
