import * as React from "react"
import classNames from "classnames/bind"
import {observer} from "mobx-react"
import {observable, action} from "mobx"
import {autobind} from "core-decorators"
import {bindArg, makeRefHandler} from "src/lib/utils/func"
import {CPopover, Component} from "src/lib/components"

const style: any = classNames.bind(require("./style.styl"))

export namespace CMentionsPopover {
   export interface Props {
       className?: string
   }
}

@observer
@autobind
export class CMentionsPopover extends Component<CMentionsPopover.Props, {}> {

    public style: React.CSSProperties = {
        left: void 0,
        top: void 0,
    }

    public elementNode: HTMLDivElement

    @observable
    private popoverOpen = false

    @observable
    private elementStyle: React.CSSProperties = {...this.style}

    public get parentElement() {
        return this.elementNode && this.elementNode.parentElement
    }

    componentWillMount() {
        Object.defineProperties(this.style, {
            left: {
                set: bindArg(this.setPosition, "left")
            },
            top: {
                set: bindArg(this.setPosition, "top")
            },
        })
    }

    @action
    private setPosition(name: "left" | "top", value: string) {
        if (name === "left" || name === "top") {
            this.elementStyle[name] = parseFloat(value || "0")
        }
        if (this.elementStyle.top !== void 0 && this.elementStyle.left !== void 0 && !this.popoverOpen) {
            this.popoverOpen = true
        }
    }

    public render() {
        return <div style={{...this.elementStyle}} className={style("element")} ref={makeRefHandler(this, "elementNode")}>
            {React.Children.count(this.props.children) > 0
                ? <CPopover
                    element={<div className={style("dummyElement")}/>}
                    open={this.popoverOpen}
                    noPadding={true}
                >
                        <div className={style("container")}>
                            {this.props.children}
                        </div>
                </CPopover>
                : null
            }
        </div>

    }
}
