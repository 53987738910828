import * as React from "react"
import classNames from "classnames/bind"
import {autobind} from "core-decorators"
import {observer} from "mobx-react"
import {CButton, CModal, Component, CSmartCard} from "src/lib/components"
import {FormattedMessage} from "src/lib/utils/intl/components"
import {inject} from "src/lib/utils/inject"
import {EditionExceedModalStore} from "./EditionExceedModalStore"
import {ContentPosition, ZLevel} from "src/lib/components/CModal/CModal";
import {CPacketBuyButton} from "src/bums/common/editionExceedModal/CPacketBuyButton";
import * as Api from "src/lib/entities/api";
import {EntityStore} from "src/lib/entities/store/EntityStore";
import CSpinner from "src/lib/components/CSpinner/CSpinner";
import {Intl} from "src/lib/utils/intl";

const style = classNames.bind(require("./theme/CEditionExceedModal.styl"))
const messages: any = require("./messages.yml")

@observer
@autobind
export class CEditionExceedModal extends Component<CLicenseExceedModal.Props, {}> {
    @inject(Api.Store)
    private apiStore: Api.Store

    @inject(EditionExceedModalStore)
    editionExceedModalStore: EditionExceedModalStore

    @inject(Intl)
    private intl: Intl

    private url: string = "https://megaplan.ru/calculation/"

    private openMegaplanEditionPage() {
        window.open(this.url, "_blank")
        this.editionExceedModalStore.close()
    }

    componentWillUnmount() {
        if (this.editionExceedModalStore) {
            this.editionExceedModalStore.destroy()
        }
    }

    private exceedStore(exceedType: string) {
        return new EntityStore<Api.EditionExceed>(this.apiStore, () => {
            return {
                contentType: Api.EditionExceed.contentType,
                id: exceedType
            }
        })
    }

    render() {
        const {isOpen, close, exceedType} = this.editionExceedModalStore
        const translatedExceedType = exceedType ? this.intl.formatMessage(messages[exceedType]) : ""

        return <CModal className={style("editionModal")} open={isOpen} zLevel={ZLevel.LEVEL_2} contentPosition={ContentPosition.TOPCENTER}>
            <CSmartCard className={style("card")}>
                <CSmartCard.Header>
                    {this.intl.formatMessage(messages["limitMessage"], {exceedType: translatedExceedType})}
                </CSmartCard.Header>
                <CSmartCard.Content>
                    {this.renderExceed(exceedType, close)}
                </CSmartCard.Content>
            </CSmartCard>
        </CModal>
    }

    private renderExceed(exceedType: string, close: () => void) {
        let content = null

        if (exceedType) {
            const exceedStore = this.exceedStore(exceedType).get();
            if (exceedStore.state !== "fulfilled") {
                content = <CSpinner/>
            } else {
                const editionExceed = exceedStore.value
                content = <div>
                    {editionExceed.message}
                    <CPacketBuyButton editionExceed={editionExceed}/>
                </div>
            }

            content = <div>
                {content}
                <div className={style("buttons")}>
                    <CButton
                        name="changePlan"
                        caption={<FormattedMessage {...messages["changePlan"]} />}
                        type={CButton.Type.FLAT_SUCCESS}
                        onClick={this.openMegaplanEditionPage}
                    />
                    <CButton
                        name="close"
                        caption={<FormattedMessage {...messages["cancel"]} />}
                        type={CButton.Type.FLAT}
                        onClick={close}
                        className={style("right-button")}
                    />
                </div>
            </div>
        }

        return content
    }
}

namespace CLicenseExceedModal {
    export type Props = {}
}
