import {PlainRoute} from "react-router"

export const discussRoutes: PlainRoute[] = [
    {
        path: "/topics*",
        async getComponent() {
            return (await import("./list/page/CDiscussListPage")).CDiscussListPage
        },
    },
    {
        path: "/discuss(/:groupId)(/:id)",
        async getComponent() {
            return (await import("./list/page/CDiscussListPage")).CDiscussListPage
        },
    },
    {
        path: "/topics/:id/card*",
        async getComponent() {
            return (await import("./list/page/CDiscussListPage")).CDiscussListPage
        },
    },
];
