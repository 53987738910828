import {autobind} from "core-decorators"
import {observable, computed, action, runInAction} from "mobx"
import {UserSettingStore} from "src/bums/common/stores/UserSettingStore"
import {inject} from "src/lib/utils/inject"
import * as Api from "src/lib/entities/api"
import {FeatureStore} from "src/bums/common/stores/FeatureStore";
import {onboardingHintsMap} from "../onboardingHintsMap"
import {Tracker} from "src/bums/common/stores/Tracker";

const shownHintSettingId = "OnBoardingShownHints"
const userSettingBuildVersionName = "createdInBuildVersion"
const TRACKER_CLICK_PREFIX = "show_hint_"

@autobind
export class OnBoardingHintActionStore {

    private shownHintIDsStore: UserSettingStore<string[]>

    private userBuildVersionStore: UserSettingStore<string>

    @observable
    private currentHintID: string | void

    @observable
    public isReady = false

    constructor(
        @inject(Api.Store) private apiStore: Api.Store,
        @inject(FeatureStore) private featureStore: FeatureStore,
        @inject(Tracker) private tracker: Tracker
    ) {
        void this.init()
    }
    private async init() {
        this.shownHintIDsStore = new UserSettingStore<string[]>(
            this.apiStore,
            () => shownHintSettingId,
            () => []
        )

        this.userBuildVersionStore = new UserSettingStore<string>(
            this.apiStore,
            () => userSettingBuildVersionName,
            () => ""
        )

        try {
            await this.userBuildVersionStore.get().promise
            await this.shownHintIDsStore.get().promise
            runInAction(() => this.isReady = true)
        } catch (e) {
            runInAction(() => this.isReady = false)
            console.error(e)
        }
    }

    private get shownHintIDs() {
        const shownHintIDs = this.shownHintIDsStore.get()
        if (shownHintIDs.state === "fulfilled") {
            return shownHintIDs.value
        }
        return []
    }

    private get buildVersion() {
        const buildVersion =  this.userBuildVersionStore.get()
        if (buildVersion.state === "fulfilled") {
            return buildVersion.value
        }
        return null
    }

    @computed
    public get currentHint() {
        return this.currentHintID
    }

    @action
    public triggerAction(actionId: string) {
        if (!this.featureStore.isAvailable("bums.common.onboarding_hint")) {
            return
        }

        if (this.currentHintID === actionId) {
            return
        }

        if (this.isReady && !this.shownHintIDs.includes(actionId)) {

            const createUserBuildVersionNumber = parseFloat(this.buildVersion.split(".")[0].substr(1))

            const isShow = !onboardingHintsMap(this.tracker)[actionId].buildVersion
                || onboardingHintsMap(this.tracker)[actionId].buildVersion <= createUserBuildVersionNumber
                || onboardingHintsMap(this.tracker)[actionId].buildVersion === "oldOnly" && !createUserBuildVersionNumber

            if (isShow) {
                this.currentHintID = actionId
                let shownHintIDs = this.shownHintIDs.slice()
                shownHintIDs.push(actionId)
                this.tracker.trackEvent("click", TRACKER_CLICK_PREFIX + actionId)
                void this.shownHintIDsStore.set(shownHintIDs)
            }
        }
    }

    @action
    public dismissHints() {
        this.currentHintID = void 0
    }
}
