import * as React from "react"
import * as Collections from "src/lib/collections"
import * as Api from "src/lib/entities/api"
import {
    convertToHTML as convertTo,
    convertFromHTML as convertFrom,
    RawEntity,
    RawBlock,
    CreateEntityFn
} from "draft-convert"
import {ContentState} from "draft-js";
import * as Url from "src/lib/utils/url";
import convertHtmlTables from "src/lib/components/CEditor/utils/html-tables-paste-converter";

export function convertToHtml(contentState: ContentState): string {
    return toHtmlConverter(contentState)
}

const toHtmlConverter = convertTo({
    styleToHTML: (style) => {
        switch (style) {
            case "STRIKETHROUGH":
                return <s />
            case "UNDERLINE":
                return <u />
            case "BOLD":
                return <strong />
            case "ITALIC":
                return <i />
            case "CODE":
                return <code />

            default:
                return void 0
        }
    },
    entityToHTML: (entity: RawEntity, originalText: string) => {
        const text = htmlSpecialCharsDecode(originalText)
        if (entity.type === "mention") {
            const mention = entity.data.mention.toJS() as Api.Employee
            return `<megaplan:mention to="${Api.getGID(mention)}">${text}</megaplan:mention>`
        } else if (entity.type === "LINK") {
            const target = Url.hasInternalLink(entity.data.url) ? "_self" : "_blank"
            return <a href={htmlSpecialCharsDecode(entity.data.url)} target={target}>{text}</a>
        }
    },
    blockToHTML: (block: RawBlock) => {
        switch (block.type) {
            case "code-block":
                return <code />
            case "unstyled":
            case "paragraph":
                return block.text === "" ? <br/> : <p/>
            default:
                return void 0
        }
    },
})

export function convertFromHtml(html: string): ContentState {
    return fromHtmlConverter(convertHtmlTables(html))
}

const fromHtmlConverter = convertFrom({
    htmlToEntity: (nodeName: string, node: HTMLElement, createEntity: CreateEntityFn) => {
        if (nodeName === "megaplan:mention") {
            const [contentType, id] = node.getAttribute("to").split("/")
            return createEntity(
                "mention",
                "IMMUTABLE",
                {
                    mention: Collections.Map({contentType, id, name: node.innerText})
                }
            )
        } else if (nodeName === "a") {
            const url = node.getAttribute("href")
            if (!url) {
                return
            }
            return createEntity(
                "LINK",
                "MUTABLE",
                {
                    url: url
                }
            )
        }
    },
    htmlToBlock: (nodeName, node) => {
        switch (nodeName) {
            case "blockquote":
                return {type: "blockquote", data: {}}
            case "pre":
                return {type: "code-block", data: {}}
            default:
                return void 0
        }
    }
})

const htmlSpecialCharsDecodeMap: {[specialChar: string]: string} = {
    "&amp;": "&",
    "&lt;": "<",
    "&gt;": ">",
    "&quot;": '"',
    "&#039;": "'",
    "&#x27;": "'",
}
const htmlSpecialCharsDecodeRegExp = new RegExp(Object.keys(htmlSpecialCharsDecodeMap).join("|"), "g")

export function htmlSpecialCharsDecode(html: string) {
    do {
        html = html.replace(htmlSpecialCharsDecodeRegExp, m => htmlSpecialCharsDecodeMap[m])
    } while (htmlSpecialCharsDecodeRegExp.test(html))    // цикл позволяет распарсить двойные конструкции вида &amp;amp;

    return html
}
