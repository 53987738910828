import {observable, action, computed} from "mobx"
import {autobind} from "core-decorators"
import {EmailTransport} from "src/bums/common/comment/transports/EmailTransport"
import * as Api from "src/lib/entities/api"
import {EmailTemplateForm} from "src/bums/common/emailTemplate/stores/EmailTemplateForm";

export type PossibleActions = "act_edit"

@autobind
export class EmailTemplateStore {

    @observable
    private $currentAppliedTemplate: Api.CommentTemplate | null

    @observable
    public form: EmailTemplateForm | null

    constructor(
        private apiStore: Api.Store,
        private $transportConfigStore: () => EmailTransport | null
    ) {
    }

    @computed
    public get transportConfigStore() {
        return this.$transportConfigStore()
    }

    @action
    public createForm(initialTemplate: Api.CommentTemplate) {
        const newTemplate = {
            ...initialTemplate,
            key: this.key,
            subject: initialTemplate.subject
                || this.transportConfigStore.transportForm?.get("subject")
                || this.transportConfigStore.commentForm.get("subject"),
            content: initialTemplate.content || this.transportConfigStore.commentForm.get("content"),
        } as Api.CommentTemplate

        this.form = new EmailTemplateForm(() => newTemplate)
    }

    @action
    public closeForm() {
        this.form = null
    }

    @computed
    public get currentAppliedTemplate() {
        return this.$currentAppliedTemplate
    }

    public createNewTemplate(commentTemplate: Api.CommentTemplate) {
        return this.apiStore.update(commentTemplate)
    }

    @action
    public applyCommentTemplate(template: Api.CommentTemplate) {
        this.$currentAppliedTemplate = template
        if (!template) {
            return
        }

        this.transportConfigStore.commentForm.set("content", template.content)
        this.transportConfigStore.transportForm?.set("subject", template.subject)
    }

    @action
    public async deleteTemplate(template: Api.CommentTemplate) {
        if (!template || !template.id) {
            return
        }
        await this.apiStore.deleteEntity(template)
    }

    @computed
    public get key() {
        const entity = this.transportConfigStore.contextEntity
        let key = entity.contentType

        if (Api.isDeal(entity)) {
            key = `${Api.Program.contentType}-${entity.program.id}`
        } else if (Api.isTask(entity) || Api.isProject(entity)) {
            key = "Issue"
        }

        return key
    }
}
