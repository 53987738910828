import {Intl, smartDateHr} from "src/lib/utils/intl";
import {DateOnlyToDate} from "src/lib/utils/intl/dateHelper";
import {diff, getToday, isToday, isTomorrow, Units} from "src/lib/utils/date";
import {firstLetterToUpperCase} from "src/lib/utils/text";
import {Vacation} from "src/lib/entities/bums"


const vacationMessages: any = require("../../messages.yml")

type VacationPeriod = "oneDay" | "willBe" | "now"

export function getVacationTitle(vacation: Vacation, intl: Intl, nanocard?: boolean): string {
    if (!vacation || !vacation.firstDay || !vacation.name) {
        return null
    }

    const {
        vacationLength,
        dayToVacation,
        firstDate,
        lastDate
    } = getVacationsParams(vacation, intl)

    if (vacationLength === 0 && dayToVacation < 31) {
        return intl.formatMessage(vacationMessages["vacation"], {
            status: "oneDay",
            endDate: getVacationOneDayFormatter(lastDate, intl),
            type: vacation.type,
        })
    } else if ((dayToVacation > 0 && dayToVacation < 31) || nanocard) {
        return intl.formatMessage(vacationMessages["vacation"], {
            status: "willBe",
            startDate: intl.date.MMdd()(firstDate),
            endDate: intl.date.MMdd()(lastDate),
            type: vacation.type,
        })
    } else if (dayToVacation <= 0) {
        return intl.formatMessage(vacationMessages["vacation"], {
            status: "now",
            endDate: getVacationNowFormatter(lastDate, intl),
            type: vacation.type,
        })
    }
    return null
}

export function getVacationPeriod(
    vacation: Vacation,
    intl: Intl,
    nanocard?: boolean,
    daysToVacation: number = 31
): { status: VacationPeriod, text: string } {
    if (!vacation || !vacation.firstDay || !vacation.name) {
        return null
    }

    const {
        vacationLength,
        dayToVacation,
        firstDate,
        lastDate
    } = getVacationsParams(vacation, intl)

    if (vacationLength === 0 && dayToVacation < daysToVacation) {
        return {
            status: "oneDay",
            text: getVacationOneDayFormatter(lastDate, intl),
        }
    } else if ((dayToVacation > 0 && dayToVacation < daysToVacation) || nanocard) {
        return {
            status: "willBe",
            text: `${intl.date.MMdd()(firstDate)} — ${intl.date.MMdd()(lastDate)}`,
        }
    } else if (dayToVacation <= 0) {
        return {
            status: "now",
            text: getVacationNowFormatter(lastDate, intl),
        }
    }
    return null
}

function getVacationsParams(vacation: Vacation, intl: Intl) {
    const {firstDay, lastDay} = vacation
    const firstDate = DateOnlyToDate(firstDay)
    const lastDate = DateOnlyToDate(lastDay)

    const dayToVacation = diff(firstDate, getToday(), Units.DAYS)
    const vacationLength = diff(firstDate, lastDate, Units.DAYS)

    return {
        vacationLength,
        dayToVacation,
        firstDate,
        lastDate
    }
}

function getVacationOneDayFormatter(date: Date, intl: Intl) {
    const formattedDate = smartDateHr(
        date,
        {
            today: intl.relativeTime.dayLong()(0),
            tomorrow: intl.relativeTime.dayLong()(1),
            other: intl.date.MMMMd(),
        }
    ).replace(".", "")
    return (isToday(date) || isTomorrow(date))
        ? firstLetterToUpperCase(formattedDate)
        : formattedDate
}

function getVacationNowFormatter(date: Date, intl: Intl) {
    return smartDateHr(date, {other: intl.date.MMMd()}).replace(".", "")
}
