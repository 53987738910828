import * as React from "react"
import {observer} from "mobx-react"
import {autobind} from "core-decorators"
import {
    PROGRAM_ID,
    GATHER_THE_TEAM,
    SUBMIT_EVENT,
    SKIP_EVENT,
    COPY_LINK_EVENT, POPUP_TOUR_EVENT_TYPE
} from "src/bums/common/onboarding/tutorial/tracker"
import {OnBoardingComponent} from "src/bums/common/onboarding/tutorial/types"
import {Tracker} from "src/bums/common/stores/Tracker"
import * as Collections from "src/lib/collections"
import * as Api from "src/lib/entities/api"
import {inject} from "src/lib/utils/inject"
import {FormattedMessage, Intl} from "src/lib/utils/intl"
import {
    CButton, CCopyToClipboardButton, CEllipsis,
    CFieldRowContainer,
    CFlex, CLink,
    CModal,
    Component,
    CSmartCard, CSpinner,
    CStyledContent
} from "src/lib/components"
import {InviteForm} from "./form/InviteForm"
import {CInviteForm} from "./CInviteForm"
import {FeatureStore} from "src/bums/common/stores/FeatureStore"
import classNames from "classnames/bind"
import {InviteLinkStore} from "src/bums/common/onboarding/tutorial/CInviteTeam/InviteLinkStore";
import {track} from "src/lib/tracking"

const messages = require("../messages.yml")
const style = classNames.bind(require("../styles/TutorialCommonStyles.styl"))
const inviteLinkStyle = classNames.bind(require("./styles/CInviteLinkModal.styl"))

export namespace CInviteTeam {
   export interface Props extends OnBoardingComponent {
   }
}

@track({eventType: POPUP_TOUR_EVENT_TYPE})
@observer
@autobind
export class CInviteTeam extends Component<CInviteTeam.Props, {}> {

    private form: InviteForm

    @inject(Api.Store)
    private apiStore: Api.Store

    @inject(Intl)
    private intl: Intl

    @inject(Tracker)
    private tracker: Tracker

    @inject(FeatureStore)
    private featureStore: FeatureStore

    @inject(InviteLinkStore)
    private inviteLinkStore: InviteLinkStore

    public componentWillMount() {
        if (this.featureStore.isAvailable("bums.staff.invite_employee_link")) {
            void this.inviteLinkStore.tryGetInviteLink()
        }

        this.form = new InviteForm(
            () => Collections.List({...InviteForm.defaultValue}),
            this.apiStore,
            this.intl
        )
    }

    private submitHandler() {
        void this.form.submit(this.onSubmit)
    }

    @track({event: SUBMIT_EVENT, channels: ["product", "amplitude"]})
    private onSubmit(saved: Collections.List<Api.Employee>) {
        this.tracker.trackEvent(PROGRAM_ID, GATHER_THE_TEAM.step + "::" + GATHER_THE_TEAM.teamSize, String(saved.length))
        this.tracker.trackEvent(PROGRAM_ID, GATHER_THE_TEAM.step, GATHER_THE_TEAM.inviteClick)
        this.tracker.yaTrack("onboardingSendInvitation")
        this.props.store.nextStep()
    }

    @track({event: SKIP_EVENT, channels: ["product", "amplitude"]})
    private onSkip() {
        this.tracker.trackEvent(PROGRAM_ID, GATHER_THE_TEAM.step, GATHER_THE_TEAM.skip)
        this.props.store.nextStep()
    }

    @track({event: COPY_LINK_EVENT, channels: ["product", "amplitude"]})
    private trackEvent() {
        return
    }

    private renderInviteLink() {
        const {isEnabled, inviteLink} = this.inviteLinkStore
        if (inviteLink && !isEnabled) {
            //новая ссылка для приглашения по умолчанию выключена, включим
            void this.inviteLinkStore.switchEnabledLink(true)
        }
        return this.inviteLinkStore.isLoading || !isEnabled
            ? <CSpinner/>
            : <CSmartCard.Content className={style("addBottomMargin")}>
                <CStyledContent className={style("cardDescription")}>
                    <FormattedMessage {...messages["sendInvitationByLink"]} />
                </CStyledContent>
                <CFlex
                    justify="between"
                    className={inviteLinkStyle("linkRow")}
                >
                    <CLink
                        to={isEnabled && `/inviteForm/${inviteLink.uuid}`}
                        className={inviteLinkStyle("inviteLink", {"inactiveLink": !isEnabled})}
                    >
                        <CEllipsis>{`${window.location.host}/inviteForm/${inviteLink.uuid}`}</CEllipsis>
                    </CLink>
                    <CCopyToClipboardButton
                        contentToCopy={isEnabled && `${window.location.host}/inviteForm/${inviteLink.uuid}`}
                        disabled={!isEnabled}
                        onAfterCopy={this.trackEvent}
                    />
                </CFlex>
            </CSmartCard.Content>
    }

    private renderFooter() {
        return <CSmartCard.Footer>
            <CButton
                name="inviteEmployees"
                type={CButton.Type.SUCCESS}
                caption={<FormattedMessage {...messages["invite"]} />}
                onClick={this.submitHandler}
                disabled={!this.form.hasValidInvitation}
                loading={this.form.isPending}
                marginRight={CButton.Margin.DEFAULT}
            />
            <CButton
                name="skip"
                type={CButton.Type.FLAT}
                caption={<FormattedMessage {...messages["skip"]} />}
                onClick={this.onSkip}
                disabled={this.form.isPending}
                marginRight={CButton.Margin.DEFAULT}
            />
        </CSmartCard.Footer>
    }

    public render() {
        return <CModal open maxWidth={400} onClose={this.onSkip}>
            <CSmartCard className={style("card")}>
                <div className={style("inviteCardImage")}/>
                <CSmartCard.Header className={style("cardHeader")}>
                    <FormattedMessage {...messages["gatherTheTeam"]} />
                </CSmartCard.Header>
                <CSmartCard.Content>
                    <CStyledContent className={style("cardDescription")}>
                        <FormattedMessage {...messages["sendInvitationByEmail"]} />
                    </CStyledContent>
                    <CFieldRowContainer marginBottom>
                        {this.form.map((child, index) => <CInviteForm
                            key={index}
                            form={child.form}
                            addEmptyRowHandler={this.form.addEmpty}
                        />)}
                    </CFieldRowContainer>
                </CSmartCard.Content>
                {this.featureStore.isAvailable("bums.staff.invite_employee_link")
                    && this.renderInviteLink()}
                {this.renderFooter()}
            </CSmartCard>
        </CModal>
    }
}
