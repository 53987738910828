import * as React from "react"
import {Intl} from "src/lib/utils/intl"
import {inject} from "src/lib/utils/inject"
import {bindArg} from "src/lib/utils/func"
import {EditorState} from "draft-js"
import classNames from "classnames/bind"
import {CIcon, CIconType, Component} from "src/lib/components"

const style: any = classNames.bind(require("./CEditorToolbar.styl"))
const messages: any = require("./messages.yml")


enum ButtonList {
    H1,
    H2,
    H3,
    H4,
    H5,
    H6,
    OL,
    UL,
    BOLD,
    ITALIC,
    UNDERLINE,
    STRIKETHROUGH,
    BLOCKQUOTE,
    CODE,
    UNSTYLED,
    LINK,
    DELIMITER
}

interface ButtonItem {
    icon?: CIconType
    label: string
    style: string
}

const ButtonMap: {[index: string]: ButtonItem} = {
    [ButtonList.H1]: {label: "H1", style: "header-one"},
    [ButtonList.H2]: {label: "H2", style: "header-two"},
    [ButtonList.H3]: {label: "H3", style: "header-three"},
    [ButtonList.H4]: {label: "H4", style: "header-four"},
    [ButtonList.H5]: {label: "H5", style: "header-five"},
    [ButtonList.H6]: {label: "H6", style: "header-six"},
    [ButtonList.OL]: {label: "OL", icon: CIconType.LIST_NUMBERED, style: "ordered-list-item"},
    [ButtonList.UL]: {label: "UL", icon: CIconType.LIST_BULLETED, style: "unordered-list-item"},
    [ButtonList.BOLD]: {label: "BOLD", icon: CIconType.BOLD, style: "BOLD"},
    [ButtonList.ITALIC]: {label: "ITALIC", icon: CIconType.ITALIC, style: "ITALIC"},
    [ButtonList.UNDERLINE]: {label: "UNDERLINE", icon: CIconType.UNDERLINE, style: "UNDERLINE"},
    [ButtonList.STRIKETHROUGH]: {label: "STRIKETHROUGH", icon: CIconType.STRIKETHROUGH, style: "STRIKETHROUGH"},
    [ButtonList.CODE]: {label: "CODE", icon: CIconType.CODE, style: "code-block"},
    [ButtonList.BLOCKQUOTE]: {label: "BLOCKQUOTE", icon: CIconType.QUOTE, style: "blockquote"},
    [ButtonList.LINK]: {label: "LINK", icon: CIconType.LINK, style: "LINK"},
    [ButtonList.UNSTYLED]: {label: "UNSTYLED", icon: CIconType.CLEAR, style: "unstyled"},
}

const FullToolbarButtons = [
    ButtonList.BOLD,
    ButtonList.ITALIC,
    ButtonList.UNDERLINE,
    ButtonList.STRIKETHROUGH,
    ButtonList.DELIMITER,
    ButtonList.OL,
    ButtonList.UL,
    ButtonList.DELIMITER,
    ButtonList.BLOCKQUOTE,
    ButtonList.CODE,
    ButtonList.LINK,
    ButtonList.DELIMITER,
    ButtonList.UNSTYLED
]

const MiniToolbarButtons = [
    ButtonList.BOLD,
    ButtonList.ITALIC,
    ButtonList.UNDERLINE,
    ButtonList.STRIKETHROUGH,
    ButtonList.DELIMITER,
    ButtonList.OL,
    ButtonList.UL,
    ButtonList.DELIMITER,
    ButtonList.UNSTYLED
]

interface CEditorToolbarProps {
    editorState: EditorState,
    theme: "full" | "mini"
    onClickButton: (style: string) => void
}

export default class CEditorToolbar extends Component<CEditorToolbarProps, {}> {

    @inject(Intl)
    private intl: Intl

    private hasActiveToolbarButton = (type: string) => {
        const {editorState} = this.props
        const blockType = editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType()
        return type === "unstyled" ? false : (blockType === type || editorState.getCurrentInlineStyle().has(type))
    }

    public render() {
        const toolbarButtons = this.props.theme === "full" ? FullToolbarButtons : MiniToolbarButtons
        return <div className={style("toolbar")}>
            {toolbarButtons.map((type, index) => {
                if (ButtonList.DELIMITER === type) {
                    return <span className={style("delimiter", "button")} key={`delimiter-${index}`}/>
                }
                const buttonDefinition = ButtonMap[type]
                const active = this.hasActiveToolbarButton(buttonDefinition.style)

                const buttonProps = {
                    title: this.intl.formatMessage(messages["label"], {type: buttonDefinition.label}),
                    onMouseDown: bindArg(this.props.onClickButton, buttonDefinition.style),
                    className: style("button", {active}),
                    key: `${buttonDefinition.label}-${index}`
                }

                if (buttonDefinition.icon) {
                    return <CIcon type={buttonDefinition.icon} size={CIcon.Size.SMALL} {...buttonProps} />
                } else {
                    return <span {...buttonProps}>{buttonDefinition.label}</span>
                }
            })}
        </div>
    }
}
