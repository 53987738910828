/* tslint:disable */
import * as H from "../metadataHelper"
import {StringField} from "./stringField"

export interface UserColor extends H.BaseValue {
    hexColor?: string; // Значение цвета в 16-ричном формате
    contentType: "UserColor"; // Object type
}

export module UserColor {
    export const contentType: "UserColor" = "UserColor";
    export const endpoint = "/api/v3/userColor";

    export const newObject: UserColor = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const hexColor = {contentType: "StringField" as "StringField", name: "hexColor", isMultiple: false, isSortable: false, id: "UserColor.hexColor", defaultValue: null as StringField["defaultValue"], maxLength: 0};
    }
}

export function isUserColor(arg: any): arg is UserColor {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "UserColor"
}
