import * as React from "react"
import {observer} from "mobx-react"
import {observable, runInAction} from "mobx"
import classNames from "classnames/bind"
import {autobind} from "core-decorators"
import {
    CREATE_TASK_EVENT,
    PROGRAM_ID,
    SKIP_FIRST_TASK_EVENT,
    TASK_CREATE,
    POPUP_TOUR_EVENT_TYPE
} from "src/bums/common/onboarding/tutorial/tracker"
import {OnBoardingComponent} from "src/bums/common/onboarding/tutorial/types"
import {Tracker} from "src/bums/common/stores/Tracker"
import {UserSettingStore} from "src/bums/common/stores/UserSettingStore"
import {TaskForm, TaskFormFactory} from "src/bums/task/form/TaskForm"
import {
    CAutocomplete,
    CButton, CEditor,
    CFieldRow, CFileUploader,
    CInput, CModal,
    Component,
    CSmartCard
} from "src/lib/components"
import * as AutocompleteUtils from "src/lib/components/CAutocomplete/utils"
import {Task} from "src/lib/entities/api"
import * as Api from "src/lib/entities/api"
import {inject} from "src/lib/utils/inject"
import {FormattedMessage, Intl} from "src/lib/utils/intl"
import {Router} from "src/lib/utils/router"
import {AccountInfoStore} from "src/lib/entities/store/AccountInfoStore"
import {BlockedPopupContentStore} from "src/bums/common/stores/BlockedPopupContentStore"
import {track} from "src/lib/tracking"

export namespace CSimpleTaskForm {
    export interface Props extends OnBoardingComponent {
    }
}

const taskMessages = require("src/bums/task/messages.yml")
const messages = require("../messages.yml")
const style = classNames.bind(require("src/bums/task/form/theme/CTaskForm.styl"))
const commonStyles = classNames.bind(require("../styles/TutorialCommonStyles.styl"))
const emptyTask = {
    ...Task.newObject,
    name: "",
    statement: "",
    isUrgent: false,
    isTemplate: false,
}

@track({eventType: POPUP_TOUR_EVENT_TYPE})
@observer
@autobind
export class CSimpleTaskForm extends Component<CSimpleTaskForm.Props, {}> {

    public fileUploader: CFileUploader

    @observable.ref
    private form: TaskForm

    @inject(TaskFormFactory)
    private taskFormFactory: TaskFormFactory

    @inject(Intl)
    private intl: Intl

    @inject(Router)
    private router: Router

    @inject(Api.Store)
    private apiStore: Api.Store

    @inject(Tracker)
    private tracker: Tracker

    @inject(AccountInfoStore)
    private accountInfo: AccountInfoStore

    @inject(BlockedPopupContentStore)
    private blockedPopupContentStore: BlockedPopupContentStore

    private listViewSettingStore: UserSettingStore<string>

    public componentWillMount() {
        void this.initForm()
        this.listViewSettingStore = new UserSettingStore(
            this.apiStore,
            () => "react.task.list.view_mode"
        )
        void this.listViewSettingStore.set("list")
    }

    componentWillUnmount() {
        // Разблокируем появление всплывающих окон
        this.blockedPopupContentStore.unblockContent()
    }

    private async initForm() {
        const from = await this.taskFormFactory.create({...emptyTask})
        runInAction(() => this.form = from)
    }

    private goToNextStep() {
        const redirectUrl = this.accountInfo.systemProductName.toLowerCase().includes("collaboration") ? "task" : "crm"
        this.props.store.nextStep()
        this.router.push(`/${redirectUrl}`)
    }

    @track({event: SKIP_FIRST_TASK_EVENT, channels: ["product", "amplitude"]})
    private closeHandler() {
        this.tracker.trackEvent(PROGRAM_ID, TASK_CREATE.step, TASK_CREATE.skip)
        this.goToNextStep()
    }

    private submitHandler() {
        void this.form.save(this.onSubmit)
    }

    @track({event: CREATE_TASK_EVENT, channels: ["product", "amplitude", "carrotquest"]})
    private onSubmit() {
        this.tracker.trackEvent(PROGRAM_ID, TASK_CREATE.step, TASK_CREATE.created)
        this.tracker.yaTrack("onboardingMakeTask")
        this.goToNextStep()
    }

    private renderTaskFormFields() {
        return <React.Fragment>
            <CFieldRow
                key={Task.fields.name.name}
                required={this.form.isRequired(Task.fields.name.name)}
                caption={<FormattedMessage {...taskMessages["taskName"]} />}
                bottomMargin
                displayType="cardList"
            >
                {this.form.renderValue(Task.fields.name.name, bind =>
                    <CFieldRow errors={bind.errors}>
                        <CFieldRow.StretchingElement>
                            <CInput
                                name="taskName"
                                {...bind}
                                placeholder={this.intl.formatMessage(taskMessages["taskNamePlaceholder"])}
                                status={bind.errors.length ? "error" : "normal"}
                                disabled={this.form.isPending}
                                multiline={true}
                                autofocus
                            />
                        </CFieldRow.StretchingElement>
                    </CFieldRow>
                )}
            </CFieldRow>
            <CFieldRow
                key={Task.fields.statement.name}
                required={this.form.isRequired(Task.fields.name.name)}
                caption={<FormattedMessage {...taskMessages[Task.fields.statement.name]} />}
                bottomMargin
                displayType="cardList"
            >
                {this.form.renderValue(Task.fields.statement.name, statementBind =>
                    <CFieldRow errors={statementBind.errors}>
                        <CFieldRow.StretchingElement>
                            <CEditor
                                {...statementBind}
                                field={Task.fields.statement.id}
                                placeholder={this.intl.formatMessage(taskMessages["statementPlaceholder"])}
                                className={style("editorRoot")}
                                editorClassName={style("editor")}
                                noStyled={true}
                                enableMentions={false}
                                disabled={this.form.isPending}
                                borderBottom
                            />
                        </CFieldRow.StretchingElement>
                    </CFieldRow>
                )}
            </CFieldRow>
            {this.form.renderValue(Task.fields.responsible.name, ({value, onChange, errors}) =>
                <CFieldRow
                    visible={!this.form.get("isGroup")}
                    required={this.form.isRequired(Task.fields.responsible.name)}
                    caption={<FormattedMessage {...taskMessages[Task.fields.responsible.name]}/>}
                    errors={errors}
                    bottomMargin
                    displayType="cardList"
                >
                    {CAutocomplete.single<Api.Employee | Api.Contractor | Api.Group>({
                        value,
                        onChange,
                        fieldName: Task.fields.responsible.name,
                        placeholder: <FormattedMessage {...taskMessages["responsiblePlaceholder"]} />,
                        endpoint: AutocompleteUtils.createEndpointWithOptions(
                            Api.getListEndpoint(Api.Task.contentType, "availableResponsibles"),
                            this.form.get("parent") ? {superTask: this.form.get("parent")} : {}
                        ),
                        removable: false,
                        disabled: this.form.isPending,
                    })}
                </CFieldRow>
            )}
        </React.Fragment>

    }

    private renderFormErrors() {
        return <CSmartCard.Content>
            {this.form && this.form.ownErrors.length > 0 && <CFieldRow errors={this.form.ownErrors}/>}
        </CSmartCard.Content>
    }

    private renderFooter() {
        return <CSmartCard.Footer>
            <CButton
                name="createTask"
                type={CButton.Type.SUCCESS}
                caption={this.intl.formatMessage(taskMessages["createTask"])}
                onClick={this.submitHandler}
                onDisabledClick={this.submitHandler}
                disabled={!this.form.isValid}
                loading={this.form.isPending}
                marginRight={CButton.Margin.DEFAULT}
                className={style("control")}
            />
            <CButton
                name="cancelCreation"
                type={CButton.Type.FLAT}
                caption={this.intl.formatMessage(messages["skip"])}
                onClick={this.closeHandler}
                disabled={this.form.isPending}
                marginRight={CButton.Margin.DEFAULT}
                className={style("control")}
            />
        </CSmartCard.Footer>
    }

    public render() {
        if (!this.form) {
            return null
        }

        return <CModal
            open={!!this.form}
            closeOnEsc
            autoOverflow
            animateClass="animate-fadein"
            contentPosition={CModal.Position.TOPCENTER}
            maxWidth={400}
            onClose={this.closeHandler}
        >
            <CSmartCard className={commonStyles("card")}>
                <div className={commonStyles("taskCardImage")}/>
                <CSmartCard.Header className={commonStyles("cardHeader")}>
                    <FormattedMessage {...messages["createFirstTask"]} />
                </CSmartCard.Header>
                {this.renderTaskFormFields()}
                {this.renderFormErrors()}
                {this.renderFooter()}
            </CSmartCard>
        </CModal>
    }
}
