import {autobind} from "core-decorators"
import {action, computed, observable} from "mobx"
import {createTransformer} from "mobx-utils"
import {Tracker} from "src/bums/common/stores/Tracker"
import * as Api from "src/lib/entities/api"
import {inject} from "src/lib/utils/inject"
import {COMPLETE_ITEM_EVENT, PRODUCT_QUEST_EVENT_TYPE} from "src/bums/common/onboarding/tutorial/tracker";
import {UserSettingStore} from "src/bums/common/stores/UserSettingStore";
import {ModalFormStore} from "src/bums/common/modalForm/ModalFormStore"

const SHOW_PRODUCT_QUEST_COUNTER = "show_product_quest_counter"

const formTypeToIdMap = new Map<string, Api.ProductQuestItem.Id>([
    [Api.ContractorHuman.contentType, Api.ProductQuestItem.Id.create_contractor],
    [Api.ContractorCompany.contentType, Api.ProductQuestItem.Id.create_contractor],
    [Api.Employee.contentType, Api.ProductQuestItem.Id.create_employee],
    [Api.Task.contentType, Api.ProductQuestItem.Id.create_task],
])

@autobind
export class ProductQuestStore {

    private entityListStore: Api.ListStore<Api.ProductQuest>

    @observable
    private $activeProductQuest: Api.ProductQuest

    private counterSettingStore: UserSettingStore<boolean>

    constructor(
        @inject(Api.Store) private $apiStore: Api.Store,
        @inject(Tracker) private tracker: Tracker,
        @inject(ModalFormStore) private modalFormStore: ModalFormStore
    ) {
        this.entityListStore = this.$apiStore.resolveList(() => {
            return {endpoint: Api.ProductQuest.endpoint}
        })

        this.counterSettingStore = new UserSettingStore(
            this.$apiStore,
            () => SHOW_PRODUCT_QUEST_COUNTER,
            () => true
        )

        this.modalFormStore.subscribeToCloseForm(this.completeCreateEntityItem)
    }

    @computed
    public get isLoading() {
        return !this.entityListStore.isCompleted
    }

    public async completeItem(item: Api.ProductQuestItem) {
        const result = await this.$apiStore.update({...Api.getLink(item), completed: true})
        this.entityListStore.resetList()    // для обновления счетчиков

        this.tracker.trackEvent(
            PRODUCT_QUEST_EVENT_TYPE,
            COMPLETE_ITEM_EVENT,
            {
                item: item.id
            },
            ["product", "amplitude", "carrotquest"]
        )

        return result
    }

    @computed
    public get quests() {
        return this.entityListStore.originalItems
    }

    @action
    public setActiveProductQuest(productQuest: Api.ProductQuest | null) {
        this.$activeProductQuest = productQuest
    }

    @computed
    public get activeProductQuest() {
        return this.$activeProductQuest
    }

    @computed
    public get totalUncomplitedItems() {
        return this.entityListStore.originalItems.reduce((count, quest) => count + quest.uncompletedItemsCount, 0)
    }

    @computed
    public get hasCounter() {
        const promise = this.counterSettingStore.get()
        if (promise.state === "fulfilled") {
            return promise.value
        }
        return false
    }

    public async hideCounter() {
        return this.counterSettingStore.set(false)
    }

    public getQuestById = createTransformer((questId: string) => {
        return this.entityListStore.originalItems.find(quest => quest.id === questId)
    })

    public completeCreateEntityItem(formType: string) {
        if (!formTypeToIdMap.has(formType)) {
            return
        }

        const questItemId = formTypeToIdMap.get(formType)
        const currentQuest = this.entityListStore.originalItems.find(quest => quest.items.some(i => i.id === questItemId))
        const item = currentQuest?.items.find(i => i.id === questItemId)

        if (item && !item.completed) {
            void this.completeItem(item)
            if (currentQuest.items.every(i => i.completed)) {
                this.modalFormStore.unsubscribeToCloseForm(this.completeCreateEntityItem)
            }
        }

    }
}
