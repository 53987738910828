import {PromoData} from "src/bums/common/promo/types"
import {CUnsupportedBrowserPopup} from "src/bums/common/promo/CUnsupportedBrowserPopup/CUnsupportedBrowserPopup"

export const createUnsupportedBrowsersPromoSettings = (): PromoData<null> => {
    return {
        id: "bums.common.unsupported_browser_popup",
        feature: "bums.common.unsupported_browser_popup",
        component: CUnsupportedBrowserPopup,
        userCondition: () => {
            return !("noModule" in HTMLScriptElement.prototype)
        },
        store: null
    }
}
