import {pick} from "lodash"
import * as React from "react"
import {autobind} from "core-decorators"
import {action, observable, computed, IComputedValue, untracked} from "mobx"
import * as Collections from "src/lib/collections"
import {FetchErrors, ownErrorProp} from "src/lib/utils/form/types"
import {IFromValidator} from "src/lib/utils/form/validation"
import {FormContract, FormError, BindObject} from "./types"
import {FormValue} from "./FormValue"

type Child<F> = { initialValue: F, form: FormContract<F> }
/**
 * Базовый класс формы-структуры.
 * Предполагает что от неё будут наследоваться.
 */
@autobind
export class Form<T extends {}> implements FormContract<T> {

    /** Кеш метода bind() */
    private $bindObjects: {[K in keyof T]?: BindObject<T[K]>} = {}
    /** Кеш get(prop) компьютедов */
    private $computedHash: {[K in keyof T]?: IComputedValue<T[K]>} = {}
    private $computedErrorsHash: {[K in keyof T]?: IComputedValue<Collections.List<FormError>>} = {}

    @observable.ref
    protected $childForms: Map<keyof T, Child<T[keyof T]>> = new Map()
    private $childFormFactories: {[K in keyof T]?: IComputedValue<Child<T[K]>>} = {}
    /**
     * Данные введенные пользователем
     */
    protected $changedProps = observable.map<keyof T, T[keyof T]>()

    @observable
    protected $showErrors = false
    @observable.ref
    private $externalErrors: FetchErrors

    constructor(protected $initialValueFactory: () => T) {
    }

    @action
    public reset(fields?: (keyof T)[]) {
        if (fields) {
            return fields.forEach(field => {
                this.$changedProps.delete(field)
                this.$childForms.delete(field)
            })
        }
        this.$changedProps.clear()
        this.$childForms = new Map()
        if (this.$externalErrors) {
            this.$externalErrors = null
        }
    }

    @computed
    protected get initialValue() {
        return this.$initialValueFactory()
    }

    @computed
    private get childForms() {
        return Object.keys(this.$childFormFactories)
            .map((prop: keyof T) => this.$childFormFactories[prop].get() && this.$childFormFactories[prop].get().form)
            .filter(f => !!f)
    }

    @computed
    public get isDirty() {
        if (Array.from(this.$changedProps.keys())
            .some((prop: keyof T) => !Object.is(this.initialValue[prop], this.$changedProps.get(prop)))
        ) {
            return true
        }
        if (this.childForms.some(form => form.isDirty)) {
            return true
        }
        return false
    }

    @computed
    public get isValid() {
        if (this.childForms.some(form => !form.isValid)) {
            return false
        }
        if (Object.keys(this.errors || {}).length) {
            return false
        }
        return true
    }

    protected get validator(): IFromValidator<T> | void {
        return null
    }

    @computed
    public get errors() {
        let validationErrors = {} as {[prop: string]: Collections.List<FormError>}
        if (this.validator) {
            validationErrors = this.validator.validate(this.value)
        }
        return validationErrors
    }

    @computed
    public get ownErrors() {
        let ownErrors = Collections.List()
        if (!this.$showErrors) {
            return ownErrors
        }
        if (this.$externalErrors) {
            if (this.$externalErrors.own && this.$externalErrors.own.length) {
                ownErrors = ownErrors.concat(this.$externalErrors.own)
            }
        }
        if (this.errors[ownErrorProp] && this.errors[ownErrorProp].length) {
            ownErrors = ownErrors.concat(this.errors[ownErrorProp])
        }
        return ownErrors
    }

    public getErrors<K extends keyof T>(prop: K): Collections.List<FormError> {
        if (!this.$computedErrorsHash[prop]) {
            this.$computedErrorsHash[prop] = computed(() => {
                let validationErrors = Collections.List<FormError>()
                if (this.validator) {
                    validationErrors = validationErrors
                        .concat(this.validator.getError(prop, this.get(prop), untracked(() => this.value)))
                }
                if (this.$externalErrors && prop in this.$externalErrors.children) {
                    validationErrors = validationErrors.concat(this.$externalErrors.children[prop].own)
                }
                return validationErrors
            }, {name: prop})
        }
        return this.$computedErrorsHash[prop].get()
    }

    @action
    public setErrors(formErrors: FetchErrors) {
        this.$externalErrors = formErrors
        for (let prop in this.$childFormFactories) {
            if (this.$childFormFactories[prop].get().form && prop in formErrors.children) {
                this.$childFormFactories[prop].get().form.setErrors(formErrors.children[prop])
            }
        }
    }

    @action
    public removeErrors() {
        for (let prop in this.$childFormFactories) {
            if (this.$childFormFactories[prop].get().form) {
                this.$childFormFactories[prop].get().form.removeErrors()
            }
        }
        this.$externalErrors = null
    }

    @action
    public showErrors() {
        this.$showErrors = true
        for (let prop in this.$childFormFactories) {
            if (this.$childFormFactories[prop].get().form) {
                this.$childFormFactories[prop].get().form.showErrors()
            }
        }
    }

    @action
    public hideErrors() {
        this.$showErrors = false
        for (let prop in this.$childFormFactories) {
            if (this.$childFormFactories[prop].get().form) {
                this.$childFormFactories[prop].get().form.hideErrors()
            }
        }
    }

    @computed
    public get isErrorsShown() {
        return this.$showErrors
    }

    @computed
    public get value() {
        if (!this.isDirty) {
            return this.initialValue
        }
        return Array.from(this.$changedProps.keys())
            .concat([...Array.from(this.$childForms.keys())])
            .reduce((result, prop: keyof T) => {
                if (
                    this.$childFormFactories[prop] &&
                    this.$childFormFactories[prop].get() &&
                    this.$childFormFactories[prop].get().form &&
                    this.$childFormFactories[prop].get().form.isDirty
                ) {
                    return {...result, [prop]: this.$childForms.get(prop).form.value}
                }
                return {...result, [prop]: this.get(prop)}
            }, {...this.initialValue as {}}) as T
    }

    @computed
    public get changedValues() {
        const changedKeys = Array.from(this.$changedProps.keys())
        this.$childForms.forEach((form, key) => {
            if (form.form && form.form.isDirty) {
                changedKeys.push(key)
            }
        })
        return pick(this.value, changedKeys)
    }

    /**
    * Регистрация вложенных форм.
    */
    @action
    protected form<K extends keyof T, F extends FormContract<T[K]>>(prop: K, formFactory: (v: T[K]) => F): F {
        this.$childFormFactories[prop as keyof T] = computed(() => {
            // Берем текущее велью для фабрики, либо из измененных в форме, либо из начального значения
            const value = this.$changedProps.has(prop) ? this.$changedProps.get(prop) as T[K] : this.initialValue[prop]
            // Если уже есть инстанс, то инвалидируем его
            if (this.$childForms.has(prop)) {
                // создаем новую только если значение отличается от того, с камим мы вызывали фабрику
                if (!this.checkChildFormValueEqual(value, this.$childForms.get(prop).initialValue)) {
                    this.$childForms.set(prop, {initialValue: value, form: formFactory(value)})
                }
            } else {
                // создаем форму только если значение не пустое
                if (value != null) {
                    this.$childForms.set(prop, {initialValue: value, form: formFactory(value)})
                // Если нет значения, то и форма должна быть void
                } else {
                    this.$childForms.set(prop, {initialValue: value, form: void 0})
                }
            }
            return this.$childForms.get(prop)
        })
        Object.defineProperty(this, prop, {
            get: () => this.$childFormFactories[prop].get().form as F,
            set: () => void 0
        })
        return this.$childFormFactories[prop].get().form as F
    }

    protected checkChildFormValueEqual(value: T[keyof T], childValue: T[keyof T]) {
        return value === childValue
    }

    @action
    public set<K extends keyof T>(prop: K, value: T[K]) {
        if (Object.is(this.initialValue[prop], value)) {
            this.$changedProps.delete(prop)
            return
        }
        this.$changedProps.set(prop, value)
    }

    public get<K extends keyof T>(prop: K): T[K] {
        if (this.hasChild(prop)) {
            return (this.$childFormFactories[prop].get().form
                ? this.$childFormFactories[prop].get().form.value
                : this.$childFormFactories[prop].get().initialValue
            ) as T[K]
        }
        if (!this.$computedHash[prop]) {
            this.$computedHash[prop] = computed(() => {
                if (this.$changedProps.has(prop)) {
                    return this.$changedProps.get(prop) as T[K]
                }
                return this.initialValue[prop] as T[K]
            })
        }
        return this.$computedHash[prop].get() as T[K]
    }

    private hasChild<K extends keyof T>(prop: K) {
        return !!this.$childFormFactories[prop]
    }

    /**
     * используйте этот метод для привязки реакт-компонента к форме.
     */
    private bind<K extends keyof T>(prop: K): BindObject<T[K]> {
        if (!this.$bindObjects[prop]) {
            const self = this
            this.$bindObjects[prop] = {
                get value(): T[K] {
                    return self.get(prop)
                },
                onChange(v: T[K]) {
                    self.set(prop, v)
                },
                get errors() {
                    return self.$showErrors ? self.getErrors(prop) : Collections.List()
                },
                get isDirty() {
                    return self.$changedProps.has(prop)
                }
            }
        }
        return this.$bindObjects[prop]
    }

    /**
     * Метод используется для рендера значения свойства формы.
     */
    public renderValue<K extends keyof T>(
        prop: K,
        render: ((bind: BindObject<T[K]>) => JSX.Element | null)
    ): React.ComponentElement<FormValue.Props<T[K]>, FormValue<T[K]>>
    public renderValue<K extends keyof T>(
        prop: K,
        extraOpts: {key?: React.Key, ref?: React.Ref<FormValue<T[K]>>},
        render: ((bind: BindObject<T[K]>) => JSX.Element | null)
    ): React.ComponentElement<FormValue.Props<T[K]>, FormValue<T[K]>>
    public renderValue<K extends keyof T>(
        prop: K,
        extraOptsOrRender: {key?: React.Key, ref?: React.Ref<FormValue<T[K]>>} | ((bind: BindObject<T[K]>) => JSX.Element | null),
        render?: ((bind: BindObject<T[K]>) => JSX.Element | null)
    ): React.ComponentElement<FormValue.Props<T[K]>, FormValue<T[K]>> {
        return React.createElement(FormValue, {
            ...(typeof extraOptsOrRender === "function" ? {} : extraOptsOrRender),
            bind: this.bind(prop),
            render: typeof extraOptsOrRender === "function" ? extraOptsOrRender : render,
        }) as React.ComponentElement<FormValue.Props<T[K]>, FormValue<T[K]>>
    }

}
