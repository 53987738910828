import {AbstractContractorTypesStore} from "./AbstractContractorTypesStore"
import {getListEndpoint} from "src/lib/entities/utils"
import {ContractorCompany} from "src/lib/entities/api"

export class ContractorCompanyTypesStore extends AbstractContractorTypesStore {

    protected get $endpoint() {
        return getListEndpoint(ContractorCompany.contentType, "types")
    }
}
