import {Component} from "src/lib/components"
import * as React from "react"
import {observer} from "mobx-react"
import classNames from "classnames/bind"
import {BaseEventsProps} from "src/lib/types"
import {omit} from "lodash"

const style = classNames.bind(require("./CFieldRowText.styl"))

interface CFieldRowTextProps extends BaseEventsProps {
    className?: string | string[]
    inCursorPointer?: boolean
}

/**
 * Текстовый элемент
 */
@observer
export default class CFieldRowText extends Component<CFieldRowTextProps, {}> {
    public render() {
        return <span
            {...omit(this.props, "inCursorPointer")}
            className={style("text", {"cursor": this.props.inCursorPointer}, this.props.className)}>
            {this.props.children}
        </span>
    }
}
