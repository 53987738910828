import {computed} from "mobx"
import * as Api from "src/lib/entities/api"
import {inject} from "src/lib/utils/inject"


export class EmployeeFilterListStore {

    private $departmentListStore: Api.ListStore<Api.TaskFilter>
    private $positionsListStore: Api.ListStore<Api.Position>
    private $groupListStore: Api.ListStore<Api.Group>

    constructor(
        @inject(Api.Store) private $apiStore: Api.Store
    ) {
        this.$positionsListStore = this.$apiStore.resolveList<Api.Position>(() => ({
            endpoint: Api.Position.endpoint
        }))
        this.$departmentListStore = this.$apiStore.resolveList<Api.TaskFilter>(() => ({
            endpoint: Api.Department.endpoint
        }))
        this.$groupListStore = this.$apiStore.resolveList<Api.Group>(() => ({
            endpoint: Api.Group.endpoint
        }))
    }

    @computed
    public get filters() {
        return this.$positionsListStore.originalItems
            .concat(this.$departmentListStore.originalItems)
            .concat(this.$groupListStore.originalItems)
    }
}
