import * as React from "react"
import classNames from "classnames/bind"
import {observer} from "mobx-react"
import {observable, action, computed, reaction} from "mobx"
import {autobind} from "core-decorators"
import {default as createMentionPlugin, MentionPluginInstance} from "draft-js-mention-plugin"
import {EditorState} from "draft-js"
import * as Api from "src/lib/entities/api"
import {inject} from "src/lib/utils/inject"
import {AutocompleteFilterStore} from "src/lib/components/CAutocomplete/AutocompleteFilterStore"
import {CMentionEntry} from "./CMentionEntry"
import {CMentionsPopover} from "./CMentionsPopover"
import {CMention} from "./CMention"
import {Component} from "src/lib/components"

const style: any = classNames.bind(require("./style.styl"))

const hasMentionStart = /^[@|!]/

interface MentionsProps {
    editorState: EditorState
    onTab: (event: React.KeyboardEvent<HTMLElement>) => void
}

export interface MentionPlugin {
    plugin: MentionPluginInstance,
    component: React.ComponentClass<MentionsProps>
}

export function createPlugin() {

    const plugin = createMentionPlugin({
        entityMutability: "IMMUTABLE",
        mentionComponent: CMention,
        theme: {
            mention: style("mentionName"),
            mentionSuggestionsEntryFocused: style("mentionFocused")
        },
        mentionTrigger: "",
        /* tslint:disable:no-cyrillic-in-string-literals */
        mentionRegExp: "[@|!][A-zА-яёЁ0-9]*"
        /* tslint:enable:no-cyrillic-in-string-literals */
    })

    const {MentionSuggestions} = plugin

    @observer
    @autobind
    class CMentionSuggestions extends Component<MentionsProps, {}> {

        @inject(Api.Store)
        private apiStore: Api.Store

        @observable
        private searchValue = ""

        private filterStore: AutocompleteFilterStore<Api.Employee>

        public mentionsNode: any

        private disposeSearchValue: () => void

        public componentWillMount() {
            this.filterStore = new AutocompleteFilterStore<Api.Employee>(this.apiStore, () => ({
                endpoint: "/api/v3/autocomplete/mentions"
            }))

            this.disposeSearchValue = reaction(
                () => this.searchValue,
                value => {
                    if (!this.searchEnabled) {
                        return
                    }
                    const searchByAlias = hasMentionStart.test(value)
                    const searchValue = searchByAlias ? value.slice(1) : value
                    this.filterStore.searchFromServer(searchValue)
                }
            )
        }

        public componentWillUnmount() {
            if (this.disposeSearchValue) {
                this.disposeSearchValue()
            }
        }

        @computed
        private get items() {
            return this.searchEnabled && !this.filterStore.loading
                ? this.filterStore.items.toArray().map((mention) => {
                    return {
                        contentType: mention.contentType,
                        id: mention.id,
                        name: Api.getValueName(mention)
                    } as Api.Employee
                })
                : []
        }

        @computed
        private get searchEnabled() {
            if (hasMentionStart.test(this.searchValue) || this.searchValue.length > 2) {
                return true
            }

            return false
        }

        @action
        private onSearchChange({value}: {value: string}) {
            this.searchValue = value
        }

        public render() {
            return <MentionSuggestions
                entryComponent={CMentionEntry}
                onSearchChange={this.onSearchChange}
                suggestions={this.items}
                popoverComponent={<CMentionsPopover />}
                initialSelectionIndex={-1}
                customOnTab={this.props.onTab}
            />
        }
    }

    return {
        plugin,
        component: CMentionSuggestions
    }
}
