import {Task} from "src/lib/entities/api"
import {BaseEntity} from "src/lib/entities/types"
import {Intl} from "src/lib/utils/intl"
import {BaseListField, ComponentListField} from "src/bums/common/listPage/types"
import {IssueFormField, TaskFormField} from "src/bums/task/form/types";
import {FeatureStore} from "src/bums/common/stores/FeatureStore";
import * as Api from "src/lib/entities/api";

const messages: any = require("./messages.yml")
const commonMessages: any = require("src/lib/messages.yml")

const TaskFields = Task.fields

export const taskFixedFilterIds = (
    featureStore: FeatureStore,
    filterListStore?: Api.ListStore<Api.TaskFilter>
): {[id: string]: 0 | 1 | 2} => {
    if (featureStore.isAvailable("bums.task.new_task_filters")) {
        return {}
    }

    const ids: {[id: string]: 0 | 1 | 2} = {
        "all": 0,
        "incoming": 1,
        "owner": 1,
        "project": 0,
        "templates": 0,
        "favorites": 0,
        "burning": 0,
    };

    if (filterListStore) {
        const allFilter = filterListStore.originalItems.find(v => v.id === "all")

        if (allFilter && !allFilter.expanded) {
            ids["incoming"] = null
            ids["owner"] = null
        }
    }

    if (featureStore.isAvailable("bums.common.new_system_filters")) {
        ids.overdue = 0
    }

    return ids
};

export function AvailableTaskTableFields(intl: Intl): (BaseListField & ComponentListField<BaseEntity>)[] {
    return [
        {
            name: "name",
            isSortable: TaskFields["name"].isSortable,
            hrName: intl.formatMessage(messages["task"]),
            isDefault: true,
            isNotConfigurable: true,
            width: "20%"
        }, {
            name: "unreadCommentsCount",
            isSortable: TaskFields["unreadCommentsCount"].isSortable,
            hrName: intl.formatMessage(messages["unreadComments"]),
            isDefault: true,
            width: "50px"
        }, {
            name: "actualTodosCount",
            isSortable: TaskFields["actualTodosCount"].isSortable,
            hrName: intl.formatMessage(messages["todosCount"]),
            isDefault: true,
            fields: ["finishedTodosCount", "actualTodosCount"],
            width: "50px"
        }, {
            name: "activity",
            isSortable: TaskFields["activity"].isSortable,
            hrName: intl.formatMessage(messages["activity"]),
            isDefault: true,
            width: "115"
        }, {
            name: "responsible",
            isSortable: TaskFields["responsible"].isSortable,
            hrName: intl.formatMessage(messages["responsible"]),
            isDefault: true,
            width: "12%"
        }, {
            name: "owner",
            isSortable: TaskFields["owner"].isSortable,
            hrName: intl.formatMessage(messages["owner"]),
            isDefault: true,
            width: "12%"
        }, {
            name: "actualStart",
            isSortable: TaskFields["actualStart"].isSortable,
            hrName: intl.formatMessage(messages["actualStart"]),
            isDefault: false,
            width: "115"
        }, {
            name: "statusChangeTime",
            isSortable: TaskFields["statusChangeTime"].isSortable,
            hrName: intl.formatMessage(messages["statusChangeTime"]),
            isDefault: false,
            width: "115"
        }, {
            name: "status",
            isSortable: TaskFields["status"].isSortable,
            hrName: intl.formatMessage(messages["status"]),
            isDefault: false,
            width: "12%"
        }, {
            name: "userCreated",
            isSortable: TaskFields["userCreated"].isSortable,
            hrName: intl.formatMessage(messages["author"]),
            isDefault: false,
            width: "12%",
            fields: ["userCreated", "owner"]
        }, {
            name: "bonuses",
            hrName: intl.formatMessage(messages["bonus"]),
            isDefault: false,
            isSortable: TaskFields["bonuses"].isSortable,
            width: "8%"
        }, {
            name: "fine",
            hrName: intl.formatMessage(messages["fine"]),
            isDefault: false,
            isSortable: TaskFields["bonuses"].isSortable,
            fields: [
                "bonuses",
                "fine"
            ],
            width: "8%"
        }, {
            name: "deadline",
            isSortable: TaskFields["deadline"].isSortable,
            hrName: intl.formatMessage(messages["deadline"]),
            isDefault: true,
            width: "115"
        }, {
            name: "duration",
            isSortable: TaskFields["duration"].isSortable,
            hrName: intl.formatMessage(messages["duration"]),
            isDefault: false,
            width: "160"
        }, {
            name: "contractor",
            isSortable: TaskFields["contractor"].isSortable,
            hrName: intl.formatMessage(messages["contractor"]),
            isDefault: false,
            width: "12%",
            right: Api.Employee.PossibleActions.crm_enter,
        }, {
            name: "plannedWork",
            isSortable: TaskFields["plannedWork"].isSortable,
            hrName: intl.formatMessage(messages["plannedWorkTable"]),
            isDefault: false,
            width: "160"
        }, {
            name: "workedOffTimeTotal",
            isSortable: TaskFields["workedOffTimeTotal"].isSortable,
            hrName: intl.formatMessage(messages["workedOffTimeTotal"]),
            isDefault: false,
            width: "160"
        }, {
            name: "plannedFinish",
            isSortable: TaskFields["plannedFinish"].isSortable,
            hrName: intl.formatMessage(messages["plannedTableFinish"]),
            isDefault: false,
            width: "130"
        }, {
            name: "completed",
            isSortable: TaskFields["completed"].isSortable,
            hrName: intl.formatMessage(messages["completed"]),
            isDefault: false,
            width: "60"
        }, {
            name: "humanNumber",
            isSortable: TaskFields["humanNumber"].isSortable,
            hrName: intl.formatMessage(commonMessages["numberSymbol"]),
            isDefault: false,
            width: "80"
        }, {
            name: "actualSubTasksCount",
            isSortable: TaskFields["actualSubTasksCount"].isSortable,
            hrName: intl.formatMessage(messages["actualSubTasksCount"]),
            isDefault: false,
            fields: ["actualSubTasksCount", "actualIssuesCount"],
            width: "8%"
        }, {
            name: "isFavorite",
            isSortable: TaskFields["isFavorite"].isSortable,
            hrName: intl.formatMessage(messages["isFavorite"]),
            isDefault: false,
            width: "50px"
        }, {
            name: "auditors",
            isSortable: TaskFields["auditors"].isSortable,
            hrName: intl.formatMessage(messages["auditors"]),
            isDefault: true,
            width: "12%"
        }, {
            name: "executors",
            isSortable: TaskFields["executors"].isSortable,
            hrName: intl.formatMessage(messages["executors"]),
            isDefault: false,
            width: "12%"
        }, {
            name: "actualFinish",
            isSortable: TaskFields["actualFinish"].isSortable,
            hrName: intl.formatMessage(messages["actualTableFinish"]),
            isDefault: false,
            width: "115"
        }, {
            name: "textStatement",
            isSortable: TaskFields["actualFinish"].isSortable,
            hrName: intl.formatMessage(messages["statement"]),
            isDefault: false,
            width: "20%"
        }, {
            name: "finalRating",
            isSortable: TaskFields["finalRating"].isSortable,
            hrName: intl.formatMessage(messages["finalRating"]),
            isDefault: false,
            width: "60"
        }, {
            name: "tags",
            isSortable: TaskFields["tags"].isSortable,
            hrName: intl.formatMessage(commonMessages["tags"]),
            isDefault: false,
            width: "170"
        }, {
            name: "allFiles",
            isSortable: TaskFields["allFiles"].isSortable,
            hrName: intl.formatMessage(messages["files"]),
            isDefault: false,
            width: "20%"
        }, {
            name: "lastComment",
            isSortable: TaskFields["lastComment"].isSortable,
            hrName: intl.formatMessage(messages["lastComment"]),
            isDefault: false,
            width: "20%"
        }, {
            name: "lastCommentTimeCreated",
            isSortable: TaskFields["lastCommentTimeCreated"].isSortable,
            hrName: intl.formatMessage(messages["lastCommentTimeCreated"]),
            isDefault: false,
            width: "20%"
        }, {
            name: "timeCreated",
            isSortable: TaskFields["timeCreated"].isSortable,
            hrName: intl.formatMessage(messages["timeCreated"]),
            isDefault: false,
            width: "20%"
        }
    ]
}

export function StaticTaskCardFields(intl: Intl, forNegotiationTask = false): TaskFormField[] {
    const fields = [
        {
            name: "name",
            hrName: intl.formatMessage(messages["name"]),
            isNotConfigurable: true,
        },
        {
            name: "statement",
            hrName: intl.formatMessage(messages["statement"]),
        },
        {
            name: "responsible",
            hrName: intl.formatMessage(messages["responsible"]),
            isNotConfigurable: true,
        },
        {
            name: "owner",
            hrName: intl.formatMessage(messages["owner"]),
            selectedForTemplateOnly: true,
        },
        {
            name: "executors",
            hrName: forNegotiationTask
                ? intl.formatMessage(messages["negotiationExecutors"])
                : intl.formatMessage(messages["executors"])
            ,
            selectedForNegotiation: true,
            isNotConfigurable: forNegotiationTask,
        },
        {
            name: "auditors",
            hrName: intl.formatMessage(messages["auditors"])
        },
        {
            name: "contractor",     // заказчик
            hrName: intl.formatMessage(messages["contractor"]),
            right: Api.Employee.PossibleActions.crm_enter,
        },
        {
            name: "deadline",
            hrName: intl.formatMessage(messages["deadline"]),
        },
        {
            name: "parent",
            hrName: intl.formatMessage(messages["parent"])
        },
        {
            name: "previousTask",
            hrName: intl.formatMessage(messages["previousTask"]),
            selectedForTemplateOnly: true,
        },
        {
            name: "baseOn",
            hrName: intl.formatMessage(messages["baseOn"]),
            disableForTemplate: true,
        }
    ] as TaskFormField[]

    if (forNegotiationTask) {
        fields.splice(2, 0, {
            name: "negotiationItems",
            hrName: intl.formatMessage(messages["negotiationItems"]),
            isNotConfigurable: true,
            enabledForNegotiationOnly: true,
            selectedForNegotiation: true,
        })
    }

    return fields
}

export function StaticProjectCardFields(intl: Intl): IssueFormField[] {
    return [
        {
            name: "name",
            hrName: intl.formatMessage(messages["name"]),
            isNotConfigurable: true,
            isDefault: true
        }, {
            name: "statement",
            hrName: intl.formatMessage(messages["projectStatement"])
        }, {
            name: "responsible",
            hrName: intl.formatMessage(messages["projectManager"]),
            isNotConfigurable: true
        }, {
            name: "owner",
            hrName: intl.formatMessage(messages["owner"]),
            selectedForTemplateOnly: true,
        }, {
            name: "executors",
            hrName: intl.formatMessage(messages["projectExecutors"]),
            selectedForOldStuffOnly: true,
        }, {
            name: "auditors",
            hrName: intl.formatMessage(messages["auditors"])
        }, {
            name: "contractor",
            hrName: intl.formatMessage(messages["contractor"]),
            selectedForOldStuffOnly: true,
            right: Api.Employee.PossibleActions.crm_enter,
        }, {
            name: "deadline",
            hrName: intl.formatMessage(messages["deadline"])
        }, {
            name: "parent",
            hrName: intl.formatMessage(messages["parentProject"])
        }, {
            name: "baseOn",
            hrName: intl.formatMessage(messages["baseOn"]),
            selectedForOldStuffOnly: true,
            disableForTemplate: true,
        }
    ]
}
