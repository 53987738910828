import {observable, computed, action} from "mobx"
import {autobind} from "core-decorators"

@autobind
export abstract class AbstractDragNDropStore {

    public static dragleave = "dragleave" as any
    public static dragenter = "dragenter" as any
    public static drop = "drop" as any

    @observable
    private dragCount = 0

    // to prevent unnecessary re-render
    private cachedIsDraggedValue: boolean

    protected abstract isEventSupported(event: DragEvent): boolean

    @action
    protected enterLeaveHandler(event: DragEvent) {
        if (!this.isEventSupported(event)) {
            return false
        }
        event.preventDefault()
        if (event.type === "dragleave") {
            this.dragCount--
        } else if (event.type === "dragenter") {
            this.dragCount++
        }
        return false
    }

    @action
    public reset(e: any) {
        e.preventDefault()
        this.dragCount = 0
        return false
    }

    @computed
    public get isDraggedOver() {
        if (this.dragCount === 0 && this.cachedIsDraggedValue) {
            this.cachedIsDraggedValue = false
        } else if (this.dragCount >= 1 && !this.cachedIsDraggedValue) {
            this.cachedIsDraggedValue = true
        }
        return this.cachedIsDraggedValue
    }

}
