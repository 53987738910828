import classNames from "classnames/bind"
import * as React from "react"
import {observer} from "mobx-react"
import {Component} from "src/lib/components"

//local imports
import {StickerName, StickerSize} from "./utils"

const style = classNames.bind(require("./CSticker.styl"))

export namespace CSticker {
   export interface Props {
       stickerName: StickerName
       size?: StickerSize
       className?: string
   }
}

@observer
export class CSticker extends Component<CSticker.Props, {}> {

    public static defaultProps = {
        size: StickerSize.Medium
    }

    public render() {
        const {size, stickerName, className} = this.props

        return <div className={style("sticker", stickerName, size, className)} />
    }
}

export namespace CSticker {
    export const Name = StickerName
    export const Size = StickerSize
}
