
import {
    EditorState,
    Modifier,
    SelectionState,
    ContentState,
    ContentBlock,
    CharacterMetadata,
    RichUtils,
} from "draft-js"
import {bindArg} from "./func"

const lastLineBreakExp = /\n$/g

/**
 *  Удаления концевого переноса строки
 */
export function trimLineBreakInBlock(editorState: EditorState, blockKey: string): EditorState {
    const block = editorState.getCurrentContent().getBlockForKey(blockKey)
    if (!block) {
        return editorState
    }
    return EditorState.push(
        editorState,
        Modifier.replaceText(
            editorState.getCurrentContent(),
            createSelection([block.getKey(), 0], [block.getKey(), block.getText().length]),
            block.getText().replace(lastLineBreakExp, "")
        ),
        "insert-characters"
    )
}

/**
 *  Упрощенное создание выделения блоков
 */
export function createSelection(anchorPair: [string, number], focusPair?: [string, number]) {
    if (!focusPair) {
        focusPair = anchorPair
    }
    return new SelectionState({
        anchorKey: anchorPair[0],
        anchorOffset: anchorPair[1],
        focusKey: focusPair[0],
        focusOffset: focusPair[1]
    })
}

/**
 *  Переносит конечную границу выделения в конец первого блока
 */
export function moveSelectionToEndFirstBlock(editorState: EditorState): EditorState {
    const currentContent = editorState.getCurrentContent()
    const currentSelection = editorState.getSelection()
    if (currentSelection.isCollapsed()) {
        return editorState
    }
    const currentBlock = currentContent.getBlockForKey(currentSelection.getStartKey())
    return EditorState.forceSelection(editorState, createSelection(
        [currentSelection.getStartKey(), currentSelection.getStartOffset()],
        [currentSelection.getStartKey(), currentBlock.getText().length]
    ))
}

/**
 *  Перебор всех выделенных блоков
 */
export function forEachSelectionBlocks(
    contentState: ContentState,
    selectionState: SelectionState,
    callback: (contentBlock: ContentBlock) => void
) {
    const startBlockKey = selectionState.getStartKey()
    const endBlockKey = selectionState.getEndKey()
    const blockOutKey = contentState.getKeyAfter(endBlockKey)
    let iterationBlock = contentState.getBlockForKey(startBlockKey)

    do {
        const currentBlockKey = iterationBlock.getKey()
        callback(iterationBlock)
        iterationBlock = contentState.getBlockAfter(currentBlockKey)
    } while (iterationBlock && iterationBlock.getKey() !== blockOutKey)
}

/**
 *  Получение текста из выделенной области
 */
export function getSelectedPlainText(editorState: EditorState): string {
    const currentContent = editorState.getCurrentContent()
    const currentSelection = editorState.getSelection()
    const contentChunks: string[] = []
    const startKey = currentSelection.getStartKey()
    const endKey = currentSelection.getEndKey()

    forEachSelectionBlocks(currentContent, currentSelection, (contentBlock: ContentBlock) => {
        const sliceArgs: number[] = []
        if (contentBlock.getKey() === startKey) {
            sliceArgs.push(currentSelection.getStartOffset())
        }
        if (contentBlock.getKey() === endKey) {
            if (sliceArgs.length === 0) {
                sliceArgs.push(0)
            }
            sliceArgs.push(currentSelection.getEndOffset())
        }
        contentChunks.push(String.prototype.slice.apply(contentBlock.getText(), sliceArgs))
    })

    return contentChunks.join("\n")
}

/**
 *  Разбивка блока на два по выбранной границе выделения
 */
export function splitBlockBySelectionBorder(editorState: EditorState, border: "start" | "end"): EditorState {
    let nextState = editorState
    let contentState = nextState.getCurrentContent()
    let currentSelection = nextState.getSelection()
    const [key, offset] = (border === "start" ?
        [currentSelection.getStartKey(), currentSelection.getStartOffset()] :
        [currentSelection.getEndKey(), currentSelection.getEndOffset()]
    ) as [string, number]

    nextState = EditorState.push(
        nextState,
        Modifier.splitBlock(contentState, createSelection([key, offset], [key, offset])),
        "split-block"
    )
    contentState = nextState.getCurrentContent()

    let anchorPair: [string, number] = [currentSelection.getStartKey(), currentSelection.getStartOffset()]
    let focusPair: [string, number] = [currentSelection.getEndKey(), currentSelection.getEndOffset()]

    if (border === "start") {
        const beforeBlock = nextState.getCurrentContent().getBlockBefore(nextState.getSelection().getStartKey())
        if (beforeBlock) {
            nextState = trimLineBreakInBlock(nextState, beforeBlock.getKey())
        }
        const nextBlockKey = contentState.getBlockAfter(currentSelection.getStartKey()).getKey()
        const hasOneBlockSelection = currentSelection.getStartKey() === currentSelection.getEndKey()
        anchorPair = [nextBlockKey, 0]
        if (hasOneBlockSelection) {
            focusPair = [nextBlockKey, currentSelection.getEndOffset() - currentSelection.getStartOffset()]
        }
    } else {
        nextState = trimLineBreakInBlock(nextState, nextState.getSelection().getEndKey())
        focusPair[1] = contentState.getBlockForKey(currentSelection.getEndKey()).getText().length
    }
    return EditorState.forceSelection(nextState, createSelection(anchorPair, focusPair))
}

/**
 *  Преобразует выделенную область в множественные блоки
 */
export function convertSelectionInBlocks(editorState: EditorState, blockType?: string): EditorState {
    let nextState = editorState
    if (blockType && blockType !== RichUtils.getCurrentBlockType(nextState)) {
        nextState = EditorState.push(
            nextState,
            Modifier.setBlockType(nextState.getCurrentContent(), nextState.getSelection(), blockType),
            "change-block-type"
        )
    }
    const currentSelection = nextState.getSelection()
    const plainText = getSelectedPlainText(nextState)
    const textContentState = ContentState.createFromText(plainText)

    nextState = EditorState.push(
        nextState,
        Modifier.replaceWithFragment(nextState.getCurrentContent(), currentSelection, textContentState.getBlockMap()),
        "insert-fragment"
    )

    nextState = EditorState.forceSelection(nextState, createSelection(
        [currentSelection.getStartKey(), currentSelection.getStartOffset()],
        [nextState.getSelection().getEndKey(), nextState.getSelection().getEndOffset()]
    ))
    return nextState
}

/**
 *  Преобразует выделенную область в один блок
 */
export function convertSelectionInBlock(editorState: EditorState, blockType?: string): EditorState {
    let nextState = editorState
    const currentSelection = nextState.getSelection()
    const plainText = getSelectedPlainText(editorState)

    nextState = EditorState.push(
        nextState,
        Modifier.replaceText(nextState.getCurrentContent(), currentSelection, plainText),
        "insert-characters"
    )

    nextState = EditorState.forceSelection(nextState, createSelection(
        [currentSelection.getStartKey(), 0],
        [currentSelection.getStartKey(), plainText.length]
    ))

    if (blockType && blockType !== RichUtils.getCurrentBlockType(nextState)) {
        nextState = EditorState.push(
            nextState,
            Modifier.setBlockType(nextState.getCurrentContent(), nextState.getSelection(), blockType),
            "change-block-type"
        )
    }
    return nextState
}

/**
 *  Получить область выделения для сущности
 */
export function getSelectionByEntity(editorState: EditorState, entityKey: string): SelectionState {
    const currentContent = editorState.getCurrentContent()
    let selectionState: SelectionState

    const createSelectionCallback = (blockKey: string, start: number, end: number) => {
        selectionState = createSelection([blockKey, start], [blockKey, end])
    }

    currentContent.getBlockMap().findEntry((contentBlock: ContentBlock) => {
        contentBlock.findEntityRanges(
            (character: CharacterMetadata) => entityKey === character.getEntity(),
            bindArg(createSelectionCallback, contentBlock.getKey())
        )
        return !!selectionState
    })

    return selectionState
}

/**
 *  Заменяет одну сущность на другую. Если fromKey === null, сущность удаляется
 */
export function replaceEntity(editorState: EditorState, toKey: string, fromKey: string) {
    const selection = getSelectionByEntity(editorState, toKey)
    return EditorState.forceSelection(
        RichUtils.toggleLink(editorState, selection, fromKey),
        createSelection([selection.getStartKey(), selection.getStartOffset()])
    )
}

/**
 *  Ищет в блоке соответсвия регулярному выражению и применяет к ним callback функцию
 */
export function findWithRegex(regex: RegExp, contentBlock: ContentBlock, callback: (start: number, end: number) => void) {
    const text = contentBlock.getText()
    let matchArr: RegExpExecArray
    let start: number
    while ((matchArr = regex.exec(text)) !== null) {
        start = matchArr.index
        callback(start, start + matchArr[0].length)
    }
}

/**
 *  Создает безопасный DOM
 */
export function getSafeBodyWithHTML(html: string) {
  let root: HTMLElement = null

  if (document.implementation && document.implementation.createHTMLDocument) {
    const doc = document.implementation.createHTMLDocument("")
    const base = doc.createElement("base")
    base.setAttribute("href", window.location.origin)
    doc.head.appendChild(base)
    doc.body.innerHTML = html
    root = doc.body
  }

  return root
}

/**
 * Вставка нового блока с контентом
 */
export function insertBlock(editorState: EditorState, type: string, text: string) {
    let currentContent = editorState.getCurrentContent()

    if (!currentContent.hasText()) {
        currentContent = Modifier.setBlockType(currentContent, currentContent.getSelectionAfter(), type)
        currentContent = Modifier.insertText(currentContent, currentContent.getSelectionAfter(), text)
        return EditorState.push(editorState, currentContent, "insert-fragment")
    }

    const currentKey = editorState.getSelection().getStartKey()
    const currentBlock = currentContent.getBlockForKey(currentKey)

    if (hasPlainBlock(currentBlock) && currentBlock.getText() === "") {
        // при вставке в пустой блок в середине текста
        currentContent = Modifier.setBlockType(currentContent, createSelection([currentKey, 0]), type)
        currentContent = Modifier.insertText(currentContent, currentContent.getSelectionAfter(), text)
        const state = EditorState.push(editorState, currentContent, "insert-fragment")
        return EditorState.forceSelection(state, createSelection(
            [currentContent.getKeyAfter(currentKey) || currentKey, 0]))
    }

    currentContent = Modifier.splitBlock(
        currentContent,
        createSelection([currentBlock.getKey(), currentBlock.getText().length])
    )
    currentContent = Modifier.setBlockType(currentContent, currentContent.getSelectionAfter(), type)
    currentContent = Modifier.insertText(currentContent, currentContent.getSelectionAfter(), text)

    return EditorState.push(editorState, currentContent, "insert-fragment")
}

/**
 * Вставка нового блока с контентом в конец
 */
export function insertBlockToTheEnd(editorState: EditorState, type: string, text: string) {
    let nextContent = editorState.getCurrentContent()
    const lastBlock = nextContent.getLastBlock()
    if (nextContent.hasText() && !(hasPlainBlock(lastBlock) && lastBlock.getText() === "")) {
        nextContent = Modifier.splitBlock(nextContent, createSelection([lastBlock.getKey(), lastBlock.getText().length]))
    }
    nextContent = Modifier.setBlockType(nextContent, nextContent.getSelectionAfter(), type)
    nextContent = Modifier.insertText(nextContent, nextContent.getSelectionAfter(), text)
    return EditorState.push(editorState, nextContent, "insert-fragment")
}

/**
 * Определение простого блока
 */
export function hasPlainBlock(block: ContentBlock) {
    return ["paragraph" , "unstyled"].includes(block.getType())
}

/**
 * Удаление всех inline стилей
 */
export function removeAllInlineStyle(editorState: EditorState) {
    const selection = editorState.getSelection()
    const nextContent = editorState.getCurrentInlineStyle().toArray().reduce((contentState: ContentState, style: string) => {
        return Modifier.removeInlineStyle(contentState, selection, style)
    }, editorState.getCurrentContent())
    return EditorState.push(editorState, nextContent, "change-inline-style")
}

/**
 * Убирает лишний пустой блок перед блоками списка (необходимо из-за issue в convertFromHTML)
 */
export function removeEmptyBlockBeforeList(blocks: Array<ContentBlock>) {
    return blocks.filter((elem, index, array) =>
        !(elem.getText().trim() === "" && ((index + 1) < array.length)
        && (array[index + 1].getType() === "unordered-list-item" || array[index + 1].getType() === "ordered-list-item"))
    )
}
