export const trackClickAtHref = (href: string, trackEventName: string) => (props: {}, args: [Event]) => {
    const [event] = args
    if (event) {
        if (
            (event.currentTarget && (event.currentTarget as HTMLAnchorElement).href === href) ||
            (event.target && (event.target as HTMLAnchorElement).href === href)
        ) {
            return {event: trackEventName}
        }
    }
}

export const SHARE_CLICK = "Share click"
