import {autobind} from "core-decorators";
import {FormCollection} from "src/lib/utils/form/formCollection";
import {NegotiationForm} from "src/bums/task/form/NegotiationForm";
import * as Api from "src/lib/entities/api";
import * as Collections from "src/lib/collections";
import {action, computed} from "mobx";
import {Intl} from "src/lib/utils/intl/Intl";
import {FormError} from "src/lib/utils/form/types";

const libMessages: any = require("src/lib/messages.yml")

@autobind
export class NegotiationFormCollection extends FormCollection<Api.NegotiationItem, NegotiationForm> {

    constructor(
        valueFactory: () => Collections.List<Api.NegotiationItem>,
        private intl: Intl,
        private isNeedValidationFactory: () => boolean
    ) {
        super(valueFactory, negotiationItem => new NegotiationForm(
            () => negotiationItem
        ))
    }

    @action
    public addNegotiationItem() {
        this.push({
            contentType: Api.NegotiationItem.contentType,
            versions: Collections.List<Api.NegotiationItemVersion>({
                contentType: Api.NegotiationItemVersion.contentType,

            })
        })
    }

    @computed
    private get isNeedValidation() {
        return this.isNeedValidationFactory()
    }

    protected validate(formValue: Collections.List<Api.NegotiationItem>) {
        return this.isNeedValidation && !formValue.some(this.negotiationHasAttachOrText)
            ? Collections.List<FormError>({
                message: this.intl.formatMessage(libMessages["emptyFieldError"]),
                type: "formValidationErrorType"
            })
            : Collections.List<FormError>()
    }

    private negotiationHasAttachOrText(negotiationItem: Api.NegotiationItem) {
        return negotiationItem.versions.some(itemVersion => !!(itemVersion.text || itemVersion.attache))
    }

    public getValueForSave(value: Collections.List<Api.NegotiationItem>) {
        if (!this.isNeedValidation) {
            return Collections.List()
        }

        return value.filter(item => !!(item.versions.first().text || item.versions.first().attache))
    }
}
