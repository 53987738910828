import {observable, computed, action, IComputedValue} from "mobx"
import {autobind} from "core-decorators"
import * as Collections from "src/lib/collections"
import {KeyedValue, isKeyedValue} from "src/lib/types"
import * as Api from "src/lib/entities/api"
import {searchInDataSet, sortByWeight} from "src/lib/utils/search"
import {Intl} from "src/lib/utils/intl"
import {bindArg} from "src/lib/utils/func"
import {filterFirstEmpty} from "src/lib/components/CAutocomplete/filters"

type Items<V, V2> = Collections.List<V | KeyedValue<V, V2>>
type Values<V, V2> = V | KeyedValue<V, V2> | Items<V, V2> | void

interface SelctionOptions<V, V2> {
    value: Values<V, V2>
    items: Items<V, V2>
    searchText: string
    lifeFiltration?: boolean
}

@autobind
export class SelectionStore<V, V2> {

    private $computedFactory: IComputedValue<SelctionOptions<V, V2>>

    @observable
    private $userChanges = false

    constructor (
        private $userStore: Api.UserStore,
        private $intl: Intl,
        valuesFactory: () => SelctionOptions<V, V2>
    ) {
        this.$computedFactory = computed(valuesFactory)
    }

    @computed
    public get searchText() {
        const searchText = this.$computedFactory.get().searchText
        return searchText === void 0 ? "" : searchText
    }

    @computed
    public get values() {
        let values = this.$computedFactory.get().value
        const emptyItem = this.normalizedItems.find(item => item.key === null)

        if (emptyItem && values === null) {
            values = Collections.List(emptyItem)
        }

        return Collections.List(values)
            .map<KeyedValue<V, V2>>(value => {
                if (isKeyedValue(value)) {
                    return value
                }

                const findItem = this.normalizedItems.find(item => item.key === value)

                if (findItem) {
                    return {key: value as any as V, value: findItem.value}
                }

                return {key: value as any as V, value: value as any as V2}
            })
    }

    @computed
    public get normalizedItems() {
        return this.$computedFactory.get().items.map<KeyedValue<V, V2>>(item => {
            return isKeyedValue(item) ? item : {key: item as V, value: item as any as V2}
        })
    }

    @computed
    public get items() {
        let result = this.normalizedItems

        if (this.$computedFactory.get().lifeFiltration) {
            let items = searchInDataSet(this.$userChanges ? this.searchText : "", this.normalizedItems, this.$userStore.user, this.$intl)

            if (this.searchText !== "") {
                items = items.sort(bindArg(sortByWeight, this.$userStore.user, this.$intl, new Map()))
            }

            result = items.map(value => value[0])
        }

        return filterFirstEmpty(result, {filter: this.searchText})
    }

    @action
    public changeUserChanges(value: boolean) {
        this.$userChanges = value
    }

    @computed
    public get hasUserChanges() {
        return this.$userChanges
    }

}
