import * as React from "react"
import {PromoData} from "src/bums/common/promo/types";
import {createSeptemberPromoSettings} from "src/bums/common/promo/CRating/sept2019";
import {UserSettingStore} from "src/bums/common/stores/UserSettingStore";
import {ApiStore} from "src/lib/entities/store/ApiStore";
import {inject} from "src/lib/utils/inject";
import {computed} from "mobx";
import {FeatureStore} from "src/bums/common/stores/FeatureStore";
import * as DateHelper from "src/lib/utils/date";
import * as Api from "src/lib/entities/api";
import {OnBoardingFactory} from "src/bums/common/onboarding/stores/OnBoardingFactory";
import {autobind} from "core-decorators";
import {CarrotQuestPromoDataFactory} from "src/bums/common/promo/stores/CarrotQuestPromoDataFactory";
import {createDecemberPromoSettings} from "src/bums/common/promo/CRating/dec2019"
import {createVideocallPromoSettings} from "src/bums/common/promo/CPromoPopup/videocallPromo"
import {createUnsupportedBrowsersPromoSettings} from "src/bums/common/promo/CUnsupportedBrowserPopup/unsupportedBrowsersPromo"
import {isModifiedEvent} from "src/lib/components/CUrlify/CUrlify"
import {createPromoNewsSettings} from "src/bums/common/promo/CPromoNews/promoNews"
import {AccountInfoStore} from "src/lib/entities/store/AccountInfoStore"

/**
 * PromoContainer работает с последовательностью показа промоблоков (раз в два дня)
 * Имеет функционал показа промоблока один раз одному пользователю
 * Каждый промоблок скрывается под фичу и пока она выключена, промоблок тоже выключен
 * Учитывает первый логин пользователя и начинает показывать промо спустя два дня
 * В массиве promoConfigs указан порядок показа промо блоков сверху вниз
 * Существует настройка для тестопланов (bums.common.common.ignore_promo_two_days_condition) - показывает промо в подряд
 */

/**
 * OLD PROMOS
 {
    id: "promo.integration.uis_app_promo_need_show_p1",
    component: CPromoUisIntegration,
    feature: "integration_uis_promo",
    store: null,
},
 {
    id: CPromoGanttTaskDuration.SETTING_ID,
    component: CPromoGanttTaskDuration,
    feature: CPromoGanttTaskDuration.FEATURE_ID,
    store: null,
},
 {
    id: CPromoNewDealCard.SETTING_ID,
    component: CPromoNewDealCard,
    feature: CPromoNewDealCard.FEATURE,
    store: null
},
 {
    id: "promo.common.promo_march_8_male",
    component: CPromoMarch8Male,
    feature: "promo_march_8_male",
    store: null,
    date: new Date("2018-03-07"),
    userCondition: (userStore: Api.UserStore) =>
        userStore.user.gender === "male",
},
 {
    id: "promo.task.promo_gantt",
    component: CPromoGantt,
    feature: "promo_gantt",
    store: null,
},
 {
    id: "promo.common.promo_spring_themes",
    component: CPromoSpringThemes,
    feature: "promo_spring_themes",
    store: null,
    date: new Date("2018-03-01"),
},
 {
    id: "promo.common.promo_february_23_female",
    component: CPromoFebruary23Female,
    feature: "promo_february_23_female",
    store: null,
    date: new Date("2018-02-22"),
    userCondition: (userStore: Api.UserStore) =>
        userStore.user.gender === "female",
},
 */

const PROMO_SHOW_INTERVAL = 2
const FIRST_LOGIN_CONF = "sdf.common.user_first_login"

@autobind
export class PromoStore {
    //Настройка первого логина - timestamp - чтобы показывать промо блоки не сразу
    private firstLoginStore: UserSettingStore<number>

    private saveSettingRunning: boolean = false

    constructor(
        @inject(ApiStore) private apiStore: ApiStore,
        @inject(FeatureStore) private featureStore: FeatureStore,
        @inject(Api.UserStore) private userStore: Api.UserStore,
        @inject(OnBoardingFactory) private onBoardingFactory: OnBoardingFactory,
        @inject(CarrotQuestPromoDataFactory) private carrotQuestDataFactory: CarrotQuestPromoDataFactory,
        @inject(AccountInfoStore) private accountInfoStore: AccountInfoStore,
    ) {
        // Подргружаем время первого логина пользователя
        this.firstLoginStore = new UserSettingStore<number>(
            this.apiStore,
            () => FIRST_LOGIN_CONF,
            () => null
        )
    }

    @computed
    private get promoConfigs() {
        if (this.carrotQuestDataFactory.promoData.state !== "fulfilled") {
            return []
        }

        return [
            createSeptemberPromoSettings(),
            createDecemberPromoSettings("none"),
            createDecemberPromoSettings("trustLand"),
            createDecemberPromoSettings("startPack"),
            createVideocallPromoSettings(),
            createUnsupportedBrowsersPromoSettings(),
            createPromoNewsSettings(this.apiStore)
        ]
        .concat(this.carrotQuestDataFactory.promoData.value)
        .map(promo => {
            return {
                ...promo,
                store: promo.store
                    ? promo.store
                    : new UserSettingStore<number>(
                        this.apiStore,
                        () => promo.id,
                        () => null
                    ),
                }
            }
        )
    }

    @computed
    private get activePromoConfigs() {
        return this.promoConfigs.filter(promo => !promo.feature || this.featureStore.isAvailable(promo.feature))
    }

    @computed
    private get allPromoStoreFulfilled() {
        //Fulfill all promo configs
        let allFulfilled = true
        this.activePromoConfigs.forEach((promo) => {
            if (!promo.store || promo.store.get().state !== "fulfilled") {
                allFulfilled = false
            }
        })

        return  allFulfilled
    }

    @computed
    private get lastShownTimestamp() {
        //default timestamp two days ago
        let lastShownTimestamp: number = DateHelper.getYesterday(DateHelper.getYesterday()).getTime()

        this.activePromoConfigs.forEach((promo) => {
            const userSetting = promo.store.get()
            if (userSetting.state === "fulfilled") {
                if (userSetting.value && userSetting.value > lastShownTimestamp ) {
                    lastShownTimestamp = userSetting.value
                }
            }
        })

        return lastShownTimestamp
    }

    @computed
    private get nonShowByDaysConditionForNewUsers() {
        // Надо не показывать 2 дня промо блоки новым сотрудникам
        const ignoreDaysCondition = this.featureStore.isAvailable("bums.common.common.ignore_promo_two_days_condition");
        if (!ignoreDaysCondition) {
            const firstLogin = this.firstLoginStore.get()
            if (firstLogin.state !== "fulfilled") {
                return true;
            } else if (firstLogin.value) { //если время есть ( если null то это старые пользлватели)
                // Если PROMO_SHOW_INTERVAL (2 дня) не прошло с момента создания пользователя, то и показывать ничего не будем
                const dateDiffFromLogin = DateHelper.diff(DateHelper.getNow(), new Date(firstLogin.value),  DateHelper.Units.DAYS)
                if (dateDiffFromLogin < PROMO_SHOW_INTERVAL) {
                    return true;
                }
            }
        }
    }

    @computed
    private get datePromo() {
        // проверям промо по дате, если есть промо на конкретную дату, то надо его показать независимо от предыдущего промо
        const datePromo = this.activePromoConfigs.find(promo => promo.date && DateHelper.isSame(promo.date, new Date()))
        if (datePromo && this.needShowPromo(datePromo)) {
            return datePromo
        }
    }

    @computed
    private get promoConfigsWithoutDates() {
        return this.activePromoConfigs.filter(promo => !promo.date)
    }

    @computed
    private get intervalSinceLastShowHasExpired() {
        const ignoreDaysCondition = this.featureStore.isAvailable("bums.common.common.ignore_promo_two_days_condition");
        // Если PROMO_SHOW_INTERVAL (2 дня) не прошло, то и показывать ничего не будем
        if (!ignoreDaysCondition
            && DateHelper.diff(
                DateHelper.getNow(),
                new Date(this.lastShownTimestamp),
                DateHelper.Units.DAYS
            ) < PROMO_SHOW_INTERVAL) {
            return false
        }

        return  true
    }

    @computed
    private get promoToShow() {
        //Find promo to show and return with it
        for (let i = 0; i < this.promoConfigsWithoutDates.length; ++i) {
            if (this.needShowPromo(this.promoConfigsWithoutDates[i])) {
                return this.promoConfigsWithoutDates[i]
            }
       }
    }

    public needShowPromo<P>(promo: PromoData<P>) {
        const userSetting = promo.store.get()
        const satisfiedCondition = promo.userCondition
            ? promo.userCondition(this.userStore, this.featureStore)
            : true

        if (userSetting.state === "fulfilled" && !userSetting.value && satisfiedCondition) {
            return true
        }
    }

    @computed
    public get currentPromo() {
        //Отключаем для автотестирования (локально могут быть bt_mode включён или bums.common.promo выключено и тогда промо не будет
        if (
            (window && window.bt_mode)
            || this.accountInfoStore.licenseExpired
            || !this.featureStore.isCompleted
            || !this.carrotQuestDataFactory.isComplited
            || !this.featureStore.isAvailable("bums.common.promo")
            || this.onBoardingFactory.hasActiveProgram
            || (window && window.name === "reactBrowserFrame")
            || this.nonShowByDaysConditionForNewUsers
            || !this.allPromoStoreFulfilled
        ) {
            return null;
        }

        return this.datePromo
            || (
                this.intervalSinceLastShowHasExpired
                && this.promoToShow
            )
            || null
    }

    /**
     * При закрытии промо, проставляет в конфиг на сервере текущую timestamp
     * @param {UserSettingStore<number>} userSetting
     * @returns {(url?: string, event?: React.MouseEvent<HTMLElement>) => Promise<undefined>}
     */
    public actionDone(userSetting: UserSettingStore<number>) {
        return async (url?: string, event?: React.MouseEvent<HTMLElement>) => {
            if (event) {
                event.preventDefault()
                event.stopPropagation()
                if (event.button !== 0 || isModifiedEvent(event)) {
                    return
                }
            }
            if (!this.saveSettingRunning) {
                this.saveSettingRunning = true
                await userSetting.set((new Date()).getTime())
                this.saveSettingRunning = false
                if (url) {
                    window.location.href = url
                }
            }
        }
    }
}
