import * as React from "react"
import classNames from "classnames/bind"
import {CIcon, CEllipsis, Component} from "src/lib/components"
import {sizeType} from "src/lib/types";

const style = classNames.bind(require("./CChip.styl"));


/**
 * скругленный блок с текстом и иконкой удаления
 */
export class CChip extends Component<CChip.Props, {}> {
    public static defaultProps = {
        type: "default"
    }

    public render() {
        const {removable, type, content, noEllipsis, noContentStyle} = this.props

        return <div className={style("CChip", type, {removable: removable}, this.props.className)}>
            {noEllipsis ? <div className={noContentStyle ? void 0 : style("content")}>{content}</div> : <CEllipsis>{content}</CEllipsis>}
            {removable  &&
                <CIcon
                    type={CIcon.Type.CANCEL_CIRCLE}
                    color={["group", "selected"].includes(type) ?  CIcon.Color.LIGHT : CIcon.Color.DARK}
                    onClick={this.props.onRemove}
                    size={CIcon.Size.SMALL}
                    className={style("removeButton")}
                />
            }
        </div>
    }
}

export namespace CChipBox {
    export interface Props {
        className?: string
        onClick?: React.MouseEventHandler<EventTarget>
        size?: sizeType
        // нужен для отлова скролла
        onMouseDown?: React.MouseEventHandler<EventTarget>
        onKeyDown?: React.KeyboardEventHandler<EventTarget>
    }
}

/**
 * Блок для вывода списка CChip
 */
export class CChipBox extends Component<CChipBox.Props, {}> {
    public render() {
        const {className, size} = this.props

        return <div {...this.props} className={style("CChipBox", size, className)} >
            {React.Children.map(this.props.children, (child: JSX.Element, index: number) => {
                return child && child.type && (child.type as React.ComponentClass<any> === CChip)
                    ? React.cloneElement(child, {className: style("CChipBoxItem", child.props.className), key: index})
                    : child
            })}
        </div>
    }
}


export namespace CChip {
    export interface Props {
        content: string | JSX.Element
        removable?: boolean
        onRemove?: (event?: React.MouseEvent<Element>) => void
        noEllipsis?: boolean
        type?: "default" | "group" | "selected" | "picker"
        className?: string
        noContentStyle?: boolean
        size?: sizeType
    }

    export const Box = CChipBox
}
