import {autobind} from "core-decorators"
import {computed, action, observable, runInAction} from "mobx"
import {OnBoardingProgramTutorial} from "src/lib/entities/bums"
import * as Api from "src/lib/entities/api"
import {ApiResponse} from "src/lib/entities/types"
import {Response} from "src/lib/utils/fetch"

enum FetchAction {
    next = "next",
    cancel = "cancel"
}

@autobind
export class OnBoardingProgramStore {

    @observable.ref
    private onBoardingProgram: OnBoardingProgramTutorial

    @observable
    private $isLoading: boolean = false

    constructor(
        private apiStore: Api.Store,
        private programId: string
    ) {
        void this.fetchProgram()
    }

    @computed
    public get currentStep() {
        if (this.onBoardingProgram) {
            return this.onBoardingProgram.step
        }
        return null
    }

    @computed
    public get isLoading() {
        return this.$isLoading
    }

    public nextStep() {
        void this.fetchProgram(FetchAction.next)
    }

    public cancel() {
        void this.fetchProgram(FetchAction.cancel)
    }

    @action
    private async fetchProgram(withAction?: FetchAction) {
        this.$isLoading = true
        let response: Response<ApiResponse<OnBoardingProgramTutorial>>
        if (withAction) {
            response = await this.apiStore.fetch<OnBoardingProgramTutorial>(this.endpoint + withAction, {method: "POST"})
        } else {
            response = await this.apiStore.fetch<OnBoardingProgramTutorial>(this.endpoint)
        }
        runInAction(() => {
            this.onBoardingProgram = response.value.data
            this.$isLoading = false
        })
    }

    private get endpoint() {
        return `/api/v3/onboardingProgram/${this.programId}/`
    }

}
