import * as ReduxActions from "redux-actions"
import {CARD_SUBJECT} from "../constants"
import {CardMeta} from "../types"

export const SWITCH_COLLAPSE = "src/lib/CCardContainer/action/SWITCH_COLLAPSE"

type SwitchCollapseAction = ReduxActions.Action<boolean> & CardMeta

export function switchCollapse(cardId: string, collapse: boolean): SwitchCollapseAction {
    return {
        type: SWITCH_COLLAPSE,
        payload: collapse,
        meta: {
            cardId,
            subject: CARD_SUBJECT
        }
    }
}

export function isSwitchCollapseAction(action: any): action is SwitchCollapseAction {
    return "object" === typeof action && SWITCH_COLLAPSE === action.type
}
